/* eslint-disable react/no-danger, react/destructuring-assignment */
import { isPlainObject } from "lodash";
import React from "react";
import __ from "src/utils/Translations";

const getReceiverFields = (receiverItemInfo) => {
  const { form: formPerReceiver } = receiverItemInfo || {};
  let receiverFullName = [];

  // Check if subscription has data collection at all
  // (in case when it doesn't `formPerReceiver` is an empty array)
  if (isPlainObject(formPerReceiver)) {
    const firstName = formPerReceiver.fields?.find(
      (field) => field.type === "firstname"
    )?.value;
    const lastName = formPerReceiver.fields?.find(
      (field) => field.type === "lastname"
    )?.value;

    if (firstName && lastName) {
      receiverFullName = [`${firstName} ${lastName}`];
    }
  } else {
    receiverFullName = null;
  }

  return receiverFullName;
};

const BenefitNameColumn = (cellInfo) => {
  const columnId = cellInfo.column.id;
  const rowName = cellInfo.row[columnId];
  const receiverItemInfo = cellInfo.original;

  const receiverFullName = getReceiverFields(receiverItemInfo);

  const content = (
    <span>
      <span
        dangerouslySetInnerHTML={{ __html: `<strong>${rowName}</strong>` }}
      />
      {receiverFullName && columnId !== "receiverFullName" && (
        <>
          &nbsp;
          <span>{__("dla")}</span>
          &nbsp;
          <span>
            {receiverFullName.length ? receiverFullName.join(", ") : "..."}
          </span>
        </>
      )}
    </span>
  );

  return content;
};

export default BenefitNameColumn;
