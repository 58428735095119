import { useState, useEffect, useCallback } from "react";
import { fetchRentableGroups } from "src/utils/Company";

export default function useRentableGroups(
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false,
  noFetching = false
) {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const result = await fetchRentableGroups(
      asOption,
      filterField,
      filterValue,
      idAsIri
    );
    setData(result);
  }, [asOption, filterField, filterValue, idAsIri]);

  useEffect(() => {
    if (noFetching) {
      setData([]);
      return;
    }
    fetchData();
  }, [noFetching, fetchData]);

  return data;
}
