import React, { useEffect } from "react";
import { Input } from "reactstrap";
import { Loader } from "react-loaders";
import PropTypes from "prop-types";
import isEmptyValue from "src/utils/jsHelpers/isEmptyValue";

const TranslationColumn = ({ column, original: data }) => {
  const { id, loading } = data;
  const { updateData, setActiveId, activeId, ids } = column;
  const isActive = id === activeId;

  useEffect(() => {
    if (isActive) {
      focusInput(id);
    }
  }, [isActive, id]);

  const inputData = data[column.id];

  const saveValue = (value) => {
    if (
      value !== inputData &&
      !(isEmptyValue(inputData) && isEmptyValue(value))
    ) {
      updateData(id, value ? value.trimStart() : value);
    }
  };

  return (
    <div className="d-block w-100 text-center" key={id}>
      {loading ? (
        <Loader active type="line-scale" style={{ transform: "scale(0.6)" }} />
      ) : (
        <Input
          id={`translate_input_${id}`}
          defaultValue={inputData}
          type="text"
          disabled={!isActive}
          onBlur={(e) => {
            const { value } = e.target;
            saveValue(value);
          }}
          onKeyDown={(e) => {
            const { key, target } = e;
            if (key === "Escape") {
              setActiveId(null);
              target.blur();
            } else if (key === "ArrowDown" || key === "Enter") {
              if (key === "Enter") {
                e.stopPropagation();
                e.preventDefault();
              }
              const currPosition = ids.indexOf(id);
              if (currPosition < ids.length - 1) {
                setActiveId(ids[currPosition + 1]);
                target.blur();
              } else {
                saveValue(target.value);
              }
            } else if (key === "ArrowUp") {
              const currPosition = ids.indexOf(id);
              if (currPosition !== 0) {
                target.blur();
                setActiveId(ids[currPosition - 1]);
              } else {
                saveValue(target.value);
              }
            }
          }}
        />
      )}
    </div>
  );
};

const focusInput = (id) =>
  document.getElementById(`translate_input_${id}`).focus();

TranslationColumn.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string,
    updateData: PropTypes.func,
    setActiveId: PropTypes.func,
    activeId: PropTypes.number,
    ids: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  original: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loading: PropTypes.bool,
  }).isRequired,
};

export default TranslationColumn;
