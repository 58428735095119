import PropTypes from "prop-types";
import React from "react";
import Loader from "react-loader-advanced";
import Spinner from "src/Components/Spinner";

export default function ContentLoading({
  children,
  message,
  useBlur,
  show,
  messageStyle,
  backgroundStyle,
  style,
}) {
  return (
    <Loader
      message={message}
      contentBlur={useBlur ? 4 : 0}
      show={show}
      messageStyle={messageStyle}
      backgroundStyle={backgroundStyle}
      style={style}
    >
      {children}
    </Loader>
  );
}

ContentLoading.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  backgroundStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // eslint-disable-next-line react/forbid-prop-types
  messageStyle: PropTypes.object,
  show: PropTypes.bool.isRequired,
  useBlur: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

ContentLoading.defaultProps = {
  // eslint-disable-next-line react/forbid-prop-types
  backgroundStyle: null,
  message: Spinner,
  messageStyle: {},
  useBlur: true,
  style: {},
};
