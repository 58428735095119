import {
  COMPANY_SCOPE,
  DEFAULT_SCOPE,
  EMPLOYEE_GROUP_SCOPE,
} from "src/Pages/Administration/Configuration/consts";

const filterSchema = (schema, scopeCode) =>
  schema
    .map((section) => {
      if (!section.children || !section.children.length) {
        return null;
      }
      const groups = section.children
        .map((group) => {
          if (!group.children || !group.children.length) {
            return null;
          }
          const fields = group.children
            .filter((field) => {
              switch (scopeCode) {
                case EMPLOYEE_GROUP_SCOPE:
                  return field.showInEmployeeGroup !== false;
                case COMPANY_SCOPE:
                  return field.showInCompany !== false;
                case DEFAULT_SCOPE:
                default:
                  return field.showInDefault !== false;
              }
            })
            .filter(Boolean);
          return fields.length
            ? {
                ...group,
                fields,
              }
            : null;
        })
        .filter(Boolean);
      return groups.length
        ? {
            ...section,
            groups,
          }
        : null;
    })
    .filter(Boolean);

export default filterSchema;
