import PropTypes from "prop-types";
import React from "react";
import __ from "src/utils/Translations";

const displayLegend = (legend) => {
  if (Array.isArray(legend)) {
    return legend.map((item) => (
      <p className="ml-3 font-weight-normal" key={item}>
        {item}
      </p>
    ));
  }

  if (typeof legend === "object") {
    return Object.keys(legend).map((key) => (
      <p className="ml-3 font-weight-normal" key={key}>
        {key} - {legend[key]}
      </p>
    ));
  }
  return legend;
};

export default function Legend({ id, title, legend, translateTitle }) {
  return (
    <div className="col-sm-12">
      <p id={id}>{translateTitle ? __(title) : title}</p>
      <div className="col-sm-12">{displayLegend(legend)}</div>
    </div>
  );
}

Legend.propTypes = {
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  translateTitle: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string.isRequired,
};

Legend.defaultProps = {
  legend: [],
  translateTitle: true,
  title: "Legenda:",
};
