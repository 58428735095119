import React, { useContext } from "react";
import { ListGroupItem, ListGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";
import { getBrowsingHistory } from "src/utils/browsingHistory";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { getColorByType, getIconByType } from "./utils";

function BrowsingHistory({ close }) {
  const { userInfo } = useContext(RbsContext);
  const userId = userInfo.getId();
  const history = getBrowsingHistory(userId);
  return (
    <div className="drawer-section p-0">
      <div className="files-box">
        <ListGroup flush>
          {history.length ? (
            history.map(({ type, path, title }) => (
              <Link key={path} to={path} onClick={close}>
                <ListGroupItem className="pt-2 pb-2 pr-2">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div
                        className={`widget-content-left fsize-2 mr-3 ${getColorByType(
                          type
                        )} center-elem`}
                      >
                        <FontAwesomeIcon icon={getIconByType(type)} />
                      </div>
                      <div className="widget-content-left">
                        <div className="font-weight-normal">{title}</div>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
              </Link>
            ))
          ) : (
            <strong className="m-3">
              {__("Brak historii przeglądanych obiektów")}
            </strong>
          )}
        </ListGroup>
      </div>
    </div>
  );
}

BrowsingHistory.propTypes = {
  close: PropTypes.func.isRequired,
};

export default BrowsingHistory;
