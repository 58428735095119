import PropTypes, { bool, string } from "prop-types";
import React, { cloneElement, useState, useContext } from "react";
import { useHistory, Prompt } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import { filterTabsByAcl } from "src/utils/RoleBasedSecurity/filters";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import __ from "src/utils/Translations";
import DefaultHashTabBar from "./DefaultHashTabBar";

export default function TabsWithMemory({
  tabsConfig = [],
  activeKey,
  defaultActiveKey,
  animated = false,
  promptWhenUnsaved = true,
}) {
  const [editedTabs, setEditedTabs] = useState({});
  const { userInfo } = useContext(RbsContext);
  const history = useHistory();

  const setTabIsEdited = (key, value) => {
    if (editedTabs[key] !== value) {
      const newState = { ...editedTabs };
      newState[key] = value;
      setEditedTabs(newState);
    }
  };

  const getLeaveWarningMessage = (unsavedTabsKeys, location) => {
    if (location.pathname === history.location.pathname) {
      return "";
    }
    const unsavedTabsName = unsavedTabsKeys
      .map((key) => {
        const tab = tabsConfig.find((el) => el.key === key);
        return tab ? tab.name : null;
      })
      .filter(Boolean);
    if (unsavedTabsName.length === 1) {
      return `${__("Masz niezapisany formularz w zakładce")} ${
        unsavedTabsName[0]
      }. ${__("Niezapisane zmiany zostaną utracone")}.`;
    }
    return `${__("Masz niezapisane formularze w zakładkach")}:${
      unsavedTabsName ? ` ${unsavedTabsName.join(", ")}.` : ","
    } ${__("Niezapisane zmiany zostaną utracone")}.`;
  };

  const currentKey = activeKey || defaultActiveKey;

  const unsavedForms = Object.keys(editedTabs).filter((key) =>
    Boolean(editedTabs[key])
  );

  return (
    <>
      <Tabs
        animated={animated}
        activeKey={currentKey}
        renderTabBar={() => <DefaultHashTabBar />}
        renderTabContent={() => <TabContent animated={animated} />}
      >
        {filterTabsByAcl(tabsConfig, userInfo).map(
          ({
            name,
            key,
            component,
            disabled,
            tabClassName = null,
            tabTitle = null,
            display = true,
            link,
          }) =>
            display ? (
              <TabPane
                tab={
                  <TabComponent
                    name={name}
                    key={key}
                    tabClassName={tabClassName}
                    edited={editedTabs[key]}
                    link={link}
                  />
                }
                key={key}
                disabled={Boolean(disabled)}
              >
                {cloneElement(component, {
                  active: currentKey === key,
                  setIsEdited: (state) => setTabIsEdited(key, state),
                })}
              </TabPane>
            ) : null
        )}
      </Tabs>
      {promptWhenUnsaved ? (
        <Prompt
          when={unsavedForms.length > 0}
          message={(location) => getLeaveWarningMessage(unsavedForms, location)}
        />
      ) : null}
    </>
  );
}

TabsWithMemory.propTypes = {
  activeKey: PropTypes.string,
  animated: PropTypes.bool,
  defaultActiveKey: PropTypes.string.isRequired,
  promptWhenUnsaved: PropTypes.bool,
  tabsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      aclKey: PropTypes.string,
      component: PropTypes.node,
      disabled: PropTypes.bool,
      link: PropTypes.string,
    })
  ).isRequired,
};

TabsWithMemory.defaultProps = {
  activeKey: "",
  animated: false,
  promptWhenUnsaved: true,
};

const TabComponent = ({ name, className, title, edited, link }) =>
  link ? (
    <RedirectTab name={name} link={link} key="tab-redirect-component" />
  ) : (
    <span className={className} title={title} key="tab-component">
      {name}
      {edited && (
        <span key="edit-icon" className="ml-2">
          <MdModeEdit
            style={{ position: "absolute", top: "8px" }}
            fontSize="15px"
          />
        </span>
      )}
    </span>
  );

const RedirectTab = ({ name, link }) => {
  const handleClick = () => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <span
      role="button"
      tabIndex="0"
      onClick={handleClick}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          handleClick();
        }
      }}
    >
      {name}
    </span>
  );
};

TabComponent.propTypes = {
  name: string.isRequired,
  className: string.isRequired,
  title: string.isRequired,
  edited: bool,
  link: string.isRequired,
};

TabComponent.defaultProps = {
  edited: false,
};

RedirectTab.propTypes = {
  name: string.isRequired,
  link: string.isRequired,
};
