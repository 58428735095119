import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import __ from "src/utils/Translations";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import Categories from "src/Pages/Company/TotalReward/Categories/Listing";

export default () => {
  const companyName = useCompanyName();
  const companyId = useCompanyId();
  const pathPrefix = `/ahr`;

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={`${__(
          "Zarządzanie kategoriami dla firmy"
        )} ${companyName} (ID: ${companyId})`}
        breadcrumbsHeading={__("Zarządzanie kategoriami benefitów")}
        pushToHistory
        breadcrumbs={[
          {
            title: "Total Reward Statement",
            link: `${pathPrefix}/total-reward`,
          },
        ]}
      />
      <Categories companyId={companyId} pathPrefix={pathPrefix} />
    </CSSTransitionGroup>
  );
};
