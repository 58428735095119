import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import PropTypes from "prop-types";
import { PROCESSES_SERVICE, restApiRequest } from "src/utils/Api";
import { DefaultLoader } from "src/Components/Loading/loaders";
import __ from "src/utils/Translations";
import { processStatusOptions } from "src/Pages/Processes/Utils/processStatuses";
import useOperators from "src/utils/hooks/operator/useOperators";

const getModifiedDate = (date) =>
  new Date(date).toLocaleString("pl-PL", { timeZone: "Europe/Warsaw" });

export default function ProcessBar({ processId, onProcessView }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const timeout = useRef(null);
  function getStatusColor(status) {
    if (loading) {
      return "#4d00d2";
    }
    if (status === "new" || status === "in_progress") {
      return "#fa9d28";
    }
    if (status === "success") {
      return "#68CE13";
    }
    if (status === "warning") {
      return "#ffde42";
    }

    return "#d20000";
  }
  const clearCheckTimeout = useCallback(() => {
    const timeoutRef = timeout.current;
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
  }, []);
  const checkProcess = useCallback(async () => {
    clearCheckTimeout();
    setLoading(true);
    const res = await restApiRequest(
      PROCESSES_SERVICE,
      `/processes/${processId}`,
      "GET",
      {}
    );
    setData(res);
    setLoading(false);
    if (res.status === "new" || res.status === "in_progress") {
      timeout.current = setTimeout(checkProcess, 5000);
    }

    if (res.status === "delegated")
      timeout.current = setTimeout(checkProcess, 30000);
  }, [processId, clearCheckTimeout]);

  useEffect(() => {
    checkProcess();
  }, [checkProcess]);

  useEffect(() => () => clearCheckTimeout(), [clearCheckTimeout]);

  let statusToDisplay = {};
  const {
    name,
    status,
    currentStep,
    stepsOk,
    stepsError,
    stepsWarn,
    maxStep,
    createdAt,
    updatedAt,
    authorId,
  } = data;
  const operators = useOperators(true);
  const matchedOptionOperator = operators.find(
    (option) => option.value === authorId
  );
  const author = matchedOptionOperator ? matchedOptionOperator.label : authorId;

  processStatusOptions.forEach((el) => {
    if (status === el.value) statusToDisplay = el;
  });
  return (
    <>
      <Card style={{ height: "210px" }}>
        <CardBody>
          {loading && <DefaultLoader />}
          {!loading && (
            <>
              <CardTitle
                data-t1="importStatus"
                data-t2={statusToDisplay.dataT2}
              >
                <div>
                  <div style={{ display: "inline-block", width: "75%" }}>
                    {`${name} - ${statusToDisplay.label}`}
                    <div
                      className="dot"
                      style={{ backgroundColor: getStatusColor(status) }}
                    />
                  </div>
                  {!onProcessView && (
                    <div
                      style={{
                        display: "inline-block",
                        width: "25%",
                        "text-align": "right",
                      }}
                    >
                      <a
                        className="mr-2 btn btn-primary btn-lg"
                        href={`/processes/show/${processId}?messageType[0]=warn&messageType[1]=error`}
                      >
                        {__("Podgląd procesu")}
                      </a>
                    </div>
                  )}
                </div>
              </CardTitle>
              <div style={{ fontWeight: 700 }}>{__("Status procesu")}</div>
              <div style={{ width: "40%", float: "left" }}>
                <li key="maxStep">
                  {__("Wierszy ogółem")}: {maxStep}
                </li>
                <li key="currentStep">
                  {__("Obecny wiersz")}: {currentStep}
                </li>
                {status !== "in_progress" && status !== "new" ? (
                  <>
                    <li key="stepsOk">
                      {__("Poprawnie przetworzone wiersze")}: {stepsOk}
                    </li>
                    <li key="stepsWarn">
                      {__("Wiersze nieprzetworzone")}: {stepsWarn}
                    </li>
                  </>
                ) : (
                  ""
                )}
                <li key="stepsError">
                  {__("Błędów krytycznych")}: {stepsError}
                </li>
              </div>
              <div style={{ width: "40%", float: "left" }}>
                <li key="createdAt">
                  {__("Data rozpoczęcia")}: {getModifiedDate(createdAt)}
                </li>
                <li key="updatedAt">
                  {__("Data ostatniej aktualizacji")}:
                  {getModifiedDate(updatedAt)}
                </li>
                <li key="name">
                  {__("Nazwa usługi")}: {name}
                </li>
                <li key="authorId">
                  {__("Zlecający")}: {author}
                </li>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
ProcessBar.propTypes = {
  processId: PropTypes.string,
  onProcessView: PropTypes.bool,
};
ProcessBar.defaultProps = {
  processId: "",
  onProcessView: false,
};
