import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardBody, CardHeader, Card, Button } from "reactstrap";
import Form from "src/Components/Form";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { API_TOTALREWARD_SERVICE, restApiRequestFormData } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function ImportForm({ companyId, imported, setImported }) {
  const submitMethod = async (data) => {
    try {
      const fileToSend = data.file[0];
      const formData = new FormData();
      formData.append("file", fileToSend);
      formData.append("tenant", companyId);

      const result = await restApiRequestFormData(
        API_TOTALREWARD_SERVICE,
        `/omb/imports?tenant=${companyId}`,
        "POST",
        formData,
        {},
        {}
      );

      const newData = await result.json();

      setImported(newData?.id);

      dynamicNotification(
        __(
          "Plik został zlecony do importu. Odśwież stronę i sprawdź wynik operacji w tabeli poniżej."
        )
      );
    } catch (e) {
      console.error(e);
      dynamicNotification([__("Wystąpił nieznany błąd")]);
      newRelicErrorReport(
        e,
        "Pages/Company/TotalReward/ImportExport/Import/form.js - 21"
      );
    }
  };

  const [data, updateData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const onChange = (key, value) => {
    const updatedData = { ...data };
    updatedData[key] = value;
    updateData(updatedData);
  };

  const fields = [
    {
      id: "file",
      type: "file",
      label: "Plik Excel",
      validation: [
        "required",
        { method: "allowedExtensions", args: [["xls", "xlsx"]] },
      ],
    },
    {
      key: "submitButton",
      component: (
        <Button
          data-t1="import"
          key="import-submit"
          disabled={isProcessing}
          color="primary"
          type="submit"
        >
          {__("Importuj")}
        </Button>
      ),
    },
  ];

  return (
    <Card className="main-card mb-3">
      <CardHeader>{__("Import danych")}</CardHeader>
      <CardBody className="m-2">
        <div>
          <p>
            {__(
              "Aby zaimportować benefity na konta użytkowników zalecane jest pobranie eksportu danych i przygotowanie nowego pliku do importu na bazie pobranego szablonu. W pliku obowiązkowe jest uzupełnienie trzech kolumn:"
            )}
          </p>
          <p>
            {__(
              "ID pracownika = FK pracownika, ID benefitu, Okres (wymagany format: RRRR-MM), Wartość."
            )}
          </p>
          <p>
            {__(
              "Pozostałe kolumny są jedynie pomocnicze i nie są importowane do systemu."
            )}
          </p>
          <div>
            {__("Aby zaimportować na konto pracownika benefit:")}
            <ul>
              <li>
                {__(
                  "z wartością (finansowy) należy w kolumnie „Wartość” wpisać odpowiednią liczbę,"
                )}
              </li>
              <li>
                {__(
                  "bez wartości (niefinansowy) należy w kolumnie „Wartość” wstawić opis lub kropkę."
                )}
                <br />
                {__(
                  "Plik zostanie odrzucony, jeśli w pliku w polu „Wartość” będą puste rekordy."
                )}
              </li>
            </ul>
          </div>
          <p>
            {__(
              "Aby zmienić wartość danego benefitu na koncie pracownika należy ponownie wgrać plik z nową wartością, która zastąpi poprzednią."
            )}
          </p>
        </div>
        <div className="mb-4">
          <Form
            id="importForm"
            data={data}
            config={{
              defaultOnChange: onChange,
              noCards: true,
              onSubmit: async () => {
                setIsProcessing(true);
                await submitMethod(data);
                setIsProcessing(false);
                updateData({});
              },
              formGroups: [
                {
                  formElements: fields,
                },
              ],
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
}

ImportForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  imported: PropTypes.string,
  setImported: PropTypes.func,
};

ImportForm.defaultProps = {
  imported: null,
  setImported: () => {},
};
