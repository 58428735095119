import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import { Input, Table, Alert } from "reactstrap";
import RbsButton from "src/utils/RoleBasedSecurity/SecurityComponents/RbsButton";
import {
  employeeAccountPermissionWrite,
  cyclicChargesPermissionWrite,
} from "src/utils/RoleBasedSecurity/permissions";
import ValueFormatter from "src/utils/ValueFormatter";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import __ from "src/utils/Translations";
import { SETTLEMENT_TYPE_IN_ADVANCE } from "src/Pages/Company/CompanyBanks/Management/Listing/bankForm";
import {
  restApiRequest,
  EMPLOYEE_MANAGEMENT_SERVICE,
  COMPANY_MANAGEMENT_SERVICE,
} from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import { useCompanyId } from "src/Pages/Company/CompanyContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function ChargeUpsList({ accounts, chargeAccount }) {
  const [inputsValue, setInputsValue] = useState({});
  const { userInfo } = useContext(RbsContext);
  const companyId = useCompanyId();
  const isAhr = userInfo.isAhr();
  const [periodInputValue, setPeriodInputValue] = useState({});
  const [employeeCharges, setEmployeeCharges] = useState(null);
  const [postResult, setPostResult] = useState(false);
  const userHasChargesPermission = useHasPermission(
    cyclicChargesPermissionWrite
  );
  const [bankWhichCyclicCharges, setBankWhichCyclicCharges] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await restApiRequest(
          EMPLOYEE_MANAGEMENT_SERVICE,
          `/cyclic-charges/individual-charges/companies/${companyId}`,
          "GET",
          {},
          []
        );
        setEmployeeCharges(result?.individualCharges);
      } catch (errorData) {
        newRelicErrorReport(
          errorData,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/ChargeUps/ChargeUpsList/index.js - 48"
        );
        console.error(errorData);
      }
    };
    fetchData();
  }, [companyId, postResult]);

  useEffect(() => {
    accounts.forEach((ele) => {
      const fetchData = async () => {
        try {
          const result = await restApiRequest(
            COMPANY_MANAGEMENT_SERVICE,
            `/points-banks/cyclic-charges/banks/${ele.pointsBankId}`,
            "GET",
            {},
            []
          );
          setBankWhichCyclicCharges((prevState) => [...prevState, result]);
        } catch (errorData) {
          console.error(errorData);
          newRelicErrorReport(
            errorData,
            "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/ChargeUps/ChargeUpsList/index.js - 74"
          );
        }
      };

      fetchData();
    });
  }, [accounts]);

  const chargeCyclicAccount = (accountInfo, inputValue) => {
    const fetchData = async () => {
      try {
        const result = await restApiRequest(
          EMPLOYEE_MANAGEMENT_SERVICE,
          `/cyclic-charges/individual-charges/${accountInfo.id}`,
          "PATCH",
          {
            body: {
              chargeCyclicAmount: inputValue,
            },
          },
          []
        );
        dynamicNotification(__("Zmieniono doładowanie cykliczne"), "success");
        setPostResult(result);
      } catch (errorData) {
        dynamicNotification(
          __("Nie można ustawić cyklicznego doładowania"),
          "error"
        );
        console.error(errorData);
        newRelicErrorReport(
          errorData,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/ChargeUps/ChargeUpsList/index.js - 105"
        );
      }
    };
    fetchData();
  };

  const deleteCharge = (employeeHasCyclicChargesInCurrentBank, accountId) => {
    const pointsChargeId =
      employeeHasCyclicChargesInCurrentBank?.[0].pointsChargeId;
    const fetchData = async () => {
      try {
        const result = await restApiRequest(
          EMPLOYEE_MANAGEMENT_SERVICE,
          `/cyclic-charges/individual-charges/${accountId}`,
          "DELETE",
          {
            body: {
              pointsChargeId,
            },
          },
          []
        );
        dynamicNotification(__("Usunięto doładowanie cykliczne"), "success");
        setPostResult(result);
      } catch (errorData) {
        dynamicNotification(
          __("Usuwanie doładowania cyklicznego nie powiodło się"),
          "error"
        );
        console.error(errorData);
        newRelicErrorReport(
          errorData,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/ChargeUps/ChargeUpsList/index.js - 138"
        );
      }
    };
    fetchData();
  };

  const updateInputValue = (e, type) => {
    const account = accounts.find(({ id }) => id === e.target.name);
    if (!account) {
      return;
    }
    const allowNegative =
      !isAhr || account.settlementType !== SETTLEMENT_TYPE_IN_ADVANCE;
    const updatedData =
      type !== "period" ? { ...inputsValue } : { ...periodInputValue };
    let value = ValueFormatter(
      allowNegative ? "price_with_negative" : "price",
      e.target.value
    );
    if (value < 0 && value * -1 > account.balance) {
      value = account.balance * -1;
    }
    updatedData[e.target.name] = value;
    if (type !== "period") setInputsValue(updatedData);
    else setPeriodInputValue(updatedData);
  };

  const validateOnBlur = (e) => {
    const inputValue = e.target.value;
    const value = Number.parseFloat(inputValue);
    const updatedData = { ...inputsValue };
    if (Number.isNaN(value)) {
      updatedData[e.target.name] = "";
    } else {
      updatedData[e.target.name] = value;
    }
    setInputsValue(updatedData);
  };

  const currentBankHasCyclicChargesFunc = (account) => {
    const currentBankHasCyclicCharges = bankWhichCyclicCharges.some((ele) => {
      const bankCyclicCharges = [...ele.cyclicCharges];
      const haveBankGoodConfigruations = bankCyclicCharges.some(
        (eachBankCyclic) => {
          const isProperlyStatus =
            eachBankCyclic.status === "WAITING_FOR_CHARGE_TIME" ||
            eachBankCyclic.status === "PREPARING_TRANSFERS" ||
            eachBankCyclic.status === "WAITING_FOR_TRANSFERS";
          return isProperlyStatus;
        }
      );
      return (
        ele.pointsBankId === account.pointsBankId && haveBankGoodConfigruations
      );
    });

    return currentBankHasCyclicCharges;
  };

  return (
    <div data-t1="charge_ups">
      {isAhr && (
        <Alert color="warning">
          {__("Banki rozliczane z góry nie mogą być rozładowywane.")}
        </Alert>
      )}
      <Table className="mb-0">
        <thead>
          <tr>
            <th>{__("Nazwa banku")}</th>
            <th>{__("Typ rozliczenia")}</th>
            <th className="text-center">{__("Aktualne saldo")}</th>
            <th className="text-center">
              {__("Kwota doładowania/rozładowania")}
            </th>
            <th className="text-center">{__("Ilość punktów po operacji")}</th>
            <th className="text-center">{__("Doładuj/rozładuj")}</th>
            {userHasChargesPermission && (
              <th className="text-center">
                {__("Aktualna wartość cyklicznego doładowania indywidualnego")}
              </th>
            )}
            {userHasChargesPermission && (
              <th className="text-center">
                {__("Cykliczne doładowania indywidualne")}
              </th>
            )}
            {userHasChargesPermission && (
              <th className="text-center">{__("Akcja")}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => {
            const { id, pointsBankDisplayName, settlementTypeLabel } = account;
            const accountBalance = parseFloat(account.balance.toFixed(2), 10);
            const accountBalanceAfterTransfer = Number.isNaN(
              parseFloat(inputsValue[account.id], 10)
            )
              ? parseFloat(account.balance.toFixed(2), 10)
              : parseFloat(
                  (account.balance + 1 * inputsValue[account.id]).toFixed(2),
                  10
                );

            const employeeHasCyclicChargesInCurrentBank =
              employeeCharges?.filter(
                (ele) => ele.pointsBankId === account.pointsBankId
              );
            const hasCyclicCharges =
              employeeHasCyclicChargesInCurrentBank &&
              employeeHasCyclicChargesInCurrentBank.length !== 0;

            const employeeCyclicChargesPerBank =
              hasCyclicCharges &&
              employeeHasCyclicChargesInCurrentBank[0].individualCharges?.find(
                (ele) => ele.employeeId === account.employeeId
              );
            const currentPoints =
              hasCyclicCharges && employeeCyclicChargesPerBank
                ? employeeCyclicChargesPerBank.points
                : "0";

            currentBankHasCyclicChargesFunc(account);
            return (
              <tr key={id} data-t1={pointsBankDisplayName}>
                <td
                  data-t1="pointsBankDisplayName"
                  data-t2={pointsBankDisplayName}
                >
                  {pointsBankDisplayName}
                </td>
                <td data-t1="settlementTypeLabel" data-t2={settlementTypeLabel}>
                  {settlementTypeLabel}
                </td>
                <td
                  data-t1="accountBalance"
                  className="text-center"
                  data-t2={accountBalance}
                >
                  {accountBalance}
                </td>
                <td>
                  <Input
                    data-t1="TopUpAmount"
                    value={inputsValue[account.id]}
                    name={account.id}
                    type="text"
                    placeholder={__("Wartość operacji")}
                    onChange={updateInputValue}
                    onBlur={validateOnBlur}
                  />
                </td>
                <td
                  className="text-center"
                  data-t1="accountBalanceAfterTransfer"
                  data-t2={accountBalanceAfterTransfer}
                >
                  {accountBalanceAfterTransfer}
                </td>
                <td className="text-center">
                  {!["payment_zfss_general", "payment_bank_of_points"].includes(
                    account.pointsBankName
                  ) ? (
                    <RbsButton
                      data-t1="charge"
                      permission={employeeAccountPermissionWrite}
                      color="link"
                      disabled={!inputsValue[account.id]}
                      onClick={() => {
                        setInputsValue({ ...inputsValue, [account.id]: "" });
                        chargeAccount(account, inputsValue[account.id]);
                      }}
                    >
                      {__("Wykonaj")}
                    </RbsButton>
                  ) : null}
                </td>
                {userHasChargesPermission && (
                  <td data-t1="" className="text-center" data-t2="">
                    {hasCyclicCharges ? currentPoints : "0"}
                  </td>
                )}
                {userHasChargesPermission && (
                  <td>
                    <Input
                      data-t1="cyclicalTopUpAmount"
                      value={periodInputValue[account.id]}
                      name={account.id}
                      type="text"
                      placeholder={__("Wartość operacji")}
                      onChange={(e) => updateInputValue(e, "period")}
                      disabled={!currentBankHasCyclicChargesFunc(account)}
                    />
                  </td>
                )}
                {userHasChargesPermission && (
                  <td className="text-center">
                    <RbsButton
                      data-t1="charge"
                      permission={cyclicChargesPermissionWrite}
                      color="link"
                      disabled={!periodInputValue[account.id]}
                      onClick={() => {
                        setPeriodInputValue({
                          ...periodInputValue,
                          [account.id]: "",
                        });
                        chargeCyclicAccount(
                          account,
                          periodInputValue[account.id]
                        );
                      }}
                    >
                      {__("Ustaw doładowanie")}
                    </RbsButton>
                    <RbsButton
                      data-t1="charge"
                      permission={cyclicChargesPermissionWrite}
                      color="link"
                      disabled={currentPoints === "0" || !hasCyclicCharges}
                      onClick={() =>
                        deleteCharge(
                          employeeHasCyclicChargesInCurrentBank,
                          account.id
                        )
                      }
                    >
                      {__("Usuń doładowanie indywidualne")}
                    </RbsButton>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

ChargeUpsList.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      pointsBankDisplayName: PropTypes.string,
      pointsBankName: PropTypes.string,
      id: PropTypes.string,
      points: PropTypes.string,
      settlementType: PropTypes.string,
      settlementTypeLabel: PropTypes.string,
    })
  ).isRequired,
  chargeAccount: PropTypes.func.isRequired,
};
