import React, { useCallback, useMemo } from "react";
import { Button, Col, CustomInput, Label, Row } from "reactstrap";
import PropTypes, { bool, string } from "prop-types";
import { isArray } from "lodash";
import __ from "src/utils/Translations";
import uuid from "src/utils/jsHelpers/uuid";
// eslint-disable-next-line import/no-cycle
import DynamicTranslationTrigger from "src/Components/DynamicTranslation/DynamicTranslationTrigger";
import Wysiwyg from "./Wysiwyg";

const RENTABLE_GROUP = "rentable-group";
const CALCULATOR = "calculator";

const translatorTrigger = ({
  value,
  label,
  scope,
  code,
  translatable,
  translatedValue,
  uploadService,
}) => (
  <DynamicTranslationTrigger
    scope={scope}
    code={code}
    isCms={translatable.isCms}
    value={value}
    translatedValue={translatedValue}
    isTitle={translatable.isTitle || false}
    label={label}
    type="wysiwyg"
    uploadService={uploadService}
    isNotDefault={translatable.isNotDefault}
    isLocked={translatable.isLocked}
    lockReason={translatable.lockReason}
    onTranslatorSubmit={translatable.onTranslatorSubmit}
    allowDirtyHtml={translatable.allowDirtyHtml}
  />
);

const RemoveButton = ({ id, onRemoveAgreement }) => (
  <Button color="light" onClick={() => onRemoveAgreement(id)}>
    <i
      className="lnr-trash btn-icon-wrapper cursor-pointer"
      role="presentation"
    />
    {__("Usuń")}
  </Button>
);

const RequiredAgreementCheckbox = ({ id, isRequired, updateRequirement }) => (
  <>
    <CustomInput
      checked={isRequired}
      type="checkbox"
      id={`${id}/checkbox`}
      onChange={(e) => updateRequirement(id, e.target.checked)}
      value={Boolean(isRequired)}
      name={id}
      label="Zgoda jest obowiązkowa do zaznaczenia"
    />
  </>
);

const AgreementCheckboxes = ({
  id: agreementId,
  checkedIds,
  updateDependencies,
  agreementCheckboxes,
}) => (
  <>
    {agreementCheckboxes.map(({ id, label }) => {
      const isChecked = checkedIds?.includes(id);
      return (
        <CustomInput
          checked={isChecked}
          type="checkbox"
          id={`${id}/${agreementId}/checkbox`}
          onChange={(e) =>
            updateDependencies(agreementId, id, e.target.checked)
          }
          value={isChecked}
          name={id}
          label={label}
        />
      );
    })}
  </>
);

export default function InfiniteInputList({
  id,
  label,
  value,
  height,
  onChange,
  tooltip,
  afterLabel,
  errorMessage,
  validateField,
  validation,
  uploadService,
  allowDirtyHTML,
  translatable,
  translatedValue,
  configSchema,
}) {
  const activeAgreements = useMemo(
    () => (isArray(value) ? value.filter(({ isActive }) => isActive) : []),
    [value]
  );

  const agreementCheckboxes = useMemo(() => {
    if (!configSchema) {
      return [];
    }
    const rgSection =
      configSchema?.find(({ id }) => id === RENTABLE_GROUP) || {};
    const calculatorSection =
      rgSection?.children?.find(({ id }) => id === CALCULATOR) || {};
    const idPrefix = `${RENTABLE_GROUP}/${CALCULATOR}/`;
    const checkboxes = [];
    // eslint-disable-next-line
    calculatorSection?.children?.forEach(
      ({ isAgreementCheckbox, id, label }) => {
        if (isAgreementCheckbox) {
          checkboxes.push({
            id: `${idPrefix}${id}`,
            label: __(`Wyświetl gdy: ${label}`),
          });
        }
      }
    );
    return checkboxes;
  }, [JSON.stringify(configSchema)]);

  const onAddAgreementClick = useCallback(() => {
    const emptyAgreement = {
      id: uuid(),
      value: "",
      isActive: true,
      dependencies: [],
    };
    const updatedAgreements = [...value, emptyAgreement];
    onChange(id, updatedAgreements);
  }, [value, id, onChange]);

  const onRemoveAgreement = useCallback(
    (agreementId) => {
      const updatedAgreements = value.map((agreement) => {
        if (agreement.id === agreementId) {
          return {
            ...agreement,
            isActive: false,
          };
        }
        return agreement;
      });
      onChange(id, updatedAgreements);
    },
    [value, id, onChange]
  );

  const onChangeAgreement = useCallback(
    (agreementId, agreementValue) => {
      const agreements = [...value].map((agreement) => {
        if (agreement.id === agreementId) {
          return {
            ...agreement,
            value: agreementValue,
          };
        }
        return agreement;
      });
      onChange(id, agreements);
    },
    [value, id, onChange]
  );

  const updateRequirement = useCallback(
    (agreementId, isChecked) => {
      const agreements = [...value].map((agreement) => {
        if (agreement.id === agreementId) {
          return {
            ...agreement,
            isRequired: isChecked,
          };
        }
        return agreement;
      });
      onChange(id, agreements);
    },
    [value, id, onChange]
  );

  const updateDependencies = useCallback(
    (agreementId, dependencyId, isChecked) => {
      const agreements = [...(value || [])].map((agreement) => {
        if (agreement.id === agreementId) {
          let dependencies = [...(agreement.dependencies || [])];
          if (isChecked) {
            dependencies.push(dependencyId);
          } else {
            dependencies = dependencies.filter(
              (agrId) => agrId !== dependencyId
            );
          }

          return {
            ...agreement,
            dependencies,
          };
        }
        return agreement;
      });
      onChange(id, agreements);
    },
    [value, id, onChange]
  );

  return (
    <div className="input-group-omb">
      {label && (
        <Label for={id}>
          <span data-t1={`infiniteInputListLabel__${id}`}>
            {label} {tooltip}
          </span>
        </Label>
      )}
      {afterLabel && <div>{afterLabel}</div>}
      <div className="mb-2 mt-3">
        {activeAgreements.map((agreement, index) => (
          <div className="mb-2" key={agreement.id}>
            <Wysiwyg
              id={agreement.id}
              label={`${__("Zgoda")} ${index + 1}`}
              translatorTrigger={translatorTrigger({
                value: agreement.value,
                label: `Zgoda ${index + 1}`,
                code: `${translatable.code}:${agreement.id}`,
                translatable,
                translatedValue,
                uploadService,
              })}
              value={agreement.value}
              height={height}
              errorMessage={errorMessage}
              validation={validation}
              validateField={validateField}
              onChange={onChangeAgreement}
              uploadService={uploadService}
              allowDirtyHTML={allowDirtyHTML}
              belowLabelComponent={
                <>
                  <RequiredAgreementCheckbox
                    id={agreement.id}
                    isRequired={agreement.isRequired}
                    updateRequirement={updateRequirement}
                  />
                  <AgreementCheckboxes
                    id={agreement.id}
                    checkedIds={agreement.dependencies}
                    updateDependencies={updateDependencies}
                    agreementCheckboxes={agreementCheckboxes}
                  />
                </>
              }
              afterEditorComponent={
                <RemoveButton
                  id={agreement.id}
                  onRemoveAgreement={onRemoveAgreement}
                />
              }
            />
          </div>
        ))}
        <Button onClick={onAddAgreementClick}>{`+ ${__(
          "Dodaj zgodę"
        )}`}</Button>
      </div>
    </div>
  );
}

RemoveButton.propTypes = {
  id: PropTypes.string.isRequired,
  onRemoveAgreement: PropTypes.func.isRequired,
};

RequiredAgreementCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  isRequired: bool.isRequired,
  updateRequirement: PropTypes.func.isRequired,
};

AgreementCheckboxes.propTypes = {
  id: PropTypes.string.isRequired,
  checkedIds: PropTypes.arrayOf(string).isRequired,
  updateDependencies: PropTypes.func.isRequired,
  agreementCheckboxes: PropTypes.arrayOf({}).isRequired,
};

InfiniteInputList.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  afterLabel: PropTypes.node,
  tooltip: PropTypes.node,
  value: PropTypes.arrayOf({}),
  errorMessage: PropTypes.string,
  height: PropTypes.number,
  uploadService: PropTypes.string,
  validateField: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
  allowDirtyHTML: PropTypes.bool,
  translatable: PropTypes.shape({
    show: PropTypes.bool,
    isCms: PropTypes.bool,
    code: PropTypes.string,
    isTitle: PropTypes.bool,
    scope: PropTypes.string,
    isNotDefault: PropTypes.bool,
    isLocked: PropTypes.bool,
    lockReason: PropTypes.string,
    allowDirtyHtml: PropTypes.bool,
    onTranslatorSubmit: PropTypes.func,
  }),
  translatedValue: string,
  configSchema: PropTypes.arrayOf(PropTypes.shape({})),
};

InfiniteInputList.defaultProps = {
  id: "",
  label: "",
  afterLabel: null,
  tooltip: null,
  value: [],
  errorMessage: "",
  uploadService: null,
  height: null,
  validateField: () => {},
  validation: [],
  allowDirtyHTML: false,
  translatable: {},
  translatedValue: "",
  configSchema: [],
};
