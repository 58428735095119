import React, { useState, useEffect, useContext } from "react";
import { match as matchPropTypes } from "react-router-prop-types";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ContentLoading from "src/Components/Loading/contentLoading";
import Parameters from "src/Pages/Report/NewReport/ReportsList/Report/parameters";
import { reportParameters } from "src/Pages/Report/NewReport/ReportsList/mockData";
import { restApiRequest, REPORT_SERVICE } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import Subscription from "src/Pages/Report/NewReport/ReportsList/Report/Subscription";
import { dynamicNotification } from "src/utils/Notifications";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { getLanguage } from "src/utils/Languages/LanguageContext";
import Spinner from "src/Components/Spinner";
import List from "./list";
import Panel from "./panel";

const EditSubscription = ({ match }) => {
  const initialSubscribeInfo = {
    reportId: "test",
    note: "",
    subscribeMethod: {
      subscribeMethod: { label: "", value: "" },
      messageTitle: "",
      recipients: [
        { value: "m@q.pl", label: "m@q.pl" },
        { value: "t@q.pl", label: "t@.pl" },
      ],
      hiddenRecipients: [{ value: "b@q.pl", label: "bz@q.pl" }],
      format: { label: "xls", value: 1 },
      messageContent: "<b>test</b>",
      ahrList: [],
    },
    subscribeFrequency: {
      subscribeFrequency: { label: "", value: "" },
      sendDate: new Date().toISOString().slice(0, 10),
      sendStartDate: new Date().toISOString().slice(0, 10),
      sendTime: "10:00",
      dayOfWeek: [],
      daysOfMonth: { label: 1, value: 1 },
      daysSendAmount: { label: 1, value: 1 },
      daysToSend: [{ index: 1, select: { label: 1, value: 1 } }],
      sendMonthStart: { value: 1, label: __("styczeń") },
      repeatEvery: "1",
      occurrence: "",
    },
    isCreator: false,
  };

  const [fetchedParameters, setFetchedParameters] = useState([]);
  const [fetchedDynamicParameters, setFetchedDynamicParameters] = useState([]);
  const [parametersState, setParametersState] = useState([]);
  const [subscribeData, setSubscribeData] = useState(
    initialSubscribeInfo || {}
  );
  const [report, setReport] = useState({ name: "", id: "" });
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [savedSubPopup, setSavedSubPopup] = useState(false);
  const [subscribeDataToSend, setSubscribeDataToSend] = useState({});
  const [isGetBlockBtn, setIsGetBlockBtn] = useState(false);
  const [invalidParameters, setInvalidParameters] = useState([]);
  const { id } = match.params;
  const history = useHistory();
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();

  const [parametersLoaded, setParametersLoaded] = useState(false);
  const [parametersRenderCompleted, setParametersRenderCompleted] =
    useState(false);

  const [subscribeListLoaded, setSubscribeListLoaded] = useState(false);
  const [subscribeDataLoaded, setSubscribeDataLoaded] = useState(false);
  const [reportDetailsLoaded, setReportDetailsLoaded] = useState(false);
  const companyId = match.params.company;

  useEffect(() => {
    fetchSubscribeData(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {}, [subscribeDataToSend]);

  useEffect(() => {
    if (subscribeData.reportId && subscribeData.reportId !== "test") {
      fetchParameters(id, subscribeData.reportId);
      fetchDynamicParameters(subscribeData.reportId);
      fetchSubscribeList(id);
      fetchReportDetails(subscribeData.reportId);
    }
    // eslint-disable-next-line
  }, [subscribeData, id]);

  useEffect(() => {
    const pArr = [];
    fetchedParameters.map((item) => {
      let defval =
        typeof item.defaultValue !== "undefined" ? item.defaultValue : [];
      if (item.type === "Multiselect" || item.type === "Select") {
        const asd = [];
        item.defaultValue.map((d) =>
          asd.push(item.selectOptions.find((a) => a.value === d))
        );

        defval = asd;
      }
      pArr.push({
        parameterName: item.parameterName,
        values: defval,
        label: item.label,
      });
      return "";
    });
    setParametersState(pArr);
  }, [fetchedParameters]);

  useEffect(() => {
    if (invalidParameters.length === 0) {
      setIsGetBlockBtn(false);
    } else {
      setIsGetBlockBtn(true);
    }
  }, [invalidParameters]);

  const fetchParameters = (subId, reportId) => {
    fetchData(
      setFetchedParameters,
      `/subscription/parameters/${subId}/${reportId}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      reportParameters,
      __("Błąd podczas pobierania parametrów.")
    ).then(() => {
      setParametersLoaded(true);
    });
  };

  const fetchDynamicParameters = (Id) => {
    fetchData(
      setFetchedDynamicParameters,
      `/reports/parameters/dynamic/${Id}/?companyId=${companyId}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      null,
      "Błąd podczas pobierania dynamicznych parametrów"
    ).then(() => {
      setParametersLoaded(true);
    });
  };

  const fetchSubscribeData = (subId) => {
    fetchData(
      setSubscribeData,
      `/subscription/subscribeData/${subId}/${companyId}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      initialSubscribeInfo,
      __("Błąd podczas pobierania danych wysyłki.")
    ).then(() => {
      setSubscribeDataLoaded(true);
    });
  };

  const fetchSubscribeList = (subId) => {
    fetchData(
      setSubscriptionList,
      `/subscription/subscriptionList/${subId}/${companyId}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      [],
      __("Błąd podczas pobierania listy wysyłek.")
    ).then(() => {
      setSubscribeListLoaded(true);
    });
  };

  const handleSaveSubscribeClick = () => {
    subscribeDataToSend.subscribeFrequency.subscribeFrequencySelect = null;
    subscribeDataToSend.subscribeMethod.SubscribeMethodSelect = null;

    const requestBody = {
      subscriptionId: id,
      parameters: parseParameterState(parametersState),
      subscriptionData: subscribeDataToSend,
      companyId,
    };
    setIsSaving(true);
    fetchData(
      () => {
        setSavedSubPopup(true);
      },
      `/subscription/${id}`,
      REPORT_SERVICE,
      "PATCH",
      {
        body: requestBody,
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      {},
      __("Błąd podczas edycji wysyłki")
    ).then(() => {
      setIsSaving(false);
    });
  };

  const fetchReportDetails = (reportId) => {
    const setReportDetail = (data) => {
      setReport({ name: data.name, id: data.id });
    };
    fetchData(
      setReportDetail,
      `/reports/${reportId}/${companyId}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      { name: "raport", id: "asd123" },
      __("Błąd podczas pobierania danych raportu")
    ).then(() => {
      setReportDetailsLoaded(true);
    });
  };

  const checkDates = () => {
    // płacowy, grup dochodowości, zgód do świadczeń, historii doładowań
    const datefrom = parametersState.find(
      (elem) => elem.parameterName === "StartDate"
    );
    const dateto = parametersState.find(
      (elem) => elem.parameterName === "EndDate"
    );
    // banków punktów
    // cech pracowników
    const reportDate = parametersState.find(
      (elem) => elem.parameterName === "ReportDate"
    );
    // przystąpień/rezygnacji
    // kto zrezygnował ze świadczeń
    // dedykowany raport banków punktów
    // płacowy Westrock

    if (datefrom && datefrom.values[0] === null) {
      dynamicNotification(__("Uzupelnij pole 'Data od'"), "error");
      if (dateto && dateto.values[0] === null) {
        dynamicNotification(__("Uzupelnij pole 'Data do'"), "error");
      }
      return false;
    }
    if (dateto && dateto.values[0] === null) {
      dynamicNotification(__("Uzupelnij pole 'Data do'"), "error");
      return false;
    }
    return true;
  };

  const deleteSubscription = () => {
    fetchData(
      () => {
        history.push(
          getAhrUrl(`/report/subscriptions?reportName=${report.name}`, isAhr)
        );
      },
      `/subscription/${id}`,
      REPORT_SERVICE,
      "Delete",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      {},
      __("Błąd podczas usuwania wysyłki.")
    );
  };

  const closeSubPopup = () => setSavedSubPopup(false);

  const setInvalidParametersFunc = (d, push) => {
    if (push) {
      if (!invalidParameters.includes(d)) {
        const arr = [...invalidParameters];
        arr.push(d);
        setInvalidParameters(arr);
      }
    } else if (!push && invalidParameters.includes(d)) {
      let arr = [...invalidParameters];
      arr = arr.filter((item) => item !== d);
      setInvalidParameters(arr);
    }
  };

  const getDisabledParameterFunc = (nameParam) => {
    const indexParam = fetchedDynamicParameters.findIndex(
      (x) => x.parameterName === nameParam
    );
    if (indexParam >= 0) {
      return true;
    }
    return false;
  };

  const canDeleteSub = (isAhr && subscribeData.isCreator) || !isAhr;
  return (
    <ContentLoading
      message={Spinner}
      show={
        !parametersLoaded ||
        !subscribeListLoaded ||
        !subscribeDataLoaded ||
        !reportDetailsLoaded
      }
      messageStyle={{ position: "absolute", top: "20%", right: "50%" }}
      backgroundStyle={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <div>
        <Panel
          deleteSubscription={deleteSubscription}
          reportName={report.name}
          canDelete={canDeleteSub}
        />

        <Parameters
          parameters={fetchedParameters}
          parametersState={parametersState}
          setParametersState={setParametersState}
          subscription={
            <>
              {subscribeDataLoaded && (
                <Subscription
                  isEdit
                  companyId={companyId}
                  subscribeFunc={handleSaveSubscribeClick}
                  subscribeData={subscribeData}
                  reportId={subscribeData.reportId}
                  subscriptionId={id}
                  setSubscribeDataToEditSend={setSubscribeDataToSend}
                  isSaving={isSaving}
                  isGetBlockBtn={isGetBlockBtn}
                  parametersDynamic={fetchedDynamicParameters}
                  isdateOK={checkDates}
                />
              )}
            </>
          }
          setInvalidParameters={setInvalidParametersFunc}
          isEdit
          isAhr={isAhr}
          isCreator={subscribeData.isCreator}
          disabledParameterFunc={getDisabledParameterFunc}
          adHocState={{}}
          setAdHocState={() => {}}
          setParametersRenderCompleted={setParametersRenderCompleted}
        />

        <List
          subscriptionList={subscriptionList}
          isAhr={isAhr}
          companyId={companyId}
        />
        <Modal
          isOpen={savedSubPopup}
          toggle={closeSubPopup}
          unmountOnClose
          size="sm"
        >
          <ModalHeader toggle={closeSubPopup}>
            {__("Powiadomienie")}
          </ModalHeader>
          <ModalBody>{__("Pomyślnie edytowano wysyłkę")}</ModalBody>
          <ModalFooter>
            <Button color="success" onClick={closeSubPopup}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </ContentLoading>
  );
};

EditSubscription.propTypes = {
  match: matchPropTypes.isRequired,
};

export default EditSubscription;

const fetchData = async (
  updateData,
  endpoint,
  service,
  method,
  { headers, params, body },
  mockData,
  error
) => {
  if (isMockView()) {
    updateData(mockData);
  } else {
    try {
      const result = await restApiRequest(
        service,
        endpoint,
        method,
        { headers, params, body },
        {}
      );
      updateData(result);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "Pages/Report/Subscriptions/Edit/index.js - 353");
      dynamicNotification(error || e.message || "błąd", "error");
    }
  }
};

const parseParameterState = (params) => {
  const newParams = [];
  params.map((item) => newParams.push({ ...item }));

  for (let i = 0; i < newParams.length; i += 1) {
    const element = newParams[i].values;

    const arr = [];
    if (element.value) {
      arr.push(element);
    }
    if (element.length === 0) {
      arr.push({ label: "", value: "" });
    }

    for (let j = 0; j < element.length; j += 1) {
      const element2 = element[j];

      if (typeof element2.label === "undefined") {
        arr.push({ label: element2, value: element2 });
      } else {
        arr.push(element2);
      }
    }
    newParams[i].values = arr;
  }
  return newParams;
};
