import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "src/Components/Form";
import DataLoading from "src/Components/Loading/dataLoading";
import useAhrRoles from "src/utils/hooks/company/useAhrRoles";
import { EMPLOYEE_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import useEmployeeGroups from "src/utils/hooks/company/useEmployeeGroups";
import { employeeAhrRolePermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import useBillingUnits from "src/utils/hooks/company/useBillingUnits";
import useOrganizationUnits from "src/utils/hooks/company/useOrganizationUnits";
import { CHECKBOXES_BUTTONS_SELECT_DESELECT_ALL } from "src/Components/FormElements/Checkboxes";
import useCompanyPointsBanks from "src/utils/hooks/pointsBank/useCompanyPointsBanks";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function Permission({
  active,
  setIsEdited,
  companyId,
  employeeId,
  setEmployeeData,
}) {
  const [data, updateData] = useState(null);
  const [originalData, updateOriginalData] = useState(null);

  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      updateData(updatedData);
      setIsEdited(true);
    },
    [data, setIsEdited]
  );

  const onAllResourcesOrganizationUnitsChange = useCallback(
    (key, value) => {
      const values = value
        ? organizationUnits.map(({ value: optionValue }) => optionValue)
        : [];
      const updatedData = {
        ...data,
        [key]: value,
        administeredOrganizationUnits: values,
      };

      updateData(updatedData);
      setIsEdited(true);
    },
    [data, setIsEdited]
  );

  const employeeGroups = useEmployeeGroups(
    true,
    "companyId",
    companyId,
    true,
    !active || !companyId
  );
  const organizationUnits = useOrganizationUnits(
    true,
    "companyId",
    companyId,
    true,
    !active || !companyId
  );
  const billingUnits = useBillingUnits(companyId, true);
  const pointsBanks = useCompanyPointsBanks(true, companyId, !companyId);

  const allEmployeeGroups = data?.allResourcesEmployeeGroups;
  useEffect(() => {
    if (allEmployeeGroups && employeeGroups?.length) {
      updateData((previous) => ({
        ...previous,
        administeredEmployeeGroups: employeeGroups.map(({ value }) => value),
        allResourcesEmployeeGroups: false,
      }));
    }
  }, [employeeGroups, allEmployeeGroups]);

  const submit = async () => {
    try {
      if (!data.ahr) {
        data.ahrRole = null;
        data.administeredPointsBanks = [];
        data.administeredEmployeeGroups = [];
        data.administeredOrganizationUnits = [];
        data.administeredBillingUnits = [];
        data.allResourcesEmployeeGroups = false;
        data.allResourcesOrganizationUnits = false;
        data.allResourcesBillingUnits = false;
        data.allResourcesPointsBanks = false;
        updateData({ ...data });
      }

      const {
        ahr,
        ahrRole,
        administeredEmployeeGroups,
        administeredPointsBanks,
        administeredOrganizationUnits,
        administeredBillingUnits,
        allResourcesEmployeeGroups,
        allResourcesOrganizationUnits,
        allResourcesBillingUnits,
        allResourcesPointsBanks,
        ahrStatus,
        ahrResponsibilities,
        businessPhone,
        businessEmail,
      } = data;

      const roleChanged =
        originalData && ahr && originalData.ahrRole !== ahrRole;

      const response = await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/employees/${employeeId}`,
        "PATCH",
        {
          body: {
            ahr,
            ahrRole,
            ahrStatus,
            ahrResponsibilities,
            businessPhone,
            businessEmail,
            administeredEmployeeGroups,
            administeredPointsBanks,
            administeredOrganizationUnits,
            administeredBillingUnits,
            allResourcesEmployeeGroups,
            allResourcesOrganizationUnits,
            allResourcesBillingUnits,
            allResourcesPointsBanks,
          },
        },
        null
      );

      dynamicNotification(__("Pomyślnie zapisano dane operatora"));
      updateOriginalData(response);
      setEmployeeData(response);

      if (roleChanged) {
        dynamicNotification(
          __(
            "Zmiana roli będzie widoczna przy ponownym zalogowaniu użytkownika."
          ),
          "warning"
        );
      }
      setIsEdited(false);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Permission/index.js - 140"
      );
      dynamicNotification(
        e.message || __("Nie udało się zapisać uprawnień"),
        "error"
      );
    }
  };

  const ahrRoles = useAhrRoles(
    true,
    "companyId",
    companyId,
    true,
    !active || !companyId
  );
  const ahrTypeOptionList = [
    { value: "main", label: __("Główny") },
    { value: "support", label: __("Pomocniczy") },
  ];
  const ahrResponsibilitiesOptionList = [
    { value: "booking", label: __("Sprawy księgowe, finansowe, faktury") },
    { value: "issues", label: __("Sprawy bieżące - administracja") },
    { value: "offer", label: __("Oferta - ustalenia biznesowe") },
  ];

  if (!active) {
    return null;
  }

  return (
    <DataLoading
      service={EMPLOYEE_MANAGEMENT_SERVICE}
      fetchedData={data !== null}
      updateData={(updatedData) => {
        updateData(updatedData);
        updateOriginalData(updatedData);
      }}
      endpoint={`/employees/${employeeId}`}
      mockDataEndpoint="/employee/permissions/edit"
    >
      <Form
        id="employeePermission"
        data={data || {}}
        config={{
          title: "Uprawnienia",
          stickyTitle: true,
          onSubmit: submit,
          buttons: [
            {
              size: "lg",
              color: "primary",
              className: "mr-2",
              text: "Zapisz",
              type: "submit",
              id: "employeePermissionSubmit",
              permission: employeeAhrRolePermissionWrite,
            },
          ],
          defaultOnChange: onChange,
          formGroups: [
            {
              title: "Administracja",
              formElements: [
                {
                  id: "ahr",
                  dataOldSk: "admin",
                  label: "Jest administratorem firmy",
                  type: "boolean",
                },
                {
                  id: "ahrRole",
                  dataOldSk: "userGroup",
                  label: "Rola AHR:",
                  type: "select",
                  options: ahrRoles,
                  validation: data?.ahr ? ["required"] : [],
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                },
                {
                  id: "ahrStatus",
                  label: "Typ AHR",
                  type: "select",
                  options: ahrTypeOptionList,
                },
                {
                  id: "ahrResponsibilities",
                  label: "Obszary AHR",
                  type: "checkbox",
                  options: ahrResponsibilitiesOptionList,
                },
                {
                  id: "businessPhone",
                  label: "Numer tel. kom. (służbowy):",
                  type: "text",
                  tooltip: {
                    type: "info",
                    content: (
                      <>
                        {`${__("Wpisz numer telefonu komórkowego")},`} <br />
                        {__(
                          "jeśli list powitalny z loginem i hasłem do systemu"
                        )}{" "}
                        <br />
                        {`${__("będziesz wysyłać sms-em")}.`}
                      </>
                    ),
                  },
                  validation: ["phone"],
                },
                {
                  id: "businessEmail",
                  label: "E-mail (służbowy):",
                  type: "text",
                  tooltip: {
                    type: "info",
                    content: (
                      <>
                        {`${__("Wpisz email")},`} <br />
                        {__(
                          "jeśli list powitalny z loginem i hasłem do systemu"
                        )}{" "}
                        <br />
                        {`${__("będziesz wysyłać e-mailowo")}.`}
                      </>
                    ),
                  },
                  validation: ["email"],
                },
                {
                  id: "administeredEmployeeGroups",
                  label:
                    "Wybierz grupy pracowników, których jest administratorem:",
                  type: "checkbox",
                  options: employeeGroups,
                  props: {
                    buttons: CHECKBOXES_BUTTONS_SELECT_DESELECT_ALL,
                  },
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                  tooltip: {
                    type: "info",
                    content: (
                      <>
                        Administrator będzie miał dostęp tylko do pracowników{" "}
                        <br />z zaznaczonych grup.
                      </>
                    ),
                  },
                },
                {
                  id: "allResourcesOrganizationUnits",
                  label:
                    "Administruje wszystkimi jednostkami organizacyjnymi w firmie",
                  type: "boolean",
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                  onChange: onAllResourcesOrganizationUnitsChange,
                },
                {
                  id: "administeredOrganizationUnits",
                  label:
                    "Wybierz jednostki organizacyjne, których jest administratorem:",
                  type: "checkbox",
                  options: organizationUnits,
                  props: {
                    buttons: CHECKBOXES_BUTTONS_SELECT_DESELECT_ALL,
                  },
                  displayCondition: !data?.allResourcesOrganizationUnits,
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                  tooltip: {
                    type: "info",
                    content: (
                      <>
                        Administrator będzie miał dostęp tylko do pracowników{" "}
                        <br />z zaznaczonych jednostek.
                      </>
                    ),
                  },
                },
                {
                  id: "allResourcesBillingUnits",
                  label:
                    "Administruje wszystkimi jednostkami rozliczeniowymi w firmie",
                  type: "boolean",
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                },
                {
                  id: "administeredBillingUnits",
                  label:
                    "Wybierz jednostki rozliczeniowe, których jest administratorem:",
                  type: "checkbox",
                  options: billingUnits,
                  props: {
                    buttons: CHECKBOXES_BUTTONS_SELECT_DESELECT_ALL,
                  },
                  displayCondition: !data?.allResourcesBillingUnits,
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                  tooltip: {
                    type: "info",
                    content:
                      "Administrator będzie miał dostęp tylko do zaznaczonych jednostek",
                  },
                },
                {
                  id: "allResourcesPointsBanks",
                  label: "Administruje wszystkimi bankami punktów w firmie",
                  type: "boolean",
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                },
                {
                  id: "administeredPointsBanks",
                  dataOldSk: "ahrPointsBanks",
                  label: "Wybierz banki punktów, których jest administratorem:",
                  type: "checkbox",
                  props: {
                    buttons: CHECKBOXES_BUTTONS_SELECT_DESELECT_ALL,
                  },
                  options: pointsBanks,
                  displayCondition: !data?.allResourcesPointsBanks,
                  depends: {
                    value: true,
                    field: "ahr",
                  },
                  tooltip: {
                    type: "info",
                    content: (
                      <>
                        Administrator będzie miał dostęp tylko do <br />
                        zaznaczonych banków punktów.
                      </>
                    ),
                  },
                },
              ],
            },
          ],
        }}
      />
    </DataLoading>
  );
}

Permission.propTypes = {
  active: PropTypes.bool,
  setIsEdited: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  employeeId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  setEmployeeData: PropTypes.func.isRequired,
};
Permission.defaultProps = {
  active: false,
  setIsEdited: () => null,
};
