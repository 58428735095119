import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";
import __ from "src/utils/Translations";
import Form from "src/Components/Form/index";
import PageTitle from "src/Layout/AppMain/PageTitle";
import DataLoading from "src/Components/Loading/dataLoading";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { getCompanyBaseBreadcrumbs } from "src/Pages/Company/routerHelper";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";

export default function TotalRewardCategoriesForm({
  categoryId,
  pathPrefix,
  setIsEdited,
}) {
  const history = useHistory();
  const isNew = categoryId === "-1";
  const companyId = useCompanyId();

  const [data, setData] = useState({
    status: true,
  });
  const [loaded, setLoaded] = useState(isNew);
  const companyName = useCompanyName();

  const onChange = (key, value) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[key] = value;
      return updatedData;
    });
    setIsEdited(true);
  };

  const onSubmit = async () => {
    try {
      const response = await restApiRequest(
        API_TOTALREWARD_SERVICE,
        `/omb/perk-categories${isNew ? "" : `/${categoryId}`}`,
        isNew ? "POST" : "PUT",
        {
          body: {
            ...data,
          },
          params: {
            tenant: companyId,
          },
        }
      );
      setIsEdited(false);
      dynamicNotification(__("Pomyślnie zapisano dane"));
      history.push(`${pathPrefix}/total-reward/categories`);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/TotalReward/Categories/Edit/index.js - 44"
      );
      dynamicNotification(
        e.message || __("Nie udało się zapisać danych"),
        "error"
      );
    }
  };

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={
          isNew
            ? __(`Tworzenie kategorii w firmie {0}`, [companyName])
            : __(`Edycja kategorii (ID: {0}) w firmie {1}`, [
                categoryId,
                companyName,
              ])
        }
        breadcrumbsHeading={
          isNew
            ? __("Tworzenie kategorii")
            : `${__("Edycja kategorii")} (ID: ${categoryId})`
        }
        breadcrumbs={[
          ...getCompanyBaseBreadcrumbs(companyId, companyName),
          {
            title: "Total Reward Statement",
            link: `${pathPrefix}/total-reward`,
          },
          {
            title: __("Zarządzanie kategoriami"),
            link: `${pathPrefix}/total-reward/categories`,
          },
        ]}
        pushToHistory={!isNew}
      />
      <DataLoading
        service={API_TOTALREWARD_SERVICE}
        endpoint={`/omb/perk-categories/${categoryId}?tenant=${companyId}`}
        fetchedData={loaded}
        updateData={(res) => {
          setData({
            id: res.id,
            status: res.status,
            name: res.name,
            description: res.description,
            sort: res.sort,
          });
          setLoaded(true);
        }}
        isNew={isNew}
      >
        <Form
          id="totalRewardCategoryForm"
          data={data || {}}
          config={{
            title: isNew ? __("Tworzenie kategorii") : __("Edycja kategorii"),
            stickyTitle: true,
            defaultOnChange: onChange,
            onSubmit,
            buttons: [
              {
                size: "lg",
                color: "success",
                className: "mr-2",
                text: "Zapisz",
                type: "submit",
                id: "configurationSubmit",
              },
            ],
            formGroups: [
              {
                formElements: [
                  {
                    type: "boolean",
                    id: "status",
                    label: "Aktywny",
                  },
                  {
                    type: "text",
                    id: "name",
                    label: "Nazwa",
                    validation: ["required"],
                    translatable: isNew
                      ? null
                      : {
                          show: true,
                          code: `totalRewardStatement:perkCategoryObject:${categoryId}:name`,
                          isCms: true,
                        },
                  },
                  {
                    type: "wysiwyg",
                    id: "description",
                    label: "Opis",
                    validation: ["required"],
                    translatable: isNew
                      ? null
                      : {
                          show: true,
                          code: `totalRewardStatement:perkCategoryObject:${categoryId}:description`,
                          isCms: true,
                        },
                  },
                  {
                    type: "number",
                    id: "sort",
                    label: "Kolejność wyświetlania",
                    validation: [
                      "required",
                      { method: "greaterEqualThan", args: [0] },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      </DataLoading>
    </CSSTransitionGroup>
  );
}

TotalRewardCategoriesForm.propTypes = {
  categoryId: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
  setIsEdited: PropTypes.func,
};

TotalRewardCategoriesForm.defaultProps = {
  setIsEdited: () => {},
};
