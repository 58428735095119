// eslint-disable-next-line import/prefer-default-export
export const getMinDate = (buffer) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + buffer);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const parseDateForApi = (date) => {
  const parsedDate = new Date(date);
  parsedDate.setTime(
    parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000
  );
  return parsedDate;
};
/*
 * Format: Y-m-d H:i:s
 */
export const getFormatDateFromValue = (value, format) => {
  const p = (s) => `00${s}`.slice(-2);

  const date = new Date(value);
  return format
    ? format.replace(/(y|Y|m|d|H|i|s)/g, (m) => {
        switch (m) {
          case "y":
            return date.getFullYear();
          case "Y":
            return date.getFullYear();
          case "m":
            return p(date.getMonth() + 1);
          case "d":
            return p(date.getDate());
          case "H":
            return p(date.getHours());
          case "i":
            return p(date.getMinutes());
          case "s":
            return p(date.getSeconds());
          default:
            return m;
        }
      })
    : date.toString();
};
