import __ from "src/utils/Translations";

const getSwitcherLabel = ({ companyId = null, employeeGroupId = null }) => {
  if (employeeGroupId) {
    return __("Użyj wartości z domyślnych ustawień firmy");
  }
  if (companyId) {
    return __("Użyj wartości nadrzędnych");
  }

  return __("Użyj wartości z domyślnych ustawień programistycznych");
};

export default getSwitcherLabel;
