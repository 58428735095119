const updateDataTableQuery = ({
  requestFilters,
  requestPage,
  requestPageSize,
  requestSort,
  defaultPageSize,
  changeQuery,
}) => {
  const params = {};
  requestFilters.forEach(({ id: filterId, value }) => {
    params[filterId] = value;
  });
  if (requestPageSize !== defaultPageSize) {
    params.pageSize = requestPageSize;
  }
  if (requestPage !== 1) {
    params.page = requestPage;
  }
  if (requestSort) {
    params.orderKey = requestSort.key;
    params.orderDir = requestSort.value;
  }
  changeQuery(params);
};

export default updateDataTableQuery;
