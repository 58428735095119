import { prepareFilters } from "./Filters/utils";

export const PENDING = "PENDING";
export const ACCEPTED = "ACCEPTED";
export const CANCELED = "CANCELED";
export const statusOptions = [
  {
    value: PENDING,
    label: "PENDING",
  },
  {
    value: ACCEPTED,
    label: "ACCEPTED",
  },
  { value: CANCELED, label: "CANCELED" },
];

export const prepareParams = (currentPage, pageSize, filters) => ({
  page: currentPage - 1,
  size: pageSize,
  ...prepareFilters(filters, true),
});
