import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { FormGroup, CustomInput } from "reactstrap";
import { COMPANY_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";

export const bankListInputId = "email-templates/email-farewell/bank-list";

const mapPointBanksToCheckboxes = (pointBanks) =>
  pointBanks.map((pointBank) => ({
    label: pointBank.fullName,
    value: pointBank.pointsBankId,
  }));

export default function BankListSelector({
  input,
  onChange,
  companyId,
  value,
  disabled,
}) {
  const [pointBanks, setPointBanks] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const result = await restApiRequest(
        COMPANY_MANAGEMENT_SERVICE,
        `/points-banks/owner?pointsBankOwnerId=${companyId}`,
        "GET",
        {}
      );
      setPointBanks(mapPointBanksToCheckboxes(result));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "/Components/Form/BankListSelector.js - 36");
      dynamicNotification(
        e.message || __("Nie udało się pobrać banków punktów"),
        "error"
      );
    }
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCheck = (e) => {
    const valueArray = value.split(",").filter((substring) => substring !== "");
    const newArray = e.target.checked
      ? [...valueArray, e.target.id]
      : valueArray.filter((val) => val !== e.target.id);
    onChange(bankListInputId, newArray.join(","));
  };

  if (!pointBanks.length) return <strong>{__("Brak banków punktów")}</strong>;

  return (
    <>
      <FormGroup>
        <div className="twoColumnsGridContainer">
          {pointBanks.map((pointBank) => {
            const { value: bankValue, label } = pointBank;
            return (
              <CustomInput
                inline
                checked={value.includes(bankValue)}
                type="checkbox"
                id={bankValue}
                label={label}
                onChange={handleCheck}
                disabled={disabled}
              />
            );
          })}
        </div>
      </FormGroup>
      <div className="notDisplayed">{input}</div>
    </>
  );
}

BankListSelector.propTypes = {
  input: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

BankListSelector.defaultProps = {};
