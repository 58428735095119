import { useEffect, useState } from "react";
import { CONFIGURATION_SERVICE, restApiRequest } from "src/utils/Api";
import mockSchema from "src/Pages/Administration/Configuration/mockSchema";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import {
  COMPANY_SCOPE,
  DEFAULT_SCOPE,
  EMPLOYEE_GROUP_SCOPE,
} from "src/Pages/Administration/Configuration/consts";
// eslint-disable-next-line import/no-cycle
import { filterSchema } from "src/Pages/Administration/Configuration/utils";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const useSchema = (scope, excludeKeys) => {
  const [loadingSchema, setLoadingSchema] = useState(true);
  const [schema, setSchema] = useState([]);
  const [filteredSchema, setFilteredSchema] = useState([]);

  useEffect(() => {
    setLoadingSchema(true);
    restApiRequest(
      CONFIGURATION_SERVICE,
      "/config-schema",
      "GET",
      {},
      mockSchema
    )
      .then((res) => {
        setSchema(res);
        setLoadingSchema(false);
      })
      .catch((e) => {
        newRelicErrorReport(
          e,
          "Pages/Administration/Configuration/utils/useSchema.js - 33"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać schematu konfiguracji"),
          "error"
        );
      });
  }, [setSchema]);

  const scopeCode = getScopeCode(scope);
  useEffect(() => {
    setFilteredSchema(
      filterSchema(schema, scopeCode).filter(
        ({ id }) => !(excludeKeys?.[scopeCode] || []).includes(id)
      )
    );
  }, [schema, setFilteredSchema, scopeCode, excludeKeys]);

  return { schema, filteredSchema, loadingSchema };
};

const getScopeCode = ({ companyId, employeeGroupId }) => {
  if (companyId && employeeGroupId) {
    return EMPLOYEE_GROUP_SCOPE;
  }
  if (companyId) {
    return COMPANY_SCOPE;
  }
  return DEFAULT_SCOPE;
};

export default useSchema;
