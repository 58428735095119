import __ from "src/utils/Translations";
import isPathActive from "src/utils/jsHelpers/isPathActive";

const getActiveId = (content, pathname) => {
  for (let i = 0; i < content.length; i += 1) {
    const item = content[i];
    if (isPathActive(item?.to, pathname, item.isAnchor)) {
      return item.id;
    }
    if (item.content) {
      const subItem = item.content.find((el) =>
        isPathActive(el?.to, pathname, true)
      );
      if (subItem) {
        return subItem.id;
      }
    }
  }
  return null;
};

export default getActiveId;
