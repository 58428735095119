import React, { useState, useContext, useCallback } from "react";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import Form from "src/Components/Form";
import { SSO_SERVICE, restApiRequest } from "src/utils/Api";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function Edit() {
  const [data, updateData] = useState({});
  const { userInfo } = useContext(RbsContext);
  const userId = userInfo.getId();

  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      updateData(updatedData);
    },
    [data]
  );

  const submit = async () => {
    try {
      await restApiRequest(
        SSO_SERVICE,
        "/change-password",
        "POST",
        {
          body: {
            newPassword: data.newPassword,
            userId,
            currentPassword: data.currentPassword,
          },
        },
        data
      );
      dynamicNotification(__("Pomyślnie zmieniono hasło"));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "Pages/Password/Edit/edit.js - 41");
      dynamicNotification(
        e.message || __("Nie udało się zmienić hasła"),
        "error"
      );
    }
  };

  return (
    <>
      <Form
        id="passwordForm"
        data={data}
        config={{
          defaultOnChange: onChange,
          title: "Zmiana hasła",
          buttons: [
            {
              size: "lg",
              color: "success",
              className: "mr-2",
              text: "Zapisz",
              type: "submit",
            },
          ],
          onSubmit: submit,
          formGroups: [
            {
              formElements: [
                {
                  id: "currentPassword",
                  label: "Twoje obecne hasło:",
                  type: "password",
                  validation: ["required"],
                },
                {
                  id: "newPassword",
                  label: "Nowe hasło:",
                  type: "password",
                  validation: ["required", "password"],
                },
                {
                  id: "newPasswordRepeat",
                  label: "Powtórz nowe hasło:",
                  type: "password",
                  validation: [
                    "required",
                    {
                      method: "mustBeEqual",
                      args: [data.newPassword, "Hasła nie są takie same"],
                    },
                  ],
                  props: {
                    previewToggle: false,
                  },
                },
              ],
            },
          ],
        }}
      />
    </>
  );
}
