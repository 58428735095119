import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect, useContext } from "react";
// eslint-disable-next-line import/no-cycle
import Form from "src/Components/Form";
import { restApiRequest, TRANSLATOR_SERVICE } from "src/utils/Api";
// eslint-disable-next-line import/no-cycle
import { SIMPLE_VALUE_TYPE } from "src/Pages/Translation/Simple";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { translationTranslatePermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import { hasAccessTo } from "src/utils/RoleBasedSecurity/filters";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import Popup from "src/Components/Popup/popup";
import LanguagesTabs from "./LanguageTabs";

export default function DynamicTranslationPopup({ close, scope, value, type }) {
  const { userInfo } = useContext(RbsContext);
  const [data, updateData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currLanguage, setCurrLanguage] = useState("en");
  const [isEdited, setIsEdited] = useState(false);

  const onChange = useCallback(
    (key, newValue) => {
      const updatedData = [...data];
      setIsEdited(true);
      const langData = updatedData.find(
        (el) => el.phrase === value && el.language === currLanguage
      );
      if (langData) {
        langData[key] = newValue;
        updateData(updatedData);
      }
    },
    [data, currLanguage, value]
  );

  useEffect(() => {
    fetchTranslationData();
    // eslint-disable-next-line
  }, []);

  const fetchTranslationData = async () => {
    if (hasAccessTo(userInfo, translationTranslatePermissionWrite)) {
      await restApiRequest(
        TRANSLATOR_SERVICE,
        "/translations",
        "POST",
        {
          body: {
            scope,
            phrase: encodeURIComponent(value),
            type: SIMPLE_VALUE_TYPE,
          },
        },
        null,
        true
      );
    }

    const result = await restApiRequest(
      TRANSLATOR_SERVICE,
      "/translations",
      "GET",
      {
        params: {
          scope,
          phrase: encodeURIComponent(value),
          itemsPerPage: 10000,
        },
      },
      mockSimpleValues,
      true
    );

    updateData(result);
    setOriginalData(result.map((item) => ({ ...item })));
    setLoading(false);
  };

  const save = async () => {
    try {
      setLoading(true);
      const promises = [];
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].translation !== originalData[i].translation) {
          const promise = restApiRequest(
            TRANSLATOR_SERVICE,
            `/translations/${data[i].id}`,
            "PATCH",
            {
              body: {
                translation: data[i].translation,
              },
            },
            null
          );
          promises.push(promise);
        }
      }
      await Promise.all(promises);
      close();
    } catch (e) {
      dynamicNotification(
        e.message || __("Nie udało się zapisać tłumaczeń"),
        "error"
      );
      newRelicErrorReport(
        e,
        "Components/DynamicTranslation/DynamicTranslationPopup.js - 110"
      );
      setLoading(false);
    }
  };

  return (
    <Popup
      id="translationFormPopup"
      isOpen
      toggle={() => close()}
      isEdited={isEdited}
      unmountOnClose
      size="lg"
    >
      <Form
        id="translationForm"
        data={data.find(
          (el) => el.phrase === value && el.language === currLanguage
        )}
        config={{
          defaultOnChange: onChange,
          isInPopup: true,
          buttons: [
            {
              text: "Zapisz",
              onClick: save,
              permission: translationTranslatePermissionWrite,
              disabled: loading,
            },
          ],
          title: `${value} (${scope})`,
          translateTitle: false,
          formGroups: [
            {
              formElements: [
                {
                  component: (
                    <LanguagesTabs
                      key="languages_tab"
                      setCurrLanguage={setCurrLanguage}
                      currLanguage={currLanguage}
                    />
                  ),
                },
                {
                  id: "phrase",
                  label: "Oryginalna wartość",
                  type,
                  props: {
                    disabled: true,
                  },
                },
                {
                  id: "translation",
                  label: "Tłumaczenie",
                  type,
                },
              ],
            },
          ],
        }}
      />
    </Popup>
  );
}

DynamicTranslationPopup.propTypes = {
  close: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DynamicTranslationPopup.defaultProps = {
  value: "",
};

const mockSimpleValues = [
  {
    id: 4,
    scope: "company_company_city",
    code: "company_company_city_6c76933666a8",
    phrase: "Firma",
    language: "en",
    type: 2,
  },
  {
    id: 5,
    scope: "company_company_city",
    code: "company_company_city_6c76933666a8",
    phrase: "Firma",
    language: "UA",
    type: 2,
  },
  {
    id: 6,
    scope: "company_company_city",
    code: "company_company_city_6c76933666a8",
    phrase: "Firma",
    language: "FR",
    type: 2,
  },
];
