import PropTypes from "prop-types";
import React, { useState } from "react";
import { Alert } from "reactstrap";
import Form from "src/Components/Form";
import Popup from "src/Components/Popup/popup";
import { fileToBase64 } from "src/utils/Parsers/fileToBase64";
import { restApiRequest, TOURISM_SERVICE } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import parseDefaultImportResult from "src/utils/import/parseDefaultImportResult";
import ImportLegend from "src/Components/ImportLegend";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function ImportForm({ close, isOpen }) {
  const [data, updateData] = useState({});
  const [importMessages, setImportMessages] = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  const instruction = (
    <>
      <ul>
        <li>
          Plik wymaga podania nagłówka z nazwami pól, które są aktualizowane.
        </li>
      </ul>
    </>
  );

  const importCSV = async () => {
    try {
      const formData = { ...data };
      const method = "POST";
      const path = "/tourism-objects/import";
      let fileData = {};
      if (formData.file && formData.file[0]) {
        fileData = {
          file: await fileToBase64(formData.file[0]),
          fileName: formData.file[0].name,
        };
      }
      const response = await restApiRequest(
        TOURISM_SERVICE,
        path,
        method,
        {
          body: {
            ...formData,
            ...fileData,
          },
        },
        data
      );
      const { success, processed, errors } = parseDefaultImportResult(response);
      if (success) {
        dynamicNotification(
          __("Pomyślnie zaimportowano {0} obiektów", [processed])
        );
      } else {
        setImportMessages(errors);
        dynamicNotification(
          __("Zaimportowano z błędami", [processed]),
          "warning"
        );
      }
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "/Pages/Tourism/listOfObjects/importForm.js = 67");
      dynamicNotification(
        e.message || __("Nie udało się zapisać pliku CSV"),
        "error"
      );
    }
  };

  const onChange = (key, value) => {
    const updatedData = { ...data };
    updatedData[key] = value;
    updateData(updatedData);
    setImportMessages([]);
    setIsEdited(true);
  };

  const fields = [
    {
      component: instruction ? (
        <Alert color="warning">{instruction}</Alert>
      ) : (
        <div />
      ),
    },
    {
      id: "file",
      type: "file",
      label: "Plik CSV",
    },
    {
      type: "button",
      label: "Importuj",
      id: "import",
      onChange: importCSV,
    },
    {
      component: (
        <div className="mt-3">
          {importMessages && importMessages.length ? (
            <Alert color="danger">
              {__("Napotkane błędy podczas importu")}
              <ul className="mb-0 pl-3">
                {importMessages.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            </Alert>
          ) : null}
          <ImportLegend legend={legend} />
        </div>
      ),
    },
  ];

  return (
    <Popup
      id="importObjectPopup"
      isOpen={isOpen}
      toggle={() => close()}
      unmountOnClose
      className="modal-lg"
      isEdited={isEdited}
    >
      <Form
        data={data}
        id="importObjectForm"
        config={{
          defaultOnChange: onChange,
          noCards: true,
          isInPopup: true,
          title: "Import obiektów turystycznych",
          buttons: [
            {
              size: "lg",
              color: "light",
              className: "mr-2",
              text: "Zamknij",
              type: "button",
              onClick: close,
            },
          ],
          onSubmit: () => {},
          formGroups: [
            {
              formElements: fields,
            },
          ],
        }}
      />
    </Popup>
  );
}

ImportForm.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const legend = [
  ["id", "Id obiektu *"],
  ["guardianId", "Opiekun (ID Operatora MB)"],
  ["status", "Status *"],
  ["name", "Pełna nazwa obiektu *"],
  ["marketingName", "Nazwa marketingowa"],
  ["objectType", "Typ obiektu"],
  ["travelPassAllowed", "Pozwalaj na TravelPass"],
  ["objectType", "Typ obiektu"],
  ["travelPassAllowed", "Pozwalaj na TravelPass"],
  [
    "hotelCategory",
    "Standard obiektu (dopuszczone wartości: 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6)",
  ],
  ["phone", "Telefon (dowolny format numeru telefonu)"],
  ["phone2", "Telefon 2 (dowolny format numeru telefonu)"],
  ["email", "Adres e-mail (format xx@xx.xx)"],
  ["countryCode", "Dwuliterowy kod państwa (Polska - PL)"],
  ["street", "Ulica"],
  ["city", "Miasto"],
  ["postalCode", "Kod pocztowy (dowolny format)"],
  ["emailForNotifications", "Adres e-mail do powiadomień (format xx@xx.xx)"],
  [
    "phoneForNotifications",
    "Telefon do powiadomień (dowolny format numeru telefonu)",
  ],
  ["statement", "Komunikat"],
  ["shortDescription", "Krótki opis"],
  ["description", "Opis"],
  ["objectRules", "Zasady obiektu"],
  ["useDefaultFulfilmentRules", "Używaj domyślnych zasad obiektu"],
  ["customFulfilmentRules", "Zasady realizacji"],
  ["searchEngineKeywords", "Słowa kluczowe dla języka wyszukiwania"],
  ["checkInFrom", "Zameldowanie od w formacie HH:MMs"],
  ["checkInTo", "Zameldowanie do w formacie HH:MM"],
  ["checkOutFrom", "Wymeldowanie od w formacie HH:MM"],
  ["checkOutTo", "Wymeldowanie do w formacie HH:MM"],
];
