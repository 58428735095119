import PropTypes from "prop-types";
import React from "react";

import __ from "src/utils/Translations";
import ButtonsList from "src/Components/ButtonsList";
import { ExportContext } from "src/Components/DataTableControlled/context";
import ExportButton from "./ExportButton";
import AhrExportButton from "./AhrExportButton";

const ExportButtons = ({
  id,
  buttons,
  exportContext,
  exportContextAhr,
  massActionSelect,
  count,
  totalCount,
  pageSize,
  page,
  filters,
  sort,
}) => (
  <div className="listing-btn-container m-2 text-right">
    {exportContextAhr && massActionSelect && (
      <AhrExportButton
        massActionSelect={massActionSelect}
        noResults={massActionSelect.included.length === 0}
        service={exportContextAhr.getService()}
        path={exportContextAhr.getPath()}
        permissions={exportContext.getPermission()}
        handleAdditionalFilters={exportContext.handleAdditionalFilters}
        fileName={exportContextAhr.getFileName()}
      />
    )}
    {exportContext ? (
      <ExportButton
        noResults={count === 0}
        service={exportContext.getService()}
        path={exportContext.getPath()}
        additionalPath={exportContext.getAdditionalPath()}
        getIsAdditionalPathVisible={exportContext.getIsAdditionalPathVisible}
        fileName={exportContext.getFileName()}
        permissions={exportContext.getPermission()}
        handleAdditionalFilters={exportContext.handleAdditionalFilters}
        count={count}
        totalCount={totalCount}
        pageSize={pageSize}
        page={page}
        filters={filters}
        sort={sort}
        buttonTitle={exportContext.getButtonTitle()}
        customDropdownItems={exportContext.getCustomDropdownItems()}
      />
    ) : null}
    <ButtonsList dataT1={id} buttons={buttons} />
  </div>
);

ExportButtons.propTypes = {
  id: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.string,
    })
  ).isRequired,
  exportContext: PropTypes.instanceOf(ExportContext).isRequired,
  exportContextAhr: PropTypes.instanceOf(ExportContext).isRequired,
  massActionSelect: {
    included: PropTypes.arrayOf(PropTypes.string),
    excluded: PropTypes.arrayOf(PropTypes.string),
    includedSelectAll: PropTypes.arrayOf(PropTypes.string),
  },
  count: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sort: PropTypes.shape({}).isRequired,
};

ExportButtons.defaultProps = {
  massActionSelect: null,
};

export default ExportButtons;
