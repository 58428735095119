/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody } from "reactstrap";
import DataTable from "src/Components/DataTable";
import DataLoading from "src/Components/Loading/dataLoading";
import { API_GATEWAY_SERVICE, restApiPrepaidCardsRequest } from "src/utils/Api";
import __ from "src/utils/Translations";
import FormTitle from "src/Components/Form/FormTitle";
import { dynamicNotification } from "src/utils/Notifications";
import { isNil } from "lodash";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import OrdersHistory from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/PrepaidCardsTab/PrepaidCardsCancellation/Tables/ordersHistory";
import scrollTo from "src/utils/jsHelpers/scrollTo";
import FormPopup from "./formPopup";

export default function PrepaidCardsTab({ employeeId }) {
  const [tableData, setTableData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [clickedCardId, setClickedCardId] = useState(null);
  const closeForm = () => setOpenPopup(false);

  const fetchData = async () => {
    try {
      const endpoint = `/admin-prepaid-cards/employee/${employeeId}`;
      const response = await restApiPrepaidCardsRequest(
        API_GATEWAY_SERVICE,
        endpoint,
        "GET",
        {}
      );
      if (response.status !== 401) {
        const data = await response.json();
        if (!isNil(data))
          data.employeeCards.sort((a, b) => {
            const x = a.status.toLowerCase();
            const y = b.status.toLowerCase();
            return x < y ? -1 : 0;
          });
        setTableData(data.employeeCards);
      }
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/PrepaidCardsTab/index.js - 43"
      );
      dynamicNotification(
        e.message ||
          __("Nie udało się pobrać listy kart przedpłaconych pracownika."),
        "error"
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [openPopup]);

  const scrollToOrdersHistoryTable = () => {
    setTimeout(() => {
      const openedTable = document.querySelector('[data-t1="orders_history"]');
      if (openedTable) {
        scrollTo(openedTable, 50);
      }
    }, 100);
  };

  const onRowClick = (state, rowInfo) => ({
    onClick: () => {
      const { id, status } = rowInfo.original;
      setClickedCardId(id);
      scrollToOrdersHistoryTable();
    },
  });

  return (
    <Card id="prepaid_cards_list">
      <FormTitle
        buttons={[
          <Button
            data-t1="add-new-prepaid-card"
            key="add-new-prepaid-card"
            color="primary"
            onClick={() => setOpenPopup(true)}
            // disabled={isWaitingData}
            disabled={!(tableData && tableData.length)}
          >
            {"+ "}
            {__("Dodaj kartę")}
          </Button>,
        ]}
      />
      <CardBody>
        <DataLoading
          service={API_GATEWAY_SERVICE}
          endpoint={`/admin-prepaid-cards/employee/${employeeId}`}
          fetchedData={tableData !== null}
          updateData={(updatedData) => setTableData(updatedData)}
        >
          <DataTable
            className="cursor-pointer"
            id="prepaidCardsList"
            key="prepaid_cards_list"
            data={tableData || []}
            noCards
            getTrProps={onRowClick}
            sortable={false}
            columns={[
              {
                Header: "#",
                Cell: ({ index }) => index + 1,
              },
              {
                Header: __("Status karty"),
                accessor: "status",
              },
              {
                Header: __("Zamaskowany nr karty"),
                accessor: "cardNumber",
              },
              {
                Header: __("Numer rachunku bankowego"),
                accessor: "accountNumber",
              },
              {
                Header: __("Data upływu możliwości zasilenia"),
                accessor: "expirationDate",
              },
            ]}
          />
          {clickedCardId && (
            <OrdersHistory
              cardId={clickedCardId}
              setClickedCardId={setClickedCardId}
              refreshData={fetchData}
            />
          )}
        </DataLoading>
        {openPopup ? (
          <FormPopup
            close={closeForm}
            isOpen={Boolean(openPopup)}
            employeeId={employeeId}
          />
        ) : null}
      </CardBody>
    </Card>
  );
}

PrepaidCardsTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
