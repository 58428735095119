/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Label } from "reactstrap";
import TagsInput from "react-tagsinput";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import "src/assets/components/tagsInput/tagsInput.scss";

const TagInput = ({
  id,
  label,
  tooltip,
  invalid,
  value,
  errorMessage,
  onChange,
  inputProps,
  ...restProps
}) => (
  <FormGroup>
    <Label data-t1={`${id}Label`} for={id}>
      {label}
      &nbsp;
      {tooltip}
    </Label>
    <TagsInput
      name={id}
      value={value || []}
      onChange={onChange}
      inputProps={inputProps}
      {...restProps}
    />
    <ValidationMessage message={errorMessage} />
  </FormGroup>
);

export default TagInput;

TagInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltip: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool,
  inputProps: PropTypes.object,
};

TagInput.defaultProps = {
  id: "",
  label: "",
  tooltip: "",
  value: [],
  errorMessage: null,
  invalid: false,
  inputProps: {},
};
