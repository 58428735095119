import { useContext, useEffect, useState } from "react";
// eslint-disable-next-line import/no-cycle
import { COMPANY_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { SetSessionValue, GetSessionValue } from "./sessionHelper";

export const SELECTED_COMPANY_STORAGE_KEY = "selectedCompany";

export function GetCurrentCompany() {
  const { userInfo } = useContext(RbsContext);
  if (userInfo.isAhr()) {
    return userInfo.getCompanyId();
  }

  const company = GetCurrentCompanyFromSession();
  return company ? company.id : "";
}

export function GetCurrentCompanyName() {
  const company = GetCurrentCompanyFromSession();
  return company ? company.name : "";
}

export function GetCurrentCompanyFromSession() {
  const savedCompanyInfo = GetSessionValue(SELECTED_COMPANY_STORAGE_KEY);
  return savedCompanyInfo;
}

export function SetCurrentCompany(id, name) {
  const company = {
    id,
    name,
  };
  SetSessionValue(SELECTED_COMPANY_STORAGE_KEY, company);
}

export const GetCompanyName = (companyId) => {
  const [companyName, setCompanyName] = useState(GetCurrentCompanyName());

  useEffect(() => {
    const fetchData = async () => {
      const response = await restApiRequest(
        COMPANY_MANAGEMENT_SERVICE,
        `/companies/${companyId}`,
        "GET",
        {},
        []
      );
      const name = response?.shortName || "";
      setCompanyName(name);
      SetCurrentCompany(companyId, name);
    };
    if (!companyName && companyId) {
      fetchData().catch((e) => {
        newRelicErrorReport(
          e,
          "src/Pages/Report/helpers/companyHelper.js - 39"
        );
      });
    }
  }, [companyName, companyId]);

  return companyName;
};

export default GetCurrentCompany;
