import __ from "src/utils/Translations";

/**
 * translates values for provided keys
 * @param {object} object - object containing fields to translate
 * @param {string[]} keys - array of keys for fields to translate
 */
const translateFields = (object, keys) => {
  const newObject = { ...object };
  keys.forEach((key) => {
    if (newObject[key]) {
      newObject[key] = __(newObject[key]);
    }
  });

  return newObject;
};

export default translateFields;
