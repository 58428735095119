/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React from "react";

const IntIdFilter = ({ filter, onChange, column }) => (
  <input
    data-t1="gridFilter"
    data-t2={column.id}
    type="text"
    style={{
      width: "100%",
    }}
    placeholder={column.Placeholder}
    value={filter ? filter.value : ""}
    onChange={(event) => onChange(event.target.value.replace(/\D/g, ""))}
  />
);

IntIdFilter.propTypes = {
  filter: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
    Placeholder: PropTypes.string,
  }).isRequired,
};

IntIdFilter.defaultProps = {
  filter: null,
};

export default IntIdFilter;
