import __ from "src/utils/Translations";

const getResultsFromChildren = (items, value, resultHandler = []) => {
  let result = resultHandler;
  items.forEach(({ to, label, content, href }) => {
    if ((to || href) && __(label).toLowerCase().includes(value.toLowerCase())) {
      result.push({ to: to || href, label, external: Boolean(href) });
    }
    if (content && content.length) {
      result = getResultsFromChildren(content, value, resultHandler);
    }
  });
  return result;
};

export default getResultsFromChildren;
