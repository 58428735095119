/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Input, InputGroup } from "reactstrap";
import __ from "src/utils/Translations";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { hasAccessTo } from "src/utils/RoleBasedSecurity/filters";

export default function MassActionsSelect({
  massActions,
  filters,
  excluded,
  included,
  count,
}) {
  const [error, setError] = useState("");
  const { userInfo } = useContext(RbsContext);
  useEffect(() => {
    if (count > 0) {
      setError("");
    }
  }, [count, setError]);

  const onChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (value) {
        if (count > 0) {
          massActions
            .find(({ id }) => id === value)
            .action(included, excluded, filters, count);
        } else {
          setError(__("Nie wybrałeś żadnych obiektów"));
        }
      }
    },
    [count, massActions, included, excluded, filters, setError]
  );

  return (
    <InputGroup className="mb-2 mt-2">
      <Input
        type="select"
        name="select"
        data-t1="massActions"
        value=""
        className={`${error ? " is-invalid-select col-sm-4" : "col-sm-4"}`}
        onChange={onChange}
      >
        {[
          {
            value: "",
            label: __("--- Akcje masowe ---"),
            action: () => {},
          },
        ]
          .concat(massActions)
          .map(({ label, permission, id }, key) => {
            const disabled = permission && !hasAccessTo(userInfo, permission);
            return (
              <option disabled={disabled} key={id || key} value={id}>
                {label}
              </option>
            );
          })}
      </Input>
      {count > 0 ? (
        <span className="m-2">
          {__("Zaznaczono")}: {count}
        </span>
      ) : null}
      <ValidationMessage message={error} />
    </InputGroup>
  );
}

MassActionsSelect.propTypes = {
  massActions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      label: PropTypes.string,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  count: PropTypes.number.isRequired,
  excluded: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  included: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]).isRequired,
};
