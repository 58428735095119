/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";

import __ from "src/utils/Translations";

import { ExportContext } from "src/Components/DataTableControlled/context";
import {
  getParsedColumns,
  getParsedColumnsForMassAction,
  getTable,
  getTableButtons,
} from "./utils";

export default function DateTable({
  columns,
  id,
  data,
  filterable,
  isFiltersLoading,
  defaultPageSize,
  showPagination,
  noCards,
  buttons,
  sortable,
  rowId,
  exportContext,
  getTrProps,
  className,
  defaultFilters,
  defaultSorted,
  setDefaultSorted,
  loading,
  hasAccess,
  massActionSelect,
  setMassActionSelect,
  onChangeMassActionSelectCb,
  selectionOptions,
}) {
  const [filters, setFilters] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPageSize, setPageSize] = useState(
    showPagination ? defaultPageSize : data.length
  );
  const [currentPage, setPage] = useState(1);
  const [currentSort, setSort] = useState(null);

  const currentPageInex = currentPage - 1;

  const updateMassActionSelection = useCallback(
    (included, excluded) => {
      if (typeof setMassActionSelect !== "function") return;
      setMassActionSelect((actions) => {
        const value = { ...actions, included, excluded };
        if (typeof onChangeMassActionSelectCb === "function")
          onChangeMassActionSelectCb(value.included);
        return value;
      });
    },
    [setMassActionSelect, onChangeMassActionSelectCb]
  );

  const resetFilters = useCallback(() => {
    if (typeof setMassActionSelect === "function") {
      setMassActionSelect({
        excluded: [],
        included: [],
      });
    }
    setFilters([]);
    setPage(1);
  }, [setMassActionSelect, setFilters, setPage]);

  useEffect(() => {
    setPageSize(showPagination ? defaultPageSize : data.length);
  }, [showPagination, data, defaultPageSize]);

  const filteredColumns = useMemo(
    () => columns.filter((item) => !item.doNotDisplay),
    [columns]
  );

  const parsedColumns = useMemo(() => {
    let columns = getParsedColumns(filteredColumns, isFiltersLoading);

    if (massActionSelect) {
      columns = getParsedColumnsForMassAction({
        data,
        parsedColumns: columns,
        currentPageInex,
        currentPageSize,
        rowId,
        massActionSelect,
        updateMassActionSelection,
        selectionOptions,
        onChangeMassActionSelectCb,
      });
    }

    return columns;
  }, [
    filteredColumns,
    isFiltersLoading,
    massActionSelect,
    data,
    currentPageInex,
    currentPageSize,
    rowId,
    selectionOptions,
    getParsedColumns,
    getParsedColumnsForMassAction,
    updateMassActionSelection,
    onChangeMassActionSelectCb,
  ]);

  const table = useMemo(
    () =>
      getTable({
        dataCount,
        data,
        defaultSorted,
        loading,
        hasAccess,
        parsedColumns,
        defaultFilters,
        showPagination,
        defaultPageSize,
        className,
        filterable,
        filters,
        sortable,
        rowId,
        currentPage,
        currentPageInex,
        currentPageSize,
        getTrProps,
        setPage,
        setPageSize,
        setDataCount,
        setMassActionSelect,
        setFilters,
        setDefaultSorted,
        setSort,
      }),
    [
      dataCount,
      data,
      defaultSorted,
      loading,
      hasAccess,
      parsedColumns,
      defaultFilters,
      showPagination,
      defaultPageSize,
      className,
      filterable,
      filters,
      sortable,
      rowId,
      currentPage,
      currentPageInex,
      currentPageSize,
      getTrProps,
      setPage,
      setPageSize,
      setDataCount,
      setMassActionSelect,
      setFilters,
      setDefaultSorted,
      setSort,
      getTable,
    ]
  );

  const tableButtons = useMemo(
    () =>
      getTableButtons({
        buttons,
        exportContext,
        dataCount,
        currentPageSize,
        currentPage,
        filters,
        currentSort,
        filterable,
        resetFilters,
      }),
    [
      buttons,
      exportContext,
      dataCount,
      currentPageSize,
      currentPage,
      filters,
      currentSort,
      filterable,
      resetFilters,
      getTableButtons,
    ]
  );

  return noCards ? (
    <div data-t1={id}>
      {tableButtons}
      {table}
    </div>
  ) : (
    <>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody data-t1={id}>
              {tableButtons}
              {table}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

DateTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  className: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.string,
    })
  ),
  defaultPageSize: PropTypes.number,
  filterable: PropTypes.bool,
  isFiltersLoading: PropTypes.bool,
  showPagination: PropTypes.bool,
  getTrProps: PropTypes.func,
  noCards: PropTypes.bool,
  sortable: PropTypes.bool,
  rowId: PropTypes.string,

  id: PropTypes.string.isRequired,
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
    })
  ),
  exportContext: PropTypes.instanceOf(ExportContext),
  defaultSorted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  setDefaultSorted: PropTypes.func,
  loading: PropTypes.bool,
  hasAccess: PropTypes.bool,
  massActionSelect: {
    included: PropTypes.arrayOf(PropTypes.string),
    excluded: PropTypes.arrayOf(PropTypes.string),
    includedSelectAll: PropTypes.arrayOf(PropTypes.string),
  },
  setMassActionSelect: PropTypes.func,
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  onChangeMassActionSelectCb: PropTypes.func,
};

DateTable.defaultProps = {
  data: [],
  buttons: [],
  defaultPageSize: 10,
  filterable: false,
  isFiltersLoading: false,
  defaultSorted: [],
  defaultFilters: [],
  getTrProps: () => ({}),
  showPagination: true,
  sortable: true,
  noCards: false,
  rowId: "id",
  exportContext: null,
  className: "",
  setDefaultSorted: null,
  loading: false,
  hasAccess: true,
  massActionSelect: null,
  setMassActionSelect: null,
  selectionOptions: undefined,
  onChangeMassActionSelectCb: undefined,
};
