import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { CardBody, CardHeader, Card, Button } from "reactstrap";
import Form from "src/Components/Form";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import {
  API_TOTALREWARD_SERVICE,
  restApiRequestFormData,
  getFileNameFromResponse,
  saveFile,
} from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import useTotalRewardPeriods from "src/Pages/Company/TotalReward/hooks/useTotalRewardPeriods";

export default function ExportForm({ companyId }) {
  const { periods } = useTotalRewardPeriods(companyId);

  const submitMethod = useCallback(
    async (data) => {
      try {
        const body = JSON.stringify({
          period: data.period,
          tenant: companyId,
        });

        const response = await restApiRequestFormData(
          API_TOTALREWARD_SERVICE,
          `/omb/perk-users/export`,
          "POST",
          body,
          null,
          { headers: { "Content-Type": "application/json" } }
        );

        const blob = await response.blob();
        const resultFileName =
          getFileNameFromResponse(response) ||
          `export_total_reward_${data.period}.xlsx`;
        saveFile(blob, false, resultFileName);
      } catch (e) {
        console.error(e);
        dynamicNotification([__("Wystąpił nieznany błąd")]);
        newRelicErrorReport(
          e,
          "Pages/Company/TotalReward/ImportExport/Export/index.js - 27"
        );
      }
    },
    [companyId]
  );

  const [data, updateData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const onChange = (key, value) => {
    const updatedData = { ...data };
    updatedData[key] = value;
    updateData(updatedData);
  };

  const onSubmit = async () => {
    setIsProcessing(true);
    await submitMethod(data);
    setIsProcessing(false);
    updateData({});
  };

  const fields = [
    {
      id: "period",
      type: "select",
      label: "Okres",
      options: periods,
      props: {},
    },
    {
      key: "submitButton",
      component: (
        <Button
          data-t1="export"
          key="export-submit"
          disabled={isProcessing}
          color="primary"
          type="submit"
        >
          {__("Pobierz plik Excel")}
        </Button>
      ),
    },
  ];

  return (
    <Card className="main-card mb-3">
      <CardHeader>{__("Export danych")}</CardHeader>
      <CardBody className="m-2">
        <div className="mb-4">
          <Form
            id="exportForm"
            data={data}
            config={{
              defaultOnChange: onChange,
              noCards: true,
              onSubmit,
              formGroups: [
                {
                  formElements: fields,
                },
              ],
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
}

ExportForm.propTypes = {
  companyId: PropTypes.string.isRequired,
};
