import React from "react";
import { match as matchPropTypes } from "react-router-prop-types";
import ContentLoading from "src/Components/Loading/contentLoading";
import UnsavedChangesPrompt from "src/Components/UnsavedChangesPromt";
import { useCompanyId } from "src/Pages/Company/CompanyContext";
import useQueryTotalRewardConfig from "src/Pages/Company/TotalReward/hooks/useQueryTotalRewardConfig";
import TotalRewardPerksForm from "src/Pages/Company/TotalReward/Perks/Edit/form";

export default function TotalRewardPerkEdit({ match }) {
  const { perkId } = match.params;
  const companyId = useCompanyId();
  const pathPrefix = `/ahr`;

  const { isLoading, isSuccess, getConfigValue } =
    useQueryTotalRewardConfig(companyId);

  return (
    <ContentLoading show={isLoading}>
      {isSuccess && (
        <UnsavedChangesPrompt>
          <TotalRewardPerksForm
            perkId={perkId}
            pathPrefix={pathPrefix}
            yourBenefitsEnabled={getConfigValue("your_benefits_module")}
            availableBenefitsEnabled={getConfigValue(
              "available_benefits_module"
            )}
          />
        </UnsavedChangesPrompt>
      )}
    </ContentLoading>
  );
}

TotalRewardPerkEdit.propTypes = {
  match: matchPropTypes.isRequired,
};
