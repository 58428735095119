import React from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import { subscriptionEmployeeSubscriptionItemCancel } from "src/utils/RoleBasedSecurity/permissions";
import Form from "src/Components/Form";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const Cancel = ({ subscription, close }) => {
  const submit = async () => {
    try {
      const { id } = subscription;
      await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employee-subscription-item-receivers/${id}/cancel`,
        "POST",
        {
          body: {
            returnNull: true,
          },
        },
        null
      );
      dynamicNotification(__("Pomyślnie anulowano świadczenie."));
      close(true);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/Popup/cancel.js - 28"
      );
      dynamicNotification(
        e.message || __("Nie udało się anulować świadczenia."),
        "error"
      );
    }
  };
  return (
    <Form
      id="cancelSubscriptionForm"
      data={{}}
      config={{
        isInPopup: true,
        togglePopup: () => close(),
        title: "Anulacja świadczenia abonamentowego",
        onSubmit: submit,
        buttons: [
          {
            onClick: () => close(),
            text: "Zamknij",
            color: "light",
            id: "resetOneTimeFormClose",
          },
          {
            text: "Anulacja świadczenia abonamentowego",
            type: "submit",
            id: "resetOneTimeFormSubmit",
            permission: subscriptionEmployeeSubscriptionItemCancel,
          },
        ],
        defaultOnChange: () => {},
        noCards: true,
        formGroups: [
          {
            formElements: [
              {
                component: <Info />,
              },
            ],
          },
        ],
      }}
    />
  );
};

Cancel.propTypes = {
  close: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

const Info = () => (
  <Alert key="cancelInfo" color="primary">
    <h4>{__("Uwaga: Anulacja świadczenia jest nieodwracalna.")}</h4>
    <p>{__("Konsekwencje anulowania świadczenia")}:</p>
    <ul>
      <li>
        {__("data startu świadczenia równa dacie końca świadczenia P = R")}
      </li>
      <li>
        {__(
          `punkty, które były zablokowane na opłacenie świadczenia zostaną automatycznie zwrócone do banku punktów użytkownika, niezależnie 
          od tego czy w danej firmie było w tym czasie kasowanie punktów. Jeśli istnieje taka konieczność, po anulacji świadczenia operator 
          musi dokonać korekty na saldzie punktów użytkownika`
        )}
      </li>
      <li>
        {__(
          "świadczenia zależne zostaną automatycznie anulowane przy anulacji świadczenia dla użytkownika"
        )}
      </li>
    </ul>
  </Alert>
);

export default Cancel;
