import React, { useCallback } from "react";
import { CustomInput } from "reactstrap";
import PropTypes from "prop-types";
import { useSelectedRows } from "./SelectedProvider";

const RowSelectionCell = ({ cellInfo }) => {
  const { id, rowSelected } = cellInfo.original;
  const { onSelectionChange } = useSelectedRows();

  const handleChange = useCallback(
    (ev) => {
      const { checked } = ev.target;
      onSelectionChange(checked, cellInfo.original);
    },
    [cellInfo.original, onSelectionChange]
  );

  return (
    <CustomInput
      id={`${id}`}
      type="checkbox"
      onChange={handleChange}
      checked={rowSelected}
      value={String(rowSelected)}
    />
  );
};

RowSelectionCell.propTypes = {
  cellInfo: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      rowSelected: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default RowSelectionCell;
