import PropTypes from "prop-types";
import React from "react";

import { Input, Form, Row } from "reactstrap";

import hashInputId from "src/utils/jsHelpers/hashInputId";
import __ from "src/utils/Translations";

export default function FilterPriceRange({
  value,
  onChange,
  id,
  withClearButton,
}) {
  const inputValue = value;
  const handleChange = ({ from, to }) => {
    const fromValue = from === undefined ? inputValue.from : from;
    let toValue = to === undefined ? inputValue.to : to;
    if (toValue && fromValue > toValue) {
      toValue = fromValue;
    }
    onChange({
      from: fromValue,
      to: toValue,
    });
  };

  const handleChangeStart = (from) => handleChange({ from: from.target.value });
  const handleChangeEnd = (to) => handleChange({ to: to.target.value });

  const handleClearButton = () => handleChange({ from: "", to: "" });

  return (
    <>
      <Row form>
        <div style={{ width: "47.5%" }}>
          <Form>
            <Input
              id={`${hashInputId(id)}_to`}
              placeholder="od"
              value={value.from}
              onChange={handleChangeStart}
            />
          </Form>
        </div>
        <p style={{ width: "5%" }}>-</p>
        <div style={{ width: "47.5%" }}>
          <Form>
            <Input
              id={`${hashInputId(id)}_to`}
              placeholder="od"
              value={value.to}
              onChange={handleChangeEnd}
            />
          </Form>
        </div>
        {withClearButton &&
          (inputValue.from !== "" || inputValue.to !== "") && (
            <button
              onClick={handleClearButton}
              type="button"
              className="btn btn-primary"
              style={{ width: "100%", fontSize: "0.7rem" }}
            >
              {__("Wyczyść")}
            </button>
          )}
      </Row>
    </>
  );
}

FilterPriceRange.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  withClearButton: PropTypes.bool,
  value: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }),
};

FilterPriceRange.defaultProps = {
  id: "",
  onChange: null,
  value: {},
  withClearButton: true,
};
