import React from "react";
import { Route } from "react-router-dom";
import { match as matchPropTypes } from "react-router-prop-types";

// Pages
import { AhrMainNav } from "src/Layout/AppNav/NavItems";
import TilesMenu from "src/Components/TilesMenu";
import { useCompanyName } from "src/Pages/Company/CompanyContext";
import { getAhrBaseBreadcrumbs } from "src/Pages/AhrCore/routerHelper";
// import SecureRoute from '../../../utils/RoleBasedSecurity/SecurityComponents/SecureRoute';
// import { subscriptionSubscriptionPermissionWrite } from '../../../utils/RoleBasedSecurity/permissions';
import ReportsAdHoc from "src/Pages/Report/ReportsAdHoc";
import EditSubscription from "src/Pages/Report/Subscriptions/Edit";
import LandingPage from "src/Pages/Report/LandingPage";
import DiagnosticPage from "src/Pages/Report/DiagnosticPage";
import __ from "src/utils/Translations";
import VisualReport from "./GraphReports";
import NewReport from "./NewReport";
import ReportDetail from "./NewReport/details";
import GeneratedReports from "./GeneratedReports";
import ArchieveDetails from "./GeneratedReports/details";
import Subscriptions from "./Subscriptions";

const config = AhrMainNav.find((el) => el.id === "report").content;
const Report = ({ match }) => {
  const companyName = useCompanyName();
  return (
    <>
      <Route
        exact
        path={`${match.url}`}
        render={() => (
          <TilesMenu
            config={config}
            title={__("Raporty")}
            breadcrumbs={[...getAhrBaseBreadcrumbs(companyName)]}
          />
        )}
      />
      {/* <SecureRoute */}
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/predefined-reports`}
        component={NewReport}
      />
      {/* <SecureRoute */}
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/predefined-reports/:id`}
        component={ReportDetail}
      />
      {/* <SecureRoute */}
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/predefined-reports/:id/:company`}
        component={ReportDetail}
      />
      {/* <SecureRoute */}
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/predefined-reports/:id/:company/:fromArchiveId`}
        component={ReportDetail}
      />
      {/* <SecureRoute */}
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/archive`}
        component={GeneratedReports}
      />
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/archive/details/:id`}
        component={ArchieveDetails}
      />
      {/* <SecureRoute */}
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/subscriptions`}
        component={Subscriptions}
      />
      {/* <SecureRoute */}
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/subscriptions/edit/:id`}
        component={EditSubscription}
      />
      {/* <SecureRoute */}
      <Route
        exact
        // permission={subscriptionSubscriptionPermissionWrite}
        path={`${match.url}/ArchiveLandingPage/:id`}
        component={LandingPage}
      />
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        path={`${match.url}/adhoc_reports`}
        component={ReportsAdHoc}
      />
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        path={`${match.url}/DiagnosticPage`}
        component={DiagnosticPage}
      />
      <Route
        // permission={subscriptionSubscriptionPermissionWrite}
        exact
        path={`${match.url}/graph-reports`}
        component={VisualReport}
      />
    </>
  );
};

export default Report;
Report.propTypes = {
  match: matchPropTypes.isRequired,
};
