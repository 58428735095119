/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Alert,
  Label,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";
import __ from "src/utils/Translations";

export default function DownloadPopup({
  isOpen,
  isQueueRequest,
  close,
  isSaving,
  save,
  onChangeSelect,
  formatOptions,
  alert,
}) {
  const saveButton = isSaving ? (
    <Button color="success" onClick={() => save(toArchive)} disabled>
      <FontAwesomeIcon icon={faSpinner} spin /> {__("Pobieranie...")}
    </Button>
  ) : (
    <Button color="success" onClick={() => save(toArchive)}>
      {__("Generuj raport")}
    </Button>
  );

  const getFormatOptions = () =>
    formatOptions.map((item) => (
      <option key={item.value} value={item.value}>
        {item.renderFormatName}
      </option>
    ));

  const [toArchive, setToArchive] = useState(false);

  return (
    <Modal isOpen={isOpen} toggle={close} unmountOnClose size="md">
      <ModalHeader toggle={close}>
        {isQueueRequest ? __("Generuj raport") : __("Pobierz raport")}
      </ModalHeader>
      <ModalBody>
        {alert && <Alert color="danger">{__("Błąd pobierania pliku.")}</Alert>}
        <div className="form-group">
          <Label for="file-format">
            {__("Wybierz format pliku")}
            {" *"}
          </Label>
          <select
            id="file-format"
            className="form-control"
            defaultValue="-1"
            onChange={onChangeSelect}
          >
            <option value="-1" disabled hidden>
              {__("---Nie Wybrano---")}
            </option>
            {getFormatOptions()}
          </select>
        </div>
        {!isQueueRequest && (
          <ToggleSwitch
            handleChange={(isOn) => setToArchive(isOn)}
            checked={toArchive}
            label={__("Dodać raport do archiwum?")}
          />
        )}
      </ModalBody>
      <ModalFooter>{saveButton}</ModalFooter>
    </Modal>
  );
}

DownloadPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isQueueRequest: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  formatOptions: PropTypes.array.isRequired,
  alert: PropTypes.bool.isRequired,
};
