import React from "react";
import PropTypes from "prop-types";
import PopupComponent from "src/Components/Popup/popup";
import {
  POPUP_TYPE_BLOCK,
  POPUP_TYPE_CANCEL,
  POPUP_TYPE_CHANGE,
  POPUP_TYPE_RESIGN,
  POPUP_TYPE_SUSPEND,
  POPUP_TYPE_CHANGE_DATA,
  POPUP_TYPE_SHOW_PERSONS,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import Cancel from "./cancel";
import Suspend from "./suspend";
import Resign from "./resign";
import Change from "./change";
import Block from "./block";
import ChangeData from "./changeData";
import ShowPersons from "./showPerson";

export default function Popup({
  close,
  subscription,
  type,
  openPopup,
  forceProcessItem,
}) {
  let content = null;
  if (type === POPUP_TYPE_SUSPEND) {
    content = <Suspend subscription={subscription} close={close} />;
  } else if (type === POPUP_TYPE_CHANGE) {
    content = <Change subscription={subscription} close={close} />;
  } else if (type === POPUP_TYPE_RESIGN) {
    content = (
      <Resign
        subscription={subscription}
        close={close}
        forceProcessItem={forceProcessItem}
      />
    );
  } else if (type === POPUP_TYPE_CANCEL) {
    content = <Cancel subscription={subscription} close={close} />;
  } else if (type === POPUP_TYPE_BLOCK) {
    content = <Block subscription={subscription} close={close} />;
  } else if (type === POPUP_TYPE_CHANGE_DATA) {
    content = <ChangeData subscription={subscription} close={close} />;
  } else if (type === POPUP_TYPE_SHOW_PERSONS) {
    content = (
      <ShowPersons
        subscription={subscription}
        close={close}
        openPopup={openPopup}
      />
    );
  }

  if (!content) {
    return null;
  }

  return (
    <PopupComponent
      id={`${type}Popup`}
      isOpen
      toggle={() => close()}
      unmountOnClose
      size="lg"
    >
      {content}
    </PopupComponent>
  );
}

Popup.propTypes = {
  close: PropTypes.func.isRequired,
  openPopup: PropTypes.func,
  type: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  forceProcessItem: PropTypes.bool.isRequired,
};

Popup.defaultProps = {
  openPopup: false,
};
