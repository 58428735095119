/* eslint-disable no-underscore-dangle, react/destructuring-assignment, react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import DataTable from "src/Components/DataTable";
import {
  booleanOptions,
  SelectFilter,
  UuidFilter,
} from "src/Components/DataTable/filters";
import BusinessIdColumn from "src/Components/DataTable/businessIdColumn";
import DataLoading from "src/Components/Loading/dataLoading";
import __ from "src/utils/Translations";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { totalRewardPerkCategoriesWrite } from "src/utils/RoleBasedSecurity/permissions";
import ActionColumn from "src/Components/DataTable/actionColumn";
import {
  getDateCell,
  mapValueFromOptions,
} from "src/Components/DataTable/commonCells";

export default function CategoriesListing({ companyId, pathPrefix }) {
  const [data, setData] = useState(null);

  const getEditFormUrl = (id) => `${pathPrefix}/total-reward/categories/${id}`;

  const deleteRow = async (idToDelete) => {
    try {
      setData(data.filter(({ id }) => id !== idToDelete));
      await restApiRequest(
        API_TOTALREWARD_SERVICE,
        `/omb/perk-categories/${idToDelete}?tenant=${companyId}`,
        "DELETE",
        {
          returnNull: true,
        }
      );
      dynamicNotification(__("Pomyślnie usunięto"));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/TotalReward/Perks/Listing/index.js - 31"
      );
      dynamicNotification(e.message || __("Nie udało się usunąć"), "error");
      setData(null);
    }
  };

  return (
    <>
      <DataLoading
        service={API_TOTALREWARD_SERVICE}
        endpoint={`/omb/perk-categories?tenant=${companyId}`}
        fetchedData={data !== null}
        updateData={(updatedData) => setData(updatedData)}
      >
        <DataTable
          id="categoriesLisitng"
          data={data || []}
          filterable
          buttons={[
            {
              text: "Dodaj kategorię",
              permission: totalRewardPerkCategoriesWrite,
              href: getEditFormUrl(-1),
            },
          ]}
          columns={[
            {
              Header: __("ID"),
              accessor: "id",
              width: 150,
              Cell: BusinessIdColumn,
              Filter: UuidFilter,
            },
            {
              Header: __("Nazwa"),
              accessor: "name",
            },
            {
              Header: __("Aktywna"),
              accessor: "status",
              Filter: SelectFilter(booleanOptions),
              Cell: mapValueFromOptions(booleanOptions, "status"),
              width: 80,
            },
            {
              Header: __("Kolejność wyświetlania"),
              accessor: "sort",
              width: 120,
            },
            {
              Header: __("Data utworzenia"),
              accessor: "created.at",
              Cell: getDateCell("created.at"),
              width: 120,
            },
            {
              Header: __("Akcja"),
              accessor: "action",
              filterable: false,
              sortable: false,
              width: 120,
              Cell: ({ row }) => {
                const { id } = row._original;
                return (
                  <div className="d-block w-100 text-center">
                    <ActionColumn
                      data={row._original}
                      buttons={[
                        {
                          id: "edit",
                          className: "m-1 p-1",
                          color: "link",
                          label: "Edytuj",
                          permission: totalRewardPerkCategoriesWrite,
                          href: getEditFormUrl(id),
                        },
                        {
                          id: "delete",
                          className: "m-1 p-1",
                          color: "link",
                          label: "Usuń",
                          permission: totalRewardPerkCategoriesWrite,
                          onClick: () => {
                            getUserConfirmationPopup(
                              __("Czy na pewno chcesz usunąć kategorię?"),
                              (confirm) =>
                                confirm && deleteRow(row._original.id),
                              __("Usuwanie kategorii")
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                );
              },
            },
          ]}
          showPagination={false}
        />
      </DataLoading>
    </>
  );
}

CategoriesListing.propTypes = {
  companyId: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
};
