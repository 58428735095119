/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useContext, useRef, useState } from "react";
import { Button, CustomInput } from "reactstrap";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import ContentLoading from "src/Components/Loading/contentLoading";
import RbsButton from "src/utils/RoleBasedSecurity/SecurityComponents/RbsButton";
import {
  subscriptionEmployeeSubscriptionItemAccept,
  subscriptionEmployeeSubscriptionItemGeneratePdf,
} from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import pendingBenefitsContext from "src/Pages/Company/Pending/BenefitsPending/Listing/context";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import { MASS_ACTIONS_STATUSES } from "src/Pages/Company/Pending/BenefitsPending/consts";

const PDF_GENERATION_POPUP_TITLE =
  "Uwaga, nastąpi wygenerowanie nowego formularza z kodem kreskowym!";
const PDF_GENERATION_POPUP_MESSAGE =
  "Czy na pewno chcesz wygenerować nowy formularz z kodem kreskowym?";

const FormsColumn = (cellInfo) => {
  const {
    expandedFormLists,
    manageFormListExpansion,
    selectedForms,
    onSelectFormsHandler,
    onUnselectFormsHandler,
  } = useContext(pendingBenefitsContext);

  const [isLoading, setIsLoading] = useState(false);

  const canGenerateUrl = useHasPermission(
    subscriptionEmployeeSubscriptionItemGeneratePdf
  );

  const forms = cellInfo.row[cellInfo.column.id];
  const formToShow = forms.some(({ accepted }) => !accepted);
  const subscriptionId = cellInfo.row._original.id;
  const isFormListExpanded = expandedFormLists.includes(subscriptionId);

  const inAnySelected = forms.some(
    ({ id, accepted }) => accepted || selectedForms.includes(id)
  );

  const formsIdsRef = useRef(forms.map(({ id }) => id));

  const { updateItem, refreshData } = cellInfo.column;
  const acceptForms = async () => {
    try {
      setIsLoading(true);
      const rowSelectedForms = selectedForms.filter((selectedFormId) =>
        forms.some(({ id }) => selectedFormId === id)
      );

      const { pdfBarcodes } = await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employee-subscription-items/bulk-accept`,
        "POST",
        {
          body: {
            pdfBarcodes: rowSelectedForms,
          },
        },
        {}
      );
      const { ACCEPTED, ALREADY_ACCEPTED } = MASS_ACTIONS_STATUSES;
      const acceptedFormsIds = Object.values(pdfBarcodes)
        .filter(({ status }) => [ACCEPTED, ALREADY_ACCEPTED].includes(status))
        .map(({ id }) => id);

      if (!acceptedFormsIds.length) {
        dynamicNotification(
          __("Nie udało się zaakceptować formularzy"),
          "error"
        );
        return;
      }

      const updatedForms = forms.map((form) => {
        const { id, accepted } = form;
        const isAccepted = acceptedFormsIds.includes(id);
        return {
          ...form,
          accepted: isAccepted || accepted,
        };
      });

      updateItem(updatedForms, subscriptionId, "pdfBarcodes");
      onUnselectAllInRowHandler();
      dynamicNotification(
        __(
          acceptedFormsIds.length === 1
            ? "Pomyślnie zaakceptowano formularz"
            : "Pomyślnie zaakceptowano formularze"
        )
      );
      refreshData();
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/Pending/BenefitsPending/Listing/Table/formsColumn.js - 49"
      );
      dynamicNotification(
        e.message || __("Nie udało się zaakceptować formularzy"),
        "error"
      );
    }
    onUnselectAllInRowHandler();
    setIsLoading(false);
  };

  if (!formToShow) {
    return (
      <div className="d-block w-100 text-center">{__("Brak formularzy")}</div>
    );
  }

  const onGeneratePdfHandler = async (url, barcode) => {
    try {
      const { url: pdfUrl } = await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        url,
        "PUT",
        {}
      );

      if (pdfUrl) window.open(pdfUrl);
    } catch (err) {
      if (err && err.status === 500) {
        dynamicNotification(
          __(
            "Nie można wygenerować dokumentu ponieważ do benefitu dodano niepoprawny plik. Konieczne jest jego usunięcie lub zmiana typu załącznika"
          ),
          "error"
        );
      }
    }
  };

  const onAskGeneratePdfHandler = (url, barcode) => {
    getUserConfirmationPopup(
      __(PDF_GENERATION_POPUP_MESSAGE),
      (isAccepted) => {
        if (!isAccepted) return;
        onGeneratePdfHandler(url, barcode);
      },
      __(PDF_GENERATION_POPUP_TITLE),
      null
    );
  };

  const onUnselectAllInRowHandler = () => {
    onUnselectFormsHandler(formsIdsRef.current, subscriptionId);
  };

  const onSelectAllInRowHandler = () => {
    onSelectFormsHandler(forms);
  };

  const onSetIsFormListExpandedHandler = (isExpanded) => {
    manageFormListExpansion({ id: subscriptionId, isExpanded });
  };

  return (
    <div className="d-block w-100 text-center">
      {isFormListExpanded ? (
        <ContentLoading show={isLoading}>
          <div>
            <div className="p-1">
              <Button
                color="link"
                size="sm"
                onClick={() => onSetIsFormListExpandedHandler(false)}
              >
                {__("Ukryj formularze")}
              </Button>
            </div>
            <span className="p-1">
              <RbsButton
                permission={subscriptionEmployeeSubscriptionItemAccept}
                color="link"
                size="sm"
                onClick={onSelectAllInRowHandler}
              >
                {__("Zaznacz wszystkie")}
              </RbsButton>
            </span>
            <span className="p-1">
              <RbsButton
                permission={subscriptionEmployeeSubscriptionItemAccept}
                color="link"
                size="sm"
                onClick={onUnselectAllInRowHandler}
              >
                {__("Odznacz wszystkie")}
              </RbsButton>
            </span>
          </div>
          {/* eslint-disable-next-line array-callback-return,consistent-return */}
          {forms
            .filter(({ accepted }) => !accepted)
            .map(
              ({ id, barcode, accepted, administrativeGenerateUrl: url }) => {
                const isChecked = selectedForms.includes(id);
                return (
                  <div key={id} className="d-flex align-items-center">
                    <CustomInput
                      id={`form_${subscriptionId}_${id}`}
                      inline
                      type="checkbox"
                      onChange={(e) => {
                        const { checked } = e.target;
                        if (checked) {
                          if (!selectedForms.includes(id)) {
                            onSelectFormsHandler([{ id, accepted }]);
                          }
                        } else {
                          onUnselectFormsHandler([id], subscriptionId);
                        }
                      }}
                      checked={isChecked}
                      value={isChecked}
                    />
                    <button
                      className="btn text-primary"
                      type="button"
                      disabled={!canGenerateUrl}
                      onClick={() => onAskGeneratePdfHandler(url, barcode)}
                    >
                      {barcode}
                    </button>
                  </div>
                );
              }
            )}
          <div>
            <span className="py-1">
              <RbsButton
                permission={subscriptionEmployeeSubscriptionItemAccept}
                color="primary"
                size="sm w-100"
                onClick={acceptForms}
                disabled={!inAnySelected}
              >
                {__("Akceptuj zaznaczone")}
              </RbsButton>
            </span>
          </div>
        </ContentLoading>
      ) : (
        <Button
          color="link"
          onClick={() => onSetIsFormListExpandedHandler(true)}
        >
          {__("Pokaż formularze")}
        </Button>
      )}
    </div>
  );
};

export default FormsColumn;
