import __ from "src/utils/Translations";
import translateFields from "src/utils/Translations/translateFields";

export const rootBreadcrumb = { title: "Firma", link: "/company" };
export const getCompanyBaseBreadcrumbs = (companyId, companyName) => [
  translateFields(rootBreadcrumb, ["title"]),
  { title: `${__("Lista firm")}`, link: "/company/list" },
  {
    title: `${__("Zarządzanie firmą")} ${companyName} (${companyId})`,
    link: `/company/edit/${companyId}`,
  },
];
