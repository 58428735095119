/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { FormGroup } from "reactstrap";
import DateRange from "src/Components/FormElements/DateRange";
import __ from "src/utils/Translations";
import Text from "src/Components/DataTableControlled/AdditionalFiltersInput/Text";
import Select from "src/Components/DataTableControlled/AdditionalFiltersInput/Select";

const getAdditionalFiltersComponent = ({
  type,
  id,
  value,
  options,
  onUpdate,
  valueObject,
  onRangeChange,
  restProps,
  validation,
}) => {
  let component;
  switch (type) {
    case "select":
      component = (
        <Select id={id} value={value} options={options} onUpdate={onUpdate} />
      );
      break;
    case "dateRange":
      component = (
        <FormGroup>
          <DateRange
            value={valueObject ? valueObject.value : { from: null, to: null }}
            onChange={(value) => onRangeChange(value, id)}
            id={id}
            {...restProps}
            validation={validation}
            startDateLabel="Od"
            endDateLabel="Do"
          />
        </FormGroup>
      );
      break;
    case "text":
    default:
      component = <Text id={id} value={value} onUpdate={onUpdate} />;
  }

  return component;
};

export default getAdditionalFiltersComponent;
