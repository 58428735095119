import React from "react";
import { match as matchPropTypes } from "react-router-prop-types";
import UnsavedChangesPrompt from "src/Components/UnsavedChangesPromt";
import TotalRewardCategoriesForm from "src/Pages/Company/TotalReward/Categories/Edit/form";

export default function TotalRewardCategoryEdit({ match }) {
  const { categoryId } = match.params;
  const pathPrefix = `/ahr`;

  return (
    <UnsavedChangesPrompt>
      <TotalRewardCategoriesForm
        categoryId={categoryId}
        pathPrefix={pathPrefix}
      />
    </UnsavedChangesPrompt>
  );
}

TotalRewardCategoryEdit.propTypes = {
  match: matchPropTypes.isRequired,
};
