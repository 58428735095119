import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";

import __ from "src/utils/Translations";
import AdditionalFilters from "src/Components/DataTableControlled/AdditionalFilters";
import { dataTableControlledTitleStyle as titleStyle } from "src/Components/DataTableControlled/styles";

const Filters = ({
  filterable,
  title,
  filterData,
  resetFilters,
  additionalFilters,
  filters,
  onFilteredChange,
}) => (
  <>
    {filterable ? (
      <div className="text-right">
        <div className="float-left" style={titleStyle}>
          {title}
        </div>
        <Button
          data-t1="gridFiltersApply"
          className="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-pill"
          outline
          color="success"
          type="submit"
          onClick={filterData}
        >
          {__("Zastosuj filtry")}
          <i className="pe-7s-filter btn-icon-wrapper"> </i>
        </Button>
        <Button
          data-t1="gridFiltersClear"
          className="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-dashed btn-pill"
          outline
          color="danger"
          onClick={resetFilters}
        >
          {__("Resetuj filtry")}
          <i className="pe-7s-close btn-icon-wrapper"> </i>
        </Button>
      </div>
    ) : null}
    {additionalFilters ? (
      <AdditionalFilters
        currentFilters={filters}
        onFilteredChange={onFilteredChange}
        filtersConfig={additionalFilters}
      />
    ) : null}
  </>
);

Filters.propTypes = {
  filterable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  filterData: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  additionalFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFilteredChange: PropTypes.func.isRequired,
};

export default Filters;
