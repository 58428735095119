export const processStatusOptions = [
  { value: "new", label: "Nowy", dataT2: "new" },
  { value: "in_progress", label: "W trakcie", dataT2: "pending" },
  { value: "success", label: "Zakończony sukcesem", dataT2: "success" },
  { value: "warning", label: "Zakończony ostrzeżeniem", dataT2: "warning" },
  { value: "failed", label: "Zakończony błędem", dataT2: "failed" },
];

export const statusOptions = [
  { value: "info", label: "Informacja" },
  { value: "warn", label: "Ostrzeżenie" },
  { value: "error", label: "Błąd" },
  { value: "finish", label: "Koniec" },
];
