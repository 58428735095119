import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { isFunction } from "lodash";
import { string, func, element, oneOfType } from "prop-types";

import { DefaultLoader } from "src/Components/Loading/loaders";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { getSession } from "src/utils/RoleBasedSecurity/Session";

import {
  generateCssLinks,
  generateJsScripts,
  loadAppData,
} from "src/utils/assetsManifest";

const MicroFrontend = ({ customElementName, children }) => {
  const appRef = useRef();
  const [initAppData, setInitAppData] = useState(null);
  const [hasLoadingError, setHasLoadingError] = useState(false);

  useEffect(() => {
    const initApp = async () => {
      const helmetContent = await loadAppData(customElementName);
      setInitAppData(helmetContent);
    };

    initApp().catch((e) => {
      newRelicErrorReport(
        e,
        `Components/MicroFrontend/Microfrontend.js - ${customElementName}`
      );
      setHasLoadingError(true);
    });
  }, [customElementName]);

  useEffect(() => {
    updateAppData();
  }, [initAppData]);

  const updateAppData = async () => {
    const session = getSession();

    if (appRef.current) {
      appRef.current.getAccessToken = async () => session.getValidAccessToken();
    }
  };

  if (hasLoadingError) {
    return <>{__("Błąd pobierania danych")}</>;
  }

  if (!initAppData) {
    return <DefaultLoader />;
  }

  return (
    <>
      <Helmet>
        {generateCssLinks(initAppData.appName, initAppData.entrypoints.css)}
        {generateJsScripts(initAppData.appName, initAppData.entrypoints.js)}
      </Helmet>
      {isFunction(children) ? children(appRef) : children}
    </>
  );
};

MicroFrontend.propTypes = {
  customElementName: string.isRequired,
  children: oneOfType([func, element]).isRequired,
};

export default MicroFrontend;
