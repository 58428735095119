import { useState, useEffect } from "react";
import { MASTERDATA_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import { mockData } from "src/Pages/Supplier/List/table";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function useProductSuppliers(
  asOption = false,
  idToLowercase = false
) {
  const [data, setData] = useState([]);

  useEffect(() => {
    restApiRequest(
      MASTERDATA_SERVICE,
      "/supplierobject/nontouristic",
      "GET",
      {
        params: {
          rowsPerPage: 10000,
        },
      },
      { member: mockData }
    )
      .then((resData) => {
        const { member: items } = resData;
        let result = items.filter(
          ({ supplierObjectBusinessID }) => supplierObjectBusinessID
        );
        if (idToLowercase) {
          result = result.map((item) => ({
            ...item,
            supplierObjectBusinessID:
              item.supplierObjectBusinessID.toLowerCase(),
          }));
        }

        if (asOption) {
          result = result.map(({ supplierObjectBusinessID, objectName }) => ({
            value: supplierObjectBusinessID,
            label: objectName,
          }));
        }
        setData(result);
      })
      .catch((e) => {
        newRelicErrorReport(
          e,
          "utils/hooks/suppliers/useProductSuppliers.js - 48"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy dostawców"),
          "error"
        );
      });
  }, [asOption, idToLowercase]);

  return data;
}
