import findCompanies from "./findCompanies";
import findData from "./findData";
import findEmployees from "./findEmployees";
import findMenuItems from "./findMenuItems";
import findProducts from "./findProducts";
import findTourismObjects from "./findTourismObjects";

const sendFindDataRequests = ({
  userInfo,
  companyData,
  inCompanyContext,
  inputText,
  setIsSearching,
  setShowSuggestions,
  setMenuItems,
  setEmployees,
  setCompanies,
  setProducts,
  setTourismObjects,
}) => {
  const handler = setTimeout(() => {
    const searchContext = { userInfo, companyData, inCompanyContext };
    if (inputText) {
      setIsSearching(true);
      setShowSuggestions(true);
      const newMenuItems = findMenuItems(inputText, searchContext);
      setMenuItems(newMenuItems);
      const requests = [];
      if (userInfo.isAhr()) {
        requests.push(
          findData(inputText, setEmployees, findEmployees, searchContext)
        );
      } else {
        requests.push(
          findData(inputText, setEmployees, findEmployees, searchContext)
        );
        requests.push(
          findData(inputText, setCompanies, findCompanies, searchContext)
        );
        requests.push(
          findData(inputText, setProducts, findProducts, searchContext)
        );
        requests.push(
          findData(
            inputText,
            setTourismObjects,
            findTourismObjects,
            searchContext
          )
        );
      }
      Promise.all(requests).then(() => setIsSearching(false));
    } else {
      setShowSuggestions(false);
    }
  }, 500);
  return () => {
    clearTimeout(handler);
  };
};

export default sendFindDataRequests;
