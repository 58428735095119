import { bool, func, node, oneOfType, string } from "prop-types";
import React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";
import __ from "src/utils/Translations";

export default function UserConfirmationPopup({
  onClose,
  onCancel,
  onConfirm,
  isOpen,
  title,
  confirmLabel,
  cancelLabel,
  message,
  confirmButtonColor,
  additionalButtons,
}) {
  return (
    <Modal isOpen={isOpen} toggle={onClose} unmountOnClose>
      <div data-t1="confirmation">
        <ModalHeader toggle={onClose}>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button data-t1="cancel" color="light" onClick={onCancel}>
            {__(cancelLabel)}
          </Button>
          <Button
            data-t1="confirm"
            color={confirmButtonColor}
            onClick={onConfirm}
          >
            {__(confirmLabel)}
          </Button>
          {additionalButtons}
        </ModalFooter>
      </div>
    </Modal>
  );
}

UserConfirmationPopup.propTypes = {
  cancelLabel: string,
  confirmLabel: string,
  isOpen: bool,
  message: oneOfType([string, node]),
  onClose: func.isRequired,
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
  title: string,
  confirmButtonColor: string,
  additionalButtons: node,
};

UserConfirmationPopup.defaultProps = {
  cancelLabel: "Nie",
  confirmLabel: "Tak",
  isOpen: true,
  message: "Czy na pewno chcesz wykonać tę operację?",
  title: "Czy jesteś pewien?",
  confirmButtonColor: "primary",
  additionalButtons: null,
};
