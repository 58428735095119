import { useState, useEffect } from "react";
import { EMPLOYEE_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import { employeeEmployeePermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function useEmployees(
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false,
  noFetching = false,
  batchParams = 0
) {
  const [data, setData] = useState([]);

  const hasPermission = useHasPermission(employeeEmployeePermissionRead);
  const skipAction = !hasPermission || noFetching;

  useEffect(() => {
    const fetchData = async () => {
      if (skipAction) {
        setData([]);
        return;
      }

      if (!!batchParams && filterField === "id" && filterValue.length) {
        const params = {
          itemsPerPage: batchParams,
        };
        // Batch the IDs and make multiple requests
        const batchSize = batchParams;
        const batches = [];

        for (let i = 0; i < filterValue.length; i += batchSize) {
          const batch = filterValue.slice(i, i + batchSize);
          batches.push(batch);
        }

        const requests = batches.map(async (batch) => {
          const batchParams = {
            ...params,
            [filterField]: batch,
          };
          return restApiRequest(
            EMPLOYEE_MANAGEMENT_SERVICE,
            "/employees",
            "GET",
            { params: batchParams },
            mockData
          );
        });

        try {
          const responses = await Promise.all(requests);
          const combinedResults = responses.flat();
          let result = combinedResults;

          if (idAsIri) {
            result = result.map((el) => ({
              ...el,
              id: `${IRI_PREFIX}/${el.id}`,
            }));
          }
          if (asOption) {
            result = result.map(({ firstName, lastName, id }) => ({
              value: id,
              label: `${firstName} ${lastName}`,
            }));
          }

          setData(result);
        } catch (e) {
          newRelicErrorReport(e, "utils/hooks/company/useEmployees.js - 57");
          dynamicNotification(
            e.message || __("Nie udało się pobrać listy pracowników"),
            "error"
          );
        }
      } else {
        const params = {
          itemsPerPage: 10000,
        };
        params[filterField] = filterValue;
        try {
          const resData = await restApiRequest(
            EMPLOYEE_MANAGEMENT_SERVICE,
            "/employees",
            "GET",
            { params },
            mockData
          );

          let result = resData;
          if (idAsIri) {
            result = result.map((el) => ({
              ...el,
              id: `${IRI_PREFIX}/${el.id}`,
            }));
          }
          if (asOption) {
            result = result.map(({ firstName, lastName, id }) => ({
              value: id,
              label: `${firstName} ${lastName}`,
            }));
          }
          setData(result);
        } catch (e) {
          newRelicErrorReport(e, "utils/hooks/company/useEmployees.js - 57");
          dynamicNotification(
            e.message || __("Nie udało się pobrać listy pracowników"),
            "error"
          );
        }
      }
    };

    fetchData();
  }, [filterField, filterValue, idAsIri, asOption, skipAction]);

  return data;
}

export const IRI_PREFIX = "/api/employee-management/v1/rest/employees";

export const mockData = [
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    firstName: "Jan",
    lastName: "Kowalski",
    companyId: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    username: "10clouds344222281",
    email: "jan.kowalski@gmial.com",
    active: true,
    employeeGroup: "Użytkownicy",
    fk: "3c3275af-7247-470e-9397-d114957deb71",
  },
];
