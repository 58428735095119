/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import {
  AsyncAutocompleteSelectFilter,
  AutocompleteSelectFilter,
  SelectFilter,
  UuidFilter,
} from "src/Components/DataTable/filters";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import BusinessIdColumn from "src/Components/DataTable/businessIdColumn";
import { API_TOTALREWARD_SERVICE } from "src/utils/Api";
import __ from "src/utils/Translations";
import translateLabels from "src/utils/Translations/translateLabels";
import useTotalRewardPerks from "src/Pages/Company/TotalReward/Perks/useTotalRewardPerks";
import useTotalRewardPeriods from "src/Pages/Company/TotalReward/hooks/useTotalRewardPeriods";
import { getUsersOptionsFetchMethod } from "src/Pages/Company/TotalReward/hooks/useQueryTotalRewardUsers";

export default function PerkUsersDataTable({ companyId, employeeId }) {
  const perks = useTotalRewardPerks(companyId);
  const { periods } = useTotalRewardPeriods(companyId);

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const fetchData = useCallback(
    async (filters, page, pageSize, sort) => {
      const options = {
        params: { tenant: companyId },
      };
      if (sort?.key === "perk") {
        options.params[`order[perk.title]`] = sort.value;
      }
      if (sort?.key === "user") {
        options.params[`order[user.lastName]`] = sort.value;
        options.params[`order[user.firstName]`] = sort.value;
      }
      if (employeeId) {
        options.params.user = employeeId;
      }
      const { data: newData, count: newCount } = await getListingData(
        API_TOTALREWARD_SERVICE,
        `/omb/perk-users`,
        filters,
        page,
        pageSize,
        sort,
        options,
        mockData,
        true
      );
      if (newData) {
        setData(newData);
        setCount(newCount);
      }
    },
    [companyId, employeeId]
  );

  return (
    <DataTableControlled
      id="PerkUsersListing"
      fetchData={fetchData}
      data={data}
      count={count}
      filterable
      columns={[
        {
          Header: __("ID"),
          accessor: "id",
          minWidth: 155,
          Cell: BusinessIdColumn,
          Filter: UuidFilter,
        },
        {
          Header: __("Benefit"),
          accessor: "perk",
          minWidth: 200,
          Filter: AutocompleteSelectFilter(perks),
          Cell: ({ row }) => row._original.perk?.title,
        },
        {
          Header: __("Widoczny"),
          accessor: "perk.status",
          minWidth: 100,
          Filter: SelectFilter(translateLabels(statusBooleanOptions)),
          Cell: mapValueFromOptions(
            translateLabels(statusBooleanOptions),
            "perk.status"
          ),
        },
        {
          Header: __("Pracownik"),
          accessor: "user",
          minWidth: 200,
          Filter: AsyncAutocompleteSelectFilter((filterValue) =>
            getUsersOptionsFetchMethod(companyId, filterValue, 100)
          ),
          Cell: ({ row }) =>
            `${row._original.user?.lastName} ${row._original.user?.firstName} ${
              row._original.user?.fk ? `(${row._original.user?.fk})` : ""
            }`,
          doNotDisplay: !!employeeId,
        },
        {
          Header: __("Okres"),
          accessor: "period",
          Filter: SelectFilter(periods),
          minWidth: 120,
        },
        {
          Header: __("Wartość"),
          accessor: "value",
          minWidth: 120,
        },
      ]}
    />
  );
}

const mockData = [];

const statusBooleanOptions = [
  {
    value: true,
    label: "Widoczny",
  },
  {
    value: false,
    label: "Niewidoczny",
  },
];

PerkUsersDataTable.propTypes = {
  companyId: PropTypes.string.isRequired,
  employeeId: PropTypes.string,
};

PerkUsersDataTable.defaultProps = {
  employeeId: null,
};
