const addAttributesToDOMElements = (
  containerTargetSelector,
  attributeName,
  attributeValue
) => {
  const elements = document.querySelectorAll(containerTargetSelector);
  if (elements.length) {
    elements.forEach((element) => {
      if (!element.getAttribute(attributeName))
        element.setAttribute(attributeName, attributeValue);
    });
  }
};

export default () => {
  setTimeout(() => {
    addAttributesToDOMElements(
      ".Toastify__toast--success",
      "data-t1",
      "saveSuccess"
    );
    addAttributesToDOMElements(
      ".Toastify__toast--warning",
      "data-t1",
      "saveWarning"
    );
    addAttributesToDOMElements(
      ".Toastify__toast--error",
      "data-t1",
      "saveError"
    );
    addAttributesToDOMElements(
      ".Toastify__toast-body",
      "data-t2",
      "notificationValue"
    );
    addAttributesToDOMElements(
      ".Toastify__close-button",
      "data-t1",
      "closeNotification"
    );
  }, 1);
};
