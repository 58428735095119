import { useState, useEffect } from "react";
import { EMPLOYEE_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import { employeeEmployeeGroupPermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function useDepartments(
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false,
  noFetching = false
) {
  const [data, setData] = useState([]);

  const hasPermission = useHasPermission(employeeEmployeeGroupPermissionRead);
  const skipAction = !hasPermission || noFetching;

  useEffect(() => {
    if (skipAction) {
      setData([]);
      return;
    }
    const params = {
      itemsPerPage: 10000,
    };
    if (filterField && filterValue) {
      params[filterField] = filterValue;
    }
    restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      "/departments",
      "GET",
      { params },
      mockData
    )
      .then((resData) => {
        let result = resData;
        if (idAsIri) {
          result = result.map((el) => ({
            ...el,
            id: `${IRI_PREFIX}/${el.id}`,
          }));
        }
        if (asOption) {
          result = result.map(({ name, id }) => ({ value: id, label: name }));
        }
        setData(result);
      })
      .catch((e) => {
        newRelicErrorReport(e, "utils/hooks/company/useDepartments.js - 53");
        dynamicNotification(
          e.message || __("Nie udało się pobrać działów"),
          "error"
        );
      });
  }, [filterField, filterValue, idAsIri, asOption, skipAction]);

  return data;
}

export const IRI_PREFIX = "/api/employee-management/v1/rest/departments";

export const mockData = [
  {
    id: "3ba186d2-b0af-430b-936d-9474acdef9cb",
    name: "Dział 1",
    companyId: "279c5217-0229-4f59-b876-519db0d989bb",
    description: "Dział 1 Opis",
    employeeIds: [
      "/api/employee-management/v1/rest/employees/480d3c1a-bbb6-4bcc-b852-fd83fbdd38a4",
    ],
  },
  {
    id: "3ba186d2-b0af-430b-936d-9474acdef9cd",
    name: "Dział 2",
    companyId: "279c5217-0229-4f59-b876-519db0d989bb",
    description: "Dział 2 Opis",
    employeeIds: [],
  },
];
