import DISCOUNT_CODES_CONSTS from "src/Pages/ProductCatalog/MBActiveProduct/consts";

const discountFormatter = (value = null, discountType) => {
  if (discountType === DISCOUNT_CODES_CONSTS.DISCOUNT_TYPES.GRATIS) {
    return "GRATIS";
  }
  if (discountType === DISCOUNT_CODES_CONSTS.DISCOUNT_TYPES.PERCENTAGE) {
    return `${value} %`;
  }
  return `${value} zł`;
};

export default discountFormatter;
