import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export const TYPE_LISTING = "listing";
export const TYPE_EDIT = "edit";

const getLocalStorageKey = (id) => `omb_browsing_history_${id}`;

export const getBrowsingHistory = (id) => {
  try {
    const result = JSON.parse(
      window.localStorage.getItem(getLocalStorageKey(id))
    );
    if (Array.isArray(result)) {
      return result;
    }
  } catch (e) {
    console.error(e);
    newRelicErrorReport(e, "utils/browsingHistory/index.js - 21");
  }
  return [];
};

const setBrowsingHistory = (data, userId) =>
  window.localStorage.setItem(getLocalStorageKey(userId), JSON.stringify(data));

export const useAddToHistory = (title, type, canAddToHistory) => {
  const path = useLocation().pathname;
  const { userInfo } = useContext(RbsContext);
  const userId = userInfo.getId();
  useEffect(() => {
    if (!canAddToHistory) {
      return;
    }
    const history = getBrowsingHistory(userId);
    let historyItem = history.find((item) => item.path === path);
    if (!historyItem) {
      historyItem = {};
      history.push(historyItem);
    }

    historyItem.path = path;
    historyItem.title = title;
    historyItem.type = type;
    historyItem.time = Date.now();

    setBrowsingHistory(
      history.sort((a, b) => b.time - a.time).slice(0, 20),
      userId
    );
  }, [title, type, path, canAddToHistory, userId]);
};
