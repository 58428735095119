/* eslint no-unused-vars: 0 */
/* eslint no-eval: 0 */
import __ from "src/utils/Translations";
import _ from "lodash";
import isEmptyValue from "src/utils/jsHelpers/isEmptyValue";

export default function validate(value, validators) {
  if (validators && validators.length) {
    let message;
    for (let i = 0; i < validators.length; i += 1) {
      const rule = validators[i];
      if (typeof rule === "object") {
        const { method, args } = rule;
        message = eval(`${method}`)(value, ...args);
      } else if (rule) {
        message = eval(`${rule}`)(value);
      }
      if (message) {
        return message;
      }
    }
  }
  return null;
}
// eval method fires functions below, `validators` contains function name
// example: validatiors = ['required', { method: 'minLength', args: [3] }];
const required = (value) => {
  let isValid = true;
  if (
    (Array.isArray(value) || value instanceof FileList) &&
    value.length === 0
  ) {
    isValid = false;
  } else if (!value) {
    isValid = false;
  }
  return isValid ? null : __("To pole jest wymagane");
};

const incorrectChars = (value, charsArgs) => {
  if (charsArgs.length > 0 && value.length > 0) {
    for (let i = 0; i < charsArgs.length; i += 1) {
      const char = charsArgs[i];
      if (value.includes(char)) {
        return __("Niedozwolony znak: {0}", [char]);
      }
    }
  }
  return null;
};

const requiredCheckbox = (value) => {
  let isValid = true;
  if (!Array.isArray(value) || value.length === 0) {
    isValid = false;
  }
  return isValid ? null : __("Należy wybrać przynajmniej jedną opcję");
};

const noRepetitions = (value) => {
  const splitedValue = value.split(";");
  const prepSplitedValue = splitedValue.map((splitedEle) =>
    splitedEle.includes(".") ? splitedEle : `${splitedEle}.00`
  );

  return _.some(
    prepSplitedValue,
    (elt, index) => prepSplitedValue.indexOf(elt) !== index
  )
    ? __("Wartości nie mogą się powtarzać")
    : null;
};

const requiredNumericField = (value) => {
  if (isEmptyValue(value) || Number.isNaN(value)) {
    return __("To pole jest wymagane");
  }
  return null;
};

const minLength = (value, minLen) =>
  value && value.trim && value.trim().length < minLen
    ? __("Minimalna ilość znaków dla tego pola to {0}", [minLen])
    : null;

const maxLength = (value, maxLen) =>
  value && value.trim && value?.trim().length > maxLen
    ? __("Maksymalna ilość znaków dla tego pola to {0}", [maxLen])
    : null;

const equalLength = (value, length) =>
  value && value.toString().length !== length
    ? __("Ilość znaków dla tego pola to {0}", [length])
    : null;

const greaterEqualThan = (value, minValue) =>
  !isEmptyValue(value) && value < minValue
    ? __("Wartość pola musi być większa lub równa {0}", [minValue])
    : null;

const greaterThan = (value, minValue) =>
  !isEmptyValue(value) && value <= minValue
    ? __("Wartość pola musi być większa od {0}", [minValue])
    : null;

const lessThanDates = (value, minValue, minValue2) => {
  const firstCondition =
    !isEmptyValue(value) && Date.parse(value) > Date.parse(minValue2)
      ? __("Data nie może być późniejsza niż {0}", [
          new Date(minValue2).toLocaleDateString("pl-PL"),
        ])
      : null;
  const secondCondition =
    Date.parse(value) > Date.parse(minValue)
      ? __("Data nie może być późniejsza niż {0}", [
          new Date(minValue).toLocaleDateString("pl-PL"),
        ])
      : null;
  let result;
  if (firstCondition) result = firstCondition;
  else if (!firstCondition && secondCondition) result = secondCondition;
  else result = null;
  return result;
};

const greaterThanDates = (value, minValue, minValue2) => {
  const firstCondition =
    !isEmptyValue(value) && Date.parse(value) <= Date.parse(minValue2)
      ? __("Data nie może być wcześniejsza niż {0}", [
          new Date(minValue2).toLocaleDateString("pl-PL"),
        ])
      : null;
  const secondCondition =
    !isEmptyValue(value) && Date.parse(value) <= Date.parse(minValue)
      ? __("Data nie może być wcześniejsza niż {0}", [
          new Date(minValue).toLocaleDateString("pl-PL"),
        ])
      : null;
  let result;
  if (firstCondition) result = firstCondition;
  else if (!firstCondition && secondCondition) result = secondCondition;
  else result = null;
  return result;
};

const betweenDates = (value, minValue, minValue2) => {
  const firstCondition =
    !isEmptyValue(value) && Date.parse(value) <= Date.parse(minValue)
      ? __("Data nie może być wcześniejsza niż {0}", [
          new Date(minValue).toLocaleDateString("pl-PL"),
        ])
      : null;
  const secondCondition =
    Date.parse(value) > Date.parse(minValue2)
      ? __("Data nie może być późniejsza niż {0}", [
          new Date(minValue2).toLocaleDateString("pl-PL"),
        ])
      : null;
  let result;
  if (firstCondition) result = firstCondition;
  else if (!firstCondition && secondCondition) result = secondCondition;
  else result = null;
  return result;
};

const lessEqualThan = (value, maxValue) =>
  !isEmptyValue(value) && value > maxValue
    ? __("Wartość pola musi być mniejsza lub równa {0}", [maxValue])
    : null;

const greaterEqualThanDate = (value, minValueArg, showTime = false) => {
  const minValue =
    minValueArg instanceof Date ? minValueArg : new Date(minValueArg);
  return value && value < minValue
    ? __("Data nie może być wcześniejsza niż {0}", [
        showTime
          ? minValue.toLocaleString("pl-PL")
          : minValue.toLocaleDateString("pl-PL"),
      ])
    : null;
};

const formatDate = (value, format) => {
  const p = (s) => `00${s}`.slice(-2);

  const date = new Date(value);
  return format
    ? format.replace(/(y|Y|m|d|H|i|s)/g, (m) => {
        switch (m) {
          case "y":
            return date.getFullYear();
          case "Y":
            return date.getFullYear();
          case "m":
            return p(date.getMonth() + 1);
          case "d":
            return p(date.getDate());
          case "H":
            return p(date.getHours());
          case "i":
            return p(date.getMinutes());
          case "s":
            return p(date.getSeconds());
          default:
            return m;
        }
      })
    : date.toString();
};

const greaterEqualThanDateTime = (
  valueTime,
  valueDate,
  minValueArg,
  showTime = false
) => {
  const minValue =
    minValueArg instanceof Date ? minValueArg : new Date(minValueArg);
  const value = new Date(
    `${formatDate(valueDate, "Y-m-d")} ${formatDate(valueTime, "H:i:s")}`
  );
  return value && value < minValue
    ? __("Data nie może być wcześniejsza niż {0}", [
        showTime
          ? minValue.toLocaleString("pl-PL")
          : minValue.toLocaleDateString("pl-PL"),
      ])
    : null;
};

export const allowedExtensions = (files = [], extensions = []) => {
  let valid = true;
  for (let i = 0; i < files?.length; i += 1) {
    const file = files[i];
    if (file && file.name) {
      const extension = file.name.split(".").pop();
      if (!extensions.includes(extension)) {
        valid = false;
        break;
      }
    }
  }
  return valid
    ? null
    : __("Dozwolone rozszerzenia pliku: {0}", [extensions.join(", ")]);
};

const lessEqualThanDate = (value, maxValueArg) => {
  const maxValue =
    maxValueArg instanceof Date ? maxValueArg : new Date(maxValueArg);
  return value && value > maxValue
    ? __("Data nie może być późniejsza niż {0}", [
        maxValue.toLocaleDateString("pl-PL"),
      ])
    : null;
};

const rangeRequiredBoth = (fieldValue) => {
  const value = fieldValue || {};
  if (value && value.from && value.to) {
    return null;
  }
  return JSON.stringify({
    from: value.from ? null : __("To pole jest wymagane"),
    to: value.to ? null : __("To pole jest wymagane"),
  });
};

const arrayNotEmptyFields = (fieldValue) =>
  fieldValue.every((arrEl) => arrEl.length > 0)
    ? null
    : __("Żadna z opcji nie może pozostać pusta");

const extendDateRequired = (fieldValue) => {
  const value = fieldValue || {};
  if (
    value &&
    value.fromDay &&
    value.toDay &&
    value.months &&
    value.months.length > 0 &&
    value.startDate
  ) {
    return null;
  }
  return JSON.stringify({
    fromDay: value.fromDay ? null : __("To pole jest wymagane"),
    toDay: value.toDay ? null : __("To pole jest wymagane"),
    startDate: value.startDate ? null : __("To pole jest wymagane"),
    months:
      value.months && value.months.length > 0
        ? null
        : __("To pole jest wymagane"),
  });
};

const datetimeRequired = (fieldValue) => {
  const value = fieldValue || {};
  if (value && value.date && value.time) {
    return null;
  }
  return JSON.stringify({
    date: value.date ? null : __("To pole jest wymagane"),
    time: value.time ? null : __("To pole jest wymagane"),
  });
};

const rangeRequiredFrom = (fieldValue) => {
  const value = fieldValue || {};
  if (value && value.from) {
    return null;
  }

  return JSON.stringify({
    from: __("To pole jest wymagane"),
    to: null,
  });
};

const ipv4 = (fieldValue) => {
  if (!fieldValue) {
    return null;
  }
  /* eslint-disable max-len */
  const ipFormat =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2])|-\d{1,3})?$/;
  /* eslint-enable max-len */
  return fieldValue.match(ipFormat) ? null : __("Nieprawidłowy adres IPv4");
};
const url = (fieldValue) => {
  if (!fieldValue) {
    return null;
  }
  /* eslint-disable max-len */
  const urlFormat =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  /* eslint-enable max-len */
  return fieldValue.match(urlFormat)
    ? null
    : __(
        "Niepoprawny adres URL. Wymagany format http(s)://(www.)domena.com/(ścieżka)(?parametry)"
      );
};

const rangeRequiredTo = (fieldValue) => {
  const value = fieldValue || {};
  if (value && value.to) {
    return null;
  }
  return JSON.stringify({
    from: null,
    to: __("To pole jest wymagane"),
  });
};

const rangeRequiredDependable = (fieldValue) => {
  const value = fieldValue || {};
  if ((value && value.from && value.to) || (!value.to && !value.from)) {
    return null;
  }
  return JSON.stringify({
    from: value.from ? null : __("To pole jest wymagane"),
    to: value.to ? null : __("To pole jest wymagane"),
  });
};

const mustBeEqual = (value, referenceValue, errorMessage) =>
  value === referenceValue ? null : __(errorMessage);

const phone = (value) =>
  !value || value.match(/^(\+?)?[\d -]{9,15}$/)
    ? null
    : __("Nieprawidłowy format numeru telefonu");

const nip = (value) =>
  !value || value.match(/^\d{10}$/)
    ? null
    : __("Nieprawidłowy format numeru NIP");

const krs = (value) =>
  !value || value.match(/^\d{10}$/)
    ? null
    : __("Nieprawidłowy format numeru KRS");

const fax = (value) =>
  !value || value.match(/^\+?[0-9]+$/)
    ? null
    : __("Nieprawidłowy format numeru fax");

const email = (value) =>
  !value || value.match(/^.+@\S+\.\S+$/)
    ? null
    : __("Nieprawidłowy format adresu e-mail");

const emailList = (value) => {
  // eslint-disable-next-line max-len
  const regex =
    /^(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-.]))))*$/;
  return !value || regex.test(value)
    ? null
    : __("Niepoprawny format listy adresów");
};

const regon = (value) =>
  !value || value.match(/^(\d{7}|\d{9})$/)
    ? null
    : __("Nieprawidłowy format numeru REGON");

const postCode = (value) =>
  !value || value.match(/^([0-9]{2})(-[0-9]{3})?$/)
    ? null
    : __("Nieprawidłowy kod pocztowy");

const numberList = (value) =>
  !value || value.match(/^([0-9]+(\.[0-9]+)?)((;[0-9]+(\.[0-9]+)?)?)+$/)
    ? null
    : __("Nieprawidłowy format listy");

const numberListMax = (value, maxValue) =>
  value && value.split(";").find((el) => el > maxValue)
    ? __("Maksymalna wartość to {0}", [maxValue])
    : null;

const password = (value) => {
  if (!value) {
    return null;
  }
  if (!value.match(/(?=^.{8,}$)/)) {
    return __("Hasło musi składać się przynajmniej z 8 znaków");
  }
  if (!value.match(/(?=.*\d)/)) {
    return __("Hasło musi mieć przynajmniej jedną cyfrę");
  }
  if (!value.match(/(?=.*[^a-zA-Z\dĄŹŻŁŚĆÓążźłśćó]+)/)) {
    return __("Hasło musi zawierać przynajmniej jeden znak specjalny");
  }
  if (!value.match(/(?=.*[A-ZĄŹŻŁŚĆÓ])/)) {
    return __("Hasło musi zawierać przynajmniej jedną wielką literę");
  }
  if (!value.match(/(?=.*[a-zążźłśćó])/)) {
    return __("Hasło musi zawierać przynajmniej jedną małą literę");
  }
  if (!value.match(/(^\S*$)/)) {
    return __("Hasło nie może zawierać białych znaków");
  }

  return null;
};

const customValidation = (value, method) => (method ? method(value) : null);
