import React, { useState } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { useCompanyData } from "src/Pages/Company/CompanyContext";
import ContentLoading from "src/Components/Loading/contentLoading";
import __ from "src/utils/Translations";

export default () => {
  const [showToken, setShowToken] = useState(false);
  const {
    id: companyId,
    fullName: companyName,
    reportAccessPassword,
  } = useCompanyData();
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`${__(
            "Dostępy dla firmy"
          )} ${companyName} (ID: ${companyId})`}
          pushToHistory
          breadcrumbs={[]}
        />
        <Card>
          <CardHeader>{__("Token dostępowy")}</CardHeader>
          <CardBody>
            <ContentLoading show={!companyName}>
              <InputGroup>
                <Input
                  disabled
                  type={showToken ? "text" : "password"}
                  value={reportAccessPassword}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    data-t1="accessTokenShow"
                    onClick={() => setShowToken(!showToken)}
                  >{`${__("Pokaż token")}`}</Button>
                </InputGroupAddon>
              </InputGroup>
            </ContentLoading>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </>
  );
};
