import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";

export default function ParametersModal({ isOpen, close, parameters }) {
  const renderParams = () =>
    parameters?.map((item) => (
      <li key={item.label}>
        {__(item.label)}
        {": "}
        {item.values?.map((v) => `${__(v.label)}, `)}
      </li>
    ));

  return (
    <Modal isOpen={isOpen} toggle={close} unmountOnClose size="sm">
      <ModalHeader toggle={close}>{__("Parametry")}</ModalHeader>
      <ModalBody>
        <ul>{renderParams()}</ul>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={close}>
          {__("Wróć")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ParametersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  parameters: PropTypes.arrayOf.isRequired,
};
