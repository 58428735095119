export const getAnixeValue = (anixeData, field) => {
  let value = getAnixeValueForLanguage(anixeData, field, "pl");
  if (!value) {
    value = getAnixeValueForLanguage(anixeData, field, "en");
  }
  if (!value) {
    const languages = Object.keys(anixeData);
    for (let i = 0; i < languages.length; i += 1) {
      const language = languages[i];
      const languageValue = getAnixeValueForLanguage(
        anixeData,
        field,
        language
      );
      if (languageValue) {
        value = languageValue;
        break;
      }
    }
  }
  return value;
};

export const getAnixeValueForLanguage = (anixeData, field, language) =>
  anixeData?.[language]?.[field];

export const getAnixeValueInAllLanguages = (anixeData, field) => {
  const result = { pl: "" };
  const languages = Object.keys(anixeData).sort(sortLanguages);
  languages.forEach((language) => {
    const value = getAnixeValueForLanguage(anixeData, field, language);
    if (value) {
      result[language] = value;
    }
  });
  return result;
};

export const sortLanguages = (a, b) => {
  if (b === "pl") {
    return 1;
  }
  if (b === "en" && a !== "pl") {
    return 1;
  }
  return -1;
};

export const decodeHtml = (text) => {
  const entities = {
    amp: "&",
    apos: "'",
    "#x27": "'",
    "#x2F": "/",
    "#39": "'",
    "#47": "/",
    lt: "<",
    gt: ">",
    nbsp: " ",
    quot: '"',
  };
  // const t1 = text.replace(/\\u([a-f0-9]{4})/gi, (match, code) => String.fromCharCode(parseInt(code, 16)));
  return text.replace(
    /&([^;]+);/gm,
    (match, entity) => entities[entity] || match
  );
};
