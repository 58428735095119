import { useEffect, useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function (companyId) {
  const [perks, setPerks] = useState([]);

  useEffect(() => {
    restApiRequest(API_TOTALREWARD_SERVICE, "/omb/perks", "GET", {
      params: {
        tenant: companyId,
      },
    })
      .then((resData) => {
        setPerks(resData.map((c) => ({ value: c.id, label: c.title })));
      })
      .catch((e) => {
        newRelicErrorReport(
          e,
          "/src/Pages/Company/TotalReward/Perks/useTotalRewardPerks.js - 11"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać benefitów"),
          "error"
        );
        setPerks([]);
      });
  }, [companyId]);

  return perks;
}
