import { useEffect, useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function (companyId) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await restApiRequest(
          API_TOTALREWARD_SERVICE,
          "/omb/config/dictionary",
          "GET",
          {
            params: {
              tenant: companyId,
            },
          },
          mockData
        );
        setData(result);
        setIsLoading(false);
        setIsSuccess(true);
      } catch (e) {
        newRelicErrorReport(
          e,
          "/src/Pages/Company/TotalReward/Settings/useQueryTotalRewardDictionary.js - 14"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać konfiguracji"),
          "error"
        );
        setData([]);
      }
    };
    fetchData();
  }, [companyId]);

  return { data, isLoading, isSuccess };
}

const mockData = [
  {
    key: "your_benefits",
    title: "Twoje benefity",
    type: "boolean",
    defaultValue: true,
    roles: "ROLE_AHR",
    pwa: true,
  },
  {
    key: "available_benefits",
    title: "Dost\u0119pne benefity",
    type: "boolean",
    defaultValue: false,
    roles: "ROLE_AHR",
    pwa: true,
  },
  {
    key: "import_date_from",
    title: "Data importu",
    type: "date",
    defaultValue: "2024-04-02",
    roles: "ROLE_OMB",
    pwa: false,
  },
];
