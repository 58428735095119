/* eslint-disable react/prop-types */
import React from "react";
import FilterPriceRange from "./FilterPriceRange";

export const priceFilterMethod = ({ id, value }, row) => {
  const rowValue = row[id];
  if (!value || (!value.to && !value.from)) {
    return true;
  }
  if (!rowValue) {
    return false;
  }
  if (value.from > rowValue) {
    return false;
  }
  return value.to >= rowValue;
};

const PriceFilter =
  () =>
  ({ filter, onChange, column }) =>
    (
      <FilterPriceRange
        id={column.id}
        value={filter ? filter.value : { from: "", to: "" }}
        onChange={onChange}
      />
    );

export default PriceFilter;
