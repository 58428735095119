import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import __ from "src/utils/Translations";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { filterNavItemsByAlc } from "src/utils/RoleBasedSecurity/filters";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { useCompanyData } from "src/Pages/Company/CompanyContext";
import AnchorTag from "./AnchorTag";

export default function TilesMenu({
  title,
  breadcrumbs,
  config,
  pushToHistory,
}) {
  const companyData = useCompanyData();
  const { userInfo } = useContext(RbsContext);

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
        style={{ margin: "0 30px 30px" }}
      >
        <PageTitle
          pushToHistory={pushToHistory}
          heading={title}
          breadcrumbs={breadcrumbs}
        />
        <div className="grid-menu grid-menu-4col">
          <Row className="no-gutters" data-t1="tilesMenu">
            {filterNavItemsByAlc(config, userInfo, companyData).map(
              ({ to, href, label, icon, tileIcon, id, notDisplay }) =>
                !notDisplay && (
                  <Col md="3" sm="6" className="bg-white" key={id}>
                    <AnchorTag to={to} href={href} id={id}>
                      <div className="widget-chart widget-chart-hover">
                        <div className="icon-wrapper rounded-circle">
                          <div className="icon-wrapper-bg bg-primary" />
                          <i className={`${tileIcon || icon} text-primary`} />
                        </div>
                        <div className="widget-numbers-sm">{__(label)}</div>
                      </div>
                    </AnchorTag>
                  </Col>
                )
            )}
          </Row>
        </div>
      </CSSTransitionGroup>
    </>
  );
}
TilesMenu.propTypes = {
  title: PropTypes.string.isRequired,
  pushToHistory: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  config: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      tileIcon: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
TilesMenu.defaultProps = {
  breadcrumbs: [],
  pushToHistory: false,
};
