import isJson from "src/utils/jsHelpers/isJson";

const parseCompanyData = (data, currentCompanyId) => {
  const companyData = {};

  data.forEach(({ companyId, path, value }) => {
    if (companyId === currentCompanyId) {
      if (isJson(value)) {
        companyData[path] = JSON.parse(value);
      } else {
        companyData[path] = value;
      }
    }
  });

  return companyData;
};

export default parseCompanyData;
