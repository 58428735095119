import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import __ from "src/utils/Translations";
import { downloadFile, getFullFileName } from "src/utils/Api";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import DropdownSecurityWrapper from "src/Components/DataTableControlled/DropdownSecurityWrapper";
import {
  buildExportPath,
  getDropdownItems,
} from "src/Components/DataTableControlled/utils";
import { MAX_EXPORT_ITEMS } from "src/Components/DataTableControlled/consts";

const ExportButton = ({
  filters,
  path,
  additionalPath,
  getIsAdditionalPathVisible,
  page,
  pageSize,
  service,
  count,
  totalCount,
  sort,
  permissions,
  fileName,
  handleAdditionalFilters,
  noResults,
  hideIfNoPermission,
  buttonTitle,
  customDropdownItems,
  withoutPermission,
}) => {
  const hasPermissions = useHasPermission(permissions);

  const getPath = useCallback(
    (size) =>
      typeof getIsAdditionalPathVisible === "function" &&
      getIsAdditionalPathVisible(size)
        ? additionalPath
        : path,
    [additionalPath, path, getIsAdditionalPathVisible]
  );

  const pathForMaxExportItems = useMemo(
    () => getPath(totalCount || MAX_EXPORT_ITEMS),
    [totalCount, getPath]
  );
  const pathForPageSizeItems = useMemo(
    () => getPath(pageSize),
    [pageSize, getPath]
  );
  const pathForCustomSizeItems = useMemo(
    () => getPath(count),
    [count, getPath]
  );

  if (hideIfNoPermission && !hasPermissions && !withoutPermission) return null;

  return (
    <UncontrolledButtonDropdown size="lg" className="mr-2">
      <DropdownSecurityWrapper
        withoutPermission={withoutPermission}
        permissions={permissions}
      >
        <DropdownToggle caret color="secondary">
          <span data-t1="export">{__(buttonTitle)}</span>
        </DropdownToggle>
      </DropdownSecurityWrapper>
      <DropdownMenu positionFixed>
        {customDropdownItems.length
          ? customDropdownItems.map(
              (
                { title, dataT1, fileType, pageSize = MAX_EXPORT_ITEMS },
                index
              ) => (
                <DropdownItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${title}-${index}`}
                  onClick={async () =>
                    downloadFile(
                      service,
                      await buildExportPath(
                        pathForPageSizeItems,
                        [],
                        handleAdditionalFilters,
                        sort,
                        pageSize
                      ),
                      getFullFileName(fileName, true, fileType)
                    )
                  }
                >
                  <span data-t1={dataT1}>{__(title)}</span>
                </DropdownItem>
              )
            )
          : getDropdownItems({
              noResults,
              service,
              filters,
              sort,
              pageSize,
              page,
              fileName,
              pathForPageSizeItems,
              pathForCustomSizeItems,
              pathForMaxExportItems,
              handleAdditionalFilters,
            })}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ExportButton.propTypes = {
  service: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  additionalPath: PropTypes.string,
  getIsAdditionalPathVisible: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  count: PropTypes.number,
  totalCount: PropTypes.number,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  fileName: PropTypes.string.isRequired,
  noResults: PropTypes.bool,
  permissions: PropTypes.string,
  handleAdditionalFilters: PropTypes.func,
  hideIfNoPermission: PropTypes.bool,
  buttonTitle: PropTypes.string,
  customDropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataT1: PropTypes.string,
      fileType: PropTypes.string,
    })
  ),
  withoutPermission: PropTypes.bool,
};

ExportButton.defaultProps = {
  additionalPath: "",
  getIsAdditionalPathVisible: null,
  permissions: null,
  handleAdditionalFilters: null,
  noResults: false,
  count: 0,
  totalCount: 0,
  sort: null,
  hideIfNoPermission: false,
  buttonTitle: "Eksportuj",
  customDropdownItems: [],
  withoutPermission: false,
};
export default ExportButton;
