import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";
import Form from "src/Components/Form";
import {
  NOTIFICATION_SERVICE,
  restApiRequest,
  TRANSLATOR_SERVICE,
} from "src/utils/Api";
import DataLoading from "src/Components/Loading/dataLoading";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import PageTitle from "src/Layout/AppMain/PageTitle";
import useEmployeeGroups from "src/utils/hooks/company/useEmployeeGroups";
import {
  getCompaniesOptionsFetchMethod,
  getEmployeesOptionsFetchMethod,
} from "src/Components/FormElements/Autocomplete/commonFetchMethods";
import { notificationAppMessagePermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import { getMinDate } from "src/Pages/Company/CompanyBanks/Management/Edit/PointsChanges/SingleCharges/utils";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import useOrganizationUnits from "src/utils/hooks/company/useOrganizationUnits";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import translateLabels from "src/utils/Translations/translateLabels";

export default function EditForm({
  company,
  companyScope,
  appMessageId,
  breadcrumbs,
  listingPath,
}) {
  const isNew = appMessageId === "-1";
  const [data, updateData] = useState(
    isNew
      ? { visibleFrom: new Date(), employeeId: [], displayAsPopup: false }
      : null
  );
  const [originalData, updateOriginalData] = useState(null);
  const [contentTranslation, setContentTranslation] = useState([]);
  const history = useHistory();
  const companyId = companyScope ? company : data?.companyId;
  const employeeGroups = useEmployeeGroups(
    true,
    "companyId",
    companyId,
    false,
    !companyId
  );
  const organizationUnits = useOrganizationUnits(
    true,
    "companyId",
    companyId,
    false,
    !companyId
  );

  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();

  const onChange = (key, value) => {
    const updatedData = { ...data };
    updatedData[key] = value;
    updateData(updatedData);
  };
  const onChangeCompany = (key, value) => {
    if (value !== data?.companyId) {
      const updatedData = { ...data };
      updatedData[key] = value;
      updatedData.employeeGroupId = null;
      updatedData.employeeId = null;
      updateData(updatedData);
    }
  };

  const onChangeEmployee = (key, value, isRemoveAction) => {
    let employeesIds = value;

    if (!isRemoveAction && data[key]?.length && Array.isArray(value)) {
      employeesIds = [...data[key]];
      // eslint-disable-next-line array-callback-return
      value.map((id) => {
        if (!employeesIds.includes(id)) {
          employeesIds.push(id);
        }
      });
    }
    onChange(key, employeesIds);
  };

  useEffect(() => {
    if (companyScope && data?.companyId !== company) {
      updateData({ ...data, companyId: company });
    }
  }, [company, companyScope, data]);

  const receiverScope = data ? data.receiverScope : null;
  const companyInputEnabled =
    !companyScope &&
    [
      RECEIVER_SCOPE_COMPANY,
      RECEIVER_SCOPE_EMPLOYEE_GROUP,
      RECEIVER_SCOPE_EMPLOYEE,
      RECEIVER_SCOPE_ORGANIZATION_UNIT,
    ].includes(receiverScope);
  const employeeGroupInputEnabled =
    receiverScope === RECEIVER_SCOPE_EMPLOYEE_GROUP;
  const employeeInputEnabled = receiverScope === RECEIVER_SCOPE_EMPLOYEE;
  const organizationUnitInputEnabled =
    receiverScope === RECEIVER_SCOPE_ORGANIZATION_UNIT;

  const submit = async () => {
    try {
      const method = isNew ? "POST" : "PATCH";
      const path = isNew ? "/app-messages" : `/app-messages/${appMessageId}`;
      const {
        receiverScope: scope,
        companyId: companyUuid,
        employeeGroupId,
        organizationUnitId,
        employeeId,
        visibleTo,
        displayAsPopup,
        ...body
      } = data;

      const { id } = await restApiRequest(
        NOTIFICATION_SERVICE,
        path,
        method,
        {
          body: {
            ...body,
            companyId: companyInputEnabled || companyScope ? companyUuid : null,
            employeeGroupId: employeeGroupInputEnabled ? employeeGroupId : null,
            organizationUnitId: organizationUnitInputEnabled
              ? organizationUnitId
              : null,
            employeeId:
              employeeInputEnabled && employeeId?.length === 1
                ? employeeId[0]
                : null,
            employeesId:
              employeeInputEnabled && employeeId?.length > 1 ? employeeId : [],
            displayAsPopup,
            ...(displayAsPopup ? { visibleTo } : {}),
          },
        },
        data
      );

      if (isNew && contentTranslation.length && id) {
        const resultContentTranslation = [
          {
            ...contentTranslation[0],
            code: `notification:appMessages:${id}:message`,
          },
        ];
        await restApiRequest(
          TRANSLATOR_SERVICE,
          "/cms/mass-save",
          "POST",
          {
            body: {
              data: resultContentTranslation,
            },
          },
          null
        );
      }

      dynamicNotification(__("Pomyślnie zapisano wiadomość"));
      history.push(listingPath);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Notification/AppMessages/Edit/edit.js - 160"
      );
      dynamicNotification(
        e.message || __("Nie udało się zapisać wiadomości"),
        "error"
      );
    }
  };

  const blockVisibleFromChange =
    !isNew && new Date(originalData?.visibleFrom) < new Date();
  const formData = data || {};
  const minDate = getMinDate(0);

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        // eslint-disable-next-line max-len
        heading={
          isNew
            ? `${__("Nowa wiadomość")}`
            : `${__("Edycja wiadomości")}${
                originalData
                  ? ` ${originalData.subject} (ID: ${originalData.id})`
                  : ""
              }`
        }
        breadcrumbs={breadcrumbs}
        pushToHistory={!isNew}
      />
      <DataLoading
        service={NOTIFICATION_SERVICE}
        fetchedData={data !== null}
        updateData={(res) => {
          const employeeId = res?.employeeId ? [res.employeeId] : "";
          const updatedData = {
            ...res,
            receiverScope: getReceiverScope(res),
            employeeId: res?.employeesId?.length ? res.employeesId : employeeId,
          };
          updateData(updatedData);
          updateOriginalData(updatedData);
        }}
        endpoint={`/app-messages/${appMessageId}`}
        mockDataEndpoint="/app-messages/edit"
        isNew={isNew}
      >
        <Form
          id="appMessageForm"
          data={{
            ...formData,
            companyId: companyInputEnabled ? formData.companyId : null,
            employeeGroupId: employeeGroupInputEnabled
              ? formData.employeeGroupId
              : null,
            employeeId: employeeInputEnabled ? formData.employeeId : null,
          }}
          config={{
            title: isNew ? __("Nowa wiadomość") : __("Edycja wiadomości"),
            stickyTitle: true,
            onSubmit: submit,
            buttons: [
              {
                size: "lg",
                color: "light",
                className: "mr-2",
                text: "Wróć",
                href: listingPath,
                id: "appMessageBack",
              },
              {
                size: "lg",
                color: "success",
                className: "mr-2",
                text: "Zapisz",
                type: "submit",
                id: "appMessageSubmit",
                permission: notificationAppMessagePermissionWrite,
              },
            ],
            defaultOnChange: onChange,
            formGroups: [
              {
                title: __("Dane"),
                formElements: [
                  {
                    id: "receiverScope",
                    label: "Odbiorca wiadomości",
                    type: "radio",
                    validation: ["required"],
                    options:
                      companyScope || isAhr
                        ? translateLabels(getReceiverCompanyScopeOptions(isAhr))
                        : translateLabels(receiverScopeOptions),
                    props: { disabled: !isNew },
                  },
                  {
                    id: "companyId",
                    label: "Firma:",
                    validation: companyInputEnabled ? ["required"] : null,
                    type: "asyncAutocomplete",
                    onChange: onChangeCompany,
                    fetchOptions: getCompaniesOptionsFetchMethod(
                      data ? data.companyId : null,
                      100
                    ),
                    props: {
                      disabled: !companyInputEnabled,
                    },
                    displayCondition: !companyScope,
                  },
                  {
                    id: "employeeGroupId",
                    label: "Grupa pracownicza:",
                    type: "autocomplete",
                    validation: employeeGroupInputEnabled ? ["required"] : null,
                    props: {
                      disabled:
                        !(data && data.companyId) || !employeeGroupInputEnabled,
                      isFieldValid: employeeGroupInputEnabled,
                    },
                    tooltip: !companyScope
                      ? {
                          content: __(
                            "Aby pojawiły się dostępne opcje najpierw należy wybrać firmę"
                          ),
                        }
                      : null,
                    options: employeeGroups,
                  },
                  {
                    id: "organizationUnitId",
                    label: "Jednostka organizacyjna:",
                    type: "autocomplete",
                    validation: organizationUnitInputEnabled
                      ? ["required"]
                      : null,
                    props: {
                      disabled:
                        !(data && data.companyId) ||
                        !organizationUnitInputEnabled,
                      isFieldValid: organizationUnitInputEnabled,
                    },
                    tooltip: !companyScope
                      ? {
                          content: __(
                            "Aby pojawiły się dostępne opcje najpierw należy wybrać firmę"
                          ),
                        }
                      : null,
                    options: organizationUnits,
                  },
                  {
                    id: "employeeId",
                    label: "Pracownicy:",
                    type: "asyncAutocompleteMultiselect",
                    validation: employeeInputEnabled ? ["required"] : null,
                    props: {
                      disabled:
                        !(data && data.companyId) || !employeeInputEnabled,
                      isFieldValid: employeeInputEnabled,
                    },
                    tooltip: {
                      content: (
                        <ul className="text-left m-0 pl-3">
                          {!companyScope && (
                            <li>
                              {__(
                                "Aby pojawiły się dostępne opcje najpierw należy wybrać firmę"
                              )}
                            </li>
                          )}
                          <li>
                            {__(
                              "Aby wysłać wiadomość do pracowników wyszukaj ich poprzez wpisanie minimum 3 znaków w polu i wybierz z listy"
                            )}
                          </li>
                        </ul>
                      ),
                    },
                    onChange: onChangeEmployee,
                    fetchOptions: getEmployeesOptionsFetchMethod(
                      data?.employeeId,
                      { companyId: data?.companyId }
                    ),
                  },
                  {
                    id: "subject",
                    label: "Tytuł:",
                    type: "text",
                    validation: [
                      "required",
                      { method: "minLength", args: [3] },
                    ],
                    translatable: {
                      scope: "notification:appMessages:subject",
                    },
                    tooltip: {
                      content: __(
                        "Aby dodać wersję angielską wpisz treść dla wersji polskiej, a następnie kliknij ikonę słownika."
                      ),
                    },
                  },
                  {
                    id: "message",
                    label: "Zawartość:",
                    type: "wysiwyg",
                    validation: ["required"],
                    translatable: isNew
                      ? false
                      : {
                          isCms: true,
                          code: `notification:appMessages:${
                            originalData?.multiselectId ?? appMessageId
                          }:message`,
                          onTranslatorSubmit: isNew
                            ? (translation) =>
                                setContentTranslation(translation)
                            : null,
                        },
                    translatedValue: isNew
                      ? contentTranslation[0]?.translation
                      : "",
                    uploadService: NOTIFICATION_SERVICE,
                    tooltip: {
                      content: __(
                        "Aby dodać wersję angielską wpisz treść dla wersji polskiej, a następnie kliknij ikonę słownika."
                      ),
                    },
                  },
                  {
                    id: "visibleFrom",
                    label: "Wyświetlaj od:",
                    type: "date",
                    validation: [
                      "required",
                      {
                        method: "greaterEqualThanDate",
                        args: [minDate, true],
                      },
                    ],
                    minDate,
                    props: {
                      disabled: blockVisibleFromChange,
                    },
                    tooltip: blockVisibleFromChange
                      ? {
                          content: __(
                            "Nie można edytować daty widoczności dla już opublikowanej wiadomości."
                          ),
                        }
                      : undefined,
                    showTimeSelect: true,
                  },
                  {
                    id: "createdAt",
                    label: "Data utworzenia:",
                    type: "date",
                    showTimeSelect: true,
                    displayCondition: !isNew,
                    props: {
                      disabled: true,
                    },
                  },
                  {
                    id: "sendEmailToEmployeeAtVisibleFrom",
                    type: "boolean",
                    label: __(
                      `Wyślij powiadomienie do pracownika na służbowy adres e-mail w dniu ustawionym jako "wyświetlaj od”`
                    ),
                    props: {
                      disabled:
                        Date.now() > new Date(data?.visibleFrom).getTime(),
                    },
                  },
                  {
                    id: "displayAsPopup",
                    type: "boolean",
                    label: __("Wyświetlanie popupu"),
                  },
                  {
                    id: "visibleTo",
                    label: "Wyświetlaj do:",
                    type: "date",
                    validation: [
                      "required",
                      {
                        method: "greaterEqualThanDate",
                        args: [new Date(data?.visibleFrom), true],
                      },
                    ],
                    minDate: new Date(data?.visibleFrom),
                    showTimeSelect: true,
                    displayCondition: data?.displayAsPopup,
                  },
                ],
              },
            ],
          }}
        />
      </DataLoading>
    </CSSTransitionGroup>
  );
}

export const RECEIVER_SCOPE_ALL = "all";
export const RECEIVER_SCOPE_COMPANY = "company";
export const RECEIVER_SCOPE_EMPLOYEE_GROUP = "employeeGroup";
export const RECEIVER_SCOPE_EMPLOYEE = "employee";
export const RECEIVER_SCOPE_ORGANIZATION_UNIT = "organizationUnit";

const getReceiverCompanyScopeOptions = (isAhr = false) => [
  {
    value: RECEIVER_SCOPE_COMPANY,
    label: isAhr
      ? "Wszyscy pracownicy do których AHR ma dostęp"
      : "Wszyscy pracownicy wybranej firmy",
  },
  {
    value: RECEIVER_SCOPE_EMPLOYEE_GROUP,
    label: "Wszyscy pracownicy wybranej grupy pracowniczej",
  },
  {
    value: RECEIVER_SCOPE_ORGANIZATION_UNIT,
    label: "Wszyscy pracownicy wybranej jednostki organizacyjnej",
  },
  { value: RECEIVER_SCOPE_EMPLOYEE, label: "Wybrani pracownicy" },
];

const receiverScopeOptions = [
  { value: RECEIVER_SCOPE_ALL, label: "Wszyscy pracownicy w systemie" },
  ...getReceiverCompanyScopeOptions(),
];
export const getReceiverScope = (object) => {
  let scope = RECEIVER_SCOPE_ALL;
  if (object.employeeId) {
    scope = RECEIVER_SCOPE_EMPLOYEE;
  } else if (object.employeeGroupId) {
    scope = RECEIVER_SCOPE_EMPLOYEE_GROUP;
  } else if (object.organizationUnitId) {
    scope = RECEIVER_SCOPE_ORGANIZATION_UNIT;
  } else if (object.companyId) {
    scope = RECEIVER_SCOPE_COMPANY;
  }
  return scope;
};

EditForm.propTypes = {
  company: PropTypes.string,
  companyScope: PropTypes.bool,
  appMessageId: PropTypes.string.isRequired,
  listingPath: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
};
EditForm.defaultProps = {
  company: null,
  companyScope: false,
};
