import React from "react";
import styled from "styled-components";
import { element } from "prop-types";

import __ from "src/utils/Translations";
import isUuid from "src/utils/jsHelpers/isUuid";
import { BusinessId } from "src/Components/DataTable/businessIdColumn";

const LINE_HEIGHT = 1.4;

const SimpleList = ({ children }) => <ul>{children}</ul>;

SimpleList.propTypes = {
  children: element.isRequired,
};

const mapValueFromOptions = (
  options,
  key,
  optionValue = "value",
  optionLabel = "label",
  commaStyledList = false,
  maxVisibleRowsNumber,
  checkAsBoolean
) => {
  const renderEditable = (cellInfo) => {
    const value = cellInfo.row[key];
    if (!Array.isArray(value)) {
      const matchedOption = options.find((option) =>
        checkAsBoolean
          ? option[optionValue] === !!value
          : option[optionValue] === value
      );
      if (!matchedOption && isUuid(value)) {
        return <BusinessId value={value} />;
      }
      const result = matchedOption ? matchedOption[optionLabel] : value;
      return <div className="tableColumnStatus">{__(result)}</div>;
    }

    const commaStyledListComp = styled.ul`
      display: inline;
      list-style: none;
      padding-left: 0px;
      margin-bottom: 0px;
      > li {
        display: inline;
        list-style: none;
        &::after {
          content: ", ";
        }
        &:last-child::after {
          content: "";
        }
      }
    `;

    const rowsLimitedStyledListComp = styled.ul`
      width: 100%;
      max-height: ${LINE_HEIGHT * maxVisibleRowsNumber}em;
      line-height: ${LINE_HEIGHT}em;
      overflow-y: auto;
      word-wrap: break-word;
    `;

    let List;

    switch (true) {
      case commaStyledList:
        List = commaStyledListComp;
        break;
      case !!maxVisibleRowsNumber:
        List = rowsLimitedStyledListComp;
        break;
      default:
        List = SimpleList;
        break;
    }

    return (
      <List>
        {value.map((elValue) => {
          const matchedOption = options.find(
            (option) => option[optionValue] === elValue
          );
          const result = matchedOption
            ? matchedOption[optionLabel]
            : `${__("Nieznany zasób")}: ${elValue}`;
          return <li key={elValue}>{result}</li>;
        })}
      </List>
    );
  };
  return renderEditable;
};

export default mapValueFromOptions;
