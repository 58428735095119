import { useState, useEffect } from "react";
import { EMPLOYEE_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { mockData } from "src/Pages/Company/EmployeeManagement/CompanyEmployees/List/list";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import { employeeEmployeePermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function useAhrs(
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false,
  noFetching = false
) {
  const [data, setData] = useState([]);

  const hasPermission = useHasPermission(employeeEmployeePermissionRead);
  const skipAction = !hasPermission || noFetching;
  useEffect(() => {
    if (skipAction) {
      setData([]);
      return;
    }

    const params = { ahr: true, itemsPerPage: 10000 };
    if (filterField && filterValue) {
      params[filterField] = filterValue;
    }
    restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      "/employees",
      "GET",
      { params },
      mockData
    )
      .then((resData) => {
        let result = resData;
        if (idAsIri) {
          result = result.map((el) => ({
            ...el,
            id: `${IRI_PREFIX}/${el.id}`,
          }));
        }
        if (asOption) {
          result = result.map(({ firstName, lastName, id }) => ({
            value: id,
            label: `${firstName} ${lastName}`,
          }));
        }
        setData(result);
      })
      .catch((e) => {
        newRelicErrorReport(e, "utils/hooks/company/useAhrs.js - 55");
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy administratorów"),
          "error"
        );
      });
  }, [filterField, filterValue, idAsIri, asOption, skipAction]);

  return data;
}

export const IRI_PREFIX = "/api/employee-management/v1/rest/employees";
