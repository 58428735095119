/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Loader } from "react-loaders";
import __ from "src/utils/Translations";
import { getDateCell, priceColumn } from "src/Components/DataTable/commonCells";
import {
  LOADING_FALLBACK_VALUE,
  PENDING_BENEFIT_FILTER_GROUPS,
  parseDataToTable,
} from "src/Pages/Company/Pending/BenefitsPending/Listing/utils";
import useBenefits from "src/utils/hooks/benefit/useBenefits";
import {
  DESELECT_PAGE_ALL,
  SELECT_PAGE_ALL,
} from "src/Components/DataTableControlled/consts";
import { getIdFromIri } from "src/utils/jsHelpers/iriConverter";
import uniqueArray from "src/utils/jsHelpers/arrayUnique";
import useEmployees from "src/utils/hooks/company/useEmployees";
import useOrganizationUnits, {
  IRI_PREFIX as ORGANIZATION_UNIT_IRI_PREFIX,
} from "src/utils/hooks/company/useOrganizationUnits";
import pendingBenefitsContext from "src/Pages/Company/Pending/BenefitsPending/Listing/context";
import DataTable from "src/Components/DataTable";
import { useCompanyOverriddenLabels } from "src/Pages/Company/CompanyContext";
import translateLabels from "src/utils/Translations/translateLabels";
import FormsColumn from "./formsColumn";
import ActionsColumn from "./actionsColumn";
import StatusColumn from "./statusColumn";
import BenefitNameColumn from "./benefitNameColumn";

const ROW_SELECT_OPTIONS = [
  {
    value: SELECT_PAGE_ALL,
    label: "Zaznacz wszystkie na tej stronie",
  },
  {
    value: DESELECT_PAGE_ALL,
    label: "Odznacz wszystkie na tej stronie",
  },
];
export default function PendingBenefitsTable({
  subscriptionsData,
  updateItem,
  fetchData,
}) {
  const { massActionSelect, setMassActionSelect, onChangeMassActionSelectCb } =
    useContext(pendingBenefitsContext);

  const { organizationUnit } = useCompanyOverriddenLabels();

  const [tableData, setTableData] = useState([]);
  const [employeesIds, setEmployeesIds] = useState([]);
  const [benefitsIds, setBenefitsIds] = useState([]);
  const [defaultSorted, setDefaultSorted] = useState([]);
  const [organizationUnitsIds, setOrganizationUnitsIds] = useState([]);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const benefits = useBenefits(
    false,
    "id",
    benefitsIds,
    true,
    !benefitsIds.length
  );
  const employees = useEmployees(
    false,
    "id",
    employeesIds,
    false,
    !employeesIds.length,
    50
  );
  const organizationUnits = useOrganizationUnits(
    false,
    "id",
    organizationUnitsIds,
    true,
    !organizationUnitsIds.length
  );

  useEffect(() => {
    setTableData(
      parseDataToTable(
        subscriptionsData,
        employees,
        benefits,
        organizationUnits,
        isLazyLoading
      )
    );
  }, [
    subscriptionsData,
    employees,
    benefits,
    organizationUnits,
    isLazyLoading,
  ]);

  useEffect(() => {
    setBenefitsIds(
      subscriptionsData
        .map((ele) => getIdFromIri(ele.benefitId, ""))
        .filter(Boolean)
        .filter(uniqueArray)
    );
    setEmployeesIds(
      subscriptionsData
        .map(({ subscriptionItemOwnerId }) => subscriptionItemOwnerId)
        .filter(Boolean)
        .filter(uniqueArray)
    );
  }, [subscriptionsData]);

  useEffect(() => {
    setOrganizationUnitsIds(
      employees
        .map(({ organizationUnit }) =>
          getIdFromIri(organizationUnit, ORGANIZATION_UNIT_IRI_PREFIX)
        )
        .filter(Boolean)
        .filter(uniqueArray)
    );
  }, [employees]);

  useEffect(() => {
    setIsLazyLoading(true);
    const timeout = setTimeout(() => {
      setIsLazyLoading(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [employees, subscriptionsData]);

  const itHasAnyOrganizationUnits = tableData.some(
    (ele) =>
      ele.organizationUnit && ele.organizationUnit !== LOADING_FALLBACK_VALUE
  );

  const columns = [
    {
      Header: "Numer FK",
      accessor: "fk",
      Cell: lazyLoadingColumn,
    },
    {
      Header: "Imię",
      accessor: "firstName",
      Cell: lazyLoadingColumn,
    },
    {
      Header: "Nazwisko",
      accessor: "lastName",
      Cell: lazyLoadingColumn,
    },
    {
      Header: organizationUnit || "Jednostka Organizacyjna",
      accessor: "organizationUnit",
      Cell: lazyLoadingColumn,
      ahrNotDisplay: !itHasAnyOrganizationUnits,
    },
    {
      Header: "Benefit",
      accessor: "benefitName",
      Cell: BenefitNameColumn,
      minWidth: 250,
    },
    {
      Header: "Łączny koszt",
      accessor: "summaryAmount",
      Cell: priceColumn,
    },
    {
      Header: "Opłacone przez pracownika",
      accessor: "employeePrice",
      Cell: priceColumn,
    },
    {
      Header: "Status",
      accessor: "pendingStatuses",
      width: 220,
      updateItem,
      Cell: StatusColumn,
      refreshData: fetchData,
    },
    {
      Header: "Formularze",
      accessor: "pdfBarcodes",
      width: 250,
      sortable: false,
      updateItem,
      Cell: FormsColumn,
      refreshData: fetchData,
    },
    {
      Header: "Data wyboru",
      accessor: "createdAt",
      Cell: getDateCell("createdAt"),
    },
    {
      Header: "Akcja",
      accessor: "action",
      filterable: false,
      sortable: false,
      refreshData: () => fetchData(PENDING_BENEFIT_FILTER_GROUPS.DEFAULT),
      Cell: ActionsColumn,
    },
  ];

  return (
    <DataTable
      id="pendingBenefitsListing"
      data={tableData}
      defaultSorted={defaultSorted}
      setDefaultSorted={setDefaultSorted}
      filterable={false}
      onChangeMassActionSelectCb={onChangeMassActionSelectCb}
      columns={columns}
      massActionSelect={massActionSelect}
      setMassActionSelect={setMassActionSelect}
      selectionOptions={translateLabels(ROW_SELECT_OPTIONS)}
    />
  );
}

const lazyLoadingColumn = (cellInfo) => {
  const value = cellInfo.row[cellInfo.column.id];

  return (
    <div className="d-block w-100 text-center">
      {value === LOADING_FALLBACK_VALUE ? (
        <Loader active type="line-scale" style={{ transform: "scale(0.4)" }} />
      ) : (
        value
      )}
    </div>
  );
};

PendingBenefitsTable.propTypes = {
  subscriptionsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateItem: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};
