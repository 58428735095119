const prepareValidation = (validation, data, fieldId) => {
  let additionalValidation = [];
  // FEN-18522
  if (["work-place", "cost-place", "organization-unit"].includes(fieldId)) {
    additionalValidation = [
      {
        method: "minLength",
        args: [2],
      },
    ];
  }

  if (!Array.isArray(validation)) {
    return additionalValidation?.length ? additionalValidation : null;
  }
  const mappedValidation = validation.map((el) =>
    el.argsSource
      ? { ...el, args: el.argsSource.map((source) => data[source]) }
      : el
  );
  return [...mappedValidation, ...additionalValidation];
};

export default prepareValidation;
