const mockBenefitsData = [
  {
    id: "359f5137-2501-457e-9f9f-808909bfd961",
    name: "Abonament 1",
    supplierId: "Empik",
    code: "abonament_123",
    thresholdDay: 1,
    manualActivationRequired: false,
    manualActivationIsDefault: false,
    pendingDay: 1,
    description: "Abonament ...",
    descriptionIsDefault: false,
    rentableGroupIds: [
      "5d2fb30d-2d07-4f84-b2f6-30ced35c7539",
      "dd88a805-8e1c-4587-8f13-7a545c10732e",
    ],
    organizationUnitIds: [
      "86ebdc5d-495f-4482-ba52-c805579c5df7",
      "d378feb1-424b-47a3-8d7d-20edb195736b",
    ],
    collectedDataScope: "names",
    benefitReceiver: ["employee", "others"],
    maxPeople: 1,
    purchaseMethod: "individual",
    relationExtensionPeriod: 1,
    relationExtendedBenefits: [
      "Pakiet Rodzinny Specjalistyczny I",
      "Pakiet Senior II",
    ],
    relationRestrictionPeriod: 1,
    relationRestrictedBenefits: ["Medycyna Pracy", "Pakiet Senior I"],
    relationParentalLeave: "value",
    relationResignationAvailable: false,
    relationPeriodResignationBlockade: 1,
    relationBenefitTypeConfiguration: "auto",
    relationBenefitAutomaticTypeConfiguration: [
      "automaticSubscriptionSettings_1",
      "automaticSubscriptionSettings_2",
    ],
    relationBenefitOptionalTypeConfiguration: "independent",
    relationBenefitOptionalTypeConfigurationDependingOn: [
      "Medycyna Pracy",
      "Pakiet Senior I",
    ],
    relationBenefitOptionalTypeConfigurationReplacing: "Medycyna Pracy",
    move: "+",
    benefitCategory:
      "/api/subscription-management/v1/rest/benefit-categories/359f5137-2501-457e-9f9f-808909bfd961",
    benefitGroup:
      "/api/subscription-management/v1/rest/benefit-groups/18be83b7-79e7-4526-8752-0054419d8da9",
  },
  {
    id: "459f5137-2501-457e-9f9f-808909bfd961",
    name: "Abonament 2",
    supplierId: "Aquapark",
    code: "abonament_123",
    thresholdDay: 1,
    manualActivationRequired: false,
    manualActivationIsDefault: false,
    pendingDay: 1,
    description: "Abonament ...",
    descriptionIsDefault: false,
    rentableGroupIds: [
      "5d2fb30d-2d07-4f84-b2f6-30ced35c7539",
      "dd88a805-8e1c-4587-8f13-7a545c10732e",
    ],
    organizationUnitIds: [
      "86ebdc5d-495f-4482-ba52-c805579c5df7",
      "d378feb1-424b-47a3-8d7d-20edb195736b",
    ],
    collectedDataScope: "names",
    benefitReceiver: ["employee", "others"],
    maxPeople: 1,
    purchaseMethod: "individual",
    relationExtensionPeriod: 1,
    relationExtendedBenefits: [
      "Pakiet Rodzinny Specjalistyczny I",
      "Pakiet Senior II",
    ],
    relationRestrictionPeriod: 1,
    relationRestrictedBenefits: ["Medycyna Pracy", "Pakiet Senior I"],
    relationParentalLeave: "value",
    relationResignationAvailable: false,
    relationPeriodResignationBlockade: 1,
    relationBenefitTypeConfiguration: "auto",
    relationBenefitAutomaticTypeConfiguration: [
      "automaticSubscriptionSettings_1",
      "automaticSubscriptionSettings_2",
    ],
    relationBenefitOptionalTypeConfiguration: "independent",
    relationBenefitOptionalTypeConfigurationDependingOn: [
      "Medycyna Pracy",
      "Pakiet Senior I",
    ],
    relationBenefitOptionalTypeConfigurationReplacing: "Medycyna Pracy",
    move: "+",
    benefitCategory:
      "/api/subscription-management/v1/rest/benefit-categories/359f5137-2501-457e-9f9f-808909bfd961",
    benefitGroup:
      "/api/subscription-management/v1/rest/benefit-groups/18be83b7-79e7-4526-8752-0054419d8da9",
  },
];
export default mockBenefitsData;
