import PropTypes from "prop-types";
import React from "react";
import { Table } from "reactstrap";
import __ from "src/utils/Translations";

export default function ImportLegend({ legend }) {
  const csvForm = legend.map(([field]) => field).join(";");
  return (
    <>
      <div className="font-weight-bold">Format pliku CSV:</div>
      <div className="text-break">{csvForm}</div>
      <div className="font-weight-bold">Legenda:</div>
      <Table className="mt-2 text-center">
        <thead>
          <tr>
            <th>Klucz</th>
            <th>Znaczenie</th>
          </tr>
        </thead>
        <tbody>
          {legend.map(([field, desc]) => (
            <tr key={field}>
              <td>{field}</td>
              <td>{__(desc)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

ImportLegend.propTypes = {
  legend: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};
