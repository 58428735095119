import translateFields from "./translateFields";

/**
 * translates values for provided keys in objects array
 * @param {array} array - array of objecs containing fields to translate
 * @param {string[]} keys - array of keys for fields to translate
 */
const translateFieldsInArray = (array, keys) =>
  array.map((obj) => translateFields(obj, keys));

export default translateFieldsInArray;
