const filterBySelectableConditions = ({ conditionAcceptable, accepted }) =>
  conditionAcceptable && !accepted;

export const prepareConditionIds = (conditions) =>
  conditions.filter(filterBySelectableConditions).map(({ id }) => id);

export const findSelectedRow = (waitingRoomId) => (selectedRow) =>
  selectedRow.waitingRoomId === waitingRoomId;

export const isEveryConditionsSelected = (conditions, selectedConditions) =>
  conditions.length > 0 &&
  conditions
    .filter(filterBySelectableConditions)
    .every(({ id }) => selectedConditions.includes(id));

const isRowSelected = (item, selectedRows) => {
  const selectedRow = selectedRows.find(findSelectedRow(item.id));
  return !!selectedRow;
};

export const parseDataToTable = (subscriptions, selectedRows) =>
  subscriptions.map((item) => {
    const { fk, firstName, lastName, organizationUnitName } = item.employee;
    const { name, beneficiaryFirstName, beneficiaryLastName } = item;

    return {
      ...item,
      fk,
      firstName,
      lastName,
      benefitName: name,
      beneficiaryFirstName,
      beneficiaryLastName,
      organizationUnit: organizationUnitName,
      rowSelected: isRowSelected(item, selectedRows),
    };
  });

export const updateSelectedRows = (
  selectedRows,
  waitingRoomId,
  conditionIds = []
) =>
  Array.from(
    new Map([
      ...selectedRows.map(({ waitingRoomId, conditionIds }) => [
        waitingRoomId,
        conditionIds,
      ]),
      [waitingRoomId, conditionIds],
    ])
  ).map(([key, value]) => ({
    waitingRoomId: key,
    conditionIds: value,
  }));

export const removeSelectedRow = (selectedRows, waitingRoomId) =>
  selectedRows.filter(
    (selectedRow) => selectedRow.waitingRoomId !== waitingRoomId
  );

export const addCondition = (selectedRows, waitingRoomId, conditionId) => {
  const foundSelection = selectedRows.find(
    (rowSelection) => rowSelection.waitingRoomId === waitingRoomId
  );
  const conditionIds = Array.from(
    new Set([...(foundSelection?.conditionIds || []), conditionId])
  );
  return updateSelectedRows(selectedRows, waitingRoomId, conditionIds);
};

export const removeCondition = (selectedRows, waitingRoomId, conditionId) =>
  selectedRows.reduce((prevSelectedRows, currentValue) => {
    if (currentValue.waitingRoomId === waitingRoomId) {
      const conditionIds = currentValue.conditionIds.filter(
        (id) => id !== conditionId
      );
      if (conditionIds.length) {
        return [
          ...prevSelectedRows,
          {
            ...currentValue,
            conditionIds,
          },
        ];
      }
      return prevSelectedRows;
    }
    return [...prevSelectedRows, currentValue];
  }, []);
