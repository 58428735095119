import { isDevMode, isTestMode } from "src/config/env";
// eslint-disable-next-line import/no-cycle
import { restApiRequestTranslations } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import { getLanguage } from "src/utils/Languages/LanguageContext";
import { defaultLanguage, INTERFACE_CODE } from "./translationUtils";

window.translations = window.translations || {};

export const isDefaultLanguage = () => getLanguage() === defaultLanguage;
export const getTranslation = (phrase) => window.translations[phrase];
export const setTranslations = (phrases) => {
  window.translations = {
    ...window.translations,
    ...phrases,
    isInitialized: true,
  };
};

const useTranslation = (!isDefaultLanguage() && !isMockView()) || isTestMode();

export const fetchMissingTranslations = async (phrases) => {
  const language = getLanguage();

  const newTranslations = await restApiRequestTranslations(
    {
      params: {
        language,
        scope: INTERFACE_CODE,
        value: phrases,
      },
    },
    '{"firma": "company"}'
  );

  setTranslations(newTranslations);
};

const missingPhrases = new Set();

// eslint-disable-next-line no-underscore-dangle
const __ = (phrase, args = []) => {
  if (!phrase || typeof phrase !== "string") {
    return phrase;
  }

  let result = phrase;

  if (!window.translations.isInitialized && isDevMode()) {
    throw Error(`Translation for '${phrase}' executed to early`);
  }

  if (
    useTranslation &&
    !isDefaultLanguage() &&
    window.translations.isInitialized
  ) {
    const translation = getTranslation(phrase);
    if (translation === undefined) {
      missingPhrases.add(phrase);
    }
    result = translation || result;
  }

  if (args.length) {
    const elementsToReplace = result.matchAll(/{(.*?)}/g);
    [...elementsToReplace].forEach(([toReplace, index]) => {
      result = result.replace(toReplace, args[index]);
    });
  }

  return result;
};

const getMissingTranslations = () => {
  if (missingPhrases.size > 0) {
    fetchMissingTranslations([...missingPhrases]);
    missingPhrases.clear();
  }
};

if (useTranslation) {
  setInterval(getMissingTranslations, 10000);
}

// eslint-disable-next-line no-underscore-dangle
window.__ = __;

export default __;
