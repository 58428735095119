import { hasAccessTo } from "src/utils/RoleBasedSecurity/filters";
import __ from "src/utils/Translations";
import { EMPLOYEE_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import isUuid from "src/utils/jsHelpers/isUuid";
import { mockData as mockEmployeeData } from "src/Pages/Company/EmployeeManagement/CompanyEmployees/List/list";
import { employeeEmployeePermissionRead } from "src/utils/RoleBasedSecurity/permissions";

const findEmployees = (value, ctx) => {
  const { userInfo, inCompanyContext, companyData } = ctx;
  const { id: companyId } = companyData;
  const isAhr = userInfo.isAhr();

  if (
    (!hasAccessTo(ctx.userInfo, employeeEmployeePermissionRead) ||
      !inCompanyContext) &&
    !isAhr
  ) {
    return [];
  }

  const filter = isUuid(value)
    ? { id: value, companyId }
    : { or_searchByName: [value], companyId };
  return restApiRequest(
    EMPLOYEE_MANAGEMENT_SERVICE,
    "/employees",
    "GET",
    {
      params: {
        ...filter,
        itemsPerPage: 10,
      },
    },
    mockEmployeeData
  ).then((res) =>
    res.map(({ firstName, lastName, email, id }) => ({
      label: `${firstName} ${lastName}${email ? ` (${email})` : ""}`,
      to: isAhr
        ? `/ahr/employees/${id}`
        : `/company/edit/${companyId}/employee-management/employees/${id}`,
    }))
  );
};

export default findEmployees;
