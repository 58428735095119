import React from "react";
import DataTable from "src/Components/DataTable";
import __ from "src/utils/Translations";
import {
  mapValueFromOptions,
  priceColumn,
} from "src/Components/DataTable/commonCells";
import {
  payerOptions,
  typeOptions,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/PrepaidCardsTab/PrepaidCardsCancellation/Utils/statuses";
import {
  PriceFilter,
  priceFilterMethod,
  SelectFilter,
} from "src/Components/DataTable/filters";
import PropTypes from "prop-types";

export default function PaymentList({ tableData }) {
  return (
    <DataTable
      id="payment_list"
      key="payment_list"
      data={tableData || []}
      sortable={false}
      filterable
      columns={[
        {
          Header: __("Rodzaj platności"),
          accessor: "type",
          Filter: SelectFilter(typeOptions),
          Cell: mapValueFromOptions(typeOptions, "type"),
        },
        {
          Header: __("Sposób opłacenia"),
          accessor: "financingSourceName",
        },
        {
          Header: __("Kwota do zwrotu"),
          accessor: "amount",
          Filter: PriceFilter(),
          minWidth: 150,
          filterMethod: priceFilterMethod,
          Cell: priceColumn,
        },
        {
          Header: __("Kto opłaca"),
          accessor: "payer",
          Cell: mapValueFromOptions(payerOptions, "payer"),
          Filter: SelectFilter(payerOptions),
          sortable: false,
        },
      ]}
    />
  );
}

PaymentList.propTypes = {
  tableData: PropTypes.array.isRequired,
};
