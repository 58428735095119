import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import CompanyContext from "src/Pages/Company/CompanyContext";
import __ from "src/utils/Translations";
import Spinner from "src/Components/Spinner";
import { sendFindDataRequests } from "./utils";

export default function SearchBox() {
  const [inputText, setInputText] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [products, setProducts] = useState([]);
  const [tourismObjects, setTourismObjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { userInfo } = useContext(RbsContext);
  const { data: companyData, inCompanyContext } = useContext(CompanyContext);
  const dropdown = useRef(null);

  useEffect(
    () =>
      sendFindDataRequests({
        userInfo,
        companyData,
        inCompanyContext,
        inputText,
        setIsSearching,
        setShowSuggestions,
        setMenuItems,
        setEmployees,
        setCompanies,
        setProducts,
        setTourismObjects,
      }),
    [inputText, userInfo, companyData, inCompanyContext]
  );

  const toggle = (e) => {
    setShowSuggestions(dropdown.current.contains(e.target));
  };
  const foundSuggestions = Boolean(
    menuItems.length ||
      companies.length ||
      employees.length ||
      products.length ||
      tourismObjects.length
  );
  const suggestions = [
    { scope: "Firmy", elements: companies },
    { scope: "Pracownicy", elements: employees },
    { scope: "Produkty", elements: products },
    { scope: "Obiekty turystyczne", elements: tourismObjects },
    { scope: "Menu", elements: menuItems },
  ];

  return (
    <div ref={dropdown}>
      <Dropdown
        isOpen={Boolean(showSuggestions && foundSuggestions)}
        toggle={toggle}
      >
        <div className="search-wrapper active">
          <div className="input-holder">
            <input
              data-t1="searchBox"
              onFocus={() => setShowSuggestions(true)}
              value={inputText}
              type="text"
              className="search-input"
              placeholder={`${__("Wpisz czego szukasz")}...`}
              onChange={(e) => setInputText(e.target.value)}
            />
            <button type="button" className="search-icon">
              {isSearching ? Spinner : <span />}
            </button>
          </div>
        </div>
        <DropdownToggle color="primary" tag="span" />
        <DropdownMenu
          className="dropdown-menu-hover-primary"
          data-t1="searchBoxResult"
        >
          {suggestions.map(({ scope, elements }) =>
            elements && elements.length ? (
              <Fragment key={scope}>
                <DropdownItem header>{scope}</DropdownItem>
                {elements.map(({ to, label, external }) =>
                  external ? (
                    <a
                      href={to}
                      rel="noopener noreferrer"
                      target="_blank"
                      key={to}
                      className="text-decoration-none"
                      onClick={() => setShowSuggestions(false)}
                    >
                      <DropdownItem>{label}</DropdownItem>
                    </a>
                  ) : (
                    <Link
                      to={to}
                      key={to}
                      className="text-decoration-none"
                      onClick={() => setShowSuggestions(false)}
                    >
                      <DropdownItem>{label}</DropdownItem>
                    </Link>
                  )
                )}
              </Fragment>
            ) : null
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
