import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const ToastStyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 430px;
    box-sizing: border-box;
  }
  .Toastify__toast {
    width: 100%;
    box-sizing: border-box;
  }
  .Toastify__toast--success {
    background-color: #edf7ed;
    color: #2e7d32;
  }
  .Toastify__toast--info {
    width: 320px;
    margin-left: auto;
  }
  .Toastify__toast--error {
    width: 320px;
    margin-left: auto;
  }
  .Toastify__toast--warning {
    width: 320px;
    margin-left: auto;
  }
`;

export default ToastStyledContainer;
