/* eslint-disable prefer-template */
import { isEmpty } from "lodash";
import AppConfig from "src/config/appConfig";
import { getLanguage } from "src/utils/Languages/LanguageContext";
// eslint-disable-next-line import/no-cycle
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import {
  addBlockedRequest,
  isBlockedRequest,
  removeBlockedRequest,
} from "src/utils/RoleBasedSecurity/blockedRequests";
import { dynamicNotification } from "src/utils/Notifications";
// eslint-disable-next-line import/no-cycle
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import ApiForbiddenError from "./ApiForbiddenError";
import ApiResponseError from "./ApiResponseError";
import isMockView from "./isMockView";

export const COMPANY_MANAGEMENT_SERVICE = "company-management";
export const EMPLOYEE_MANAGEMENT_SERVICE = "employee-management";
export const TRANSLATOR_SERVICE = "translator";
export const CONFIGURATION_SERVICE = "configuration";
export const OPERATOR_MANAGEMENT_SERVICE = "operator-management";
export const MAGENTO_HELPER_SERVICE = "magento-helper";
export const MAGENTO_ADMIN_SERVICE = "magento-admin";
export const AGREEMENT_SERVICE = "agreement";
export const NOTIFICATION_SERVICE = "notification";
export const CMS_SERVICE = "cms";
export const SSO_SERVICE = "sso";
export const DICTIONARY_SERVICE = "dictionary";
export const CATALOG_MANAGEMENT_SERVICE = "catalog-management";
export const SUBSCRIPTION_MANAGEMENT_SERVICE = "subscription-management";
export const EMPLOYEE_GROUP_PRODUCT_SERVICE = "employee-group-product";
export const TOURISM_SERVICE = "tourism";
export const PDF_GENERATOR_SERVICE = "pdf-generator";
export const MIGRATION_SERVICE = "migration";
export const MAGENTO_CATALOG_SERVICE = "magento-catalog";
export const KEYCLOAK_SERVICE = "keycloak";
export const MASTERDATA_SERVICE = "masterdata";
export const REPORT_SERVICE = "report";
export const PROCESSES_SERVICE = "job-manager";
export const SFTP_SERVICE = "sftp";
export const API_GATEWAY_SERVICE = "api-gateway";
export const API_COMPANYEVENTS_SERVICE = "api-companyevents";
export const API_PROPOSALS_SERVICE = "api-proposals";
export const API_TOTALREWARD_SERVICE = "api-trs";
export const EGP_REPORT_SERVICE = "egp-report";

export const getServiceHost = (service) =>
  AppConfig.get(`endpoints.${service}`) || "";

export const setIsMockView = (value) => {
  if (value) {
    window.localStorage.setItem("use_mocks", "1");
  } else {
    window.localStorage.removeItem("use_mocks");
  }
};

export const getQueryString = (parameters, joinArray, isTable) => {
  const params = { ...parameters };
  const result = isEmpty(parameters)
    ? ""
    : `?${Object.keys(params)
        .map((key) => {
          const value = params[key];
          if (Array.isArray(value)) {
            if (joinArray) {
              return `${key}=${value.join(",")}`;
            }
            return value.map((el) => `${key}[]=${el}`).join("&");
          }
          return `${key}=${value}`;
        })
        .join("&")}`;
  if (isTable) {
    return result;
  }
  return encodeURI(result);
};

/* eslint-disable-next-line max-len */
export const getFullFileName = (fileName, exportAll, fileType) =>
  `export_${
    exportAll ? "all_" : ""
  }${fileName}_${new Date().toLocaleDateString()}.${fileType}`;

export const getFileNameFromResponse = (response) => {
  const regExpFilename = /filename="(?<filename>.*)"/;

  return regExpFilename.exec(response.headers.get("content-disposition"))
    ?.groups?.filename;
};

export const saveFile = (blob, open, fileName) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  if (open) {
    link.target = "_blank";
  } else {
    link.download = fileName;
  }
  link.click();
};

export const restApiPrepaidCardsRequest = async (
  service,
  path,
  method,
  { body }
) => {
  const session = getSession();
  const urlPath = getServiceHost(service) + path;
  const response = await fetch(urlPath, {
    method,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${await session.getValidAccessToken()}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  if (response.status === 401 && !isBlockedRequest(path)) {
    addBlockedRequest(path);
    if (session.getRefreshToken()) {
      await new Promise(() => session.refreshToken());
      return null;
    }
    return null;
  }
  if (
    response.status === 403 ||
    response.status === 404 ||
    response.status === 401
  ) {
    let errorMessage = __(
      "Taki zasób nie istnieje lub nie masz do niego dostępu."
    );
    try {
      const { detail } = await response.json();
      if (detail) {
        errorMessage = detail;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      newRelicErrorReport(e, "utils/Api/index.js - 121");
    }
    throw new ApiForbiddenError(errorMessage);
  }
  return response;
};

export const restApiRequest = async (
  service,
  path,
  method,
  {
    headers = {},
    params = null,
    body = null,
    joinArray = false,
    skipToken = false,
    returnNull = false,
  },
  mockData,
  isTable = false,
  shouldThrowApiResponseError = false
) => {
  if (isMockView()) {
    return new Promise((resolve) => setTimeout(() => resolve(mockData), 100));
  }

  const baseHeaders = {
    accept: "application/json",
  };
  const session = getSession();
  if (!skipToken) {
    baseHeaders.authorization = `Bearer ${await session.getValidAccessToken()}`;
  }

  switch (method) {
    case "PUT":
      baseHeaders["content-type"] = "application/json";
      break;
    case "POST":
      baseHeaders["content-type"] = "application/json";
      break;
    case "DELETE":
      baseHeaders["content-type"] = "application/json";
      break;
    case "PATCH":
      baseHeaders["content-type"] = "application/merge-patch+json";
      break;
    default:
    // DO Nothing
  }

  baseHeaders["content-language"] = getLanguage().toLowerCase();

  const queryString = params ? getQueryString(params, joinArray, isTable) : "";
  const serviceHost = getServiceHost(service);
  const urlPath = `${
    service === REPORT_SERVICE ? window.reportingTool : ""
  }${serviceHost}${path}${queryString}`;

  const response = await fetch(urlPath, {
    method,
    headers: {
      ...baseHeaders,
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
  });

  if (!response.ok) {
    if (shouldThrowApiResponseError) {
      throw new ApiResponseError(
        response.status,
        response.url,
        response.statusText,
        `${__("Błąd")}: ${response.statusText}`
      );
    }

    if (service === MASTERDATA_SERVICE) {
      throw new ApiResponseError(
        response.status,
        response.url,
        response.statusText,
        `${__("Błędna odpowiedź z serwisu Masterdata")} MD_${
          response.status
        } - ${response.statusText}`
      );
    }

    if (service === REPORT_SERVICE && method !== "GET") {
      if (response.status === 400) {
        const { status, url } = response;
        let errorTitle;
        let message;
        let errorsList;
        try {
          const { title, detail, errorMessage, errors } = await response.json();
          errorTitle = title;
          message = detail || errorMessage;
          errorsList = errors;
        } catch (e) {
          error = null;
          newRelicErrorReport(e, "utils/Api/index.js - 234");
          message = __("Niepoprawna odpowiedź z serwera");
        }
        throw new ApiResponseError(status, url, errorTitle, message);
      }
    }

    if (response.status === 401 && !isBlockedRequest(path)) {
      addBlockedRequest(path);
      if (session.getRefreshToken()) {
        await new Promise(() => session.refreshToken());
        return null;
      }
      // TODO redirect to login page
      return null;
    }
    if (
      response.status === 403 ||
      response.status === 404 ||
      response.status === 401
    ) {
      let errorMessage =
        "Taki zasób nie istnieje lub nie masz do niego dostępu.";
      try {
        const { detail } = await response.json();
        if (detail) {
          errorMessage = detail;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        newRelicErrorReport(e, "utils/Api/index.js - 220");
      }
      throw new ApiForbiddenError(errorMessage);
    }

    if (response.status === 422) {
      const { status, url } = response;
      let error;
      let message;
      try {
        const { title, description, errorMessage, detail } =
          await response.json();
        error = title;
        message = errorMessage || description || detail;
      } catch (e) {
        error = null;
        newRelicErrorReport(e, "utils/Api/index.js - 289");
        message = __("Niepoprawna odpowiedź z serwera");
      }
      throw new ApiResponseError(status, url, error, message);
    }

    const { status, url } = response;
    let error;
    let message;
    try {
      const { title, detail, errorMessage } = await response.json();
      error = title;
      message = detail || errorMessage;
    } catch (e) {
      error = null;
      newRelicErrorReport(e, "utils/Api/index.js - 234");
      message = __("Niepoprawna odpowiedź z serwera");
    }
    throw new ApiResponseError(status, url, error, message);
  }

  if (response.status === 202) {
    return { status: "success" };
  }
  if (response.status === 204) {
    return null;
  }

  removeBlockedRequest(path);
  if (returnNull) {
    return null;
  }

  return response.json();
};

// formData can't have headers overriden, or else it won't work
export const restApiRequestFormData = async (
  service,
  path,
  method,
  body = null,
  mockData = undefined,
  { headers = {}, customErrorHandler } = {}
) => {
  if (isMockView()) {
    return new Promise((resolve) => setTimeout(() => resolve(mockData), 100));
  }

  const session = getSession();

  const urlPath = getServiceHost(service) + path;
  const response = await fetch(urlPath, {
    method,
    headers: {
      authorization: `Bearer ${await session.getValidAccessToken()}`,
      ...headers,
    },
    body: body || undefined,
  });
  if (!response.ok) {
    if (customErrorHandler) {
      const bodyData = await response.json();
      customErrorHandler(bodyData);
    }

    if (response.status === 401) {
      addBlockedRequest(path);
      if (session.getRefreshToken()) {
        await new Promise(() => session.refreshToken());
        return null;
      }
      throw response;
    }
    if (
      response.status === 403 ||
      response.status === 404 ||
      response.status === 401
    ) {
      throw new ApiForbiddenError(
        "Taki zasób nie istnieje lub nie masz do niego dostępu."
      );
    }

    throw response;
  }

  if (response.status === 202) {
    return { status: "success" };
  }

  removeBlockedRequest(path);

  return response;
};

const splitTranslationRequestUrl = (url, result = []) => {
  const identifier = "&value";
  const maxUrlLength = 2048;

  if (url.length > maxUrlLength) {
    const prefix = url.slice(0, url.indexOf(identifier));

    const lastValueIndex = url.slice(0, maxUrlLength).lastIndexOf(identifier);

    result.push(url.slice(0, lastValueIndex));

    const nextUrl = `${prefix}${url.slice(lastValueIndex)}`;
    return splitTranslationRequestUrl(nextUrl, result);
  }

  return [...result, url];
};

export const restApiRequestTranslations = async (
  { params = null },
  mockData
) => {
  if (isMockView()) {
    return JSON.parse(mockData);
  }

  const session = getSession();

  const queryString = params ? getQueryString(params, false, false) : "";
  const urlPath =
    getServiceHost(TRANSLATOR_SERVICE) + "/get-by-phrase" + queryString;
  const urls = splitTranslationRequestUrl(urlPath);

  const token = await session.getValidAccessToken();

  try {
    const responses = await Promise.all(
      urls.map((url) =>
        fetch(url, {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
      )
    );

    const responsesJSON = await Promise.all(
      responses.map((response) => response.json())
    );

    return responsesJSON.reduce((acc, curr) => {
      const currParsed = JSON.parse(curr);
      return { ...acc, ...currParsed };
    }, {});
  } catch (error) {
    console.error(error);
    newRelicErrorReport(error, "utils/Api/index - 425");
    return {};
  }
};

export const downloadFile = async (service, path, fileName, open = false) => {
  const splitedFileNameExtension = fileName.split(".");
  const fileNameExtension =
    splitedFileNameExtension[splitedFileNameExtension.length - 1];

  const fileType =
    fileNameExtension === "csv"
      ? "text/csv"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  let headers = {
    contentType: fileType,
    accept: fileType,
    "content-language": getLanguage(),
  };
  if (!service || service === "migration" || service === "catalog-management") {
    headers = { contentType: "application/json", accept: "application/json" };
  }

  const session = getSession();
  const response = await fetch(`${getServiceHost(service)}${path}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${await session.getValidAccessToken()}`,
      ...headers,
    },
  });
  if (!response.ok) {
    dynamicNotification(__("Nie udało się pobrać pliku."), "error");
  } else {
    const blob = await response.blob();
    const resultFileName = fileName || getFileNameFromResponse(response);
    saveFile(blob, open, resultFileName);
  }
};

export const uploadFile = async (
  service,
  path,
  file,
  headers = {},
  allowedExtensions = null,
  additionalData = {},
  method = "POST"
) => {
  if (allowedExtensions) {
    const fileExtension = file.name.split(".").pop();
    if (!allowedExtensions.includes(fileExtension)) {
      throw new Error(
        __(
          "Rozszerzenie pliku {0} nie znajduje się na liście dozwolonych: {1}.",
          [fileExtension, allowedExtensions.join(", ")]
        )
      );
    }
  }
  const session = getSession();
  const formData = new FormData();
  formData.append("file", file);
  Object.keys(additionalData).forEach((key) =>
    formData.append(key, additionalData[key])
  );
  const response = await fetch(`${getServiceHost(service)}${path}`, {
    method,
    body: formData,
    headers: {
      // 'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${await session.getValidAccessToken()}`,
      ...headers,
    },
  });
  if (!response.ok) {
    const { status, url } = response;

    let error = null;
    let message;
    if (status === 413) {
      message = __("Zbyt duży rozmiar pliku.");
    } else {
      try {
        const { title, detail } = await response.json();
        error = title;
        message = detail;
      } catch (e) {
        newRelicErrorReport(e, "utils/Api/index.js - 406");
        message = __("Niepoprawna odpowiedź z serwera");
      }
    }
    throw new ApiResponseError(status, url, error, message);
  }
  return response.json();
};
