/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "reactstrap";
import {
  getDateCell,
  mapValueFromOptions,
  priceColumn,
} from "src/Components/DataTable/commonCells";
import { statusesOptions } from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/MSCards/utils";
import {
  DateFilter,
  dateFilterMethod,
  SelectFilter,
} from "src/Components/DataTable/filters";
import { restApiRequest, API_GATEWAY_SERVICE } from "src/utils/Api";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import DataTableControlled from "src/Components/DataTableControlled";

export default function MSCards({ employeeId }) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const fetchData = useCallback(
    async (filters, page, pageSize) => {
      try {
        const data = await restApiRequest(
          API_GATEWAY_SERVICE,
          `/points-banks-cyclic-subscriptions/subscriptions?filters={"employeeId":"${employeeId}"}&pageSize=${pageSize}&page=${page}`,
          "GET",
          {}
        );
        setTotalElements(data.totalElements);
        if (data.subscriptions)
          // endpoint can respond with empty data object
          setSubscriptions(data.subscriptions);
      } catch (error) {
        console.error(error);
        newRelicErrorReport(
          error,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/MSCards/index.js - 40"
        );
        dynamicNotification(
          error.mesage || __("Nie udało się pobrać listy."),
          "error"
        );
      }
    },
    [employeeId]
  );

  const pointsBank = (row) => {
    const item = row.financingSources.find((x) => x.type === "POINTS_BANK");
    return item?.cost;
  };

  const salaryDeduction = (row) => {
    const item = row.financingSources.find(
      (x) => x.type === "SALARY_DEDUCTION"
    );
    return item?.cost;
  };

  return (
    <Card>
      <CardHeader>
        {__("Świadczenia cykliczne (abonamenty i karty sportowe)")}
      </CardHeader>
      <CardBody>
        <DataTableControlled
          noCards
          id="ms_cards_subscriptions"
          key={`ms_cards_subscriptions_${employeeId}`}
          count={totalElements}
          fetchData={fetchData}
          columns={[
            {
              Header: "ID subskrypcji",
              accessor: "id",
              minWidth: 150,
            },
            {
              Header: "Status",
              accessor: "status",
              Filter: SelectFilter(statusesOptions),
              Cell: mapValueFromOptions(statusesOptions, "status"),
            },
            {
              Header: "Data utworzenia",
              accessor: "createdAt",
              Filter: DateFilter(),
              minWidth: 150,
              filterMethod: dateFilterMethod,
              Cell: getDateCell("createdAt", false, true),
            },
            {
              Header: "Data startu",
              accessor: "startDate",
              Filter: DateFilter(),
              minWidth: 150,
              filterMethod: dateFilterMethod,
              Cell: getDateCell("startDate", false, true),
            },
            {
              Header: "Data rezygnacji",
              accessor: "resignationDate",
              Filter: DateFilter(),
              minWidth: 150,
              filterMethod: dateFilterMethod,
              Cell: getDateCell("resignationDate", false, true),
            },
            {
              Header: "Data akceptacji",
              accessor: "activatedAt",
              Filter: DateFilter(),
              minWidth: 150,
              filterMethod: dateFilterMethod,
              Cell: getDateCell("activatedAt", false, true),
            },
            {
              id: "salary_deduction_cost",
              Header: "Koszt pracownika",
              accessor: (row) => salaryDeduction(row),
              Cell: priceColumn,
            },
            {
              id: "points_bank_cost",
              Header: "Koszt z BP",
              accessor: (row) => pointsBank(row),
              Cell: priceColumn,
            },
            {
              Header: "Priorytet",
              accessor: "priority",
            },
          ]}
          data={subscriptions}
          filterable
        />
      </CardBody>
    </Card>
  );
}

MSCards.propTypes = {
  employeeId: PropTypes.string.isRequired,
};

const mockData = {
  pageNumber: 0,
  pageSize: 0,
  totalPages: 0,
  totalElements: 0,
  subscriptions: [
    {
      id: "string",
      parentId: "111",
      employeeId: "string",
      financingSources: [
        {
          cost: 0,
          chargePeriodicity: 0,
          externalId: "string",
          type: "POINTS_BANK",
          lastPeriodPayment: {
            id: "string",
            status: "NEW",
            periodStartDate: "2022-11-17",
            periodEndDate: "2022-11-17",
            advancedAt: "2022-11-17T08:45:59.617Z",
            payAt: "2022-11-17T08:45:59.617Z",
            paidAt: "2022-11-17T08:45:59.617Z",
            refundedAt: "2022-11-17T08:45:59.617Z",
          },
          paymentMethod: "PERIOD_TO_PERIOD",
        },
      ],
      payDay: 0,
      priority: 0,
      status: "NEW",
      startDate: "2022-11-17",
      relatedSubscriptions: ["string"],
      waitingRoomActive: true,
      waitingRoomAcceptedAt: "2022-11-17T08:45:59.617Z",
      resignationEnteredAt: "2022-11-17T08:45:59.617Z",
      resignedAt: "2022-11-17T08:45:59.617Z",
      canceledAt: "2022-11-17T08:45:59.617Z",
      activatedAt: "2022-11-17T08:45:59.617Z",
      createdAt: "2022-11-17T08:45:59.617Z",
      origin: "string",
    },
    {
      id: "string",
      parentId: "222",
      employeeId: "string",
      financingSources: [
        {
          cost: 0,
          chargePeriodicity: 0,
          externalId: "string",
          type: "POINTS_BANK",
          lastPeriodPayment: {
            id: "string",
            status: "NEW",
            periodStartDate: "2022-11-17",
            periodEndDate: "2022-11-17",
            advancedAt: "2022-11-17T08:45:59.617Z",
            payAt: "2022-11-17T08:45:59.617Z",
            paidAt: "2022-11-17T08:45:59.617Z",
            refundedAt: "2022-11-17T08:45:59.617Z",
          },
          paymentMethod: "PERIOD_TO_PERIOD",
        },
      ],
      payDay: 0,
      priority: 0,
      status: "ACTIVE",
      startDate: "2022-11-17",
      relatedSubscriptions: ["string"],
      waitingRoomActive: true,
      waitingRoomAcceptedAt: "2022-11-17T08:45:59.617Z",
      resignationEnteredAt: "2022-11-17T08:45:59.617Z",
      resignedAt: "2022-11-17T08:45:59.617Z",
      canceledAt: "2022-11-17T08:45:59.617Z",
      activatedAt: "2022-11-17T08:45:59.617Z",
      createdAt: "2022-11-17T08:45:59.617Z",
      origin: "string",
    },
  ],
};
