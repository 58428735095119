/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const UuidFilter = ({ filter, onChange, column }) => {
  const [inputState, setInputState] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!filter) setInputState("");
  }, [filter]);

  return (
    <input
      data-t1="gridFilter"
      data-t2={column.id}
      type="text"
      style={{
        width: "100%",
      }}
      placeholder={column.Placeholder}
      onPaste={() => {
        setIsCopied(true);
      }}
      onChange={(event) => {
        const trimedValue = isCopied
          ? event.target.value.trim()
          : event.target.value;
        setInputState(trimedValue);
        onChange(trimedValue);
        setIsCopied(false);
      }}
      value={inputState}
      pattern="[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}"
    />
  );
};

UuidFilter.propTypes = {
  filter: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
    Placeholder: PropTypes.string,
  }).isRequired,
};

UuidFilter.defaultProps = {
  filter: null,
};

export default UuidFilter;
