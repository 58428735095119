import React from "react";
import __ from "src/utils/Translations";
import { statusListingOptions } from "src/Pages/Company/Pending/BenefitsPending/Listing/utils";

const StatusColumn = (cellInfo) => {
  const pendingStatus = Array.isArray(cellInfo.row.pendingStatuses)
    ? cellInfo.row.pendingStatuses[0]
    : cellInfo.row.pendingStatuses;
  const listingOptionsStatusLabel = statusListingOptions.find(
    (option) => option.value === pendingStatus
  );
  const label = listingOptionsStatusLabel
    ? listingOptionsStatusLabel.label
    : "";

  return <div>{__(label)}</div>;
};

export default StatusColumn;
