import React from "react";

const getTooltipIcon = (type, id) => {
  switch (type) {
    case "legacy":
      return (
        <i
          id={id}
          className="pe-7s-help1 text-danger font-weight-bold tooltipIcon"
        />
      );
    case "black":
      return (
        <i
          id={id}
          className="pe-7s-help1 font-weight-bold tooltipIcon"
          style={{ color: "black" }}
        />
      );
    case "info":
    default:
      return (
        <i
          id={id}
          className="pe-7s-help1 text-primary font-weight-bold tooltipIcon"
        />
      );
  }
};

export default getTooltipIcon;
