import PropTypes from "prop-types";
import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Label,
  InputGroupText,
} from "reactstrap";
import Select from "react-select";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import __ from "src/utils/Translations";
import { defaultStyles, errorStyles } from "./consts";

export default function Multiselect({
  id,
  label,
  validation,
  invalid,
  value,
  prefix,
  suffix,
  validateField,
  tooltip,
  errorMessage,
  onChange,
  options,
  disabled,
  afterLabel,
}) {
  const inputGroup = (
    <div
      className="input-group-omb font-weight-normal text-decoration-none w-100"
      data-t1={id}
    >
      <Select
        key={`${id}_autocomplete-${value}`}
        isClearable
        className="omb-autocomplete font-weight-normal text-decoration-none"
        styles={invalid ? errorStyles : defaultStyles}
        isDisabled={disabled}
        isMulti
        noOptionsMessage={() => __("Brak opcji")}
        hideSelectedOptions={false}
        menuPlacement="auto"
        id={id}
        options={options}
        value={value || []}
        onChange={(option, triggeredAction) => {
          const isClearACtion = triggeredAction.action.includes("clear");
          onChange(isClearACtion ? [] : option);
          validateField(id, option, validation);
        }}
        onBlur={() => {
          if (validateField) {
            validateField(id, value, validation);
          }
        }}
      />
      <ValidationMessage message={errorMessage} />
    </div>
  );

  return (
    <FormGroup>
      <Label data-t1={`${id}Label`} for={id}>
        {label}
        &nbsp;
        {tooltip}
      </Label>
      {afterLabel ? <span className="ml-3">{afterLabel}</span> : null}
      <InputGroup>
        {prefix ? (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{prefix}</InputGroupText>
          </InputGroupAddon>
        ) : null}
        {inputGroup}
        {suffix ? (
          <InputGroupAddon addonType="append">
            <InputGroupText>{suffix}</InputGroupText>
          </InputGroupAddon>
        ) : null}
      </InputGroup>
    </FormGroup>
  );
}

Multiselect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  disabled: PropTypes.bool,
  afterLabel: PropTypes.node,
  errorMessage: PropTypes.string,
  validateField: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  invalid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
};

Multiselect.defaultProps = {
  id: "",
  label: "",
  tooltip: null,
  afterLabel: null,
  value: [],
  errorMessage: null,
  validateField: null,
  validation: null,
  prefix: "",
  suffix: "",
  invalid: false,
  disabled: false,
};
