/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useCallback, useState } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import PageTitle from "src/Layout/AppMain/PageTitle";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import {
  companyCompanyPermissionRead,
  companyCompanyPermissionWrite,
} from "src/utils/RoleBasedSecurity/permissions";
import {
  COMPANY_MANAGEMENT_SERVICE,
  EMPLOYEE_MANAGEMENT_SERVICE,
  restApiRequest,
} from "src/utils/Api";
import {
  activeBooleanOptions,
  SelectFilter,
} from "src/Components/DataTable/filters";
import ActionColumn from "src/Components/DataTable/actionColumn";
// import BusinessIdColumn from '../../../Components/DataTable/businessIdColumn';
import { ExportContext } from "src/Components/DataTableControlled/context";
import arrayUnique from "src/utils/jsHelpers/arrayUnique";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import __ from "src/utils/Translations";
import _ from "lodash";

const defaultCompaniesSort = {
  key: "shortName",
  value: "asc",
};

export default () => {
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState(null);
  const [count, setCount] = useState(0);
  const [prevFilters, setPrevFilters] = useState([]);
  const [prevSort, setPrevSort] = useState([]);
  const [massActionSelect, setMassActionSelect] = useState({
    excluded: [],
    included: [],
  });

  const exportContext = new ExportContext({
    service: COMPANY_MANAGEMENT_SERVICE,
    path: "/companies-export",
    permission: companyCompanyPermissionRead,
    fileName: "companies",
    handleAdditionalFilters,
  });

  const exportContextAhr = new ExportContext({
    service: EMPLOYEE_MANAGEMENT_SERVICE,
    path: "/ahrs-export",
    permission: companyCompanyPermissionRead,
    fileName: "companiesAhr",
    handleAdditionalFilters,
  });

  const fetchData = useCallback(async (filters, page, pageSize, sort) => {
    const additionalFilters = await handleAdditionalFilters(filters);
    if (additionalFilters === false) {
      setData([]);
      setCount(0);
      return;
    }
    let requestFilters = filters;
    if (additionalFilters.length) {
      requestFilters = [...requestFilters, ...additionalFilters];
    }
    const { data: newData, count: newCount } = await getListingData(
      COMPANY_MANAGEMENT_SERVICE,
      "/companies",
      requestFilters,
      page,
      pageSize,
      sort || defaultCompaniesSort,
      {
        params: {
          withCount: true,
        },
      },
      mockData
    );
    setData(
      newData.map((el) => ({ ...el, employeeCount: el.employeeCount || 0 }))
    );
    setCount(newCount);
  }, []);

  const fetchFullData = async (filters, sort) => {
    if (_.isEqual(prevFilters, filters) && _.isEqual(prevSort, sort)) {
      return fullData;
    }
    setPrevFilters(filters);
    setPrevSort(sort);
    const additionalFilters = await handleAdditionalFilters(filters);
    let requestFilters = filters;
    if (additionalFilters.length) {
      requestFilters = [...requestFilters, ...additionalFilters];
    }
    const { data: newFullData } = await getListingData(
      COMPANY_MANAGEMENT_SERVICE,
      "/companies-names",
      requestFilters,
      1,
      10000,
      sort || defaultCompaniesSort,
      mockData
    );
    const newFullDataIds = newFullData.map((companyEle) => companyEle.id);
    setFullData(newFullDataIds);
    return newFullDataIds;
  };

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={__("Lista firm")}
          breadcrumbs={[{ title: `${__("Firma")}`, link: "/company" }]}
        />
        <DataTableControlled
          id="companiesListing"
          columns={columns}
          massActions={[]}
          massActionSelect={massActionSelect}
          setMassActionSelect={setMassActionSelect}
          exportContext={exportContext}
          exportContextAhr={exportContextAhr}
          fetchData={fetchData}
          fetchFullData={fetchFullData}
          buttons={[
            {
              id: "add",
              color: "primary",
              href: "/company/list/create",
              text: `+ ${__("Dodaj firmę")}`,
              permission: companyCompanyPermissionWrite,
            },
          ]}
          data={data}
          fullData={fullData}
          count={count}
          filterable
          additionalFilters={[
            {
              type: "text",
              id: "organization_unit",
              label: "Jednostka organizacyjna",
            },
            {
              type: "text",
              id: "rentable_group",
              label: "Grupa dochodowości",
            },
          ]}
        />
      </CSSTransitionGroup>
    </>
  );
};

const columns = [
  // {
  //   Header: 'ID',
  //   accessor: 'id',
  //   width: 150,
  //   Cell: BusinessIdColumn,
  // },
  {
    Header: "Skrócona nazwa",
    accessor: "shortName",
  },
  {
    Header: "Pełna nazwa",
    accessor: "fullName",
  },
  {
    Header: "Ulica",
    accessor: "street",
  },
  {
    Header: "Miasto",
    accessor: "city",
  },
  {
    Header: "Liczba pracowników",
    accessor: "employeeCount",
    filterable: false,
    sortable: false,
    maxWidth: 200,
  },
  {
    Header: "Status",
    accessor: "active",
    Filter: SelectFilter(activeBooleanOptions),
    Cell: mapValueFromOptions(activeBooleanOptions, "active"),
  },
  {
    Header: "Akcja",
    accessor: "action",
    filterable: false,
    sortable: false,
    maxWidth: 150,
    Cell: (data) => (
      <div className="d-block w-100 text-center">
        <ActionColumn
          data={data.row._original}
          buttons={[
            {
              id: "edit",
              href: `/company/edit/${data.row._original.id}`,
            },
          ]}
        />
      </div>
    ),
  },
];

const handleAdditionalFilters = async (filters) => {
  const result = [];
  const idsFilter = filters.find(({ id }) => id === "id");
  let useAdditionalFilter = false;
  if (idsFilter) {
    return result;
  }
  let companyIds = [];
  const organizationUnitFilter = filters.find(
    ({ id }) => id === "organization_unit"
  );
  if (organizationUnitFilter && organizationUnitFilter.value) {
    useAdditionalFilter = true;
    const organizationUnits = await restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      "/organization-units",
      "GET",
      {
        params: {
          name: organizationUnitFilter.value,
          itemsPerPage: 10000,
        },
      }
    );
    const ids = organizationUnits
      .map(({ companyId }) => companyId)
      .filter(arrayUnique);
    companyIds = [...companyIds, ...ids];
  }
  const rentableGroupFilter = filters.find(({ id }) => id === "rentable_group");
  if (rentableGroupFilter && rentableGroupFilter.value) {
    useAdditionalFilter = true;
    const rentableGroups = await restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      "/rentable-groups",
      "GET",
      {
        params: {
          frontendName: rentableGroupFilter.value,
          itemsPerPage: 10000,
        },
      }
    );
    const ids = rentableGroups
      .map(({ companyId }) => companyId)
      .filter(arrayUnique);
    companyIds = [...companyIds, ...ids];
  }
  if (companyIds.length) {
    result.push({ id: "id", value: companyIds.filter(arrayUnique) });
  } else if (useAdditionalFilter) {
    return false;
  }
  return result;
};

export const mockData = [
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    fullName: "10clouds",
    shortName: "10clouds",
    street: "Warszawska",
    city: "Wrocław",
    industry: "AUTOMOTIVE",
    employeeCount: 76,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200022",
    fullName: "PKO BP",
    shortName: "PKO BP",
    street: "Warszawska",
    city: "Wrocław",
    industry: "AUTOMOTIVE",
    employeeCount: 12,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200023",
    fullName: "Janex",
    shortName: "Janex",
    street: "Kościuszki",
    city: "Wrocław",
    industry: "AUTOMOTIVE",
    employeeCount: 43,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200024",
    fullName: "3m",
    shortName: "3m",
    street: "Niepodległości",
    city: "Łódź",
    industry: "AUTOMOTIVE",
    employeeCount: 76,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200025",
    fullName: "AECOM",
    shortName: "AECOM",
    street: "Warszawska",
    city: "Wrocław",
    industry: "Branża 2",
    employeeCount: 87,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200026",
    fullName: "Allegro",
    shortName: "Allegro",
    street: "Warszawska",
    city: "Wrocław",
    industry: "AUTOMOTIVE",
    employeeCount: 44,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200027",
    fullName: "Alior bank",
    shortName: "Alior bank",
    street: "Poznańska",
    city: "Wrocław",
    industry: "Branża 2",
    employeeCount: 33,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200028",
    fullName: "Castorama",
    shortName: "Castorama",
    street: "Warszawska",
    city: "Wrocław",
    industry: "AUTOMOTIVE",
    employeeCount: 76,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200029",
    fullName: "Polfarmex",
    shortName: "Polfarmex",
    street: "Warszawska",
    city: "Wrocław",
    industry: "AUTOMOTIVE",
    employeeCount: 44,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac12000210",
    fullName: "Cemex",
    shortName: "Cemex",
    street: "Długa",
    city: "Białystok",
    industry: "AUTOMOTIVE",
    employeeCount: 7,
  },
];
