import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { CSSTransitionGroup } from "react-transition-group";

import HeaderLogo from "src/Layout/AppLogo";

import SearchBox from "src/Components/SearchBox";
import UserBox from "./UserBox";
import HeaderRightDrawer from "./HeaderRightDrawer";

import HeaderDots from "./HeaderDots";

const Header = ({ enableMobileMenuSmall }) => (
  <>
    <CSSTransitionGroup
      component="div"
      className="app-header header-shadow"
      transitionName="HeaderAnimation"
      transitionAppear
      transitionAppearTimeout={1500}
      transitionEnter={false}
      transitionLeave={false}
    >
      <HeaderLogo />
      <div
        className={cx("app-header__content", {
          "header-mobile-open": enableMobileMenuSmall,
        })}
      >
        <div className="app-header-left">
          <SearchBox />
        </div>
        <div className="app-header-right">
          <HeaderDots />
          <UserBox />
          <HeaderRightDrawer />
        </div>
      </div>
    </CSSTransitionGroup>
  </>
);

const mapStateToProps = (state) => ({
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

Header.propTypes = {
  enableMobileMenuSmall: PropTypes.string,
};

Header.defaultProps = {
  enableMobileMenuSmall: false,
};
