import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import { subscriptionEmployeeSubscriptionItemBlockade } from "src/utils/RoleBasedSecurity/permissions";
import Form from "src/Components/Form";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const Suspend = ({ subscription, close }) => {
  const [data, setData] = useState({});
  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      setData(updatedData);
    },
    [data]
  );

  useEffect(() => {
    setData({
      suspendedFrom: subscription.suspendedFrom,
      suspendedTo: subscription.suspendedTo,
    });
  }, [subscription]);

  const submit = async () => {
    try {
      const { id } = subscription;
      await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employee-subscription-items/${id}/blockade`,
        "PATCH",
        {
          body: data,
        },
        null
      );
      dynamicNotification(
        __("Pomyślnie zmieniono daty zawieszeń świadczenia.")
      );
      close(true);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/Popup/suspend.js - 47"
      );
      dynamicNotification(
        e.message || __("Nie udało się zmienić świadczenia."),
        "error"
      );
    }
  };
  return (
    <Form
      id="suspendSubscriptionForm"
      data={data}
      config={{
        isInPopup: true,
        togglePopup: () => close(),
        title: "Zmiana daty zawieszenia świadczenia",
        onSubmit: submit,
        buttons: [
          {
            onClick: () => close(),
            text: "Zamknij",
            color: "light",
            id: "resetOneTimeFormClose",
          },
          {
            text: "Zmień",
            type: "submit",
            id: "resetOneTimeFormSubmit",
            permission: subscriptionEmployeeSubscriptionItemBlockade,
          },
        ],
        defaultOnChange: onChange,
        noCards: true,
        formGroups: [
          {
            formElements: [
              {
                component: (
                  <Alert key="popupInfo">
                    Pozostawienie pól niewypełnionych usuwa zawieszenie.
                  </Alert>
                ),
              },
              {
                id: "suspendedFrom",
                label: "Zawieszenie od:",
                type: "date",
              },
              {
                id: "suspendedTo",
                label: "Zawieszenie do:",
                type: "date",
                validation: [
                  {
                    method: "greaterEqualThanDate",
                    args: [data?.suspendedFrom || 0],
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

Suspend.propTypes = {
  close: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    suspendedFrom: PropTypes.string,
    suspendedTo: PropTypes.string,
  }).isRequired,
};

export default Suspend;
