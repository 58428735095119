import React from "react";
import PropTypes from "prop-types";
import TooltipWrapper from "src/Components/Tooltips/TooltipWrapper";
import RbsButton from "src/utils/RoleBasedSecurity/SecurityComponents/RbsButton";
import __ from "src/utils/Translations";

export default function ButtonsList({ buttons, dataT1 }) {
  return buttons
    .filter(Boolean)
    .map(
      (
        {
          id = "",
          size = "lg",
          color = "primary",
          className = "mr-2",
          onClick = () => {},
          text = "Zapisz",
          type = "button",
          disabled = false,
          tooltip,
          href,
          permission,
          data1,
          title = text,
          isVisible = true,
          hideIfNoPermission,
        },
        key
      ) => {
        if (!isVisible) {
          return null;
        }
        const dataAttribute =
          (dataT1 ? `${dataT1}Add` : data1) || id || key || "";

        const button = (
          <RbsButton
            /* eslint-disable-next-line react/no-array-index-key */
            key={id || key}
            data-t1={dataAttribute}
            size={size}
            color={color}
            type={type}
            permission={permission}
            className={className}
            disabled={disabled}
            onClick={onClick}
            title={title}
            href={href ? `${href}` : null}
            hideIfNoPermission={hideIfNoPermission}
          >
            {__(text)}
          </RbsButton>
        );
        return tooltip ? (
          <TooltipWrapper
            key={`button-list-tooltip-${id}`}
            content={tooltip}
            id={`button-list-tooltip-${key}`}
          >
            {button}
          </TooltipWrapper>
        ) : (
          button
        );
      }
    );
}

ButtonsList.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      size: PropTypes.string,
      color: PropTypes.string,
      className: PropTypes.string,
      permission: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      href: PropTypes.string,
      title: PropTypes.string,
      dataT1: PropTypes.string,
    })
  ).isRequired,
  dataT1: PropTypes.string,
};

ButtonsList.defaultProps = {
  dataT1: "",
};
