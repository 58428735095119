/* eslint-disable no-underscore-dangle, react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "react-loaders";
import {
  CATALOG_MANAGEMENT_SERVICE,
  MASTERDATA_SERVICE,
  restApiRequest,
} from "src/utils/Api";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import ActionColumn from "src/Components/DataTable/actionColumn";
import arrayUnique from "src/utils/jsHelpers/arrayUnique";
import isEmptyValue from "src/utils/jsHelpers/isEmptyValue";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function ProductSuppliersTable() {
  const getUrlToForm = (id) => `/suppliers/edit/${id}#view_supplier_data`;
  const [data, setData] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [count, setCount] = useState(0);

  const fetchData = useCallback(async (filters, page, pageSize, sort) => {
    const { data: newData, count: newCount } = await getListingData(
      MASTERDATA_SERVICE,
      "/supplierobject/nontouristic",
      [
        ...filters,
        {
          id: "rowsPerPage",
          value: pageSize,
        },
      ],
      page,
      pageSize,
      sort,
      {},
      mockData
    );
    setData(newData);
    setSuppliers([...newData]);
    setCount(newCount);
  }, []);

  useEffect(() => {
    const ids = suppliers
      .map(({ supplierObjectBusinessID }) => supplierObjectBusinessID)
      .filter(arrayUnique)
      .filter(Boolean);
    fetchProductsCount(ids)
      .then((countMap) => {
        setData(
          suppliers.map((el) => ({
            ...el,
            itemsAmount:
              countMap[el.supplierObjectBusinessID?.toLowerCase()] ?? 0,
          }))
        );
      })
      .catch((e) => {
        newRelicErrorReport(e, "/Pages/Supplier/List/table.js - 61");
        console.error(e);
      });
  }, [suppliers]);

  return (
    <DataTableControlled
      id="productSuppliersListing"
      fetchData={fetchData}
      data={data}
      count={count}
      filterable
      columns={[
        {
          Header: "Nazwa podmiotu",
          accessor: "supplierName",
        },
        {
          Header: "Nazwa obiektu",
          accessor: "objectName",
        },
        {
          Header: "Obszar",
          accessor: "objectCooperationArea",
          maxWidth: 200,
        },
        {
          Header: "Miasto",
          accessor: "city",
          maxWidth: 200,
        },
        {
          Header: "Ilość produktów",
          accessor: "itemsAmount",
          filterable: false,
          sortable: false,
          maxWidth: 200,
          Cell: lazyLoadingColumn,
        },
        {
          Header: "Akcja",
          maxWidth: 150,
          filterable: false,
          sortable: false,
          Cell: ({ row }) => (
            <div className="d-block w-100 text-center row">
              <ActionColumn
                data={row._original}
                buttons={[
                  {
                    id: "productSuppliersPreview",
                    className: "m-1",
                    href: getUrlToForm(row._original.supplierObjectBusinessID),
                    color: "link",
                    label: "Podgląd",
                  },
                ]}
              />
            </div>
          ),
        },
      ]}
      rowId="supplierId"
    />
  );
}

const lazyLoadingColumn = (cellInfo) => {
  const value = cellInfo.row[cellInfo.column.id];
  return (
    <div className="d-block w-100 text-center">
      {isEmptyValue(value) ? (
        <Loader active type="line-scale" style={{ transform: "scale(0.4)" }} />
      ) : (
        value
      )}
    </div>
  );
};

const fetchProductsCount = (ids) =>
  restApiRequest(
    CATALOG_MANAGEMENT_SERVICE,
    "/supplier-count-products",
    "POST",
    {
      body: {
        supplierIds: ids,
      },
    },
    []
  ).then((res) => {
    const countMap = {};
    res.forEach(({ id, count: productsCount }) => {
      countMap[id] = productsCount;
    });
    return countMap;
  });

export const mockData = [
  {
    supplierObjectBusinessID: "1",
    city: "Wrocław",
    objectCooperationArea: "Cała Polska",
    street: "Sezamkowa 15",
    objectName: "YASUMI Sp. z o.o. S.K.",
    country: "Polska",
    objectsAmount: 2,
    benefitsAmount: 4,
    code: "2342346",
    status: "Aktywny",
    supplierName: "GDS, MyBenefit",
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "2",
    city: "Gdańsk",
    street: "Sezamkowa 15",
    objectName: "YASUMI Sp. z o.o. S.K.",
    objectCooperationArea: "Cała Polska",
    country: "Polska",
    objectsAmount: 2,
    benefitsAmount: 4,
    code: "2342346",
    status: "Aktywny",
    supplierName: "GDS, MyBenefit",
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "3",
    city: "Wrocław",
    street: "Sezamkowa 15",
    objectName: "Active Woman",
    country: "Niemcy",
    objectsAmount: 2,
    objectCooperationArea: "Cała Polska",
    benefitsAmount: 4,
    code: "2342346",
    status: "Aktywny",
    supplierName: "GDS, MyBenefit",
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "4",
    city: "Wrocław",
    street: "Sezamkowa 15",
    objectName: "Akademia kreatywności",
    objectCooperationArea: "Belgia",
    country: "Belgia",
    status: "Aktywny",
    objectsAmount: 2,
    benefitsAmount: 4,
    code: "2342346",
    supplierName: "GDS, MyBenefit",
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "5",
    city: "Wrocław",
    street: "Sezamkowa 15",
    objectName: "Akademia fitness",
    country: "Belgia",
    status: "Aktywny",
    objectCooperationArea: "Cała Polska",
    code: "2342346",
    objectsAmount: 2,
    benefitsAmount: 4,
    supplierName: "ANIXE",
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "6",
    city: "Gdańsk",
    objectCooperationArea: "Cała Polska",
    street: "Sezamkowa 15",
    objectName: "All For Body",
    country: "Belgia",
    code: "2342346",
    status: "Aktywny",
    supplierName: "ANIXE",
    objectsAmount: 2,
    benefitsAmount: 4,
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "7",
    city: "Wrocław",
    street: "Sezamkowa 15",
    objectName: "Kręgielnia kometa",
    code: "2342346",
    country: "Belgia",
    status: "Aktywny",
    objectCooperationArea: "Cała Polska",
    supplierName: "ANIXE",
    objectsAmount: 2,
    benefitsAmount: 4,
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "8",
    city: "Wrocław",
    street: "Sezamkowa 15",
    objectName: "Krav Maga 3 System",
    country: "Polska",
    objectCooperationArea: "Cała Polska",
    objectsAmount: 2,
    benefitsAmount: 4,
    code: "2342346",
    status: "Aktywny",
    supplierName: "ANIXE",
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "9",
    city: "Wrocław",
    street: "Sezamkowa 15",
    objectName: "B2Carting",
    country: "Kolonia",
    objectCooperationArea: "Cała Polska",
    code: "2342346",
    status: "Aktywny",
    supplierName: "ANIXE",
    checkbox: false,
  },
  {
    supplierObjectBusinessID: "10",
    city: "Wrocław",
    street: "Sezamkowa 15",
    objectCooperationArea: "Cała Polska",
    objectName: "Mikołajkowo",
    country: "Niemcy",
    code: "2342346",
    status: "Aktywny",
    supplierName: "ANIXE",
    checkbox: false,
  },
];

ProductSuppliersTable.propTypes = {};
