import PropTypes from "prop-types";
import React, {
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { Row, Col, Card, CardBody, Form } from "reactstrap";

import ReactTable from "react-table";
import __ from "src/utils/Translations";
import useQueryToState from "src/utils/hooks/useQueryToState";
import ApiForbiddenError from "src/utils/Api/ApiForbiddenError";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import Pagination from "src/Components/Pagination";
import MassActionsSelect from "./MassActionsSelect";
import {
  getEncodedRequestFilters,
  getFilteredColumns,
  getParsedColumns,
  getRowSelectColumn,
  updateDataTableQuery,
} from "./utils";
import { ExportContext } from "./context";
import { dataTableControlledFooterStyle as footerStyle } from "./styles";
import ExportButtons from "./ExportButtons";
import Filters from "./Filters";

export default function DataTableControlled({
  apiRef,
  columns,
  data,
  filterable,
  defaultPageSize,
  exportContext,
  exportContextAhr,
  fetchData,
  additionalFilters,
  count,
  totalCount,
  buttons,
  getTrProps,
  massActions,
  rowId,
  paramsInUrl,
  id,
  title,
  footer,
  reloadDependencies,
  fullData,
  shouldUpdateOnFiltersChange,
  fetchFullData,
  massActionSelect,
  setMassActionSelect,
  onChangeMassActionSelectCb,
  selectionOptions,
}) {
  const [
    {
      pageSize: initialPageSize,
      orderKey,
      orderDir,
      page: initialPage,
      ...initialFilters
    },
    changeQuery,
  ] = useQueryToState();
  const [pageSize, setPageSize] = useState(initialPageSize || defaultPageSize);
  const [hasAccess, setHasAccess] = useState(true);
  const [page, setPage] = useState(+initialPage || 1);
  const [filters, setFilters] = useState(() =>
    Object.keys(initialFilters).map((key) => ({
      id: key,
      value: initialFilters[key],
    }))
  );
  const [sort, setSort] = useState(() => {
    if (orderKey) {
      return {
        key: orderKey,
        value: orderDir || "asc",
      };
    }
    return null;
  });

  const pages = Math.ceil(count / +pageSize);

  const [loading, setLoading] = useState(false);
  const updateQuery = useCallback(
    (requestFilters, requestPage, requestPageSize, requestSort) =>
      updateDataTableQuery({
        requestFilters,
        requestPage,
        requestPageSize,
        requestSort,
        defaultPageSize,
        changeQuery,
      }),
    [changeQuery, defaultPageSize]
  );
  const updateData = useCallback(
    async (requestFilters, requestPage, requestPageSize, requestSort) => {
      if (paramsInUrl) {
        updateQuery(requestFilters, requestPage, requestPageSize, requestSort);
      }
      const encodedRequestFilters = getEncodedRequestFilters({
        requestFilters,
      });

      try {
        await fetchData(
          encodedRequestFilters,
          requestPage,
          requestPageSize,
          requestSort
        );
        if (fullData) {
          await fetchFullData(encodedRequestFilters, requestSort);
        }
      } catch (e) {
        if (e instanceof ApiForbiddenError) {
          setHasAccess(false);
        }
        newRelicErrorReport(e, "Components/DataTableControlled/index.js - 126");
      }
      setLoading(false);
    },
    [paramsInUrl, updateQuery, fetchData, fullData, fetchFullData]
  );

  const updateOnFiltersChange = shouldUpdateOnFiltersChange && filters;

  useEffect(() => {
    if (typeof setMassActionSelect !== "function") return;
    setMassActionSelect((actions) => ({
      ...actions,
      includedSelectAll: fullData,
    }));
  }, [fullData, setMassActionSelect]);

  useEffect(() => {
    setLoading(true);
    updateData(filters, page, pageSize, sort);
    setPage(page);
    // eslint-disable-next-line
  }, [...reloadDependencies, updateOnFiltersChange]);

  const onPageChange = useCallback(
    (newPage) => {
      if (loading) {
        return;
      }

      setLoading(true);
      updateData(filters, newPage, pageSize, sort);
      setPage(newPage);
    },
    [loading, updateData, filters, pageSize, sort]
  );

  const onFilteredChange = useCallback(
    (newFilters) => {
      if (loading) {
        return;
      }
      setFilters(newFilters);
    },
    [loading]
  );

  const filterData = useCallback(() => {
    if (loading) {
      return;
    }
    const newPage = 1;
    setLoading(true);
    setPage(newPage);
    updateData(filters, newPage, pageSize, sort);
  }, [filters, pageSize, loading, updateData, sort]);

  const changeSort = useCallback(
    (field) => {
      if (loading) {
        return;
      }

      let sortValue = null;
      if (field && field[0]) {
        sortValue = {
          key: field[0].id,
          value: field[0].desc ? "desc" : "asc",
        };
      }
      const newPage = 1;
      setLoading(true);
      updateData(filters, newPage, pageSize, sortValue);
      setSort(sortValue);
      setPage(newPage);
    },
    [loading, filters, pageSize, setLoading, setSort, setPage, updateData]
  );

  const updateMassActionSelection = useCallback(
    async (included, excluded) => {
      if (typeof setMassActionSelect !== "function") return;

      // === true, because we need to check if included is exactly true, not array or object
      if (included === true) {
        setLoading(true);
        await fetchFullData(filters, sort);
        setLoading(false);
      }
      setMassActionSelect((actions) => {
        const value = { ...actions, included, excluded };
        if (typeof onChangeMassActionSelectCb === "function")
          onChangeMassActionSelectCb(value.included);
        return value;
      });
    },
    [
      filters,
      sort,
      setMassActionSelect,
      setLoading,
      fetchFullData,
      onChangeMassActionSelectCb,
    ]
  );

  const resetFilters = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newPage = 1;
    const newFilters = [];
    updateData(newFilters, newPage, pageSize, sort);
    setFilters(newFilters);
    setPage(newPage);
  }, [loading, updateData, pageSize, sort]);

  const onPageSizeChange = useCallback(
    (newPageSize) => {
      if (loading) {
        return;
      }
      setLoading(true);
      const newPage = 1;
      updateData(filters, newPage, newPageSize, sort);
      setPageSize(newPageSize);
      setPage(newPage);
    },
    [filters, loading, updateData, sort]
  );

  const onRemoveItem = useCallback(() => {
    if (loading) {
      return;
    }

    const newPage =
      pages > 1 && pages === page && data.length === 1 ? page - 1 : page;

    updateData(filters, newPage, pageSize, sort);
    setPage(newPage);
  }, [loading, data.length, filters, page, pageSize, pages, sort, updateData]);

  useImperativeHandle(
    apiRef,
    () => ({
      refetch: filterData,
      reset: resetFilters,
      onRemoveItem,
    }),
    [filterData, onRemoveItem, resetFilters]
  );

  const filteredColumns = useMemo(() => getFilteredColumns(columns), [columns]);

  const parsedColumns = useMemo(() => {
    const columns = getParsedColumns(filteredColumns);

    if (massActionSelect) {
      const pageIds = data.map((item) => item[rowId]);
      columns.unshift(
        getRowSelectColumn(
          rowId,
          pageIds,
          massActionSelect.included,
          massActionSelect.excluded,
          updateMassActionSelection,
          selectionOptions,
          onChangeMassActionSelectCb
        )
      );
    }

    return columns;
  }, [
    filteredColumns,
    data,
    rowId,
    massActionSelect,
    selectionOptions,
    updateMassActionSelection,
    onChangeMassActionSelectCb,
  ]);

  return (
    <>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                filterData();
              }}
              data-t1={id}
            >
              <CardBody>
                {buttons.length || exportContext || exportContextAhr ? (
                  <ExportButtons
                    id={id}
                    buttons={buttons}
                    exportContext={exportContext}
                    exportContextAhr={exportContextAhr}
                    massActionSelect={massActionSelect}
                    count={count}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    page={page}
                    filters={filters}
                    sort={sort}
                  />
                ) : null}
                <Filters
                  filterable={filterable}
                  title={title}
                  filterData={filterData}
                  resetFilters={resetFilters}
                  additionalFilters={additionalFilters}
                  filters={filters}
                  onFilteredChange={onFilteredChange}
                />
                {massActions?.length && massActionSelect ? (
                  <MassActionsSelect
                    filters={filters}
                    excluded={massActionSelect.excluded}
                    included={massActionSelect.included}
                    massActions={massActions}
                    count={
                      massActionSelect.included === true
                        ? count - massActionSelect.excluded.length
                        : massActionSelect.included.length
                    }
                  />
                ) : null}
                <div
                  className="text-right"
                  data-t1="numberOfRecords"
                  data-t2={count}
                >
                  {__("Ilość rekordów")}:{count}
                </div>
                <ReactTable
                  data={data}
                  columns={parsedColumns}
                  className="-striped -highlight"
                  NoDataComponent={() => (
                    <div className="no-table-controlled-records">
                      {__("Nie znaleziono rekordów")}
                    </div>
                  )}
                  filterable={filterable}
                  loading={loading || !hasAccess}
                  loadingText={
                    hasAccess
                      ? __("Pobieranie danych...")
                      : __(
                          "Taki zasób nie istnieje lub nie masz do niego dostępu."
                        )
                  }
                  showFilters
                  previousText="Poprzednia"
                  nextText="Następna"
                  page={0}
                  sorted={
                    sort ? [{ id: sort.key, desc: sort.value === "desc" }] : []
                  }
                  pageSize={pageSize}
                  filtered={filters}
                  minRows={0}
                  defaultFilterMethod={() => data}
                  defaultSortMethod={() => data}
                  showPagination={false}
                  onSortedChange={changeSort}
                  onFilteredChange={onFilteredChange}
                  getTheadThProps={(a, b, column) =>
                    column.sortable !== false
                      ? {
                          "data-t1": "gridSort",
                          "data-t2": column.id,
                        }
                      : {}
                  }
                  getTrProps={(state, row) => ({
                    "data-t1": "gridRow",
                    "data-t2":
                      row?.original?.benefit?.id ?? row?.original[rowId],
                    ...getTrProps(state, row),
                  })}
                  getTdProps={(state, row, column) => ({
                    "data-t1": "gridCell",
                    "data-t2": column.data2 || column.id,
                    "data-t3": state.sortedData?.[row.index]?.[column.id],
                  })}
                />
                <Pagination
                  page={page}
                  pageSize={+pageSize}
                  pages={pages}
                  availablePageSizes={[20, 40, 50, 80, 100]}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                />
                <div className="float-left" style={footerStyle}>
                  {footer}
                </div>
              </CardBody>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

DataTableControlled.propTypes = {
  apiRef: PropTypes.shape({
    current: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      onRemoveItem: PropTypes.func.isRequired,
    }),
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.string,
    })
  ),
  count: PropTypes.number,
  totalCount: PropTypes.number,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  fullData: PropTypes.arrayOf(PropTypes.string),
  defaultPageSize: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  fetchFullData: PropTypes.func,
  getTrProps: PropTypes.func,
  filterable: PropTypes.bool,
  additionalFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.oneOf(["text", "select"]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    })
  ),
  rowId: PropTypes.string,
  paramsInUrl: PropTypes.bool,
  exportContext: PropTypes.instanceOf(ExportContext),
  exportContextAhr: PropTypes.instanceOf(ExportContext),
  massActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    })
  ),
  title: PropTypes.string,
  footer: PropTypes.string,
  reloadDependencies: PropTypes.instanceOf(Array),
  shouldUpdateOnFiltersChange: PropTypes.bool,
  massActionSelect: PropTypes.shape({
    included: PropTypes.arrayOf(PropTypes.string),
    excluded: PropTypes.arrayOf(PropTypes.string),
    includedSelectAll: PropTypes.arrayOf(PropTypes.string),
  }),
  setMassActionSelect: PropTypes.func,
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  onChangeMassActionSelectCb: PropTypes.func,
};

DataTableControlled.defaultProps = {
  apiRef: {
    current: {
      refetch: () => {
        throw new Error("refetch() not implemented");
      },
      reset: () => {
        throw new Error("reset() not implemented");
      },
      onRemoveItem: () => {
        throw new Error("onRemoveItem() not implemented");
      },
    },
  },
  data: {},
  fullData: [],
  buttons: [],
  rowId: "id",
  massActions: null,
  exportContext: null,
  exportContextAhr: null,
  paramsInUrl: true,
  count: 0,
  totalCount: 0,
  defaultPageSize: 20,
  fetchFullData: () => {},
  getTrProps: () => ({}),
  filterable: true,
  additionalFilters: null,
  title: null,
  footer: null,
  reloadDependencies: [],
  shouldUpdateOnFiltersChange: false,
  massActionSelect: null,
  setMassActionSelect: null,
  selectionOptions: undefined,
  onChangeMassActionSelectCb: undefined,
};
