import React from "react";
import _ from "lodash";
import __ from "src/utils/Translations";
import { isDevMode } from "src/config/env";
import { godPermission } from "./permissions";
import ForbiddenPage from "./SecurityComponents/ForbiddenPage";

const isDev = isDevMode();
export const filterNavItemsByAlc = (menu, userInfo, companyData) => {
  const permissions = userInfo.getPermissions();
  const {
    functionalities = [],
    ahrMyBenefitActiveUrl = "",
    ombMyBenefitActiveUrl = "",
  } = companyData || {};
  return menu
    .map((item) => {
      if (item.dev && !isDev) {
        return null;
      }
      if (
        item.aclKey &&
        !permissions.includes(item.aclKey) &&
        !permissions.includes(godPermission)
      ) {
        return null;
      }

      // Nav item is enabled on one functionality - item.functionality is a string
      // Nav item is enabled on multiple functionalities - item.functionalities is an array of strings
      if (
        (companyData &&
          item.functionality &&
          !functionalities.includes(item.functionality)) ||
        (companyData &&
          item.functionalities?.length &&
          !_.intersection(functionalities, item.functionalities).length)
      ) {
        // if companyData is not defined, we can't filter by functionality
        return null;
      }
      const updatedItem = { ...item };
      if (
        updatedItem.href &&
        updatedItem.href.includes("{MyBenefitActiveUrl}")
      ) {
        updatedItem.href = userInfo.isAhr()
          ? ahrMyBenefitActiveUrl
          : ombMyBenefitActiveUrl;
        if (!updatedItem.href) {
          return null;
        }
      }
      if (item.content) {
        updatedItem.content = filterNavItemsByAlc(
          item.content,
          userInfo,
          companyData
        );
        if (updatedItem.content.length === 0) {
          return null;
        }
      }
      return updatedItem;
    })
    .filter(Boolean);
};

export const filterTabsByAcl = (tabsConfig, userInfo) => {
  const permissions = userInfo.getPermissions();
  return tabsConfig
    .map((item) => {
      if (
        item.aclKey &&
        !permissions.includes(item.aclKey) &&
        !permissions.includes(godPermission)
      ) {
        return {
          ...item,
          component: <ForbiddenPage permission={item.aclKey} />,
          disabled: true,
          tabClassName: "not-allowed",
          tabTitle: __("Nie masz uprawnień ({0})", [item.aclKey]),
        };
      }
      return item;
    })
    .filter(Boolean);
};

export const hasAccessTo = (userInfo, aclKey) => {
  const permissions = userInfo.getPermissions();
  return Boolean(
    permissions.includes(godPermission) || permissions.includes(aclKey)
  );
};
