export const LOADING_FALLBACK_VALUE = "***";

export const alertMessages = {
  default: "Uwaga! ta zmiana jest nieodwracalna.",
};

export const conditionTypes = {
  AHR_ACCEPTANCE_REQUIRED: "Akceptacja przez AHR",
  WAITING_FOR_PARENT: "Oczekuje na świadczenia zależne",
  DOCUMENT_REQUIRED: "Akceptacja dokumentu potrącenia z wynagrodzenia",
  SIGNED_DOCUMENT_REQUIRED: "Wymagany podpisany dokument",
};

export const ACCEPTED_REQUIREMENT_SUCCESS_ONE =
  "Pomyślnie zaakceptowano wymaganie";
export const ACCEPTED_REQUIREMENT_SUCCESS_OTHER =
  "Pomyślnie zaakceptowano wymagania";

export const ACCEPTED_REQUIREMENT_FAILURE_ONE =
  "Nie udało się zaakceptować wymagania";
export const ACCEPTED_REQUIREMENT_FAILURE_OTHER =
  "Nie udało się zaakceptować wymagań";

export const REJECT_BENEFIT_SUCCESS = "Pomyślnie odrzucono świadczenie";
export const REJECT_BENEFIT_FAILURE = "Nie udało się odrzucić świadczenia";
