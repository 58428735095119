import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import { subscriptionEmployeeSubscriptionItemResign } from "src/utils/RoleBasedSecurity/permissions";
import Form from "src/Components/Form";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import getLastDayOfTheMonth from "src/utils/jsHelpers/getLastDayOfTheMonth";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const Resign = ({ subscription, close, forceProcessItem }) => {
  const [data, setData] = useState({});
  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      if (key === "endsAt") {
        updatedData[key] = getLastDayOfTheMonth(value);
      } else {
        updatedData[key] = value;
      }
      setData(updatedData);
    },
    [data]
  );

  // if startsAt is in the past then resignation should be available from now on
  const resignStartsAt =
    new Date(subscription?.startsAt) < new Date()
      ? new Date()
      : new Date(subscription?.startsAt);

  useEffect(() => {
    const now = new Date();
    const startsAt = resignStartsAt;
    setData({
      blockTo: subscription.blockTo,
      endsAt: getLastDayOfTheMonth(now >= startsAt ? now : startsAt),
    });
  }, [subscription]);

  const submit = async () => {
    try {
      const { id } = subscription;
      // To resign from whole subscription (not sigle receiver) forced flag is needed
      const processedData = {
        ...data,
        forceProcessItem,
      };

      await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employee-subscription-item-receivers/${id}/resign`,
        "POST",
        {
          body: processedData,
        },
        null
      );
      dynamicNotification(__("Pomyślnie zrezygnowano ze świadczenia."));
      close(true);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/Popup/resign.js - 53"
      );
      dynamicNotification(
        e.message || __("Nie udało się zrezygnować ze świadczenia."),
        "error"
      );
    }
  };

  return (
    <Form
      id="suspendSubscriptionForm"
      data={data}
      config={{
        isInPopup: true,
        togglePopup: () => close(),
        title: "Rezygnacja ze świadczenia",
        onSubmit: submit,
        buttons: [
          {
            onClick: () => close(),
            text: "Zamknij",
            color: "light",
            id: "resetOneTimeFormClose",
          },
          {
            text: "Zmień",
            type: "submit",
            id: "resetOneTimeFormSubmit",
            permission: subscriptionEmployeeSubscriptionItemResign,
          },
        ],
        defaultOnChange: onChange,
        noCards: true,
        formGroups: [
          {
            formElements: [
              {
                id: "endsAt",
                label: "Rezygnacja od:",
                type: "month",
                minDate: resignStartsAt,
              },
              {
                id: "blockTo",
                label: "Blokada ponownego wyboru do:",
                type: "date",
                tooltip: {
                  content: __(
                    "Pozostaw puste aby nie blokować ponownego wyboru tego świadczenia."
                  ),
                },
                validation: [
                  {
                    method: "greaterEqualThanDate",
                    args: [data?.endsAt || 0],
                  },
                ],
                minDate: data.endsAt,
              },
              {
                component: <Info />,
              },
            ],
          },
        ],
      }}
    />
  );
};

const Info = () => (
  <Alert key="cancelInfo" color="primary">
    <span>
      {__(
        `Jeśli benefit posiada świadczenia zależne, zostaną one zrezygnowane w dacie podanej dla świadczenia głównego. 
       Rezygnacja ze świadczenia dla pracownika spowoduje rezygnację ze świadczeń dla osób dodatkowych. 
       Jeśli osoby dodatkowe mają ustawioną datę rezygnacji późniejszą niż data rezygnacji pracownika, 
       zostanie skrócony okres obowiązywania świadczenia dla osoby dodatkowej i data rezygnacji zostanie ustawiona na taką jaką posiada pracownik.`
      )}
    </span>
  </Alert>
);

Resign.propTypes = {
  close: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    blockTo: PropTypes.string,
    startsAt: PropTypes.string,
  }).isRequired,
  forceProcessItem: PropTypes.bool.isRequired,
};

export default Resign;
