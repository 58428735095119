import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader } from "reactstrap";
import Form from "src/Components/Form";
import { subscriptionEmployeeSubscriptionItemRead } from "src/utils/RoleBasedSecurity/permissions";
import { getEmployeesOptionsFetchMethod } from "src/Components/FormElements/Autocomplete/commonFetchMethods";
import {
  PENDING_BENEFIT_FILTER_FIELDS_BY_GROUP,
  PENDING_BENEFIT_FILTER_GROUPS,
  statusOptions,
} from "src/Pages/Company/Pending/BenefitsPending/Listing/utils";
import { LAYOUT_THREE_COLUMNS } from "src/Components/Layouts";
import __ from "src/utils/Translations";

export default function PendingBenefitsTable({
  companyId,
  fetchData,
  filters,
  setFilters,
  resetFilters,
}) {
  const isClearingFormNumbersFilters = useRef(false);
  const isClearingDefaultFilters = useRef(false);

  const onChange = (key, value, filterGroup) => {
    const updatedData = { ...filters };
    updatedData[filterGroup][key] = value;
    setFilters(updatedData);
  };
  const onRangeChange = (key, value, filterGroup) => {
    const updatedData = { ...filters };
    const { from, to } = value;
    updatedData[filterGroup][`${key}From`] = from;
    updatedData[filterGroup][`${key}To`] = to;
    setFilters(updatedData);
  };

  return (
    <>
      <Card className="mb-4">
        <CardHeader className="card-header-lg text-transform-none">
          <div className="card-header-title font-size-lg font-weight-normal">
            {__("Filtry")}
          </div>
        </CardHeader>
      </Card>
      <Form
        id="pendingBenefitsFilters"
        data={filters[PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS]}
        config={{
          title: __("Formularze z kodem kreskowym"),
          buttons: [
            {
              size: "lg",
              color: "danger",
              className: "mr-2",
              text: `${__("Wyczyść")}`,
              onClick: (e) => {
                resetFilters(PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS);
                if (e.target.form) {
                  const submitButton = e.target.form.querySelector(
                    'button[type="submit"]'
                  );
                  setTimeout(() => {
                    isClearingFormNumbersFilters.current = true;
                    submitButton.click();
                  }, 10);
                }
              },
              id: "pendingBenefitsReset",
              permission: subscriptionEmployeeSubscriptionItemRead,
            },
            {
              size: "lg",
              color: "success",
              className: "mr-2",
              text: `${__("Szukaj")}`,
              type: "submit",
              id: "pendingBenefitsSearch",
              permission: subscriptionEmployeeSubscriptionItemRead,
            },
          ],
          stickyTitle: false,
          onSubmit: () => {
            if (isClearingFormNumbersFilters.current) {
              fetchData();
              isClearingFormNumbersFilters.current = false;
              return;
            }
            fetchData(PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS);
          },
          defaultOnChange: (key, value) =>
            onChange(key, value, PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS),
          formGroups: [
            {
              formElements: [
                {
                  id: PENDING_BENEFIT_FILTER_FIELDS_BY_GROUP[
                    PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS
                  ].FORM_NUMBERS,
                  label: `${__("Numer formularza")}:`,
                  type: "tagInput",
                  tooltip: {
                    content: (
                      <>
                        Wyszukiwanie możliwe jest po całości kodu, jeśli chcesz
                        wyszukać kilka kodów oddziel je od siebie spacją.
                      </>
                    ),
                  },
                  props: {
                    addOnPaste: true,
                    addOnBlur: true,
                    onlyUnique: true,
                    addKeys: ["Enter", " ", ","],
                    inputProps: {
                      placeholder: null,
                    },
                  },
                },
              ],
            },
          ],
        }}
      />

      <Form
        id="pendingBenefitsFilters"
        data={filters[PENDING_BENEFIT_FILTER_GROUPS.DEFAULT]}
        config={{
          title: __("Wszystkie świadczenia w benefitach oczekujących"),
          buttons: [
            {
              size: "lg",
              color: "danger",
              className: "mr-2",
              text: `${__("Resetuj")}`,
              onClick: (e) => {
                resetFilters(PENDING_BENEFIT_FILTER_GROUPS.DEFAULT);
                if (e.target.form) {
                  const submitButton = e.target.form.querySelector(
                    'button[type="submit"]'
                  );
                  isClearingDefaultFilters.current = true;
                  setTimeout(() => {
                    submitButton.click();
                  }, 10);
                }
              },
              id: "pendingBenefitsReset",
              permission: subscriptionEmployeeSubscriptionItemRead,
            },
            {
              size: "lg",
              color: "success",
              className: "mr-2",
              text: `${__("Szukaj")}`,
              type: "submit",
              id: "pendingBenefitsSearch",
              permission: subscriptionEmployeeSubscriptionItemRead,
            },
          ],
          stickyTitle: false,
          onSubmit: () => {
            if (isClearingDefaultFilters.current) {
              fetchData();
              isClearingDefaultFilters.current = false;
              return;
            }
            fetchData(PENDING_BENEFIT_FILTER_GROUPS.DEFAULT);
          },
          defaultOnChange: (key, value) =>
            onChange(key, value, PENDING_BENEFIT_FILTER_GROUPS.DEFAULT),
          formGroups: [
            {
              formElements: [
                {
                  id: PENDING_BENEFIT_FILTER_FIELDS_BY_GROUP[
                    PENDING_BENEFIT_FILTER_GROUPS.DEFAULT
                  ].CREATED_AT,
                  label: `${__("Data wyboru")}:`,
                  type: "dateRange",
                  props: {
                    minDateFrom: new Date(1970, 1, 1, 1),
                    minDateTo: new Date(1970, 1, 1, 1),
                  },
                  onChange: (key, value) =>
                    onRangeChange(
                      key,
                      value,
                      PENDING_BENEFIT_FILTER_GROUPS.DEFAULT
                    ),
                },
                {
                  layout: LAYOUT_THREE_COLUMNS,
                  formElements: [
                    {
                      id: PENDING_BENEFIT_FILTER_FIELDS_BY_GROUP[
                        PENDING_BENEFIT_FILTER_GROUPS.DEFAULT
                      ].EMPLOYEE_ID,
                      label: "Pracownik/Numer FK:",
                      type: "asyncAutocomplete",
                      isMultiselect: true,
                      fetchOptions: getEmployeesOptionsFetchMethod(
                        filters?.employeeId,
                        { companyId }
                      ),
                    },
                    {
                      id: PENDING_BENEFIT_FILTER_FIELDS_BY_GROUP[
                        PENDING_BENEFIT_FILTER_GROUPS.DEFAULT
                      ].BENEFIT_NAME,
                      label: "Nazwa benefitu:",
                      type: "text",
                      translatable: {
                        scope: "companyManagement:pendingBenefits:benefitName",
                      },
                    },
                    {
                      id: "status",
                      label: "Status:",
                      type: "select",
                      options: statusOptions,
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    </>
  );
}

PendingBenefitsTable.propTypes = {
  companyId: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    employeeId: PropTypes.string,
  }).isRequired,
};
