import { hasAccessTo } from "src/utils/RoleBasedSecurity/filters";
import __ from "src/utils/Translations";
import { restApiRequest, TOURISM_SERVICE } from "src/utils/Api";
import { tourismObjectsMockData } from "src/Pages/Tourism/listOfObjects/table";
import isUuid from "src/utils/jsHelpers/isUuid";
import { tourismTourismObjectPermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import { getAnixeValue } from "src/Pages/Tourism/listOfObjects/editObject/utils/anixeData";

const findTourismObjects = (value, ctx) => {
  if (!hasAccessTo(ctx.userInfo, tourismTourismObjectPermissionRead)) {
    return [];
  }
  const filter = isUuid(value)
    ? { id: value }
    : { multi_searchByName: [value] };
  return restApiRequest(
    TOURISM_SERVICE,
    "/tourism-objects",
    "GET",
    {
      params: {
        ...filter,
        itemsPerPage: 10,
      },
    },
    tourismObjectsMockData.filter((item) => {
      const name = item?.name || getAnixeValue(item?.anixeData || {}, "name");
      return name.toLowerCase().includes(value.toLowerCase());
    })
  ).then((res) =>
    res.map(({ name, id }) => ({
      label: name,
      to: `/tourism/objects/edit/${id}#basic_info`,
    }))
  );
};

export default findTourismObjects;
