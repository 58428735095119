import isJson from "src/utils/jsHelpers/isJson";

const parseDataFromBackend = (data, sectionId) => {
  const result = {};
  data.forEach(({ path, value }) => {
    if (!sectionId || path.split("/")[0] === sectionId) {
      if (isJson(value)) {
        result[path] = JSON.parse(value);
      } else {
        result[path] = value;
      }
    }
  });
  return result;
};

export default parseDataFromBackend;
