import React from "react";
import { Button, Label } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { GetErrorMessage } from "src/Pages/Report/Validation/validations";
import FreqDescription from "./Frequency/freqDescritpion";

const SubscriptionForm = ({
  subscribeMethodDropDown,
  subscribeFrequencyDropDown,
  subscribeLanguageDropDown,
  setSubscribeMethod,
  setSubscribeFrequency,
  setSubscribeLanguage,
  setNote,
  setIsOpenDataMailModal,
  clickSubscribe,
  occurrence,
  subscribeInfo,
  isEdit,
  clickFrequencyEdit,
  isSaving,
  isGetBlockBtn,
  isTokenAvailable,
  isdateOK,
}) => {
  const validate = (value) => {
    let isError = false;

    if (!isdateOK) {
      isError = true;
      dynamicNotification(
        GetErrorMessage(__('"Data od" i "Data do"')),
        "error"
      );
    }
    if (subscribeMethod.subscribeMethod.value < 1) {
      dynamicNotification(GetErrorMessage(__('"Sposób wysyłki"')), "error");
      isError = true;
    }
    if (subscribeFrequency.occurrence === "") {
      dynamicNotification(GetErrorMessage(__('"Częstotliwość"')), "error");
      isError = true;
    }
    if (subscribeLanguage === "") {
      dynamicNotification(GetErrorMessage(__('"Język raportu"')), "error");
      isError = true;
    }

    if (!isError) {
      clickSubscribe();
    }
  };
  const tokenValid = () => {
    if (isTokenAvailable === false) {
      return __(
        "wysyłka z zaszyfrowanym plikiem xls może nie zostać wysłana – token dostępowy jest pusty"
      );
    }
    return "";
  };
  const subScribeBtn = () => {
    const buttonText = isEdit ? __("Zapisz") : __("Ustaw");
    const buttonSavingText = isEdit ? __("Zapisywanie") : __("Ustawianie");

    return isSaving ? (
      <Button color="success" onClick={validate} disabled>
        <FontAwesomeIcon icon={faSpinner} spin /> {buttonSavingText}{" "}
        {__("wysyłki...")}
      </Button>
    ) : (
      <Button
        className="mx-1"
        color="success"
        onClick={validate}
        disabled={isGetBlockBtn}
      >
        {buttonText} {__("wysyłkę")}
      </Button>
    );
  };

  const { subscribeMethod, subscribeFrequency, subscribeLanguage, note } =
    subscribeInfo;
  subscribeMethodDropDown.forEach((d) => {
    // eslint-disable-next-line no-param-reassign
    d.label = __(d.label);
  });
  subscribeFrequencyDropDown.forEach((d) => {
    // eslint-disable-next-line no-param-reassign
    d.label = __(d.label);
  });
  subscribeLanguageDropDown.forEach((d) => {
    // eslint-disable-next-line no-param-reassign
    d.label = __(d.label);
  });
  const selectedsubscribeMethod = {
    label: __(subscribeMethod.subscribeMethod.label),
    value: subscribeMethod.subscribeMethod.value,
    isSelected: subscribeMethod.subscribeMethod.isSelected,
  };
  const selectedsubscribeFrequency = {
    label: __(subscribeFrequency.subscribeFrequency.label),
    value: subscribeFrequency.subscribeFrequency.value,
    isSelected: subscribeFrequency.subscribeFrequency.isSelected,
  };

  return (
    <div className="col-md-12">
      <Form onSubmit={(e) => e.preventDefault()}>
        {" "}
        <div className="row">
          <div className="col-md-3">
            <Label>{__("Sposób wysyłki")}</Label>
            <Select
              defaultValue={{ label: __("Wybierz..."), value: -1 }}
              name="subscriptionMethod"
              className="basic-select"
              classNamePrefix="select"
              options={subscribeMethodDropDown}
              onChange={setSubscribeMethod}
              value={selectedsubscribeMethod}
            />
          </div>
          <div className="col-md-3">
            <Label>{__("Częstotliwość")}</Label>
            <Select
              defaultValue={{ label: __("Wybierz..."), value: -1 }}
              name="frequency"
              className="basic-select"
              classNamePrefix="select"
              options={subscribeFrequencyDropDown}
              onChange={setSubscribeFrequency}
              value={selectedsubscribeFrequency}
            />
          </div>
          <div className="col-md-6">
            <Label>{__("Notatka")}</Label>
            <Input
              className="form-control"
              type="text"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
        <div className="row top-buffer">
          <div className="col-md-3">
            <Label>{__("Język raportu")}</Label>
            <Select
              defaultValue={{ label: __("Wybierz..."), value: -1 }}
              name="language"
              className="basic-select"
              classNamePrefix="select"
              options={subscribeLanguageDropDown}
              onChange={setSubscribeLanguage}
              value={subscribeLanguageDropDown.find(
                (el) => el.value === subscribeLanguage
              )}
            />
          </div>
        </div>{" "}
        <div className="col-md-12 text-danger">{tokenValid()}</div>
        <div className="row subscription-info">
          <div className="col-md-8">
            <FreqDescription info={occurrence} />
          </div>
          <div className="col-md-4 text-right">
            <Button
              className="mx-1"
              color="secondary"
              onClick={() => setIsOpenDataMailModal(true)}
            >
              {__("Edytuj dane maila")}
            </Button>
            {isEdit && (
              <Button
                className="mx-1"
                color="secondary"
                onClick={clickFrequencyEdit}
              >
                {__("Edytuj częstotliwość")}
              </Button>
            )}
            {subScribeBtn()}
          </div>
        </div>
      </Form>
    </div>
  );
};
SubscriptionForm.propTypes = {
  subscribeMethodDropDown: PropTypes.array,
  subscribeFrequencyDropDown: PropTypes.array,
  subscribeLanguageDropDown: PropTypes.array,
  setSubscribeMethod: PropTypes.func.isRequired,
  setSubscribeFrequency: PropTypes.func.isRequired,
  setSubscribeLanguage: PropTypes.func.isRequired,
  note: PropTypes.string,
  setNote: PropTypes.func.isRequired,
  setIsOpenDataMailModal: PropTypes.func.isRequired,
  clickSubscribe: PropTypes.func.isRequired,
  occurrence: PropTypes.string.isRequired,
  subscribeInfo: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  clickFrequencyEdit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isGetBlockBtn: PropTypes.bool.isRequired,
  isTokenAvailable: PropTypes.bool.isRequired,
  isdateOK: PropTypes.func.isRequired,
};

SubscriptionForm.defaultProps = {
  subscribeMethodDropDown: [],
  subscribeFrequencyDropDown: [],
  subscribeLanguageDropDown: [],
  note: "",
};

export default SubscriptionForm;
