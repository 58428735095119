import { activationModeDateKeys } from "./consts";

export const parseRentableGroupSelectionWindowDataFromBackend = ({
  newEmployee,
  windowType,
  ...data
}) => {
  const newEmployeeValue =
    typeof newEmployee === "boolean" ? String(newEmployee) : null;
  const windowTypeValue =
    windowType === 3 ? ["1", "2"] : [windowType.toString()];
  return {
    ...data,
    newEmployee: newEmployeeValue,
    windowType: windowTypeValue,
  };
};

export const parseRentableGroupSelectionWindowDataToBackend = ({
  newEmployee,
  name,
  windowType,
  rangeDateFrom,
  rangeDateTo,
  ...data
}) => ({
  ...data,
  newEmployee: newEmployee !== undefined ? JSON.parse(newEmployee) : undefined,
  name: name || null,
  windowType: sumWindowTypes(windowType),
  ...(data?.rangeDateFromLocal
    ? { rangeDateFrom: data.rangeDateFromLocal }
    : {}),
  ...(data?.rangeDateToLocal ? { rangeDateTo: data.rangeDateToLocal } : {}),
  ...(data?.periodicallyDateFromLocal
    ? { periodicallyDateFrom: data.periodicallyDateFromLocal }
    : {}),
});

export const sumWindowTypes = (windowType) => {
  let sum = 0;
  windowType.forEach((type) => {
    sum += parseInt(type, 10);
  });

  return sum;
};

export const isFutureDate = (date) => {
  if (!date) {
    return false;
  }
  const now = new Date();
  const dt = new Date(date);

  return dt.getTime() > now.getTime();
};

export const removeActivationModeFields = (data) => {
  const updatedData = { ...data };
  activationModeDateKeys.forEach((key) => delete updatedData[key]);
  return updatedData;
};
