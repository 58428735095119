import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import PageTitle from "src/Layout/AppMain/PageTitle";
import Listing from "src/Pages/Company/Pending/BenefitsMultiSportPending/Listing";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import __ from "src/utils/Translations";

export default () => {
  const companyName = useCompanyName();
  const companyId = useCompanyId();
  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={`${__(
          "Benefity oczekujące - MultiSport dla firmy"
        )} ${companyName} (ID: ${companyId})`}
        breadcrumbs={[]}
        pushToHistory
      />
      <Listing companyId={companyId} />
    </CSSTransitionGroup>
  );
};
