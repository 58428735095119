import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Form from "src/Components/Form";
import ContentLoading from "src/Components/Loading/contentLoading";
import Popup from "src/Components/Popup/popup";
import Spinner from "src/Components/Spinner";

export default function FinancingPopup({ close, isOpen, data, employeeId }) {
  const [isEdited, setIsEdited] = useState(false);
  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      setIsEdited(true);
    },
    [data]
  );

  return (
    <Popup
      id="financingEditPopup"
      isOpen={isOpen}
      toggle={() => close()}
      unmountOnClose
      size="lg"
      isEdited={isEdited}
    >
      <ContentLoading message={Spinner} show={false}>
        <Form
          id="financingEditForm"
          data={data}
          config={{
            defaultOnChange: onChange,
            stickyTitle: true,
            isInPopup: true,
            title: "Dofinansowanie kolonii",
            groupsAsColumns: true,
            onSubmit: () => {
              setTimeout(() => {
                close();
              }, 500);
            },
            buttons: [
              {
                size: "lg",
                color: "light",
                onClick: () => {
                  close();
                },
                className: "mr-2",
                text: "Wróć",
              },
              {
                size: "lg",
                color: "primary",
                className: "mr-2",
                text: "Przydziel",
                type: "submit",
              },
            ],
            formGroups: [
              {
                formElements: [
                  {
                    component: <p>Pracownik:</p>,
                  },
                  {
                    component: <p>Komentarz:</p>,
                  },
                  {
                    component: <p>Kwota dofinansowania:</p>,
                  },
                  {
                    component: <p>Źródło:</p>,
                  },
                ],
              },
              {
                formElements: [
                  {
                    component: (
                      <p>
                        Katarzyna Jóźwiak
                        {employeeId}
                      </p>
                    ),
                  },
                  {
                    component: <p>{data.comment ? data.comment : 10}</p>,
                  },
                  {
                    component: <p>{data.amount ? data.amount : 10}</p>,
                  },
                  {
                    component: <p>old-Ogólny ZFŚS</p>,
                  },
                ],
              },
            ],
          }}
        />
      </ContentLoading>
    </Popup>
  );
}

FinancingPopup.propTypes = {
  close: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      amount: PropTypes.string,
    })
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
};
