import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import PopupComponent from "src/Components/Popup/popup";

export default function ItemPopup({ item, close }) {
  return (
    <>
      <PopupComponent
        id="userAgreementContent"
        isOpen
        toggle={close}
        unmountOnClose
        size="lg"
      >
        <ModalHeader toggle={close}>Treść zgody:</ModalHeader>
        <ModalBody
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        />
        <ModalFooter>
          <Button color="primary" onClick={close}>
            Zamknij
          </Button>
        </ModalFooter>
      </PopupComponent>
    </>
  );
}
ItemPopup.propTypes = {
  close: PropTypes.func.isRequired,
  item: PropTypes.shape({}).isRequired,
};
