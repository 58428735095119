import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import uk from "date-fns/locale/uk";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import {
  COMPANY_MANAGEMENT_SERVICE,
  restApiRequest,
  TRANSLATOR_SERVICE,
} from "src/utils/Api";
import { INTERFACE_CODE } from "src/utils/Translations/translationUtils";
import DefaultFallback from "src/Layout/AppMain/DefaultFallback";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import LanguageContext, { setLanguage, getLanguage } from "./LanguageContext";

export const mockLanguages = [
  {
    code: "pl",
    label: "polski",
    isDefault: true,
    shortLabel: "pl",
  },
  {
    code: "en",
    label: "angielski",
    isDefault: false,
    shortLabel: "en",
  },
  // {
  //   code: 'uk',
  //   label: 'ukraiński',
  //   isDefault: false,
  //   shortLabel: 'UK',
  // },
];

export const getLanguagesConfig = async () => {
  try {
    return await restApiRequest(
      TRANSLATOR_SERVICE,
      "/languages",
      "GET",
      {},
      mockLanguages
    );
  } catch (e) {
    newRelicErrorReport(e, "utils/Languages/languageWrapper.js - 49");
    return [...mockLanguages];
  }
};
export const getCompanyLanguages = async (companyId) => {
  try {
    const response = await restApiRequest(
      COMPANY_MANAGEMENT_SERVICE,
      `/companies/${companyId}`,
      "GET",
      {},
      []
    );
    return response?.availableLanguages || [];
  } catch (e) {
    newRelicErrorReport(e, "utils/Languages/languageWrapper.js - 49");
    return [];
  }
};

export const loadInterfaceTranslation = async (language) => {
  try {
    await loadPreFetchedInterfaceTranslation(language);
    if (window.translations) {
      return;
    }
    const response = await restApiRequest(
      TRANSLATOR_SERVICE,
      "/get-by-scope",
      "GET",
      {
        params: {
          language,
          value: [INTERFACE_CODE],
        },
      },
      "{}"
    );
    window.translations = JSON.parse(response);
    window.translations.isInitialized = true;
  } catch (e) {
    newRelicErrorReport(e, "utils/Languages/languageWrapper.js - 49");
    console.error(e);
  }
};

export const loadPreFetchedInterfaceTranslation = async (language) => {
  try {
    window.translations = await fetch(`/translations/${language}.json`).then(
      (res) => {
        if (!res.ok) {
          return null;
        }
        return res.json();
      }
    );
  } catch (e) {
    newRelicErrorReport(e, "utils/Languages/languageWrapper.js - 49");
    console.error(e);
    window.translations = null;
  }
};

const init = async (
  setTranslationsLoaded,
  setLanguagesConfig,
  setDefaultLanguage,
  userInfo
) => {
  const config = {};
  config.allSystemLanguages = await getLanguagesConfig();
  if (userInfo.isAhr()) {
    config.companyLanguages = await getCompanyLanguages(
      userInfo.getCompanyId()
    );
  }
  const { allSystemLanguages, companyLanguages = null } = config;
  const languageConfig = userInfo.isAhr()
    ? allSystemLanguages.filter(
        (el) => companyLanguages.includes(el.code) || el.isDefault
      )
    : allSystemLanguages;

  const defaultLanguage = languageConfig.find(({ isDefault }) => isDefault);
  let language = getLanguage();

  if (!languageConfig.find(({ code }) => code === language)) {
    language = defaultLanguage.code;
    setLanguage(language);
  }
  registerGlobalLocale(language);
  if (defaultLanguage.code !== language) {
    await loadInterfaceTranslation(language);
  } else if (window.translations) {
    window.translations.isInitialized = true;
  }
  setDefaultLanguage(defaultLanguage);

  setLanguagesConfig(languageConfig);
  setTranslationsLoaded(true);
};

export default function LanguageWrapper({ children }) {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const [languagesConfig, setLanguagesConfig] = useState({});
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const { userInfo } = useContext(RbsContext);

  useEffect(() => {
    init(
      setTranslationsLoaded,
      setLanguagesConfig,
      setDefaultLanguage,
      userInfo
    );
  }, [setTranslationsLoaded, setLanguagesConfig, setDefaultLanguage, userInfo]);

  if (!translationsLoaded) {
    return DefaultFallback;
  }
  return (
    <LanguageContext.Provider
      value={{
        languagesConfig,
        defaultLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

const registerGlobalLocale = (languageCode) => {
  const locale = languageToLocaleMap[languageCode] || pl;
  registerLocale(languageCode, locale); // register it with the name you want
  setDefaultLocale(languageCode);
};

const languageToLocaleMap = {
  pl,
  en,
  uk,
};

LanguageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
