import __ from "src/utils/Translations";
import React from "react";

export const getChangeEmployeeGroupPopupMessage = (
  automaticReplaceBenefitAfterChangeGP,
  enableConfigurationRentableAndOrganization
) => {
  if (
    automaticReplaceBenefitAfterChangeGP &&
    !enableConfigurationRentableAndOrganization
  ) {
    return (
      <>
        <p>
          {__(
            "Jeśli użytkownik ma wybrane abonamenty, zmiana grupy pracowniczej spowoduje przepięcie abonamentów z 1 dniem kolejnego miesiąca, "
          )}

          {__(
            "jeśli konfiguracja na to pozwala lub rezygnację z aktywnych świadczeń abonamentowych pracownika oraz anulację świadczeń oczekujących, jeśli konfiguracja uniemożliwia przepięcie abonamentów. "
          )}

          {__(
            "Rezygnacja ma skutek na ostatni dzień bieżącego miesiąca, jeśli użytkownik posiada świadczenia zrezygnowane z datą przyszłą - zostanie ona zmieniona na ostatni dzień bieżącego miesiąca. "
          )}

          {__(
            "Jeżeli dokonasz takiej zmiany, poinformuj pracownika o konieczności ponownego wyboru lub zleć import abonamentów we właściwej grupie pracowniczej. "
          )}
        </p>
        <p>{__("Czy chcesz dokonać zmiany?")}</p>
      </>
    );
  }
  if (
    !automaticReplaceBenefitAfterChangeGP &&
    enableConfigurationRentableAndOrganization
  ) {
    return (
      <>
        <p>
          {__(
            "Zmiana grupy pracowniczej, grupy dochodowości lub jednostki organizacyjnej może spowodować rezygnację z aktywnych świadczeń abonamentowych pracownika oraz anulację świadczeń oczekujących. "
          )}

          {__(
            "Rezygnacja ma skutek na ostatni dzień bieżącego miesiąca, jeśli użytkownik posiada świadczenia zrezygnowane z datą przyszłą - zostanie ona zmieniona na ostatni dzień bieżącego miesiąca. "
          )}

          {__(
            "Jeżeli dokonasz takiej zmiany, poinformuj pracownika o konieczności ponownego wyboru lub zleć import abonamentów we właściwej grupie"
          )}
        </p>
      </>
    );
  }
  if (
    automaticReplaceBenefitAfterChangeGP &&
    enableConfigurationRentableAndOrganization
  ) {
    return (
      <>
        <p>
          {__(
            "Jeśli użytkownik ma wybrane abonamenty, zmiana grupy pracowniczej, grupy dochodowości lub jednostki organizacyjnej może spowodować przepięcie abonamentów z 1 dniem kolejnego miesiąca, "
          )}
          {__(
            "jeśli konfiguracja na to pozwala lub rezygnację z aktywnych świadczeń abonamentowych pracownika oraz anulację świadczeń oczekujących, jeśli konfiguracja uniemożliwia przepięcie abonamentów. "
          )}
          {__(
            "Rezygnacja ma skutek na ostatni dzień bieżącego miesiąca, jeśli użytkownik posiada świadczenia zrezygnowane z datą przyszłą - zostanie ona zmieniona na ostatni dzień bieżącego miesiąca. "
          )}
          {__(
            "Jeżeli dokonasz takiej zmiany, poinformuj pracownika o konieczności ponownego wyboru lub zleć import abonamentów we właściwej grupie. "
          )}
        </p>
        <p>{__("Czy chcesz dokonać zmiany?")}</p>
      </>
    );
  }
  return (
    <>
      <p>
        {__(
          "Zmiana grupy pracowniczej spowoduje rezygnację z aktywnych świadczeń "
        )}
        {__("abonamentowych pracownika oraz anulację świadczeń oczekujących. ")}
        {__(
          "Rezygnacja ma skutek na ostatni dzień bieżącego miesiąca, jeśli użytkownik "
        )}
        {__(
          "posiada świadczenia zrezygnowane z datą przyszłą - zostanie ona zmieniona na ostatni dzień bieżącego miesiąca. "
        )}
        {__(
          "Jeżeli dokonasz takiej zmiany, poinformuj pracownika o konieczności ponownego wyboru lub "
        )}
        {__(
          "zaimportuj pracownikowi abonamenty we właściwej grupie pracowniczej."
        )}
      </p>
    </>
  );
};

export const getChangeEmployeeGroupPopupTitle = (
  automaticReplaceBenefitAfterChangeGP,
  enableConfigurationRentableAndOrganization
) => {
  if (
    automaticReplaceBenefitAfterChangeGP &&
    !enableConfigurationRentableAndOrganization
  ) {
    return __(
      "Uwaga! Zmiana grupy pracowniczej spowoduje przepięcie lub rezygnację z wybranych przez pracownika abonamentów!"
    );
  }
  if (
    automaticReplaceBenefitAfterChangeGP &&
    enableConfigurationRentableAndOrganization
  ) {
    return __(
      "Uwaga! Zmiana może spowodować rezygnację lub przepięcie wybranych przez pracownika abonamentów!"
    );
  }
  if (!automaticReplaceBenefitAfterChangeGP) {
    return __(
      "Uwaga! Zmiana może spowodować rezygnację z wybranych przez pracownika abonamentów"
    );
  }
  return __(
    "Uwaga! Zmiana grupy pracowniczej spowoduje rezygnację z wybranych przez pracownika abonamentów!"
  );
};

export const importEmployeeGroupPopupTitle =
  "Uwaga! Zmiana grupy pracowniczej/grupy dochodowości/jednostki organizacyjnej może spowodować przepięcie lub rezygnację z wybranych przez pracownika abonamentów!";

export const importEmployeeGroupPopupTitleWithoutConfig =
  "Uwaga! Zmiana grupy pracowniczej spowoduje przepięcie lub rezygnację z wybranych przez pracownika abonamentów!";
