import React, { useState } from "react";
import { Button } from "reactstrap";
import { Loader } from "react-loaders";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";

export default function GoogleTranslate({ column, original: data }) {
  const [loading, setLoading] = useState(false);
  const { fetchGoogleSuggestion, setFromSuggestion } = column;
  const getSuggestion = async () => {
    setLoading(true);
    await fetchGoogleSuggestion(data.id);
    setLoading(false);
  };

  const value = data[column.id];
  if (value !== undefined) {
    return (
      <div>
        <div className="d-block w-100 text-center">{value}</div>
        <div>
          <Button onClick={() => setFromSuggestion(data.id)} color="link">
            {__("Użyj")}
          </Button>
        </div>
      </div>
    );
  }
  if (loading) {
    return (
      <Loader active type="line-scale" style={{ transform: "scale(0.6)" }} />
    );
  }
  return (
    <Button onClick={getSuggestion} color="link">
      {__("Pobierz")}
    </Button>
  );
}
GoogleTranslate.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string,
    fetchGoogleSuggestion: PropTypes.func,
    setFromSuggestion: PropTypes.func,
  }).isRequired,
  original: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};
