export const STATUS_NEW = "new";
export const STATUS_ACTIVE = "active";
export const STATUS_CREATED = "created";
export const STATUS_CANCELED = "canceled";
export const STATUS_RESIGNED = "resigned";

export const statusesOptions = [
  { value: STATUS_ACTIVE, label: "Aktywna" },
  { value: STATUS_NEW, label: "Nowa" },
  { value: STATUS_CREATED, label: "Stworzona" },
  { value: STATUS_CANCELED, label: "Anulowana" },
  { value: STATUS_RESIGNED, label: "Zrezygnowana" },
];
