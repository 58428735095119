import { useEffect } from "react";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { godPermission } from "src/utils/RoleBasedSecurity/permissions";

const { useState, useContext } = require("react");

const useHasPermission = (aclKey) => {
  const { userInfo } = useContext(RbsContext);
  const [result, setResult] = useState(null);
  useEffect(() => {
    const permissions = userInfo.getPermissions();
    setResult(
      Boolean(
        permissions.includes(godPermission) || permissions.includes(aclKey)
      )
    );
  }, [userInfo, aclKey]);

  return result;
};

export default useHasPermission;
