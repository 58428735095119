import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
// eslint-disable-next-line import/no-cycle
import Form from "src/Components/Form";
import { LAYOUT_TWO_COLUMNS } from "src/Components/Layouts";
import { restApiRequest, TRANSLATOR_SERVICE } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { useLanguages } from "src/utils/Languages/LanguageContext";
import { translationTranslatePermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import Popup from "src/Components/Popup/popup";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import LanguagesTabs from "./LanguageTabs";

export default function DynamicCmsTranslationPopup({
  close,
  code,
  value,
  translatedValue,
  scope,
  isTitle,
  label,
  uploadService,
  type,
  isNotDefault,
  onTranslatorSubmit,
  allowDirtyHtml,
}) {
  const languages = useLanguages();
  const [data, updateData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currLanguage, setCurrLanguage] = useState("en");

  const onChange = useCallback(
    (key, newValue) => {
      const updatedData = [...data];
      const langData = updatedData.find((el) => el.language === currLanguage);
      if (langData) {
        langData[key] = newValue;
        updateData(updatedData);
      }
    },
    [data, currLanguage]
  );
  useEffect(() => {
    if (languages.length) {
      fetchTranslationData();
    }
    // eslint-disable-next-line
  }, [languages]);
  const fetchTranslationData = async () => {
    const result = await restApiRequest(
      TRANSLATOR_SERVICE,
      "/cms-translations",
      "GET",
      {
        params: {
          code,
          itemsPerPage: 10000,
          "order[id]": "desc",
        },
      },
      mockSimpleValues
    );
    const formData = languages.map(({ code: langCode }) => {
      const isTourismObjects = code.includes("tourism:tourismObject");
      let savedItem;

      if (isTourismObjects) {
        const tourismItem =
          result.find(
            ({ language, code = "" }) =>
              language === langCode && code?.split(":")?.includes("description")
          ) || {};
        const tourismAnixeItem =
          result.find(
            ({ language, code = "" }) =>
              language === langCode &&
              code?.split(":")?.includes("descriptionAnixe")
          ) || {};

        savedItem = tourismItem.translation ? tourismItem : tourismAnixeItem;
      } else {
        savedItem = result.find(({ language }) => language === langCode) || {};
      }

      return {
        translation: translatedValue,
        title: "",
        ...savedItem,
        code,
        scope: scope || "cms",
        language: langCode,
        phrase: value,
      };
    });
    updateData(formData);
    setLoading(false);
  };

  const save = async () => {
    if (onTranslatorSubmit) {
      onTranslatorSubmit(data);
      close();
      return;
    }
    try {
      setLoading(true);
      await restApiRequest(
        TRANSLATOR_SERVICE,
        "/cms/mass-save",
        "POST",
        {
          body: {
            data,
          },
        },
        null
      );
      close();
    } catch (e) {
      dynamicNotification(
        e.message || __("Nie udało się zapisać tłumaczeń"),
        "error"
      );
      newRelicErrorReport(
        e,
        "Components/DynamicTranslation/DynamicCmsTranslationPopup.js - 106"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <Popup
        id="translationCmsFormPopup"
        isOpen
        toggle={() => close()}
        unmountOnClose
        size="lg"
        className="modal-xxl"
      >
        <Form
          id="translationCmsForm"
          data={data.find((el) => el.language === currLanguage)}
          config={{
            defaultOnChange: onChange,
            isInPopup: true,
            buttons: [
              {
                color: "light",
                text: "Zamknij",
                onClick: close,
              },
              {
                text: "Zapisz",
                onClick: save,
                permission: translationTranslatePermissionWrite,
                disabled: loading || !isNotDefault,
              },
            ],
            togglePopup: close,
            title: `${__("Edycja tłumaczenia pola")} ${label} (${code})`,
            translateTitle: false,
            formGroups: [
              {
                formElements: [
                  {
                    component: (
                      <LanguagesTabs
                        key="languages_tab"
                        setCurrLanguage={setCurrLanguage}
                        currLanguage={currLanguage}
                      />
                    ),
                  },
                  {
                    layout: LAYOUT_TWO_COLUMNS,
                    formElements: [
                      {
                        id: "phrase",
                        label: "Oryginalna wartość",
                        type,
                        props: {
                          height: 400,
                          disabled: true,
                        },
                      },
                      {
                        id: isTitle ? "title" : "translation",
                        label: "Tłumaczenie",
                        type,
                        uploadService,
                        allowDirtyHTML: allowDirtyHtml,
                        props: {
                          height: 400,
                          disabled: !isNotDefault,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      </Popup>
    </>
  );
}

DynamicCmsTranslationPopup.propTypes = {
  close: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  scope: PropTypes.string,
  type: PropTypes.string.isRequired,
  uploadService: PropTypes.string,
  value: PropTypes.string.isRequired,
  translatedValue: PropTypes.string,
  isTitle: PropTypes.bool.isRequired,
  label: PropTypes.string,
  isNotDefault: PropTypes.bool,
  onTranslatorSubmit: PropTypes.func,
  allowDirtyHtml: PropTypes.bool,
};

DynamicCmsTranslationPopup.defaultProps = {
  scope: "",
  translatedValue: "",
  label: "",
  uploadService: null,
  isNotDefault: true,
  onTranslatorSubmit: null,
  allowDirtyHtml: false,
};

const mockSimpleValues = [
  {
    id: 4,
    scope: "company_company_city",
    code: "company_company_city_6c76933666a8",
    language: "en",
    type: 2,
  },
  {
    id: 5,
    scope: "company_company_city",
    code: "company_company_city_6c76933666a8",
    language: "UA",
    type: 2,
  },
  {
    id: 6,
    scope: "company_company_city",
    code: "company_company_city_6c76933666a8",
    language: "FR",
    type: 2,
  },
];
