import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import ContentLoading from "src/Components/Loading/contentLoading";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { restApiRequest, API_GATEWAY_SERVICE } from "src/utils/Api";
import MassAction from "./MassAction";
import SelectedProvider from "./Table/SelectedProvider";
import { prepareParams } from "./utils";
import Filters from "./Filters";
import Table from "./Table";

export default function PendingBenefitsMultiSport({ companyId }) {
  const [data, setData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const tableApiRef = useRef(null);

  const fetchWaitingRoomData = useCallback(
    async (currentPage, pageSize) => {
      setIsLoading(true);
      try {
        const params = prepareParams(currentPage, pageSize, filters);
        const response = await restApiRequest(
          API_GATEWAY_SERVICE,
          `/waiting-room/company/${companyId}/waiting-rooms`,
          "GET",
          {
            params,
          },
          []
        );
        const { content, totalElements } = response;
        setData(content);
        setTotalElements(totalElements);
      } catch (e) {
        setData([]);
        console.error(e);
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy benefitów oczekujacych."),
          "error"
        );
      }
      setIsLoading(false);
    },
    [companyId, filters]
  );

  const fetchData = useCallback(
    (_filters, currentPage, pageSize) => {
      fetchWaitingRoomData(currentPage, pageSize);
    },
    [fetchWaitingRoomData]
  );

  const onFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const onRemoveItem = () => {
    tableApiRef.current.onRemoveItem();
  };

  const onReset = () => {
    setFilters({});
    setTimeout(() => {
      tableApiRef.current.reset();
    }, 0);
  };

  const onSubmit = () => {
    tableApiRef.current.refetch();
  };

  return (
    <SelectedProvider data={data}>
      <Filters
        companyId={companyId}
        filters={filters}
        onFiltersChange={onFiltersChange}
        onReset={onReset}
        onSubmit={onSubmit}
      />
      <ContentLoading show={isLoading}>
        <MassAction
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onSuccess={onRemoveItem}
        />
        <Table
          apiRef={tableApiRef}
          fetchData={fetchData}
          subscriptionsData={data}
          totalElements={totalElements}
          onRemoveItem={onRemoveItem}
        />
      </ContentLoading>
    </SelectedProvider>
  );
}

PendingBenefitsMultiSport.propTypes = {
  companyId: PropTypes.string.isRequired,
};
