export const statusOptions = [
  { value: "paid", label: "Zapłacona" },
  { value: "to_cancel", label: "Oznaczona do anulowania" },
  { value: "canceled", label: "Anulowana" },
];

export const typeOptions = [
  { value: "CHARGE", label: "Zasilenie karty" },
  { value: "ISSUANCE_FEE", label: "Opłata za wydanie karty" },
  { value: "TAX", label: "Prowizja za zasilenie karty" },
];

export const payerOptions = [
  { value: "COMPANY", label: "Pracodawca" },
  { value: "EMPLOYEE", label: "Pracownik" },
];
