import { CompanySubMenu } from "src/Layout/AppNav/SubMenuItems";

const getSubMenuComponentByCode = (code) => {
  switch (code) {
    case CompanySubMenu.code:
      return CompanySubMenu;
    default:
      return null;
  }
};

export default getSubMenuComponentByCode;
