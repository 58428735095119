/* eslint import/no-cycle:0 */
import { setIsMockView } from "src/utils/Api";
import { USER_INFO_STORAGE_KEY } from "./Session";
import { godPermission } from "./permissions";

export const MOCK_ADMIN_ACCESS_TOKEN =
  "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJuSzlZYUF3SGplMG4wMlFsemp6SzcwNFJKN0FJQ25DS1pSdW04cnIwdk8wIn0.eyJleHAiOjE3MTA1MDEyODYsImlhdCI6MTcxMDUwMDM4NiwiYXV0aF90aW1lIjoxNzEwNTAwMzgwLCJqdGkiOiJkNjA1NWE3OS0wZDk5LTRkNjAtYTM0ZC1hN2NiNmFiNzUzMzMiLCJpc3MiOiJodHRwczovL2tleWNsb2FrLnFhMS5zdGFnaW5nLmF3cy5teWJlbmVmaXQucGwvYXV0aC9yZWFsbXMvY2FmZXRlcmlhX3N5c3RlbSIsImF1ZCI6WyJhMjA5YWJkZDllMjlhN2M2YWIwMWJhMGMwYTBlZTFlYyIsImFjY291bnQiXSwic3ViIjoiNTdjNTUwZmUtMTZkNy00YjhmLWJjYTQtN2Q3ZDY1MTlhNmE3IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiZGNjMmRjYTg5YmFmZGMxOWZhYWY4MzRmM2RiN2RiMzQiLCJub25jZSI6ImI2OGRjZDBmNzliYzMyNTMyOTQ2MGJkMzU4ZDY4MjQ5Iiwic2Vzc2lvbl9zdGF0ZSI6IjdlZjYxZTIwLWY2MDktNDRjMS1iYjY2LWZjYmI2M2RlZWY3OCIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJPTUIiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYTIwOWFiZGQ5ZTI5YTdjNmFiMDFiYTBjMGEwZWUxZWMiOnsicm9sZXMiOlsidG9rZW5fZXhjaGFuZ2UiXX0sImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIHByaXZhdGUgZW1haWwgcHJvZmlsZSBjb21wYW55SWQgTXlCX1NTT19VSUQiLCJzaWQiOiI3ZWY2MWUyMC1mNjA5LTQ0YzEtYmI2Ni1mY2JiNjNkZWVmNzgiLCJjb21wYW55SWQiOiJvbWIiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInJvbGUiOiJvbWIiLCJNeUJfU1NPX1VJRCI6IjJkOTkwODQ1LWU4ZGQtNDg2NC1hYzRlLWU2NGRjNWQxMWNhMiIsIm5pY2tuYW1lIjoidWx5YW5hLm9tYjpvbWIiLCJuYW1lIjoiVWx5YW5hIEJ5Y2hrb3Zza2F5YSIsImxhc3RMb2dpbkRhdGUiOiIyMDI0LTAzLTE1VDExOjU5OjQwLjg4Mjc4OCIsInByZWZlcnJlZF91c2VybmFtZSI6InVseWFuYS5vbWI6b21iIiwiZ2l2ZW5fbmFtZSI6IlVseWFuYSIsImZhbWlseV9uYW1lIjoiQnljaGtvdnNrYXlhIn0.Ox5-iGK9gUq3PodXAI4D8PUjYISGtZ0oyGmojHQvKdBPsUUPDAtuJ1mX5obVYI2iNAFtYrSjiGjm1fqfixwxEnqEM7rphjrKOCAA3YkUSQRowblXwj2AokXHGfk8vSGVh1suPyObxfYhR0yQrFK1hJqj7WTq8dXh65MzsoUptvZGjWl3j5JvSOmMdv7SwVZ0_8aNzCdFMrxiJ05t5vMlO-9NGmyMvMU1BesJuilXVw1vHNx7wbpnkXp3eMdHoXvKrKWPtq78flvwR_Of4yvskJR9o7XlsLXLHCdJaevQssyavMbvPQNOLHKVU3w44e6wU5xxVq74oJ5Ztxx9Y7eBEA";

const mockData = {
  token: MOCK_ADMIN_ACCESS_TOKEN,
  refresh_token: "qwerty",
  date: Date.now(),
  expiresIn: 3600,
};

export default function tmpAuthorization(type) {
  let isMock = false;
  let companyId = 0;
  let role;
  let lastName;

  switch (type) {
    case "omb":
      role = "omb";
      lastName = "mocks";
      isMock = true;
      break;
    case "ahr":
      role = "ahr";
      lastName = "ahr mocks";
      isMock = true;
      companyId = "a43275e4-eeb2-11ea-adc1-0242ac1200021";
      break;
    case "admin":
      role = "omb";
      lastName = "admin";
      break;
    case "adminahr":
      role = "ahr";
      lastName = "admin ahr";
      companyId = "18be83b7-79e7-4526-8752-0054419d8da9";
      break;
    default:
      return false;
  }
  setIsMockView(isMock);
  const userInfo = {
    id: "dev",
    role,
    companyId,
    firstName: "dev",
    lastName,
    email: "test@example.com",
    permissions: [godPermission],
    relations: {
      company: companyId,
    },
    username: type,
  };

  window.localStorage.setItem(USER_INFO_STORAGE_KEY, JSON.stringify(userInfo));
  return {
    ...mockData,
    userInfo,
  };
}
