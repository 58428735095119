import React, { useEffect } from "react";
import { Label } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import DateTimePicker from "src/Components/FormElements/DateTimePicker";
import { subscribeFrequencyModalMock } from "src/Pages/Report/NewReport/ReportsList/Report/Subscription/mockData";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import FreqDescription from "./freqDescritpion";

const Annually = ({
  subscribeFrequency,
  setSubscribeFrequency,
  subscribeFrequencyInfo,
  setsubscribeFrequencyInfo,
}) => {
  const { sendDate, sendTime } = subscribeFrequencyInfo;

  const sendStartDateTime = {
    date: sendDate ?? new Date().toISOString().slice(0, 10),
    time: sendTime ?? "10:00",
  };
  const { date, time } = sendStartDateTime;

  const occurrence = `co roku w dniu ${date.substr(
    8,
    date.length
  )}.${date.substr(5, 2)}`;

  useEffect(() => {
    const subInfo1 = {
      ...subscribeFrequencyInfo,
      occurrence,
      sendDate: date,
      sendTime: time,
    };
    setsubscribeFrequencyInfo(subInfo1);
  }, []); // eslint-disable-line

  const handleDateTimePickerChange = (data) => {
    const selectedDate = new Date(data.date).setHours(
      data.time.substr(0, 2),
      data.time.substr(3, 5)
    );
    const d = data.date;
    if (selectedDate < new Date()) {
      dynamicNotification(__("Nie można wybrać daty wstecz"), "error");
    } else {
      const occurr = `co roku w dniu ${d.substr(8, date.length)}.${d.substr(
        5,
        2
      )}`;
      const subInfo = {
        ...subscribeFrequencyInfo,
        sendDate: data.date,
        sendTime: data.time,
        occurrence: occurr,
      };
      setsubscribeFrequencyInfo(subInfo);
    }
  };

  const deliveryTime = new Date(Date.UTC(2000, 1, 1, 8, 0, 0));
  const deliveryEndTime = new Date(Date.UTC(2000, 1, 1, 17, 0, 0));
  const timezone = deliveryTime.getTimezoneOffset() / -60;
  subscribeFrequencyModalMock.forEach((d) => {
    // eslint-disable-next-line no-param-reassign
    d.label = __(d.label);
  });
  const selectedsubscribeMethod = {
    label: __(subscribeFrequency.label),
    value: subscribeFrequency.value,
    isSelected: subscribeFrequency.isSelected,
  };
  return (
    <>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3">
            <Label>{__("Powtarzaj")}</Label>
          </div>
          <div className="col-md-6 freq-select">
            <Select
              name="frequencySelect"
              className="basic-select"
              classNamePrefix="select"
              options={subscribeFrequencyModalMock}
              onChange={setSubscribeFrequency}
              value={selectedsubscribeMethod}
            />
          </div>
        </div>
      </div>
      <br />
      <DateTimePicker
        label={__("Data wysyłki")}
        value={sendStartDateTime}
        onChange={handleDateTimePickerChange}
        validateField={() => {}}
      />
      <FreqDescription info={occurrence} />
      <text>
        {__("Raport będzie wysyłany między godziną")}{" "}
        {`${deliveryTime.toTimeString().substr(0, 5)}`}
        {" a "}
        {`${deliveryEndTime.toTimeString().substr(0, 5)}`}
        {` GMT${timezone > 0 ? "+" : ""}${timezone}`}
      </text>
    </>
  );
};

Annually.propTypes = {
  subscribeFrequency: PropTypes.object.isRequired,
  setSubscribeFrequency: PropTypes.func.isRequired,
  subscribeFrequencyInfo: PropTypes.object.isRequired,
  setsubscribeFrequencyInfo: PropTypes.func.isRequired,
};
export default Annually;
