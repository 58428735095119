import { TYPE_EDIT, TYPE_LISTING } from "src/utils/browsingHistory";

const getColorByType = (type) => {
  switch (type) {
    case TYPE_LISTING:
      return "text-primary";
    case TYPE_EDIT:
    default:
      return "text-danger";
  }
};

export default getColorByType;
