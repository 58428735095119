export const booleanOptions = [
  {
    value: false,
    label: "Nie",
  },
  {
    value: true,
    label: "Tak",
  },
];

export const objectStatusOptions = [
  {
    value: "active",
    label: "Aktywny",
  },
  {
    value: "inactive",
    label: "Nieaktywny",
  },
  {
    value: "new",
    label: "Nowy",
  },
];

export const activeBooleanOptions = [
  {
    value: true,
    label: "Aktywna",
  },
  {
    value: false,
    label: "Nieaktywna",
  },
];
