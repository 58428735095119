import moment from "moment-timezone";

export const getUTCDateAndTime = (date) => {
  if (!isDateValid(date)) {
    return [];
  }

  const [utcDate, utcTimeAndZone] = new Date(date).toISOString().split("T");
  const utcTime = utcTimeAndZone.split(".")[0];
  return [utcDate, utcTime];
};

export const getWarsawDate = (utcDate) => {
  if (!utcDate) {
    return [];
  }

  const newDate = new Date(utcDate);

  if (!isDateValid(newDate)) {
    return [];
  }

  const [date = "", time = ""] = moment(newDate)
    .tz("Europe/Warsaw")
    .format("YYYY-MM-DD, HH:mm:ss")
    ?.split(", ");
  const [yyyy, mm, dd] = date.split("-");

  return [new Date(yyyy, mm - 1, dd), time, new Date(`${date} ${time}`)];
};

export const getLocalDate = (localDate, withTime) => {
  if (!localDate) return null;
  return moment(new Date(localDate)).format(
    withTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
  );
};

export const isDateValid = (date) =>
  date && !Number.isNaN(new Date(date).getTime());
