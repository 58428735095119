import { useCallback, useEffect, useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const apiCache = {};

export const getUsersOptionsFetchMethod =
  (companyId, filterValue, itemsPerPage = 10000, timeLimit = 3600000) =>
  async (search) => {
    const params = {
      tenant: companyId,
      itemsPerPage,
    };
    if (search) {
      params.firstName = search;
      params.lastName = search;
      params.fk = search;
    } else if (filterValue) {
      params.id = filterValue;
    }
    let result = [];
    const key = `users|${JSON.stringify(params)}`;
    if (apiCache[key] && apiCache[key].timestamp + timeLimit > Date.now()) {
      result = apiCache[key].data || [];
    } else {
      result = await restApiRequest(
        API_TOTALREWARD_SERVICE,
        "/omb/users",
        "GET",
        {
          params,
        },
        mockData
      );
      apiCache[key] = { data: result, timestamp: Date.now() };
    }
    return (
      result
        ?.filter((c) => !filterValue || c.id === filterValue)
        .map((c) => ({
          value: c.id,
          label: `${c.lastName} ${c.firstName} (${c.fk || ""})`,
        })) || []
    );
  };

export default function (companyId, enabled) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await restApiRequest(
        API_TOTALREWARD_SERVICE,
        "/omb/users",
        "GET",
        {
          params: {
            tenant: companyId,
          },
        },
        mockData
      );
      setData(result || []);
      setIsSuccess(true);
    } catch (e) {
      newRelicErrorReport(
        e,
        "/src/Pages/Company/TotalReward/Settings/useQueryTotalRewardUsers.js - 14"
      );
      dynamicNotification(
        e.message || __("Nie udało się pobrać danych"),
        "error"
      );
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    data,
    options:
      data?.map((c) => ({
        value: c.id,
        label: `${c.lastName} ${c.firstName} (${c.fk || ""})`,
      })) || [],
    isLoading,
    isSuccess,
    refetch: fetchData,
  };
}

const mockData = [
  {
    fk: "5779",
    id: "004bfa47-dc81-4619-aa18-288d130db14e",
    firstName: "Jan",
    lastName: "Kowalski",
  },
];
