import PropTypes from "prop-types";
import React from "react";

import { InputGroup, FormGroup, Row } from "reactstrap";

import DatePicker from "react-datepicker";
import hashInputId from "src/utils/jsHelpers/hashInputId";
import __ from "src/utils/Translations";

export default function FilterDateRange({
  value,
  onChange,
  id,
  showTimeSelect,
  onlyMonths,
  withClearButton,
}) {
  const inputValue = value;

  const handleChange = ({ from, to }) => {
    const fromValue = from === undefined ? inputValue.from : from;
    let toValue = to === undefined ? inputValue.to : to;

    if (toValue && fromValue > toValue) {
      toValue = fromValue;
    }
    onChange({
      from: fromValue,
      to: toValue,
    });
  };

  const handleChangeStart = (from) => handleChange({ from });
  const handleChangeEnd = (to) => {
    let newValue = to;
    if (!showTimeSelect && newValue) {
      newValue = new Date(newValue);
      newValue.setHours(23);
      newValue.setMinutes(59);
      newValue.setSeconds(59);
    }
    return handleChange({ to: newValue });
  };

  const handleClearButton = () => handleChange({ from: "", to: "" });

  const from = value && value.from ? new Date(value.from) : null;
  const to = value && value.to ? new Date(value.to) : null;

  let format;
  if (onlyMonths) {
    format = "MM/yyyy";
  } else if (showTimeSelect) {
    format = "dd/MM/yyyy HH:mm aa";
  } else {
    format = "dd/MM/yyyy";
  }

  return (
    <>
      <Row form>
        <div style={{ width: "47.5%" }}>
          <FormGroup>
            <InputGroup>
              <div
                className={`m-0 p-0 ${
                  showTimeSelect || onlyMonths
                    ? "datetime-picker-wrapper"
                    : "date-picker-wrapper"
                }`}
                data-t1="gridFilter"
                data-t2={`${id}From`}
              >
                <DatePicker
                  id={`${hashInputId(id)}_from`}
                  popperPlacement="auto"
                  popperClassName="some-custom-class"
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                  selected={from}
                  placeholderText={__("od")}
                  selectsStart
                  showTimeSelect={showTimeSelect}
                  className="form-control"
                  startDate={from}
                  endDate={to}
                  showMonthYearPicker={onlyMonths}
                  showFullMonthYearPicker={onlyMonths}
                  showFourColumnMonthYearPicker={onlyMonths}
                  onChange={handleChangeStart}
                  dateFormat={format}
                />
              </div>
            </InputGroup>
          </FormGroup>
        </div>
        <p style={{ width: "5%" }}>-</p>
        <div style={{ width: "47.5%" }}>
          <FormGroup>
            <InputGroup>
              <div
                className={`m-0 p-0 ${
                  showTimeSelect || onlyMonths
                    ? "datetime-picker-wrapper"
                    : "date-picker-wrapper"
                }`}
                data-t1="gridFilter"
                data-t2={`${id}To`}
              >
                <DatePicker
                  id={`${hashInputId(id)}_to`}
                  popperPlacement="auto"
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                  selected={to}
                  showTimeSelect={showTimeSelect}
                  placeholderText={__("do")}
                  selectsEnd
                  className="form-control"
                  startDate={from}
                  endDate={to}
                  showMonthYearPicker={onlyMonths}
                  showFullMonthYearPicker={onlyMonths}
                  showFourColumnMonthYearPicker={onlyMonths}
                  onChange={handleChangeEnd}
                  timeFormat="HH:mm"
                  dateFormat={format}
                />
              </div>
            </InputGroup>
          </FormGroup>
        </div>
        {withClearButton &&
          (inputValue.from !== "" || inputValue.to !== "") && (
            <button
              onClick={handleClearButton}
              type="button"
              className="btn btn-primary"
              style={{ width: "100%", fontSize: "0.7rem" }}
            >
              {__("Wyczyść")}
            </button>
          )}
      </Row>
    </>
  );
}

FilterDateRange.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  showTimeSelect: PropTypes.bool.isRequired,
  onlyMonths: PropTypes.bool.isRequired,
  withClearButton: PropTypes.bool,
  value: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }),
};

FilterDateRange.defaultProps = {
  id: "",
  onChange: null,
  value: {},
  withClearButton: true,
};
