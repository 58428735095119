import React, { useState, useEffect } from "react";
import { REPORT_SERVICE, restApiRequest } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import { dynamicNotification } from "src/utils/Notifications";
import ContentLoading from "src/Components/Loading/contentLoading";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { reportModuleConfiguration } from "src/Pages/Report/report.config.json";
import Spinner from "src/Components/Spinner";
// eslint-disable-next-line
import { version } from "../../../../package.json";

let errorAlert = "";
const DiagnosticPage = () => {
  const [diagnose, setDiagnose] = useState({});

  const [diagnosticDataLoaded, setDiagnosticDataLoaded] = useState(false);

  useEffect(() => {
    getDiagnose();
  }, []);

  const getDiagnose = () => {
    const endpoint = "/audit/Diagnostic";

    fetchData(
      setDiagnose,
      endpoint,
      REPORT_SERVICE,
      "GET",
      {},
      [],
      "Błąd podczas pobierania danych diagnostycznych, nie można się połączyć z staticReportApi"
    ).then(() => setDiagnosticDataLoaded(true));
  };

  const rowList = (items) => {
    if (items) {
      return items.map((item) => <div>{item}</div>);
    }
    return null;
  };

  return (
    <ContentLoading
      message={Spinner}
      show={!diagnosticDataLoaded}
      messageStyle={{ position: "absolute", top: "20%", right: "50%" }}
      backgroundStyle={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <div>
        <p>{errorAlert}</p>
        <p>
          Guid firmy: &nbsp;
          {diagnose.companyId}
        </p>
        <p>
          Nazwa użytkownika: &nbsp;
          {diagnose.name}
          <br />
          Guid użytkownika: &nbsp;
          {diagnose.userId}
        </p>
        <p>
          Rola: &nbsp;
          {diagnose.roleName}
        </p>
        <p>
          Grupy pracownicze: &nbsp;
          <table className="table">
            <thead>
              <tr>
                <th>z uprawnień</th>
                <th>z DWH</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{rowList(diagnose.employeeGroups)}</td>
                <td>{rowList(diagnose.dwhEmployeeGroups)}</td>
              </tr>
            </tbody>
          </table>
        </p>
        <p>
          Jednostki organizacyjne: &nbsp;
          <table className="table">
            <thead>
              <tr>
                <th>z uprawnień</th>
                <th>z DWH</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{rowList(diagnose.organisationUnits)}</td>
                <td>{rowList(diagnose.dwhOrganisationUnits)}</td>
              </tr>
            </tbody>
          </table>
        </p>
        <p>
          Tryb: &nbsp;
          {diagnose.mode}
        </p>
        <p>
          Stan połączenia z SubscriptionServiceApi: &nbsp;
          {diagnose.subscriptionServiceApiConnectionState}
        </p>
        <p>
          Stan połączenia z ReportingServicesApi: &nbsp;
          {diagnose.reportingServicesApiConnectionState}
        </p>
        <p>
          Wersja aplikacji Feniks: &nbsp;
          {version}
        </p>
        <p>
          Wersja modułu raportowego: &nbsp;
          {reportModuleConfiguration.version}
        </p>
        <p>
          Wersja API: &nbsp;
          {diagnose.apiVersion}
        </p>
        <p>
          Wersja DB: &nbsp;
          {diagnose.dbVersion}
        </p>
        <p>
          Błędy: &nbsp;
          <ul>
            {diagnose?.exceptionMessages?.map((val) => (
              <li>{val}</li>
            ))}
          </ul>
        </p>
      </div>
    </ContentLoading>
  );
};

export default DiagnosticPage;

const fetchData = async (
  updateData,
  endpoint,
  service,
  method,
  { headers, params },
  mockData,
  error
) => {
  if (isMockView()) {
    updateData(mockData);
  } else {
    try {
      const result = await restApiRequest(
        service,
        endpoint,
        method,
        { headers, params },
        {}
      );
      updateData(result);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "Pages/Report/DiagnosticPage/index.js - 168");
      dynamicNotification(error || e.message || "błąd", "error");
      errorAlert = `!!!!${error}!!!!`;
    }
  }
};
