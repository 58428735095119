import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Sticky from "react-stickynode";
import Nav from "src/Layout/AppNav/VerticalNavWrapper";

export default function AppSidebar() {
  return (
    <>
      <div role="presentation" className="sidebar-mobile-overlay" />
      <PerfectScrollbar>
        <Sticky top=".app-header">
          <div className="app-sidebar__inner app-sidebar__no-transition">
            <Nav />
          </div>
        </Sticky>
      </PerfectScrollbar>
      <div className="app-sidebar-bg" />
    </>
  );
}
