import React from "react";

import __ from "src/utils/Translations";
import discountFormatter from "src/utils/jsHelpers/discountFormatter";

const discountColumn = (cellInfo) => (
  <div className="d-block w-100 text-center">
    {discountFormatter(
      cellInfo.row[cellInfo.column.id],
      cellInfo.row.discountType
    )}
  </div>
);

export default discountColumn;
