export const defaultValidation = { method: "maxLength", args: [255] };

export const getActualValidation = (validation, type) => {
  switch (type) {
    case "number":
    case "text":
    case "time":
      return validation
        ? [...validation, defaultValidation]
        : [defaultValidation];
    default:
      return validation;
  }
};
