import { useEffect, useState } from "react";
import { CONFIGURATION_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const useConfigValue = (path, companyId = null, employeeGroupId = null) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const params = { path };
    if (companyId) {
      params.companyId = companyId;
    }
    if (employeeGroupId) {
      params.employeeGroupId = employeeGroupId;
    }

    restApiRequest(
      CONFIGURATION_SERVICE,
      "/get-config-value",
      "GET",
      {
        params,
      },
      "mockConfigValue with others characters: '<', '>', '&'; (\u0026lt;, \u0026gt;, \u0026amp;)"
    )
      .then((res) => setValue(res))
      .catch((e) => {
        console.error(e);
        newRelicErrorReport(
          e,
          "utils/hooks/configuration/useConfigValue.js - 31"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać danych konfiguracyjnych"),
          "error"
        );
      });
  }, [path, companyId, employeeGroupId]);

  return value;
};

export default useConfigValue;
