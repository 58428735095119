import React from "react";
import { Row, Col } from "reactstrap";
import __ from "src/utils/Translations";

export default function ForbiddenErrorPage() {
  return (
    <Row className="vh-100">
      <Col className="my-auto">
        <div className="text-center ">
          <i className="pe-7s-attention icon-gradient bg-plum-plate error-page-icon" />
          <div>
            {__("Taki zasób nie istnieje lub nie masz do niego dostępu.")}
          </div>
        </div>
      </Col>
    </Row>
  );
}
