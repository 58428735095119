/* eslint-disable no-underscore-dangle, react/prop-types */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import DataTableControlled from "src/Components/DataTableControlled";
import __ from "src/utils/Translations";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import { GetCurrentCompany } from "src/Pages/Report/helpers/companyHelper";

const ReportsTable = ({ reportList = [], isAdHoc }) => {
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();
  const currentCompany = GetCurrentCompany();

  const getUrlToForm = (id) =>
    getAhrUrl(
      `/report/predefined-reports/${id}${!isAhr ? `/${currentCompany}` : ""}`,
      isAhr
    );

  const columns = () => {
    const colArray = [];
    colArray.push({
      Header: __("Nazwa raportu"),
      accessor: "reportName",
      filterable: false,
      Cell: ({ row }) =>
        !row._original.underMaintenance ? (
          <div>
            <Link to={getUrlToForm(row._original.reportGuid)}>
              <Button role="button" color="link">
                {" "}
                {row.reportName}
              </Button>
            </Link>
          </div>
        ) : (
          <div> {row.reportName}</div>
        ),
    });
    if (isAdHoc) {
      colArray.push({
        Header: __("Utworzono"),
        maxWidth: 100,
        accessor: "reportInsertDate",
        filterable: false,
        Cell: ({ row }) => (
          <div
            style={{
              wordBreak: "normal",
              textAlign: "center",
            }}
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: moment(row.reportInsertDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            }}
            // 2023-03-20T11:18:11.17
          />
        ),
      });
    }
    if (isAdHoc) {
      colArray.push({
        Header: __("Utworzone przez"),
        maxWidth: 250,
        accessor: "createdBy",
        filterable: false,
        Cell: ({ row }) => (
          <div
            style={{
              wordBreak: "normal",
              textAlign: "center",
            }}
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: row.createdBy }}
          />
        ),
      });
    }
    colArray.push({
      Header: __("Opis"),
      accessor: "description",
      filterable: false,
      Cell: ({ row }) => (
        <div
          style={{
            wordBreak: "normal",
            textAlign: "justify",
          }}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: row.description }}
        />
      ),
    });
    colArray.push({
      Header: __("Akcja"),
      maxWidth: 150,
      filterable: false,
      sortable: false,
      Cell: ({ row }) =>
        !row._original.underMaintenance ? (
          <div className="d-block w-100 text-center row">
            <Link to={getUrlToForm(row._original.reportGuid)}>
              <Button role="button" color="link">
                {__("Przejdź")}
              </Button>
            </Link>
          </div>
        ) : (
          <div className="d-block w-100 text-center row">
            {__("niedostępny")}
          </div>
        ),
    });
    return colArray;
  };

  return (
    <>
      <DataTableControlled
        id="reportsDTC"
        columns={columns()}
        data={reportList}
        filterable
        count={reportList.length}
        defaultPageSize={100}
        fetchData={() => {}}
      />
    </>
  );
};

ReportsTable.propTypes = {
  reportList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAdHoc: PropTypes.bool,
};

ReportsTable.defaultProps = {
  isAdHoc: false,
};

export default ReportsTable;
