import React from "react";
import ReactDOM from "react-dom";
import __ from "src/utils/Translations";
import InfoModal from "src/Components/InfoModal";

const messageByType = {
  changeOrganizationUnit: [
    `Po zapisaniu zmiany Jednostki Organizacyjnej,`,
    `jeżeli użytkownik posiada aktywne karty Multisport lub Multilife,`,
    `zostanie poinformowany o konieczności zamówienia nowej karty.`,
    `Dotychczasowe karty użytkownika będą aktywne do końca miesiąca.`,
  ],
  changeFirstNameOrLastName: [
    `Jeżeli Pracownik posiada kartę MultiSport`,
    `to po zmianie danych osobowych niezbędne jest złożenie wniosku o duplikat karty MultiSport.`,
    `Poinformuj go aby zamówił duplikat na swoim koncie.`,
  ],
  turnOnCalculator: [
    `Aby funkcjonalność działała poprawnie, należy określić`,
    `progi dochodowe dla GD oraz domyślne GD w poszczególnych grupach pracowniczych.`,
    `W przeciwnym wypadku pracownicy nie zostaną przypisani do odpowiednich GD.`,
  ],
};

function getInfoModal({ onClose, type }) {
  const message = messageByType[type]?.reduce(
    (acc, cur, i) => acc + (i ? " " : "") + __(cur)
  );
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);
  const closeModal = (confirm) => {
    onClose(confirm);
    ReactDOM.unmountComponentAtNode(container);
  };
  ReactDOM.render(
    <InfoModal message={message} onClose={closeModal} />,
    container
  );
}

export default getInfoModal;
