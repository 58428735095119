import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { EMPLOYEE_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import ContentLoading from "src/Components/Loading/contentLoading";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import sortAlphabeticallyByField from "src/utils/jsHelpers/sortAlphabeticallyByField";
import { settlementTypeOptions as settlementTypeOptionsUntranslated } from "src/Pages/Company/CompanyBanks/Management/Listing/bankForm";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import translateLabels from "src/utils/Translations/translateLabels";
import ChargeUpsList from "./ChargeUpsList";

const fetchAccounts = (employeeId) =>
  restApiRequest(
    EMPLOYEE_MANAGEMENT_SERVICE,
    "/employee-accounts",
    "GET",
    {
      params: {
        employeeId,
      },
    },
    mockAccountsData
  );

const updateAmount = (accountId, amount) =>
  restApiRequest(
    EMPLOYEE_MANAGEMENT_SERVICE,
    `/employee-accounts/${accountId}`,
    "PATCH",
    {
      body: {
        chargeAmount: amount,
      },
    },
    {}
  );

export default function ChargeUps({ active, employeeId }) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const settlementTypeOptions = useMemo(
    () => translateLabels(settlementTypeOptionsUntranslated),
    []
  );
  const loadAccounts = useCallback(async (employee) => {
    setLoading(true);
    try {
      const response = await fetchAccounts(employee);
      const parsedResponse = response.map((account) => {
        const settlementTypeLabel = settlementTypeOptions.find(
          ({ value }) => value === account.settlementType
        )?.label;
        return {
          ...account,
          settlementTypeLabel: settlementTypeLabel || account.settlementType,
        };
      });
      setAccounts(
        parsedResponse.sort(sortAlphabeticallyByField("pointsBankDisplayName"))
      );
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/ChargeUps/index.js - 61"
      );
      dynamicNotification(
        e.message || __("Nie udało się pobrać kont pracownika."),
        "error"
      );
      setAccounts([]);
    }
    setLoading(false);
  }, []);

  const chargeAccount = useCallback(
    async (account, amount) => {
      try {
        if (!account) {
          return;
        }
        const updatedAccounts = [...accounts];
        updatedAccounts[accounts.indexOf(account)] = {
          ...account,
          balance: account.balance + amount,
        };
        setAccounts(updatedAccounts);
        await updateAmount(account.id, amount);
        dynamicNotification(__("Pomyślnie zaktualizowano liczbę punktów."));
      } catch (e) {
        console.error(e);
        newRelicErrorReport(
          e,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/ChargeUps/index.js - 90"
        );
        dynamicNotification(
          e.message || __("Nie udało się doładować konta."),
          "error"
        );
        loadAccounts(employeeId);
      }
    },
    [loadAccounts, accounts, employeeId]
  );

  useEffect(() => {
    if (!active) {
      return;
    }
    loadAccounts(employeeId);
  }, [active, employeeId, loadAccounts]);

  if (!active) {
    return null;
  }

  return (
    <Card>
      <ContentLoading show={loading}>
        <CardHeader>
          <CardTitle>{__("Doładowania punktów")}</CardTitle>
        </CardHeader>
        <CardBody>
          <ChargeUpsList
            accounts={accounts}
            chargeAccount={chargeAccount}
            key="charge_ups_list"
          />
        </CardBody>
      </ContentLoading>
    </Card>
  );
}
ChargeUps.propTypes = {
  active: PropTypes.bool,
  employeeId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  companyId: PropTypes.string.isRequired,
};
ChargeUps.defaultProps = {
  active: false,
};

const mockAccountsData = [
  {
    pointsBankId: "047ff444-c57e-4dc2-86c2-c8a5910984b9",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "test5",
    locks: [],
  },
  {
    pointsBankId: "4d157c59-bae0-416c-ba20-c1d39cc7b5eb",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "Testowy bank 2",
    locks: [],
  },
  {
    pointsBankId: "c38648dd-4c06-4959-aa5d-2fceace8f3c4",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "tes4",
    locks: [],
  },
  {
    pointsBankId: "eed80c44-7eec-413b-9808-d85a596301ba",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "Testowy bank",
    locks: [],
  },
  {
    pointsBankId: "235621a9-8060-4d87-85dc-a9f036bf86e5",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "test6",
    locks: [],
  },
  {
    pointsBankId: "07042743-c2d0-46b7-bfd0-61da57335a21",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "test3",
    locks: [],
  },
];
