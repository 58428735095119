import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import { subscriptionEmployeeSubscriptionItemChangeDates } from "src/utils/RoleBasedSecurity/permissions";
import Form from "src/Components/Form";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const Change = ({ subscription, close }) => {
  const [data, setData] = useState({});
  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      setData(updatedData);
    },
    [data]
  );

  useEffect(() => {
    setData({
      startsAt: subscription.startsAt,
      endsAt: subscription.endsAt,
    });
  }, [subscription]);

  const {
    resignAvailableFrom,
    resignAvailableTo,
    startAvailableFrom,
    startAvailableTo,
    benefitSubgroupId,
  } = subscription || {};

  const hasNotResignAvailabilityData =
    !resignAvailableFrom && !resignAvailableTo;

  const isCurrentBenefitFromGroupUms = benefitSubgroupId;

  const submit = async () => {
    try {
      const { id } = subscription;
      await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employee-subscription-item-receivers/${id}/change-dates`,
        "POST",
        {
          body: data,
        },
        null
      );
      dynamicNotification(__("Pomyślnie zmieniono świadczenie."));
      close(true);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/Popup/change.js - 45"
      );
      dynamicNotification(
        e.message || __("Nie udało się zmienić świadczenia."),
        "error"
      );
    }
  };
  return (
    <Form
      id="changeSubscriptionForm"
      data={data}
      config={{
        isInPopup: true,
        togglePopup: () => close(),
        title: "Zmiana daty obowiązywania świadczenia",
        onSubmit: submit,
        buttons: [
          {
            onClick: () => close(),
            text: "Zamknij",
            color: "light",
            id: "changeSubscriptionFormClose",
          },
          {
            text: "Zmień",
            type: "submit",
            id: "changeSubscriptionFormSubmit",
            permission: subscriptionEmployeeSubscriptionItemChangeDates,
          },
        ],
        defaultOnChange: onChange,
        noCards: true,
        formGroups: [
          {
            formElements: [
              {
                component: isCurrentBenefitFromGroupUms ? (
                  <GroupUmsInfo />
                ) : (
                  <Info />
                ),
              },
              {
                id: "startsAt",
                label: "Nowa data startu:",
                type: "month",
                validation: ["required"],
                disabled: true,
                props: {
                  disabled: isCurrentBenefitFromGroupUms,
                },
                ...(isCurrentBenefitFromGroupUms && {
                  tooltip: {
                    content: __(
                      "Dla abonamentów z grupy II (UMS) nie ma możliwości zmiany daty przystąpienia. Jeśli abonament ma mieć inną datę startu użyj importera."
                    ),
                  },
                }),
                ...(!isCurrentBenefitFromGroupUms && {
                  minDate: new Date(startAvailableFrom ?? undefined),
                  maxDate: new Date(startAvailableTo ?? undefined),
                }),
              },
              {
                id: "endsAt",
                label: "Nowa data końca:",
                type: "month",
                validation: [
                  {
                    method: "greaterEqualThanDate",
                    args: [data?.startsAt || 0],
                  },
                ],
                minDate: new Date(resignAvailableFrom ?? undefined),
                maxDate: new Date(resignAvailableTo ?? undefined),
                props: {
                  disabled: hasNotResignAvailabilityData,
                },
              },
            ],
          },
        ],
      }}
    />
  );
};

Change.propTypes = {
  close: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    startsAt: PropTypes.string,
    endsAt: PropTypes.string,
    benefit: PropTypes.shape({
      benefitGroup: PropTypes.string,
      benefitSubgroup: PropTypes.string,
    }),
  }).isRequired,
};

const Info = () => (
  <Alert key="cancelInfo" color="primary">
    <h4>
      {__("UWAGA: Anulacja świadczenia spowoduje zmiany w raporcie płacowym.")}
    </h4>
    <p>
      {__(
        // eslint-disable-next-line max-len
        "Czy akceptujesz zmiany na swoją odpowiedzialność oraz czy poinformowałeś/aś wszystkie osoby korzystające z raportu płacowego o zmianach (np. AHR firmy)?"
      )}
    </p>
    <ul>
      <li>{__("pusta data końca oznacza świadczenie na czas nieokreślony")}</li>
      <li>{__("świadczenia zależne nie przestawiają się automatycznie")}</li>
    </ul>
    <p>
      {__(
        "Zmiana dat na świadczeniu Przekazanie Punktów do Banku Punktów powoduje zmianę saldo na koncie użytkownika:"
      )}
    </p>
    <ul>
      <li>
        {__(
          "wydłużenie świadczenia doładowuje punkty (sprawdź czy doładowana liczba punktów jest prawidłowa)"
        )}
      </li>
      <li>
        {__(
          // eslint-disable-next-line max-len
          "skrócenie świadczenia odejmuje punkty (sprawdź czy odjęta liczba punktów jest prawidłowa, jeżeli na koncie użytkownika nie ma wystarczającej liczby punktów potrzebnej do skorygowania daty świadczenia, zmiana daty nie jest możliwa)"
        )}
      </li>
    </ul>
  </Alert>
);

const GroupUmsInfo = () => (
  <Alert key="cancelInfo" color="primary">
    <p>
      {__(
        "1) Pusta data końca oznacza, że abonament jest wybrany na czas nieokreślony"
      )}
    </p>
    <p>{__("2) Brak możliwości zmiany daty startu abonamentu")}</p>
    <p>{__("3) Możliwość zmiany daty rezygnacji istnieje:")}</p>
    <ul>
      <li>
        {__(
          "a. jeśli obecna data rezygnacji jest datą w przyszłości - nowa data nie może być datą przeszłą"
        )}
      </li>
      <li>
        {__(
          // eslint-disable-next-line max-len
          "b. jeśli zmieniamy datę w przeszłości na dalszą (np. abonament aktywny od stycznia 2022, rezygnacja ustawiona na maj 2022 - możliwa jest zmiana na kwiecień 2022, ale na czerwiec 2022 nie)."
        )}
      </li>
    </ul>
    <p>
      {__(
        // eslint-disable-next-line max-len
        "W przypadku opisanym w punkcie 3b nastąpią zmiany w raporcie płacowym, czy akceptujesz zmiany na swoją odpowiedzialność oraz czy poinformowałeś/aś wszystkie osoby korzystające z raportu płacowego o zmianach (np. AHR firmy)?"
      )}
    </p>
  </Alert>
);

export default Change;
