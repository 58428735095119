import _ from "lodash";
import {
  EMPLOYEE_MANAGEMENT_SERVICE,
  getServiceHost,
  restApiRequest,
} from "src/utils/Api";
import __ from "src/utils/Translations";
import {
  IRI_PREFIX,
  mockData,
} from "src/utils/hooks/company/useRentableGroupSelectionWindows";
import { getIdFromIri } from "src/utils/jsHelpers/iriConverter";
import { dynamicNotification } from "src/utils/Notifications";
import { ACTIVATION_MODE_INFINITY } from "src/Pages/Company/EmployeeManagement/RentableGroupsWindow/utils/consts";
import {
  STATUS_ACTIVE,
  STATUS_PENDING,
  STATUS_PENDING_ACCEPTED,
  STATUS_NEW_PERSON,
  STATUS_RESIGNED,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import { isFutureDate } from "src/Pages/Company/EmployeeManagement/RentableGroupsWindow/utils/utils";
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { getLanguage } from "src/utils/Languages/LanguageContext";

const DAY_HOURS = 24;

export const fetchRentableGroupSelectionWindow = async (iris) => {
  try {
    const ids = iris.map((iri) => getIdFromIri(iri, IRI_PREFIX));
    const promises = ids.map((id) =>
      restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/rentable-group-selection-windows/${id}`,
        "GET",
        {},
        mockData
      ).catch((e) => {
        newRelicErrorReport(
          e,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/utils.js - 39"
        );
      })
    );
    return (await Promise.all(promises)).filter(Boolean);
  } catch (e) {
    console.error(e);
    newRelicErrorReport(
      e,
      "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/utils.js - 50"
    );
    dynamicNotification(
      e.message ||
        __("Nie udało się pobrać listy okien wyboru grup dochodowości"),
      "error"
    );
    return [];
  }
};

export const isActivationModeInfinity = (windowSelectionData, windowType) => {
  const selectionWindow = windowSelectionData.find(
    (item) => item.windowType === windowType
  );
  if (selectionWindow) {
    return selectionWindow.activationMode === ACTIVATION_MODE_INFINITY;
  }
  return false;
};

export const saveRentableGroupSelectionWindow = async (
  employeeId,
  windowsData,
  individualWindowsEnabled
) => {
  const promises = [];
  windowsData.forEach(({ enabled, ...windowData }) => {
    if (enabled && individualWindowsEnabled) {
      promises.push(saveWindow(employeeId, windowData));
    } else {
      promises.push(deleteWindow(windowData.id));
    }
  });
  return Promise.all(promises);
};

const deleteWindow = async (id) => {
  try {
    if (id) {
      await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/rentable-group-selection-windows/${id}`,
        "DELETE",
        { returnNull: true },
        null
      );
    }
  } catch (e) {
    console.error(e);
    newRelicErrorReport(
      e,
      "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/utils.js - 101"
    );
    dynamicNotification(
      e.message || __("Nie udało się odłączyć okna wyboru grupy dochodowości"),
      "error"
    );
  }
};

const saveWindow = async (
  employeeId,
  { rangeDateFrom, rangeDateTo, ...windowData }
) => {
  try {
    const body = {
      ...windowData,
      ...(windowData?.rangeDateFromLocal
        ? { rangeDateFrom: windowData.rangeDateFromLocal }
        : {}),
      ...(windowData?.rangeDateToLocal
        ? { rangeDateTo: windowData.rangeDateToLocal }
        : {}),
      windowType: +windowData.windowType,
      active: true,
    };
    if (windowData.id) {
      await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/rentable-group-selection-windows/${windowData.id}`,
        "PATCH",
        {
          body,
        },
        {}
      );
    } else {
      await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/rentable-group-selection-windows/individual/${employeeId}`,
        "POST",
        {
          body,
        },
        {}
      );
    }
  } catch (e) {
    console.error(e);
    newRelicErrorReport(
      e,
      "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/utils.js - 141"
    );
    dynamicNotification(
      e.message || __("Nie udało się zapisać okna wyboru grupy dochodowości"),
      "error"
    );
  }
};

export const deleteOMBOnlyFields = (data) => {
  const OMB_ONLY_FIELDS = [
    "administeredBillingUnits",
    "administeredEmployeeGroups",
    "administeredOrganizationUnits",
    "administeredPointsBanks",
    "ahr",
    "ahrResponsibilities",
    "ahrRole",
    "ahrStatus",
  ];

  const updatedData = { ...data };
  OMB_ONLY_FIELDS.forEach((field) => {
    delete updatedData[field];
  });

  return updatedData;
};

export const checkOpenSubscriptions = (data) => {
  if (_.isEmpty(data)) {
    return false;
  }

  const activeStatuses = [
    STATUS_ACTIVE,
    STATUS_PENDING,
    STATUS_PENDING_ACCEPTED,
    STATUS_NEW_PERSON,
  ];

  const hasActiveSubscriptions = data.some((item) =>
    activeStatuses.includes(item.status)
  );

  if (hasActiveSubscriptions) return true;

  return data.some(
    (item) => item.status === STATUS_RESIGNED && isFutureDate(item.endsAt)
  );
};

export const downloadFile = async (service, path, fileName, open = false) => {
  const session = getSession();
  const response = await fetch(`${getServiceHost(service)}${path}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${await session.getValidAccessToken()}`,
      "content-language": getLanguage(),
    },
  });
  if (!response.ok) {
    dynamicNotification(__("Nie udało się pobrać listu."), "error");
  } else {
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    if (open) {
      link.target = "_blank";
    } else {
      link.download =
        fileName ||
        /filename="(?<filename>.*)"/.exec(
          response.headers.get("content-disposition")
        )?.groups?.filename;
    }
    link.click();
  }
};
