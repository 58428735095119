/* eslint-disable no-underscore-dangle, react/prop-types */
import React, { useCallback, useState } from "react";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import {
  SelectFilter,
  booleanOptions,
  AutocompleteSelectFilter,
  AsyncAutocompleteSelectFilter,
  UuidFilter,
} from "src/Components/DataTable/filters";
import { CATALOG_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import {
  CATEGORIES_SEPARATOR,
  useCategoryOptions,
} from "src/Pages/ProductCatalog/EditProduct/utils";
import useProductSuppliers from "src/utils/hooks/suppliers/useProductSuppliers";
import ActionColumn from "src/Components/DataTable/actionColumn";
import BusinessIdColumn from "src/Components/DataTable/businessIdColumn";
import { catalogProductPermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import { getSuppliersOptionsFetchMethod } from "src/Components/FormElements/Autocomplete/commonFetchMethods";
import priceFormatter from "src/utils/jsHelpers/priceFormatter";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export const mapPriceValue = (item) => {
  if (item.configurableProduct) {
    const lastItem = item.configurations.length - 1;
    return (
      <div className="d-block w-100 text-center">
        {item.configurations.map((configItem, index) => {
          if (index === lastItem) {
            return priceFormatter(configItem.sellingPrice);
          }
          return `${priceFormatter(configItem.sellingPrice)}, `;
        })}
      </div>
    );
  }
  return (
    <div className="d-block w-100 text-center">
      {priceFormatter(item.sellingPrice)}
    </div>
  );
};

const PriceColumn = (rowData) => mapPriceValue(rowData.row._original);

export default function BenefitDataTable() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const fetchData = useCallback(async (filters, page, pageSize, sort) => {
    const { data: newData, count: newCount } = await getListingData(
      CATALOG_MANAGEMENT_SERVICE,
      "/products",
      filters,
      page,
      pageSize,
      sort,
      {},
      productsMockData,
      true
    );
    if (newData) {
      setData(newData);
      setCount(newCount);
    }
  }, []);

  const deleteProduct = async (id) => {
    try {
      setData(data.filter((el) => el.id !== id));
      await restApiRequest(
        CATALOG_MANAGEMENT_SERVICE,
        `/products/${id}`,
        "DELETE",
        { returnNull: true },
        {}
      );
      dynamicNotification(__("Pomyślnie usunięto produkt"));
    } catch (e) {
      setData(data);
      console.error(e);
      newRelicErrorReport(e, "Pages/ProductCatalog/List/table.js - 84");
      dynamicNotification(
        e.message || __("Nie udało się usunąć produktu"),
        "error"
      );
    }
  };
  const showAddProductButton =
    process.env.REACT_APP_SHOW_ADD_PRODUCT_BUTTON === "true";
  const categoryOptions = useCategoryOptions();
  const suppliersOptions = useProductSuppliers(true, true);
  const categoryOptionsShortLabel = categoryOptions.map(({ label, value }) => {
    const elements = label.split(CATEGORIES_SEPARATOR);
    return {
      value,
      label: elements[elements.length - 1],
    };
  });
  return (
    <DataTableControlled
      id="catalogProductListing"
      fetchData={fetchData}
      buttons={[
        ...(showAddProductButton
          ? [
              {
                color: "primary",
                text: "+ Dodaj",
                href: "/product-catalog/products/-1/-1",
                permission: catalogProductPermissionWrite,
              },
            ]
          : []),
      ]}
      columns={[
        {
          Header: "Kod produktu",
          accessor: "id",
          width: 150,
          Cell: BusinessIdColumn,
          Filter: UuidFilter,
        },
        {
          Header: "Włączony",
          accessor: "active",
          maxWidth: 200,
          Filter: SelectFilter(booleanOptions),
          Cell: mapValueFromOptions(booleanOptions, "active"),
        },
        {
          Header: "Nazwa produktu",
          accessor: "name",
        },
        {
          Header: "Identyfikator",
          accessor: "identifier",
        },
        {
          Header: "Cena produktu",
          accessor: "sellingPrice",
          Cell: PriceColumn,
          filterable: false,
          sortable: false,
        },
        {
          Header: "Dostawca",
          accessor: "supplierId",
          Filter: AsyncAutocompleteSelectFilter((filterValue) =>
            getSuppliersOptionsFetchMethod(filterValue)
          ),
          Cell: mapValueFromOptions(suppliersOptions, "supplierId"),
        },
        {
          Header: "Kategoria",
          accessor: "businessCategory",
          sortable: false,
          Filter: AutocompleteSelectFilter(categoryOptions),
          Cell: mapValueFromOptions(
            categoryOptionsShortLabel,
            "businessCategory"
          ),
        },
        {
          Header: "Akcja",
          filterable: false,
          sortable: false,
          Cell: ({ row }) => (
            <div className="d-block w-100 text-center row">
              <ActionColumn
                data={row._original}
                buttons={[
                  {
                    id: "ProductCatalogListingEdit",
                    href: `/product-catalog/products/${row._original.id}/${row._original.mbProductType}`,
                    className: "m-1",
                    color: "link",
                    label: "Edytuj",
                  },
                  {
                    id: "ProductCatalogListingDelete",
                    className: "m-1",
                    color: "link",
                    onClick: () =>
                      getUserConfirmationPopup(
                        __("Czy na pewno chcesz usunąć produkt?"),
                        (confirm) =>
                          confirm && deleteProduct(row._original.id, true),
                        __("Niezapisane zmiany zostaną utracone")
                      ),
                    label: "Usuń",
                  },
                ]}
              />
            </div>
          ),
        },
      ]}
      data={data}
      count={count}
      filterable
    />
  );
}

export const productsMockData = [
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    name: "Bon 50zł do Lidla",
    supplier: "Anixe",
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea2",
    city: "Wrocław",
    active: true,
    option: "AUTOMOTIVE",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200022",
    name: "Bilet do Muzeum Sztuki Nowożytnej",
    supplier: "Anixe",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea8",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200023",
    name: "Kurs wioślarstwa",
    supplier: "Anixe",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea4",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200024",
    name: "Kurs Gotuj Zdrowiej",
    supplier: "GDS",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea2",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200025",
    name: "Bilety do Kina Helios",
    supplier: "Anixe",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea3",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200026",
    name: "Bon do Decathlon",
    supplier: "GDS",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea8",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200027",
    name: "Wycieczka do Pragi",
    supplier: "Anixe",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea3",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200028",
    name: "Kurs języka japońskiego",
    supplier: "Anixe",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea8",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200029",
    name: "Kurs skakania na skakance",
    supplier: "GDS",
    active: false,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea4",
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200020",
    name: "Bon do Empiku",
    supplier: "Anixe",
    active: true,
    businessCategory:
      "/api/catalog-management/v1/rest/business-categories/7105a40b-a629-4035-a79e-f1b7c6c50ea2",
  },
];
