import { useEffect, useState } from "react";
import { CONFIGURATION_SERVICE, restApiRequest } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";

const useConfig = (companyId = null, employeeGroupId = null) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    const params = {};
    if (companyId) {
      params.companyId = companyId;
    }
    if (employeeGroupId) {
      params.employeeGroupId = employeeGroupId;
    }

    restApiRequest(
      CONFIGURATION_SERVICE,
      "/get-config",
      "GET",
      {
        params,
      },
      {}
    )
      .then((res) => setValue(res))
      .catch((e) => {
        console.error(e);
        newRelicErrorReport(e, "utils/hooks/configuration/useConfig.js - 31");
        dynamicNotification(
          e.message || __("Nie udało się pobrać danych konfiguracyjnych"),
          "error"
        );
      });
  }, [companyId, employeeGroupId]);

  return value;
};

export default useConfig;
