import __ from "src/utils/Translations";
import { getLanguage } from "src/utils/Languages/LanguageContext";

const getFilteredColumns = (columns) =>
  columns
    .filter((item) => !item.hasNoPermission && !item.doNotDisplay)
    .map((x) => {
      const currentLanguage = getLanguage();
      if (x.format && x.format.digits) {
        // eslint-disable-next-line
        x.Cell = function (props) {
          // eslint-disable-next-line
          return props.value.toLocaleString(currentLanguage, {
            minimumFractionDigits: x.format.digits,
          });
        };
      } else if (x.format && x.format.datetime === true) {
        // eslint-disable-next-line
        x.Cell = function (props) {
          // eslint-disable-next-line
          return new Date(props.value).toLocaleDateString(currentLanguage);
        };
      }
      return x;
    });

export default getFilteredColumns;
