/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import DataTable from "src/Components/DataTable";
import DataLoading from "src/Components/Loading/dataLoading";
import { getDateCell } from "src/Components/DataTable/commonCells";
import { AGREEMENT_SERVICE } from "src/utils/Api";
import FormTitle from "src/Components/Form/FormTitle";
import ActionColumn from "src/Components/DataTable/actionColumn";
import ItemPopup from "./itemPopup";

export default function UserAgreements({ employeeId }) {
  const [data, setData] = useState(null);
  const [item, setItem] = useState(null);

  return (
    <Card>
      <FormTitle title="Zgody użytkownika" />
      <CardBody>
        <DataLoading
          service={AGREEMENT_SERVICE}
          endpoint={`/user-agreement-extended?userId=${employeeId}`}
          fetchedData={data !== null}
          updateData={(updatedData) => setData(updatedData)}
        >
          <DataTable
            id="userAgreementsListing"
            key="user_agreements_listing"
            data={data || []}
            noCards
            columns={[
              {
                Header: "Tytuł",
                accessor: "title",
              },
              {
                Header: "Reakcja",
                accessor: "action",
                Cell: (rowData) => {
                  if (rowData.row._original.action === "ACCEPT") {
                    return "Zaakceptowana";
                  }
                  return "Odrzucona";
                },
              },
              {
                Header: "Data",
                accessor: "createdAt",
                Cell: getDateCell("createdAt", true),
              },
              {
                Header: "Wersja zgody",
                accessor: "historyVersion",
              },
              {
                Header: "Reakcja na aktualną wersję",
                accessor: "validVersion",
                Cell: (rowData) => {
                  if (rowData.row._original.validVersion === "1") {
                    return "Tak";
                  }
                  return "Nie";
                },
              },
              {
                Header: "Akcja",
                accessor: "action",
                Cell: (cellData) => (
                  <div className="d-block w-100 text-center">
                    <ActionColumn
                      data={cellData}
                      buttons={[
                        {
                          id: "showDetails",
                          onClick: () => {
                            setItem(cellData.original.content);
                          },
                          label:
                            "Podgląd treści zgody wyrażonej przez użytkownika",
                        },
                      ]}
                    />
                  </div>
                ),
              },
            ]}
          />
        </DataLoading>
        {item ? <ItemPopup close={() => setItem(null)} item={item} /> : null}
      </CardBody>
    </Card>
  );
}

UserAgreements.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
