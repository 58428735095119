/* eslint-disable react/prop-types */
import React from "react";
import Autocomplete from "src/Components/FormElements/Autocomplete/Autocomplete";

const AutocompleteSelectFilter =
  (options, submitForm = true) =>
  ({ filter, onChange, column }) =>
    (
      <Autocomplete
        id={column.id}
        options={options}
        classNamePrefix="gridFilterAutocomplete"
        value={filter ? filter.value : 0}
        onChange={(selected) => {
          onChange(selected?.value || "");
          if (submitForm) {
            const form = document.getElementById(column.id).closest("form");
            if (form) {
              const submitButton = form.querySelector('button[type="submit"]');
              setTimeout(() => {
                submitButton.click();
              }, 10);
            }
          }
        }}
      />
    );

export default AutocompleteSelectFilter;
