/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from "react";
import { CustomInput } from "reactstrap";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { API_GATEWAY_SERVICE, restApiRequest } from "src/utils/Api";
import ActionColumn from "src/Components/DataTable/actionColumn";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import ContentLoading from "src/Components/Loading/contentLoading";
import { useSelectedRows } from "./SelectedProvider";
import {
  ACCEPTED_REQUIREMENT_FAILURE_ONE,
  ACCEPTED_REQUIREMENT_FAILURE_OTHER,
  ACCEPTED_REQUIREMENT_SUCCESS_ONE,
  ACCEPTED_REQUIREMENT_SUCCESS_OTHER,
  alertMessages,
  conditionTypes,
  REJECT_BENEFIT_FAILURE,
  REJECT_BENEFIT_SUCCESS,
} from "./consts";

const ActionsColumn = (cellInfo) => {
  const {
    row: {
      _original: { id: waitingRoomId, conditions, documentUrl },
    },
    column: { onCancelSubscription, onAcceptConditions },
  } = cellInfo;
  const [isLoading, setIsLoading] = useState(false);
  const { getConditionIdsById, onSelectionChange, onConditionSelectionChange } =
    useSelectedRows();
  const selectedConditions = getConditionIdsById(waitingRoomId);
  const isAnyAccepted = selectedConditions.length < 1;
  const isEveryConditionDisabled = conditions.every(
    ({ conditionAcceptable }) => !conditionAcceptable
  );

  const acceptConditions = useCallback(async () => {
    try {
      setIsLoading(true);
      await restApiRequest(
        API_GATEWAY_SERVICE,
        `/waiting-room/${waitingRoomId}/accept-conditions`,
        "PUT",
        {
          params: {
            conditionIdList: selectedConditions,
          },
        },
        {}
      );

      dynamicNotification(
        __(
          selectedConditions.length === 1
            ? ACCEPTED_REQUIREMENT_SUCCESS_ONE
            : ACCEPTED_REQUIREMENT_SUCCESS_OTHER
        )
      );
      onSelectionChange(false, { id: waitingRoomId });
      onAcceptConditions();
    } catch (e) {
      console.error(e);
      dynamicNotification(
        e.message ||
          `${__("Wystąpił nieoczekiwany błąd")}\n ${__(
            selectedConditions.length === 1
              ? ACCEPTED_REQUIREMENT_FAILURE_ONE
              : ACCEPTED_REQUIREMENT_FAILURE_OTHER,
            [selectedConditions.length]
          )}`,
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    waitingRoomId,
    selectedConditions,
    onSelectionChange,
    onAcceptConditions,
  ]);

  const denySubscription = useCallback(async () => {
    try {
      setIsLoading(true);
      await restApiRequest(
        API_GATEWAY_SERVICE,
        `/waiting-room/${waitingRoomId}/cancel`,
        "PUT",
        {},
        {}
      );
      dynamicNotification(__(REJECT_BENEFIT_SUCCESS));
      onSelectionChange(false, { id: waitingRoomId });
      onCancelSubscription();
    } catch (e) {
      console.error(e);
      dynamicNotification(
        e.message ||
          `${__("Wystąpił nieoczekiwany błąd")}\n ${__(
            REJECT_BENEFIT_FAILURE
          )}`,
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  }, [waitingRoomId, onSelectionChange, onCancelSubscription]);

  const handleChecked = useCallback(
    (id) => (ev) => {
      const { checked } = ev.target;
      onConditionSelectionChange(checked, waitingRoomId, id);
    },
    [onConditionSelectionChange, waitingRoomId]
  );

  const handleCancel = useCallback(() => {
    getUserConfirmationPopup(
      __(alertMessages.default),
      (confirm) => confirm && denySubscription(),
      __(`Czy na pewno chcesz odrzucić świadczenie?`)
    );
  }, [denySubscription]);

  useEffect(() => {
    onSelectionChange(false, { id: waitingRoomId });
  }, [onSelectionChange, waitingRoomId]);

  return (
    <ContentLoading show={isLoading}>
      <div className="d-block w-100 text-center">
        {conditions.map(({ id, accepted, conditionAcceptable, type }) => {
          const isChecked = accepted || selectedConditions.includes(id);
          const isDisabled = accepted || !conditionAcceptable;
          return (
            <div key={id} className="d-flex text-break text-left m-1">
              <CustomInput
                id={`form_${waitingRoomId}_${id}`}
                inline
                type="checkbox"
                disabled={isDisabled}
                checked={isChecked}
                value={isChecked}
                onChange={handleChecked(id)}
                label={__(conditionTypes[type])}
              />
            </div>
          );
        })}
        <ActionColumn
          data={cellInfo.row._original}
          buttons={[
            {
              id: "open-document",
              role: "button",
              disabled: isLoading,
              size: "sm",
              className: "m-1",
              label: "Pobierz dokument",
              title: __("Pobierz dokument"),
              doNotDisplay: !documentUrl,
              href: documentUrl,
              target: "_blank",
            },
            {
              id: "accept",
              role: "button",
              color: "primary",
              disabled: isLoading || isAnyAccepted || isEveryConditionDisabled,
              size: "sm",
              className: "m-1",
              label: "Akceptuj",
              title: __("Akceptuj"),
              onClick: acceptConditions,
            },
            {
              id: "cancel",
              role: "button",
              color: "danger",
              disabled: isLoading,
              size: "sm",
              className: "m-1",
              label: "Odrzuć",
              title: __("Odrzuć"),
              onClick: handleCancel,
            },
          ]}
        />
      </div>
    </ContentLoading>
  );
};

export default ActionsColumn;
