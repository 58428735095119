import PropTypes from "prop-types";
import React, { useState } from "react";
import { CardHeader, Card, CardBody } from "reactstrap";
import Tooltip from "src/Components/Tooltips/DefaultTooltip";
import { getLayout } from "src/Components/Layouts";
// eslint-disable-next-line import/no-cycle
import FormElement from "./FormElement";

const getValueFromData = (config, data) => {
  switch (config.type) {
    case "dateRangeExtend":
      return {
        fromDay: data[`${config.id}FromDay`],
        toDay: data[`${config.id}ToDay`],
        months: data[`${config.id}Months`] || [],
        startDate: data[`${config.id}StartDate`],
      };
    case "dateRange":
    case "numberRange":
      return { from: data[`${config.id}From`], to: data[`${config.id}To`] };
    case "boolean":
      return data[config.exclusiveWith] ? 0 : data[config.id];
    default:
      return data[config.id];
  }
};

const isException = (dependedValue, dataValue) => {
  const exceptionsForZeroValue = ["", null];
  return dependedValue === 0 && exceptionsForZeroValue.includes(dataValue);
};

export const shouldDisplayElementWithFormElements = (
  depends,
  data,
  displayCondition,
  formElements
) => {
  if (displayCondition === false) {
    return false;
  }

  if (!depends) {
    return true;
  }

  const { field: dependsFieldId, value: dependsRequiredValue } = depends;

  if (
    data[dependsFieldId] === dependsRequiredValue ||
    isException(dependsRequiredValue, data[dependsFieldId])
  ) {
    const dependsField = formElements.find(
      (element) => element.id === dependsFieldId
    );

    if (!dependsField?.depends) return true;

    return shouldDisplayElementWithFormElements(
      dependsField.depends,
      data,
      dependsField.displayCondition,
      formElements
    );
  }

  return false;
};

export const shouldDisplayElement = (depends, data, displayCondition) => {
  if (displayCondition === false) {
    return false;
  }
  if (!depends) {
    return true;
  }

  if (Array.isArray(depends)) {
    return !depends.find(
      ({ value, field }) =>
        data[field] !== value && !isException(value, data[field])
    );
  }
  const { field, value, functionValidation } = depends;
  if (functionValidation) {
    return functionValidation(data);
  }
  return data[field] === value || isException(value, data[field]);
};

export const isFormElementVisible = (
  depends,
  data,
  displayCondition,
  formElements
) =>
  formElements
    ? shouldDisplayElementWithFormElements(
        depends,
        data,
        displayCondition,
        formElements
      )
    : shouldDisplayElement(depends, data, displayCondition);

const formElementsMapper = (
  formElementConfig,
  key,
  data,
  validateField,
  invalidFields,
  defaultOnChange,
  setRadioFlag,
  radioFlag,
  configSchema
) => {
  const {
    component,
    onChange,
    depends,
    displayCondition,
    layout,
    layoutConfig,
    border,
    dataT1,
    dataT2,
    formElements: layoutFromElements,
    ...restConfig
  } = formElementConfig;
  if (layout) {
    return getLayout(
      layout,
      layoutFromElements
        .map((LayoutElementConfig, elKey) =>
          formElementsMapper(
            LayoutElementConfig,
            elKey,
            data,
            validateField,
            invalidFields,
            defaultOnChange
          )
        )
        .filter(Boolean),
      layoutConfig,
      key,
      border,
      dataT1,
      dataT2
    );
  }

  if (!shouldDisplayElement(depends, data, displayCondition)) {
    return null;
  }
  if (component) {
    return React.cloneElement(component, {
      key,
      ...(restConfig.validation && {
        errorMessage: invalidFields[restConfig.id],
        validate: (componentData) =>
          validateField(restConfig.id, componentData, restConfig.validation),
      }),
    });
  }
  const value = getValueFromData(formElementConfig, data);

  return (
    <FormElement
      /* eslint-disable-next-line react/no-array-index-key */
      key={key}
      value={value}
      validateField={validateField}
      errorMessage={invalidFields[restConfig.id]}
      invalidFields={invalidFields}
      onChange={onChange || defaultOnChange}
      setRadioFlag={setRadioFlag}
      radioFlag={radioFlag}
      configSchema={configSchema}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restConfig}
    />
  );
};

const defaultFormGroupClassNames = "main-card mb-3";

export default function FormGroup({
  title,
  formElements,
  data,
  defaultOnChange,
  noCards,
  tooltip,
  id,
  groupsAsColumns,
  invalidFields,
  validateField,
  className,
  configSchema,
}) {
  const [radioFlag, setRadioFlag] = useState(null);

  const processedClassName = className
    ? [defaultFormGroupClassNames, className].join(" ")
    : defaultFormGroupClassNames;

  const elements = formElements
    .filter((formElement) =>
      isFormElementVisible(
        formElement.depends,
        data,
        formElement.displayCondition,
        formElements
      )
    )
    .map((formElementConfig, key) =>
      formElementsMapper(
        formElementConfig,
        key,
        data,
        validateField,
        invalidFields,
        defaultOnChange,
        setRadioFlag,
        radioFlag,
        configSchema
      )
    );

  let Wrapper;
  switch (true) {
    case noCards:
    case groupsAsColumns:
      Wrapper = NoCardWrapper;
      break;
    default:
      Wrapper = CardWrapper;
      break;
  }

  return (
    <Wrapper
      title={title}
      tooltip={tooltip}
      id={id}
      className={processedClassName}
    >
      {elements}
    </Wrapper>
  );
}

const CardWrapper = ({ title, children, tooltip, id, className }) => {
  let tooltipComponent;
  if (tooltip) {
    tooltipComponent = (
      <Tooltip
        id={`form_group_tooltip_${id}`}
        type={tooltip.type}
        content={tooltip.content}
        placement={tooltip.placement}
      />
    );
  }
  return (
    <Card className={className}>
      {title ? (
        <CardHeader>
          {title}
          {tooltipComponent}
        </CardHeader>
      ) : null}
      <CardBody className="pt-4">{children}</CardBody>
    </Card>
  );
};

const NoCardWrapper = ({ title, children, tooltip, id, className }) => {
  let tooltipComponent;
  if (tooltip) {
    tooltipComponent = (
      <Tooltip
        id={`form_group_tooltip_${id}`}
        type={tooltip.type}
        content={tooltip.content}
        placement={tooltip.placement}
      />
    );
  }
  return (
    <div className={className}>
      {title ? (
        <CardHeader>
          {title}
          {tooltipComponent}
        </CardHeader>
      ) : null}
      <div className="pt-4">{children}</div>
    </div>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  tooltip: PropTypes.shape({
    content: PropTypes.node,
    type: PropTypes.string,
    placement: PropTypes.string,
  }),
};

NoCardWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  tooltip: PropTypes.shape({
    content: PropTypes.node,
    type: PropTypes.string,
    placement: PropTypes.string,
  }),
};

FormGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  defaultOnChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  formElements: PropTypes.array,
  id: PropTypes.number,
  noCards: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.shape({
    content: PropTypes.node,
    type: PropTypes.string,
  }),
  validateField: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  invalidFields: PropTypes.object,
  groupsAsColumns: PropTypes.bool,
  className: PropTypes.string,
  configSchema: PropTypes.arrayOf(PropTypes.shape({})),
};

CardWrapper.defaultProps = {
  children: null,
  title: "",
  tooltip: null,
  className: "",
};

NoCardWrapper.defaultProps = {
  children: null,
  title: "",
  tooltip: null,
  className: "",
};

FormGroup.defaultProps = {
  data: {},
  defaultOnChange: () => {},
  formElements: [],
  id: 0,
  noCards: false,
  title: "",
  tooltip: null,
  validateField: null,
  invalidFields: null,
  groupsAsColumns: false,
  className: "",
  configSchema: [],
};
