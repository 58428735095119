import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import SelectCreatorView from "src/Components/SelectCreatorView";
import { InputGroup } from "reactstrap";

export default function SelectCreator({
  id,
  defaultValue,
  onChange,
  errorMessage,
}) {
  const [options, setOptions] = useState(defaultValue);

  useEffect(() => onChange(options), [options]);

  const updateOption = (val, index) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = val;
      return newOptions;
    });
  };

  const addOption = () => {
    const newOptions = [...options, ""];
    setOptions(newOptions);
  };

  const removeOption = (orderIndex) => {
    const optionsCopy = [...options];
    optionsCopy.splice(orderIndex, 1);
    setOptions(optionsCopy);
  };

  const move = (index, direction) => {
    const newOptions = [...options];
    const newIndex = direction === "down" ? index + 1 : index - 1;
    newOptions[index] = options[newIndex];
    newOptions[newIndex] = options[index];

    setOptions(newOptions);
  };

  return (
    <InputGroup>
      <SelectCreatorView
        validateFields={!!errorMessage}
        id={id}
        move={move}
        options={options}
        updateOption={updateOption}
        removeOption={removeOption}
        addOption={addOption}
      />
    </InputGroup>
  );
}

SelectCreator.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SelectCreator.defaultProps = {
  defaultValue: [""],
  errorMessage: null,
};
