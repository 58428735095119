import React, { Suspense, lazy, useContext, useState, useEffect } from "react";
import { Route, Redirect, useLocation, Switch, Link } from "react-router-dom";
import SplitPane from "react-split-pane";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
// Layout
import AppHeader from "src/Layout/AppHeader";
import AppSidebar from "src/Layout/AppSidebar";
import MockApiWarning from "src/utils/Api/mockApiWarning";
import LanguageWrapper from "src/utils/Languages/languageWrapper";
import { companyCompanyPermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import {
  filterNavItemsByAlc,
  hasAccessTo,
} from "src/utils/RoleBasedSecurity/filters";
import { MainNav } from "src/Layout/AppNav/NavItems";
import {
  CompanyContextWrapper,
  useCompanyData,
} from "src/Pages/Company/CompanyContext";
import { isDevMode } from "src/config/env";
import Password from "src/Pages/Password/Edit";
import AhrCore from "src/Pages/AhrCore";
import signalRService from "src/utils/SignalR/signalRService";
import ToastStyledContainer from "src/utils/Notifications/ToastStyledContainer";
import ErrorBoundary from "./ErrorBoundary";
import NoAccessPage from "./NoAccessPage";
import DefaultFallback from "./DefaultFallback";

const isDev = isDevMode();

const Authentication = lazy(() => import("../../Pages/Authentication"));
const Company = lazy(() => import("../../Pages/Company"));
const Standards = lazy(() => import("../../Pages/Standards"));
const Tourism = lazy(() => import("../../Pages/Tourism"));
const User = lazy(() => import("../../Pages/User"));
const Agreements = lazy(() => import("../../Pages/Agreements"));
const Supplier = lazy(() => import("../../Pages/Supplier"));
const ProductCatalog = lazy(() => import("../../Pages/ProductCatalog"));
const Imports = lazy(() => import("../../Pages/Imports"));
const Benefit = lazy(() => import("../../Pages/Benefit"));
const SftpImport = lazy(() => import("../../Pages/SftpImport"));
const Cms = lazy(() => import("../../Pages/Cms"));
const ActiveForms = lazy(() => import("../../Pages/ActiveForms"));
const Administration = lazy(() => import("../../Pages/Administration"));
const Report = lazy(() => import("../../Pages/Report"));
const Translation = lazy(() => import("../../Pages/Translation"));
const Notification = lazy(() => import("../../Pages/Notification"));
const Diagnostic = lazy(() => import("../../Pages/Diagnostic"));
const Processes = lazy(() => import("../../Pages/Processes"));

const defaultSidebarWidth =
  parseInt(localStorage.getItem("splitPos"), 10) || 320;

const startupPage = "/company/list";
const Router = () => {
  const { userInfo } = useContext(RbsContext);
  const companyData = useCompanyData();
  let defaultRedirect = startupPage;
  const hasPermissionToDefault = hasAccessTo(
    userInfo,
    companyCompanyPermissionRead
  );
  if (!hasPermissionToDefault) {
    const allowedPages = filterNavItemsByAlc(MainNav, userInfo, companyData);
    if (allowedPages.length) {
      if (allowedPages[0].content) {
        defaultRedirect = allowedPages[0].content[0].to;
      } else {
        defaultRedirect = allowedPages[0].to;
      }
    }
  }
  return (
    <Suspense fallback={DefaultFallback}>
      <Switch>
        {/* Dashboards */}
        <Route path="/company" component={Company} />
        {/* Standards */}
        <Route path="/standards" component={Standards} />
        {/* Standards */}
        <Route path="/user" component={User} />
        {/* Providers */}
        <Route path="/suppliers" component={Supplier} />
        {/* ProductCatalog */}
        <Route path="/product-catalog" component={ProductCatalog} />
        {/* Imports */}
        <Route path="/imports" component={Imports} />
        {/* Benefit */}
        <Route path="/benefit" component={Benefit} />
        {/* Cms */}
        <Route path="/cms" component={Cms} />
        {/* Active Forms */}
        <Route path="/active-forms" component={ActiveForms} />
        {/* Agreements */}
        <Route path="/import-sftp" component={SftpImport} />
        {/* Agreements */}
        <Route path="/agreements" component={Agreements} />
        {/* Administration */}
        <Route path="/administration" component={Administration} />
        {/* Report */}
        <Route path="/report" component={Report} />
        {/* Report ahr */}
        <Route path="/ahr/report" component={Report} />
        {/* Tourism */}
        <Route path="/tourism" component={Tourism} />
        {/* Notifications */}
        <Route path="/notification" component={Notification} />
        {/* Translations */}
        <Route path="/translate" component={Translation} />
        {/* Diagnostic */}
        <Route path="/diagnostic" component={Diagnostic} />
        {/* Password change */}
        <Route path="/password" component={Password} />
        {/* Password change */}
        <Route path="/processes" component={Processes} />
        {/* Default Redirect */}
        <Route render={() => <Redirect to={defaultRedirect} />} />
      </Switch>
    </Suspense>
  );
};
const AhrRouter = () => (
  <Suspense fallback={DefaultFallback}>
    <Switch>
      {/* AhrRouter */}
      <Route path="/ahr" component={AhrCore} />
      {/* Default Redirect */}
      <Route render={() => <Redirect to="/ahr" />} />
      {/* Password change */}
      <Route path="/ahr/password" component={Password} />
    </Switch>
  </Suspense>
);
const AppMain = () => {
  const [sidebarWidth, setSidebarWidth] = useState(defaultSidebarWidth);
  const [companyId, setCompanyId] = useState(null);
  const location = useLocation();
  const { isLoggedIn, userInfo, logout } = useContext(RbsContext);

  useEffect(() => {
    signalRService
      .prepare()
      .then(() => {
        signalRService.attachEvents(userInfo.isAhr());
        signalRService
          .start()
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      })
      .catch(() => {});

    return () => {
      signalRService.detachEvents(isAhr);
    };
  }, [signalRService]);

  useEffect(() => {
    const regexpMatch = location.pathname.match(
      /^\/company\/edit\/([a-zA-Z0-9-]+)(\/.+)?/
    );
    setCompanyId(regexpMatch && regexpMatch[1] ? regexpMatch[1] : null);
  }, [location]);
  if (location.pathname.indexOf("/authentication/") === 0 && isDev) {
    return (
      <Suspense fallback={DefaultFallback}>
        <Route path="/authentication" component={Authentication} />
      </Suspense>
    );
  }
  if (!isLoggedIn) {
    /* FEN-14402 temporary version proxy */
    const proxyRegexOutput =
      /https:\/\/(?<env>(dv1|qa1))-(?<dv>[0-9]*).omb.mybenefit.g4n.eu/g.exec(
        window.location.href
      );
    let version = "";
    if (typeof proxyRegexOutput?.groups?.dv !== "undefined") {
      if (proxyRegexOutput?.groups?.env === "dv1") {
        version = `version=dv1-${proxyRegexOutput.groups.dv}&`;
      } else if (proxyRegexOutput?.groups?.env === "qa1") {
        version = `version=qa1-${proxyRegexOutput.groups.dv}&`;
      }
    }

    window.location = `/signin/oauth/client/login${
      location.pathname
        ? `?${version}referer_url=${encodeURIComponent(`${location.pathname}`)}`
        : ""
    }`;
    return DefaultFallback;
  }
  if (!userInfo.hasAccessToPanel()) {
    return <NoAccessPage logout={logout} />;
  }
  const isAhr = userInfo.isAhr();

  return (
    <LanguageWrapper>
      <CompanyContextWrapper
        companyId={isAhr ? userInfo.getCompanyId() : companyId}
      >
        <AppHeader />
        <div className="app-main">
          <SplitPane
            split="vertical"
            minSize={280}
            style={{ height: "inherit", overflow: "visible" }}
            resizerClassName="vertical-resizer"
            maxSize={600}
            allowResize
            defaultSize={defaultSidebarWidth}
            onChange={(size) => {
              localStorage.setItem("splitPos", size);
              setSidebarWidth(size);
            }}
          >
            <AppSidebar />
            <ErrorBoundary>
              <div
                className="app-main__outer"
                style={{
                  maxWidth: `${document.body.clientWidth - sidebarWidth}px`,
                }}
              >
                <MockApiWarning />
                <div className="app-main__inner">
                  {isAhr ? <AhrRouter /> : <Router />}
                </div>
              </div>
            </ErrorBoundary>
          </SplitPane>
        </div>
        <ToastStyledContainer />
      </CompanyContextWrapper>
    </LanguageWrapper>
  );
};
export default AppMain;
