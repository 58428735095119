import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import __ from "src/utils/Translations";

const AnchorTag = ({ href, to, id, children }) =>
  href ? (
    <a href={href} className="link-unstyled" data-t1={`${id}Tile`}>
      {children}
    </a>
  ) : (
    <Link to={to} className="link-unstyled" data-t1={`${id}Tile`}>
      {children}
    </Link>
  );

AnchorTag.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

AnchorTag.defaultProps = {
  href: "",
  to: "",
};

export default AnchorTag;
