import __ from "src/utils/Translations";

export const MAX_EXPORT_ITEMS = 150000;

export const SELECT_ALL = "selectAll";
export const SELECT_PAGE_ALL = "selectPageAll";
export const DESELECT_ALL = "deselectAll";
export const DESELECT_PAGE_ALL = "deselectPageAll";

export const placeholderSelectOption = {
  value: "",
  label: "-",
};
