import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import DatePicker from "src/Components/FormElements/DatePicker";
import DatePickerMax from "src/Components/FormElements/DatePickerMax";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";
import __ from "src/utils/Translations";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";
import MultiSelectParameter from "./multiSelectParameter";
import AdHocReport from "./adHocReport";

export default function Parameters({
  parameters,
  parametersState,
  setParametersState,
  subscription,
  setInvalidParameters,
  isAhr,
  isCreator,
  disabledParameterFunc,
  showAllSelected,
  adHocState,
  setAdHocState,
  setParametersRenderCompleted,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const [isOpenAdHoc, setIsOpenAdHoc] = useState(false);
  const toggleAdHoc = () => setIsOpenAdHoc(!isOpenAdHoc);

  const angleDirectionAdHoc = isOpenAdHoc ? "up" : "down";

  useEffect(() => {
    if (
      parametersState !== null &&
      parametersState !== undefined &&
      parametersState.length > 0
    ) {
      setParametersRenderCompleted(true);
    }
  });

  function disabledParam() {
    const isDisabled = !((isAhr && isCreator) || !isAhr);
    return false;
  }

  const generateParameters = () => {
    const paramArray = [];
    let tempArr = [];
    for (let index = 1; index <= parameters.length; index += 1) {
      tempArr.push(parameters[index - 1]);

      if (index % 4 === 0 || index === parameters.length) {
        paramArray.push(tempArr);
        tempArr = [];
      }
    }
    return paramArray.map((paramItem) => (
      <div key={paramItem[0].parameterName} className="row">
        {paramItem.map((item) => (
          <div key={item.parameterName} className="col-md-3 col-sm-12">
            {renderParameter(item)}
          </div>
        ))}
      </div>
    ));
  };

  const updateParameterState = (newValue, paramIndex) => {
    let newVal = newValue;
    if (newVal instanceof Date) {
      const hoursDiff = newVal.getHours() - newVal.getTimezoneOffset() / 60;
      const minutesDiff = newVal.getHours() - newVal.getTimezoneOffset() / 60;
      newVal.setHours(hoursDiff);
      newVal.setMinutes(minutesDiff);
    }
    if (newVal === "[]") {
      newVal = null;
    }

    const arr = [...parametersState];
    arr[paramIndex].values = [newVal];
    setParametersState(arr);
  };

  const updateParameterStateMultiSelect = (newValue, paramIndex) => {
    const multiArr = [];

    newValue.map((item) =>
      multiArr.push({ label: item.label, value: item.value })
    );

    const arr = [...parametersState];
    arr[paramIndex].values = multiArr;
    setParametersState(arr);
  };

  const updateParameterStateSelect = (newValue, paramIndex) => {
    const arr = [...parametersState];
    arr[paramIndex].values = [{ label: newValue.label, value: newValue.value }];
    setParametersState(arr);
  };

  function disabledParameter(nameParam) {
    if (disabledParam() === true) {
      return true;
    }
    return disabledParameterFunc(nameParam);
  }

  const renderLabel = (item, withWrapper = true) => {
    const content = (
      <>
        {__(item.label)}
        {item.tooltipMessage && (
          <span className="ml-1">
            <DefaultTooltip
              id={`${item.parameterName}-tooltip`}
              content={__(item.tooltipMessage)}
            />
          </span>
        )}
      </>
    );

    return withWrapper ? (
      <label htmlFor={item.parameterName}>{content}</label>
    ) : (
      content
    );
  };

  const renderParameter = (item) => {
    const paramIndex = parametersState.findIndex(
      (x) => x.parameterName === item.parameterName
    );
    if (parametersState.length > 0) {
      switch (item.type) {
        case "DateTime": {
          let dateValue = parametersState[paramIndex].values[0];
          let nvalidClass = "";

          if (typeof dateValue === "undefined") {
            dateValue = "";
          }

          // validation
          if (!dateValue && item.isRequired) {
            nvalidClass = " ";
            setInvalidParameters(item.parameterName, true);
          } else {
            setInvalidParameters(item.parameterName, false);
          }

          const minDate = item.minDate
            ? new Date(Date.parse(item.minDate))
            : undefined;
          let maxDate = new Date();
          if (item.hasOwnProperty("maxDate") && item.maxDate) {
            maxDate = new Date(Date.parse(item.maxDate));
          }

          return (
            <DatePickerMax
              errorMessage={nvalidClass}
              label={renderLabel(item, false)}
              value={dateValue}
              onChange={(newValue) =>
                updateParameterState(newValue, paramIndex)
              }
              validateField={() => {}}
              disabled={disabledParameter(item.parameterName)}
              dateFormat="yyyy-MM-dd"
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        }
        case "Select": {
          const value = parametersState[paramIndex].values ?? null;
          let nvalidClass = "";

          // validation
          if (!value && item.isRequired) {
            nvalidClass = "non-valid-parameter";
            setInvalidParameters(item.parameterName, true);
          } else {
            setInvalidParameters(item.parameterName, false);
          }
          item.selectOptions.forEach((d) => {
            // eslint-disable-next-line no-param-reassign
            d.label = __(d.label);
          });
          return (
            <>
              {renderLabel(item)}
              <Select
                defaultValue={{
                  label: item.label,
                  value: value ? value[0] : value,
                }}
                name="select-params"
                className={`basic-select ${nvalidClass}`}
                classNamePrefix="select"
                options={item.selectOptions}
                onChange={(e) => {
                  updateParameterStateSelect(e, paramIndex);
                }}
                value={value}
                isDisabled={disabledParameter(item.parameterName)}
              />
            </>
          );
        }
        case "Multiselect": {
          const defaultValue = parametersState[paramIndex].values ?? [];
          let nvalidClass = "";
          item.selectOptions.forEach((d) => {
            // eslint-disable-next-line no-param-reassign
            d.label = __(d.label);
          });
          // validation
          if (defaultValue.length === 0 && item.isRequired) {
            nvalidClass = "non-valid-parameter";
            setInvalidParameters(item.parameterName, true);
          } else {
            setInvalidParameters(item.parameterName, false);
          }

          return (
            <>
              {renderLabel(item)}
              <MultiSelectParameter
                isMulti
                name="multiselect-params"
                className={`basic-multi-select ${nvalidClass}`}
                classNamePrefix="select"
                options={item.selectOptions}
                allowSelectAll
                hideSelectedOptions={false}
                onChange={(i) => updateParameterStateMultiSelect(i, paramIndex)}
                value={defaultValue}
                isRequired={item.isRequired}
                disabledParam={disabledParameter(item.parameterName)}
                showAllSelected={showAllSelected}
              />
            </>
          );
        }
        case "Boolean": {
          let boolValue = parametersState[paramIndex].values[0];

          if (typeof boolValue === "undefined") {
            boolValue = false;
          }

          if (typeof boolValue !== "boolean") {
            if (typeof boolValue !== "undefined")
              boolValue = boolValue === "true";
          }

          return (
            <>
              {renderLabel(item)}
              <div className="d-block w-100 text-center">
                <ToggleSwitch
                  id={item.parameterName}
                  handleChange={(isOn) =>
                    updateParameterState(isOn, paramIndex)
                  }
                  checked={boolValue}
                  disabled={disabledParameter(item.parameterName)}
                />
              </div>
            </>
          );
        }
        case "String":
        default: {
          const val = parametersState[paramIndex].values[0] ?? "";
          let nvalidClass = "";

          // validation
          if (val === "" && item.isRequired) {
            nvalidClass = "non-valid-parameter";
            setInvalidParameters(item.parameterName, true);
          } else {
            setInvalidParameters(item.parameterName, false);
          }

          return (
            <>
              {renderLabel(item)}
              <input
                id={item.parameterName}
                type="text"
                className={`form-control ${nvalidClass}`}
                value={val}
                onChange={(e) =>
                  updateParameterState(e.target.value, paramIndex)
                }
                disabled={disabledParameter(item.parameterName)}
              />
            </>
          );
        }
      }
    }
    return "";
  };

  const renderParemeters = () => (
    <div>
      <form>{generateParameters()}</form>
    </div>
  );

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle>
            <div className="row">
              <div className="col-md-12" style={{ marginTop: "15px" }}>
                {__("Parametry")}
              </div>
            </div>
          </CardTitle>
          {renderParemeters()}
          {adHocState && (
            <>
              <hr />
              <CardTitle
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={toggleAdHoc}
              >
                <div className="row">
                  <div className="col-md-10" style={{ marginTop: "15px" }}>
                    {__("Raport personalizowany")}
                  </div>
                  <div className="col-md-2 text-right">
                    <Button color="link">
                      <i
                        className={`pe-7s-angle-${angleDirectionAdHoc} pe-3x`}
                      />
                    </Button>
                  </div>
                </div>
              </CardTitle>
            </>
          )}
          {isOpenAdHoc && (
            <div>
              <AdHocReport
                adHocState={adHocState}
                setAdHocState={setAdHocState}
              />
            </div>
          )}
          <hr />
          <CardTitle style={{ userSelect: "none" }} onClick={toggle}>
            {__("Wysyłka raportu")}
          </CardTitle>
          {subscription}
        </CardBody>
      </Card>
    </>
  );
}

Parameters.propTypes = {
  parameters: PropTypes.array.isRequired,
  parametersState: PropTypes.array.isRequired,
  setParametersState: PropTypes.func.isRequired,
  subscription: PropTypes.node.isRequired,
  setInvalidParameters: PropTypes.func.isRequired,
  isAhr: PropTypes.bool.isRequired,
  isCreator: PropTypes.bool.isRequired,
  disabledParameterFunc: PropTypes.func.isRequired,
  showAllSelected: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  adHocState: PropTypes.object.isRequired,
  setAdHocState: PropTypes.func.isRequired,
  setParametersRenderCompleted: PropTypes.func.isRequired,
};

Parameters.defaultProps = {
  showAllSelected: false,
};
