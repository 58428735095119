import {
  CONFIGURATION_SERVICE,
  EMPLOYEE_MANAGEMENT_SERVICE,
  restApiRequest,
} from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { getIdFromIri } from "src/utils/jsHelpers/iriConverter";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { parseDataFromBackend } from "src/Pages/Administration/Configuration/utils";

export const fetchRentableGroups = async (
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false
) => {
  const params = {
    itemsPerPage: 10000,
  };
  if (filterField && filterValue) {
    params[filterField] = filterValue;
  }
  try {
    const resData = await restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      "/rentable-groups",
      "GET",
      { params },
      mockData
    );
    let result = resData;
    if (idAsIri) {
      result = result.map((el) => ({ ...el, id: `${IRI_PREFIX}/${el.id}` }));
    }
    if (asOption) {
      result = result.map(({ frontendName, id }) => ({
        value: id,
        label: frontendName,
      }));
    }
    return result;
  } catch (e) {
    newRelicErrorReport(e, "utils/Company/index.js - 39");
    dynamicNotification(
      e.message || __("Nie udało się pobrać grup dochodowości"),
      "error"
    );
    return null;
  }
};

export const fetchRentableGroupsByEmployeeGroupIri = (employeeGroupId) =>
  fetchRentableGroups(
    true,
    "employeeGroups",
    getIdFromIri(employeeGroupId, IRI_PREFIX)
  );

export const getIdFromIriRentableGroup = (iri) => getIdFromIri(iri, IRI_PREFIX);

export const fetchOverriddenLabels = async (companyId, isAhr) => {
  try {
    const res = await restApiRequest(
      CONFIGURATION_SERVICE,
      isAhr ? "/get-my-config" : "/get-config-records",
      "GET",
      {
        params: {
          companyId,
        },
      },
      []
    );

    if (res) {
      const parsedRes = isAhr ? res : parseDataFromBackend(res);
      const overriddenLabels = {
        costPlace: parsedRes[overriddenLabelsPaths.costPlace],
        organizationUnit: parsedRes[overriddenLabelsPaths.organizationUnit],
        workPlace: parsedRes[overriddenLabelsPaths.workPlace],
      };
      return overriddenLabels;
    }
    return null;
  } catch (e) {
    newRelicErrorReport(e, "utils/Company/index.js - 82");
    dynamicNotification(
      e.message || __("Nie udało się pobrać konfiguracji"),
      "error"
    );
    return null;
  }
};

export const overriddenLabelsPaths = {
  costPlace: "overridden-labels/company/cost-place",
  organizationUnit: "overridden-labels/company/organization-unit",
  workPlace: "overridden-labels/company/work-place",
};

export const IRI_PREFIX = "/api/employee-management/v1/rest/rentable-groups";

export const mockData = [
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    frontendName: "Grupa I (do 2500 PLN)",
    employeeGroups: ["a43275e4-eeb2-11ea-adc1-0242ac1200021"],
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200022",
    frontendName: "Grupa II (od 2500 PLN do 4000 PLN)",
    employeeGroups: [
      "a43275e4-eeb2-11ea-adc1-0242ac1200021",
      "a43275e4-eeb2-11ea-adc1-0242ac1200022",
    ],
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200023",
    frontendName: "Grupa III (od 40000 PLN do 5500 PLN)",
    employeeGroups: ["a43275e4-eeb2-11ea-adc1-0242ac1200023"],
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200024",
    frontendName: "Grupa IV (powyżej 5500 PLN)",
    employeeGroups: [],
  },
];
