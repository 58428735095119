import {
  CONTENT_SECTION_TYPE,
  FORM_SECTION_TYPE,
  HEADER_SECTION_TYPE,
} from "src/Pages/ActiveForms/Edit/utils/consts";
import __ from "src/utils/Translations";
import validation from "src/utils/Validation";

export const requireErrorMessage = "To pole jest wymagane";

export default function validate(data) {
  const errors = {};
  data.forEach(({ type, id, ...sectionData }) => {
    let sectionErrors = null;
    if (type === HEADER_SECTION_TYPE) {
      sectionErrors = validateHeader(sectionData);
    } else if (type === FORM_SECTION_TYPE) {
      sectionErrors = validateForm(sectionData);
    } else if (type === CONTENT_SECTION_TYPE) {
      sectionErrors = validateContent(sectionData);
    }
    if (sectionErrors) {
      errors[id] = sectionErrors;
    }
  });
  return Object.keys(errors).length > 0 ? errors : null;
}

const validateHeader = ({ value }) => {
  const valueError = validation(value, validators.header.value);
  if (valueError) {
    return { value: valueError };
  }
  return null;
};

const validateContent = ({ name, content }) => {
  const result = {};
  const nameError = validation(name, validators.content.name);
  if (nameError) {
    result.name = nameError;
  }
  const contentError = validation(content, validators.content.content);
  if (contentError) {
    result.content = contentError;
  }

  return Object.keys(result).length > 0 ? result : null;
};

const isSelectLabelValid = (label) => {
  if (typeof label !== "object") return !!label;

  const isLabelEmpty = !label || !label?.title;
  const areOptionsEmpty = !label?.options?.length;

  if (isLabelEmpty || areOptionsEmpty) return false;

  const areAllOptionsValid = label.options.every(
    (option) => option.label && option.value
  );

  return areAllOptionsValid;
};

const validateForm = ({ fields }) => {
  const result = {};
  if (Array.isArray(fields) && fields.length > 0) {
    fields.forEach(({ id, label, tooltip, type }) => {
      if (type === "requirePesel") return;

      if (type === "select" && !isSelectLabelValid(label)) {
        if (!result.field) {
          result.field = {};
        }

        result.field[id] = {
          label: __(requireErrorMessage),
        };
        return;
      }

      if (!label) {
        if (!result.field) {
          result.field = {};
        }
        result.field[id] = {
          label: __(requireErrorMessage),
        };
      }
      const fieldErrors = {};
      const labelError = validation(label, validators.fields.field.label);
      if (labelError) {
        fieldErrors.label = labelError;
      }
      const tooltipError = validation(tooltip, validators.fields.field.tooltip);
      if (tooltipError) {
        fieldErrors.tooltip = tooltipError;
      }
      if (Object.keys(fieldErrors).length > 0) {
        if (!result.field) {
          result.field = {};
        }
        result.field[id] = fieldErrors;
      }
    });
  } else {
    result.fields = __("Sekcja nie może być pusta");
  }
  return Object.keys(result).length > 0 ? result : null;
};

export const getErrorForField = (errors, sectionId, field) => {
  if (!errors) {
    return null;
  }
  return errors && errors[sectionId] && errors[sectionId][field]
    ? errors[sectionId][field]
    : null;
};
export const getErrorForFormField = (errors, sectionId, fieldId, field) => {
  if (!errors) {
    return null;
  }
  return errors &&
    errors[sectionId] &&
    errors[sectionId].field &&
    errors[sectionId].field[fieldId] &&
    errors[sectionId].field[fieldId][field]
    ? errors[sectionId].field[fieldId][field]
    : null;
};

export const validators = {
  header: {
    value: ["required", { args: [255], method: "maxLength" }],
  },
  content: {
    name: ["required", { args: [255], method: "maxLength" }],
    content: ["required"],
  },
  fields: {
    fields: ["required"],
    field: {
      label: ["required", { args: [255], method: "maxLength" }],
      tooltip: [{ args: [255], method: "maxLength" }],
    },
  },
};
