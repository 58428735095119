import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Loader } from "react-loaders";
import DataTableControlled from "src/Components/DataTableControlled";
import {
  getDateCell,
  priceColumnEmptyIfNull,
} from "src/Components/DataTable/commonCells";
import { useCompanyOverriddenLabels } from "src/Pages/Company/CompanyContext";
import { parseDataToTable } from "./utils";
import { useSelectedRows } from "./SelectedProvider";
import RowSelectionColumnHeader from "./rowSelectionColumnHeader";
import RowSelectionCell from "./rowSelectionCell";
import ActionsColumn from "./actionsColumn";
import BenefitNameColumn from "./benefitNameColumn";
import { LOADING_FALLBACK_VALUE } from "./consts";

export default function PendingMultiSportBenefitsTable({
  apiRef,
  subscriptionsData,
  totalElements,
  fetchData,
  onRemoveItem,
}) {
  const [tableData, setTableData] = useState([]);
  const [defaultSorted, setDefaultSorted] = useState([]);
  const { organizationUnit } = useCompanyOverriddenLabels();
  const { selectedRows } = useSelectedRows();

  useEffect(() => {
    setTableData(parseDataToTable(subscriptionsData, selectedRows));
  }, [selectedRows, subscriptionsData]);

  return (
    <>
      <DataTableControlled
        id="pendingBenefitsMultisportListing"
        apiRef={apiRef}
        count={totalElements}
        data={tableData}
        fetchData={fetchData}
        sortable={false}
        filterable={false}
        defaultSorted={defaultSorted}
        setDefaultSorted={setDefaultSorted}
        columns={[
          {
            Header: (columnInfo) => (
              <RowSelectionColumnHeader data={columnInfo.data} />
            ),
            additionalHeader: true,
            accessor: "rowSelected",
            sortable: false,
            width: 50,
            Cell: (cellInfo) => <RowSelectionCell cellInfo={cellInfo} />,
          },
          {
            Header: "Numer FK",
            accessor: "fk",
            sortable: false,
            Cell: lazyLoadingColumn,
          },
          {
            Header: "Imię",
            accessor: "firstName",
            sortable: false,
            Cell: lazyLoadingColumn,
          },
          {
            Header: "Nazwisko",
            accessor: "lastName",
            sortable: false,
            Cell: lazyLoadingColumn,
          },
          {
            Header: organizationUnit || "Jednostka Organizacyjna",
            accessor: "organizationUnit",
            sortable: false,
            Cell: lazyLoadingColumn,
          },
          {
            Header: "Benefit",
            accessor: "benefitName",
            sortable: false,
            Cell: BenefitNameColumn,
          },
          {
            Header: "Łączny koszt",
            accessor: "totalCost",
            sortable: false,
            Cell: priceColumnEmptyIfNull,
          },
          {
            Header: "Opłacone przez pracownika",
            accessor: "paidByEmployee",
            sortable: false,
            Cell: priceColumnEmptyIfNull,
          },
          {
            Header: "Data wyboru",
            accessor: "createdAt",
            sortable: false,
            Cell: getDateCell("createdAt"),
          },
          {
            Header: "Akcja",
            accessor: "action",
            filterable: false,
            sortable: false,
            onAcceptConditions: onRemoveItem,
            onCancelSubscription: onRemoveItem,
            Cell: ActionsColumn,
          },
        ]}
      />
    </>
  );
}

const lazyLoadingColumn = (cellInfo) => {
  const value = cellInfo.row[cellInfo.column.id];

  return (
    <div className="d-block w-100 text-center">
      {value === LOADING_FALLBACK_VALUE ? (
        <Loader active type="line-scale" style={{ transform: "scale(0.4)" }} />
      ) : (
        value
      )}
    </div>
  );
};

PendingMultiSportBenefitsTable.propTypes = {
  apiRef: PropTypes.shape({ current: PropTypes.shape() }),
  subscriptionsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  totalElements: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
};

PendingMultiSportBenefitsTable.defaultProps = {
  apiRef: {},
};
