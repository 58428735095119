/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Form from "src/Components/Form";
import DataTable from "src/Components/DataTable";
import BenefitNameColumn from "src/Pages/Company/Pending/BenefitsPending/Listing/Table/benefitNameColumn";
import ActionColumn from "src/Components/DataTable/actionColumn";
import { getDateCell } from "src/Components/DataTable/commonCells";
import {
  STATUS_CANCELED,
  STATUS_RESIGNED,
  STATUS_PENDING,
  POPUP_TYPE_CHANGE_DATA,
  POPUP_TYPE_CHANGE,
  POPUP_TYPE_RESIGN,
  hasFormsWithNames,
  prepareDataNameColumn,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import {
  subscriptionEmployeeSubscriptionItemUpdatePersonalData,
  subscriptionEmployeeSubscriptionItemChangeDates,
  subscriptionEmployeeSubscriptionItemUpdateForm,
  subscriptionEmployeeSubscriptionItemResign,
} from "src/utils/RoleBasedSecurity/permissions";

const ShowPersons = ({ subscription, close, openPopup }) => {
  const { allReceiversFromSubscriptionItem } = subscription || {};
  const rbsContext = useContext(RbsContext);

  const hasResignedPermissions = useHasPermission(
    subscriptionEmployeeSubscriptionItemResign
  );
  const isAhr = rbsContext.userInfo.isAhr();

  const prepFilteredData = allReceiversFromSubscriptionItem?.filter(
    (person) =>
      person.status !== STATUS_CANCELED && person.status !== STATUS_RESIGNED
  );

  const prepPersonsData = prepareDataNameColumn(prepFilteredData);

  const Table = (
    <DataTable
      id="showPersonsListing"
      showPagination={false}
      data={prepPersonsData}
      defaultSorted={[{ id: "startsAt", desc: true }]}
      columns={[
        {
          Header: "Imię i nazwisko osoby",
          accessor: "receiverFullName",
          Cell: BenefitNameColumn,
        },
        {
          Header: "Data startu",
          accessor: "startsAt",
          Cell: getDateCell("startsAt", false, true),
        },
        {
          Header: "Akcja",
          accessor: "action",
          filterable: false,
          sortable: false,
          minWidth: 150,
          Cell: (cell) => {
            const currentPrepPersonData = prepPersonsData.find(
              (person) => person.id === cell.original.id
            );
            const hasAnyFormsWithNames = hasFormsWithNames(
              currentPrepPersonData
            );
            const personHasPendingStatus =
              cell.row._original.status === STATUS_PENDING;
            const personHasStartDate = cell.row._original.startsAt;
            return (
              <div className="w-100">
                <ActionColumn
                  data={subscription}
                  buttons={[
                    {
                      id: "changeData",
                      permission:
                        subscriptionEmployeeSubscriptionItemUpdatePersonalData,
                      onClick: () =>
                        openPopup({
                          type: POPUP_TYPE_CHANGE_DATA,
                          id: cell.original.id,
                        }),
                      label: "Zmień dane",
                      disabled: !hasAnyFormsWithNames || !openPopup,
                      hideIfNoPermission: true,
                    },
                    {
                      id: "change",
                      permission:
                        subscriptionEmployeeSubscriptionItemChangeDates,
                      onClick: () =>
                        openPopup({
                          type: POPUP_TYPE_CHANGE,
                          id: cell.original.id,
                        }),
                      label: "Zmień daty",
                      disabled:
                        personHasPendingStatus ||
                        !personHasStartDate ||
                        !subscriptionEmployeeSubscriptionItemUpdateForm,
                      doNotDisplay: isAhr,
                    },
                    // Resign action is available only for AHR with the same logic and validations as "resign" from activeSubscriptions table
                    {
                      id: "resign",
                      permission: subscriptionEmployeeSubscriptionItemResign,
                      onClick: () =>
                        openPopup({
                          type: POPUP_TYPE_RESIGN,
                          id: cell.original.id,
                        }),
                      label: "Rezygnuj",
                      disabled: personHasPendingStatus || !personHasStartDate,
                      doNotDisplay: !hasResignedPermissions || !isAhr,
                    },
                  ]}
                />
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <Form
      id="cancelSubscriptionForm"
      data={{}}
      config={{
        isInPopup: true,
        togglePopup: () => close(),
        title: `${"Dane osób w świadczeniu"} ${subscription.benefitName}`,
        buttons: [
          {
            onClick: () => close(),
            text: "Zamknij",
            color: "light",
            id: "resetOneTimeFormClose",
          },
        ],
        defaultOnChange: () => {},
        noCards: true,
        formGroups: [
          {
            formElements: [
              {
                component: Table,
              },
            ],
          },
        ],
      }}
    />
  );
};

ShowPersons.propTypes = {
  close: PropTypes.func.isRequired,
  openPopup: PropTypes.func,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    benefitName: PropTypes.string,
    allReceiversFromSubscriptionItem: [
      PropTypes.shape({
        startsAt: PropTypes.string,
        fields: [],
      }),
    ],
  }).isRequired,
};

ShowPersons.defaultProps = {
  openPopup: false,
};

export default ShowPersons;
