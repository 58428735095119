export const godPermission = "all"; // for mocks and admin admin

export const employeeAhrRolePermissionRead = "employee:ahrRole:read";
export const employeeAhrRolePermissionWrite = "employee:ahrRole:write";
export const employeeEmployeePermissionRead = "employee:employee:read";
export const employeeEmployeePermissionWrite = "employee:employee:write";
export const employeeDepartmentPermissionWrite = "employee:department:write";
export const employeeAccountPermissionWrite = "employee:account:write";
export const employeeEmployeeGroupPermissionRead =
  "employee:employeeGroup:read";
export const employeeEmployeeGroupPermissionWrite =
  "employee:employeeGroup:write";
export const employeeEmployeeLeavePermissionRead =
  "employee:employeeLeave:read";
export const employeeEmployeeLeavePermissionWrite =
  "employee:employeeLeave:write";
export const employeeOrganizationUnitPermissionRead =
  "employee:organizationUnit:read";
export const employeeOrganizationUnitPermissionWrite =
  "employee:organizationUnit:write";
export const employeeRentableGroupPermissionRead =
  "employee:rentableGroup:read";
export const employeeRentableGroupPermissionWrite =
  "employee:rentableGroup:write";
export const employeeRentableGroupSelectionWindowPermissionRead =
  "employee:rentableGroupSelectionWindow:read";
export const employeeRentableGroupSelectionWindowPermissionWrite =
  "employee:rentableGroupSelectionWindow:write";
export const employeeEmployeePermissionClearRentableGroup =
  "employee:employee:clear-rentable-group";
export const ssoIpAddressRestrictionPermissionRead =
  "sso:ipAddressRestriction:read";
export const ssoIpAddressRestrictionPermissionWrite =
  "sso:ipAddressRestriction:write";
export const ombTwoFactorLoginReset = "sso:omb:twoFactorLoginReset";
export const ssoCanSwitchUser = "sso:canSwitchUser";
export const ssoPasswordChangeWrite = "sso:passwordChange:write";
export const companyApplicationPermissionRead = "company:application:read";
export const companyApplicationPermissionWrite = "company:application:write";
export const companyAttachmentPermissionRead = "company:attachment:read";
export const companyAttachmentPermissionWrite = "company:attachment:write";
export const companyCompanyPermissionRead = "company:company:read";
export const companyCompanyPermissionWrite = "company:company:write";
export const companyTokenPermissionRead = "company:token:read";
export const companyTokenPermissionWrite = "company:token:write";
export const companyReportAccessPasswordRead =
  "company:reportAccessPassword:read";
export const companyMediaPermissionWrite = "company:mediaObject:write";
export const operatorOperatorPermissionRead = "operator:operator:read";
export const operatorOperatorPermissionWrite = "operator:operator:write";
export const operatorDiagnosticPermissionRead = "operator:diagnostic:read";
export const tourismTourismObjectPermissionRead = "tourism:tourismObject:read";
export const tourismTourismObjectPermissionWrite =
  "tourism:tourismObject:write";
export const tourismTourismObjectPermissionImport =
  "tourism:tourismObject:import";
export const tourismTourismPicturePermissionWrite =
  "tourism:tourismPicture:write";
export const tourismTourismAttributePermissionRead =
  "tourism:tourismAttribute:read";
export const tourismTourismAttributePermissionDelete =
  "tourism:tourismAttribute:delete";
export const tourismTourismAttributePermissionWrite =
  "tourism:tourismAttribute:write";
export const tourismTourismAttributeOptionPermissionRead =
  "tourism:tourismAttributeOption:read";
export const tourismTourismAttributeOptionPermissionWrite =
  "tourism:tourismAttributeOption:write";
export const tourismTourismObjectChangelogPermissionRead =
  "tourism:tourismObjectChangelog:read";
export const tourismTourismObjectImportAnixePermissionRead =
  "tourism:tourismObjectImportAnixe:read";
export const tourismMediaPermissionWrite = "tourism:mediaObject:write";
export const tourismAttachmentPermissionRead = "tourism:attachment:read";
export const tourismAttachmentPermissionWrite = "tourism:attachment:write";
export const cmsDocumentPermissionRead = "cms:document:read";
export const cmsDocumentPermissionWrite = "cms:document:write";
export const translationTranslatePermissionWrite =
  "translation:translation:write";
export const translationTranslationScopePermissionRead =
  "translation:translationScope:read";
export const translationTranslationScopePermissionWrite =
  "translation:translationScope:write";
export const configurationConfigurationPermissionRead =
  "configuration:configuration:read";
export const configurationConfigurationPermissionWrite =
  "configuration:configuration:write";
export const configurationMediaWrite = "configuration:mediaObject:write";
export const notificationEmailPermissionWrite = "notification:email:write";
export const notificationEmailPermissionRead = "notification:email:read";
export const notificationPushMessagePermissionWrite =
  "notification:pushMessage:write";
export const notificationPushMessagePermissionRead =
  "notification:pushMessage:read";
export const notificationAppMessagePermissionWrite =
  "notification:appMessage:write";
export const notificationAppMessagePermissionRead =
  "notification:appMessage:read";
export const notificationSmsPermissionCreate = "notification:sms:create";
export const notificationSmsPermissionRead = "notification:sms:read";
export const notificationMediaPermissionWrite =
  "notification:mediaObject:write";
export const catalogProductPermissionRead = "catalogManagement:product:read";
export const catalogProductPermissionWrite = "catalogManagement:product:write";
export const catalogProductAttachmentPermissionRead =
  "catalogManagement:attachment:read";
export const catalogProductAttachmentPermissionWrite =
  "catalogManagement:attachment:write";
export const catalogProductAttachmentFilePermissionRead =
  "catalogManagement:attachmentFile:read";
export const catalogProductAttachmentFilePermissionWrite =
  "catalogManagement:attachmentFile:write";
export const catalogProductCodeTemplatePermissionRead =
  "catalogManagement:codeTemplate:read";
export const catalogProductCodeTemplatePermissionWrite =
  "catalogManagement:codeTemplate:write";
export const catalogCategoryPermissionRead =
  "catalogManagement:businessCategory:read";
export const catalogCategoryPermissionWrite =
  "catalogManagement:businessCategory:write";
export const catalogMediaPermissionWrite =
  "catalogManagement:mediaObject:write";
export const dictionaryDictionaryPermissionRead = "dictionary:dictionary:read";
export const dictionaryDictionaryPermissionWrite =
  "dictionary:dictionary:write";
export const dictionaryDictionaryItemsImportWrite =
  "dictionary:dictionaryItemsImport:write";
export const agreementAgreementPermissionRead = "agreement:agreement:read";
export const agreementAgreementPermissionWrite = "agreement:agreement:write";
export const agreementMediaPermissionWrite = "agreement:mediaObject:write";
export const subscriptionBenefitCategoryPermissionRead =
  "subscriptionManagement:benefitCategory:read";
export const subscriptionBenefitCategoryPermissionWrite =
  "subscriptionManagement:benefitCategory:write";
export const subscriptionBenefitPermissionRead =
  "subscriptionManagement:benefit:read";
export const subscriptionBenefitPermissionWrite =
  "subscriptionManagement:benefit:write";
export const subscriptionBenefitGroupPermissionRead =
  "subscriptionManagement:benefitGroup:read";
export const subscriptionBenefitGroupPermissionWrite =
  "subscriptionManagement:benefitGroup:write";
export const subscriptionBenefitGroupAttachmentPermissionWrite =
  "subscriptionManagement:benefitGroupAttachment:write";
export const subscriptionBenefitGroupAttachmentPermissionDelete =
  "subscriptionManagement:benefitGroupAttachment:delete";
export const subscriptionActiveFormPermissionRead =
  "subscriptionManagement:activeForm:read";
export const subscriptionActiveFormGroupPermissionWrite =
  "subscriptionManagement:activeForm:write";
export const subscriptionAttachmentPermissionRead =
  "subscriptionManagement:attachment:read";
export const subscriptionAttachmentPermissionWrite =
  "subscriptionManagement:attachment:write";
export const subscriptionEmployeeSubscriptionItemRead =
  "subscriptionManagement:employeeSubscriptionItem:read";
export const subscriptionEmployeeSubscriptionItemBlockade =
  "subscriptionManagement:employeeSubscriptionItem:blockade";
export const subscriptionEmployeeSubscriptionItemChangeDates =
  "subscriptionManagement:employeeSubscriptionItem:changeDates";
export const subscriptionEmployeeSubscriptionItemResign =
  "subscriptionManagement:employeeSubscriptionItem:resign";
export const subscriptionEmployeeSubscriptionItemCancel =
  "subscriptionManagement:employeeSubscriptionItem:cancel";
export const subscriptionEmployeeSubscriptionItemCancelAhr =
  "subscriptionManagement:employeeSubscriptionItem:cancelAhr";
export const subscriptionEmployeeSubscriptionItemAccept =
  "subscriptionManagement:employeeSubscriptionItem:accept";
export const subscriptionEmployeeSubscriptionItemUpdateForm =
  "subscriptionManagement:employeeSubscriptionItem:updateForm";
export const subscriptionEmployeeSubscriptionItemUpdatePersonalData =
  "subscriptionManagement:employeeSubscriptionItem:updatePersonalData";
export const subscriptionEmployeeSubscriptionItemGeneratePdf =
  "subscriptionManagement:employeeSubscriptionItem:generatePdf";
export const subscriptionPdfFormPermissionRead =
  "subscriptionManagement:pdfForm:read";
export const subscriptionPdfFormPermissionWrite =
  "subscriptionManagement:pdfForm:write";
export const subscriptionBenefitEmployeeGroupPermissionRead =
  "subscriptionManagement:benefitEmployeeGroup:read";
export const subscriptionBenefitEmployeeGroupPermissionWrite =
  "subscriptionManagement:benefitEmployeeGroup:write";
export const subscriptionMediaPermissionWrite =
  "subscriptionManagement:mediaObject:write";
export const subscriptionManagementConfigurationStatusWrite =
  "subscriptionManagement:configurationStatus:write";
export const banksBanksPermissionRead = "company:pointsBank:read";
export const banksBanksPermissionWrite = "company:pointsBank:write";
export const employeeGroupProductConfigPermissionRead =
  "employeeGroupProduct:employeeGroupProductData:read";
export const employeeGroupProductConfigPermissionWrite =
  "employeeGroupProduct:employeeGroupProductData:write";
export const employeeGroupProductTemplatePermissionWrite =
  "employeeGroupProduct:egpTemplate:write";
export const magentoAdminOrderPermissionRead = "magentoAdmin:order:read";
export const magentoAdminOrderPermissionTransactionUpdate =
  "magentoAdmin:orderTransaction:update";
export const magentoAdminOrderCodePermissionRead = "magentoAdmin:code:read";
export const magentoECommercePermissionAccess = "e-commerce:magento";
export const employeeMultisportCardAdministrator =
  "employee:multisportCardAdministrator";
export const companyMultisportCardWaitingRoom =
  "company:multisportCardWaitingRoom";
export const companyAccessToSportsCards = "company:accessToSportsCards";
export const companyMyBenefitActiveRead = "company:myBenefitActive:read";
export const companyPrepaidCardRead = "company:prepaidCard:read";
export const companyPrepaidCardWrite = "company:prepaidCard:write";
export const cyclicChargesPermissionWrite = "employee:accountCharges:write";
export const proposalsModulePermissionRead = "proposals:proposals:read";

export const totalRewardModule = "totalRewardStatement:module:read";
export const totalRewardSettings = "totalRewardStatement:settings:write";
export const totalRewardPerkUsersImportExport =
  "totalRewardStatement:perkUser:importexport";
export const totalRewardPerkUsersRead = "totalRewardStatement:perkUser:read";
export const totalRewardPerkUsersWrite = "totalRewardStatement:perkUser:write";
export const totalRewardPerksRead = "totalRewardStatement:perk:read";
export const totalRewardPerksWrite = "totalRewardStatement:perk:write";
export const totalRewardPerkCategoriesRead =
  "totalRewardStatement:perkCategory:read";
export const totalRewardPerkCategoriesWrite =
  "totalRewardStatement:perkCategory:write";

// BELOW REPORTS PERMISSIONS
export const reportsAhrGraphs = "chart:chart001:read";

// BELOW ARE NOT CONFIRMED PERMISSIONS (WAITING FOR BACKEND
export const companyFundingPermissionRead = "company:funding:read";
export const companyFundingPermissionWrite = "company:funding:write";
export const jobManagerProcessRead = "jobManager:process:read";
export const jobManagerProcessOutputRead = "jobManager:processOutput:read";

export const employeeSftpImportRead = "sftp:employeeSFTPImport:read";
export const employeeSftpImportWrite = "sftp:employeeSFTPImport:write";
