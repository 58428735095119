import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  addCondition,
  findSelectedRow,
  isEveryConditionsSelected,
  prepareConditionIds,
  removeCondition,
  removeSelectedRow,
  updateSelectedRows,
} from "./utils";

const SelectedContext = createContext({
  selectedRows: [],
  onResetSelection: () => {
    throw new Error("onResetSelection is not implemented");
  },
  onSelectionChange: () => {
    throw new Error("onSelectionChange is not implemented");
  },
  onSelectAllChange: () => {
    throw new Error("onSelectAllChange is not implemented");
  },
  onConditionSelectionChange: () => {
    throw new Error("onConditionSelectionChange is not implemented");
  },
});

export const useSelectedRows = () => useContext(SelectedContext);

const SelectedProvider = ({ data, children }) => {
  const [selectedWaitingRooms, setSelectedWaitingRooms] = useState([]);

  const handleSelectionChange = useCallback((checked, { id, conditions }) => {
    setSelectedWaitingRooms((prevRowSelection) => {
      if (checked) {
        const conditionIds = prepareConditionIds(conditions);
        return updateSelectedRows(prevRowSelection, id, conditionIds);
      }
      return removeSelectedRow(prevRowSelection, id);
    });
  }, []);

  const handleSelectAllChange = useCallback((isAllRowSelected, rows) => {
    setSelectedWaitingRooms(
      isAllRowSelected
        ? rows.map(({ id, conditions }) => ({
            waitingRoomId: id,
            conditionIds: prepareConditionIds(conditions),
          }))
        : []
    );
  }, []);

  const handleConditionSelectionChange = useCallback(
    (checked, waitingRoomId, conditionId) => {
      setSelectedWaitingRooms((prevRowSelection) =>
        checked
          ? addCondition(prevRowSelection, waitingRoomId, conditionId)
          : removeCondition(prevRowSelection, waitingRoomId, conditionId)
      );
    },
    []
  );

  const handleResetSelection = useCallback(() => {
    setSelectedWaitingRooms([]);
  }, []);

  const getConditionIdsById = useCallback(
    (waitingRoomId) =>
      selectedWaitingRooms.find(findSelectedRow(waitingRoomId))?.conditionIds ||
      [],
    [selectedWaitingRooms]
  );

  const selectedRows = useMemo(
    () =>
      selectedWaitingRooms.filter(({ waitingRoomId, conditionIds }) => {
        const waitingRoom = data.find((item) => item.id === waitingRoomId);
        return (
          !!waitingRoom &&
          isEveryConditionsSelected(waitingRoom.conditions, conditionIds)
        );
      }),
    [data, selectedWaitingRooms]
  );

  const value = useMemo(
    () => ({
      selectedRows,
      getConditionIdsById,
      onResetSelection: handleResetSelection,
      onSelectionChange: handleSelectionChange,
      onSelectAllChange: handleSelectAllChange,
      onConditionSelectionChange: handleConditionSelectionChange,
    }),
    [
      getConditionIdsById,
      handleConditionSelectionChange,
      handleResetSelection,
      handleSelectAllChange,
      handleSelectionChange,
      selectedRows,
    ]
  );

  useEffect(() => {
    handleResetSelection();
  }, [data, handleResetSelection]);

  return (
    <SelectedContext.Provider value={value}>
      {children}
    </SelectedContext.Provider>
  );
};

SelectedProvider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  children: PropTypes.node.isRequired,
};

export default SelectedProvider;
