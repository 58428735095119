import PropTypes from "prop-types";
import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PopupComponent from "src/Components/Popup/popup";
import __ from "src/utils/Translations";

export default function InfoModal({ message, onClose }) {
  return (
    <>
      <PopupComponent
        id="oneTimeServiceEditInfo"
        isOpen
        toggle={() => onClose(false)}
        unmountOnClose
      >
        <ModalHeader toggle={() => onClose(false)}>{__("Uwaga!")}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onClose(true)}>
            {__("Ok")}
          </Button>
        </ModalFooter>
      </PopupComponent>
    </>
  );
}

InfoModal.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
};
