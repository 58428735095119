import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import _ from "lodash";
import { dynamicNotification } from "src/utils/Notifications";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import { subscriptionEmployeeSubscriptionItemUpdatePersonalData } from "src/utils/RoleBasedSecurity/permissions";
import useFormFields from "src/utils/hooks/benefit/useFormFields";
import Form from "src/Components/Form";
import __ from "src/utils/Translations";
import { parseDataToBackend } from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";

const ChangeData = ({ subscription, close }) => {
  const { id, benefitName, form, type } = subscription;
  const originalData = {
    ...form,
  };

  const parsedFormElements = useFormFields(form, type);
  const [data, setData] = useState({});
  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      setData(updatedData);
    },
    [data]
  );

  const submit = async () => {
    if (_.isEmpty(data)) {
      dynamicNotification(__("Nie wprowadzono nowych danych."), "error");

      return;
    }

    try {
      await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        // ID in request URL is receiver id. Payload contains only firstname and lastname
        `/employee-subscription-item-receivers/${id}/edit-user-data`,
        "POST",
        {
          body: parseDataToBackend(originalData, data),
        },
        null
      );
      dynamicNotification(__("Pomyślnie zmieniono dane."));
      close(true);
    } catch (e) {
      console.error(e);
      dynamicNotification(
        e.message || __("Nie udało się zmienić danych."),
        "error"
      );
    }
  };

  return (
    <Form
      id="blockSubscriptionForm"
      data={data}
      config={{
        isInPopup: true,
        noCards: true,
        togglePopup: () => close(),
        title: `Zmiana imion i nazwisk na abonamencie ${benefitName}`,
        onSubmit: submit,
        buttons: [
          {
            onClick: () => close(),
            text: "Zamknij",
            color: "light",
            id: "changeDataFormClose",
          },
          {
            text: "Zmień",
            type: "submit",
            id: "changeDataFormSubmit",
            permission: subscriptionEmployeeSubscriptionItemUpdatePersonalData,
          },
        ],
        defaultOnChange: onChange,
        formGroups: [
          {
            formElements: [
              {
                component: <Info />,
              },
            ],
          },
          {
            formElements: parsedFormElements,
          },
        ],
      }}
    />
  );
};

const Info = () => (
  <Alert key="changeDataInfo" color="primary">
    <p>
      {__(
        // eslint-disable-next-line max-len
        `Zmiana imienia i nazwiska w tym miejscu spowoduje tylko zmianę danych dla niniejszego abonamentu. Zmienione dane wiążą się ze zmianą w formularzu dla abonamentu. Sprawdź czy dane w sekcji "dane pracownika" są poprawne.`
      )}
    </p>
  </Alert>
);

ChangeData.propTypes = {
  close: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    forms: PropTypes.array,
    activeForms: PropTypes.array,
    benefitName: PropTypes.string,
    form: PropTypes.shape({
      type: PropTypes.string,
    }),
    type: PropTypes.string,
  }).isRequired,
};

export default ChangeData;
