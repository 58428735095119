import { useEffect, useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function (companyId, asOptions) {
  const [perkCategories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async (companyId) => {
      try {
        const result = await restApiRequest(
          API_TOTALREWARD_SERVICE,
          "/omb/perk-categories",
          "GET",
          {
            params: {
              tenant: companyId,
            },
          }
        );
        setCategories(result);
      } catch (e) {
        newRelicErrorReport(
          e,
          "/src/Pages/Company/TotalReward/Categories/useTotalRewardCategories.js - 13"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać kategorii"),
          "error"
        );
        setCategories([]);
      }
    };
    getCategories(companyId);
  }, [companyId]);

  return {
    perkCategories: asOptions
      ? perkCategories.map((c) => ({
          value: c.id,
          label: c.name,
          status: c.status,
        }))
      : perkCategories,
  };
}
