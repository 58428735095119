import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";
import { restApiRequest, REPORT_SERVICE } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { useLanguages } from "src/utils/Languages/LanguageContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import DynamicParametersDescription from "src/Pages/Report/NewReport/ReportsList/Report/dynamicParametersDescription";
import FrequencyModal from "./Frequency";
import SubscriptionForm from "./subscriptionForm";
import SendMethodModal from "./sendMethodModal";
import {
  subscribeMethodMock,
  subscribeFrequencyMock,
  formatDropdownMock,
  ahrsDropdownMock,
} from "./mockData";

const Subscription = ({
  isEdit,
  subscribeFunc,
  subscribeData,
  reportId,
  companyId,
  isSaving,
  setSubscribeDataToEditSend,
  isGetBlockBtn,
  isAhr,
  isdateOK,
  changeSwitchSendReport,
  parametersDynamic,
}) => {
  const [checkSub, setCheckSub] = useState(isEdit);
  const [isOpenDataMailModal, setIsOpenDataMailModal] = useState(false);
  const [isOpenFrequencyModal, setIsOpenFrequencyModal] = useState(false);

  const subscribeLanguageOptions = useLanguages(true).map((lang) => ({
    label: lang.label,
    value: lang.code,
  }));

  const [subscribeMethod, setSubscribeMethod] = useState(
    subscribeData?.subscribeMethod?.subscribeMethod || {
      label: __("Nie wybrano"),
      value: -1,
    }
  );
  const [subscribeFrequency, setSubscribeFrequency] = useState(
    subscribeData?.subscribeFrequency?.subscribeFrequency || {
      label: __("Nie wybrano"),
      value: -1,
    }
  );

  const [subscribeInfo, setsubscribeInfo] = useState(subscribeData);
  const [subscribeInfoModal, setsubscribeInfoModal] = useState(subscribeData);

  const [subscribeMethodOptions, setSubscribeMethodOptions] = useState([]);
  const [formatOptions, setFormatOptions] = useState([]);
  const [ahrsList, setAhrsList] = useState([]);
  const [isToken, setisToken] = useState(false);
  const [parametersDynamicInside, setParametersDynamicInside] =
    useState(parametersDynamic);

  useEffect(() => {
    fetchSubscribeMethodDropdown();
    fetchSubscribeFormatDropdown();
    fetchAhrsListDropdown();
    fetchIsTokenValid();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setParametersDynamicInside(parametersDynamic);
  }, [parametersDynamic]);

  useEffect(() => {
    if (isEdit) {
      setSubscribeDataToEditSend(subscribeInfo);
    }
  }, [isEdit, subscribeInfo, setSubscribeDataToEditSend]);

  const handleChangeSubscribeMethod = (val) => {
    setSubscribeMethod(val);

    setIsOpenDataMailModal(true);
  };

  const handleChangeFrequency = (val) => {
    setSubscribeFrequency(val);
    setIsOpenFrequencyModal(true);
  };

  const handleChangeLanguage = (val) => {
    const subscribeLanguageData = { ...subscribeInfo };
    subscribeLanguageData.subscribeLanguage = val.value;

    setsubscribeInfo(subscribeLanguageData);
  };

  const saveFrequency = () => {
    const subscribeFrequencyData = { ...subscribeInfo };
    subscribeFrequencyData.subscribeFrequency =
      subscribeInfoModal.subscribeFrequency;
    subscribeFrequencyData.subscribeFrequency.subscribeFrequency =
      subscribeFrequency;

    setsubscribeInfo(subscribeFrequencyData);
    setIsOpenFrequencyModal(false);
  };

  const clickSubscribe = () => {
    if (isAhr) {
      const subInfo = { ...subscribeInfo };
      subInfo.subscribeMethod.recipients = [];
      setsubscribeInfo(subInfo);
    }
    const data = subscribeInfo;
    subscribeFunc(data);
  };

  const closeFrequencyModal = () => {
    const subInfo = { ...subscribeInfo };

    setsubscribeInfoModal(subInfo);
    setIsOpenFrequencyModal(false);
  };

  const closeSendMethodModal = () => {
    const subInfo = { ...subscribeInfo };

    setsubscribeInfoModal(subInfo);
    setIsOpenDataMailModal(false);
  };

  const handleNoteChange = (val) => {
    const subinfo = { ...subscribeInfo, note: val };
    const subinfoModal = { ...subscribeInfoModal, note: val };

    setsubscribeInfo(subinfo);
    setsubscribeInfoModal(subinfoModal);
  };

  const handleFrequencyInfoChange = (data) => {
    const subinfoModal = { ...subscribeInfoModal, subscribeFrequency: data };

    setsubscribeInfoModal(subinfoModal);
  };

  const handleClickEditFrequency = () => {
    setIsOpenFrequencyModal(true);
  };

  const handleSubscribeMethodSave = (data) => {
    const subMethod = {
      ...subscribeInfo.subscribeMethod.subscribeMethod,
      label: subscribeMethod.label,
      value: subscribeMethod.value,
    };

    const subinfo = { ...subscribeInfo, subscribeMethod: data };
    subinfo.subscribeMethod.subscribeMethod = subMethod;

    const subinfoModal = { ...subscribeInfoModal, subscribeMethod: data };
    subinfoModal.subscribeMethod.subscribeMethod = subMethod;

    setsubscribeInfo(subinfo);
    setsubscribeInfoModal(subinfoModal);
    setIsOpenDataMailModal(false);
  };

  const fetchSubscribeMethodDropdown = () => {
    fetchData(
      setSubscribeMethodOptions,
      "/subscription/methods",
      REPORT_SERVICE,
      "GET",
      {},
      subscribeMethodMock,
      __("Błąd podczas pobierania listy metod wysyłki")
    );
  };

  const fetchSubscribeFormatDropdown = () => {
    fetchData(
      setFormatOptions,
      "/reports/static/formats",
      REPORT_SERVICE,
      "GET",
      {},
      formatDropdownMock,
      __("Błąd podczas pobierania listy dostępnych formatów")
    );
  };

  const fetchIsTokenValid = async () => {
    const endpointToken = `/reportgroups/checkToken/${companyId}`;
    fetchData(setisToken, endpointToken, REPORT_SERVICE, "GET", {});
  };
  const fetchAhrsListDropdown = () => {
    const endpoint = `/subscription/ahremail/${companyId}`;
    fetchData(
      setAhrsList,
      endpoint,
      REPORT_SERVICE,
      "GET",
      {},
      ahrsDropdownMock,
      __("Błąd podczas pobierania listy ahr-ów.")
    );
  };

  function PrepareDynamicParameters(isOn) {
    if (typeof changeSwitchSendReport === "function") {
      changeSwitchSendReport(isOn);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <ToggleSwitch
            handleChange={(isOn) => {
              setCheckSub(isOn);
              PrepareDynamicParameters(isOn);
            }}
            checked={checkSub}
            label={__("Włącz wysyłkę raportu")}
            disabled={isEdit}
          />
        </div>
      </div>
      <div className="row">
        <DynamicParametersDescription
          parametersDynamic={parametersDynamicInside}
          isOpen={checkSub}
        />
        {checkSub && (
          <SubscriptionForm
            subscribeMethodDropDown={subscribeMethodOptions}
            subscribeFrequencyDropDown={subscribeFrequencyMock}
            subscribeLanguageDropDown={subscribeLanguageOptions}
            subscribeInfo={subscribeInfo}
            setSubscribeMethod={handleChangeSubscribeMethod}
            setSubscribeFrequency={handleChangeFrequency}
            setSubscribeLanguage={handleChangeLanguage}
            setNote={handleNoteChange}
            setIsOpenDataMailModal={setIsOpenDataMailModal}
            clickSubscribe={clickSubscribe}
            occurrence={subscribeInfo.subscribeFrequency.occurrence}
            isEdit={isEdit}
            clickFrequencyEdit={handleClickEditFrequency}
            isSaving={isSaving}
            isGetBlockBtn={isGetBlockBtn}
            isTokenAvailable={isToken}
            isdateOK={isdateOK}
          />
        )}
        <SendMethodModal
          isOpen={isOpenDataMailModal}
          toggle={closeSendMethodModal}
          subscribeMethod={subscribeMethod}
          subscribeMethodInfo={subscribeInfoModal.subscribeMethod}
          handleSubscribeMethodSave={handleSubscribeMethodSave}
          reportId={reportId}
          formatDropdown={formatOptions}
          isAhr={isAhr}
          ahrsList={ahrsList}
          isTokenAvailable={isToken}
        />
        <FrequencyModal
          isOpen={isOpenFrequencyModal}
          toggle={closeFrequencyModal}
          subscribeFrequencyDropDown={subscribeFrequencyMock}
          subscribeFrequencyInfo={subscribeInfoModal.subscribeFrequency}
          subscribeFrequency={subscribeFrequency}
          setSubscribeFrequency={setSubscribeFrequency}
          setsubscribeFrequencyInfo={handleFrequencyInfoChange}
          saveFrequency={saveFrequency}
        />
      </div>
    </>
  );
};

Subscription.propTypes = {
  isEdit: PropTypes.bool,
  subscribeFunc: PropTypes.func.isRequired,
  subscribeData: PropTypes.object.isRequired,
  reportId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  setSubscribeDataToEditSend: PropTypes.func.isRequired,
  isAhr: PropTypes.bool,
  isGetBlockBtn: PropTypes.bool.isRequired,
  isdateOK: PropTypes.func.isRequired,
  changeSwitchSendReport: PropTypes.func,
  parametersDynamic: PropTypes.array.isRequired,
};

Subscription.defaultProps = {
  changeSwitchSendReport: null,
  isAhr: false,
  isEdit: false,
};

export default Subscription;

const fetchData = async (
  updateData,
  endpoint,
  service,
  method,
  { headers, params, body },
  mockData,
  error
) => {
  if (isMockView()) {
    updateData(mockData);
  } else {
    try {
      const result = await restApiRequest(
        service,
        endpoint,
        method,
        { headers, params, body },
        {}
      );
      updateData(result);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Report/NewReport/ReportsList/Report/Subscription/index.js - 332"
      );
      dynamicNotification(error || e.message || "błąd", "error");
    }
  }
};
