import React from "react";
import { match as matchPropTypes } from "react-router-prop-types";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import { getAhrBaseBreadcrumbs } from "src/Pages/AhrCore/routerHelper";
import __ from "src/utils/Translations";
import ArchiveDetails from "src/Pages/Report/GeneratedReports/List/Details";

export default function Details({ match }) {
  const companyName = useCompanyName();
  const companyId = useCompanyId();

  return (
    <ArchiveDetails
      companyId={companyId}
      companyName={companyName}
      archiveId={match.params.id}
      archiveListUrl="/ahr/report/archive"
      breadcrumbs={[
        ...getAhrBaseBreadcrumbs(companyName),
        { title: __("Raporty"), link: "/ahr/report" },
        { title: __("Archiwum"), link: "/ahr/report/archive" },
      ]}
    />
  );
}

Details.propTypes = {
  match: matchPropTypes.isRequired,
};
