import { CompanySubMenu } from "src/Layout/AppNav/SubMenuItems";

const getSubMenuByLocation = (location, isAhr) => {
  if (isAhr) {
    const pathRoot = location.split("/").slice(0, 3).join("/");
    switch (pathRoot) {
      case "/ahr/company":
        return CompanySubMenu.code;
      default:
        return false;
    }
  } else {
    const pathRoot = location.split("/").slice(0, 3).join("/");
    switch (pathRoot) {
      case "/company/edit":
        return CompanySubMenu.code;
      default:
        return false;
    }
  }
};

export default getSubMenuByLocation;
