import { hasAccessTo } from "src/utils/RoleBasedSecurity/filters";
import __ from "src/utils/Translations";
import { CATALOG_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { productsMockData } from "src/Pages/ProductCatalog/List/table";
import isUuid from "src/utils/jsHelpers/isUuid";
import { catalogProductPermissionRead } from "src/utils/RoleBasedSecurity/permissions";

const findProducts = (value, ctx) => {
  if (!hasAccessTo(ctx.userInfo, catalogProductPermissionRead)) {
    return [];
  }
  const filter = isUuid(value) ? { id: value } : { name: value };
  return restApiRequest(
    CATALOG_MANAGEMENT_SERVICE,
    "/products",
    "GET",
    {
      params: {
        ...filter,
        itemsPerPage: 10,
      },
    },
    productsMockData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    )
  ).then((res) =>
    res.map(({ name, id, mbProductType }) => ({
      label: name,
      to: `/product-catalog/products/${id}/${mbProductType}`,
    }))
  );
};

export default findProducts;
