import React from "react";
import PropTypes from "prop-types";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import __ from "src/utils/Translations";
import { downloadFile, getFullFileName } from "src/utils/Api";
import SecurityWrapper from "src/utils/RoleBasedSecurity/SecurityComponents/SecuirityWrapper";
import {
  buildExportPathForAhr,
  disableButtonChecker,
} from "src/Components/DataTableControlled/utils";

const AhrExportButton = ({
  path,
  service,
  permissions,
  fileName,
  noResults,
  massActionSelect,
}) => (
  <UncontrolledButtonDropdown size="lg" className="mr-2">
    <SecurityWrapper permission={permissions} disable>
      <DropdownToggle caret color="secondary">
        <span data-t1="export">{__("Eksportuj raport AHR")}</span>
      </DropdownToggle>
    </SecurityWrapper>
    <DropdownMenu positionFixed>
      <DropdownItem
        disabled={disableButtonChecker(noResults, massActionSelect)}
        onClick={async () => {
          downloadFile(
            service,
            await buildExportPathForAhr(path, massActionSelect),
            getFullFileName(fileName, false, "csv")
          );
        }}
      >
        <span data-t1="export-page">{__("Eksportuj AHR do CSV")}</span>
      </DropdownItem>
      <DropdownItem
        disabled={disableButtonChecker(noResults, massActionSelect)}
        onClick={async () => {
          downloadFile(
            service,
            await buildExportPathForAhr(path, massActionSelect),
            getFullFileName(fileName, false, "xlsx")
          );
        }}
      >
        <span data-t1="export-page">{__("Eksportuj AHR do XLS")}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledButtonDropdown>
);

AhrExportButton.propTypes = {
  service: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  noResults: PropTypes.bool,
  permissions: PropTypes.string,
  massActionSelect: PropTypes.shape({
    excluded: PropTypes.arrayOf(PropTypes.string),
    included: PropTypes.arrayOf(PropTypes.string),
    includedSelectAll: PropTypes.arrayOf(PropTypes.string),
  }),
};

AhrExportButton.defaultProps = {
  permissions: null,
  noResults: false,
  massActionSelect: null,
};
export default AhrExportButton;
