import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import PageTitle from "src/Layout/AppMain/PageTitle";
import TabsWithMemory from "src/Components/Tabs/TabsWithMemory";
import { EMPLOYEE_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import {
  employeeAccountPermissionWrite,
  employeeAhrRolePermissionRead,
  employeeEmployeeLeavePermissionRead,
  employeeEmployeePermissionRead,
  magentoAdminOrderPermissionRead,
  subscriptionEmployeeSubscriptionItemRead,
} from "src/utils/RoleBasedSecurity/permissions";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import General from "./General";
import Subscriptions from "./Subscriptions";
import Permission from "./Permission";
import Vacations from "./Vacations";
import ChargeUps from "./ChargeUps";
import Financing from "./Financing";
import OneTimeServices from "./OneTimeServices";
import UserAgreements from "./UserAgreements";
import MSCards from "./MSCards";
import PrepaidCardsTab from "./PrepaidCardsTab";

const getActiveTab = (hashValue) => {
  const hash = hashValue ? hashValue.replace("#", "") : "general_data";
  return hash.split("/")[0];
};

export default function Tabs({
  breadcrumbs,
  isAhr,
  employeeId,
  companyId,
  companyName,
}) {
  const [employeeData, setEmployeeData] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const history = useHistory();
  const isNew = employeeId === "-1";

  useEffect(() => {
    if (employeeId && !isNew) {
      restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/employees/${employeeId}`,
        "GET",
        {},
        { firstName: "Jan", lastName: "Kowalski" }
      )
        .then((resData) => {
          if (resData.companyId !== companyId) {
            setIsVisible(false);
            // eslint-disable-next-line no-throw-literal
            throw {
              message: `W tej firmie nie istnieje pracownik o ID ${employeeId}`,
            };
          }
          setEmployeeData(resData);
        })
        .catch((e) => {
          newRelicErrorReport(
            e,
            "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/tabs.js - 68"
          );
          dynamicNotification(
            e.message || __("Nie udało się pobrać listy pracowników"),
            "error"
          );
        });
    }
  }, [employeeId, isNew, companyId]);

  const activeKey = getActiveTab(history.location.hash);
  const subscriptionsPermissionForAhr = useHasPermission(
    subscriptionEmployeeSubscriptionItemRead
  );
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={
            isNew
              ? `${__("Tworzenie nowego pracownika firmy")} ${companyName}`
              : `${__("Edycja pracownika")} 
            ${employeeData.firstName || ""} ${
                  employeeData.lastName || ""
                } (ID: ${employeeId}) firmy ${companyName}`
          }
          breadcrumbsHeading={
            isNew
              ? `${__("Tworzenie nowego pracownika")}`
              : `${__("Edycja pracownika")} 
            ${employeeData.firstName || ""} ${
                  employeeData.lastName || ""
                } (ID: ${employeeId})`
          }
          breadcrumbs={breadcrumbs}
          pushToHistory={!isNew}
        />
        {isVisible && (
          <div className="mt-1">
            <TabsWithMemory
              activeKey={activeKey}
              defaultActiveKey="general_data"
              tabsConfig={[
                {
                  name: `${__("Dane pracownika")}`,
                  key: "general_data",
                  aclKey: employeeEmployeePermissionRead,
                  component: (
                    <General
                      employeeId={employeeId}
                      isNew={isNew}
                      companyId={companyId}
                      setEmployeeData={setEmployeeData}
                      employeeData={employeeData}
                    />
                  ),
                },
                {
                  name: `${__("Świadczenia abonamentowe")}`,
                  key: "subscriptions",
                  aclKey: subscriptionEmployeeSubscriptionItemRead,
                  component: (
                    <Subscriptions
                      employeeId={employeeId}
                      companyId={companyId}
                      isNew={isNew}
                    />
                  ),
                  disabled: isNew,
                  display: isAhr ? subscriptionsPermissionForAhr : true,
                },
                {
                  name: `${__("Dofinansowania pracownika")}`,
                  key: "financing",
                  aclKey: employeeEmployeePermissionRead,
                  component: (
                    <Financing employeeId={employeeId} companyId={companyId} />
                  ),
                  disabled: isNew,
                  display: !isAhr,
                },
                {
                  name: `${__("Uprawnienia")}`,
                  key: "permissions",
                  aclKey: employeeAhrRolePermissionRead,
                  component: (
                    <Permission
                      employeeId={employeeId}
                      companyId={companyId}
                      setEmployeeData={setEmployeeData}
                    />
                  ),
                  disabled: isNew,
                  display: !isAhr,
                },
                /* Ukrycie zakładki zgodnie z https://jira.unity.pl/browse/FEN-20617 */
                // {
                //   name: `${__("Urlopy")}`,
                //   key: "vacations",
                //   aclKey: employeeEmployeeLeavePermissionRead,
                //   component: (
                //     <Vacations employeeId={employeeId} companyId={companyId} />
                //   ),
                //   disabled: isNew,
                //   display: !isAhr,
                // },
                {
                  name: `${__("Doładowania punktów")}`,
                  key: "charge_ups",
                  aclKey: employeeAccountPermissionWrite,
                  component: (
                    <ChargeUps employeeId={employeeId} companyId={companyId} />
                  ),
                  disabled: isNew,
                },
                {
                  name: `${__("Świadczenia jednorazowe")}`,
                  key: "one_time_services",
                  aclKey: magentoAdminOrderPermissionRead,
                  component: (
                    <OneTimeServices
                      employeeId={employeeId}
                      companyId={companyId}
                    />
                  ),
                  disabled: isNew,
                  display: !isAhr,
                },
                {
                  name: `${__("Zgody użytkownika")}`,
                  key: "user_agreements",
                  aclKey: employeeEmployeePermissionRead,
                  component: (
                    <UserAgreements
                      employeeId={employeeId}
                      companyId={companyId}
                    />
                  ),
                  disabled: isNew,
                  display: !isAhr,
                },
                {
                  name: `${__("Świadczenia cykliczne")}`,
                  key: "ms_cards",
                  aclKey: employeeEmployeePermissionRead,
                  component: <MSCards employeeId={employeeId} />,
                  disabled: isNew,
                  display: !isAhr,
                },
                {
                  name: `${__("Karty przedplacone")}`,
                  key: "prepaid_cards",
                  aclKey: employeeEmployeePermissionRead,
                  component: <PrepaidCardsTab employeeId={employeeId} />,
                  disabled: isNew,
                  display: !isAhr,
                },
              ]}
            />
          </div>
        )}
      </CSSTransitionGroup>
    </>
  );
}
Tabs.propTypes = {
  companyId: PropTypes.string.isRequired,
  employeeId: PropTypes.string.isRequired,
  isAhr: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
  companyName: PropTypes.string,
};

Tabs.defaultProps = {
  companyName: "",
  isAhr: false,
};
