import { isEmpty } from "lodash";

const removeEmptyFields = (data) => {
  const copyOfData = { ...data };
  Object.entries(data).forEach(([key, value]) => {
    if (isEmpty(value)) {
      delete copyOfData[key];
    }
  });
  return copyOfData;
};

export default removeEmptyFields;
