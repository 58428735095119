// ambiguous signs = ['B', '8', 'G', '6', 'I', 'L', '1', 'l', '0', 'O', 'Q', 'D', 'S', '5', 'Z', '2']
const ambiguousCharacters = ["B", "G", "I", "L", "l", "O", "Q", "D", "S", "Z"];
const characters = "abcdefghijklmnopqrstuvwxyz"; // to upper
const numeric = "347934793";
const punctuation = "!@#$%&*?";

const getRandom = (min = 0, max = 10) =>
  Math.floor(Math.random() * (max - min)) + min;

const getRandomChar = (large = false) => {
  const char = characters.charAt(
    Math.ceil(characters.length * Math.random() * Math.random())
  );
  const result = large ? char.toUpperCase() : char;
  if (ambiguousCharacters.includes(result)) {
    return getRandomChar(large);
  }
  return result;
};
const getRandomNumber = () =>
  numeric.charAt(Math.ceil(numeric.length * Math.random() * Math.random()));
const getRandomSign = () =>
  punctuation.charAt(
    Math.ceil(punctuation.length * Math.random() * Math.random())
  );

const getRandomCharacter = () => {
  const random = getRandom(1, 4);
  switch (random) {
    case 1: {
      return getRandomChar();
    }
    case 2: {
      return getRandomChar(true);
    }
    case 3: {
      return getRandomNumber();
    }
    case 4: {
      return getRandomSign();
    }
    default:
      return "";
  }
};

const generatePassword = () => {
  const length = Math.floor(Math.random() * (15 - 10 + 1)) + 10;

  let password = `${getRandomChar()}${getRandomNumber()}${getRandomChar(
    true
  )}${getRandomSign()}`;
  while (password.length < length) {
    password += getRandomCharacter();
  }
  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  const punctuationArr = punctuation.split("");
  const result = punctuationArr.some((ele) => password.includes(ele));

  if (!result) {
    const replacePosition = Math.floor(Math.random() * (length - 1));
    const splitedPassword = password.split("");
    splitedPassword[replacePosition] =
      punctuationArr[Math.floor(Math.random() * (punctuationArr.length - 1))];
    password = splitedPassword.join("");
  }

  return password.substr(0, length);
};

export default generatePassword;
