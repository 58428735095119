import React from "react";
import isObject from "src/utils/jsHelpers/isObject";
import __ from "src/utils/Translations";
import ProcessBar from "src/Pages/Processes/View/processBar";

export default (data = {}) => {
  const {
    "execute:errorLines": errors = {},
    "execute:processed": processed = 0,
    "execute:totalLines": totalLines = 0,
  } = data;
  const errorMessages = [];
  if (isObject(errors)) {
    Object.keys(errors).forEach((row) => {
      if (Array.isArray(errors[row])) {
        errors[row].forEach((errorMessage) => {
          errorMessages.push(
            __("Błąd w wierszu {0}: {1}", [row, errorMessage])
          );
        });
      } else {
        errorMessages.push(__("Błąd w wierszu {0}: {1}", [row, errors[row]]));
      }
    });
  }

  return {
    success: !errorMessages.length,
    processed,
    totalLines,
    errors: errorMessages,
    importProcessBlock: <ProcessBar processId={data.id} />,
  };
};
