import { operatorDiagnosticPermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import {
  AGREEMENT_SERVICE,
  CATALOG_MANAGEMENT_SERVICE,
  COMPANY_MANAGEMENT_SERVICE,
  CONFIGURATION_SERVICE,
  DICTIONARY_SERVICE,
  EMPLOYEE_GROUP_PRODUCT_SERVICE,
  EMPLOYEE_MANAGEMENT_SERVICE,
  MAGENTO_CATALOG_SERVICE,
  MAGENTO_HELPER_SERVICE,
  NOTIFICATION_SERVICE,
  OPERATOR_MANAGEMENT_SERVICE,
  SSO_SERVICE,
  TOURISM_SERVICE,
  TRANSLATOR_SERVICE,
  PDF_GENERATOR_SERVICE,
  MIGRATION_SERVICE,
  MAGENTO_ADMIN_SERVICE,
  SUBSCRIPTION_MANAGEMENT_SERVICE,
  PROCESSES_SERVICE,
  SFTP_SERVICE,
} from "src/utils/Api";

const diagnosticServices = [
  {
    label: "Agreement",
    to: "/diagnostic/agreement",
    id: "diagnostic-agreement",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: AGREEMENT_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Catalog Management",
    to: "/diagnostic/catalog-management",
    id: "diagnostic-catalog-management",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: CATALOG_MANAGEMENT_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Company",
    to: "/diagnostic/company",
    id: "diagnostic-company",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: COMPANY_MANAGEMENT_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Configuration",
    to: "/diagnostic/configuration",
    id: "diagnostic-configuration",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: CONFIGURATION_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Dictionary",
    to: "/diagnostic/dictionary",
    id: "diagnostic-dictionary",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: DICTIONARY_SERVICE,
    withActivityLog: true,
  },
  {
    label: "EGP",
    to: "/diagnostic/egp",
    id: "diagnostic-egp",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: EMPLOYEE_GROUP_PRODUCT_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Employee",
    to: "/diagnostic/employee",
    id: "diagnostic-employee",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: EMPLOYEE_MANAGEMENT_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Magento Admin",
    to: "/diagnostic/magento-admin",
    id: "diagnostic-magento-admin",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: MAGENTO_ADMIN_SERVICE,
    withActivityLog: false,
  },
  {
    label: "Magento Catalog",
    to: "/diagnostic/magento-catalog",
    id: "diagnostic-magento-catalog",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: MAGENTO_CATALOG_SERVICE,
    withActivityLog: false,
  },
  {
    label: "Notification",
    to: "/diagnostic/notification",
    id: "diagnostic-notification",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: NOTIFICATION_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Operator",
    to: "/diagnostic/operator",
    id: "diagnostic-operator",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: OPERATOR_MANAGEMENT_SERVICE,
    withActivityLog: true,
  },
  {
    label: "PDF Generator",
    to: "/diagnostic/pdf-generator",
    id: "diagnostic-pdf-generator",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: PDF_GENERATOR_SERVICE,
    withActivityLog: false,
  },
  {
    label: "SSO",
    to: "/diagnostic/sso",
    id: "diagnostic-sso",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: SSO_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Subscription",
    to: "/diagnostic/subscription",
    id: "diagnostic-subscription",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: SUBSCRIPTION_MANAGEMENT_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Tourism",
    to: "/diagnostic/tourism",
    id: "diagnostic-tourism",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: TOURISM_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Translator",
    to: "/diagnostic/translator",
    id: "diagnostic-translator",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: TRANSLATOR_SERVICE,
    withActivityLog: true,
  },
  {
    label: "Job manager",
    to: "/diagnostic/job-manager",
    id: "diagnostic-job-manager",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: PROCESSES_SERVICE,
    withActivityLog: false,
  },
  {
    label: "SFTP",
    to: "/diagnostic/sftp",
    id: "diagnostic-sftp",
    aclKey: operatorDiagnosticPermissionRead,
    tileIcon: "pe-7s-config",
    service: SFTP_SERVICE,
    withActivityLog: true,
  },
];
export default diagnosticServices;
