/* eslint-disable react/no-array-index-key */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import { Button } from "reactstrap";
import Form from "src/Components/Form/index";
import { LAYOUT_TWO_COLUMNS } from "src/Components/Layouts";
import __ from "src/utils/Translations";
import { catalogProductPermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import useConfigValue from "src/utils/hooks/configuration/useConfigValue";
import { CATALOG_MANAGEMENT_SERVICE } from "src/utils/Api";

const defaultLabelValues = {
  firstname: "Imię",
  lastname: "Nazwisko",
  email: "Email",
  phone: "Telefon",
  dayOfBirth: "Data urodzenia",
  shipmentAddress: "Adres koresp.",
  checkInAddress: "Adres zameldowania",
};

const AGREEMENT_FIELD_PREFIX = "agreement_form_";
const FORM_FIELD_TYPE_PREFIX = "form_field_type_";
const FORM_FIELD_LABEL_PREFIX = "form_field_label_";
const FORM_FIELD_CODE_PREFIX = "form_field_code_";
const FORM_FIELD_IS_CUSTOM_PREFIX = "form_field_is_custom_";

export const PERSON_FORM_TYPE_MULTIPLE = "multiple";
export const PERSON_FORM_TYPE_SINGLE = "single";
export const SINGLE_PERSON_FORM_TYPE_EMPLOYEE = "employee";
export const SINGLE_PERSON_FORM_TYPE_OTHER = "other";

export default function AttachmentsAndLinks({
  data,
  setData,
  next,
  title,
  isNew,
  productId,
  setIsEdited,
}) {
  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      setData(updatedData);
      setIsEdited(true);
    },
    [data, setData, setIsEdited]
  );

  if (!isNew && !data.personFormType) {
    // personFormType is new field, set multiple for exist product to show form data
    // eslint-disable-next-line no-param-reassign
    data.personFormType = PERSON_FORM_TYPE_MULTIPLE;
    setData(data);
  }
  const updateAgreementField = (key, value) => {
    const index = +key.replace(AGREEMENT_FIELD_PREFIX, "");
    const agreementsData = data.agreementsData || [];
    agreementsData[index] = value;
    onChange("agreementsData", agreementsData);
  };
  const onChangePersonFormType = (key, value) => {
    const updatedData = { ...data };
    delete updatedData.personFormType;
    updatedData[key] = value;
    setData(updatedData);
  };

  const onChangeSinglePersonFormType = (key, value) => {
    const updatedData = { ...data };
    delete updatedData.singlePersonFormType;
    updatedData[key] = value;
    setData(updatedData);
  };
  const othersDefaultStatement = useConfigValue(
    "product/product-with-form/default-agreement"
  );
  let agreementsFields = [];
  const agreementsFieldsData = {};
  if (data && data.agreementsData) {
    agreementsFields = data.agreementsData.map((agreement, index) => {
      const fieldId = `${AGREEMENT_FIELD_PREFIX}${index}`;
      agreementsFieldsData[fieldId] = agreement;
      const removeButton = (
        <i
          data-t1="agreementDelete"
          className="lnr-trash btn-icon-wrapper cursor-pointer"
          role="presentation"
          onClick={() => removeAgreement(index)}
        />
      );
      return {
        type: "textarea",
        id: fieldId,
        displayCondition: Boolean(
          data.showAgreements &&
            (data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
              (data.personFormType === PERSON_FORM_TYPE_SINGLE &&
                data.singlePersonFormType))
        ),
        onChange: updateAgreementField,
        label: (
          <>
            {removeButton} {`Treść zgody ${index + 1}:`}
          </>
        ),
        validation: ["required"],
        depends: {
          functionValidation: (dataValidation) =>
            Boolean(dataValidation.showAgreements),
        },
      };
    });
  }
  const addAgreement = () => {
    const updatedAgreements = data.agreementsData || [];
    updatedAgreements.push("");
    onChange("agreementsData", updatedAgreements);
  };
  const removeAgreement = (index) => {
    const updatedAgreements = data.agreementsData || [];
    onChange(
      "agreementsData",
      updatedAgreements.filter((el, elIndex) => elIndex !== index)
    );
  };

  const updateFormFieldType = (key, value) => {
    const index = +key.replace(FORM_FIELD_TYPE_PREFIX, "");
    const fieldsData = data.fieldsData || [];
    fieldsData[index].fieldType = value;
    fieldsData[index].label = defaultLabelValues[value] || "";
    fieldsData[index].isCustom = value === "newValue";
    onChange("fieldsData", fieldsData);
  };

  const updateFormFieldLabel = (key, value) => {
    const index = +key.replace(FORM_FIELD_LABEL_PREFIX, "");
    const fieldsData = data.fieldsData || [];
    fieldsData[index].label = value;
    onChange("fieldsData", fieldsData);
  };

  const updateFormFieldCode = (key, value) => {
    const code = +key.replace(FORM_FIELD_CODE_PREFIX, "");
    const fieldsData = data.fieldsData || [];
    fieldsData[code].code = value;
    onChange("fieldsData", fieldsData);
  };

  let formFields = [];
  const formFieldsData = {};
  if (data && data.fieldsData) {
    formFields = data.fieldsData.map(
      ({ fieldType = "", label = "", code = "", isCustom = false }, index) => {
        const fieldTypeId = `${FORM_FIELD_TYPE_PREFIX}${index}`;
        const fieldLabelId = `${FORM_FIELD_LABEL_PREFIX}${index}`;
        const fieldCode = `${FORM_FIELD_CODE_PREFIX}${index}`;
        const fieldIsCustom = `${FORM_FIELD_IS_CUSTOM_PREFIX}${index}`;
        formFieldsData[fieldTypeId] = fieldType;
        formFieldsData[fieldLabelId] = label;
        formFieldsData[fieldCode] = code;
        formFieldsData[fieldIsCustom] = isCustom;
        const removeButton = (
          <i
            data-t1="fieldTypeDelete"
            className="lnr-trash btn-icon-wrapper cursor-pointer"
            role="presentation"
            onClick={() => removeFormField(index)}
          />
        );
        return {
          layout: LAYOUT_TWO_COLUMNS,
          formElements: [
            {
              type: "select",
              id: fieldTypeId,
              displayCondition: Boolean(
                data.enabledFormConfiguration === true && data.personFormType
              ),
              onChange: updateFormFieldType,
              options: formFielTypeOptions,
              label: (
                <>
                  {removeButton} {__("Typ pola")}:
                </>
              ),
              validation: ["required"],
            },
            {
              type: "text",
              id: fieldLabelId,
              displayCondition: Boolean(
                data.enabledFormConfiguration === true && data.personFormType
              ),
              onChange: updateFormFieldLabel,
              label: "Etykieta pola:",
              validation: ["required"],
              depends: {
                functionValidation: (dataValidation) =>
                  Boolean(dataValidation[fieldTypeId]),
              },
            },
            {
              type: "text",
              className: "m-0",
              id: fieldCode,
              displayCondition: Boolean(
                data.enabledFormConfiguration === true && data.personFormType
              ),
              onChange: updateFormFieldCode,
              label: "Kod pola:",
              validation: ["required"],
              depends: {
                field: fieldTypeId,
                value: "newValue",
              },
            },
          ],
        };
      }
    );
  }
  const addFormField = () => {
    const updatedFields = data.fieldsData || [];
    updatedFields.push({ fieldType: null, label: "" });
    onChange("fieldsData", updatedFields);
  };
  const removeFormField = (index) => {
    const updatedFields = data.fieldsData || [];
    onChange(
      "fieldsData",
      updatedFields.filter((el, elIndex) => elIndex !== index)
    );
  };
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Form
          id="formsAndConsents"
          data={{
            ...data,
            ...agreementsFieldsData,
            ...formFieldsData,
            othersStatement: data?.otherStatementIsDefault
              ? othersDefaultStatement
              : data?.othersStatement,
          }}
          config={{
            stickyTitle: false,
            defaultOnChange: onChange,
            groupsAsColumns: true,
            noCards: true,
            onSubmit: () => next(setIsEdited),
            title,
            buttons: [
              {
                size: "lg",
                color: "success",
                className: "mr-2",
                text: "Zapisz",
                type: "submit",
                id: "formsAndConsentsSubmit",
                permission: catalogProductPermissionWrite,
              },
            ],
            formGroups: [
              {
                formElements: [
                  {
                    type: "boolean",
                    isCheckbox: true,
                    id: "enabledFormConfiguration",
                    label: "Konfiguracja produktu z formularzem",
                  },
                  {
                    type: "radio",
                    label: "Typ formularzu:",
                    id: "personFormType",
                    onChange: onChangePersonFormType,
                    displayCondition: Boolean(
                      data.enabledFormConfiguration === true
                    ),
                    options: [
                      {
                        value: PERSON_FORM_TYPE_MULTIPLE,
                        label: "Formularz z danymi wielu osób",
                      },
                      {
                        value: PERSON_FORM_TYPE_SINGLE,
                        label: "Formularz z danymi jednej osoby",
                      },
                    ],
                  },
                  {
                    displayCondition: Boolean(
                      data.enabledFormConfiguration === true &&
                        data.personFormType
                    ),
                    component: (
                      <h6 key="forms" className="m-3">
                        <strong>Formularze</strong>
                      </h6>
                    ),
                  },
                  ...formFields,
                  {
                    displayCondition: Boolean(
                      data.enabledFormConfiguration === true &&
                        data.personFormType
                    ),
                    component: (
                      <Button
                        key="add_field"
                        color="link"
                        onClick={addFormField}
                        data-t1="attachmentsAndLinks__addField"
                      >
                        <i
                          className="pe-7s-plus pe-3x pe-va"
                          data-t1="attachmentsAndLinks__addFieldIcon"
                        />{" "}
                        Dodaj kolejne pole
                      </Button>
                    ),
                  },
                ],
              },
              {
                formElements:
                  data.enabledFormConfiguration !== true
                    ? []
                    : [
                        {
                          type: "radio",
                          label: "Dla kogo:",
                          id: "singlePersonFormType",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_SINGLE
                          ),
                          onChange: onChangeSinglePersonFormType,
                          options: [
                            {
                              value: SINGLE_PERSON_FORM_TYPE_EMPLOYEE,
                              label: "Dla pracownika",
                            },
                            {
                              value: SINGLE_PERSON_FORM_TYPE_OTHER,
                              label: "Dla osoby towarzyszącej",
                            },
                          ],
                        },
                        {
                          type: "boolean",
                          isCheckbox: true,
                          id: "canBuyMultiple",
                          label: "Możliwość zakupu wielu kodów na raz",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType)
                          ),
                          tooltip: {
                            content: (
                              <>
                                Zaznacz checkbox, jeśli chcesz aby użytkownik
                                mógł zakupić wiele kodów w jednej transakcji.
                              </>
                            ),
                          },
                        },
                        {
                          type: "date",
                          id: "formDataEditLastDay",
                          label: "Ostatni dzień edycji danych w formularzu:",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType)
                          ),
                          tooltip: {
                            content: (
                              <>
                                Wybierz z kalendarza datę, do której użytkownik
                                może edytować w zakładce „Moje zamówienia” dane
                                w formularzu.
                              </>
                            ),
                          },
                        },
                        {
                          type: "boolean",
                          isCheckbox: true,
                          id: "disabledEmployeeLabel",
                          label: "Wyłącz edycję pracownika",
                          props: {
                            disabled: Boolean(data.formDataEditLastDay == null),
                          },
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType ===
                                  SINGLE_PERSON_FORM_TYPE_EMPLOYEE)
                          ),
                          tooltip: {
                            content: (
                              <>
                                Zaznacz ten checkbox, jeżeli nie chcesz, aby
                                edycja danych dla pracownika była możliwa
                                <br />
                                (checkbox działa tylko jeżeli wybrałeś datę z
                                kalendarza w polu „Ostatni dzień edycji danych w
                                formularzu”).
                              </>
                            ),
                          },
                        },
                        {
                          type: "text",
                          id: "employeeMaxCodesCount",
                          label: "Maksymalna ilość kodów dla pracownika:",
                          valueFormatter: "integer",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType ===
                                  SINGLE_PERSON_FORM_TYPE_EMPLOYEE)
                          ),
                          tooltip: {
                            content: (
                              <>
                                W to pole wpisz ilość kodów, którą maksymalnie
                                może zakupić Użytkownik na dane Pracownika.
                                <br />
                                Pole puste oznacza, że może on zakupić
                                nielimitowaną ilość kodów na dane Pracownika.
                                <br />
                                Jeżeli w pole wpiszesz wartość „0”, na froncie
                                wyświetlisz formularz tylko dla „innej osoby”,
                                <br />
                                bez możliwości zakupu kodu dla Pracownika.
                              </>
                            ),
                          },
                          validation: [
                            { method: "lessEqualThan", args: [100000] },
                          ],
                        },
                        {
                          type: "boolean",
                          isCheckbox: true,
                          id: "canBuyForOthers",
                          label:
                            "Os. towarzysząca - możliwość zakupu bez kodu dla pracownika",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE
                          ),
                          tooltip: {
                            content: (
                              <>
                                Zaznacz checkbox, jeżeli chcesz, aby Użytkownik
                                mógł zakupić tylko kody dla innej osoby;
                                <br />
                                Powinieneś wybrać tę opcję, gdy limit dla osoby
                                towarzyszącej jest większy niż limit dla
                                pracownika
                                <br />
                                lub pole limit dla osoby towarzyszącej jest
                                puste.
                              </>
                            ),
                          },
                          // todo fix message if required field
                          // validation: [
                          //   (!data.canBuyMultiple && data.employeeMaxCodesCount < data.othersMaxCodesCount) ? 'required' : '',
                          // ],
                        },
                        {
                          type: "boolean",
                          isCheckbox: true,
                          id: "disabledOthersLabel",
                          label: "Wyłącz edycję osoby towarzyszącej",
                          props: {
                            disabled: Boolean(data.formDataEditLastDay == null),
                          },
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType ===
                                  SINGLE_PERSON_FORM_TYPE_OTHER)
                          ),
                          tooltip: {
                            content: (
                              <>
                                Zaznacz ten checkbox, jeżeli nie chcesz, aby
                                edycja danych dla osoby towarzyszącej była
                                możliwa
                                <br />
                                (checkbox działa tylko jeżeli wybrałeś datę z
                                kalendarza w polu „Ostatni dzień edycji danych w
                                formularzu”.
                              </>
                            ),
                          },
                        },
                        {
                          type: "wysiwyg",
                          id: "othersStatement",
                          label: "Oświadczenie dla osoby towarzyszącej:",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType ===
                                  SINGLE_PERSON_FORM_TYPE_OTHER)
                          ),
                          validation:
                            data.othersMaxCodesCount !== 0 ? ["required"] : [],
                          translatable: {
                            show: !isNew,
                            isCms: true,
                            isNotDefault: !data?.otherStatementIsDefault,
                            code: data?.otherStatementIsDefault
                              ? "configuration:product/product-with-form/default-agreement:default:default"
                              : `catalogManagement:product:${productId}:othersStatement`,
                          },
                          inputSwitcher: {
                            label: "Użyj domyślnej treści oświadczenia",
                            onChange: (key, checked) => {
                              onChange("otherStatementIsDefault", checked);
                            },
                            disableIfChecked: true,
                            switcherValue: Boolean(
                              data?.otherStatementIsDefault
                            ),
                          },
                          uploadService: CATALOG_MANAGEMENT_SERVICE,
                        },
                        {
                          type: "text",
                          id: "othersMaxCodesCount",
                          label:
                            "Maksymalna ilość kodów dla osoby towarzyszącej:",
                          validation: [
                            { method: "lessEqualThan", args: [100000] },
                          ],
                          valueFormatter: "integer",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType ===
                                  SINGLE_PERSON_FORM_TYPE_OTHER)
                          ),
                          tooltip: {
                            content: (
                              <>
                                W to pole wpisz ilość kodów, którą maksymalnie
                                może zakupić Użytkownik na dane innej osoby.
                                <br />
                                Pole puste oznacza, że może on zakupić
                                nielimitowaną ilość kodów na dane innej osoby.
                                <br />
                                Jeżeli w pole wpiszesz wartość „0”, na froncie
                                wyświetlisz formularz tylko dla „Pracownika”,
                                <br />
                                bez możliwości zakupu kodu dla „innej osoby”.
                              </>
                            ),
                          },
                        },
                        {
                          component: (
                            <h6 className="mt-3" key="Fields">
                              <strong>Zgody</strong>
                            </h6>
                          ),
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType)
                          ),
                        },
                        {
                          type: "boolean",
                          isCheckbox: true,
                          id: "showAgreements",
                          label:
                            "Wyświetl zgodę przy zakupie kodu z formularzem",
                          displayCondition: Boolean(
                            data.personFormType === PERSON_FORM_TYPE_MULTIPLE ||
                              (data.personFormType ===
                                PERSON_FORM_TYPE_SINGLE &&
                                data.singlePersonFormType)
                          ),
                        },
                        ...agreementsFields,
                        {
                          displayCondition: Boolean(
                            data.showAgreements &&
                              (data.personFormType ===
                                PERSON_FORM_TYPE_MULTIPLE ||
                                (data.personFormType ===
                                  PERSON_FORM_TYPE_SINGLE &&
                                  data.singlePersonFormType))
                          ),
                          component: (
                            <Button
                              key="add_Fields"
                              data-t1="addNewAgreement__button"
                              color="link"
                              onClick={addAgreement}
                            >
                              <i
                                className="pe-7s-plus pe-3x pe-va"
                                data-t1="addNewAgreement__icon"
                              />{" "}
                              Dodaj nową zgodę
                            </Button>
                          ),
                        },
                      ],
              },
            ],
          }}
        />
      </CSSTransitionGroup>
    </>
  );
}

const formFielTypeOptions = [
  { value: "firstname", label: "Imię" },
  { value: "lastname", label: "Nazwisko" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Telefon" },
  { value: "dayOfBirth", label: "Data urodzenia" },
  { value: "shipmentAddress", label: "Adres koresp." },
  { value: "checkInAddress", label: "Adres zameldowania" },
  { value: "newValue", label: "Zdefiniuj nową wartość" },
];

AttachmentsAndLinks.propTypes = {
  next: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  title: PropTypes.string,
  isNew: PropTypes.bool.isRequired,
  productId: PropTypes.string.isRequired,
  setIsEdited: PropTypes.func,
};
AttachmentsAndLinks.defaultProps = {
  next: () => {},
  setIsEdited: () => {},
  title: null,
};
