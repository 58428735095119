import { useCompanyHasFunctionality } from "src/Pages/Company/CompanyContext";

/**
 *
 * @TODO: Remove during refactor. This is bad, this check should be done in filterNavItemsByAlc.
 * Currently we are doing this 2 times, once here and once in filterNavItemsByAlc.
 * The issue here is ENABLE_PERIOD_SUBSCRIPTION_AND_IDEAL_CAFETERIA which check should be moved to filterNavItemsByAlc.
 * UPDATE: Subscription funcionalities moved to filterNavItemsByAlc.
 */
export default function useExcludeMenuItems() {
  const mbActive = useCompanyHasFunctionality("ENABLE_MYBENEFIT_ACTIVE");

  const prepaidCards = useCompanyHasFunctionality("ENABLE_PREPAID_CARDS");
  const companyEvents = useCompanyHasFunctionality("ENABLE_COMPANY_EVENTS");
  const proposals = useCompanyHasFunctionality("ENABLE_PROPOSALS");
  const totalRewardStatement = useCompanyHasFunctionality(
    "ENABLE_TOTAL_REWARD_STATEMENT"
  );

  const excludeIds = [];

  if (!mbActive) excludeIds.push("mybenefit_active");

  if (!prepaidCards) excludeIds.push("prepaidCards");
  if (!companyEvents) excludeIds.push("companyEvents");
  if (!proposals) excludeIds.push("proposals");
  if (!totalRewardStatement) excludeIds.push("totalRewardStatement");

  return excludeIds;
}
