import __ from "src/utils/Translations";
import { DefaultFilter } from "src/Components/DataTable/filters";

const getParsedColumns = (filteredColumns) =>
  filteredColumns.map((columnData) => {
    const column = { ...columnData };

    if (!column.additionalHeader) column.Header = __(column.Header);
    if (!column.Filter) {
      column.Filter = DefaultFilter;
    }
    return column;
  });

export default getParsedColumns;
