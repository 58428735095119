import React, { useState } from "react";
import PropTypes from "prop-types";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";
// eslint-disable-next-line import/no-cycle
import DynamicTranslationPopup from "./DynamicTranslationPopup";
// eslint-disable-next-line import/no-cycle
import DynamicCmsTranslationPopup from "./DynamicCmsTranslationPopup";

export default function DynamicTranslationTrigger({
  scope,
  isCms,
  value,
  translatedValue,
  code,
  isTitle,
  label,
  uploadService,
  type,
  isNotDefault,
  isLocked,
  lockReason,
  onTranslatorSubmit,
  allowDirtyHtml,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  const getPopup = () =>
    isCms ? (
      <DynamicCmsTranslationPopup
        close={closePopup}
        code={code}
        scope={scope}
        value={value}
        translatedValue={translatedValue}
        isTitle={isTitle}
        uploadService={uploadService}
        label={label}
        type={type}
        isNotDefault={isNotDefault}
        onTranslatorSubmit={onTranslatorSubmit}
        allowDirtyHtml={allowDirtyHtml}
      />
    ) : (
      <DynamicTranslationPopup
        close={closePopup}
        scope={scope}
        value={value}
        label={label}
        type={type}
      />
    );

  if (!value) {
    return null;
  }

  return (
    <>
      <i
        role="presentation"
        onClick={isLocked ? undefined : openPopup}
        className={`pe-7s-notebook cursor-pointer${
          isLocked ? null : " text-success"
        }`}
      />
      {isOpen && getPopup()}
      {lockReason && isLocked && (
        <DefaultTooltip id="tooltip-announcement" content={lockReason} />
      )}
    </>
  );
}

DynamicTranslationTrigger.propTypes = {
  scope: PropTypes.string,
  code: PropTypes.string,
  isCms: PropTypes.bool,
  uploadService: PropTypes.string,
  value: PropTypes.string,
  translatedValue: PropTypes.string,
  isTitle: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isNotDefault: PropTypes.bool,
  isLocked: PropTypes.bool,
  lockReason: PropTypes.string,
  onTranslatorSubmit: PropTypes.func,
  allowDirtyHtml: PropTypes.bool,
};

DynamicTranslationTrigger.defaultProps = {
  isCms: false,
  value: "",
  translatedValue: "",
  scope: "",
  code: "",
  isTitle: false,
  uploadService: null,
  label: "",
  type: "text",
  isNotDefault: true,
  isLocked: false,
  lockReason: null,
  onTranslatorSubmit: null,
  allowDirtyHtml: false,
};
