import { useEffect, useState } from "react";
import isJson from "src/utils/jsHelpers/isJson";
import { CONFIGURATION_SERVICE, restApiRequest } from "src/utils/Api";
import isObject from "src/utils/jsHelpers/isObject";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const useConfigFormData = (schema, data, scope) => {
  const [defaultData, setDefaultData] = useState({});
  const [schemaDefaultValues, setSchemaDefaultValues] = useState({});
  useEffect(() => {
    const newSchemaDefaultValues = {};
    schema.forEach((section) => {
      const sectionId = section.id;
      section.children.forEach((groupConfig) => {
        const groupId = groupConfig.id;
        groupConfig.children.forEach((fieldConfig) => {
          const fieldId = `${sectionId}/${groupId}/${fieldConfig.id}`;
          if (fieldConfig.defaultValue !== undefined) {
            newSchemaDefaultValues[fieldId] = isJson(fieldConfig.defaultValue)
              ? JSON.parse(fieldConfig.defaultValue)
              : fieldConfig.defaultValue;
          } else {
            newSchemaDefaultValues[fieldId] = "";
          }
        });
      });
    });
    setSchemaDefaultValues(newSchemaDefaultValues);
  }, [schema]);

  useEffect(() => {
    setDefaultData({});
    if (scope.companyId) {
      const params = {};
      if (scope.employeeGroupId) {
        params.companyId = scope.companyId;
      }
      restApiRequest(
        CONFIGURATION_SERVICE,
        "/get-config",
        "GET",
        {
          params,
        },
        {}
      )
        .then((res) => {
          setDefaultData(parseDefaultDataFromBackend(res));
        })
        .catch((e) => {
          newRelicErrorReport(
            e,
            "Pages/Administration/Configuration/utils/useConfigFormData.js - 51"
          );
          console.error(e);
        });
    }
  }, [setDefaultData, scope.companyId, scope.employeeGroupId]);

  const filteredData = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== null && data[key] !== undefined) {
      filteredData[key] = data[key];
    }
  });

  const allData = { ...schemaDefaultValues, ...defaultData, ...filteredData };
  const formData = {};
  Object.keys(allData).forEach((key) => {
    const value = allData[key];
    if (isObject(value)) {
      Object.keys(value).forEach((valueKey) => {
        formData[
          `${key}${valueKey.charAt(0).toUpperCase()}${valueKey.slice(1)}`
        ] = value[valueKey];
      });
    } else {
      formData[key] = value;
    }
  });

  return [formData, allData];
};

export const parseDefaultDataFromBackend = (data, sectionId) => {
  const result = {};
  Object.keys(data).forEach((path) => {
    if (!sectionId || path.split("/")[0] === sectionId) {
      let value = data[path];
      if (isJson(value) && value !== null) {
        value = JSON.parse(value);
      }
      if (value !== null) {
        result[path] = value;
      }
    }
  });
  return result;
};

export default useConfigFormData;
