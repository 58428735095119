import React from "react";
import ReactDOM from "react-dom";
import UserConfirmationPopup from "src/Components/UserConfirmationPopup";
import __ from "src/utils/Translations";

function getUserConfirmationPopup(
  message,
  callback,
  title = `${__("Czy na pewno chcesz opuścić tę stronę")}?`,
  noCbOnClose,
  additionalButtons,
  confirmLabel = "Tak",
  cancelLabel = "Nie"
) {
  if (!message) {
    callback(true);
    return;
  }
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);
  const onUserRespondHandler = (callbackState, noCallback) => {
    ReactDOM.unmountComponentAtNode(container);
    if (!noCallback) callback(callbackState);
  };
  ReactDOM.render(
    <UserConfirmationPopup
      title={title}
      onClose={() => onUserRespondHandler(false, noCbOnClose)}
      onCancel={() => onUserRespondHandler(false)}
      onConfirm={() => onUserRespondHandler(true)}
      message={message}
      additionalButtons={additionalButtons}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
    />,
    container
  );
}

export default getUserConfirmationPopup;
