/* eslint-disable react/prop-types */
import React from "react";

const SelectFilter =
  (options, submitForm = true) =>
  ({ filter, onChange, column }) => {
    const optionsArray = options.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ));
    optionsArray.unshift(
      <option value="" key="empty-option">
        ---
      </option>
    );
    return (
      <select
        data-t1="gridFilter"
        data-t2={column.id}
        className="form-control"
        value={filter ? filter.value : 0}
        onChange={(e) => {
          onChange(e.target.value);
          if (submitForm && e.target.form) {
            const submitButton = e.target.form.querySelector(
              'button[type="submit"]'
            );
            setTimeout(() => {
              submitButton.click();
            }, 10);
          }
        }}
      >
        {optionsArray}
      </select>
    );
  };

export default SelectFilter;
