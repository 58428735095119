export const defaultStyles = {
  menu: (provided) => ({ ...provided, zIndex: 100 }),
};

export const errorStyles = {
  ...defaultStyles,
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? "#ddd" : "red",
    "&:hover": {
      borderColor: state.isFocused ? "#ddd" : "red",
    },
  }),
};
