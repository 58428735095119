import { messageTypes } from "src/Components/InfoModal/consts";
import { getInfoModal } from "src/utils/system/modal";

const verifyIsCalculatorOn = (data, originalData) => {
  const calculatorPath = "rentable-group/calculator/enable-calculator";
  if (
    !!data[calculatorPath] &&
    !!data[calculatorPath] !== !!originalData[calculatorPath]
  ) {
    getInfoModal({
      onClose: () => {},
      type: messageTypes.turnOnCalculator,
    });
  }
};

export default verifyIsCalculatorOn;
