/* eslint-disable react/prop-types */
import React from "react";
import FilterDateRange from "./FilterDateRange";

export const dateFilterMethod = ({ id, value }, row) => {
  if (!value || (!value.to && !value.from)) {
    return true;
  }
  if (!row[id]) {
    return false;
  }

  const rowValue = new Date(row[id]);

  if (value.from && new Date(value.from) > rowValue) {
    return false;
  }

  return !(value.to && new Date(value.to) < rowValue);
};

const DateFilter =
  (hasTimeSelect = false, onlyMonths = false) =>
  ({ filter, onChange, column }) =>
    (
      <FilterDateRange
        id={column.id}
        showTimeSelect={hasTimeSelect}
        onlyMonths={onlyMonths}
        value={filter ? filter.value : { from: "", to: "" }}
        onChange={onChange}
      />
    );

export default DateFilter;
