import { getQueryString } from "src/utils/Api";

const buildExportPath = async (
  path,
  filters,
  handleAdditionalFilters,
  sort,
  pageSize = null,
  page = null
) => {
  const params = {};

  if (pageSize !== null) {
    params.itemsPerPage = pageSize;
  }
  if (page !== null) {
    params.page = page;
  }

  let allFilters = filters || [];
  if (handleAdditionalFilters) {
    const additionalFilter = await handleAdditionalFilters(filters);
    if (additionalFilter) {
      allFilters = [...allFilters, ...additionalFilter];
    }
  }

  allFilters.forEach(({ id, value }) => {
    params[id] = value;
  });
  if (sort) {
    params[`order[${sort.key}]`] = sort.value;
  } else if (!sort && path === "/companies-export") {
    params["order[shortName]"] = "asc";
  }
  const queryString = getQueryString(params, false);
  return `${path}${queryString}`;
};

export default buildExportPath;
