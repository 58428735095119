import { Input } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";
import RbsButton from "src/utils/RoleBasedSecurity/SecurityComponents/RbsButton";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import prettyBytes from "src/utils/prettyBytes/prettyBytes";

export default function FileInput({
  id,
  onChange,
  disabled,
  invalid,
  value,
  permission,
  color,
  btnText,
  chooseFilePopup,
}) {
  const fileName =
    value && value[0]
      ? `${value[0]?.name} (${prettyBytes(value[0]?.size)})`
      : null;

  const handleChooseFileClick = (fileInput) =>
    getUserConfirmationPopup(
      chooseFilePopup?.message,
      (confirm) => {
        if (confirm && fileInput) {
          fileInput.click();
        }
      },
      chooseFilePopup?.title
    );

  return (
    <span>
      <RbsButton
        permission={permission}
        className={invalid ? "border-danger text-danger" : undefined}
        color={color || "secondary"}
        type="button"
        disabled={disabled}
        onClick={(e) => {
          const fileInput = e.target.querySelector("input");
          if (fileInput) {
            handleChooseFileClick(fileInput);
          }
        }}
      >
        {__(btnText) || __("Wybierz plik")}
        <Input
          // It is a trick to allow upload twice file with the same name
          key={chooseFilePopup ? fileName?.length : id + Math.random()}
          className="d-none"
          data-t1={id}
          type="file"
          id={id}
          name={id}
          label="Wybierz plik"
          onChange={onChange}
        />
      </RbsButton>
      {fileName}
    </span>
  );
}

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  permission: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.number,
    })
  ),
  chooseFilePopup: PropTypes.shape({
    message: PropTypes.string,
    title: PropTypes.string,
  }),
  color: PropTypes.string,
  btnText: PropTypes.string,
};

FileInput.defaultProps = {
  disabled: false,
  invalid: false,
  permission: null,
  value: {},
  color: null,
  btnText: null,
  chooseFilePopup: null,
};
