import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import __ from "src/utils/Translations";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import Employees from "src/Pages/Company/CompanyEvents/Employees";
import TabsWithMemory from "src/Components/Tabs/TabsWithMemory";
import { useHistory } from "react-router-dom";

export const getActiveTab = (hashValue) => {
  const hash = hashValue
    ? hashValue.replace("#", "")
    : "company_events_ahr_employees";
  return hash.split("/")[0];
};

export default () => {
  const companyName = useCompanyName();
  const companyId = useCompanyId();
  const history = useHistory();
  const activeKey = getActiveTab(history.location.hash);
  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={`Zarządzanie wydarzeniami dla firmy ${companyName} (ID: ${companyId})`}
        breadcrumbsHeading={__("Zarządzanie wydarzeniami")}
        pushToHistory
        breadcrumbs={[]}
      />
      <TabsWithMemory
        activeKey={activeKey}
        defaultActiveKey="company_events_ahr_employees"
        tabsConfig={[
          {
            name: `${__("Przegląd danych")}`,
            key: "company_events_ahr_employees",
            component: <Employees companyId={companyId} />,
          },
          {
            name: `${__("Zarządzanie imprezami")}`,
            key: "company_events_ahr_manage",
            component: <></>,
            link: generateUrl(),
          },
        ]}
      />
    </CSSTransitionGroup>
  );
};

const generateUrl = () => {
  const isProduction = window.location.href.includes("adm-kafeteria");
  const stageUrl =
    "https://kafeteria.staging.aws.mybenefit.pl/company-events/event/list";
  const prodUrl = "https://kafeteria.mybenefit.pl/company-events/event/list";
  return isProduction ? prodUrl : stageUrl;
};
