import { useState, useEffect } from "react";
import { OPERATOR_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import { mockData } from "src/Pages/User/Operator/List";
import __ from "src/utils/Translations";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import { operatorOperatorPermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

// export default function useOperatorRoles(asOption = false, idAsIri = false) {
export default function useOperators(
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false,
  noFetching = false
) {
  const [data, setData] = useState([]);
  const hasPermission = useHasPermission(operatorOperatorPermissionRead);
  const skipAction = !hasPermission || noFetching;
  useEffect(() => {
    if (skipAction) {
      setData([]);
      return;
    }
    const params = {
      itemsPerPage: 10000,
    };
    if (filterField && filterValue) {
      params[filterField] = filterValue;
    }
    restApiRequest(
      OPERATOR_MANAGEMENT_SERVICE,
      "/operators",
      "GET",
      { params },
      mockData
    )
      .then((resData) => {
        let result = resData;
        if (idAsIri) {
          result = result.map((el) => ({
            ...el,
            id: `${IRI_PREFIX}/${el.id}`,
          }));
        }
        if (asOption) {
          result = result.map(({ id, firstName, lastName, username }) => ({
            value: id,
            label: `${firstName} ${lastName} (${username})`,
          }));
        }
        setData(result);
      })
      .catch((e) => {
        newRelicErrorReport(e, "utils/hooks/operator/useOperators.js - 56");
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy operatorów"),
          "error"
        );
      });
  }, [filterField, filterValue, idAsIri, asOption, skipAction]);

  return data;
}

export const IRI_PREFIX = "/api/operator-management/v1/rest/operators";
