import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import { useCompanyHasFunctionality } from "src/Pages/Company/CompanyContext";
import { subscriptionManagementConfigurationStatusWrite } from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import ContentLoading from "src/Components/Loading/contentLoading";
import Form from "src/Components/Form";
import { LAYOUT_TWO_COLUMNS } from "src/Components/Layouts";
import CanceledSubscriptions from "./CanceledSubscriptions";
import ActiveSubscriptions from "./ActiveSubscriptions";
import Popup from "./Popup";
import {
  STATUS_ACTIVE,
  STATUS_SUSPENDED,
  STATUS_PENDING,
  STATUS_NEW_PERSON,
  STATUS_PENDING_ACCEPTED,
  STATUS_PROCESSING,
  STATUS_BENEFIT_PRICING_CHANGED,
} from "./utils";

const ACTIVE_STATUSES = [
  STATUS_ACTIVE,
  STATUS_SUSPENDED,
  STATUS_PENDING,
  STATUS_NEW_PERSON,
  STATUS_PENDING_ACCEPTED,
  STATUS_PROCESSING,
  STATUS_BENEFIT_PRICING_CHANGED,
];
const subscriptionConfigurationUrl = "/subscription-configuration-statuses";

export default function Subscriptions({
  active,
  employeeId,
  companyId,
  isNew,
}) {
  const hasSubscriptionsEnabled = useCompanyHasFunctionality(
    "ENABLE_PERIOD_SUBSCRIPTION_AND_IDEAL_CAFETERIA"
  );
  const hasSubscriptionsUmsEnabled = useCompanyHasFunctionality(
    "ENABLE_OTHER_PERIOD_SUBSCRIPTION_UMS"
  );
  const hasBothSubscriptionGroupsEnabled =
    hasSubscriptionsEnabled && hasSubscriptionsUmsEnabled;
  const [data, updateData] = useState({});
  const [subscriptionConfigurationId, setSubscriptionConfigurationId] =
    useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const tableApiRef = useRef(null);

  const formData = data || {};

  useEffect(() => {
    restApiRequest(
      SUBSCRIPTION_MANAGEMENT_SERVICE,
      subscriptionConfigurationUrl,
      "GET",
      {
        params: {
          employeeId,
        },
        headers: {
          accept: "application/ld+json",
        },
      }
    )
      .then((response) => {
        if (_.isEmpty(response)) {
          dynamicNotification(
            __(
              "Nie udało się pobrać konfiguracji świadczeń abonamentowych pracownika."
            ),
            "error"
          );
        }

        const subscriptionConfiguration = response["hydra:member"][0];
        const { id } = subscriptionConfiguration || {};

        updateData(subscriptionConfiguration);
        setSubscriptionConfigurationId(id);
      })
      .catch((e) => {
        newRelicErrorReport(
          e,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/index.js - 93"
        );
        dynamicNotification(
          e.message ||
            __(
              "Nie udało się pobrać konfiguracji świadczeń abonamentowych pracownika."
            ),
          "error"
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      updateData(updatedData);
    },
    [data]
  );

  const onRangeChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      const { from, to } = value;
      updatedData[`${key}From`] = from || null;
      updatedData[`${key}To`] = to || null;
      updateData(updatedData);
    },
    [data]
  );

  const reloadSubscriptions = useCallback(async () => {
    try {
      setIsLoading(true);
      const params = {
        "subscriptionItem.subscription.ownerId": employeeId,
        "order[subscriptionItem.id]": "asc",
        "order[type]": "asc",
      };

      const headers = {
        accept: "application/ld+json",
      };

      const activeResult = await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/subscription-item-receivers`,
        "GET",
        {
          params: {
            ...params,
            receiverStatus: ACTIVE_STATUSES,
            itemsPerPage: 100,
          },
          headers,
        },
        []
      );

      const activeItems = activeResult?.["hydra:member"].map((item) => ({
        ...item,
        status:
          item.packageType === "group" ||
          item.subscriptionItemStatus === STATUS_BENEFIT_PRICING_CHANGED
            ? item.subscriptionItemStatus
            : item.status,
        ...{
          employerPriceSummary: +item.employerPrice + +item.pointsBankPrice,
        },
      }));

      setActiveSubscriptions(activeItems);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/index.js -168"
      );
      dynamicNotification(
        e.message || __("Nie udało się pobrać listy świadczeń pracownika."),
        "error"
      );
    }
    setIsLoading(false);
  }, [employeeId]);

  useEffect(() => {
    if (active && !isNew) {
      reloadSubscriptions();
    }
  }, [active, reloadSubscriptions, isNew]);

  if (!active) {
    return null;
  }

  const openPopup = ({
    type,
    id,
    itemsFromArg = [],
    forceProcessItem = false,
  }) => {
    const subscription = [...activeSubscriptions, ...itemsFromArg].find(
      ({ id: itemId }) => itemId === id
    );
    if (subscription) {
      setPopupData({ type, subscription, forceProcessItem });
    } else {
      setPopupData(null);
    }
  };

  const closePopup = (reload = false) => {
    setPopupData(null);
    if (reload) {
      reloadSubscriptions();
      tableApiRef.current.refetch();
    }
  };

  const submit = async () => {
    const requestUrl = subscriptionConfigurationId
      ? `${subscriptionConfigurationUrl}/${subscriptionConfigurationId}`
      : subscriptionConfigurationUrl;

    try {
      const response = await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        requestUrl,
        subscriptionConfigurationId ? "PATCH" : "POST",
        {
          body: {
            employeeId,
            ...formData,
          },
        }
      );

      updateData(response);
      dynamicNotification(
        __(
          "Pomyślnie zapisano konfigurację świadczeń abonamentowych pracownika."
        )
      );
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/index.js - 230"
      );
      dynamicNotification(
        e.message ||
          __(
            "Nie udało się zapisać konfiguracji świadczeń abonamentowych pracownika."
          ),
        "error"
      );
    }
  };

  const prepActiveSubscription = activeSubscriptions.filter(
    (ele) => ele.representative
  );

  return (
    <ContentLoading show={isLoading}>
      <Form
        id="employeeSubscriptions"
        data={formData}
        data1="employeeData"
        config={{
          title: `${__("Indywidualne okno świadczeń abonamentowych")}`,
          onSubmit: submit,
          stickyTitle: false,
          defaultOnChange: onChange,
          buttons: [
            {
              id: "submit",
              size: "lg",
              color: "primary",
              className: "mr-2",
              text: "Zapisz",
              type: "submit",
              permission: subscriptionManagementConfigurationStatusWrite,
            },
          ],
          formGroups: [
            {
              formElements: [
                {
                  layout: LAYOUT_TWO_COLUMNS,
                  formElements: [
                    {
                      id: "individualSubscriptionWindow",
                      data1: "subscriptionWindow",
                      label: __(
                        "Indywidualne okno wyboru abonamentów (grupa I):"
                      ),
                      type: "dateRange",
                      onChange: onRangeChange,
                      validation: ["rangeRequiredDependable"],
                      props: {
                        disabled: !hasSubscriptionsEnabled,
                      },
                      ...(hasBothSubscriptionGroupsEnabled && {
                        tooltip: {
                          type: "info",
                          content: __(
                            "Abonamenty z grupy I to abonamenty skonfigurowane na wirtualnym limicie opłacanym przez pracodawcę, nie mają wpływu na punkty dostępne w bankach punktów pracownika."
                          ),
                        },
                      }),
                    },
                    {
                      id: "individualSubscriptionWindowUms",
                      data1: "subscriptionWindow",
                      label: __(
                        "Indywidualne okno wyboru abonamentów (grupa II - UMS):"
                      ),
                      type: "dateRange",
                      onChange: onRangeChange,
                      validation: ["rangeRequiredDependable"],
                      props: {
                        disabled: !hasSubscriptionsUmsEnabled,
                      },
                      ...(hasBothSubscriptionGroupsEnabled && {
                        tooltip: {
                          type: "info",
                          content: __(
                            "Abonamenty z grupy II opłacane są z banku punktów."
                          ),
                        },
                      }),
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
      <div className="pb-3">
        <ActiveSubscriptions
          items={prepActiveSubscription}
          openPopup={openPopup}
          companyId={companyId}
        />
      </div>
      <div className="pb-3">
        <CanceledSubscriptions
          openPopup={openPopup}
          companyId={companyId}
          employeeId={employeeId}
          tableApiRef={tableApiRef}
          activeSubscriptions={prepActiveSubscription}
        />
      </div>
      {popupData ? (
        <Popup
          close={closePopup}
          subscription={popupData.subscription}
          type={popupData.type}
          openPopup={openPopup}
          forceProcessItem={popupData.forceProcessItem}
        />
      ) : null}
    </ContentLoading>
  );
}
Subscriptions.propTypes = {
  active: PropTypes.bool,
  employeeId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
};
Subscriptions.defaultProps = {
  active: false,
};
