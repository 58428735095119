/* eslint-disable react/prop-types */
import React from "react";

import { Button } from "reactstrap";

import __ from "src/utils/Translations";
import ButtonsList from "src/Components/ButtonsList";
import ExportButton from "src/Components/DataTableControlled/ExportButtons/ExportButton";

const getTableButtons = ({
  buttons,
  exportContext,
  dataCount,
  currentPageSize,
  currentPage,
  filters,
  currentSort,
  filterable,
  resetFilters,
}) =>
  buttons.length ? (
    <div className="listing-btn-container m-2 text-right">
      {exportContext ? (
        <ExportButton
          noResults={dataCount === 0}
          service={exportContext.getService()}
          path={exportContext.getPath()}
          fileName={exportContext.getFileName()}
          permissions={exportContext.getPermission()}
          hideIfNoPermission={exportContext.getHideIfNoPermission()}
          handleAdditionalFilters={exportContext.handleAdditionalFilters}
          pageSize={currentPageSize}
          page={currentPage}
          filters={filters}
          sort={currentSort}
          buttonTitle={exportContext.getButtonTitle()}
          customDropdownItems={exportContext.getCustomDropdownItems()}
        />
      ) : null}
      <ButtonsList buttons={buttons} />
      {filterable && (
        <div className="text-right mt-2">
          <Button
            data-t1="gridFiltersClear"
            className="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-dashed btn-pill"
            outline
            color="danger"
            onClick={resetFilters}
          >
            {__("Resetuj filtry")}
            <i className="pe-7s-close btn-icon-wrapper"> </i>
          </Button>
        </div>
      )}
    </div>
  ) : null;

export default getTableButtons;
