export default function ValueFormatter(formatter, value) {
  let parsed;
  let float;
  let isNegative;
  let dotPosition;
  if (typeof formatter === "string") {
    switch (formatter) {
      case "number":
        return +value;
      case "integer":
        return parseInt(value.replace(/\D/g, ""), 10);
      case "float":
        return value
          .replace(/[,]/g, ".")
          .split(".")
          .slice(0, 2)
          .join(".")
          .replace(/[^.\d]/g, "");
      case "price":
        float = value
          .replace(/[,]/g, ".")
          .split(".")
          .slice(0, 2)
          .join(".")
          .replace(/[^.\d]/g, "");
        if (float.split(".")[1]?.length > 2) float = Number(float).toFixed(2);
        return float;
      case "price_no_rounding":
        float = value
          .replace(/[,]/g, ".")
          .split(".")
          .slice(0, 2)
          .join(".")
          .replace(/[^.\d]/g, "");
        dotPosition = float.indexOf(".");
        return dotPosition === -1 ? float : float.substring(0, dotPosition + 3);
      case "price_with_negative":
        isNegative = Boolean(value?.indexOf("-") === 0);
        float = value
          .replace(/[,]/g, ".")
          .replace(/[^.\d]/g, "")
          .replace(/^\.+/, "");
        float = float
          .split("")
          .filter((item, pos, self) =>
            Boolean(item !== "." || self.indexOf(item) === pos)
          )
          .join("");
        if (isNegative) {
          float = `-${float}`;
        }
        dotPosition = float.indexOf(".");
        return dotPosition === -1 ? float : float.substring(0, dotPosition + 3);
      case "only_digits":
        return value.replace(/\D/g, "");
      case "ipv4":
        return value.replace(/[^0-9./-]/g, "");
      case "comma_to_dot":
        return value.replace(/[,]/g, ".");
      case "no_whitespaces":
        return value.replace(/\s+/g, "").trim();
      case "post_code":
        parsed = value.replace(/[^0-9-]/g, "");
        if (parsed.length === 3 && !parsed.includes("-")) {
          parsed = `${parsed.substr(0, 2)}-${parsed.substr(2, 3)}`;
        }
        return parsed.substr(0, 6);
      default:
        return value;
    }
  } else if (typeof formatter === "function") {
    return formatter(value);
  }
  return value;
}
