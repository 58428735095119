import React from "react";
import { Route } from "react-router-dom";
import { match as matchPropTypes } from "react-router-prop-types";
import { AhrMainNav } from "src/Layout/AppNav/NavItems";

// Pages
import TilesMenu from "src/Components/TilesMenu";
import SecureRoute from "src/utils/RoleBasedSecurity/SecurityComponents/SecureRoute";
import {
  employeeEmployeePermissionRead,
  notificationAppMessagePermissionRead,
  companyReportAccessPasswordRead,
  subscriptionEmployeeSubscriptionItemAccept,
  employeeMultisportCardAdministrator,
  proposalsModulePermissionRead,
  totalRewardModule,
  totalRewardSettings,
  totalRewardPerkUsersRead,
  totalRewardPerkUsersImportExport,
  totalRewardPerksRead,
  totalRewardPerkCategoriesRead,
} from "src/utils/RoleBasedSecurity/permissions";
import Proposals from "src/External/proposals";
import { useCompanyName } from "src/Pages/Company/CompanyContext";
import Password from "src/Pages/Password/Edit";
import __ from "src/utils/Translations";
import CompanyEmployeesList from "./CompanyEmployees/List";
import CompanyEmployeesEdit from "./CompanyEmployees/Edit";
import PendingBenefits from "./BenefitsPending";
import PendingBenefitsMultisport from "./BenefitsMultisportPending";
import CompanyEvents from "./CompanyEvents";
import TotalRewardSettings from "./TotalRewardStatement/Settings";
import TotalRewardPerkUsers from "./TotalRewardStatement/PerkUsers";
import TotalRewardImportExport from "./TotalRewardStatement/Import";
import TotalRewardPerks from "./TotalRewardStatement/Perks";
import TotalRewardPerkEdit from "./TotalRewardStatement/Perks/edit";
import TotalRewardCategories from "./TotalRewardStatement/Categories";
import TotalRewardCategoryEdit from "./TotalRewardStatement/Categories/edit";
import AccessToken from "./AccessToken";
import Report from "./Report";
import AppMessagesList from "./Notification/AppMessages/List";
import AppMessagesEdit from "./Notification/AppMessages/Edit";

const AhrCore = ({ match }) => {
  const companyName = useCompanyName();
  const ahrTilesNav = AhrMainNav.map((item) => {
    if (item.tail === false) {
      return null;
    }
    return item;
  }).filter(Boolean);
  return (
    <>
      <Route
        exact
        path={`${match.url}`}
        render={() => (
          <TilesMenu
            config={ahrTilesNav}
            title={`${__("Zarządzanie firmą")} ${companyName}`}
            breadcrumbs={[]}
          />
        )}
      />
      <SecureRoute
        permission={employeeEmployeePermissionRead}
        exact
        path={`${match.url}/employees`}
        component={CompanyEmployeesList}
      />
      <SecureRoute
        permission={employeeEmployeePermissionRead}
        exact
        path={`${match.url}/employees/:employeeId`}
        component={CompanyEmployeesEdit}
      />
      <SecureRoute
        permission={subscriptionEmployeeSubscriptionItemAccept}
        exact
        path={`${match.url}/pending-benefits`}
        component={PendingBenefits}
      />
      <SecureRoute
        permission={employeeMultisportCardAdministrator}
        exact
        path={`${match.url}/pending-benefits-multisport`}
        component={PendingBenefitsMultisport}
      />
      <SecureRoute
        permission={companyReportAccessPasswordRead}
        exact
        path={`${match.url}/access`}
        component={AccessToken}
      />
      <Route path={`${match.url}/company-events`} component={CompanyEvents} />
      <SecureRoute
        permission={proposalsModulePermissionRead}
        path={`${match.url}/proposals`}
        component={Proposals}
      />
      <SecureRoute
        permission={totalRewardModule}
        path={`${match.url}/total-reward`}
        exact
        render={() => (
          <TilesMenu
            config={
              AhrMainNav.find((el) => el.id === "totalRewardStatement").content
            }
            title="Total Reward Statement"
            breadcrumbs={[
              {
                title: `${__("Zarządzanie firmą")} ${companyName}`,
                link: `${match.url}`,
              },
            ]}
          />
        )}
      />
      <SecureRoute
        permission={totalRewardSettings}
        path={`${match.url}/total-reward/settings`}
        exact
        component={TotalRewardSettings}
      />
      <SecureRoute
        permission={totalRewardPerkUsersRead}
        path={`${match.url}/total-reward/perk-users`}
        exact
        component={TotalRewardPerkUsers}
      />
      <SecureRoute
        permission={totalRewardPerkUsersImportExport}
        path={`${match.url}/total-reward/perkusers-import`}
        exact
        component={TotalRewardImportExport}
      />
      <SecureRoute
        permission={totalRewardPerksRead}
        path={`${match.url}/total-reward/perks`}
        exact
        component={TotalRewardPerks}
      />
      <SecureRoute
        permission={totalRewardPerksRead}
        path={`${match.url}/total-reward/perks/:perkId`}
        exact
        component={TotalRewardPerkEdit}
      />
      <SecureRoute
        permission={totalRewardPerkCategoriesRead}
        path={`${match.url}/total-reward/categories`}
        exact
        component={TotalRewardCategories}
      />
      <SecureRoute
        permission={totalRewardPerkCategoriesRead}
        path={`${match.url}/total-reward/categories/:categoryId`}
        exact
        component={TotalRewardCategoryEdit}
      />
      <Route path={`${match.url}/report`} component={Report} />
      <Route
        exact
        path={`${match.url}/notification`}
        render={() => (
          <TilesMenu
            config={AhrMainNav.find((el) => el.id === "notification").content}
            title={__("Powiadomienia")}
            breadcrumbs={[
              {
                title: `${__("Zarządzanie firmą")} ${companyName}`,
                link: `${match.url}`,
              },
            ]}
          />
        )}
      />
      <SecureRoute
        permission={notificationAppMessagePermissionRead}
        exact
        path={`${match.url}/notification/app-messages`}
        component={AppMessagesList}
      />
      <SecureRoute
        permission={notificationAppMessagePermissionRead}
        exact
        path={`${match.url}/notification/app-messages/:appMessageId`}
        component={AppMessagesEdit}
      />
      {/* Password change */}
      <Route path="/ahr/password" component={Password} />
    </>
  );
};

export default AhrCore;

AhrCore.propTypes = {
  match: matchPropTypes.isRequired,
};
