export const HEADER_SECTION_TYPE = "header";
export const FORM_SECTION_TYPE = "form";
export const CONTENT_SECTION_TYPE = "content";

export const FIELD_TYPES_CONFIG = [
  { code: "text", name: "Tekst", defaultLabel: "" },
  { code: "number", name: "Numer", defaultLabel: "" },
  { code: "checkbox", name: "Checkbox", defaultLabel: "" },
  { code: "firstname", name: "Imię", defaultLabel: "Imię" },
  { code: "lastname", name: "Nazwisko", defaultLabel: "Nazwisko" },
  { code: "email", name: "Adres e-mail", defaultLabel: "Adres e-mail" },
  { code: "phone", name: "Numer telefonu", defaultLabel: "Numer telefonu" },
  { code: "pesel", name: "PESEL", defaultLabel: "PESEL" },
  { code: "postcode", name: "Kod pocztowy", defaultLabel: "Kod pocztowy" },
  { code: "date", name: "Data", defaultLabel: "Data" },
  { code: "birthDate", name: "Data urodzenia", defaultLabel: "Data urodzenia" },
  { code: "requirePesel", name: "wymagalność PESEL", defaultValue: null },
  { code: "select", name: "Lista rozwijana", defaultLabel: null },
];

export const availableFields = FIELD_TYPES_CONFIG.map(({ code }) => code);
