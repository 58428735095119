/* eslint-disable no-underscore-dangle, react/destructuring-assignment, react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody, CardText } from "reactstrap";
import Form from "src/Components/Form";
import DataTable from "src/Components/DataTable";
import ActionColumn from "src/Components/DataTable/actionColumn";
import __ from "src/utils/Translations";
import { API_COMPANYEVENTS_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import ChangeDepartmentForm from "./changeDepartmentForm";

export default function Employees({ companyId }) {
  const [data, setData] = useState({ employee: null });
  const [itemToPopup, setItemToPopup] = useState(false);

  const openPopup = (item) => {
    setItemToPopup(item);
  };

  const closePopup = (refresh = false) => {
    setItemToPopup(null);
    if (refresh) {
      onSubmit();
    }
  };

  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      setData(updatedData);
    },
    [data]
  );

  const onSubmit = async () => {
    try {
      const result = await restApiRequest(
        API_COMPANYEVENTS_SERVICE,
        `/omb/${companyId}/employees/find`,
        "POST",
        { body: { search: data.employee } }
      );

      setEmployees(result);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/CompanyEvents/Employees/index.js - 35"
      );
      dynamicNotification(
        e.message || __("Nie udało się pobrać danych"),
        "error"
      );
    }
  };

  const [employees, setEmployees] = useState([]);

  return (
    <>
      <Card>
        <CardHeader className="card-header-lg text-transform-none">
          <div className="card-header-title font-size-lg font-weight-normal">
            {__("Zmiana przypisania pracownika w dziale")}
          </div>
        </CardHeader>
        <CardBody>
          <Form
            id="employeeSearch"
            data={data}
            config={{
              onSubmit,
              defaultOnChange: onChange,
              noCards: true,
              translateTitle: true,
              formGroups: [
                {
                  formElements: [
                    {
                      id: "employee",
                      label: "Imię, nazwisko lub numer pracownika",
                      type: "text",
                      validation: ["required"],
                    },
                  ],
                },
                {
                  className: "mt-n4",
                  formElements: [
                    {
                      id: "employeeSearchSubmit",
                      label: "Szukaj",
                      type: "button",
                      props: {
                        type: "submit",
                      },
                    },
                  ],
                },
              ],
            }}
          />
          <CardText>
            <DataTable
              id="modulesLisitng"
              data={employees || []}
              filterable
              noCards
              showPagination={false}
              columns={[
                {
                  Header: __("ID"),
                  accessor: "employeeId",
                },
                {
                  Header: __("Imię"),
                  accessor: "employeeFirstname",
                },
                {
                  Header: __("Nazwisko"),
                  accessor: "employeeLastname",
                },
                {
                  Header: __("Numer"),
                  accessor: "employeeExternalId",
                },
                {
                  Header: __("Dział"),
                  accessor: "departmentName",
                },
                {
                  Header: __("Numer działu"),
                  accessor: "departmentNumber",
                },
                {
                  Header: __("Akcja"),
                  accessor: "action",
                  filterable: false,
                  sortable: false,
                  Cell: ({ row }) => (
                    <div className="d-block w-100 text-center">
                      <ActionColumn
                        data={row._original}
                        buttons={[
                          {
                            id: "companyEeventsEmployeeDepartmentEdit",
                            color: "link",
                            label: "Zmień dział",
                            onClick: () => openPopup(row._original),
                          },
                        ]}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </CardText>
        </CardBody>
      </Card>
      {itemToPopup && (
        <ChangeDepartmentForm
          close={closePopup}
          companyId={companyId}
          item={itemToPopup}
        />
      )}
    </>
  );
}

Employees.propTypes = {
  companyId: PropTypes.string.isRequired,
};
