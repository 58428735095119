class UserInfo {
  constructor({
    id,
    role,
    companyId,
    firstName,
    businessEmail,
    personalEmail,
    lastName,
    permissions = [],
    username,
    relations = {},
  }) {
    this.getId = () => id;
    this.getRole = () => role;
    this.getFirstName = () => firstName;
    this.getLastName = () => lastName;
    this.getPermissions = () => permissions;
    this.getRelations = () => relations;
    this.getBusinessEmail = () => businessEmail;
    this.getPersonalEmail = () => personalEmail;
    this.getEmail = this.getBusinessEmail;
    this.getUsername = () => username;
    this.getCompanyId = () =>
      companyId || relations?.administeredCompanies?.[0];
    this.isAhr = () => role === "ahr";
    this.hasAccessToPanel = () => ["ahr", "omb"].includes(role);
  }
}

export default UserInfo;
