import PropTypes from "prop-types";
import React from "react";
import { DropdownItem } from "reactstrap";
import __ from "src/utils/Translations";
import { downloadFile, getFullFileName } from "src/utils/Api";
import { MAX_EXPORT_ITEMS } from "src/Components/DataTableControlled/consts";
// eslint-disable-next-line import/no-cycle
import { buildExportPath } from "src/Components/DataTableControlled/utils";

const CustomDropdownItem = ({
  disabled = false,
  service,
  path,
  filters,
  handleAdditionalFilters,
  sort,
  pageSize = MAX_EXPORT_ITEMS,
  page,
  dataT1 = "export-page",
  title,
  exportAll = false,
  fileType = "csv",
  fileName,
}) => (
  <DropdownItem
    disabled={disabled}
    onClick={async () => {
      downloadFile(
        service,
        await buildExportPath(
          path,
          filters,
          handleAdditionalFilters,
          sort,
          pageSize,
          page
        ),
        getFullFileName(fileName, exportAll, fileType)
      );
    }}
  >
    <span data-t1={dataT1}>{__(title)}</span>
  </DropdownItem>
);

CustomDropdownItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  service: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  handleAdditionalFilters: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  dataT1: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  exportAll: PropTypes.bool.isRequired,
  fileType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

CustomDropdownItem.defaultProps = {
  sort: null,
};

export default CustomDropdownItem;
