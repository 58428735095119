import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "src/Components/Form";
import Popup from "src/Components/Popup/popup";
import { banksBanksPermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { COMPANY_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import uuid from "src/utils/jsHelpers/uuid";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function BankForm({ close, companyId, item }) {
  const [data, updateData] = useState({});

  const { isNew } = item;

  useEffect(() => {
    updateData({ ...item });
  }, [item]);

  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      updateData(updatedData);
    },
    [data]
  );

  const submit = async () => {
    try {
      await restApiRequest(
        COMPANY_MANAGEMENT_SERVICE,
        isNew ? "/points-banks/create" : "/points-bank",
        isNew ? "POST" : "PATCH",
        {
          body: isNew
            ? {
                pointsBankId: uuid(),
                pointsBankOwnerId: companyId,
                bankNameDocId: data.fullName,
                fullName: data.fullName,
                settlementType: data.settlementType,
              }
            : {
                pointsBankId: data.pointsBankId,
                fullName: data.fullName,
              },
          headers: {
            "content-type": isNew
              ? "application/json"
              : "application/merge-patch+json",
          },
          returnNull: true,
        }
      );
      dynamicNotification(__("Pomyślnie zapisano bank punktów"));
      close(true);
    } catch (e) {
      newRelicErrorReport(
        e,
        "Pages/Company/CompanyBanks/Management/Listing/bankForm.js - 58"
      );
      dynamicNotification(
        e.message || __("Nie udało się zapisać banku punktów"),
        "error"
      );
    }
  };

  return (
    <>
      <Popup id="newBankFormPopup" isOpen toggle={() => close()} unmountOnClose>
        <Form
          id="newBankForm"
          data={data}
          config={{
            isInPopup: true,
            togglePopup: () => close(),
            title: isNew
              ? "Dodaj nowy bank"
              : `Edycja banku ${item.fullName} (${item.pointsBankId})`,
            onSubmit: submit,
            buttons: [
              {
                onClick: () => close(),
                text: "Zamknij",
                color: "light",
                id: "newBankFormClose",
              },
              {
                text: "Zapisz",
                type: "submit",
                id: "newBankFormSubmit",
                permission: banksBanksPermissionWrite,
              },
            ],
            defaultOnChange: onChange,
            noCards: true,
            formGroups: [
              {
                formElements: [
                  {
                    id: "fullName",
                    label: "Nazwa:",
                    type: "text",
                    validation: ["required"],
                    translatable: {
                      scope: "companyManagement:newBank:name",
                    },
                  },
                  {
                    id: "settlementType",
                    label: "Rozliczenia:",
                    type: "select",
                    props: {
                      disabled: !isNew,
                    },
                    options: settlementTypeOptions,
                    validation: ["required"],
                  },
                ],
              },
            ],
          }}
        />
      </Popup>
    </>
  );
}

export const SETTLEMENT_TYPE_IN_ADVANCE = "IN_ADVANCE";
export const SETTLEMENT_TYPE_AFTERWARDS = "AFTERWARDS";
export const SETTLEMENT_TYPE_AFTERWARDS_ZFSS = "AFTERWARDS_ZFSS";
export const settlementTypeOptions = [
  { value: SETTLEMENT_TYPE_IN_ADVANCE, label: "Z góry" },
  { value: SETTLEMENT_TYPE_AFTERWARDS, label: "Z dołu" },
  { value: SETTLEMENT_TYPE_AFTERWARDS_ZFSS, label: "Z dołu ZFŚS" },
];

BankForm.propTypes = {
  close: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    isNew: PropTypes.bool,
    pointsBankId: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
};
