import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

async function findData(inputText, setResult, filter, rbsContext) {
  if (inputText && inputText.length > 1) {
    try {
      const result = await filter(inputText, rbsContext);
      setResult(result);
    } catch (e) {
      newRelicErrorReport(e, "Components/SearchBox/utils/findData.js - 10");
      console.error(e);
    }
  }
}

export default findData;
