import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import Form from "src/Components/Form";
import { useLanguages } from "src/utils/Languages/LanguageContext";
import Popup from "src/Components/Popup/popup";

export default function TranslationForm({
  close,
  initialData,
  language,
  onSave,
}) {
  const [data, updateData] = useState({ ...initialData });
  const [isEdited, setIsEdited] = useState(false);

  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      updateData(updatedData);
      setIsEdited(true);
    },
    [data]
  );
  const languages = useLanguages();
  const currentLanguage = languages.find((lang) => lang.code === language);
  const title = `Tłumaczenie na ${
    currentLanguage ? currentLanguage.label.toLowerCase() : ""
  }`;
  return (
    <>
      <Popup
        id="translationPopup"
        isOpen
        toggle={close}
        unmountOnClose
        size="lg"
        className="modal-xxl"
        isEdited={isEdited}
      >
        <Form
          id="translationForm"
          data={data}
          config={{
            isInPopup: true,
            buttons: [
              {
                text: "Zapisz",
                type: "submit",
                color: "primary",
              },
            ],
            onSubmit: () => {
              close();
              onSave(data.id, data.translation);
            },
            title,
            defaultOnChange: onChange,
            noCards: true,
            formGroups: [
              {
                formElements: [
                  {
                    id: "phrase",
                    label: "Oryginalna wartość:",
                    type: "text",
                    props: {
                      disabled: true,
                    },
                  },
                  {
                    id: "translation",
                    label: "Tłumaczenie:",
                    type: "text",
                  },
                ],
              },
            ],
          }}
        />
      </Popup>
    </>
  );
}
TranslationForm.propTypes = {
  close: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};
