import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import Creatable from "react-select/async-creatable";

import PropTypes from "prop-types";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { GetErrorMessage } from "src/Pages/Report/Validation/validations";

const SendMethodModal = ({
  isOpen,
  toggle,
  subscribeMethod,
  subscribeMethodInfo,
  handleSubscribeMethodSave,
  formatDropdown,
  ahrsList,
  isTokenAvailable,
}) => {
  const close = () => {
    setSendInfoData(subscribeMethodInfo);
    toggle(false);
  };

  const [sendInfoData, setSendInfoData] = useState({
    ...subscribeMethodInfo,
  });

  const rbsContext = useContext(RbsContext);
  const { userInfo } = rbsContext;
  const isAhr = userInfo.isAhr();
  const email = userInfo.getEmail();
  const userId = userInfo.getId();

  const handleChange = (e) => {
    const { name, value } = e.target;

    const obj = { ...sendInfoData, [name]: value };
    setSendInfoData(obj);
  };

  const handleFormatSelectChange = (data) => {
    setSendInfoData({ ...sendInfoData, format: data });
  };

  const handleRecipientsChange = (data) =>
    setSendInfoData({ ...sendInfoData, recipients: data });
  const handleAhrsChange = (data) =>
    setSendInfoData({ ...sendInfoData, ahrList: data });

  const handleHiddenRecipientsChange = (data) =>
    setSendInfoData({ ...sendInfoData, hiddenRecipients: data });

  const handleMessageChange = (id, data) => {
    setSendInfoData({ ...sendInfoData, messageContent: data });
  };

  const handleSaveClick = () => {
    let isError = false;
    if (sendInfoData.recipients.length < 1 && sendInfoData.ahrList.length < 1) {
      dynamicNotification(
        GetErrorMessage(__('"Adresaci" lub "Lista AHR"')),
        "error"
      );
      isError = true;
    }
    if (sendInfoData.messageTitle.length < 1) {
      dynamicNotification(GetErrorMessage(__('"Tytuł wiadomości"')), "error");
      isError = true;
    }
    if (!isError) {
      handleSubscribeMethodSave(sendInfoData);
    }
  };

  const handleBlurRecipients = (e) => {
    const data = e.target.value;
    const newData = { ...sendInfoData };

    if (data !== "") newData.recipients.push({ label: data, value: data });
  };

  const handleBlurHiddenRecipients = (e) => {
    const data = e.target.value;
    const newData = { ...sendInfoData };

    if (data !== "")
      newData.hiddenRecipients.push({ label: data, value: data });
  };

  const title =
    subscribeMethod.value === -1
      ? __("Edycja danych")
      : __(subscribeMethod.label); // : subscribeMethod.label;
  const formatRender = () => {
    if (subscribeMethod.value === "2") {
      return (
        <div className="col-md-12">
          <Label>{__("Format")}</Label>
          <Select
            name="format"
            className="basic-select"
            classNamePrefix="select"
            options={formatDropdown}
            onChange={handleFormatSelectChange}
            value={sendInfoData.format}
          />
        </div>
      );
    }

    return "";
  };
  const tokenValid = () => {
    if (isTokenAvailable === false && subscribeMethod.value === "1") {
      return __(
        "wysyłka z zaszyfrowanym plikiem xls może nie zostać wysłana – token dostępowy jest pusty"
      );
    }
    return "";
  };
  const recipientsRender = () => {
    const result = [];
    const addRecpients = (val, ahr) =>
      result.push(
        <div className="col-md-12">
          <Label>{__("Adresaci OMB")}</Label>
          <Creatable
            isMulti
            name="recipients"
            onChange={handleRecipientsChange}
            options={[]}
            onBlur={handleBlurRecipients}
            value={val}
            isDisabled={ahr}
            placeholder={__("Wybierz z listy lub wpisz")}
          />
        </div>
      );

    const addHiddenRecipients = (val, ahr) =>
      result.push(
        <div className="col-md-12">
          <Label>{__("Adresaci inni")}</Label>
          <Creatable
            isMulti
            name="hiddenRecipients"
            onChange={handleHiddenRecipientsChange}
            options={[]}
            onBlur={handleBlurHiddenRecipients}
            value={val}
            isDisabled={ahr}
            placeholder={__("Wybierz z listy lub wpisz")}
          />
        </div>
      );

    if (!isAhr) {
      result.push(
        <div className="col-md-12">
          <Label>{__("Lista AHR")}</Label>
          <Select
            isMulti
            name="recipients"
            onChange={handleAhrsChange}
            options={ahrsList}
            value={sendInfoData.ahrList}
            placeholder={__("Wybierz...")}
          />
        </div>
      );
    }

    if (isAhr) {
      if (email.length > 0) {
        addRecpients([{ label: email, value: userId }], true);
      } else {
        addRecpients([], false);
      }
    } else if (subscribeMethod.value === "1") {
      addRecpients(sendInfoData.recipients, false);
      addHiddenRecipients(sendInfoData.hiddenRecipients, false);
    }

    return result;
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={close} unmountOnClose scrollable size="lg">
        <ModalHeader toggle={close}>{title}</ModalHeader>
        <ModalBody>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="col-md-12">
                  <Label>{__("Tytuł wiadomości")}*</Label>
                  <Input
                    type="text"
                    name="messageTitle"
                    id="messageTitle"
                    value={sendInfoData.messageTitle}
                    onChange={handleChange}
                  />
                </div>
                {recipientsRender()}
                {formatRender()}
                <div className="col-md-12 text-danger">{tokenValid()}</div>
              </div>

              <div className="col-md-6">
                <div className="col-md-12">
                  <Label>{__("Treść wiadomości")}*</Label>
                  <Input
                    name="messageContent"
                    type="textarea"
                    id="messageContent"
                    value={sendInfoData.messageContent}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveClick}>
            {__("Zapisz")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

SendMethodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  subscribeMethod: PropTypes.object.isRequired,
  subscribeMethodInfo: PropTypes.object.isRequired,
  handleSubscribeMethodSave: PropTypes.func.isRequired,
  formatDropdown: PropTypes.array.isRequired,
  ahrsList: PropTypes.array.isRequired,
  isTokenAvailable: PropTypes.bool.isRequired,
};

export default SendMethodModal;
