import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";
import { Alert } from "reactstrap";
import { update } from "lodash";
import moment from "moment";
import __ from "src/utils/Translations";
import Form from "src/Components/Form/index";
import { LAYOUT_TWO_COLUMNS } from "src/Components/Layouts";
import PageTitle from "src/Layout/AppMain/PageTitle";
import DataLoading from "src/Components/Loading/dataLoading";
import { API_TOTALREWARD_SERVICE } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import { getCompanyBaseBreadcrumbs } from "src/Pages/Company/routerHelper";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import useMutateTotalRewardPerk from "src/Pages/Company/TotalReward/hooks/useMutateTotalRewardPerk";
import useTotalRewardCategories from "src/Pages/Company/TotalReward/hooks/useTotalRewardCategories";
import useEmployeeGroups from "src/utils/hooks/company/useEmployeeGroups";
import useRentableGroups from "src/utils/hooks/company/useRentableGroups";
import useOrganizationUnits from "src/utils/hooks/company/useOrganizationUnits";
import translateLabels from "src/utils/Translations/translateLabels";

const fileReader = new FileReader();

export default function TotalRewardPerksForm({
  perkId,
  pathPrefix,
  yourBenefitsEnabled,
  availableBenefitsEnabled,
  setIsEdited,
}) {
  const history = useHistory();
  const isNew = perkId === "-1";
  const companyId = useCompanyId();

  const [fileDataURL, setFileDataURL] = useState(null);
  const [coverImgInfo, setCoverImgInfo] = useState([]);

  const onImgLoad = ({ target: img }) => {
    const gcd = (...arr) => {
      const gcd2 = (x, y) => (!y ? x : gcd(y, x % y));
      return [...arr].reduce((a, b) => gcd2(a, b));
    };

    const gcdResult = gcd(img.naturalWidth, img.naturalHeight);

    setCoverImgInfo([
      { title: __("Wysokość"), value: `${img.naturalHeight}px` },
      { title: __("Szerokość"), value: `${img.naturalWidth}px` },
      {
        title: __("Proporcje"),
        value: `${img.naturalWidth / gcdResult}:${
          img.naturalHeight / gcdResult
        }`,
      },
      {
        title: __("Orientacja"),
        value:
          img.naturalHeight <= img.naturalWidth ? __("pozioma") : __("pionowa"),
      },
    ]);
  };

  const employeeGroups = useEmployeeGroups(
    true,
    "companyId",
    companyId,
    false,
    !(companyId && availableBenefitsEnabled)
  );
  const rentableGroups = useRentableGroups(
    true,
    "companyId",
    companyId,
    false,
    !(companyId && availableBenefitsEnabled)
  );
  const organizationUnits = useOrganizationUnits(
    true,
    "companyId",
    companyId,
    false,
    !(companyId && availableBenefitsEnabled)
  );

  const [originData, setOriginData] = useState();
  const [data, setData] = useState({
    visibility: !!availableBenefitsEnabled,
    status: !!yourBenefitsEnabled,
    activeFrom: null,
    activeTo: null,
    value: false,
    videos: [],
    files: [],
    employeeGroups: [],
    rentableGroups: [],
    organizationUnits: [],
    sumFormat: null,
    sort: 0,
  });

  const companyName = useCompanyName();
  const { perkCategories } = useTotalRewardCategories(companyId, true);

  const anyGroupSelected =
    data.employeeGroups?.length > 0 ||
    data.rentableGroups?.length > 0 ||
    data.organizationUnits?.length > 0;

  const hiddenEmployeeGroups =
    originData?.employeeGroups.filter(
      (id) => !employeeGroups.map((g) => g.value).includes(id)
    ) || [];
  const hiddenRentableGroups =
    originData?.rentableGroups.filter(
      (id) => !rentableGroups.map((g) => g.value).includes(id)
    ) || [];
  const hiddenOrganizationUnits =
    originData?.organizationUnits.filter(
      (id) => !organizationUnits.map((g) => g.value).includes(id)
    ) || [];

  const onChange = (key, value) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      update(updatedData, key, (v) => value);
      return updatedData;
    });

    setIsEdited(true);
  };

  const [errorMessage, setErrorMessage] = useState(null);

  const mutation = useMutateTotalRewardPerk();

  const onSubmit = async () => {
    setErrorMessage(null);
    await mutation.mutate(
      {
        tenant: companyId,
        id: data.id,
        title: data.title,
        visibility: !!data.visibility,
        employeeGroups: [...data.employeeGroups, ...hiddenEmployeeGroups],
        rentableGroups: [...data.rentableGroups, ...hiddenRentableGroups],
        organizationUnits: [
          ...data.organizationUnits,
          ...hiddenOrganizationUnits,
        ],
        status: !!data.status,
        activeFrom: data.activeFrom
          ? moment(data.activeFrom).format("YYYY-MM-DD")
          : undefined,
        activeTo: data.activeTo
          ? moment(data.activeTo).format("YYYY-MM-DD")
          : undefined,
        image: data.image || undefined,
        body: data.body,
        shortDescription: data.shortDescription,
        videos: data.videos,
        files: data.files,
        value: !data.value,
        sumFormat: !data.value ? data.sumFormat : 0,
        category: data.category,
        sort: data.sort || 0,
      },
      {
        onSuccess: (data) => {
          setIsEdited(false);
          dynamicNotification(__("Pomyślnie zapisano dane"));
          history.push(`${pathPrefix}/total-reward/perks`);
        },
        onError: (data) => {
          if (data.detail) {
            setErrorMessage(data.detail);
          }
          dynamicNotification(__("Nie udało się zapisać benefitu"), "error");
        },
      }
    );
  };

  const addVideo = async () => {
    const newVideos = data ? [...data?.videos] : [];
    newVideos.push({ link: "", title: "" });
    onChange("videos", newVideos);
  };

  const removeVideo = async (key) => {
    const newVideos = data ? [...data?.videos] : [];
    newVideos.splice(key, 1);
    onChange("videos", newVideos);
  };

  const addFile = async () => {
    const newFiles = data ? [...data?.files] : [];
    newFiles.push({ title: "", file: null });
    onChange("files", newFiles);
  };

  const removeFile = async (key) => {
    const newFiles = data ? [...data?.files] : [];
    newFiles.splice(key, 1);
    onChange("files", newFiles);
  };

  const onRangeChange = (key, value) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      const { from, to } = value;
      update(updatedData, `${key}From`, (v) => from);
      update(updatedData, `${key}To`, (v) => to);
      return updatedData;
    });
  };

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={
          isNew
            ? __(`Tworzenie benefitu w firmie {0}`, [companyName])
            : __(`Edycja benefitu (ID: {0}) w firmie {1}`, [
                perkId,
                companyName,
              ])
        }
        breadcrumbsHeading={
          isNew
            ? __("Tworzenie benefitu")
            : `${__("Edycja benefitu")} (ID: ${perkId})`
        }
        breadcrumbs={[
          ...getCompanyBaseBreadcrumbs(companyId, companyName),
          {
            title: "Total Reward Statement",
            link: `${pathPrefix}/total-reward`,
          },
          {
            title: __("Zarządzanie benefitami"),
            link: `${pathPrefix}/total-reward/perks`,
          },
        ]}
        pushToHistory={!isNew}
      />
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      <DataLoading
        service={API_TOTALREWARD_SERVICE}
        endpoint={`/omb/perks/${perkId}?tenant=${companyId}`}
        fetchedData={isNew || !!data.id}
        updateData={(res) => {
          setOriginData(res);
          const data = {
            sumFormat: 0,
            sort: 0,
            videos: [],
            ...res,
            files:
              (res?.files || []).map((f) => ({
                ...f,
                title: f.fileTitle,
                file: null,
              })) || [],
            image: null,
            imageUrl: res.imageUrl,
            category: res.category?.id
              ? `/omb/perk-categories/${res.category.id}`
              : null,
            value: !res.value,
          };
          setData(data);
        }}
        isNew={isNew}
      >
        <Form
          id="totalRewardPerkForm"
          data={data}
          config={{
            title: isNew ? __("Tworzenie benefitu") : __("Edycja benefitu"),
            stickyTitle: true,
            defaultOnChange: onChange,
            onSubmit,
            buttons: [
              {
                size: "lg",
                color: "success",
                className: "mr-2",
                text: "Zapisz",
                type: "submit",
                id: "configurationSubmit",
              },
            ],
            formGroups: [
              {
                formElements: [
                  {
                    type: "text",
                    id: "title",
                    label: "Nazwa",
                    translateTitle: false,
                    validation: ["required"],
                    translatable: isNew
                      ? null
                      : {
                          show: true,
                          code: `totalRewardStatement:perkObject:${perkId}:title`,
                          isCms: true,
                        },
                  },
                  {
                    type: "boolean",
                    id: "status",
                    label: "Widoczność w zakładce Twoje Benefity",
                    displayCondition: yourBenefitsEnabled,
                  },
                  {
                    type: "boolean",
                    id: "visibility",
                    label: "Widoczność w zakładce Dostępne benefity",
                    displayCondition: availableBenefitsEnabled,
                  },
                  {
                    type: "dateRange",
                    id: "active",
                    label: "Ograniczenie widoczności",
                    displayCondition: availableBenefitsEnabled,
                    depends: {
                      value: true,
                      field: "visibility",
                    },
                    props: {
                      minDateFrom: new Date(1970, 1, 1, 1),
                      minDateTo: new Date(1970, 1, 1, 1),
                    },
                    onChange: onRangeChange,
                  },
                  {
                    type: "staticValue",
                    id: "anyGroupSelected",
                    label:
                      "Wybierz grupy pracownicze lub grupy dochodowości lub jednostki organizacyjne. *",
                    displayCondition: availableBenefitsEnabled,
                    depends: {
                      value: true,
                      field: "visibility",
                    },
                    validation: [
                      {
                        method: "customValidation",
                        args: [
                          () => {
                            if (anyGroupSelected) {
                              return false;
                            }
                            return __(
                              "Wybór przynajmniej jednej opcji jest wymagany."
                            );
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "autocompleteMultiselect",
                    id: "employeeGroups",
                    label: "Grupa pracownicza",
                    displayCondition: availableBenefitsEnabled,
                    options: employeeGroups,
                    depends: {
                      value: true,
                      field: "visibility",
                    },
                    onChange: (key, value) => {
                      onChange(key, value);
                      onChange("anyGroupSelected", false);
                    },
                    // validation: [anyGroupSelected ? undefined : "required"],
                    props: {
                      closeMenuOnSelect: false,
                    },
                  },
                  {
                    type: "autocompleteMultiselect",
                    id: "rentableGroups",
                    label: "Grupa dochodowości",
                    displayCondition: availableBenefitsEnabled,
                    options: rentableGroups,
                    depends: {
                      value: true,
                      field: "visibility",
                    },
                    onChange: (key, value) => {
                      onChange(key, value);
                      onChange("anyGroupSelected", false);
                    },
                    // validation: [anyGroupSelected ? undefined : "required"],
                    props: {
                      closeMenuOnSelect: false,
                    },
                  },
                  {
                    type: "autocompleteMultiselect",
                    id: "organizationUnits",
                    label: "Jednostki organizacyjne",
                    displayCondition: availableBenefitsEnabled,
                    options: organizationUnits,
                    depends: {
                      value: true,
                      field: "visibility",
                    },
                    onChange: (key, value) => {
                      onChange(key, value);
                      onChange("anyGroupSelected", false);
                    },
                    // validation: [anyGroupSelected ? undefined : "required"],
                    props: {
                      closeMenuOnSelect: false,
                    },
                  },
                  {
                    type: "hr",
                  },
                  {
                    label:
                      "Okładka. Maksymalny rozmiar pliku - 3MB, sugerowane proporcje obrazka to 16:9, orientacja pozioma.",
                    type: "file",
                    id: "image",
                    onChange: (_, v) => {
                      setCoverImgInfo([]);
                      fileReader.onload = (e) => {
                        const { result } = e.target;
                        if (result) {
                          setFileDataURL(result);
                        }
                      };
                      fileReader.readAsDataURL(v[0]);

                      setData({ ...data, image: v[0] });
                      return v;
                    },
                    validation: [
                      data.imageUrl ? undefined : "required",
                      {
                        method: "allowedExtensions",
                        args: [["jpg", "jpeg", "png", "webp"]],
                      },
                    ],
                    value: [data?.image],
                  },
                  {
                    type: "staticValue",
                    id: "showImg",
                    value:
                      data.image || data.imageUrl ? (
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col col-auto">
                              <img
                                src={data.image ? fileDataURL : data.imageUrl}
                                onLoad={onImgLoad}
                                alt="preview"
                                style={{
                                  borderRadius: "16px",
                                  maxWidth: "254px",
                                  height: "168px",
                                  objectFit: "cover",
                                  aspectRatio: "16 / 9",
                                  alignSelf: "flex-start",
                                }}
                              />
                            </div>
                            <div className="col">
                              <ul>
                                {coverImgInfo.map(({ title, value }) => (
                                  <li key={title}>
                                    {title}: {value}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : null,
                  },
                  {
                    type: "hr",
                  },
                  {
                    type: "wysiwyg",
                    id: "body",
                    label: "Opis",
                    validation: ["required"],
                    translatable: isNew
                      ? null
                      : {
                          show: true,
                          code: `totalRewardStatement:perkObject:${perkId}:body`,
                          isCms: true,
                        },
                    uploadService: API_TOTALREWARD_SERVICE,
                  },
                  {
                    type: "wysiwyg",
                    id: "shortDescription",
                    label: "Krótki opis",
                    // validation: ["required"],
                    translatable: isNew
                      ? null
                      : {
                          show: true,
                          code: `totalRewardStatement:perkObject:${perkId}:shortDescription`,
                          isCms: true,
                        },
                    uploadService: API_TOTALREWARD_SERVICE,
                  },
                ],
              },
              {
                title: "Video",
                formElements: [
                  ...(data?.videos?.length > 0
                    ? Array.from(Array(data?.videos?.length).keys()).map(
                        (k) => ({
                          layout: LAYOUT_TWO_COLUMNS,
                          formElements: [
                            {
                              type: "text",
                              id: `videos[${k}].title`,
                              label: "Tytuł Video",
                              value: data?.videos[k].title,
                              validation: ["required"],
                            },
                            {
                              type: "text",
                              id: `videos[${k}].link`,
                              label: "Link do Video",
                              value: data?.videos[k].link,
                              validation: ["required"],
                            },
                            {
                              type: "button",
                              label: "Usuń Video",
                              props: {
                                outline: true,
                                size: "sm",
                                className: "mb-4",
                              },
                              onChange: () => removeVideo(k),
                            },
                          ],
                        })
                      )
                    : []),
                  {
                    type: "hr",
                  },
                  {
                    type: "button",
                    label: "Dodaj Video",
                    onChange: addVideo,
                  },
                ],
              },
              {
                title: __("Pliki"),
                formElements: [
                  ...(data?.files?.length > 0
                    ? Array.from(Array(data?.files?.length).keys()).map(
                        (k) => ({
                          layout: LAYOUT_TWO_COLUMNS,
                          formElements: [
                            {
                              type: "text",
                              id: `files[${k}].title`,
                              label: "Skrócona nazwa pliku",
                              value: data?.files[k].title,
                              validation: ["required"],
                            },
                            {
                              type: "file",
                              id: `files[${k}].file`,
                              label: "Plik",
                              value: data?.files[k].file,
                              validation: data?.files[k].fileUrl
                                ? undefined
                                : ["required"],
                            },
                            {
                              type: "button",
                              label: "Usuń Plik",
                              props: {
                                outline: true,
                                size: "sm",
                                className: "mb-4",
                              },
                              onChange: () => removeFile(k),
                            },
                            {
                              component: (
                                <>
                                  {data?.files[k].fileUrl ? (
                                    <a
                                      href={data.files[k].fileUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-sm btn-outline-primary"
                                    >
                                      {__("Pobierz")}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ),
                            },
                          ],
                        })
                      )
                    : []),
                  {
                    type: "hr",
                  },
                  {
                    type: "button",
                    label: "Dodaj Plik",
                    onChange: addFile,
                  },
                ],
              },
              {
                title: __("Pozostałe ustawienia"),
                formElements: [
                  {
                    type: "boolean",
                    id: "value",
                    label: "Bez wartości",
                  },
                  {
                    type: "select",
                    id: "sumFormat",
                    label: "Format sumowania",
                    options: translateLabels(sumFormats),
                    validation: !data.value ? ["required"] : undefined,
                    depends: {
                      value: false,
                      field: "value",
                    },
                  },
                  {
                    type: "select",
                    id: "category",
                    label: "Kategoria",
                    options: perkCategories
                      .filter((pc) => pc.status)
                      .map((pc) => ({
                        ...pc,
                        value: `/omb/perk-categories/${pc.value}`,
                      })),
                    validation: ["required"],
                  },
                  {
                    type: "number",
                    id: "sort",
                    label: "Kolejność wyświetlania",
                    validation: [{ method: "greaterEqualThan", args: [0] }],
                  },
                ],
              },
            ],
          }}
        />
      </DataLoading>
    </CSSTransitionGroup>
  );
}

export const sumFormats = [
  { value: 1, label: "liczba po przecinku w formacie 0 000,00" },
  { value: 2, label: "liczba całkowita w formacie 0 000" },
  { value: 3, label: "liczba PKT w formacie 0 000 pkt" },
  { value: 4, label: "wartość zł w formacie 0 000,00 zł" },
  { value: 5, label: "wartość EUR w formacie 0 000,00 EUR" },
];

TotalRewardPerksForm.propTypes = {
  perkId: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
  yourBenefitsEnabled: PropTypes.bool,
  availableBenefitsEnabled: PropTypes.bool,
  setIsEdited: PropTypes.func,
};

TotalRewardPerksForm.defaultProps = {
  yourBenefitsEnabled: false,
  availableBenefitsEnabled: false,
  setIsEdited: () => {},
};
