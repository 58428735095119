/* eslint-disable */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import moment from "moment";
import Loader from "react-loaders";
import PopupComponent from "../../../../../../Components/Popup/popup";
import __ from "../../../../../../utils/Translations";
import isObject from "../../../../../../utils/jsHelpers/isObject";
import { magentoAdminOrderCodePermissionRead } from "../../../../../../utils/RoleBasedSecurity/permissions";
import {
  MAGENTO_ADMIN_SERVICE,
  CATALOG_MANAGEMENT_SERVICE,
  restApiRequest,
  downloadFile,
} from "src/utils/Api";
import { dynamicNotification } from "../../../../../../utils/Notifications";
import useHasPermission from "../../../../../../utils/hooks/security/useHasPermission";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const UNIVERSAL_CODE_PRODUCT_TYPE = "universal_code";

export default function PreviewItemPopup({
  previewItem,
  close,
  employeeId,
  itemId,
}) {
  const {
    productData = {},
    productOptions = {},
    orderType,
    orderId,
  } = previewItem;
  const [codeText, setCodeText] = useState(null);
  const [status, setStatus] = useState([]);
  const [previewPDF, setPreviewPDF] = useState(null);
  const [statusMessage, setStatusMessage] = useState("Generowanie pliku...");
  const [canPrintCode, setCanPrintCode] = useState(null);
  const [newProdData, setNewProductData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPreviewButtonLoading, setPreviewButtonLoading] = useState(false);

  const date = new Date(previewItem?.productExpirationDate);

  useEffect(() => {
    setNewProductData({
      ...productData,
      order_type: orderType,
      price: Number(productData?.price ?? previewItem?.price).toFixed(2),
      purchase_price: Number(
        productData?.purchase_price ?? previewItem?.priceInclTax
      ).toFixed(2),
      product_expiration_date:
        previewItem.product_expiration_date ??
        moment(date.getTime() + date.getTimezoneOffset() * 60000).format(
          "YYYY-MM-DD"
        ),
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await restApiRequest(
          MAGENTO_ADMIN_SERVICE,
          `/user-orders?customerBusinessId=${employeeId}&id=${orderId}`,
          "GET",
          {},
          []
        );

        result.forEach((element) => {
          const order = element?.items?.map((item) => ({
            id: item.itemId,
            status: element.status,
          }));

          setStatus((prev) => [...prev, ...order]);
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
        newRelicErrorReport(
          e,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/OneTimeServices/previewItemPopup.js - 76"
        );
        dynamicNotification(e.message || __("Błąd pobierania danych"), "error");
      }
    };

    fetchData();
  }, [employeeId]);

  console.log(canPrintCode, "canprint");

  useEffect(() => {
    const fetchProductSettingsData = async () => {
      const supplierResponse = await restApiRequest(
        CATALOG_MANAGEMENT_SERVICE,
        `/code-settings?productId=${productData.sku}`,
        "GET",
        {},
        []
      );

      setCanPrintCode(supplierResponse.canPrintCode);
    };
    if (newProdData.order_type === UNIVERSAL_CODE_PRODUCT_TYPE && productData) {
      fetchProductSettingsData();
    }
  }, [newProdData, productData]);

  const onClickShowCode = () => {
    const fetchItemCode = async () => {
      try {
        const codeFetchResult = await restApiRequest(
          MAGENTO_ADMIN_SERVICE,
          `/order-item-code/${itemId}`,
          "GET",
          {},
          []
        );

        const currentItemStatus = status.filter((ele) => ele.id === itemId);
        const { paymentStatus } = previewItem;
        const conditionsForCodeShowing =
          paymentStatus === "PAID" &&
          currentItemStatus[0]?.status === "paid_claimed_codes";

        if (
          codeFetchResult &&
          typeof codeFetchResult === "string" &&
          conditionsForCodeShowing
        ) {
          setCodeText(codeFetchResult);
          dynamicNotification(__("Kod został wygenerowany"), "success");

          const {
            supplier_id: supplierId,
            price,
            supplier_name: supplierName,
            product_expiration_date: productExpirationDate,
            name,
          } = newProdData;

          const fetchDataDownloadPdf = async (code) => {
            try {
              /* eslint-disable no-unused-vars */
              const result = await restApiRequest(
                CATALOG_MANAGEMENT_SERVICE,
                "/create-barcode",
                "POST",
                {
                  body: {
                    supplier_id: supplierId,
                    nazwa_dostawcy: supplierName,
                    kwota: price % 1 ? Number(price).toFixed(2) : +price,
                    numer_kodu: code,
                    data_waznosci: productExpirationDate,
                    nazwa: name,
                    product_id: productData?.sku || null,
                  },
                },
                null
              );

              const { pathToPdf } = result;
              setPreviewPDF(pathToPdf);
              dynamicNotification(
                __("Plik z kodem został wygenerowany"),
                "success"
              );
            } catch (err) {
              newRelicErrorReport(
                err,
                "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/OneTimeServices/previewItemPopup.js - 163"
              );
              setStatusMessage("Generowanie pliku z kodem nie powiodło się");
              dynamicNotification(
                __("Nie można wygenerować pliku z kodem"),
                "error"
              );
              console.error(err);
            }
          };

          if (
            newProdData.order_type === UNIVERSAL_CODE_PRODUCT_TYPE &&
            canPrintCode
          )
            fetchDataDownloadPdf(codeFetchResult);
          else setStatusMessage("");
        } else {
          dynamicNotification(__("Pobranie kodu nie powiodło się"), "error");
        }
      } catch (err) {
        newRelicErrorReport(
          err,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/OneTimeServices/previewItemPopup.js - 181"
        );
        dynamicNotification(__("Pobranie kodu nie powiodło się"), "error");
        console.error(err);
      }
    };
    fetchItemCode();
  };

  const onPreviewButtonClick = async () => {
    setPreviewButtonLoading(true);
    const fileName =
      (typeof previewPDF === "string" &&
        previewPDF.split("/").slice(-2, -1)?.[0]) ||
      "Kod";
    await downloadFile("", previewPDF, fileName, true);
    setPreviewButtonLoading(false);
  };

  const codeReadPermission = useHasPermission(
    magentoAdminOrderCodePermissionRead
  );
  return (
    <>
      <PopupComponent
        id="oneTimeServiceEditInfo"
        isOpen
        toggle={close}
        unmountOnClose
        size="lg"
      >
        <ModalHeader toggle={close}>{__("Wartości z dnia zakupu")}</ModalHeader>
        <ModalBody>
          <Table className="mt-2 text-center">
            <thead>
              <tr>
                <th>{__("Klucz")}</th>
                <th>{__("Wartość")}</th>
              </tr>
            </thead>
            <tbody>
              {newProdData
                ? Object.keys(labelsMapToDisplay).map((key) => {
                    const rawValue = newProdData[key];
                    const {
                      custom_options: customOptions,
                      custom_options_form: customOptionsForm,
                    } = productOptions || {};
                    if (
                      key === "code" &&
                      codeReadPermission &&
                      newProdData.order_type === UNIVERSAL_CODE_PRODUCT_TYPE
                    ) {
                      return (
                        <tr key={key} className="w-100">
                          <td>
                            {labelsMapToDisplay[key]
                              ? __(labelsMapToDisplay[key])
                              : key}
                          </td>
                          <td
                            style={{
                              maxWidth: "400px",
                              overflowWrap: "break-word",
                            }}
                          >
                            <div className="centerButton">
                              {loading ? (
                                <Loader
                                  active
                                  type="line-scale"
                                  style={{ transform: "scale(0.6)" }}
                                />
                              ) : codeText ? (
                                <span
                                  style={{ fontSize: "14", margin: "5px 0px" }}
                                >
                                  {codeText}
                                </span>
                              ) : (
                                <Button
                                  className="mx-2"
                                  color="primary"
                                  onClick={() => onClickShowCode()}
                                >
                                  Pokaż kod
                                </Button>
                              )}
                            </div>
                            {previewPDF && (
                              <div className="centerButton">
                                {isPreviewButtonLoading ? (
                                  <Loader
                                    active
                                    type="line-scale"
                                    style={{ transform: "scale(0.6)" }}
                                  />
                                ) : (
                                  <Button
                                    color="primary"
                                    onClick={onPreviewButtonClick}
                                  >
                                    Pobierz kod
                                  </Button>
                                )}
                              </div>
                            )}
                            {!previewPDF && codeText && (
                              <span>{__(statusMessage)}</span>
                            )}
                          </td>
                        </tr>
                      );
                    }

                    if (
                      key === "product_configuration_form" &&
                      codeReadPermission &&
                      customOptions?.length &&
                      customOptionsForm?.length
                    ) {
                      const labelList = customOptionsForm?.map(({ title }) => {
                        const bracketIndex = title.indexOf("[");
                        return title.slice(0, bracketIndex);
                      });

                      const valueList = customOptions?.[0].fields?.map(
                        ({ option_value: optionValue }) => optionValue
                      );

                      return (
                        <tr
                          key={labelsMapToDisplay.product_configuration_form}
                          className="w-100"
                        >
                          <td>
                            {labelsMapToDisplay.product_configuration_form}
                          </td>
                          <td
                            style={{
                              maxWidth: "400px",
                              overflowWrap: "break-word",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {labelList?.map((item, index) => (
                                <span
                                  key={item}
                                >{`${item}: ${valueList[index]}`}</span>
                              ))}
                            </div>
                          </td>
                        </tr>
                      );
                    }

                    return (
                      key !== "code" && (
                        <tr key={key} className="w-100">
                          <td>
                            {labelsMapToDisplay[key]
                              ? __(labelsMapToDisplay[key])
                              : key}
                          </td>
                          <td
                            style={{
                              maxWidth: "400px",
                              overflowWrap: "break-word",
                            }}
                          >
                            {isObject(rawValue) || Array.isArray(rawValue)
                              ? JSON.stringify(rawValue)
                              : rawValue}
                          </td>
                        </tr>
                      )
                    );
                  })
                : null}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={close}>
            Zamknij
          </Button>
        </ModalFooter>
      </PopupComponent>
    </>
  );
}

const labelsMapToDisplay = {
  sku: "SKU",
  entity_id: "Identyfikator produktu",
  name: "Nazwa produktu",
  business_category_id: "Kategoria biznesowa",
  supplier_id: "ID dostawcy",
  provider_name: "Nazwa dostawcy",
  location_cities_cities: "Lokalizacja - Miasta",
  location_location_parent_location_parent: "Lokalizacja nadrzędna",
  location_address_city: "Lokalizacjia - miasto",
  location_address_postal_code: "Lokalizacja - kod pocztowy",
  location_address_street: "Lokalizacja - ulica",
  code_realization_place: "Miejsce realizacji kodu",
  facility_email_address: "Adres e-mail obiektu",
  facility_web_page: "Strona www obiektu",
  description: "Opis",
  message_after_purchase: "Wiadomość po zakupie",
  mb_benefit_type: "Typ produktu",
  withdraw_allowed: "Możliowść odstąpienia od umowy",
  acceptance_supplier_panel_manual: "Akceptacja w panelu dostawcy - ręczna",
  acceptance_supplier_panel_auto: "Akceptacja w panelu dostawcy - automatyczna",
  vat_rate: "Stawka VAT",
  product_expiration_date: "Data ważności kodu",
  price: "Cena",
  purchase_price: "Cena zakupu",
  can_buy_multiple: "Możliwość zakupu wielu",
  form_can_buy_without_customer:
    "Os. towarzysząca - możliwość zakupu bez kodu dla pracownika",
  form_edit_last_date: "Ostatni dzień edycji danych w formularzu",
  form_disabled_edit_customer: "Wyłącz edycję pracownika",
  form_max_customer_codes: "Maksymalna ilosć kodów dla pracownika",
  form_disabled_edit_others: "Wyłącz edycję osoby towarzyszącej",
  show_consent_purchasing_form:
    "Wyświetl zgodę przy zakupie kodu z formularzem",
  created_at: "Stworzono o",
  updated_at: "Zaktualizowano o",
  product_configuration_form: "Produkt konfigurowalny",
  code: "Zakupiony Kod",
};

// eslint-disable-next-line no-unused-vars
const allLabelsMap = {
  ...labelsMapToDisplay,
  attribute_set_id: "Zestaw atrybutów",
  type_id: "Typ",
  has_options: "Czy posiada opcje",
  required_options: "Wymagane opcje",
  mb_product_type: "Typ produktu",
  image: "Zdjęcie",
  store_id: "ID sklepu",
  small_image: "Małe zdjęcie",
  thumbnail: "Miniaturka",
  options_container: "Opcje",
  msrp_display_actual_price_type: "Cena MSRP",
  url_key: "Klucz url",
  gift_message_available: "Wiadomość podarunkowa",
  swatch_image: "Obraz opcji",
  identifier: "Id",
  mb_product_type_filter: "Rodzaj produktu",
  availability_filter: "Dostępność",
  status: "Status",
  visibility: "Widoczność",
  tax_class_id: "Klasa podatkowa",
  enabled_sms_acceptance_option: "Akceptacja SMS",
  product_label: "Rodzaj produktu",
  codes_count_visibility_value: "Widoczność kodu",
  wartosc: "Wartość",
  options: "Opacje",
  media_gallery: "Galeria",
  extension_attributes: "Dodatkowe atrybuty",
  tier_price: "Cena hurtowa",
  tier_price_changed: "Zmiana ceny hurtowej",
  quantity_and_stock_status: "Dostępność i ilość",
  category_ids: "Kategorie",
  is_salable: "Dostępne do sprzedaży",
  main_category_id: "Id głównej kategorii",
};

PreviewItemPopup.propTypes = {
  close: PropTypes.func.isRequired,
  previewItem: PropTypes.shape({
    productData: PropTypes.shape({
      provider_name: PropTypes.string,
    }),
    productOptions: PropTypes.shape({
      custom_options: PropTypes.arrayOf(PropTypes.shape({})),
      custom_options_form: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    paymentStatus: PropTypes.string.isRequired,
    productUserUsed: PropTypes.number.isRequired,
    supplierId: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    product_expiration_date: PropTypes.string,
  }).isRequired,
  employeeId: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
};
