import PropTypes from "prop-types";
import React from "react";

import {
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Label,
  Col,
  Row,
} from "reactstrap";

import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DatePicker from "react-datepicker";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import hashInputId from "src/utils/jsHelpers/hashInputId";
import __ from "src/utils/Translations";

export default function DateRange({
  value,
  label,
  data1,
  onChange,
  id,
  format,
  errorMessage,
  validateField,
  validation,
  afterLabel,
  startDateLabel,
  endDateLabel,
  disabled,
  disabledFrom,
  disabledTo,
  minDateFrom,
  minDateTo,
  radioFlag,
  setRadioFlag,
  tooltipComponent,
  ...restProps
}) {
  const inputValue = value || {};
  const errorMessages = errorMessage ? JSON.parse(errorMessage) : null;
  const fromRequired =
    validation &&
    validation.find((rule) =>
      ["rangeRequiredBoth", "rangeRequiredFrom"].includes(rule)
    );
  const toRequired =
    validation &&
    validation.find((rule) =>
      ["rangeRequiredBoth", "rangeRequiredTo"].includes(rule)
    );
  const errorMessageFrom = errorMessages && errorMessages.from;
  const errorMessageTo = errorMessages && errorMessages.to;

  const handleChange = ({ from, to }) => {
    let fromValue;
    let toValue;

    if (from === undefined) {
      fromValue = inputValue.from ? new Date(inputValue.from) : "";
    } else {
      fromValue = from;
    }

    if (to === undefined) {
      toValue = inputValue.to ? new Date(inputValue.to) : "";
    } else {
      toValue = to;
    }

    if (toValue && fromValue > toValue) {
      toValue = null;
    }
    if (validateField) {
      if (validation?.find((rule) => rule === "rangeRequiredBoth")) {
        if (to) {
          validateField(
            id,
            {
              to: toValue,
            },
            ["rangeRequiredTo"]
          );
        }
        if (from) {
          validateField(
            id,
            {
              from: fromValue,
            },
            ["rangeRequiredFrom"]
          );
        }
      } else {
        validateField(
          id,
          {
            from: fromValue,
            to: toValue,
          },
          validation
        );
      }
    }
    onChange({
      from: fromValue,
      to: toValue,
    });
  };

  const handleChangeStart = (from) => handleChange({ from });
  const handleChangeEnd = (to) => {
    if (to instanceof Date) {
      to.setHours(23);
      to.setMinutes(59);
      to.setSeconds(59);
    }
    handleChange({ to });
  };

  const from = value && value.from ? new Date(value.from) : null;
  const to = value && value.to ? new Date(value.to) : null;
  const currentDate = new Date().getTime();

  const clearPastDates = () => {
    if (from && currentDate > from.getTime() + 86400000) {
      handleChangeStart("");
    }

    if (to && currentDate > to.getTime() + 86400000) {
      handleChangeEnd("");
    }
    // eslint-disable-next-line no-unused-expressions
    setRadioFlag && setRadioFlag(null);
  };

  if (radioFlag === "oneTime") {
    clearPastDates();
  }

  return (
    <>
      {label ? (
        <Label data-t1={`${id}Label`} for={`${id}`} className="mr-sm-2">
          {label}
          {tooltipComponent && <span className="ml-2">{tooltipComponent}</span>}
        </Label>
      ) : null}
      {afterLabel ? <span className="ml-3">{afterLabel}</span> : null}
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for={`${id}_from`} className="mr-sm-2">
              {startDateLabel || `${__("Od")}:`}{" "}
              {fromRequired ? <span className="text-danger">*</span> : null}
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
              </InputGroupAddon>
              <div
                data-t1={`${id}From`}
                className={
                  restProps?.from?.showTimeSelect
                    ? "datetime-picker-wrapper"
                    : "date-picker-wrapper"
                }
              >
                <DatePicker
                  id={`${hashInputId(id)}_from`}
                  customInput={<input data-t1={`${data1}From`} type="text" />}
                  popperPlacement="auto"
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                  selected={from}
                  disabled={disabled || disabledFrom}
                  selectsStart
                  className={`form-control${
                    errorMessageFrom ? " is-invalid" : ""
                  }`}
                  startDate={from}
                  endDate={to}
                  minDate={minDateFrom || new Date()}
                  onChange={handleChangeStart}
                  dateFormat={format || "dd/MM/yyyy"}
                  onBlur={(e) => {
                    if (validateField) {
                      validateField(
                        id,
                        {
                          from: e.target.value,
                          to: inputValue.to,
                        },
                        validation
                      );
                    }
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(restProps?.from || {})}
                />
              </div>
              <ValidationMessage message={errorMessageFrom} />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for={`${id}_to`} className="mr-sm-2">
              {endDateLabel || `${__("Do")}:`}{" "}
              {toRequired ? <span className="text-danger">*</span> : null}
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
              </InputGroupAddon>
              <div
                data-t1={`${id}To`}
                className={
                  restProps?.to?.showTimeSelect
                    ? "datetime-picker-wrapper"
                    : "date-picker-wrapper"
                }
              >
                <DatePicker
                  id={`${hashInputId(id)}_to`}
                  customInput={<input data-t1={`${data1}To`} type="text" />}
                  popperPlacement="auto"
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                  selected={to}
                  disabled={disabled || disabledTo}
                  selectsEnd
                  className={`form-control${
                    errorMessageTo ? " is-invalid" : ""
                  }`}
                  startDate={from}
                  endDate={to}
                  minDate={minDateTo || new Date()}
                  onChange={handleChangeEnd}
                  dateFormat={format || "dd/MM/yyyy"}
                  onBlur={(e) => {
                    if (validateField) {
                      validateField(
                        id,
                        {
                          from: inputValue.from,
                          to: e.target.value,
                        },
                        validation
                      );
                    }
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(restProps?.to || {})}
                />
              </div>
              <ValidationMessage message={errorMessageTo} />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

DateRange.propTypes = {
  data1: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledFrom: PropTypes.bool,
  disabledTo: PropTypes.bool,
  afterLabel: PropTypes.node,
  format: PropTypes.string,
  value: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }),
  errorMessage: PropTypes.string,
  validateField: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
  minDateFrom: PropTypes.instanceOf(Date),
  minDateTo: PropTypes.instanceOf(Date),
  radioFlag: PropTypes.string,
  setRadioFlag: PropTypes.func,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  tooltipComponent: PropTypes.node,
};

DateRange.defaultProps = {
  id: "",
  label: "",
  onChange: null,
  afterLabel: null,
  disabled: false,
  disabledFrom: false,
  disabledTo: false,
  format: null,
  value: {},
  errorMessage: null,
  validateField: null,
  validation: null,
  minDateFrom: undefined,
  minDateTo: undefined,
  data1: null,
  setRadioFlag: null,
  radioFlag: "",
  startDateLabel: undefined,
  endDateLabel: undefined,
  tooltipComponent: null,
};
