import { useContext } from "react";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";

const useIsAhr = () => {
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();

  return isAhr;
};

export default useIsAhr;
