import React from "react";

import __ from "src/utils/Translations";
import priceFormatter from "src/utils/jsHelpers/priceFormatter";

const priceColumn = (cellInfo) => (
  <div className="d-block w-100 text-center">
    {priceFormatter(cellInfo.row[cellInfo.column.id])}
  </div>
);

export default priceColumn;
