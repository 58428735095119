/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useCallback, useState, useContext, useEffect } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import PageTitle from "src/Layout/AppMain/PageTitle";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import { restApiRequest, EMPLOYEE_MANAGEMENT_SERVICE } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import {
  booleanOptions,
  SelectFilter,
  UuidFilter,
} from "src/Components/DataTable/filters";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import useEmployeeGroups from "src/utils/hooks/company/useEmployeeGroups";
import useEmployees from "src/utils/hooks/company/useEmployees";
import {
  employeeEmployeePermissionRead,
  employeeEmployeePermissionWrite,
} from "src/utils/RoleBasedSecurity/permissions";
import ActionColumn from "src/Components/DataTable/actionColumn";
import BusinessIdColumn from "src/Components/DataTable/businessIdColumn";
import { ExportContext } from "src/Components/DataTableControlled/context";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import __ from "src/utils/Translations";
import useDepartments from "src/utils/hooks/company/useDepartments";
import { useCompanyHasFunctionality } from "src/Pages/Company/CompanyContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const LARGE_EMPLOYEES_AMOUNT_LIMIT = 30000;
const LAST_LOGIN_DATE = "lastLoginDate";
const fieldsWithExistFilter = [LAST_LOGIN_DATE];

export default function List({
  companyId,
  companyName,
  breadcrumbs,
  getEditEmployeeUrl,
  getEmployeeEmailList,
}) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [totalEmployeesCount, setTotalEmployeesCount] = useState(0);
  const [employeesIds, setEmployeesIds] = useState([]);
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();
  const departmentFunctionality = useCompanyHasFunctionality(
    "ENABLE_COMPANY_STRUCTURE"
  );

  useEffect(() => {
    getListingData(
      EMPLOYEE_MANAGEMENT_SERVICE,
      "/employees",
      [
        {
          id: "companyId",
          value: companyId,
        },
      ],
      1,
      20,
      null,
      {},
      mockData,
      true
    )
      .then(({ count }) => setTotalEmployeesCount(count))
      .catch((e) => {
        console.error(e);
        newRelicErrorReport(
          e,
          "Pages/Company/EmployeeManagement/CompanyEmployees/List/list.js - 77"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać pracowników"),
          "error"
        );
      });
  }, [companyId]);

  const fetchData = useCallback(
    async (filters, page, pageSize, sort) => {
      const modifiedFilters = filters.map((filter) => ({
        ...filter,
        isExist: fieldsWithExistFilter.includes(filter.id),
      }));

      const { data: newData, count: newCount } = await getListingData(
        EMPLOYEE_MANAGEMENT_SERVICE,
        "/employees",
        [
          ...modifiedFilters,
          {
            id: "companyId",
            value: companyId,
          },
        ],
        page,
        pageSize,
        sort || { key: "createdAt", value: "asc" },
        {},
        mockData,
        true
      );

      if (newData) {
        const supervisorsIds = [];
        newData.forEach(({ supervisor }) => {
          if (supervisor) {
            supervisorsIds.push(supervisor);
          }
        });

        setData(newData);
        setCount(newCount);
        setEmployeesIds(supervisorsIds);
      }
    },
    [companyId]
  );
  const employeeGroups = useEmployeeGroups(
    true,
    "companyId",
    companyId,
    true,
    !companyId
  );

  const departments = useDepartments(
    true,
    "companyId",
    companyId,
    true,
    !companyId
  );

  const employees = useEmployees(
    true,
    "id",
    employeesIds,
    false,
    !employeesIds.length
  );

  const sendMessageToUser = async (employeeId) => {
    try {
      await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/employee/${employeeId}/password/email`,
        "GET",
        {},
        {}
      );
      const message = "Wysłano e-mail do pracownika.";
      dynamicNotification(__(message));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/List/list.js - 123"
      );
      const message = "Nie udało się wysłać wiadomości e-mail.";
      dynamicNotification(e.message || __(message), "error");
    }
  };

  const exportContext = new ExportContext({
    service: EMPLOYEE_MANAGEMENT_SERVICE,
    path: departmentFunctionality
      ? "/employees-export-structure"
      : "/employees-export",
    additionalPath: "/employees-export-large",
    isAdditionalPathVisible: (employeesCount) =>
      !isAhr &&
      !departmentFunctionality &&
      employeesCount >= LARGE_EMPLOYEES_AMOUNT_LIMIT,
    permission: employeeEmployeePermissionRead,
    fileName: `employees(${companyName})`,
    handleAdditionalFilters: () => [
      {
        id: "companyId",
        value: companyId,
      },
    ],
  });

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`${__(
            "Lista pracowników dla firmy"
          )} ${companyName} (ID: ${companyId})`}
          breadcrumbsHeading={__("Lista pracowników")}
          breadcrumbs={breadcrumbs}
        />
        <DataTableControlled
          id="companyEmployeesListing"
          fetchData={fetchData}
          data={data}
          count={count}
          totalCount={totalEmployeesCount}
          filterable
          exportContext={exportContext}
          buttons={[
            {
              onClick: () => {
                history.push(getEditEmployeeUrl("-1", companyId));
              },
              permission: employeeEmployeePermissionWrite,
              text: `+ ${__("Dodaj pracownika")}`,
              color: "primary",
              id: "addEmployee",
            },
            {
              onClick: () => {
                history.push(getEmployeeEmailList(companyId));
              },
              permission: employeeEmployeePermissionWrite,
              text: __("Wyślij e-mail"),
              color: "primary",
              id: "sendEmail",
              isVisible: !isAhr,
            },
          ]}
          columns={[
            {
              Header: "ID",
              accessor: "id",
              width: 150,
              Cell: BusinessIdColumn,
              Filter: UuidFilter,
            },
            {
              Header: "Imię",
              accessor: "firstName",
            },
            {
              Header: "Nazwisko",
              accessor: "lastName",
            },
            {
              Header: "Login",
              accessor: "username",
            },
            {
              Header: "E-mail służbowy",
              accessor: "businessEmail",
              minWidth: 170,
            },
            {
              Header: "E-mail prywatny",
              accessor: "personalEmail",
              doNotDisplay: isAhr,
              minWidth: 170,
            },
            {
              Header: "Aktywny",
              accessor: "active",
              sortable: false,
              Filter: SelectFilter(booleanOptions),
              Cell: mapValueFromOptions(booleanOptions, "active"),
            },
            {
              Header: "Numer pracownika",
              accessor: "fk",
            },
            {
              Header: "Grupa pracownicza",
              accessor: "employeeGroup",
              Filter: SelectFilter(employeeGroups),
              sortable: false,
              Cell: mapValueFromOptions(employeeGroups, "employeeGroup"),
            },
            {
              Header: "Dział",
              accessor: "department",
              Filter: SelectFilter(departments),
              sortable: false,
              Cell: mapValueFromOptions(departments, "department"),
              hasNoPermission: !departmentFunctionality,
            },
            {
              Header: "Przełożony",
              accessor: "supervisor",
              sortable: false,
              Cell: mapValueFromOptions(employees, "supervisor"),
              hasNoPermission: !departmentFunctionality,
            },
            // {
            //   Header: "Zalogował się w Magento", // temporarily hide per client request
            //   accessor: LAST_LOGIN_DATE,
            //   sortable: false,
            //   Filter: SelectFilter(booleanOptions),
            //   minWidth: 130,
            //   Cell: mapValueFromOptions(
            //     booleanOptions,
            //     LAST_LOGIN_DATE,
            //     "value",
            //     "label",
            //     false,
            //     null,
            //     true
            //   ),
            // },
            {
              Header: "Akcja",
              accessor: "action",
              maxWidth: 150,
              filterable: false,
              sortable: false,
              Cell: (cellData) => (
                <div>
                  <div className="d-block">
                    <ActionColumn
                      data={cellData.row._original}
                      buttons={[
                        {
                          id: "companEmployeesEdit",
                          href: getEditEmployeeUrl(
                            cellData.row.id,
                            cellData.row._original.companyId
                          ),
                          className: "m-1",
                          color: "link",
                          label: "Edytuj",
                        },
                      ]}
                    />
                  </div>
                  {cellData?.original?.businessEmail &&
                    !cellData?.original?.lastLoginDate && (
                      <div className="d-block">
                        <ActionColumn
                          data={cellData.row._original}
                          buttons={[
                            {
                              id: "companEmployeesSendEmail",
                              onClick: () => {
                                sendMessageToUser(cellData?.original?.id);
                              },
                              className: "m-1",
                              color: "link",
                              label: "Wyślij e-mail",
                            },
                          ]}
                        />
                      </div>
                    )}
                </div>
              ),
            },
          ]}
        />
      </CSSTransitionGroup>
    </>
  );
}

export const mockData = [
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    firstName: "Jan",
    lastName: "Kowalski",
    companyId: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    username: "10clouds344222281",
    email: "jan.kowalski@gmial.com",
    active: true,
    employeeGroup: "Użytkownicy",
    fk: "3c3275af-7247-470e-9397-d114957deb71",
  },
];

List.propTypes = {
  companyId: PropTypes.string.isRequired,
  getEditEmployeeUrl: PropTypes.func.isRequired,
  getEmployeeEmailList: PropTypes.func,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
  companyName: PropTypes.string,
};

List.defaultProps = {
  companyName: "",
  getEmployeeEmailList: () => {},
};
