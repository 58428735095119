import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { restApiRequest } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import ApiForbiddenError from "src/utils/Api/ApiForbiddenError";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import Spinner from "src/Components/Spinner";
import ContentLoading from "./contentLoading";

const NoAccessMessage = () => (
  <strong>
    {__("Taki zasób nie istnieje lub nie masz do niego dostępu.")}
  </strong>
);

const fetchData = async (
  updateData,
  path,
  isMock,
  service,
  { headers, params },
  mockDataPath
) => {
  if (isMock || isMockView()) {
    const data = await fetch(`/mockData${mockDataPath || path}.json`).then(
      (res) => res.json()
    );
    updateData(data);
  } else {
    const result = await restApiRequest(
      service,
      path,
      "GET",
      { headers, params },
      {}
    );
    updateData(result);
  }
};

export default function DataLoading({
  children,
  service,
  options,
  updateData,
  endpoint,
  isMock,
  fetchedData,
  isNew,
  forceLoader,
  mockDataEndpoint,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);

  useEffect(() => {
    if (!(fetchedData || isNew) && !isLoading && hasAccess) {
      setIsLoading(true);
      fetchData(
        updateData,
        endpoint,
        isMock,
        service,
        options,
        mockDataEndpoint
      )
        .then(() => setIsLoading(false))
        .catch((e) => {
          newRelicErrorReport(e, "Components/Loading/dataLoading.js - 73");
          if (e instanceof ApiForbiddenError) {
            setHasAccess(false);
          } else {
            dynamicNotification(
              e.message || __("Nie udało się pobrać danych"),
              "error"
            );
          }
        });
    }
  }, [
    fetchedData,
    isNew,
    isLoading,
    updateData,
    endpoint,
    isMock,
    service,
    options,
    mockDataEndpoint,
    hasAccess,
    setHasAccess,
  ]);

  return (
    <ContentLoading
      message={hasAccess ? Spinner : <NoAccessMessage />}
      show={!(fetchedData || isNew) || forceLoader}
      messageStyle={{ position: "absolute", top: "20%", right: "50%" }}
    >
      {children}
    </ContentLoading>
  );
}

DataLoading.propTypes = {
  children: PropTypes.node.isRequired,
  endpoint: PropTypes.string.isRequired,
  mockDataEndpoint: PropTypes.string,
  fetchedData: PropTypes.bool.isRequired,
  forceLoader: PropTypes.bool,
  isMock: PropTypes.bool,
  isNew: PropTypes.bool,
  service: PropTypes.string,
  options: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    headers: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    params: PropTypes.object,
  }),
  updateData: PropTypes.func.isRequired,
};
DataLoading.defaultProps = {
  service: null,
  mockDataEndpoint: null,
  forceLoader: false,
  isMock: false,
  isNew: false,
  options: {},
};
