import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { filterNavItemsByAlc } from "src/utils/RoleBasedSecurity/filters";
import SidebarMenu from "src/Components/SidebarMenu";
import CompanyContext, {
  useCompanyData,
} from "src/Pages/Company/CompanyContext";
import useExcludeMenuItems from "src/utils/hooks/menu/useExcludeMenuItems";
import DefaultFallback from "src/Layout/AppMain/DefaultFallback";
import { getSubMenuByLocation, getSubMenuComponentByCode } from "./utils";
import { AhrMainNav, MainNav } from "./NavItems";

const Nav = () => {
  const history = useHistory();
  const rbsContext = useContext(RbsContext);
  const companyData = useCompanyData();
  const { isLoaded = false } = useContext(CompanyContext);
  const ahrMenu = rbsContext.userInfo.isAhr();
  const excludeIds = useExcludeMenuItems();
  const [currentSubmenu, setCurrentSubmenu] = useState(
    getSubMenuByLocation(history.location.pathname, ahrMenu)
  );
  const [showSubMenu, setShowSubMenu] = useState(Boolean(currentSubmenu));

  useEffect(
    () =>
      history.listen((location) => {
        const submenu = getSubMenuByLocation(location.pathname, ahrMenu);
        if (submenu !== currentSubmenu) {
          setCurrentSubmenu(submenu);
          setShowSubMenu(true);
        }
      }),
    [currentSubmenu, history, ahrMenu]
  );

  if (!isLoaded) {
    return DefaultFallback;
  }

  if (showSubMenu && currentSubmenu && !ahrMenu) {
    const subMenu = getSubMenuComponentByCode(currentSubmenu);
    return subMenu ? (
      <>
        <Button
          data-t1="menuSwitcher"
          className="position-absolute mb-2 btn-icon btn-icon-only"
          color="link"
          outline
          style={{ right: 0, zIndex: 100 }}
          onClick={() => setShowSubMenu(false)}
        >
          <i className="pe-7s-angle-left-circle btn-icon-wrapper"> </i>
        </Button>
        <h5 className="app-sidebar__heading pr-3">
          {subMenu.getTitle(history.location.pathname)}
        </h5>
        <SidebarMenu
          key="main_menu"
          content={filterNavItemsByAlc(
            subMenu.getConfig(history.location.pathname, null, excludeIds),
            rbsContext.userInfo,
            companyData
          )}
          isSubMenu
        />
      </>
    ) : null;
  }
  let linkId = null;
  if (currentSubmenu) {
    const subMenu = getSubMenuComponentByCode(currentSubmenu);
    linkId = subMenu ? subMenu.parent_id : null;
  }
  return (
    <>
      {currentSubmenu ? (
        <Button
          data-t1="menuSwitcher"
          className="position-absolute mb-2 btn-icon btn-icon-only"
          color="link"
          outline
          style={{ right: 0, zIndex: 100 }}
          onClick={() => setShowSubMenu(true)}
        >
          <i className="pe-7s-angle-right-circle btn-icon-wrapper"> </i>
        </Button>
      ) : null}
      <h5 className="app-sidebar__heading">Menu</h5>
      <SidebarMenu
        key="sub_menu"
        content={filterNavItemsByAlc(
          ahrMenu ? AhrMainNav : MainNav,
          rbsContext.userInfo,
          companyData
        )}
        linkId={linkId}
        isSubMenu={false}
      />
    </>
  );
};

export default Nav;
