import React, { useContext, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { COMPANY_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import ApiForbiddenError from "src/utils/Api/ApiForbiddenError";
import ForbiddenErrorPage from "src/utils/ErrorPages/403";
import isEmptyValue from "src/utils/jsHelpers/isEmptyValue";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { fetchOverriddenLabels } from "src/utils/Company";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { mockData } from "./CompanyList";

const CompanyContext = React.createContext({
  data: {},
  refresh: () => null,
  refreshConfig: () => null,
  isLoaded: false,
  inCompanyContext: false,
});

export const CompanyContextWrapper = ({ companyId, children, showError }) => {
  const [data, setData] = useState({ id: companyId });
  const [hasAccess, setHasAccess] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();

  const fetchCompanyData = useCallback(() => {
    if (companyId) {
      restApiRequest(
        COMPANY_MANAGEMENT_SERVICE,
        `/companies/${companyId}`,
        "GET",
        {},
        mockData.find(({ id }) => id === companyId)
      )
        .then((res) => {
          if (res) {
            setData(res);
            fetchCompanyConfiguration();
            setIsLoaded(true);
          }
        })
        .catch((e) => {
          newRelicErrorReport(e, "Pages/Company/CompanyContext.js - 34");
          if (e instanceof ApiForbiddenError) {
            setHasAccess(false);
          } else {
            console.error(e);
          }
        });
    } else {
      setData({});
      setIsLoaded(true);
    }
  }, [companyId]);

  const fetchCompanyConfiguration = useCallback(async () => {
    if (companyId) {
      const overriddenLabels = await fetchOverriddenLabels(companyId, isAhr);
      setData((res) => ({ ...res, overriddenLabels }));
    }
  }, [companyId, isAhr]);

  useEffect(() => {
    fetchCompanyData();
  }, [companyId, fetchCompanyData]);
  let content = children;
  if (!hasAccess) {
    content = showError ? <ForbiddenErrorPage /> : null;
  }
  return (
    <CompanyContext.Provider
      value={{
        data,
        inCompanyContext: Boolean(companyId),
        isLoaded,
        refresh: fetchCompanyData,
        refreshConfig: fetchCompanyConfiguration,
      }}
    >
      {content}
    </CompanyContext.Provider>
  );
};

CompanyContextWrapper.propTypes = {
  companyId: PropTypes.string,
  children: PropTypes.node.isRequired,
  showError: PropTypes.bool,
};

CompanyContextWrapper.defaultProps = {
  showError: true,
  companyId: null,
};

export const useCompanyName = () => {
  const { data = {} } = useContext(CompanyContext);
  const { fullName: name = "" } = data;
  return name;
};

export const useCompanyId = () => {
  const { data = {} } = useContext(CompanyContext);
  const { id = "" } = data;
  return id;
};

export const useCompanyHasFunctionality = (code) => {
  const { data = {} } = useContext(CompanyContext);
  const { functionalities = [] } = data;
  return functionalities.includes(code);
};

export const useCompanyValue = (fieldCode, defaultValue = null) => {
  const { data = {} } = useContext(CompanyContext);
  return !isEmptyValue(data[fieldCode]) ? data[fieldCode] : defaultValue;
};

export const useCompanyData = () => {
  const { data = {} } = useContext(CompanyContext);
  return data;
};

export const useCompanyOverriddenLabels = () => {
  const { data = {} } = useContext(CompanyContext);
  return data?.overriddenLabels || {};
};

export default CompanyContext;
