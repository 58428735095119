const isDisabledByExclusiveFields = (
  fieldConfig,
  companyConfig,
  generalConfig
) => {
  const fieldConfigExclusiveWithExtend = fieldConfig.exclusiveWithExtend;
  if (fieldConfigExclusiveWithExtend && companyConfig) {
    const { fields = null, value = null } = fieldConfigExclusiveWithExtend;
    if (typeof fields !== "string") return false;

    const exclusiveWithExtend = JSON.parse(fields.replace(/([{}])/g, ""));

    return exclusiveWithExtend.every(
      (field) => (companyConfig[field] ?? generalConfig[field]) === value
    );
  }

  return false;
};

export default isDisabledByExclusiveFields;
