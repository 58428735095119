import React from "react";

import __ from "src/utils/Translations";
import priceFormatterEmptyIfNull from "src/utils/jsHelpers/priceFormatterEmptyIfNull";

const priceColumnEmptyIfNull = (cellInfo) => (
  <div className="d-block w-100 text-center">
    {priceFormatterEmptyIfNull(cellInfo.row[cellInfo.column.id])}
  </div>
);

export default priceColumnEmptyIfNull;
