import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Label } from "reactstrap";
import CKEditor from "ckeditor4-react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import {
  AGREEMENT_SERVICE,
  API_TOTALREWARD_SERVICE,
  CATALOG_MANAGEMENT_SERVICE,
  COMPANY_MANAGEMENT_SERVICE,
  CONFIGURATION_SERVICE,
  NOTIFICATION_SERVICE,
  SUBSCRIPTION_MANAGEMENT_SERVICE,
  TOURISM_SERVICE,
  uploadFile,
} from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { hasAccessTo } from "src/utils/RoleBasedSecurity/filters";
import {
  agreementMediaPermissionWrite,
  catalogMediaPermissionWrite,
  companyMediaPermissionWrite,
  configurationMediaWrite,
  notificationMediaPermissionWrite,
  subscriptionMediaPermissionWrite,
  totalRewardPerksWrite,
  tourismMediaPermissionWrite,
} from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function Wysiwyg({
  id,
  label,
  translatorTrigger,
  value,
  height,
  disabled,
  onChange,
  tooltip,
  belowLabelComponent,
  afterEditorComponent,
  className,
  errorMessage,
  validateField,
  validation,
  uploadService,
  allowDirtyHTML,
}) {
  const [removeListener, setRemoveListener] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [instanceReady, setInstanceReady] = useState(false);
  const [uploadAllowed, setUploadAllowed] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [editorData, setEditorData] = useState("");
  const { userInfo } = useContext(RbsContext);
  const wysRef = useRef(null);
  const editor = wysRef.current?.editor;

  const handleTyping = (e) => {
    setIsTyping(true);
    setEditorData(e.editor.getData());
  };

  useEffect(() => {
    let timeout;
    if (isTyping) {
      timeout = setTimeout(() => {
        setIsTyping(false);
      }, 250);
    }

    return () => window.clearTimeout(timeout);
    //  eslint-disable-next-line
  }, [editorData]);

  useEffect(() => {
    if (editor?.instanceReady && !isTyping) {
      const actualEditorData = fromEditorValueParser(
        editor.getData(),
        allowDirtyHTML
      );
      if ((value || value === "") && actualEditorData !== value) {
        editor.setData(value);
      }
    }
    //  eslint-disable-next-line
  }, [value, editor?.instanceReady]);

  useEffect(() => {
    setUploadAllowed(isUploadAllowed(uploadService, userInfo));
  }, [userInfo, uploadService]);

  const onUpdate = (event) => {
    if (disabled) {
      return;
    }
    let data = event?.editor?.getData();
    if (data) {
      data = fromEditorValueParser(data, allowDirtyHTML);
    }
    if (data === value) {
      return;
    }
    onChange(id, data);
    validateField(id, data, validation);
  };

  const updateEditorData = async (editor) => {
    const actualEditorData = fromEditorValueParser(
      editor.getData(),
      allowDirtyHTML
    );
    await editor.setData(actualEditorData);
  };

  const uploadImage = useCallback(
    async (file) => {
      try {
        const { contentUrl } = await uploadFile(
          uploadService,
          "/media-objects",
          file,
          { accept: "application/json" },
          ["png", "jpg", "jpeg"]
        );
        return contentUrl;
      } catch (e) {
        console.error(e);
        newRelicErrorReport(e, "Components/FormElements/Wysiwyg.js - 127");
        dynamicNotification(
          e.message || __("Nie udało się wgrać pliku"),
          "error"
        );
        return null;
      }
    },
    [uploadService]
  );

  const onBeforeLoad = useCallback((CKEDITOR) => {
    if (!CKEDITOR.plugins.externals.imageUpload) {
      CKEDITOR.plugins.addExternal(
        "imageUpload",
        "/ckeditor/plugins/imageUpload/",
        "plugin.js"
      );
    }
    if (!CKEDITOR.plugins.externals.flexboxLayout) {
      CKEDITOR.plugins.addExternal(
        "flexboxLayout",
        "/ckeditor/plugins/flexboxLayout/",
        "plugin.js"
      );
    }
    if (!CKEDITOR.plugins.externals.customStyles) {
      CKEDITOR.plugins.addExternal(
        "customStyles",
        "/ckeditor/plugins/customStyles/",
        "plugin.js"
      );
    }
  }, []);

  useEffect(() => {
    // hack to solve problem with cke-editor FEN-5653
    if (instanceReady) {
      setReadOnly(disabled);
    }
  }, [disabled, instanceReady]);

  if (uploadAllowed === null) {
    return null;
  }
  return (
    <div className="input-group-omb">
      {label ? (
        <Label for={id}>
          <span data-t1={`wysiwygLabel__${id}`}>
            {label} {translatorTrigger} {tooltip}
          </span>
        </Label>
      ) : null}
      {belowLabelComponent ? <div>{belowLabelComponent}</div> : null}
      <div
        className={className}
        data-t1={`${id}Wysiwyg`}
        style={{ opacity: disabled ? 0.6 : 1 }}
      >
        <CKEditor
          id={id}
          ref={wysRef}
          onChange={onUpdate}
          onKey={handleTyping}
          onBlur={() => updateEditorData(editor)}
          onBeforeDestroy={(event) => updateEditorData(event?.editor)}
          onMode={(event) => {
            const editable = event?.editor?.editable();
            if (event.editor.mode !== "source") {
              if (removeListener) {
                removeListener();
              }
            } else {
              const listenerObject = editable.attachListener(
                editable,
                "input",
                () => {
                  let data = event?.editor?.getData();
                  if (data) {
                    data = fromEditorValueParser(data, allowDirtyHTML);
                  }
                  onChange(id, data);
                  validateField(id, data, validation);
                }
              );
              setRemoveListener(() => listenerObject.removeListener);
            }
          }}
          height={1}
          onAfterPaste={onUpdate}
          onAfterCommandExec={onUpdate}
          onInstanceReady={() => {
            setInstanceReady(true);
          }}
          onBeforeLoad={onBeforeLoad}
          config={{
            height: height || 150,
            extraPlugins: [
              "flexboxLayout",
              "customStyles",
              uploadAllowed ? "imageUpload" : null,
            ],
            contentsCss: ["/ckeditor/styles/wysiwyg.css"],
            uploadFunction: uploadImage,
            autoParagraph: false,
            allowedContent: {
              $1: {
                // Use the ability to specify elements as an object.
                // elements: CKEditor?.CKEditor?.dtd,
                // elements: window?.CKEDITOR?.dtd,
                // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_disallowed_content.html#how-to-allow-everything-except
                // @ToDo: Cannot set reference to dtd object here but without setting elements it seems that it fallback to default
                attributes: true,
                styles: true,
                classes: true,
              },
            },
            disallowedContent: "script; iframe; *[on*] *{javascript*}",
          }}
          readOnly={readOnly}
          onFocus={() => {
            const targetNode = document.querySelector("#root");
            const clickEvent = document.createEvent("MouseEvents");
            clickEvent.initEvent("mousedown", true, true);
            targetNode.dispatchEvent(clickEvent);
          }}
        />
        <ValidationMessage message={errorMessage} />
      </div>
      {afterEditorComponent ? <div>{afterEditorComponent}</div> : null}
    </div>
  );
}

const fromEditorValueParser = (value, allowDirtyHTML) => {
  if (!(value || value === "")) {
    return null;
  }
  return allowDirtyHTML
    ? value.replace(/&oacute;/g, "ó")
    : DOMPurify.sanitize(value, { ADD_ATTR: ["target", "onclick"] }).replace(
        /&oacute;/g,
        "ó"
      );
};

const isUploadAllowed = (service, userInfo) => {
  if (!userInfo) {
    return false;
  }
  switch (service) {
    case COMPANY_MANAGEMENT_SERVICE:
      return hasAccessTo(userInfo, companyMediaPermissionWrite);
    case CATALOG_MANAGEMENT_SERVICE:
      return hasAccessTo(userInfo, catalogMediaPermissionWrite);
    case CONFIGURATION_SERVICE:
      return hasAccessTo(userInfo, configurationMediaWrite);
    case NOTIFICATION_SERVICE:
      return (
        hasAccessTo(userInfo, notificationMediaPermissionWrite) ||
        userInfo.isAhr()
      );
    case TOURISM_SERVICE:
      return hasAccessTo(userInfo, tourismMediaPermissionWrite);
    case AGREEMENT_SERVICE:
      return hasAccessTo(userInfo, agreementMediaPermissionWrite);
    case SUBSCRIPTION_MANAGEMENT_SERVICE:
      return hasAccessTo(userInfo, subscriptionMediaPermissionWrite);
    case API_TOTALREWARD_SERVICE:
      return hasAccessTo(userInfo, totalRewardPerksWrite);
    default:
      return false;
  }
};

Wysiwyg.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
  belowLabelComponent: PropTypes.node,
  afterEditorComponent: PropTypes.node,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  translatorTrigger: PropTypes.node,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  uploadService: PropTypes.string,
  validateField: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
  allowDirtyHTML: PropTypes.bool,
};

Wysiwyg.defaultProps = {
  id: "",
  label: "",
  tooltip: null,
  belowLabelComponent: null,
  afterEditorComponent: null,
  value: "",
  errorMessage: "",
  translatorTrigger: null,
  uploadService: null,
  height: null,
  disabled: false,
  className: "",
  validateField: () => {},
  validation: [],
  allowDirtyHTML: false,
};
