import PropTypes from "prop-types";
import React from "react";
import __ from "src/utils/Translations";
import SecurityWrapper from "src/utils/RoleBasedSecurity/SecurityComponents/SecuirityWrapper";

const DropdownSecurityWrapper = ({
  withoutPermission,
  permissions,
  children,
}) =>
  withoutPermission ? (
    <>{children}</>
  ) : (
    <SecurityWrapper permission={permissions} disable={!withoutPermission}>
      {children}
    </SecurityWrapper>
  );

DropdownSecurityWrapper.propTypes = {
  permissions: PropTypes.string.isRequired,
  withoutPermission: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default DropdownSecurityWrapper;
