import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import __ from "src/utils/Translations";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import PerkUsers from "src/Pages/Company/TotalReward/PerkUsers/Listing";

export default () => {
  const companyName = useCompanyName();
  const companyId = useCompanyId();

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={`${__(
          "Przydzielone Benefity"
        )} ${companyName} (ID: ${companyId})`}
        breadcrumbsHeading={__("Przydzielone Benefity")}
        pushToHistory
        breadcrumbs={[
          {
            title: "Total Reward Statement",
            link: `/ahr/total-reward`,
          },
        ]}
      />
      <PerkUsers companyId={companyId} />
    </CSSTransitionGroup>
  );
};
