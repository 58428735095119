import { AhrMainNav, MainNav } from "src/Layout/AppNav/NavItems";
import { filterNavItemsByAlc } from "src/utils/RoleBasedSecurity/filters";
import __ from "src/utils/Translations";
import { CompanySubMenu } from "src/Layout/AppNav/SubMenuItems";
import getResultsFromChildren from "./getResultsFromChildren";

const findMenuItems = (value, ctx) => {
  const { userInfo, inCompanyContext, companyData } = ctx;
  if (!value || value.length < 2) {
    return [];
  }
  const isAhr = userInfo.isAhr();
  const items = isAhr
    ? AhrMainNav
    : filterNavItemsByAlc(MainNav, userInfo, companyData);
  let result = getResultsFromChildren(items, value)
    .slice(0, 5)
    .map(({ label, ...rest }) => ({ ...rest, label: __(label) }));
  if (!isAhr && inCompanyContext) {
    const { id: companyId, shortName: companyName } = companyData;
    if (companyId) {
      const subItems = filterNavItemsByAlc(
        CompanySubMenu.getConfig(null, companyId),
        userInfo,
        companyData
      );
      result = [
        ...result,
        ...getResultsFromChildren(subItems, value)
          .slice(0, 5)
          .map(({ label, ...rest }) => ({
            ...rest,
            label: `${__(label)} (${companyName})`,
          })),
      ];
    }
  }

  return result;
};

export default findMenuItems;
