import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import AsyncSelect from "react-select/async";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import __ from "src/utils/Translations";
import { defaultStyles, errorStyles } from "./Autocomplete";

export default function AsyncAutocomplete({
  value,
  label,
  onChange,
  id,
  errorMessage,
  validateField,
  validation,
  fetchOptions,
  tooltip,
  inputSwitcher,
  disabled,
  isMultiselect,
  placeholder,
  classNamePrefix,
}) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const timer = useRef();

  useEffect(() => {
    if (!value && selectedValue) setSelectedValue(null);
  }, [value, selectedValue]);

  const inputGroup = (
    <div
      className="input-group-omb font-weight-normal text-decoration-none"
      data-t1={id}
    >
      <AsyncSelect
        key={`${id}_autocomplete-${value}`}
        isClearable
        className="omb-autocomplete font-weight-normal text-decoration-none"
        isDisabled={disabled}
        isMulti={isMultiselect}
        noOptionsMessage={() => __("Brak opcji")}
        loadingMessage={() => __("Wczytywanie...")}
        hideSelectedOptions={false}
        classNamePrefix={classNamePrefix}
        menuPlacement="auto"
        cacheOptions
        defaultOptions
        loadOptions={(inputValue, callback) => {
          if (timer.current) clearTimeout(timer.current);
          timer.current = setTimeout(async () => {
            if (
              (inputValue && inputValue.length > 1) ||
              (!inputValue && value?.length)
            ) {
              const newOptions = await fetchOptions(inputValue);
              if (!selectedValue) setSelectedValue(newOptions);
              callback(newOptions);
            } else {
              if (!selectedValue) setSelectedValue([]);
              callback([]);
            }
          }, 500);
        }}
        id={id}
        defaultInputValue={searchValue}
        onInputChange={(query) => setSearchValue(query)}
        value={selectedValue}
        styles={errorMessage ? errorStyles : defaultStyles}
        onChange={(option, triggeredAction) => {
          const value = option?.length ? option[0]?.value : option?.value;
          const isRemoveAction = triggeredAction.action.includes("remove");
          const isClearACtion = triggeredAction.action.includes("clear");
          setSelectedValue(option);
          onChange(
            isClearACtion ? [] : option,
            isRemoveAction || isClearACtion
          );
          validateField(id, value, validation);
        }}
        placeholder={placeholder || __("Zacznij wpisywać...")}
        onKeyDown={(e) => e.stopPropagation()}
        onBlur={async () => {
          if (validateField) {
            validateField(id, value, validation);
          }
        }}
      />
      <ValidationMessage message={errorMessage} />
    </div>
  );

  return (
    <>
      {label ? (
        <Label data-t1={`${id}Label`} for={id} className="mr-sm-2">
          {label}
          {tooltip ? <> {tooltip}</> : null}
        </Label>
      ) : null}
      {inputSwitcher ? (
        <Row>
          <Col sm={9}>{inputGroup}</Col>
          <Col sm={3}>{inputSwitcher}</Col>
        </Row>
      ) : (
        inputGroup
      )}
    </>
  );
}

AsyncAutocomplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  tooltip: PropTypes.node,
  errorMessage: PropTypes.string,
  classNamePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  validateField: PropTypes.func,
  inputSwitcher: PropTypes.node,
  disabled: PropTypes.bool,
  isMultiselect: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
  fetchOptions: PropTypes.func.isRequired,
};

AsyncAutocomplete.defaultProps = {
  id: "",
  label: "",
  value: "",
  errorMessage: "",
  inputSwitcher: null,
  classNamePrefix: null,
  disabled: false,
  isMultiselect: false,
  tooltip: null,
  placeholder: null,
  validateField: () => {},
  validation: [],
};
