import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import __ from "src/utils/Translations";
import DefaultFallback from "src/Layout/AppMain/DefaultFallback";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import RbsContext from "./RbsContext";
import {
  clearSessionData,
  cookieExist,
  deleteSession,
  getCookieValue,
  getSession,
  getUserInfo,
  IMPERSONATE_AHR_COOKIE_NAME,
  initSession,
  SESSION_STORAGE_KEY,
} from "./Session";
import tmpAuthorization from "./tmpAuthorization";
import UserInfo from "./UserInfo";

export default function RbsAppWrapper({ children }) {
  const [userInfo, setUserInfo] = useState(new UserInfo({}));
  const [isLoggedIn, setIsLogged] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    initializeSession();
  }, []);

  const tmpLoginAction = async (type) => {
    try {
      const {
        token,
        refresh_token: refreshToken,
        date,
        expiresIn,
        userInfo: user,
      } = await tmpAuthorization(type);

      const session = getSession();
      await session.login(token, refreshToken, date, expiresIn);
      setUserInfo(new UserInfo(user));
      setIsLogged(Boolean(await session.getValidAccessToken()));
      return null;
    } catch (e) {
      newRelicErrorReport(e, "utils/RoleBasedSecurity/RbsAppWrapper.js - 43");
      console.error(e);
      return __("Nieprawidłowy login lub hasło");
    }
  };
  const logoutAction = async () => {
    try {
      const storedValue = localStorage.getItem(SESSION_STORAGE_KEY);
      const parsedStoredValue = JSON.parse(storedValue);
      const hasAhrCookie = cookieExist(IMPERSONATE_AHR_COOKIE_NAME);
      let cookieValue = "";
      if (hasAhrCookie) {
        cookieValue = getCookieValue(IMPERSONATE_AHR_COOKIE_NAME);
        cookieValue = JSON.parse(cookieValue);
      }

      const redirectUrl = hasAhrCookie
        ? cookieValue.rdr
        : window.location.origin;
      clearSessionData();
      await new Promise((resolve) => {
        window.location = `/signin/oauth/logout?redirect_url=${redirectUrl}&id_token_hint=${parsedStoredValue.id_token}`;
        setTimeout(() => resolve(), 5000);
      });
      return null;
    } catch (e) {
      newRelicErrorReport(e, "utils/RoleBasedSecurity/RbsAppWrapper.js - 59");
      console.error(e);
      return __("Nie udaoło się wylogować");
    }
  };

  const initializeSession = async () => {
    const session = await initSession();
    let isAlreadyLoggedIn = Boolean(await session.getValidAccessToken());
    if (isAlreadyLoggedIn) {
      try {
        const user = await getUserInfo();
        setUserInfo(new UserInfo(user));
      } catch (e) {
        clearSessionData();
        deleteSession();
        await initSession();
        isAlreadyLoggedIn = false;
      }
    }

    setIsLogged(isAlreadyLoggedIn);
    setIsInitializing(false);
  };

  if (isInitializing) {
    return DefaultFallback;
  }

  return (
    <RbsContext.Provider
      value={{
        isLoggedIn,
        login: tmpLoginAction,
        logout: logoutAction,
        userInfo,
      }}
    >
      {children}
    </RbsContext.Provider>
  );
}

RbsAppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
