/* eslint-disable no-underscore-dangle, react/prop-types */
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import { SelectFilter } from "src/Components/DataTable/filters";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import __ from "src/utils/Translations";
import { restApiRequest, TRANSLATOR_SERVICE } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import { ExportContext } from "src/Components/DataTableControlled/context";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { getAllOptions } from "./fetchScopeOptions";
// eslint-disable-next-line import/no-cycle
import Form from "./form";
import GoogleTranslate from "./googleTranslate";
import TranslationColumn from "./translationColumn";

export default function Listing({
  language,
  type,
  mockData,
  scopeOptions,
  exportContext,
}) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [activeId, setActiveId] = useState(null);
  const [formId, setFormId] = useState(null);
  const fetchData = async (filters, page, pageSize, sort) => {
    const { data: newData, count: newCount } = await getListingData(
      TRANSLATOR_SERVICE,
      "/translations",
      filters,
      page,
      pageSize,
      sort,
      {
        params: {
          language,
          type,
        },
      },
      mockData
    );
    setData(newData);
    setCount(newCount);
    getSuggestions(newData);
  };

  const getSuggestions = async (listingData) => {
    const phrases = listingData.map((item) => item.phrase).filter(Boolean);
    if (!phrases.length) {
      return;
    }
    const suggestions = await restApiRequest(
      TRANSLATOR_SERVICE,
      "/get-suggestions",
      "GET",
      {
        params: {
          language,
          value: phrases,
        },
      },
      []
    );

    const updatedData = [...listingData];
    if (suggestions.length) {
      suggestions.forEach(({ phrase, translations }) => {
        updatedData.forEach((item) => {
          if (item.phrase.toLowerCase() === phrase.toLowerCase()) {
            // eslint-disable-next-line no-param-reassign
            item.suggestions = translations;
          }
        });
      });
      setData(updatedData);
    }
  };

  const onSave = async (id, value) => {
    await updateData(id, value);
    setActiveId(null);
  };

  const closeForm = () => {
    setFormId(null);
  };
  const updateData = async (id, value) => {
    try {
      setData(editItem(id, { loading: true }));
      await restApiRequest(TRANSLATOR_SERVICE, `/translations/${id}`, "PATCH", {
        body: {
          translation: value,
        },
      });
      setData(editItem(id, { translation: value }));
      dynamicNotification(__("Pomyślnie zapisano tłumaczenie."));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "/Pages/Translation/utils/listing.js - 105");
      dynamicNotification(
        __("Nie udało się poprawnie zapisać tłumaczenia."),
        "error"
      );
    }
    setData(editItem(id, { loading: false }));
  };
  const fetchGoogleSuggestion = async (id) => {
    try {
      const { autoTranslation } = await restApiRequest(
        TRANSLATOR_SERVICE,
        `/translations/${id}/auto`,
        "POST",
        {},
        { autoTranslation: "someValue" }
      );
      setData(editItem(id, { autoTranslation }));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "/Pages/Translation/utils/listing.js - 130");
      dynamicNotification(
        __("Nie udało się pobrać sugestii tłumaczenia."),
        "error"
      );
    }
  };
  const setFromSuggestion = async (id) => {
    const item = data.find((el) => el.id === id);
    if (item) {
      await updateData(id, item.autoTranslation || null);
    }
  };

  const ids = data.map(({ id }) => id);
  return (
    <>
      <DataTableControlled
        id="translationsListing"
        exportContext={exportContext}
        columns={[
          {
            Header: "Kod",
            accessor: "code",
            width: 400,
          },
          {
            Header: "Zakres",
            accessor: "scope",
            Filter: SelectFilter(scopeOptions, true),
            filterMethod: (filter) => {
              switch (filter.value) {
                default:
                  return true;
              }
            },
            width: 150,
            Cell: mapValueFromOptions(scopeOptions, "scope"),
          },
          {
            Header: "Oryginalna wartość",
            accessor: "phrase",
          },
          {
            Header: "Tłumaczenie",
            accessor: "translation",
            Cell: TranslationColumn,
            updateData,
            setActiveId,
            activeId,
            ids,
          },
          {
            Header: "Sugestia",
            accessor: "suggestions",
            filterable: false,
            sortable: false,
            Cell: ({ row }) => (
              <div>
                {row.suggestions
                  ? row.suggestions.map(({ value, scope }, key) =>
                      row.scope !== scope ? (
                        <Fragment
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={key}
                        >
                          {value} ({getLabelForOption(getAllOptions(), scope)}
                          )
                          <br />
                        </Fragment>
                      ) : null
                    )
                  : null}
              </div>
            ),
          },
          {
            Header: "Google Translate",
            accessor: "autoTranslation",
            filterable: false,
            sortable: false,
            width: 100,
            fetchGoogleSuggestion,
            setFromSuggestion,
            Cell: GoogleTranslate,
          },
          {
            Header: "Akcja",
            accessor: "action",
            filterable: false,
            sortable: false,
            width: 100,
            Cell: ({ row }) => (
              <div className="d-block w-100 text-center">
                <Button
                  color="link"
                  onClick={() => setFormId(row._original.id)}
                >
                  Edytuj
                </Button>
              </div>
            ),
          },
        ]}
        fetchData={fetchData}
        data={data}
        count={count}
        filterable
        getTrProps={(state, rowInfo) => ({
          onClick: (e) => {
            if (e.target.tagName === "INPUT") {
              const { id } = rowInfo.row._original;
              if (id !== activeId) {
                setActiveId(id);
              }
            }
          },
        })}
      />
      {formId ? (
        <Form
          close={closeForm}
          initialData={data.find((item) => item.id === formId)}
          onSave={onSave}
          language={language}
        />
      ) : null}
    </>
  );
}

const editItem = (id, newData) => (data) => {
  const updatedData = [...data];
  const item = updatedData.find((el) => el.id === id);
  if (item) {
    updatedData[data.indexOf(item)] = { ...item, ...newData };
  }
  return updatedData;
};

const getLabelForOption = (options, value) => {
  const matchedOption = options.find((option) => option.value === value);
  return matchedOption ? matchedOption.label : value;
};

Listing.propTypes = {
  language: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mockData: PropTypes.array.isRequired,
  scopeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.number,
    })
  ),
  type: PropTypes.number.isRequired,
  exportContext: PropTypes.instanceOf(ExportContext),
};

Listing.defaultProps = {
  scopeOptions: [],
  exportContext: null,
};
