import { CustomInput } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";

export default function InputSwitcher({
  id,
  onChange,
  checked,
  label,
  className,
  dataT1Custom,
  disabled,
}) {
  const isDefaultValue = label.includes("domyśl");
  const dataT1 = isDefaultValue ? `${id}_defaultValue` : id;

  if (id === "email-templates/email-farewell/bank-list") {
    return null;
  }

  const onChangeHandler = (e) => onChange(e.target.checked);
  return (
    <span className={className || "my-2"}>
      <CustomInput
        inline
        checked={checked}
        type="checkbox"
        id={`${id}_input_switch`}
        onChange={onChangeHandler}
        name={`${id}_input_switch`}
        data-t1={dataT1Custom || dataT1}
        label={<span data-t1={dataT1Custom || dataT1}>{__(label)}</span>}
        value={checked}
        disabled={disabled}
      />
    </span>
  );
}

InputSwitcher.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  dataT1Custom: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

InputSwitcher.defaultProps = {
  label: "Użyj wartości domyślnej",
  className: null,
  dataT1Custom: null,
};
