import React from "react";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";
import { translatedFrequencyText } from "src/Pages/Report/helpers/paramtersHelper";

const FreqDescription = ({ info }) => (
  <div className="row subscription-info">
    <div className="col-md-12">
      {__("Występuje")}:{" "}
      <span className="subscription-info-annotation">
        {translatedFrequencyText(info)}
      </span>
    </div>
  </div>
);

export default FreqDescription;

FreqDescription.propTypes = {
  info: PropTypes.string.isRequired,
};
