import { useState, useEffect } from "react";
import { TOURISM_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function useAnixeSuppliers() {
  const [data, setData] = useState([]);

  useEffect(() => {
    restApiRequest(
      TOURISM_SERVICE,
      "/tourism-object-suppliers/unique",
      "GET",
      {
        params: {
          rowsPerPage: 1000,
        },
      },
      mockData
    )
      .then((resData) => {
        const resultArray = resData.map((item) => ({
          value: item,
          label: item,
        }));
        setData(resultArray);
      })
      .catch((e) => {
        newRelicErrorReport(
          e,
          "utils/hooks/suppliers/useUniqueAnixeSuppliers.js - 30"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy dostawców"),
          "error"
        );
      });
  }, []);

  return data;
}

const mockData = ["MYBENEFIT", "HTLB", "SUNHOTELS"];
