import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";
import { useLanguages } from "src/utils/Languages/LanguageContext";
import { correctLanguageParameters } from "src/Pages/Report/helpers/paramtersHelper";
import DatePicker from "src/Components/FormElements/DatePicker";
import DatePickerMax from "src/Components/FormElements/DatePickerMax";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";

const Data = ({
  details,
  handleUpdateReportExpiration,
  expirationDate,
  setExpirationDate,
  isNeverExpiring,
  setIsNeverExpiring,
}) => {
  const [isExpirationButtonDisabled, setIsExpirationButtonDisabled] =
    useState(true);

  const languages = useLanguages(true);
  const minDate = Date.now();
  const maxDate = new Date(Date.parse("2029-12-31"));
  const nvalidClass = "";

  const renderParameters = () => {
    if (details.parameters) {
      // eslint-disable-next-line no-param-reassign
      details.parameters = correctLanguageParameters(
        details.parameters,
        languages
      );
      return details.parameters.map((p) => (
        <FormGroup key={p.label}>
          <Label for={p.label}>{__(p.label)}</Label>
          {renderValues(p)}
        </FormGroup>
      ));
    }
    return "";
  };

  // eslint-disable-next-line max-len
  const renderValues = (parameter) =>
    parameter.values.map((p) => (
      <Input
        key={p.value}
        type="text"
        name={parameter.label}
        disabled
        value={__(p.label)}
      />
    ));

  return (
    <>
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <CardTitle>
                {__("Podstawowe dane")}
                <hr />
              </CardTitle>
              <Form>
                <FormGroup>
                  <Label for="reportGroup">{__("Grupa")}</Label>
                  <Input
                    type="text"
                    name="reportGroups"
                    disabled
                    value={__(details.reportGroup)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="reportName">{__("Nazwa raportu")}</Label>
                  <Input
                    type="text"
                    name="reportName"
                    value={__(details.reportName)}
                    disabled
                  />
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="saveDate">{__("Data zapisania")}</Label>
                      <Input
                        type="text"
                        name="saveDate"
                        value={__(details.creationDateStr)}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="reportFormat">{__("Format")}</Label>
                      <Input
                        type="text"
                        name="reportFormat"
                        value={__(details.fileExtension)}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="source">{__("Źródło")}</Label>
                  <Input
                    type="text"
                    name="source"
                    value={__(details.sourceName)}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="createdBy">{__("Utworzone przez")}</Label>
                  <Input
                    type="text"
                    name="createdBy"
                    value={__(details.creator)}
                    disabled
                  />
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <br />
          {details.isAgrArchive && (
            <Card>
              <CardBody>
                <CardTitle>
                  {__("Data ważności")}
                  <hr />
                </CardTitle>
                <Form>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <DatePickerMax
                          name="expiresAfter"
                          id="expiresAfter"
                          label={__("Usuń raport w dniu")}
                          errorMessage={nvalidClass}
                          value={isNeverExpiring ? "" : expirationDate}
                          onChange={(newValue) => {
                            setExpirationDate(newValue);
                            setIsExpirationButtonDisabled(false);
                          }}
                          validateField={() => {}}
                          dateFormat="yyyy-MM-dd HH:mm"
                          minDate={minDate}
                          maxDate={maxDate}
                          disabled={isNeverExpiring}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label class="mr-sm-2">&nbsp;</Label>
                        <ToggleSwitch
                          name="expiresNever"
                          checked={isNeverExpiring}
                          label={__("Ustaw jako bezterminowy")}
                          handleChange={() => {
                            setIsNeverExpiring(!isNeverExpiring);
                            setIsExpirationButtonDisabled(false);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  id="buttonSaveDate"
                  className="form-control mr-2 btn btn-lg"
                  color={isExpirationButtonDisabled ? "default" : "primary"}
                  onClick={handleUpdateReportExpiration}
                  disabled={isExpirationButtonDisabled}
                >
                  {__("Zatwierdź zmianę daty")}&nbsp;
                  {isNeverExpiring ? __("(bezterminowy)") : ""}
                </Button>
              </CardFooter>
            </Card>
          )}
        </Col>
        <Col lg="6">
          <Card>
            <CardBody>
              <CardTitle>
                {__("Parametry raportu")}
                <hr />
              </CardTitle>
              <Form>{renderParameters()}</Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

Data.propTypes = {
  details: PropTypes.objectOf.isRequired,
  handleUpdateReportExpiration: PropTypes.func.isRequired,
  expirationDate: PropTypes.object.isRequired,
  setExpirationDate: PropTypes.func.isRequired,
  isNeverExpiring: PropTypes.bool.isRequired,
  setIsNeverExpiring: PropTypes.func.isRequired,
};
export default Data;
