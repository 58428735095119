import PropTypes from "prop-types";
import React, { useState } from "react";

import { getTooltipIcon } from "src/Components/Tooltips/utils";
import { Tooltip } from "reactstrap";

export default function DefaultTooltip({
  type,
  content,
  placement,
  id,
  innerClassName,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen((prevState) => !prevState);
  const validId = id.replace(/[/:]/g, "");
  const icon = getTooltipIcon(type, validId);
  return (
    <>
      {icon}
      <Tooltip
        target={validId}
        placement={placement}
        isOpen={tooltipOpen}
        toggle={toggle}
        innerClassName={innerClassName}
      >
        {content}
      </Tooltip>
    </>
  );
}

DefaultTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  id: PropTypes.string.isRequired,
  placement: PropTypes.string,
  type: PropTypes.string,
  innerClassName: PropTypes.string,
};

DefaultTooltip.defaultProps = {
  placement: "auto",
  type: "info",
  innerClassName: "",
};
