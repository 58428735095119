import { useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function (companyId) {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const mutate = async (payload) => {
    setIsPending(true);
    try {
      const result = await restApiRequest(
        API_TOTALREWARD_SERVICE,
        `/omb/configs${payload.id ? `/${payload.id}` : ""}`,
        payload.id ? "PATCH" : "POST",
        {
          params: {
            tenant: companyId,
          },
          body: payload,
        }
      );

      setIsSuccess(true);
      setIsPending(false);
      setData(result);
    } catch (e) {
      newRelicErrorReport(
        e,
        "/src/Pages/Company/TotalReward/hooks/useMutateTotalRewardConfig.js - 16"
      );
      dynamicNotification(
        e.message || __("Nie udało się pobrać konfiguracji"),
        "error"
      );
      setIsError(true);
      setIsPending(false);
      setData(null);
    }

    return data;
  };

  return { mutate, data, isPending, isSuccess, isError };
}
