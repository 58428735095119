import React from "react";
import PropTypes from "prop-types";
import { Alert, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { history as historyPropTypes } from "react-router-prop-types";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const reloadPage = () => {
  window.location.reload(true);
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => {
      this.setState({ hasError: false, errorMessage: null });
    });
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    this.setState({ hasError: true, errorMessage: error.message });
    newRelicErrorReport(error, `ERROR BOUNDARY-stack:${info.componentStack}`);
  }

  render() {
    const { children } = this.props;
    const { hasError, errorMessage } = this.state;
    if (hasError) {
      const isChunkError = errorMessage?.includes("Loading chunk");

      return (
        <div className="p-4">
          <Alert color="danger">
            {__("Coś poszło nie tak.")} {errorMessage}
          </Alert>
          {isChunkError && (
            <Button color="secondary" onClick={reloadPage}>
              {__("Przeładuj stronę")}
            </Button>
          )}
        </div>
      );
    }
    return children;
  }
}

export default withRouter(ErrorBoundary);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: historyPropTypes.isRequired,
};
