import { useCallback, useEffect, useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function (companyId) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await restApiRequest(
        API_TOTALREWARD_SERVICE,
        "/omb/configs",
        "GET",
        {
          params: {
            tenant: companyId,
          },
        },
        mockData
      );
      setData(result || []);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (e) {
      newRelicErrorReport(
        e,
        "/src/Pages/Company/TotalReward/Settings/useQueryTotalRewardConfig.js - 14"
      );
      dynamicNotification(
        e.message || __("Nie udało się pobrać konfiguracji"),
        "error"
      );
      setData([]);
    }
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getConfigValue = (key) => {
    const config = data.find((item) => item.configKey === key);
    return config
      ? parseConfigValue(config.configValue, config.valueType)
      : undefined;
  };

  const parseConfigValue = (value, type) => {
    if (type === "boolean") {
      return value === "1";
    }
    return value;
  };

  return {
    data,
    isLoading,
    isSuccess,
    refetch: fetchData,
    getConfigValue,
    parseConfigValue,
  };
}

const mockData = [
  {
    id: "6e801f1d-d8c0-43e4-93cc-113155a5ec5d",
    tenant: "b967c558-2553-4bfc-9cd6-5a5ad64d92f4",
    configKey: "your_benefits_module",
    configValue: "0",
    valueType: "boolean",
  },
  {
    id: "1967c558-2553-4bfc-9cd6-5a5ad64d92f4",
    tenant: "b967c558-2553-4bfc-9cd6-5a5ad64d92f4",
    configKey: "your_benefits",
    configValue: "0",
    valueType: "boolean",
  },
  {
    id: "74567578-e206-45f9-b754-a470c4659349",
    tenant: "b967c558-2553-4bfc-9cd6-5a5ad64d92f4",
    configKey: "available_benefits_module",
    configValue: "1",
    valueType: "boolean",
  },
  {
    id: "db0e044e-14e4-4a59-9fc2-3c69656d9b2f",
    tenant: "b967c558-2553-4bfc-9cd6-5a5ad64d92f4",
    configKey: "available_benefits",
    configValue: "1",
    valueType: "boolean",
  },
  {
    id: "d445e465-fa26-416b-b7c3-67a9e8931a97",
    tenant: "b967c558-2553-4bfc-9cd6-5a5ad64d92f4",
    configKey: "import_date_from",
    configValue: "2024-03-31",
    valueType: "date",
  },
];
