// style skopiowane na prośbę ze starej wersji

export const dataTableControlledTitleStyle = {
  display: "flex",
  MsFlexAlign: "start",
  WebkitAlignItems: "flex-start",
  alignItems: "flex-start",
  wordWrap: "break-word",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
  paddingLeft: "2pt",
  paddingTop: "2pt",
  paddingRight: "2pt",
  paddingBottom: "2pt",
  border: "1pt none Black",
  backgroundColor: "Transparent",
  fontStyle: "normal",
  fontFamily: "Arial",
  fontSize: "18pt",
  fontWeight: "700",
  textDecoration: "none",
  unicodeBidi: "normal",
  color: "Black",
  verticalAlign: "top",
  textAlign: "left",
};

export const dataTableControlledFooterStyle = {
  display: "flex",
  MsFlexAlign: "start",
  WebkitAlignItems: "flex-start",
  alignItems: "flex-start",
  wordWrap: "break-word",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  fontStyle: "normal",
  fontFamily: "Arial",
  fontSize: "12pt",
  fontWeight: "700",
  textDecoration: "none",
  unicodeBidi: "normal",
  color: "Black",
  verticalAlign: "top",
  textAlign: "left",
};
