import React, { useEffect } from "react";
import { Label } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import DateTimePicker from "src/Components/FormElements/DateTimePicker";
import { subscribeFrequencyModalMock } from "src/Pages/Report/NewReport/ReportsList/Report/Subscription/mockData";
import __ from "src/utils/Translations";
import FreqDescription from "./freqDescritpion";

const Once = ({
  subscribeFrequency,
  setSubscribeFrequency,
  subscribeFrequencyInfo,
  setsubscribeFrequencyInfo,
}) => {
  const { sendDate, sendTime } = subscribeFrequencyInfo;

  const sendStartDateTime = {
    date: sendDate ?? new Date().toISOString().slice(0, 10),
    time: sendTime ?? "10:00",
  };
  const occurrence = `raz w dniu ${sendStartDateTime.date}`;

  useEffect(() => {
    const { date, time } = sendStartDateTime;
    const subInfo1 = {
      ...subscribeFrequencyInfo,
      occurrence,
      sendDate: date,
      sendTime: time,
    };
    setsubscribeFrequencyInfo(subInfo1);
  }, []); // eslint-disable-line

  const handleDateTimePickerChange = (data) => {
    const { date, time } = data;
    const occurr = `raz w dniu ${date}`;
    const subInfo = {
      ...subscribeFrequencyInfo,
      sendDate: date,
      sendTime: time,
      occurrence: occurr,
    };
    setsubscribeFrequencyInfo(subInfo);
  };
  subscribeFrequencyModalMock.forEach((d) => {
    // eslint-disable-next-line no-param-reassign
    d.label = __(d.label);
  });

  const selecteddaysSendAmount = {
    label: __(subscribeFrequency.label),
    value: subscribeFrequency.value,
    isSelected: subscribeFrequency.isSelected,
  };
  return (
    <>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3">
            <Label>{__("Powtarzaj")}</Label>
          </div>
          <div className="col-md-6 freq-select">
            <Select
              name="frequencySelect"
              className="basic-select"
              classNamePrefix="select"
              options={subscribeFrequencyModalMock}
              onChange={setSubscribeFrequency}
              value={selecteddaysSendAmount}
            />
          </div>
        </div>
      </div>
      <br />
      <DateTimePicker
        label={__("Data wysyłki")}
        onChange={handleDateTimePickerChange}
        value={sendStartDateTime}
        validateField={() => {}}
      />
      <FreqDescription info={occurrence} />
    </>
  );
};
Once.propTypes = {
  subscribeFrequency: PropTypes.object.isRequired,
  setSubscribeFrequency: PropTypes.func.isRequired,
  subscribeFrequencyInfo: PropTypes.object.isRequired,
  setsubscribeFrequencyInfo: PropTypes.func.isRequired,
};

export default Once;
