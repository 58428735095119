/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from "react";
import { LAYOUT_TWO_COLUMNS } from "src/Components/Layouts";
import {
  FORM_FIELD_CONFIG,
  RECEIVER_LABELS,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";

const parseForms = (form, type) => {
  if (!form) return [];
  const formFields = [
    {
      layout: LAYOUT_TWO_COLUMNS,
      formElements: parseFormFields(form, type),
    },
  ];

  return formFields;
};

export default function useFormFields(formType, type) {
  const [formElements, setFormElements] = useState([]);

  useEffect(() => {
    setFormElements(parseForms(formType, type));
  }, [formType, type]);

  return formElements;
}

const parseFormFields = (form, type) => {
  const receiverLabel = RECEIVER_LABELS[type];
  const currentFields = [];
  const newFields = [];
  const fields = {};
  form.fields.forEach((field) => {
    if (field.type === "firstname" || field.type === "lastname") {
      fields[field.type] = field.value;
    }
  });

  for (const fieldKey in fields) {
    if (Object.prototype.hasOwnProperty.call(fields, fieldKey)) {
      currentFields.push({
        id: `current.${fieldKey}`,
        label: `Obecne ${FORM_FIELD_CONFIG[fieldKey]} ${receiverLabel}`,
        type: "text",
        value: fields[fieldKey],
        props: {
          disabled: true,
        },
      });

      newFields.push({
        id: `${form.id}.${fieldKey}`,
        label: `Nowe ${FORM_FIELD_CONFIG[fieldKey]} ${receiverLabel}`,
        type: "text",
      });
    }
  }

  return [...currentFields, ...newFields];
};
