/* eslint-disable no-underscore-dangle, react/destructuring-assignment, react/prop-types */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DataTableControlled from "src/Components/DataTableControlled";
import {
  booleanOptions,
  SelectFilter,
  UuidFilter,
} from "src/Components/DataTable/filters";
import BusinessIdColumn from "src/Components/DataTable/businessIdColumn";
import __ from "src/utils/Translations";
import {
  API_TOTALREWARD_SERVICE,
  restApiRequest,
  restApiRequestFormData,
  getFileNameFromResponse,
  saveFile,
} from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import {
  totalRewardPerksRead,
  totalRewardPerksWrite,
} from "src/utils/RoleBasedSecurity/permissions";
import ActionColumn from "src/Components/DataTable/actionColumn";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import useTotalRewardCategories from "src/Pages/Company/TotalReward/hooks/useTotalRewardCategories";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import { getListingData } from "src/Components/DataTableControlled/utils";

export default function PerksListing({ companyId, pathPrefix }) {
  const { perkCategories } = useTotalRewardCategories(companyId, true);

  const getEditFormUrl = (id) => `${pathPrefix}/total-reward/perks/${id}`;

  const [deleted, setDeleted] = useState(0);
  const deleteRow = async (idToDelete) => {
    try {
      await restApiRequest(
        API_TOTALREWARD_SERVICE,
        `/omb/perks/${idToDelete}?tenant=${companyId}`,
        "DELETE",
        {
          returnNull: true,
        }
      );
      dynamicNotification(__("Pomyślnie usunięto"));
      setDeleted(idToDelete);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/TotalReward/Perks/Listing/index.js - 31"
      );
      dynamicNotification(e.message || __("Nie udało się usunąć"), "error");
    }
  };

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const fetchData = useCallback(
    async (filters, page, pageSize, sort) => {
      const options = {
        params: { tenant: companyId },
      };
      const { data: newData, count: newCount } = await getListingData(
        API_TOTALREWARD_SERVICE,
        `/omb/perks`,
        filters,
        page,
        pageSize,
        sort,
        options,
        mockData,
        true
      );
      if (newData) {
        setData(newData);
        setCount(newCount);
      }
    },
    [companyId]
  );

  const exportPerks = useCallback(async () => {
    try {
      const response = await restApiRequestFormData(
        API_TOTALREWARD_SERVICE,
        `/omb/perk-exports?tenant=${companyId}`,
        "GET",
        null,
        null,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const blob = await response.blob();
      const resultFileName =
        getFileNameFromResponse(response) ||
        `export_total_reward_benefits.xlsx`;
      saveFile(blob, false, resultFileName);
    } catch (e) {
      console.error(e);
      dynamicNotification([
        __("Nie udało się pobrać pliku. Wystąpił nieznany błąd"),
      ]);
      newRelicErrorReport(
        e,
        "Pages/Company/TotalReward/Perks/Listing/index.js - 87"
      );
    }
  }, [companyId]);

  return (
    <>
      <DataTableControlled
        id="perksLisitng"
        fetchData={fetchData}
        data={data}
        count={count}
        reloadDependencies={[deleted]}
        filterable
        buttons={[
          {
            permission: totalRewardPerksRead,
            text: "Eksportuj benefity",
            onClick: () => exportPerks(),
          },
          {
            permission: totalRewardPerksWrite,
            text: "Dodaj benefit",
            href: getEditFormUrl(-1),
          },
        ]}
        columns={[
          {
            Header: __("ID"),
            accessor: "id",
            minWidth: 155,
            Cell: BusinessIdColumn,
            Filter: UuidFilter,
          },
          {
            Header: __("Nazwa"),
            accessor: "title",
            minWidth: 200,
          },
          {
            Header: __(`Widoczność: "Twoje benefity"`),
            accessor: "status",
            minWidth: 100,
            Filter: SelectFilter(booleanOptions),
            Cell: mapValueFromOptions(booleanOptions, "status"),
          },
          {
            Header: __(`Widoczność: "Dostępne benefity"`),
            accessor: "visibility",
            minWidth: 100,
            Filter: SelectFilter(booleanOptions),
            Cell: mapValueFromOptions(booleanOptions, "visibility"),
          },
          {
            Header: __(`Data aktywności: "Dostępne benefity"`),
            accessor: "activeFrom",
            minWidth: 155,
            Cell: ({ row }) => {
              const activeFrom = row._original?.activeFrom?.slice(0, 10);
              const activeTo = row._original?.activeTo?.slice(0, 10);
              return (
                <>
                  {activeFrom ? `${__("Od")}:${activeFrom}` : ""} <br />{" "}
                  {activeTo ? `${__("Do")}:${activeTo}` : ""}
                </>
              );
            },
          },
          {
            Header: __("Kolejność wyświetlania"),
            accessor: "sort",
            minWidth: 110,
          },
          {
            Header: __("Kategoria"),
            accessor: "category.id",
            minWidth: 200,
            Filter: SelectFilter(perkCategories),
            Cell: mapValueFromOptions(perkCategories, "category.id"),
          },
          {
            Header: __("Akcja"),
            accessor: "action",
            filterable: false,
            sortable: false,
            width: 120,
            Cell: ({ row }) => {
              const { id } = row._original;
              return (
                <div className="d-block w-100 text-center">
                  <ActionColumn
                    data={row._original}
                    buttons={[
                      {
                        id: "perkEdit",
                        className: "m-1 p-1",
                        color: "link",
                        label: "Edytuj",
                        permission: totalRewardPerksWrite,
                        href: getEditFormUrl(id),
                      },
                      {
                        id: "delete",
                        className: "m-1 p-1",
                        color: "link",
                        label: "Usuń",
                        permission: totalRewardPerksWrite,
                        onClick: () => {
                          getUserConfirmationPopup(
                            __("Czy na pewno chcesz usunąć ten benefit?"),
                            (confirm) => confirm && deleteRow(row._original.id),
                            __("Usuwanie benefitu")
                          );
                        },
                      },
                    ]}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
}

const mockData = [];

PerksListing.propTypes = {
  companyId: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
};
