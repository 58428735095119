import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import PageTitle from "src/Layout/AppMain/PageTitle";

import EditForm from "./edit";

export default function Edit() {
  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle heading="Zmień hasło" breadcrumbs={[]} />
      <EditForm />
    </CSSTransitionGroup>
  );
}
