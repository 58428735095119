const getTranslatable = (
  fieldType,
  fieldName,
  { companyId = "default", employeeGroupId = "default" }
) => {
  if (["wysiwyg", "textarea", "infinite-input-list"].includes(fieldType)) {
    return {
      code: `configuration:${fieldName}:${companyId}:${employeeGroupId}`,
      isCms: true,
    };
  }
  return {
    scope: `configuration:${fieldName}`,
  };
};

export default getTranslatable;
