export const WINDOW_TYPE_POPUP = "1";
export const WINDOW_TYPE_ACCOUNT = "2";

export const getWindowPrefix = (type) => {
  switch (type) {
    case WINDOW_TYPE_ACCOUNT:
      return "Account";
    case WINDOW_TYPE_POPUP:
      return "Popup";
    default:
      return "";
  }
};

export const windowTypeOptions = [
  {
    value: WINDOW_TYPE_POPUP,
    label: "Wyświetlanie popupu po zalogowaniu",
    datat1: "popupWindowType",
  },
  {
    value: WINDOW_TYPE_ACCOUNT,
    label: "Zmiana grupy dochodowości w profilu",
    datat1: "accountWindowType",
  },
];

export const ACTIVATION_MODE_PERIODICALLY = "periodically";
export const ACTIVATION_MODE_RANGE = "range";
export const ACTIVATION_MODE_INFINITY = "infinity";

export const activationModeOptions = [
  {
    value: ACTIVATION_MODE_PERIODICALLY,
    label: "Cykliczny",
  },
  {
    value: ACTIVATION_MODE_RANGE,
    label: "Konkretne daty",
  },
  {
    value: ACTIVATION_MODE_INFINITY,
    label: "Czas nieokreślony",
  },
];

export const individualActivationModeOptions = [
  {
    value: ACTIVATION_MODE_RANGE,
    label: "Konkretne daty",
    data1: "rangeAccount",
  },
];

export const individualActivationModeOptionPopup = [
  {
    value: ACTIVATION_MODE_RANGE,
    label: "Konkretne daty",
    data1: "rangePopup",
  },
];

export const activationModeOptionsForListing = [
  {
    value: ACTIVATION_MODE_PERIODICALLY,
    label: "Cykliczny",
  },
  {
    value: ACTIVATION_MODE_RANGE,
    label: "Konkretne daty",
  },
  {
    value: ACTIVATION_MODE_INFINITY,
    label: "Czas nieokreślony",
  },
];

export const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

export const activationModeDateKeys = [
  "rangeDate",
  "rangeDateFrom",
  "rangeDateTo",
  "periodicallyDateFrom",
  "periodicallyMonths",
  "periodicallyDays",
  "rangeDateFromLocal",
  "rangeDateToLocal",
  "sendingEmails",
];
