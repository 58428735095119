import PropTypes from "prop-types";
import { Button } from "reactstrap";
import React from "react";
import __ from "src/utils/Translations";
import useHasPermission from "src/utils/hooks/security/useHasPermission";

const RbsButton = ({
  permission,
  children,
  className,
  disabled,
  title,
  hideIfNoPermission,
  ...props
}) => {
  let buttonDisabled = disabled;
  let buttonClassName = buttonDisabled
    ? `${className} enable-pointer-event`
    : className;
  let buttonTitle = title;
  const hasPermissions = useHasPermission(permission);
  if (permission && !hasPermissions) {
    buttonDisabled = true;
    buttonTitle = __("Nie masz uprawnień ({0})", [permission]);
    buttonClassName = buttonClassName
      ? `${buttonClassName} not-allowed`
      : "not-allowed";
  }

  if (hideIfNoPermission && !hasPermissions) return null;

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      disabled={buttonDisabled}
      className={buttonClassName}
      title={buttonTitle}
    >
      {children}
    </Button>
  );
};

export default RbsButton;

RbsButton.propTypes = {
  className: PropTypes.string,
  permission: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hideIfNoPermission: PropTypes.bool,
};

RbsButton.defaultProps = {
  permission: "",
  title: null,
  className: null,
  disabled: false,
  hideIfNoPermission: false,
};
