import PropTypes from "prop-types";
import React from "react";
import { FormGroup, InputGroup, Label, Col, Row, Input } from "reactstrap";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import hashInputId from "src/utils/jsHelpers/hashInputId";
import isEmptyValue from "src/utils/jsHelpers/isEmptyValue";

export default function NumberRange({
  value: fieldValue,
  label,
  onChange,
  id,
  min,
  max,
  errorMessage,
  validateField,
  validation,
  disabled,
  afterLabel,
}) {
  const value = fieldValue || {};
  const errorMessages = errorMessage ? JSON.parse(errorMessage) : null;
  const fromRequired =
    validation &&
    validation.find((rule) =>
      ["rangeRequiredBoth", "rangeRequiredFrom"].includes(rule)
    );
  const toRequired =
    validation &&
    validation.find((rule) =>
      ["rangeRequiredBoth", "rangeRequiredTo"].includes(rule)
    );
  const errorMessageFrom = errorMessages && errorMessages.from;
  const errorMessageTo = errorMessages && errorMessages.to;

  const handleChange = (
    { from: fromValue, to: toValue },
    updateRelation = false
  ) => {
    let from = fromValue === undefined ? value.from : fromValue;
    let to = toValue === undefined ? value.to : toValue;
    from = !isEmptyValue(from) ? +from : null;
    to = !isEmptyValue(to) ? +to : null;

    if (min !== null) {
      if (from !== null && min > from) {
        from = min;
      }
      if (to !== null && min > to) {
        to = min;
      }
    }
    if (max !== null) {
      if (from !== null && max < from) {
        from = max;
      }
      if (to !== null && max < to) {
        to = max;
      }
    }

    if (updateRelation) {
      if (to && from > to) {
        to = from;
      }
    }

    if (validateField) {
      if (!validation?.includes("rangeRequiredBoth")) {
        if (to) {
          validateField(
            id,
            {
              to,
            },
            ["rangeRequiredTo"]
          );
        }
        if (from) {
          validateField(
            id,
            {
              from,
            },
            ["rangeRequiredFrom"]
          );
        }
      } else {
        validateField(
          id,
          {
            from,
            to,
          },
          validation
        );
      }
    }
    onChange({
      from,
      to,
    });
  };

  const handleChangeStart = (e) => handleChange({ from: e.target.value });

  const handleChangeEnd = (e) => handleChange({ to: e.target.value });

  return (
    <>
      {label ? (
        <Label data-t1={`${id}Label`} for={`${id}`} className="mr-sm-2">
          {label}
        </Label>
      ) : null}
      {afterLabel ? <span className="ml-3">{afterLabel}</span> : null}
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for={`${id}_from`} className="mr-sm-2">
              Od {fromRequired ? <span className="text-danger">*</span> : null}
            </Label>
            <InputGroup>
              <Input
                data-t1={`${id}From`}
                id={`${hashInputId(id)}_from`}
                value={value.from || ""}
                onChange={handleChangeStart}
                className={`form-control${
                  errorMessageFrom ? " is-invalid" : ""
                }`}
                type="number"
                onBlur={(e) => handleChange({ from: e.target.value }, true)}
                disabled={disabled}
              />
              <ValidationMessage message={errorMessageFrom} />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for={`${id}_to`} className="mr-sm-2">
              Do {toRequired ? <span className="text-danger">*</span> : null}
            </Label>
            <InputGroup>
              <Input
                data-t1={`${id}From`}
                id={`${id}_to`}
                value={value.to || ""}
                onChange={handleChangeEnd}
                className={`form-control${errorMessageTo ? " is-invalid" : ""}`}
                type="number"
                onBlur={(e) => handleChange({ to: e.target.value }, true)}
                disabled={disabled}
              />
              <ValidationMessage message={errorMessageTo} />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

NumberRange.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  afterLabel: PropTypes.node,
  errorMessage: PropTypes.string,
  validateField: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
};

NumberRange.defaultProps = {
  id: "",
  label: "",
  max: null,
  min: null,
  value: {},
  disabled: false,
  afterLabel: null,
  errorMessage: "",
  validateField: "",
  validation: "",
};
