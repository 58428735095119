import {
  COMPANY_MANAGEMENT_SERVICE,
  EMPLOYEE_MANAGEMENT_SERVICE,
  MASTERDATA_SERVICE,
  API_GATEWAY_SERVICE,
  restApiRequest,
} from "src/utils/Api";
import { mockData as companyMockData } from "src/Pages/Company/CompanyList";
import { mockData as employeesMockData } from "src/Pages/Company/EmployeeManagement/CompanyEmployees/List/list";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

/** METHODS FOR ASYNC AUTOCOMPLETE */

export const getCompaniesOptionsFetchMethod =
  (currValue, itemsAmount) =>
  async (inputValue = "") => {
    const params = {};
    let mockData = [];
    if (inputValue) {
      params.fullName = inputValue;
      mockData = companyMockData.filter((company) =>
        company.fullName.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else if (currValue && !Array.isArray(currValue)) {
      params.id = currValue;
      mockData = companyMockData.filter((company) => company.id === currValue);
    } else if (Array.isArray(currValue) && currValue.length) {
      params.id = currValue;
      mockData = companyMockData.filter((company) =>
        currValue.includes(company.id)
      );
    }
    params.itemsPerPage = itemsAmount || 10000;
    return restApiRequest(
      COMPANY_MANAGEMENT_SERVICE,
      "/companies-names",
      "GET",
      { params },
      mockData
    )
      .then((result) =>
        result.map(({ id, fullName }) => ({
          value: id,
          label: fullName,
        }))
      )
      .catch((e) => {
        newRelicErrorReport(
          e,
          "Components/FormElements/Autocomplete/commonFetchMethods.js - 47"
        );
      });
  };

export const getPrepaidCardOptionsFetchMethod =
  (currentValue = null) =>
  () =>
    restApiRequest(
      API_GATEWAY_SERVICE,
      "/admin-prepaid-cards/prepaid-cards",
      "GET",
      {}
    )
      .then((result) => {
        const response = result?.prepaidCards?.map(({ id, name }) => ({
          value: id,
          label: name,
        }));

        if (currentValue)
          return response.filter(({ value }) => value === currentValue);

        return response;
      })
      .catch((e) => {
        console.error(e);
        newRelicErrorReport(
          e,
          "Components/FormElements/Autocomplete/commonFetchMethods.js - 70"
        );
        return [];
      });

export const getEmployeesOptionsFetchMethod =
  (currValue, additionalFilterParams) =>
  async (inputValue = "") => {
    const params = {};
    let mockData = [];
    if (inputValue) {
      params.or_searchByName = [inputValue];
      mockData = employeesMockData.filter((employee) =>
        employee.firstName.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else if (currValue && !Array.isArray(currValue)) {
      params.id = currValue;
      mockData = employeesMockData.filter(
        (employee) => employee.id === currValue
      );
    } else if (Array.isArray(currValue) && currValue.length) {
      params.id = currValue;
      mockData = employeesMockData.filter((employee) =>
        currValue.includes(employee.id)
      );
    }
    const fetchByName = restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      "/employees",
      "GET",
      { params: { ...params, ...additionalFilterParams } },
      mockData
    )
      .then((result) =>
        result.map(({ id, firstName, lastName, fk }) => ({
          value: id,
          label: `${firstName} ${lastName}${fk ? `(fk: ${fk})` : ""}`,
        }))
      )
      .catch((e) => {
        newRelicErrorReport(
          e,
          "Components/FormElements/Autocomplete/commonFetchMethods.js - 112"
        );
      });
    const promises = [fetchByName];

    if (inputValue) {
      const fetchByFk = restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        "/employees",
        "GET",
        { params: { fk: inputValue, ...additionalFilterParams } },
        mockData
      )
        .then((result) =>
          result.map(({ id, firstName, lastName, fk = "" }) => ({
            value: id,
            label: `${firstName} ${lastName}${fk ? `(fk: ${fk})` : ""}`,
          }))
        )
        .catch((e) => {
          newRelicErrorReport(
            e,
            "Components/FormElements/Autocomplete/commonFetchMethods.js - 134"
          );
        });
      promises.push(fetchByFk);
    }

    const responses = await Promise.all(promises);

    let result = [];
    responses.forEach((requestResult) => {
      result = result.concat(requestResult);
    });
    return result.filter(
      (value, index, self) =>
        self.findIndex((el) => el.value === value.value) === index
    );
  };

const fetch = () =>
  restApiRequest(
    MASTERDATA_SERVICE,
    "/supplierobject/nontouristic",
    "GET",
    {
      params: {
        rowsPerPage: 10000,
      },
    },
    []
  )
    .then((result) =>
      result.member
        .map(({ supplierObjectBusinessID, objectName }) =>
          supplierObjectBusinessID
            ? {
                value: supplierObjectBusinessID.toLowerCase(),
                label: objectName,
              }
            : null
        )
        .filter(Boolean)
    )
    .catch((e) => {
      console.error(e);
      newRelicErrorReport(
        e,
        "Components/FormElements/Autocomplete/commonFetchMethods.js - 180"
      );
      return [];
    });
let data;

export const getSuppliersOptionsFetchMethod =
  (currValue) =>
  async (inputValue = "") => {
    if (!data) {
      data = await fetch();
    }
    if (inputValue) {
      return data.filter(
        (el) =>
          el.value?.toLowerCase() === inputValue?.toLowerCase() ||
          el.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
    if (currValue) {
      return data.filter(
        (el) => el.value?.toLowerCase() === currValue?.toLowerCase()
      );
    }
    return [];
  };
