import { isEmpty } from "lodash";

const shouldDisplayNameChangeModal = ({
  dataToSubmit,
  originalData,
  isAhr,
  notificationAfterChangeEmployeeNamesForOrderDuplicateMs,
}) => {
  if (
    !isAhr ||
    !notificationAfterChangeEmployeeNamesForOrderDuplicateMs ||
    isEmpty(originalData)
  ) {
    return false;
  }
  return ["firstName", "lastName"].some(
    (key) => dataToSubmit[key] !== originalData[key]
  );
};

export default shouldDisplayNameChangeModal;
