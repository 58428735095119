import React, { useCallback, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import DataTable from "src/Components/DataTable";
import __ from "src/utils/Translations";
import ActionColumn from "src/Components/DataTable/actionColumn";
import { employeeAhrRolePermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import { getDateCell, priceColumn } from "src/Components/DataTable/commonCells";
import { DateFilter, dateFilterMethod } from "src/Components/DataTable/filters";
import PropTypes from "prop-types";
import PaymentList from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/PrepaidCardsTab/PrepaidCardsCancellation/Tables/paymentList";
import scrollTo from "src/utils/jsHelpers/scrollTo";
import { API_GATEWAY_SERVICE, restApiRequest } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { dynamicNotification } from "src/utils/Notifications";

export default function OrdersHistory({
  cardId,
  setClickedCardId,
  refreshData,
}) {
  const [isPaymentList, setIsPaymentList] = useState(false);
  const [ordersHistoryTableData, setOrdersHistoryTableData] = useState([]);
  const [paymentListTableData, setPaymentListTableData] = useState([]);

  const fetchData = async () => {
    try {
      const endpoint = `/admin-prepaid-cards/employee-cards/${cardId}/orders`;
      const response = await restApiRequest(
        API_GATEWAY_SERVICE,
        endpoint,
        "GET",
        {},
        mockData,
        false
      );
      setOrdersHistoryTableData(response);
    } catch (e) {
      console.error("error", e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/PrepaidCardsTab/ordersHistory.js - 37"
      );
      dynamicNotification(
        e.message || __("Nie udało się pobrać listy zamówień"),
        "error"
      );
    }
  };

  const cancelOrder = useCallback(async (orderId) => {
    try {
      const endpoint = `/admin-prepaid-cards/orders/${orderId}/cancel`;
      await restApiRequest(API_GATEWAY_SERVICE, endpoint, "POST", {});
      setIsPaymentList(false);
      setClickedCardId(null);
      refreshData();
      scrollToPrepaidCardsList();
      dynamicNotification(
        __("Zamówienie zostało pomyślnie anulowane"),
        "success"
      );
    } catch (e) {
      console.error("error", e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/PrepaidCardsTab/ordersHistory.js - 62"
      );
      dynamicNotification(
        e.message || __("Nie udało się anulować zamówienie"),
        "error"
      );
      setIsPaymentList(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [cardId]);

  const preparePaymentListData = (id) => {
    const data = ordersHistoryTableData
      .filter((data) => data.id === id)
      .map((data) => data.payments)
      .flat();
    const newData = data.map((item) =>
      item.type === "RECHARGE_COMMISSION" ? { ...item, type: "TAX" } : item
    );
    setPaymentListTableData(newData);
    showPaymentList();
  };

  const showPaymentList = () => {
    setIsPaymentList(true);
    scrollToPaymentListTable();
  };

  const scrollToPaymentListTable = () => {
    setTimeout(() => {
      const openedTable = document.querySelector('[data-t1="payment_list"]');
      if (openedTable) {
        scrollTo(openedTable, 50);
      }
    }, 100);
  };

  const scrollToPrepaidCardsList = () => {
    setTimeout(() => {
      const openedTable = document.getElementById("prepaid_cards_list");
      if (openedTable) {
        scrollTo(openedTable, 50);
      }
    }, 100);
  };

  const session = getSession();
  const resourceAccess = jwt_decode(session.getAccessToken())?.resource_access;

  return (
    <>
      <DataTable
        id="orders_history"
        key="orders_history"
        data={ordersHistoryTableData}
        sortable={false}
        filterable
        columns={[
          {
            Header: __("Numer zamówenia"),
            accessor: "orderNumber",
          },
          {
            Header: __("Data złożenia zamówienia"),
            accessor: "completedAt",
            Filter: DateFilter(),
            minWidth: 150,
            filterMethod: dateFilterMethod,
            Cell: getDateCell("completedAt", false, true),
          },
          {
            Header: __("Wartość zamówienia"),
            accessor: "totalAmount",
            Cell: priceColumn,
          },
          {
            Header: "Akcja",
            accessor: "action",
            maxWidth: 200,
            filterable: false,
            sortable: false,
            Cell: (cell) => (
              <div className="w-100">
                <ActionColumn
                  data={cell.original}
                  buttons={[
                    {
                      id: "open_payment_list",
                      onClick: () => preparePaymentListData(cell.original.id),
                      label: "Lista płatności",
                    },
                    {
                      id: "cancel",
                      disabled:
                        cell.original.status === "CANCELED" ||
                        !resourceAccess?.["prepaid-cards-m2m"]?.roles?.includes(
                          "employee-card-management"
                        ),
                      permission: employeeAhrRolePermissionRead,
                      onClick: () => cancelOrder(cell.original.id),
                      label: "Anuluj zamówienie",
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
      {isPaymentList && <PaymentList tableData={paymentListTableData} />}
    </>
  );
}

const mockData = [
  {
    id: "32b52d8c-99b8-410c-9192-5005b55e7c30",
    orderNumber: "KPP000000253",
    completedAt: "2023-05-10T11:12:13+01:00",
    totalAmount: 12.45,
    totalEmployeeAmount: 10.5,
    orderType: "ORDER",
    status: "CANCELED",
    // status: "NEW|COMPLETED|FAILED|CANCELED",
    payments: [
      {
        type: "RECHARGE_COMMISSION",
        payer: "COMPANY",
        amount: 0.9,
        financingSourceId: null,
        financingSourceName: null,
      },
      {
        type: "CHARGE",
        payer: "EMPLOYEE",
        amount: 10.5,
        financingSourceId: "6dc49744-68d8-4940-804a-a2db5b52429c",
        financingSourceName: "test bank 1",
      },
      {
        type: "ISSUANCE_FEE",
        payer: "COMPANY",
        amount: 1.05,
        financingSourceId: null,
        financingSourceName: null,
      },
      {
        type: "RECHARGE_COMMISSION",
        payer: "COMPANY",
        amount: 0.9,
        financingSourceId: null,
        financingSourceName: null,
      },
      {
        type: "RECHARGE_COMMISSION",
        payer: "EMPLOYEE",
        amount: 0.9,
        financingSourceId: null,
        financingSourceName: null,
      },
      {
        type: "CHARGE",
        payer: "EMPLOYEE",
        amount: 10.5,
        financingSourceId: "6dc49744-68d8-4940-804a-a2db5b52429c",
        financingSourceName: "test bank 1",
      },
    ],
  },
  {
    id: "ba1f5de4-9e45-43e5-b79d-5b4fc7665470",
    orderNumber: "KPP000000254",
    completedAt: "2023-05-11T11:12:13+01:00",
    totalAmount: 55.55,
    totalEmployeeAmount: 55.55,
    orderType: "CHARGE",
    status: "NEW|COMPLETED|FAILED|CANCELED",
    payments: [
      {
        type: "CHARGE",
        payer: "EMPLOYEE",
        amount: 55.55,
        financingSourceId: "6dc49744-68d8-4940-804a-a2db5b52429c",
        financingSourceName: "test bank 1",
      },
      {
        type: "ISSUANCE_FEE",
        payer: "COMPANY",
        amount: 1.05,
        financingSourceId: null,
        financingSourceName: null,
      },
      {
        type: "RECHARGE_COMMISSION",
        payer: "EMPLOYEE",
        amount: 20,
        financingSourceId: "6dc49744-68d8-4940-804a-a2db5b52429c",
        financingSourceName: "test bank 1",
      },
    ],
  },
];

OrdersHistory.propTypes = {
  cardId: PropTypes.string.isRequired,
  setClickedCardId: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};
