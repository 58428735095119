import {
  STATUS_ACTIVE,
  STATUS_DENIED,
  STATUS_PENDING,
  STATUS_PENDING_ACCEPTED,
  STATUS_BENEFIT_PRICING_CHANGED,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";

export const COMPLETED_STATUS_WAITING_FOR_ACTIVE_FORMS =
  "waitingForActiveForms";
export const COMPLETED_STATUS_WAITING_FOR_PDF_BARCODES =
  "waitingForPdfBarcodes";
export const COMPLETED_STATUS_READY = "ready";
export const COMPLETED_STATUS_WAITING_FOR_DEPENDANT_SUBSCRIPTIONS =
  "waitingForDependantSubscriptions";

export const statusOptions = [
  {
    value: COMPLETED_STATUS_WAITING_FOR_ACTIVE_FORMS,
    label: "Oczekuje na uzupełnienie formularza",
  },
  {
    value: COMPLETED_STATUS_WAITING_FOR_PDF_BARCODES,
    label: "Oczekuje na spełnienie wymagań formalnych",
  },
  { value: COMPLETED_STATUS_READY, label: "Gotowe do zaakceptowania" },
  {
    value: COMPLETED_STATUS_WAITING_FOR_DEPENDANT_SUBSCRIPTIONS,
    label: "Oczekuje na świadczenia zależne",
  },
  {
    value: STATUS_PENDING_ACCEPTED,
    label: "Oczekuje na uzupełnienie formularza",
  },
  {
    value: STATUS_BENEFIT_PRICING_CHANGED,
    label: "Zmieniono sposób finansowania",
  },
];
export const statusListingOptions = [
  ...statusOptions,
  { value: STATUS_ACTIVE, label: "Zaakceptowany" },
  { value: STATUS_DENIED, label: "Odrzucony" },
];
export const PENDING_BENEFIT_FILTER_GROUPS = {
  FORM_NUMBERS: "formNumbers",
  DEFAULT: "default",
};
export const PENDING_BENEFIT_FILTER_FIELDS_BY_GROUP = {
  [PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS]: {
    FORM_NUMBERS: PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS,
  },
  [PENDING_BENEFIT_FILTER_GROUPS.DEFAULT]: {
    EMPLOYEE_ID: "employeeId",
    CREATED_AT: "createdAt",
    BENEFIT_NAME: "benefitName",
  },
};

export const prepareBackendFilters = (
  filters,
  companyId,
  filtersToUse = PENDING_BENEFIT_FILTER_GROUPS.DEFAULT
) => {
  const params = {
    receiverStatus: [
      STATUS_PENDING,
      STATUS_PENDING_ACCEPTED,
      STATUS_BENEFIT_PRICING_CHANGED,
    ],
    "subscriptionItem.benefit.companyId": companyId,
    itemsPerPage: 10000,
    "order[subscriptionItem.id]": "asc",
    "order[type]": "asc",
  };
  if (filtersToUse === PENDING_BENEFIT_FILTER_GROUPS.DEFAULT) {
    const { employeeId, createdAtFrom, createdAtTo, benefitName } = Object(
      filters[PENDING_BENEFIT_FILTER_GROUPS.DEFAULT]
    );
    if (employeeId) {
      params["subscriptionItem.subscription.ownerId"] = employeeId;
    }
    if (createdAtFrom) {
      params["createdAt[after]"] = new Date(createdAtFrom).toISOString();
    }
    if (createdAtTo) {
      params["createdAt[before]"] = new Date(createdAtTo).toISOString();
    }
    if (benefitName) {
      params.benefitName = benefitName;
    }
  }
  if (filtersToUse === PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS) {
    const { formNumbers } = Object(
      filters[PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS]
    );
    if (formNumbers?.length) params["pdfBarcodes.barcode"] = formNumbers;
  }
  return params;
};

export const filterAdditionalStatus = (data, status) =>
  data.length && status
    ? data.filter(
        ({ status: benefitStatus, pendingStatuses }) =>
          benefitStatus === status || pendingStatuses?.includes(status)
      )
    : data;

export const LOADING_FALLBACK_VALUE = "***";
export const parseDataToTable = (
  subscriptions,
  employees,
  benefits,
  organizationUnits,
  loadingData
) =>
  subscriptions.map((item) => {
    const loadingFallback = loadingData ? LOADING_FALLBACK_VALUE : null;
    const employee = employees.find(
      ({ id: employeeId }) => employeeId === item.subscriptionItemOwnerId
    );
    const organizationUnit = organizationUnits.find(
      ({ id: organizationUnitIri }) =>
        organizationUnitIri &&
        organizationUnitIri === employee?.organizationUnit
    );
    const benefit = benefits.find(({ id: benefitIri }) =>
      benefitIri.includes(item.benefitId)
    );
    const pendingStatus =
      item.pendingStatuses?.length === 1
        ? item.pendingStatuses[0]
        : item.pendingStatuses;

    const prices =
      // item.representative check is for group benefits, for others, when we should not take prices from newBenefitEmployeeGroupPriceData
      item.status === STATUS_BENEFIT_PRICING_CHANGED && item.representative
        ? {
            employeePrice: item.newBenefitEmployeeGroupPriceData?.employeePrice,
            employerPrice: item.newBenefitEmployeeGroupPriceData?.employerPrice,
            pointsBankPrice:
              item.newBenefitEmployeeGroupPriceData?.pointsBankPrice,
            summaryAmount:
              +item.newBenefitEmployeeGroupPriceData?.employeePrice +
              +item.newBenefitEmployeeGroupPriceData?.employerPrice +
              +item.newBenefitEmployeeGroupPriceData?.pointsBankPrice,
          }
        : {
            summaryAmount:
              +item.employeePrice + +item.employerPrice + +item.pointsBankPrice,
          };

    return {
      ...item,
      ...prices,
      fk: employee?.fk || (item.ownerId && !employee ? loadingFallback : null),
      firstName:
        employee?.firstName ||
        (item.ownerId && !employee ? loadingFallback : null),
      lastName:
        employee?.lastName ||
        (item.ownerId && !employee ? loadingFallback : null),
      organizationUnit:
        organizationUnit?.name ||
        (item.ownerId && !organizationUnit ? loadingFallback : null),
      benefit:
        benefit?.name || (item.benefit && !benefit ? loadingFallback : null),
      pendingStatuses: pendingStatus,
    };
  });

export const resetFilterGroup = (state, filterGroup) => ({
  ...state,
  [filterGroup]: {},
});

export const filterSelectedPeopleWithOriginalData = (selectedPeople, data) =>
  selectedPeople.filter((receiverId) =>
    data.some(({ receivers }) => receivers.some(({ id }) => id === receiverId))
  );

export const filterSelectedFormsWithOriginalData = (selectedForms, data) =>
  selectedForms.filter((formId) =>
    data.some(({ pdfBarcodes }) => pdfBarcodes.some(({ id }) => id === formId))
  );

export const filterSelectedBenefitsWithOriginalData = (
  selectedBenefits,
  data
) =>
  selectedBenefits.filter((benefitId) =>
    data.some(({ id }) => id === benefitId)
  );

export const checkIfMassRejectionIsPossible = ({
  data,
  selectedBenefits,
  selectedPeople,
  selectedForms,
}) =>
  data.length &&
  selectedBenefits.length &&
  !data.some(({ id, pdfBarcodes }) => {
    if (selectedBenefits.includes(id)) return false;
    if (selectedPeople.includes(id)) return true;
    if (
      pdfBarcodes.some(({ id: pdfBarcodeId }) =>
        selectedForms.includes(pdfBarcodeId)
      )
    ) {
      return true;
    }

    return false;
  });

export const onManageListExpansionArrayHandler = ({ id, isExpanded, cb }) => {
  cb((prev) => {
    if (prev.includes(id))
      return isExpanded ? prev : prev.filter((itemId) => itemId !== id);
    return isExpanded ? [...prev, id] : prev;
  });
};

export const onAddListsToExpandedMassivelyHandler = ({ ids, cb }) => {
  cb((prev) => [
    ...prev,
    ...ids.filter(
      (itemId) => !prev.some((prevItemId) => prevItemId === itemId)
    ),
  ]);
};
