import { restApiRequest, TRANSLATOR_SERVICE } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const cache = {};
let cacheAll = null;
export default async function fetchScopeOptions(type, mockData) {
  if (!cache[type]) {
    if (!cacheAll) {
      try {
        const res = await restApiRequest(
          TRANSLATOR_SERVICE,
          "/scopes",
          "GET",
          {
            itemsPerPage: 10000,
          },
          mockData
        );
        cacheAll = res.map(({ title, code, type: optionType }) => ({
          value: code,
          label: title,
          type: optionType,
        }));
      } catch (e) {
        console.error(e);
        newRelicErrorReport(
          e,
          "/Pages/Translation/utils/fetchScopeOptions.js - 26"
        );
        cacheAll = [];
      }
    }
    cache[type] = cacheAll.filter((item) => item.type === type);
  }
  return cache[type] || [];
}

export function getAllOptions() {
  return cacheAll || [];
}

export const ScopeTypes = [
  { label: "Interfejs", value: 1 },
  { label: "Wartości proste", value: 2 },
  { label: "Opisy", value: 3 },
];
