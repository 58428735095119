/* eslint-disable no-underscore-dangle, react/destructuring-assignment, react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { CardHeader } from "reactstrap";
import __ from "src/utils/Translations";
import PerkUsersDataTable from "./table";

export default function PerkUsersListing({ companyId }) {
  return (
    <>
      <CardHeader className="card-header-lg text-transform-none">
        <div className="card-header-title font-size-lg font-weight-normal">
          {__("Przydzielone Benefity")}
        </div>
      </CardHeader>
      <PerkUsersDataTable companyId={companyId} />
    </>
  );
}

PerkUsersListing.propTypes = {
  companyId: PropTypes.string.isRequired,
};
