import { hasAccessTo } from "src/utils/RoleBasedSecurity/filters";
import { mockData as mockCompanyData } from "src/Pages/Company/CompanyList";
import __ from "src/utils/Translations";
import { COMPANY_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import isUuid from "src/utils/jsHelpers/isUuid";
import { companyCompanyPermissionRead } from "src/utils/RoleBasedSecurity/permissions";

const findCompanies = (value, ctx) => {
  if (!hasAccessTo(ctx.userInfo, companyCompanyPermissionRead)) {
    return [];
  }

  const filter = isUuid(value) ? { id: value } : { fullName: value };
  return restApiRequest(
    COMPANY_MANAGEMENT_SERVICE,
    "/companies",
    "GET",
    {
      params: {
        ...filter,
        itemsPerPage: 10,
      },
    },
    mockCompanyData.filter((item) =>
      item.fullName.toLowerCase().includes(value.toLowerCase())
    )
  ).then((res) =>
    res.map(({ fullName, id }) => ({
      label: fullName,
      to: `/company/edit/${id}`,
    }))
  );
};

export default findCompanies;
