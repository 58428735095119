/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export const defaultFilterMethod = ({ id, value, pivotId }, row) => {
  if (!value) {
    return true;
  }
  const rowId = pivotId || id;
  const rowValue = row[rowId];
  if (Array.isArray(rowValue)) {
    return rowValue
      .map((el) => String(el).toLowerCase())
      .includes(String(value).toLowerCase());
  }
  return (
    String(rowValue) &&
    String(rowValue).toLowerCase().includes(String(value).toLowerCase())
  );
};

const DefaultFilter = ({ filter, onChange, column }) => {
  const [inputState, setInputState] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!filter) setInputState("");
  }, [filter]);

  return (
    <input
      data-t1="gridFilter"
      data-t2={column.id}
      type="text"
      style={{
        width: "100%",
      }}
      placeholder={column.Placeholder}
      onPaste={() => {
        setIsCopied(true);
      }}
      onChange={(event) => {
        const trimedValue = isCopied
          ? event.target.value.trim()
          : event.target.value;
        setInputState(trimedValue);
        onChange(trimedValue);
        setIsCopied(false);
      }}
      value={inputState}
    />
  );
};

DefaultFilter.propTypes = {
  filter: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
    Placeholder: PropTypes.string,
  }).isRequired,
};

DefaultFilter.defaultProps = {
  filter: null,
};

export default DefaultFilter;
