import { getRowSelectColumn } from "src/Components/DataTableControlled/utils";

const getParsedColumnsForMassAction = ({
  data,
  parsedColumns,
  currentPageInex,
  currentPageSize,
  rowId,
  massActionSelect,
  updateMassActionSelection,
  selectionOptions,
  onChangeMassActionSelectCb,
}) => {
  const parsedColumnsCopy = [...parsedColumns];
  const pageIds = data
    .slice(
      currentPageInex * currentPageSize,
      currentPageInex * currentPageSize + currentPageSize
    )
    .map((item) => item[rowId]);

  parsedColumnsCopy.unshift(
    getRowSelectColumn(
      rowId,
      pageIds,
      massActionSelect.included,
      massActionSelect.excluded,
      updateMassActionSelection,
      selectionOptions,
      onChangeMassActionSelectCb
    )
  );

  return parsedColumnsCopy;
};

export default getParsedColumnsForMassAction;
