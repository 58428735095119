/* eslint-disable no-underscore-dangle, react/prop-types */
import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import DataTableControlled from "src/Components/DataTableControlled";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import __ from "src/utils/Translations";
import {
  SelectFilterWithTranslation,
  mapValueFromOptionsTranslation,
  translatedFrequencyText,
} from "src/Pages/Report/helpers/paramtersHelper";

const SubscriptionReportList = ({ subscriptionList, isAhr, companyId }) => {
  const history = useHistory();

  const editSubUrl = (id) =>
    getAhrUrl(
      `/report/subscriptions/edit/${id}${!isAhr ? `/${companyId}` : ""}`,
      isAhr
    );

  const handleClick = (id) => {
    history.push(editSubUrl(id));
    history.go(0);
  };

  const columns = () => [
    {
      Header: "Nazwa raportu",
      accessor: "reportName",
      filterable: false,
      Cell: (rowData) => __(rowData.row.reportName),
    },
    {
      Header: "Utworzono",
      accessor: "createdStr",
      filterable: false,
    },
    {
      Header: __("Sposób wysyłki"),
      accessor: "subscriptionMethod",
      Filter: SelectFilterWithTranslation([]),
      Cell: mapValueFromOptionsTranslation([], "subscriptionMethod"),
      filterable: false,
    },
    {
      Header: "Format i Kodowanie",
      accessor: "format",
      Filter: SelectFilterWithTranslation([]),
      Cell: mapValueFromOptionsTranslation([], "format"),
      filterable: false,
    },
    {
      Header: "Częstotliwość",
      accessor: "frequency",
      Cell: (rowData) => translatedFrequencyText(rowData.row.frequency),
      filterable: false,
    },
    {
      Header: "Akcja",
      maxWidth: 150,
      filterable: false,
      sortable: false,
      Cell: ({ row }) => (
        <div className="d-block w-100 text-center row">
          <Button
            role="button"
            color="link"
            onClick={() => handleClick(row._original.id)}
          >
            {__("Przejdź")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataTableControlled
        id="SubscriptionReportList"
        columns={columns()}
        data={subscriptionList}
        filterable
        fetchData={() => {}}
        count={subscriptionList.length || 0}
        title={`${__("Aktywne wysyłki dla raportu")} ${
          subscriptionList[0]?.reportName
            ? `- ${__(subscriptionList[0]?.reportName)}`
            : ""
        }`}
      />
    </>
  );
};

SubscriptionReportList.propTypes = {
  subscriptionList: PropTypes.arrayOf(
    PropTypes.shape({
      reportName: PropTypes.string,
      createdStr: PropTypes.string,
      subscriptionMethod: PropTypes.string,
      format: PropTypes.string,
      frequency: PropTypes.string,
    })
  ).isRequired,
  isAhr: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default SubscriptionReportList;
