import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Form from "src/Components/Form";
import { getEmployeesOptionsFetchMethod } from "src/Components/FormElements/Autocomplete/commonFetchMethods";
import { LAYOUT_THREE_COLUMNS } from "src/Components/Layouts";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import {
  API_GATEWAY_SERVICE,
  getFileNameFromResponse,
  restApiRequestFormData,
  saveFile,
} from "src/utils/Api";
import { prepareFilters } from "./utils";

export default function Filters({
  companyId,
  filters,
  onFiltersChange,
  onReset,
  onSubmit,
}) {
  const onChange = useCallback(
    (key, value) => {
      onFiltersChange({ [key]: value });
    },
    [onFiltersChange]
  );

  const onRangeChange = useCallback(
    (key, value) => {
      const { from, to } = value;
      onFiltersChange({ [`${key}From`]: from, [`${key}To`]: to });
    },
    [onFiltersChange]
  );

  const onExportXLS = useCallback(async () => {
    try {
      const body = JSON.stringify({
        filterRequest: prepareFilters(filters),
        translations: {
          fkNumber: __("Numer FK"),
          firstName: __("Imię"),
          lastName: __("Nazwisko"),
          benefitName: __("Benefit"),
          organizationUnit: __("Jednostka Organizacyjna"),
          totalCost: __("Łączny koszt"),
          paidByEmployee: __("Opłacone przez pracownika"),
          createdAt: __("Data wyboru"),
          email: __("E-mail służbowy"),
          employeeGroup: __("Grupa pracownika"),
        },
      });

      const response = await restApiRequestFormData(
        API_GATEWAY_SERVICE,
        `/waiting-room/company/${companyId}/waiting-rooms/download-xls`,
        "POST",
        body,
        null,
        {
          headers: {
            Accept: "application/vnd.ms-excel",
            "Content-Type": "application/json",
          },
        }
      ).catch((err) => err);
      if (!response.ok) {
        throw new Error("Nie udało się pobrać pliku.");
      }
      const blob = await response.blob();
      const fileName = getFileNameFromResponse(response);
      saveFile(blob, false, fileName);
    } catch (err) {
      dynamicNotification(__(err.message), "error");
    }
  }, [companyId, filters]);

  return (
    <Form
      id="pendingBenefitsFilters"
      data={filters}
      config={{
        title: `${__("Filtry")}`,
        onSubmit,
        buttons: [
          {
            size: "lg",
            color: "secondary",
            className: "mr-2",
            text: `${__("Eksportuj do XLS")}`,
            onClick: onExportXLS,
            id: "pendingBenefitsExportXLS",
          },
          {
            size: "lg",
            color: "danger",
            className: "mr-2",
            text: `${__("Resetuj")}`,
            onClick: onReset,
            id: "pendingBenefitsReset",
          },
          {
            size: "lg",
            color: "success",
            className: "mr-2",
            text: `${__("Szukaj")}`,
            type: "submit",
            id: "pendingBenefitsSearch",
          },
        ],
        defaultOnChange: onChange,
        formGroups: [
          {
            formElements: [
              {
                id: "barcodeIds",
                label: `${__("Numer formularza")}:`,
                type: "tagInput",
                tooltip: {
                  content: __(
                    "Wyszukiwanie możliwe jest po całości kodu, jeśli chcesz wyszukać kilka kodów oddziel je od siebie spacją."
                  ),
                },
                props: {
                  addOnPaste: true,
                  addOnBlur: true,
                  onlyUnique: true,
                  addKeys: ["Enter", " ", ","],
                  inputProps: {
                    placeholder: null,
                  },
                },
              },
              {
                id: "createdAt",
                label: `${__("Data wyboru")}:`,
                type: "dateRange",
                props: {
                  minDateFrom: new Date(1970, 1, 1, 1),
                  minDateTo: new Date(1970, 1, 1, 1),
                },
                onChange: onRangeChange,
              },
              {
                layout: LAYOUT_THREE_COLUMNS,
                formElements: [
                  {
                    id: "employeeId",
                    label: "Pracownik/Numer FK:",
                    type: "asyncAutocomplete",
                    fetchOptions: getEmployeesOptionsFetchMethod(
                      filters?.employeeId,
                      { companyId }
                    ),
                  },
                  {
                    id: "benefitName",
                    label: "Nazwa benefitu:",
                    type: "text",
                    translatable: {
                      scope: "companyManagement:pendingBenefits:benefitName",
                    },
                  },
                  // na razie zostaje wylączane
                  // {
                  //    id: "status",
                  //    label: "Status:",
                  //    type: "select",
                  //    options: statusOptions,
                  //  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
}

Filters.propTypes = {
  companyId: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    employeeId: PropTypes.string,
  }).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
