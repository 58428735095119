/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import __ from "src/utils/Translations";

const MultiSelectParameter = ({
  isMulti,
  name,
  className,
  classNamePrefix,
  options,
  allowSelectAll,
  hideSelectedOptions,
  onChange,
  value,
  allOption = {
    label: __("Wybierz wszystkie"),
    value: "*",
  },
  disabledParam,
  showAllSelected,
}) => {
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      maxHeight: showAllSelected ? "unset" : "30px",
    }),
  };
  const ValueContainer = (props) => {
    const { length } = props.getValue();
    if (showAllSelected && length > 0) {
      return (
        <components.ValueContainer {...props}>
          <>
            {props.children[0].map((child) => child)}
            {React.cloneElement(props.children[1])}
          </>
        </components.ValueContainer>
      );
    }
    if (length > 2) {
      if (
        props.children[0][0].props.children.length +
          props.children[0][1].props.children.length >
        25
      ) {
        return (
          <components.ValueContainer {...props}>
            <>
              {props.children[0][0]}
              {!props.selectProps.menuIsOpen && `+ ${length - 1} `}
              {React.cloneElement(props.children[1])}
            </>
          </components.ValueContainer>
        );
      }

      return (
        <components.ValueContainer {...props}>
          <>
            {props.children[0][0]}
            {props.children[0][1]}
            {!props.selectProps.menuIsOpen && `+ ${length - 2} `}
            {React.cloneElement(props.children[1])}
          </>
        </components.ValueContainer>
      );
    }
    if (length === 2) {
      if (
        props.children[0][0].props.children.length +
          props.children[0][1].props.children.length >
        30
      ) {
        return (
          <components.ValueContainer {...props}>
            <>
              {props.children[0][0]}
              {!props.selectProps.menuIsOpen && `+ ${length - 1} `}
              {React.cloneElement(props.children[1])}
            </>
          </components.ValueContainer>
        );
      }
    }
    return (
      <components.ValueContainer {...props}>
        <>{props.children}</>
      </components.ValueContainer>
    );
  };
  if (allowSelectAll) {
    return (
      <Select
        isMulti={isMulti}
        name={name}
        className={className}
        classNamePrefix={classNamePrefix}
        allowSelectAll={allowSelectAll}
        hideSelectedOptions={hideSelectedOptions}
        value={value}
        styles={customStyles}
        components={{ ValueContainer }}
        options={[...options]}
        onChange={(selected) => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value
          ) {
            return onChange(options);
          }
          return onChange(selected);
        }}
        isDisabled={disabledParam}
      />
    );
  }

  return (
    <Select
      isMulti={isMulti}
      name={name}
      className={className}
      classNamePrefix={classNamePrefix}
      allowSelectAll={allowSelectAll}
      hideSelectedOptions={hideSelectedOptions}
      value={value}
      styles={customStyles}
      options={options}
      onChange={onChange}
      isDisabled={disabledParam}
    />
  );
};

MultiSelectParameter.propTypes = {
  isMulti: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  classNamePrefix: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  allowSelectAll: PropTypes.bool.isRequired,
  hideSelectedOptions: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  disabledParam: PropTypes.bool.isRequired,
  showAllSelected: PropTypes.bool,
};

MultiSelectParameter.defaultProps = {
  showAllSelected: false,
};

export default MultiSelectParameter;
