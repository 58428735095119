import __ from "src/utils/Translations";
import { DefaultFilter, FilterLoader } from "src/Components/DataTable/filters";

const getParsedColumns = (filteredColumns, isFiltersLoading) =>
  filteredColumns.map((columnData) => {
    const column = { ...columnData };
    if (typeof column.Header === "function") {
      column.Header = column.Header();
    } else {
      column.Header = __(column.Header);
    }
    if (isFiltersLoading) {
      column.Filter = FilterLoader;
    }
    if (!column.Filter) {
      column.Filter = DefaultFilter;
    }

    if (column.accessor === "action") {
      column.sortable = false;
    }
    return column;
  });

export default getParsedColumns;
