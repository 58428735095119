import { useState, useEffect } from "react";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import mockBenefitsData from "src/Pages/Company/Products/Subscriptions/utils/mockBenefitsData";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function useBenefits(
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false,
  noFetching = false,
  additionalParams = {}
) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (noFetching) {
      setData([]);
      return;
    }

    const params = {
      showEmployeeGroupName:
        filterField === "companyId" || filterField === "benefitGroup",
      itemsPerPage: 10000,
      ...additionalParams,
    };
    if (filterField && filterValue) {
      params[filterField] = filterValue;
    }

    restApiRequest(
      SUBSCRIPTION_MANAGEMENT_SERVICE,
      "/benefits",
      "GET",
      { params },
      mockBenefitsData
    )
      .then((resData) => {
        let result = resData;
        if (idAsIri) {
          result = result.map((el) => ({
            ...el,
            id: `${IRI_PREFIX}/${el.id}`,
          }));
        }
        if (asOption) {
          result = result.map(
            ({ name, id, benefitEmployeeGroups, benefitSubgroup }) => ({
              value: id,
              label: name,
              benefitEmployeeGroups,
              benefitSubgroup,
            })
          );
        }
        setData(result);
      })
      .catch((e) => {
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy abonamentów"),
          "error"
        );
        newRelicErrorReport(e, "utils/hooks/benefit/useBenefits.js - 58");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterField, filterValue, idAsIri, asOption, noFetching]);

  return data;
}

export const IRI_PREFIX = "/api/subscription-management/v1/rest/benefits";
export const IRI_PREFIX_GROUP =
  "/api/subscription-management/v1/rest/benefit-groups";
export const IRI_PREFIX_SUBGROUP =
  "/api/subscription-management/v1/rest/benefit-subgroups";
