const sortAlphabeticallyByField = (field) => (a, b) => {
  const firstItem = a ? a[field]?.toLowerCase() : null;
  const secondItem = b ? b[field]?.toLowerCase() : null;
  if (firstItem < secondItem) {
    return -1;
  }
  if (firstItem > secondItem) {
    return 1;
  }
  return 0;
};

export default sortAlphabeticallyByField;
