import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import PropTypes from "prop-types";
import { subscribeFrequencyModalMock } from "src/Pages/Report/NewReport/ReportsList/Report/Subscription/mockData";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import Once from "./once";
import Weekly from "./weekly";
import Monthly from "./monthly";
import Annually from "./annually";

const FrequencyModal = ({
  isOpen,
  toggle,
  subscribeFrequencyInfo,
  setsubscribeFrequencyInfo,
  saveFrequency,
  subscribeFrequency,
  setSubscribeFrequency,
}) => {
  const close = () => toggle(false);

  const frequencySelect = initialFreqencySelect(subscribeFrequency);

  const handleSaveClick = () => {
    if (frequencySelect.value === "once") {
      const now = new Date();
      const sDate = new Date(subscribeFrequencyInfo.sendDate).setHours(
        subscribeFrequencyInfo.sendTime.substr(0, 2),
        subscribeFrequencyInfo.sendTime.substr(3, 5)
      );
      if (sDate < now) {
        dynamicNotification(__("Nie można wybrać daty wstecz"), "error");
      } else {
        saveFrequency();
      }
    } else {
      saveFrequency();
    }
  };
  const renderPeriodContent = () => {
    switch (frequencySelect.value) {
      case "once":
        return (
          <Once
            subscribeFrequency={frequencySelect}
            setSubscribeFrequency={setSubscribeFrequency}
            subscribeFrequencyInfo={subscribeFrequencyInfo}
            setsubscribeFrequencyInfo={setsubscribeFrequencyInfo}
          />
        );
      case "weekly":
        return (
          <Weekly
            subscribeFrequency={frequencySelect}
            setSubscribeFrequency={setSubscribeFrequency}
            subscribeFrequencyInfo={subscribeFrequencyInfo}
            setsubscribeFrequencyInfo={setsubscribeFrequencyInfo}
          />
        );
      case "monthly":
        return (
          <Monthly
            subscribeFrequency={frequencySelect}
            setSubscribeFrequency={setSubscribeFrequency}
            subscribeFrequencyInfo={subscribeFrequencyInfo}
            setsubscribeFrequencyInfo={setsubscribeFrequencyInfo}
          />
        );
      case "annually":
        return (
          <Annually
            subscribeFrequency={frequencySelect}
            setSubscribeFrequency={setSubscribeFrequency}
            subscribeFrequencyInfo={subscribeFrequencyInfo}
            setsubscribeFrequencyInfo={setsubscribeFrequencyInfo}
          />
        );
      default:
        return (
          <Once
            subscribeFrequency={frequencySelect}
            setSubscribeFrequency={setSubscribeFrequency}
            subscribeFrequencyInfo={subscribeFrequencyInfo}
            setsubscribeFrequencyInfo={setsubscribeFrequencyInfo}
          />
        );
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={close} unmountOnClose size="lg">
        <ModalHeader toggle={close}>{__("Częstotliwość")}</ModalHeader>
        <ModalBody>{renderPeriodContent()}</ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveClick}>
            {__("Zapisz")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
FrequencyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  subscribeFrequency: PropTypes.object.isRequired,
  subscribeFrequencyInfo: PropTypes.object.isRequired,
  setsubscribeFrequencyInfo: PropTypes.func.isRequired,
  saveFrequency: PropTypes.func.isRequired,
  setSubscribeFrequency: PropTypes.func.isRequired,
};

export default FrequencyModal;

const initialFreqencySelect = (subscribeFrequency) => {
  const result = subscribeFrequencyModalMock.find(
    (x) => x.value === subscribeFrequency.value
  ) ?? { label: __("Nie wybrano..."), value: -1 };

  return result;
};
