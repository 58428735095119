/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useContext, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";
import { DateFilter, SelectFilter } from "src/Components/DataTable/filters";
import {
  getDateCell,
  mapValueFromOptions,
  priceColumn,
} from "src/Components/DataTable/commonCells";
import __ from "src/utils/Translations";
import {
  benefitIdColumn,
  POPUP_TYPE_CANCEL,
  POPUP_TYPE_CHANGE,
  STATUS_CANCELED,
  STATUS_RESIGNED,
  statusesOptionsDeactivated,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import ActionColumn from "src/Components/DataTable/actionColumn";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import {
  subscriptionEmployeeSubscriptionItemCancel,
  subscriptionEmployeeSubscriptionItemChangeDates,
} from "src/utils/RoleBasedSecurity/permissions";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import BenefitNameColumn from "src/Pages/Company/Pending/BenefitsPending/Listing/Table/benefitNameColumn";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";
import { SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";

const CANCELED_STATUSES = [STATUS_RESIGNED, STATUS_CANCELED];

const CanceledSubscriptions = ({
  companyId,
  employeeId,
  openPopup,
  activeSubscriptions,
  tableApiRef,
}) => {
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();

  const [canceledItems, setCanceledItems] = useState([]);
  const [count, setCount] = useState(null);

  const fetchData = useCallback(async (filters, page, pageSize, sort) => {
    let localSort = sort;
    const localFilters = [
      ...filters,
      {
        id: "subscriptionItem.subscription.ownerId",
        value: employeeId,
      },
    ].reduce((result, current) => {
      if (
        current.id !== "startsAt" &&
        current.id !== "endsAt" &&
        current.id !== "graceDate" &&
        current.id !== "benefit"
      ) {
        result.push(current);
        return result;
      }

      if (current.id === "benefit") {
        result.push({ id: "benefit.id", value: current.value });
      }

      const after = current.value?.from && {
        id: `${current.id}[after]`,
        value: moment(current.value.from).format("YYYY-MM-DD"),
      };
      const before = current.value?.to && {
        id: `${current.id}[before]`,
        value: moment(current.value.to).format("YYYY-MM-DD"),
      };
      [after, before].filter(Boolean).forEach((param) => {
        result.push(param);
      });

      return result;
    }, []);

    const hasStatusFilter =
      filters?.findIndex((filter) => filter.id === "status") !== -1;
    const updatedFilters = hasStatusFilter
      ? localFilters
      : [
          ...localFilters,
          // if there aren't any filters then limit statuses to STATUS_RESIGNED or both CANCELED
          {
            id: "receiverStatus",
            value: isAhr ? STATUS_RESIGNED : CANCELED_STATUSES,
          },
          { id: "order[subscriptionItem.id]", value: "asc" },
          { id: "order[type]", value: "asc" },
        ];

    if (sort?.key === "benefit") {
      localSort = { key: "benefit.id", value: sort.value };
    }

    const { data: newData, count: newCount } = await getListingData(
      SUBSCRIPTION_MANAGEMENT_SERVICE,
      "/subscription-item-receivers",
      updatedFilters,
      page,
      pageSize,
      localSort
    );

    const canceledItems = newData.map((item) => ({
      ...item,
      ...{
        employerCost: +item.employerPrice + +item.pointsBankPrice,
      },
    }));

    setCanceledItems(canceledItems);
    setCount(newCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAnyItemGraceDate = canceledItems?.some(
    (canceledItem) => canceledItem.graceDate
  );

  const canceledSubscriptionsWithActiveSubstitute = canceledItems?.filter(
    (canceledSub) =>
      activeSubscriptions?.find((activeSub) => activeSub.id === canceledSub.id)
  );

  return (
    <Card>
      <CardHeader>
        {__("Świadczenia abonamentowe, z których zrezygnowano")}
      </CardHeader>
      <CardBody>
        <DataTableControlled
          noCards
          fetchData={fetchData}
          apiRef={tableApiRef}
          count={count}
          data={canceledItems}
          id="canceledSubscriptions"
          key="canceledSubscriptions"
          columns={[
            {
              Header: "Nazwa",
              accessor: "benefitName",
              Cell: BenefitNameColumn,
            },
            {
              Header: "Status",
              accessor: "status",
              filterable: true,
              sortable: true,
              Filter: SelectFilter(statusesOptionsDeactivated),
              Cell: mapValueFromOptions(statusesOptionsDeactivated, "status"),
            },
            {
              Header: "Data startu",
              accessor: "startsAt",
              Filter: DateFilter(),
              Cell: getDateCell("startsAt", false, true),
            },
            {
              Header: "Data końca",
              accessor: "endsAt",
              Filter: DateFilter(),
              Cell: getDateCell("endsAt", false, true),
            },
            {
              Header: "Data karencji",
              accessor: "graceDate",
              Filter: DateFilter(),
              minWidth: 150,
              doNotDisplay: !hasAnyItemGraceDate,
              Cell: getDateCell("graceDate", false, true),
            },
            {
              Header: "ID benefitu w firmie",
              accessor: "benefit",
              company: companyId,
              Cell: benefitIdColumn,
            },
            {
              Header: "Koszt pracownika",
              accessor: "employeePrice",
              Cell: priceColumn,
            },
            {
              Header: () => (
                <>
                  <span className="mr-2">{__("Koszt pracodawcy")}</span>
                  <DefaultTooltip
                    id="employer-price-info"
                    content={__(
                      "W zależności od grupy abonamentowej przez koszt pracodawcy rozumiemy limit pracodawcy lub punkty z banków punktów"
                    )}
                  />
                </>
              ),
              accessor: "employerCost",
              Cell: priceColumn,
            },
            // {
            //   Header: "Blokada ponownego wyboru do",
            //   accessor: "blockTo",
            //   Filter: DateFilter(),
            //   filterMethod: dateFilterMethod,
            //   Cell: getDateCell("blockTo", false, true),
            // },
            {
              Header: "Akcja",
              accessor: "action",
              maxWidth: 125,
              filterable: false,
              sortable: false,
              doNotDisplay: isAhr,
              Cell: (cell) => {
                const rowId = cell.row._original.id;
                const isCanceled =
                  cell.row._original.status === STATUS_CANCELED;

                const receiverId = cell.row._original.id;
                const hasItemActiveSubtitute =
                  canceledSubscriptionsWithActiveSubstitute.find(
                    (activeSubtitute) => activeSubtitute.id === receiverId
                  );

                const isDateChangeBlocked =
                  !cell.row._original.resignAvailableFrom &&
                  !cell.row._original.resignAvailableTo &&
                  !cell.row._original.startAvailableFrom &&
                  !cell.row._original.startAvailableTo;

                return (
                  <div className="w-100">
                    <ActionColumn
                      data={cell.row._original}
                      buttons={[
                        !isCanceled
                          ? {
                              id: "cancel",
                              onClick: () =>
                                openPopup({
                                  type: POPUP_TYPE_CANCEL,
                                  id: rowId,
                                  itemsFromArg: canceledItems,
                                }),
                              permission:
                                subscriptionEmployeeSubscriptionItemCancel,
                              label: "Anuluj",
                              doNotDisplay: isAhr,
                            }
                          : null,
                        !isCanceled
                          ? {
                              id: "change",
                              permission:
                                subscriptionEmployeeSubscriptionItemChangeDates,
                              onClick: () =>
                                openPopup({
                                  type: POPUP_TYPE_CHANGE,
                                  id: rowId,
                                  itemsFromArg: canceledItems,
                                }),
                              label: "Zmień daty",
                              disabled:
                                hasItemActiveSubtitute || isDateChangeBlocked,
                              doNotDisplay: isAhr,
                            }
                          : null,
                        // {
                        //   id: "block",
                        //   permission:
                        //     subscriptionEmployeeSubscriptionItemBlockade,
                        //   onClick: () => openPopup(POPUP_TYPE_BLOCK, rowId),
                        //   label: "Blokada",
                        //   doNotDisplay: isAhr
                        //     ? ahrHasNoBlockadePermission
                        //     : false,
                        // },
                      ]}
                    />
                  </div>
                );
              },
            },
          ]}
          showPagination
          sortable
          filterable
        />
      </CardBody>
    </Card>
  );
};

CanceledSubscriptions.propTypes = {
  companyId: PropTypes.string.isRequired,
  employeeId: PropTypes.number.isRequired,
  openPopup: PropTypes.func.isRequired,
  activeSubscriptions: PropTypes.array.isRequired,
  tableApiRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
};

export default CanceledSubscriptions;
