import { useEffect, useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function (companyId) {
  const [periods, setPeriods] = useState([]);

  useEffect(() => {
    const getPeriods = async (companyId) => {
      try {
        const result = await restApiRequest(
          API_TOTALREWARD_SERVICE,
          "/omb/perk-users/periods",
          "GET",
          {
            params: {
              tenant: companyId,
              "order[period]": "desc",
            },
          }
        );
        setPeriods(result.map((c) => ({ value: c.period, label: c.period })));
      } catch (error) {
        newRelicErrorReport(
          error,
          "/src/Pages/Company/TotalReward/PerkUsers/useTotalRewardPeriods.js - 13"
        );
        dynamicNotification(
          error.message || __("Nie udało się pobrać słownika okresów"),
          "error"
        );
        setPeriods([]);
      }
    };
    getPeriods(companyId);
  }, [companyId]);

  return { periods };
}
