/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Label } from "reactstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import "react-multi-email/style.css";

export default function MultiInput({
  id,
  label,
  invalid,
  value,
  tooltip,
  errorMessage,
  onChange,
  afterLabel,
}) {
  return (
    <FormGroup>
      <Label data-t1={`${id}Label`} for={id}>
        {label}
        &nbsp;
        {tooltip}
      </Label>
      {afterLabel && <span className="ml-3">{afterLabel}</span>}
      <ReactMultiEmail
        emails={value || []}
        onChange={(emails) => onChange(emails)}
        validateEmail={(email) => isEmail(email)}
        getLabel={(email, index, removeEmail) => (
          <div data-tag key={index}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        )}
        style={invalid ? { borderColor: "#dc3545" } : {}}
      />
      <ValidationMessage message={errorMessage} />
    </FormGroup>
  );
}

MultiInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  afterLabel: PropTypes.node,
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool,
};

MultiInput.defaultProps = {
  id: "",
  label: "",
  tooltip: null,
  afterLabel: null,
  value: [],
  errorMessage: null,
  invalid: false,
};
