import { restApiRequest } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import ApiForbiddenError from "src/utils/Api/ApiForbiddenError";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import isUuid from "src/utils/jsHelpers/isUuid";

const getListingData = async (
  service,
  path,
  filters,
  page,
  pageSize,
  sort = null,
  options = {},
  mockData = [],
  validateUuid = false
) => {
  if (validateUuid) {
    const idFilter = filters.find(({ id }) => id === "id");
    if (idFilter && !isUuid(idFilter.value)) {
      return {};
    }
  }

  if (isMockView()) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: mockData,
          count: mockData.length,
        });
      }, 100);
    });
  }

  let params = {
    itemsPerPage: pageSize,
    page,
  };
  if (options.params) {
    params = { ...params, ...options.params };
  }
  let headers = {
    accept: "application/ld+json",
  };
  if (options.headers) {
    headers = { ...headers, ...options.headers };
  }
  const method = options.method ? options.method : "GET";

  if (filters) {
    const dateFilters = options.dateFilters || [];
    filters.forEach(({ id, value, isExist }) => {
      if (dateFilters.includes(id)) {
        if (typeof value === "object") {
          const { from, to } = value;
          if (from) {
            params[`${id}[after]`] = from.toISOString();
          }
          if (to) {
            params[`${id}[before]`] = to.toISOString();
          }
        }
      } else if (isExist) {
        params[`exists[${id}]`] = value;
      } else {
        params[id] = value;
        params[id] =
          typeof value === "string" && value.includes("&")
            ? encodeURIComponent(value)
            : value;
      }
    });
  }
  if (sort) {
    params[`order[${sort.key}]`] = sort.value;
  }
  try {
    const result = await restApiRequest(
      service,
      path,
      method,
      { params, headers },
      null,
      true
    );
    return {
      data: result["hydra:member"] || result.member,
      count: result.totalItems || result["hydra:totalItems"] || 0,
    };
  } catch (e) {
    newRelicErrorReport(e, "Components/DataTableControlled/index.js - 504");
    if (e instanceof ApiForbiddenError) {
      throw e;
    } else {
      dynamicNotification(e.message, "error");
    }
    return {
      data: [],
      count: 0,
    };
  }
};

export default getListingData;
