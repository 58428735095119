import React from "react";
import { match as matchPropTypes } from "react-router-prop-types";
import EditForm from "src/Pages/Notification/AppMessages/Edit/edit";
import { useCompanyName, useCompanyId } from "src/Pages/Company/CompanyContext";
import { getAhrBaseBreadcrumbs } from "src/Pages/AhrCore/routerHelper";
import __ from "src/utils/Translations";

export default function Edit({ match }) {
  const { appMessageId } = match.params;

  const listingPath = "/ahr/notification/app-messages";
  const companyName = useCompanyName();
  const companyId = useCompanyId();
  return (
    <EditForm
      appMessageId={appMessageId}
      companyScope
      company={companyId}
      listingPath={listingPath}
      breadcrumbs={[
        ...getAhrBaseBreadcrumbs(companyName),
        {
          title: `${__("Powiadomienia")}`,
          link: `/company/edit/${companyId}/notification`,
        },
        {
          title: __("Lista wiadomości"),
          link: listingPath,
        },
      ]}
    />
  );
}

Edit.propTypes = {
  match: matchPropTypes.isRequired,
};
