import { faFileSignature, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import __ from "src/utils/Translations";
import { TYPE_EDIT, TYPE_LISTING } from "src/utils/browsingHistory";

const getIconByType = (type) => {
  switch (type) {
    case TYPE_LISTING:
      return faFileAlt;
    case TYPE_EDIT:
    default:
      return faFileSignature;
  }
};

export default getIconByType;
