/* eslint-disable no-underscore-dangle */
import React from "react";
import __ from "src/utils/Translations";
import arrayUnique from "src/utils/jsHelpers/arrayUnique";
import {
  DESELECT_ALL,
  DESELECT_PAGE_ALL,
  placeholderSelectOption,
  SELECT_ALL,
  SELECT_PAGE_ALL,
} from "src/Components/DataTableControlled/consts";

function getRowSelectColumn(
  rowId,
  pageIds,
  included,
  excluded,
  updateMassActionSelection,
  selectionOptionsArg,
  onChangeMassActionSelectCb
) {
  const selectionOptions = selectionOptionsArg || [
    {
      value: SELECT_ALL,
      label: __("Zaznacz wszystkie"),
    },
    {
      value: SELECT_PAGE_ALL,
      label: __("Zaznacz wszystkie na tej stronie"),
    },
    {
      value: DESELECT_ALL,
      label: __("Odznacz wszystkie"),
    },
    {
      value: DESELECT_PAGE_ALL,
      label: __("Odznacz wszystkie na tej stronie"),
    },
  ];

  const selectOptions = [placeholderSelectOption, ...selectionOptions];

  return {
    accessor: "checkbox",
    maxWidth: 45,
    sortable: false,
    Header: () => (
      <select
        data-t1="massActionSelect"
        className="form-control"
        value=""
        onChange={(e) => {
          const selectAll = included === true;
          let newExcluded = [...excluded];
          let newIncluded = selectAll ? included : [...included];
          switch (e.target.value) {
            case SELECT_ALL:
              newIncluded = true;
              newExcluded = [];
              break;
            case SELECT_PAGE_ALL:
              if (selectAll) {
                newExcluded = newExcluded.filter((el) => !pageIds.includes(el));
              } else {
                newIncluded = newIncluded.concat(pageIds).filter(arrayUnique);
                newExcluded = newExcluded.filter((el) => !pageIds.includes(el));
              }
              break;
            case DESELECT_ALL:
              newIncluded = [];
              newExcluded = [];
              break;
            case DESELECT_PAGE_ALL:
              if (selectAll) {
                newExcluded = newExcluded.concat(pageIds).filter(arrayUnique);
              } else {
                newExcluded = newExcluded.concat(pageIds).filter(arrayUnique);
                newIncluded = newIncluded.filter((el) => !pageIds.includes(el));
              }
              break;
            default:
          }
          updateMassActionSelection(newIncluded, newExcluded);
          if (typeof onChangeMassActionSelectCb === "function")
            onChangeMassActionSelectCb(newIncluded);
        }}
      >
        {selectOptions.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    ),
    Cell: (rowData) => {
      const id = rowData.row._original[rowId];
      const selectAll = included === true;
      const isChecked =
        (selectAll && !excluded.includes(id)) ||
        (!selectAll && included.includes(id));
      return (
        <div className="d-block w-100 text-center">
          <input
            data-t1="massActionCheckbox"
            type="checkbox"
            checked={isChecked}
            onChange={() => {
              let newExcluded = [...excluded];
              let newIncluded = selectAll ? included : [...included];
              if (isChecked) {
                if (selectAll) {
                  newExcluded.push(id);
                } else {
                  newIncluded = newIncluded.filter((el) => el !== id);
                }
              } else {
                // eslint-disable-next-line no-lonely-if
                if (selectAll) {
                  newExcluded = newExcluded.filter((el) => el !== id);
                } else {
                  newIncluded.push(id);
                }
              }
              updateMassActionSelection(newIncluded, newExcluded);
              if (typeof onChangeMassActionSelectCb === "function")
                onChangeMassActionSelectCb(newIncluded);
            }}
          />
        </div>
      );
    },
  };
}

export default getRowSelectColumn;
