/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useCallback, useContext, useRef, useState } from "react";
import __ from "src/utils/Translations";
import { dynamicNotification } from "src/utils/Notifications";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import ActionColumn from "src/Components/DataTable/actionColumn";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import {
  getAlertMessage,
  STATUS_PENDING,
  STATUS_PENDING_ACCEPTED,
  STATUS_BENEFIT_PRICING_CHANGED,
  alertTypes,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import {
  subscriptionEmployeeSubscriptionItemAccept,
  subscriptionEmployeeSubscriptionItemCancel,
  subscriptionEmployeeSubscriptionItemCancelAhr,
} from "src/utils/RoleBasedSecurity/permissions";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { MASS_ACTIONS_STATUSES } from "src/Pages/Company/Pending/BenefitsPending/consts";

const ActionsColumn = (cellInfo) => {
  const {
    row: {
      _original: {
        status,
        pendingDependencies,
        id: currentReceiverId,
        pdfBarcodes,
        pendingStatuses,
      },
    },
    column: { refreshData },
  } = cellInfo;

  const [isLoading, setIsLoading] = useState(false);
  const pdfBarcodesIds = useRef(
    pdfBarcodes.filter(({ accepted }) => !accepted).map(({ id }) => id)
  );

  const receiverHasStatusPendingAccepted = status === STATUS_PENDING_ACCEPTED;
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();

  const hasUnacceptedForms = pdfBarcodes.some((el) => !el.accepted);

  const prepPendingDependencies =
    pendingDependencies ||
    pendingStatuses.includes("waitingForDependantSubscriptions");

  const denySubscription = useCallback(async () => {
    try {
      setIsLoading(true);
      const { subscriptionItemReceivers } = await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employee-subscription-item-receivers/bulk-cancel`,
        "POST",
        {
          body: {
            subscriptionItemReceivers: [currentReceiverId], // Receivers ids array
          },
        },
        {}
      );
      refreshData();
      if (
        Object.values(subscriptionItemReceivers)[0].status ===
        MASS_ACTIONS_STATUSES.NOT_FOUND
      ) {
        dynamicNotification(__("Nie udało się odrzucić świadczenia"), "error");
        return;
      }
      dynamicNotification(__("Pomyślnie odrzucono świadczenie"));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/Pending/BenefitsPending/Listing/Table/actionsColumn.js - 59"
      );
      dynamicNotification(
        e.message || __("Nie udało się odrzucić świadczenia"),
        "error"
      );
    }
    setIsLoading(false);
  }, [currentReceiverId, refreshData]);
  const acceptSubscription = useCallback(async () => {
    try {
      setIsLoading(true);
      const { receivers } = await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employee-subscription-item-receivers/bulk-accept`,
        "POST",
        {
          body: {
            receivers: [currentReceiverId], // Receivers ids array
            pdfBarcodes: pdfBarcodesIds.current, // Pdfbarcodes ids array
          },
        },
        {}
      );
      refreshData();
      if (
        Object.values(receivers)[0].status === MASS_ACTIONS_STATUSES.NOT_FOUND
      ) {
        dynamicNotification(__("Nie udało się odrzucić świadczenia"), "error");
        return;
      }
      dynamicNotification(__("Pomyślnie zaakceptowano świadczenie"));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/Pending/BenefitsPending/Listing/Table/actionsColumn.js - 84"
      );
      dynamicNotification(
        e.message || __("Nie udało się zaakceptować świadczenia"),
        "error"
      );
    }
    setIsLoading(false);
  }, [currentReceiverId, refreshData]);

  return (
    <div className="d-block w-100 text-center">
      {status === STATUS_PENDING ||
      status === STATUS_PENDING_ACCEPTED ||
      status === STATUS_BENEFIT_PRICING_CHANGED ? (
        <ActionColumn
          data={cellInfo.row._original}
          buttons={[
            {
              id: "accept",
              permission: subscriptionEmployeeSubscriptionItemAccept,
              color: "primary",
              disabled:
                isLoading ||
                receiverHasStatusPendingAccepted || // If receiver status (from status field) is pending_accepted
                prepPendingDependencies || // If benefit is dependent on another
                hasUnacceptedForms, // If some of the PDFs are not accepted
              size: "sm",
              className: "my-1 w-100",
              onClick: () =>
                getUserConfirmationPopup(
                  __(
                    status !== STATUS_BENEFIT_PRICING_CHANGED
                      ? getAlertMessage(alertTypes.default)
                      : getAlertMessage(alertTypes.acceptPricingChanged)
                  ),
                  (confirm) => confirm && acceptSubscription(),
                  __("Czy na pewno chcesz zaakceptować abonament?")
                ),
              label: "Akceptuj",
            },
            {
              id: "cancel",
              permission: isAhr
                ? subscriptionEmployeeSubscriptionItemCancelAhr
                : subscriptionEmployeeSubscriptionItemCancel,
              role: "button",
              color: "danger",
              disabled: isLoading,
              size: "sm",
              className: "my-1 w-100",
              onClick: () =>
                getUserConfirmationPopup(
                  __(
                    status !== STATUS_BENEFIT_PRICING_CHANGED
                      ? getAlertMessage(alertTypes.cancelAlert)
                      : getAlertMessage(alertTypes.cancelAlertPricingChanged)
                  ),
                  (confirm) => confirm && denySubscription(),
                  __("Czy na pewno chcesz odrzucić abonament?")
                ),
              label: "Anuluj",
              hideIfNoPermission: true,
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default ActionsColumn;
