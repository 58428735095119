/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Input, InputGroup } from "reactstrap";
import {
  DndContext,
  DragOverlay,
  rectIntersection,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
} from "@dnd-kit/core";
import {
  useSortable,
  SortableContext,
  rectSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";
import __ from "src/utils/Translations";
import {
  SetSessionValue,
  GetSessionValue,
} from "src/Pages/Report/helpers/sessionHelper";
import { getLanguage } from "src/utils/Languages/LanguageContext";

export default function AdHocReport({ adHocState, setAdHocState }) {
  const [activeItem, setActiveItem] = useState(null);

  const [availableHeight, setAvailableHeight] = useState(0);
  const availableRef = useRef(null);
  const [selectedHeight, setSelectedHeight] = useState(0);
  const selectedRef = useRef(null);

  class CustomPointerSensor extends PointerSensor {
    static activators = [
      {
        eventName: "onPointerDown",
        handler: ({ nativeEvent }) => {
          if (nativeEvent.target.nodeName !== "DIV") {
            return false;
          }
          nativeEvent.preventDefault();
          return true;
        },
      },
    ];
  }

  const sensors = useSensors(useSensor(CustomPointerSensor));

  const handleTranslationToggle = (event) => {
    const newAdHocState = { ...adHocState };
    // nie używać operatora ^= bo eslint go zamienia na =
    if (newAdHocState.isTranslationChecked) {
      newAdHocState.isTranslationChecked = false;
    } else {
      newAdHocState.isTranslationChecked = true;
    }
    setAdHocState(newAdHocState);
  };

  function findContainer(id) {
    if (id in adHocState) {
      return id;
    }

    if (
      adHocState.availableColumns.indexOf((i) => i.id === id) >= 0 ||
      adHocState.selectedColumns.indexOf((i) => i.id === id) >= 0
    ) {
      return id;
    }

    return Object.keys(adHocState).find(
      (key) =>
        Array.isArray(adHocState[key]) &&
        adHocState[key].find((x) => x.id === id) !== undefined
    );
  }

  const handleDragStart = (event) => {
    if (availableRef !== null && availableRef.current !== null) {
      setAvailableHeight(availableRef.current.clientHeight);
    }
    setSelectedHeight(selectedRef.current.clientHeight);
    setActiveItem(event.active);
  };

  const handleDragOver = (event) => {
    const { draggingRect } = event;
    const { active } = event;
    const { over } = event;

    if (active === null || over === null) {
      return;
    }

    const activeContainer = findContainer(active.id);
    const overContainer = findContainer(over.id);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setAdHocState((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      const activeIndex = activeItems.findIndex((i) => i.id === active.id);
      const overIndex = overItems.findIndex((i) => i.id === over.id);

      let newIndex;
      if (over.id in prev) {
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect &&
          draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item.id !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          adHocState[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  };

  const handleDragEnd = (event) => {
    setActiveItem(null);
    setAvailableHeight(null);
    setSelectedHeight(null);

    const { active } = event;
    const { over } = event;

    if (active === null || over === null) {
      return;
    }

    const activeContainer = findContainer(active.id);
    const overContainer = findContainer(over.id);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = adHocState[activeContainer].findIndex(
      (i) => i.id === active.id
    );
    const overIndex = adHocState[overContainer].findIndex(
      (i) => i.id === over.id
    );

    if (activeIndex !== overIndex) {
      setAdHocState((prev) => ({
        ...prev,
        [overContainer]: arrayMove(prev[overContainer], activeIndex, overIndex),
      }));
    }
  };

  const handleReportNameChange = (isEnglishTranslation) => (event) => {
    const newAdHocState = { ...adHocState };
    if (!isEnglishTranslation) {
      newAdHocState.namePl = event.target.value;
    } else {
      newAdHocState.nameEn = event.target.value;
    }
    setAdHocState(newAdHocState);
  };

  function handleSelectAll() {
    const availableItems = adHocState.availableColumns;
    const selectedItems = adHocState.selectedColumns;
    setAdHocState((prev) => ({
      ...prev,
      availableColumns: [],
      selectedColumns: [...selectedItems, ...availableItems],
    }));
  }

  function handleRemoveAll() {
    const availableItems = adHocState.availableColumns;
    const selectedItems = adHocState.selectedColumns;
    setAdHocState((prev) => ({
      ...prev,
      availableColumns: [...availableItems, ...selectedItems],
      selectedColumns: [],
    }));
  }

  const rowStyle = {
    background: "#f0f3f5",
    border: "1px solid #ced4da",
    padding: "8px 4px 0 8px",
    margin: "8px 0",
    borderRadius: "0.25rem",
  };

  return (
    <>
      {adHocState !== null && (
        <div>
          {GetSessionValue("englishEnabled") === true && (
            <ToggleSwitch
              checked={adHocState.isTranslationChecked}
              label={__("Tłumaczenie struktury raportu na j. angielski")}
              handleChange={() => {
                handleTranslationToggle();
              }}
            />
          )}

          <div className="row">
            <div className="col-md-6">
              <label htmlFor={adHocState.dataFromApi.id}>
                {__("Nazwa raportu w języku polskim")}
              </label>
              <Input
                id={adHocState.dataFromApi.id}
                defaultValue={adHocState.dataFromApi.namePl}
                onChange={handleReportNameChange(false)}
                maxLength={150}
                disabled={adHocState.dataFromApi.isBase !== true}
              />
            </div>
            {GetSessionValue("englishEnabled") === true &&
              adHocState.isTranslationChecked === true && (
                <div className="col-md-6">
                  <label htmlFor={adHocState.dataFromApi.id}>
                    {__("Nazwa raportu w języku angielskim")}
                  </label>
                  <Input
                    id={adHocState.dataFromApi.id}
                    defaultValue={adHocState.dataFromApi.nameEn}
                    onChange={handleReportNameChange(true)}
                    maxLength={150}
                    disabled={adHocState.dataFromApi.isBase !== true}
                  />
                </div>
              )}
          </div>

          <DndContext
            sensors={sensors}
            collisionDetection={rectIntersection}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            {adHocState.dataFromApi.isBase === true && (
              <div>
                <span>{__("Dostępne kolumny")}</span>
                <div
                  ref={availableRef}
                  style={{
                    ...rowStyle,
                    minHeight: availableHeight
                      ? `${availableHeight}px`
                      : "100px",
                  }}
                >
                  <Container
                    id="availableColumns"
                    activeItem={activeItem}
                    isDisabled={adHocState.dataFromApi.isBase === false}
                    adHocState={adHocState}
                    setAdHocState={setAdHocState}
                  />
                </div>
                {adHocState.availableColumns.length > 0 && (
                  <div className="text-right">
                    <Button
                      className="mx-1"
                      color="secondary"
                      onClick={() => {
                        handleSelectAll();
                      }}
                    >
                      <i className="lnr-arrow-down btn-icon-wrapper" />
                      {__("Dodaj wszystkie")}
                    </Button>
                  </div>
                )}
              </div>
            )}

            <span>{__("Wybrane kolumny")}</span>
            <div
              ref={selectedRef}
              style={{
                ...rowStyle,
                minHeight: selectedHeight ? `${selectedHeight}px` : "100px",
              }}
            >
              {adHocState.dataFromApi.isBase === true &&
                adHocState.selectedColumns.length === 0 && (
                  <div className="text-center">
                    {__(
                      "Brak zdefiniowanych kolumn w raporcie z własną konfiguracją. Wygenerowany raport będzie raportem domyślnym."
                    )}
                  </div>
                )}
              {adHocState.dataFromApi.isBase === true && (
                <div>
                  <div className="text-center">
                    {__(
                      "Przeciągnij tutaj wybrane kolumny aby utworzyć własną konfigurację."
                    )}
                  </div>
                  <div className="text-center">
                    <i
                      className="lnr-enter-down btn-icon-wrapper"
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                </div>
              )}
              <Container
                id="selectedColumns"
                activeItem={activeItem}
                isDisabled={adHocState.dataFromApi.isBase === false}
                adHocState={adHocState}
                setAdHocState={setAdHocState}
              />
            </div>
            {adHocState.dataFromApi.isBase === true && (
              <div className="text-right">
                {adHocState.selectedColumns.length > 0 && (
                  <Button
                    className="mx-1 text-right"
                    color="secondary"
                    onClick={() => {
                      handleRemoveAll();
                    }}
                  >
                    <i className="lnr-arrow-up btn-icon-wrapper" />
                    {__("Usuń wszystkie")}
                  </Button>
                )}
              </div>
            )}
            <DragOverlay>
              {activeItem ? (
                <div
                  style={{
                    boxShadow: "0 3px 19px #ababab, 0 4px 6px #b7b7b7",
                  }}
                >
                  <Item item={activeItem} adHocState={adHocState} />
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      )}
    </>
  );
}

AdHocReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  adHocState: PropTypes.object.isRequired,
  setAdHocState: PropTypes.func.isRequired,
};

AdHocReport.defaultProps = {};

function Container(props) {
  const { id, activeItem, isDisabled, adHocState, setAdHocState } = props;

  const style = {
    width: "100%",
    minHeight: "100px",
    maxHeight: "375px",
    overflowY: "auto",
    display: "grid",
    padding: "15px 15px 23px 15px",
    alignItems: "center",
    maxWidth: "100%",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "15px",
  };

  const { setNodeRef } = useDroppable({
    id: props.id,
  });

  return (
    <div ref={setNodeRef} className="custom-scrollbar" style={style}>
      <SortableContext
        id={id}
        items={adHocState[id]}
        strategy={rectSortingStrategy}
      >
        {adHocState[id].map((item) => (
          <SortableItem
            key={item.id}
            item={item}
            containerId={id}
            isDisabled={isDisabled}
            isDragging={activeItem !== null && activeItem.id === id}
            adHocState={adHocState}
            setAdHocState={setAdHocState}
          />
        ))}
      </SortableContext>
    </div>
  );
}

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.item.id,
    disabled: !props.adHocState.dataFromApi.isBase,
  });

  const style = {
    cursor: props.adHocState.dataFromApi.isBase ? "grab" : "",
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={{ ...style, opacity: isDragging ? 0.3 : 1 }}
      {...attributes}
      {...listeners}
    >
      <Item
        item={props.item}
        containerId={props.containerId}
        isDisabled={props.isDisabled}
        adHocState={props.adHocState}
        setAdHocState={props.setAdHocState}
      />
    </div>
  );
}

function Item(props) {
  const { item, containerId, isDisabled, adHocState, setAdHocState } = props;

  const handleColumnNameChange =
    (columnId, containerId, isTranslation) => (event) => {
      const newAdHocState = { ...adHocState };
      const column = newAdHocState[containerId].find(
        (col) => col.id === columnId
      );

      if (column !== undefined) {
        if (isTranslation) {
          column.englishName = event.target.value;
        } else {
          column.customName = event.target.value;
        }
      }
      setAdHocState(newAdHocState);
    };

  const style = {
    position: "relative",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ced4da",
    padding: "16px 12px 12px 12px",
    background: "white",
    borderRadius: "0.25rem",
  };

  return (
    <div style={style}>
      <span
        style={{
          marginRight: "16px",
          cursor: "text",
          color: "#777",
          fontSize: "0.8em",
        }}
      >
        {__("Domyślnie:")}
        &nbsp;
        {getLanguage() === "pl" ? item?.defaultPl : item?.defaultEn}
      </span>
      {props.adHocState.dataFromApi.isBase && (
        <div
          className="lnr lnr-move"
          style={{ position: "absolute", top: 8, right: 8 }}
        />
      )}
      <InputGroup>
        <Input
          value={item?.customName}
          disabled={isDisabled}
          onChange={handleColumnNameChange(item?.id, containerId, false)}
          maxLength={200}
        />
      </InputGroup>
      {GetSessionValue("englishEnabled") === true &&
        adHocState.isTranslationChecked === true && (
          <div>
            <span
              style={{
                display: "inline-block",
                marginTop: "8px",
                cursor: "text",
                color: "#777",
                fontSize: "0.8em",
              }}
            >
              {__("Domyślnie EN:")}
              &nbsp;
              {item?.defaultEn}
            </span>
            <InputGroup>
              <Input
                value={item?.englishName}
                disabled={isDisabled}
                onChange={handleColumnNameChange(item?.id, containerId, true)}
                maxLength={200}
              />
            </InputGroup>
          </div>
        )}
    </div>
  );
}
