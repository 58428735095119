import { useState } from "react";
import { CONFIGURATION_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import isObject from "src/utils/jsHelpers/isObject";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const useSaveFunction = (scope, service = CONFIGURATION_SERVICE) => {
  const [isSaving, setIsSaving] = useState(false);

  const save = async (data) => {
    setIsSaving(true);
    if (data["subscription/functionalities/bannerContent"]?.length > 601) {
      setIsSaving(false);
      return dynamicNotification(
        __(
          "Pole 'Treść baneru dotyczącego abonamentów' ma więcej niż 600 znaków"
        ),
        "error"
      );
    }
    return restApiRequest(
      service,
      "/update-config",
      "POST",
      {
        body: {
          companyId: scope.companyId || null,
          employeeGroupId: scope.employeeGroupId || null,
          data: parseDataToBackend(data, true),
        },
      },
      {}
    )
      .then(() => {
        setIsSaving(false);
        dynamicNotification("Pomyślnie zapisano konfigurację");
        return true;
      })
      .catch((e) => {
        newRelicErrorReport(
          e,
          "Pages/Administration/Configuration/utils/useSaveFunction.js - 41"
        );
        dynamicNotification(
          e.message || __("Nie udało się zapisać konfiguracji"),
          "error"
        );
        setIsSaving(false);
        return false;
      });
  };
  return [save, isSaving];
};

export const parseDataToBackend = (data, stringifyArrays) => {
  const bodyData = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (isObject(value) || (stringifyArrays && Array.isArray(value))) {
      bodyData[key] = JSON.stringify(value);
    } else {
      bodyData[key] = value;
    }
  });
  return bodyData;
};

export default useSaveFunction;
