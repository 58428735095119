import React from "react";
import PropTypes from "prop-types";
import ReportsTable from "./table";

const ReportListByGroup = ({ description, reportList, isAdHoc }) => (
  <>
    <h4>{description}</h4>
    <ReportsTable reportList={reportList} isAdHoc={isAdHoc} />
  </>
);

ReportListByGroup.propTypes = {
  description: PropTypes.string,
  reportList: PropTypes.arrayOf(PropTypes.object),
  isAdHoc: PropTypes.bool,
};

ReportListByGroup.defaultProps = {
  isAdHoc: false,
  description: "",
  reportList: [],
};

export default ReportListByGroup;
