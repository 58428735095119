/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import { isDevMode } from "src/config/env";

const ENV_URL_PARTS = {
  LOCAL: "localhost",
  DEV: "dev",
  STAGING: "staging",
};
const ENV_NAMES = {
  DEV: "dev",
  STAGING: "staging",
  PROD: "prod",
};

const newRelicErrorReport = (
  error,
  additionalMessage = "no context provided"
) => {
  if (!window) {
    return;
  }

  const updatedError = new Error(
    `${
      error instanceof Error && error.message
    } - additional: ${additionalMessage}`.substring(0, 255)
  );

  if (isDevMode()) {
    console.log(
      "%c !!!!!!!!!!!!!!!!!!!!!! NEW RELIC ERROR !!!!!!!!!!!!!!!!!!!!!!",
      "background: #222; color: red; ; font-size: 20px"
    );
    console.trace(updatedError);
    console.log(
      "%c !!!!!!!!!!!!!!!!!!!! NEW RELIC ERROR END !!!!!!!!!!!!!!!!!!!!",
      "background: #222; color: red; font-size: 20px"
    );
  }

  const url = window.location.origin;

  // no notification on local
  if (url.includes(ENV_URL_PARTS.LOCAL)) {
    return;
  }

  const environment = url.includes(ENV_URL_PARTS.DEV)
    ? ENV_NAMES.DEV
    : url.includes(ENV_URL_PARTS.STAGING)
    ? ENV_NAMES.STAGING
    : ENV_NAMES.PROD;

  window.newrelic?.noticeError(updatedError, { environment });
};

export default newRelicErrorReport;
