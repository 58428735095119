import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "src/Components/Form";
import Popup from "src/Components/Popup/popup";
import __ from "src/utils/Translations";
import { API_COMPANYEVENTS_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const getDepartmentsOptionsFetchMethod =
  (currValue, { companyId, oldDepartment }) =>
  async (inputValue = "") => {
    let options = [];

    const params = {};
    if (inputValue) {
      params.search = inputValue;
      try {
        const result = await restApiRequest(
          API_COMPANYEVENTS_SERVICE,
          `/omb/${companyId}/departments/find`,
          "POST",
          { body: params }
        );
        options = result
          ?.filter((row) => row.id !== oldDepartment)
          .map(({ id, name, number }) => ({
            value: id,
            label: `${number} - ${name}`,
          }));
      } catch (e) {
        newRelicErrorReport(
          e,
          "Pages/Company/CompanyEvents/Employees/changeDepartmentForm.js - 31"
        );
      }
    }

    return options;
  };

export default function ChangeDepartmentForm({ close, companyId, item }) {
  const [data, updateData] = useState({
    newDepartment: null,
  });

  const onChange = useCallback(
    (key, value) => {
      const updatedData = { ...data };
      updatedData[key] = value;
      updateData(updatedData);
    },
    [data]
  );

  const submit = async () => {
    try {
      await restApiRequest(
        API_COMPANYEVENTS_SERVICE,
        `/omb/${companyId}/employees/change`,
        "POST",
        {
          body: {
            employeeId: item.employeeId,
            newDepartmentId: data.newDepartment,
          },
          headers: {
            "content-type": "application/json",
          },
          returnNull: true,
        }
      );
      dynamicNotification(__("Pomyślnie zapisano zmianę"));
      close(true);
    } catch (e) {
      newRelicErrorReport(
        e,
        "Pages/Company/CompanyEvents/Employees/changeDepartmentForm.js - 77"
      );
      dynamicNotification(e.message || __("Nie udało się zapisać"), "error");
    }
  };

  return (
    <>
      <Popup
        id="changeEmployeeDepartmentPopup"
        isOpen
        toggle={() => close()}
        unmountOnClose
      >
        <Form
          id="changeEmployeeDepartmentForm"
          data={data}
          config={{
            isInPopup: true,
            togglePopup: () => close(),
            onSubmit: submit,
            title: "Zmiana działu pracownika",
            translateTitle: true,
            defaultOnChange: onChange,
            noCards: true,
            buttons: [
              {
                onClick: () => close(),
                text: "Zamknij",
                color: "light",
                id: "changeEmployeeDepartmentFormClose",
              },
              {
                text: "Zapisz",
                type: "submit",
                id: "changeEmployeeDepartmentFormSubmit",
              },
            ],
            formGroups: [
              {
                formElements: [
                  {
                    type: "title",
                    label: `${item.employeeFirstname} ${item.employeeLastname}`,
                  },
                  {
                    type: "hr",
                  },
                  {
                    id: "newDepartment",
                    label: "Wyszukaj nowy dział",
                    validation: ["required"],
                    type: "asyncAutocomplete",
                    fetchOptions: getDepartmentsOptionsFetchMethod(
                      data?.newDepartment,
                      {
                        companyId,
                        oldDepartment: item.departmentId,
                      }
                    ),
                  },
                ],
              },
            ],
          }}
        />
      </Popup>
    </>
  );
}

ChangeDepartmentForm.propTypes = {
  close: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    departmentId: PropTypes.string,
    employeeId: PropTypes.number,
    employeeFirstname: PropTypes.string,
    employeeLastname: PropTypes.string,
  }).isRequired,
};
