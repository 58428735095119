import { useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequestFormData } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { serialize } from "src/utils/ObjectToFormData";

export default function () {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const mutate = async (payload, options = {}) => {
    setIsPending(true);
    try {
      const result = await restApiRequestFormData(
        API_TOTALREWARD_SERVICE,
        `/omb/perks${payload.id ? `/${payload.id}` : ""}?tenant=${
          payload.tenant
        }`,
        "POST",
        serialize(payload, {
          booleansAsIntegers: true,
          indices: true,
          allowEmptyArrays: false,
        }),
        null,
        { customErrorHandler: options?.onError }
      );

      await options?.onSuccess?.(data, payload);

      setIsSuccess(true);
      setIsPending(false);
      setData(result);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "/src/Pages/Company/TotalReward/hooks/useMutateTotalRewardPerk.js - 17"
      );
      setIsError(true);
      setIsPending(false);
      setData(null);
    }

    return data;
  };

  return { mutate, data, isPending, isSuccess, isError };
}
