const formatDate = (date) => {
  const encapsulated_date = new Date(date);
  return new Date(
    encapsulated_date.getTime() - encapsulated_date.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
};

// eslint-disable-next-line import/prefer-default-export
export const prepareFilters = (filters, toString = false) => {
  const {
    employeeId,
    createdAtFrom,
    createdAtTo,
    benefitName,
    status,
    barcodeIds,
  } = filters;

  return {
    ...(employeeId && { employeeId }),
    ...(createdAtFrom && { dateFrom: formatDate(createdAtFrom) }),
    ...(createdAtTo && { dateTo: formatDate(createdAtTo) }),
    ...(benefitName && { benefitName }),
    ...(status && { status }),
    ...(barcodeIds &&
      barcodeIds.length && {
        barcodeIds: toString ? barcodeIds.join(",") : barcodeIds,
      }),
  };
};
