/* eslint-disable no-underscore-dangle, react/prop-types */
import React, { useCallback, useState } from "react";
import { CSSTransitionGroup } from "react-transition-group";

import PropTypes from "prop-types";
import PageTitle from "src/Layout/AppMain/PageTitle";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import { DateFilter, IntIdFilter } from "src/Components/DataTable/filters";
import {
  getDateCell,
  mapValueFromOptions,
} from "src/Components/DataTable/commonCells";
import { NOTIFICATION_SERVICE } from "src/utils/Api";
import { notificationAppMessagePermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import ActionColumn from "src/Components/DataTable/actionColumn";
import {
  getReceiverScope,
  RECEIVER_SCOPE_ALL,
  RECEIVER_SCOPE_COMPANY,
  RECEIVER_SCOPE_EMPLOYEE,
  RECEIVER_SCOPE_EMPLOYEE_GROUP,
  RECEIVER_SCOPE_ORGANIZATION_UNIT,
} from "src/Pages/Notification/AppMessages/Edit/edit";
import __ from "src/utils/Translations";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";
import useOperators from "src/utils/hooks/operator/useOperators";
import useCompanies from "src/utils/hooks/company/useCompanies";
import useAhrs from "src/utils/hooks/company/useAhrs";
import useEmployees from "src/utils/hooks/company/useEmployees";
import useEmployeeGroups from "src/utils/hooks/company/useEmployeeGroups";
import useOrganizationUnits from "src/utils/hooks/company/useOrganizationUnits";

const MAX_ITEMS_PER_REQUEST = 100;

export default function AppMessagesList({
  company,
  companyScope,
  getUrlToForm,
  breadcrumbs,
}) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [createdByIds, setCreatedByIds] = useState([]);
  const [companyIds, setCompanyIds] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [employeeGroupIds, setEmployeeGroupIds] = useState([]);
  const [organizationUnitIds, setOrganizationUnitIds] = useState([]);

  const fetchData = useCallback(
    async (filters, page, pageSize, sort) => {
      const requestFilters = [...filters];
      if (companyScope) {
        requestFilters.push({ id: "companyId", value: company });
      }
      const { data: newData, count: newCount } = await getListingData(
        NOTIFICATION_SERVICE,
        "/app-messages/groupByMultiselect",
        requestFilters,
        page,
        pageSize,
        sort || defaultSorted,
        {
          dateFilters: ["visibleFrom", "createdAt"],
        },
        mockData
      );
      setData(
        newData.map((el) => ({
          ...el,
          receiverScope: getReceiverScope(el),
          receiver: el.multiselectId
            ? el.employeesId
            : el.employeeId ||
              el.employeeGroupId ||
              el.organizationUnitId ||
              el.companyId,
        }))
      );
      setCount(newCount);
      const companies = [];
      const employeeGroups = [];
      const organizationUnits = [];
      let employees = [];
      const authors = [];
      newData.forEach(
        ({
          companyId,
          employeeId,
          employeesId,
          employeeGroupId,
          organizationUnitId,
          createdBy,
        }) => {
          if (companyId) {
            companies.push(companyId);
          }
          if (employeeId) {
            if (employeesId.length) {
              employees = [...employees, ...employeesId];
            } else {
              employees.push(employeeId);
            }
          }
          if (employeeGroupId) {
            employeeGroups.push(employeeGroupId);
          }
          if (organizationUnitId) {
            organizationUnits.push(organizationUnitId);
          }
          if (createdBy) {
            authors.push(createdBy);
          }
        }
      );

      setCompanyIds(companies);
      setEmployeeGroupIds(employeeGroups);
      setOrganizationUnitIds(organizationUnits);
      setEmployeeIds([...new Set(employees)]); // remove duplicates
      setCreatedByIds(authors);
    },
    [companyScope, company]
  );

  const operators = useOperators(
    true,
    "id",
    createdByIds,
    false,
    !createdByIds.length
  );
  const ahrs = useAhrs(true, "id", createdByIds, false, !createdByIds.length);
  const companies = useCompanies({
    asOption: true,
    filterField: "id",
    filterValue: companyIds,
    noFetching: !companyIds.length,
  });
  const employeeGroups = useEmployeeGroups(
    true,
    "id",
    employeeGroupIds,
    false,
    !employeeGroupIds.length
  );
  const organizationUnits = useOrganizationUnits(
    true,
    "id",
    organizationUnitIds,
    false,
    !organizationUnitIds.length
  );
  const employees = useEmployees(
    true,
    "id",
    employeeIds,
    false,
    !employeeIds.length,
    employeeIds.length < MAX_ITEMS_PER_REQUEST
      ? employeeIds.length
      : MAX_ITEMS_PER_REQUEST
  );

  const createdByOptions = [...operators, ...ahrs];
  const receiverOptions = [
    ...companies,
    ...employeeGroups,
    ...organizationUnits,
    ...employees,
  ];

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={__("Lista wiadomości wewnątrzsystemowych")}
          breadcrumbs={breadcrumbs}
        />
        <DataTableControlled
          id="agreementsListing"
          fetchData={fetchData}
          data={data}
          count={count}
          filterable
          buttons={[
            {
              id: "addAppMessage",
              color: "primary",
              href: getUrlToForm(-1),
              text: `+ ${__("Dodaj wiadomość")}`,
              permission: notificationAppMessagePermissionWrite,
            },
          ]}
          columns={[
            {
              Header: "ID",
              accessor: "id",
              filterable: false,
              Filter: IntIdFilter,
            },
            {
              Header: "Zakres",
              accessor: "receiverScope",
              filterable: false,
              sortable: false,
              Cell: mapValueFromOptions(receiverScopeOptions, "receiverScope"),
            },
            {
              Header: (
                <>
                  {__("Odbiorca")}{" "}
                  <DefaultTooltip
                    id="filter-tooltip-receiver"
                    content={
                      <>
                        {__(
                          "Aby filtrować wpisz id firmy, grupy pracowniczej lub pracownika."
                        )}
                        <br />
                        {__(
                          "Brak nazwy odbiorcy może oznaczać że nie masz dostępu do danego zasobu."
                        )}
                      </>
                    }
                  />
                </>
              ),
              additionalHeader: true,
              accessor: "receiver",
              sortable: false,
              filterable: false,
              Cell: mapValueFromOptions(receiverOptions, "receiver"),
            },
            {
              Header: (
                <>
                  {__("Zlecający")}{" "}
                  <DefaultTooltip
                    id="filter-tooltip-created-by"
                    content={
                      <>
                        {__("Aby filtrować wpisz id operatora lub ahr'a.")}
                        <br />
                        {__(
                          "Brak nazwy zlecającego może oznaczać że nie masz dostępu do danego zasobu."
                        )}
                      </>
                    }
                  />
                </>
              ),
              additionalHeader: true,
              accessor: "createdBy",
              sortable: false,
              filterable: false,
              Cell: mapValueFromOptions(createdByOptions, "createdBy"),
            },
            {
              Header: "Tytuł",
              accessor: "subject",
              sortable: false,
            },
            {
              Header: "Zawartość",
              accessor: "message",
              sortable: false,
              filterable: false,
              Cell: (cellData) => {
                let value = cellData.row._original[cellData.column.id];
                if (value) {
                  value = value.replace(/(<([^>]+)>)/gi, "");
                }
                if (value && value.length > 258) {
                  value = `${value.slice(0, 255)}...`;
                }
                return value;
              },
            },
            {
              Header: "Wyświetlaj od/do",
              accessor: "visibleFrom",
              Filter: DateFilter(true),
              Cell: getDateCell("visibleFrom", true),
            },
            {
              Header: "Data utworzenia",
              accessor: "createdAt",
              Filter: DateFilter(true),
              Cell: getDateCell("createdAt", true),
            },
            {
              Header: "Akcja",
              accessor: "action",
              filterable: false,
              sortable: false,
              Cell: ({ row }) => (
                <ActionColumn
                  data={row._original}
                  buttons={[
                    {
                      id: "appMessageEdit",
                      label: "Edytuj",
                      href: getUrlToForm(row._original.id),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </CSSTransitionGroup>
    </>
  );
}

const defaultSorted = {
  key: "visibleFrom",
  value: "desc",
};
const receiverScopeOptions = [
  { value: RECEIVER_SCOPE_ALL, label: "Wszyscy" },
  { value: RECEIVER_SCOPE_COMPANY, label: "Firma" },
  { value: RECEIVER_SCOPE_EMPLOYEE_GROUP, label: "Grupa pracownicza" },
  { value: RECEIVER_SCOPE_ORGANIZATION_UNIT, label: "Jednostka organizacyjna" },
  { value: RECEIVER_SCOPE_EMPLOYEE, label: "Wybrani pracownicy" },
];

const mockData = [
  {
    companyId: "Firma 1",
    createdAt: "2020-12-17T10:44:00+00:00",
    createdBy: "Administrator 1",
    id: 1,
    subject: "Ważna wiadomość",
    message:
      "On the other hand, we denounce with righteous indignation and dislike men who are so...",
    visibleFrom: "2020-12-17T10:44:00+00:00",
  },
  {
    employeeId: "Jan Kowlaski",
    createdAt: "2020-12-17T10:44:00+00:00",
    createdBy: "Administrator 2",
    id: 2,
    subject: "Pilne powiadomienie",
    message:
      "On the other hand, we denounce with righteous indignation and dislike men who are so...",
    visibleFrom: "2020-12-17T10:44:00+00:00",
  },
  {
    employeeGroupId: "Nowi użytkownicy",
    createdAt: "2020-12-17T10:44:00+00:00",
    createdBy: "Administrator 2",
    id: 3,
    subject: "Witaj w serwisie!",
    message:
      "On the other hand, we denounce with righteous indignation and dislike men who are so...",
    visibleFrom: "2020-12-17T10:44:00+00:00",
  },
];
AppMessagesList.propTypes = {
  company: PropTypes.string,
  companyScope: PropTypes.bool,
  getUrlToForm: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
};
AppMessagesList.defaultProps = {
  company: null,
  companyScope: false,
};
