import React, { useState } from "react";
import { Card, CardBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import __ from "src/utils/Translations";
import DownloadPopup from "./downloadPopup";

const Panel = ({
  fetchFormatOptions,
  showReport,
  download,
  requestReport,
  formatOptions,
  reportName,
  isGetBlockBtn,
  isAhr,
  fullreportName,
  isdateOK,
  adHocState,
  handleSaveAdHocReport,
  announcement,
  isDownloadBtnVisible,
  isQueueBtnVisible,
}) => {
  const history = useHistory();
  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useState(false);
  const [isQueueRequest, setIsQueueRequest] = useState(false);
  const [format, setFromat] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isAlert, setIsAlert] = useState(false);

  const closeDownloadReportPopup = () => setIsDownloadPopupOpen(false);
  const openDownloadReportPopup = (isQueue) => {
    setIsAlert(false);
    fetchFormatOptions();
    setIsDownloadPopupOpen(true);
  };

  const backToPreviousSite = () => {
    history.goBack();
  };

  const handleClickListBtn = () => {
    const url = `/report/subscriptions?reportName=${fullreportName}`;

    history.push(getAhrUrl(url, isAhr));
  };

  const saveReport = (toArchive) => {
    const callback = (result) => {
      if (result) {
        setIsSaving(false);
        setIsDownloadPopupOpen(false);
      } else {
        setIsSaving(false);
        setIsAlert(true);
      }
    };

    if (format !== "-1" && format !== "" && isdateOK) {
      setIsAlert(false);
      setIsSaving(true);
      if (!isQueueRequest) {
        download(format, callback, toArchive);
      } else {
        requestReport(format, callback);
      }
    }
  };

  const createHtml = () => ({ __html: announcement });

  return (
    <>
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-md-6 col-sm-12 configure-report-title">
              {__("Konfiguracja raportu")}
              {/* eslint-disable react/no-danger */}
              <span
                className="report-announcement"
                dangerouslySetInnerHTML={createHtml()}
              />
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <Button
                className="mx-1"
                color="secondary"
                onClick={backToPreviousSite}
              >
                {__("Wróć")}
              </Button>
              {adHocState?.selectedColumns?.length > 0 &&
                adHocState.dataFromApi.isBase === true && (
                  <Button
                    className="mx-1"
                    color="primary"
                    onClick={handleSaveAdHocReport}
                  >
                    {__("Zapisz konfigurację raportu")}
                  </Button>
                )}
              <Button
                className="mx-1"
                color="primary"
                onClick={handleClickListBtn}
              >
                {__("Lista wysyłek dla raportu ")}
              </Button>
              <Button
                className="mx-1"
                color="primary"
                onClick={() => showReport(1)}
                disabled={isGetBlockBtn}
              >
                {__("Podgląd")}
              </Button>
              {isDownloadBtnVisible === true && (
                <Button
                  className="mx-1"
                  color="success"
                  onClick={() => {
                    setIsQueueRequest(false);
                    openDownloadReportPopup();
                  }}
                  disabled={isGetBlockBtn}
                >
                  {__("Pobierz")}
                </Button>
              )}
              {isQueueBtnVisible === true && (
                <Button
                  className="mx-1"
                  color="success"
                  onClick={() => {
                    setIsQueueRequest(true);
                    openDownloadReportPopup();
                  }}
                  disabled={isGetBlockBtn}
                >
                  {__("Generuj raport")}
                </Button>
              )}
            </div>
          </div>
          <DownloadPopup
            isOpen={isDownloadPopupOpen}
            isQueueRequest={isQueueRequest}
            close={closeDownloadReportPopup}
            isSaving={isSaving}
            save={saveReport}
            onChangeSelect={(e) => {
              setFromat(e.target.value);
            }}
            formatOptions={formatOptions}
            alert={isAlert}
          />
        </CardBody>
      </Card>
    </>
  );
};

Panel.propTypes = {
  fetchFormatOptions: PropTypes.func.isRequired,
  showReport: PropTypes.func.isRequired,
  formatOptions: PropTypes.array.isRequired,
  download: PropTypes.func.isRequired,
  requestReport: PropTypes.func,
  reportName: PropTypes.string.isRequired,
  fullreportName: PropTypes.string.isRequired,
  isGetBlockBtn: PropTypes.bool.isRequired,
  isAhr: PropTypes.bool.isRequired,
  isdateOK: PropTypes.func.isRequired,
  handleSaveAdHocReport: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  adHocState: PropTypes.object.isRequired,
  announcement: PropTypes.string.isRequired,
  isDownloadBtnVisible: PropTypes.bool,
  isQueueBtnVisible: PropTypes.bool,
};
Panel.defaultProps = {
  requestReport: () => {},
  isDownloadBtnVisible: true,
  isQueueBtnVisible: true,
};
export default Panel;
