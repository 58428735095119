import React from "react";
import AppMessagesList from "src/Pages/Notification/AppMessages/List/list";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import { getAhrBaseBreadcrumbs } from "src/Pages/AhrCore/routerHelper";
import __ from "src/utils/Translations";

export default function List() {
  const getUrlToForm = (id) => `/ahr/notification/app-messages/${id}`;
  const companyName = useCompanyName();
  const companyId = useCompanyId();
  return (
    <AppMessagesList
      companyScope
      company={companyId}
      getUrlToForm={getUrlToForm}
      breadcrumbs={[
        ...getAhrBaseBreadcrumbs(companyName),
        {
          title: `${__("Powiadomienia")}`,
          link: `/ahr/notification`,
        },
      ]}
    />
  );
}
