import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React from "react";
import __ from "src/utils/Translations";
import { reimpersonate } from "src/utils/RoleBasedSecurity/Session";

function SidebarMenuAnchor({
  id,
  href,
  before,
  isOpen,
  isActive,
  hasActiveChild,
  hasSubmenu,
  to,
  icon,
  label,
  toggleSubmenu,
}) {
  const handleClick = (event, href, action) => {
    event.preventDefault();
    if (action === "reimpersonate") {
      reimpersonate();
    }
    window.location.href = href;
  };

  if (href && before) {
    return (
      <a
        data-t1={id}
        className={`metismenu-link${hasActiveChild ? " has-active-child" : ""}${
          isActive ? " active" : ""
        }`}
        href={href}
        onClick={(event) => handleClick(event, href, before)}
      >
        <i className={`metismenu-icon${icon ? ` ${icon}` : ""}`} />
        {__(label)}
      </a>
    );
  }

  if (href) {
    return (
      <a
        data-t1={id}
        className={`metismenu-link${hasActiveChild ? " has-active-child" : ""}${
          isActive ? " active" : ""
        }`}
        href={href}
      >
        <i className={`metismenu-icon${icon ? ` ${icon}` : ""}`} />
        {__(label)}
      </a>
    );
  }

  return (
    <Link
      data-t1={id}
      className={`metismenu-link${hasActiveChild ? " has-active-child" : ""}${
        isActive ? " active" : ""
      }`}
      to={to || "#"}
      href={href}
      onClick={() => toggleSubmenu(id)}
    >
      <i className={`metismenu-icon${icon ? ` ${icon}` : ""}`} />
      {__(label)}
      {hasSubmenu ? (
        <i
          className={`metismenu-state-icon pe-7s-angle-down caret-left${
            isOpen ? " rotate-minus-90" : ""
          }`}
        />
      ) : null}
    </Link>
  );
}

SidebarMenuAnchor.propTypes = {
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  before: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  hasActiveChild: PropTypes.bool.isRequired,
  hasSubmenu: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  toggleSubmenu: PropTypes.func.isRequired,
};

export default SidebarMenuAnchor;
