/* eslint-disable no-underscore-dangle, react/prop-types */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import { REPORT_SERVICE, restApiRequest, getServiceHost } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import {
  ArchiveListMock,
  groupFilterMock,
  formatFilterMock,
  sourceFilterMock, // creatorFilterMock,
} from "src/Pages/Report/GeneratedReports/mockData";
import { dynamicNotification } from "src/utils/Notifications";
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import { useLanguages } from "src/utils/Languages/LanguageContext";
import {
  correctLanguageParameters,
  SelectFilterWithTranslation,
  mapValueFromOptionsTranslation,
} from "src/Pages/Report/helpers/paramtersHelper";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";
import signalRService from "src/utils/SignalR/signalRService";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import ParametersModal from "./parametersModal";

const Archive = ({ companyId, archiveDetailsUrl }) => {
  const [archiveList, setArchiveList] = useState([]);
  const archiveListRef = useRef(archiveList);
  const [archiveListListCount, setArchiveListListCount] = useState(0);

  const [paramsModalOpen, setParamsModalOpen] = useState(false);
  const [paramsModal, setParamsModal] = useState([]);

  const [reportGroupFilter, setReportGroupFilter] = useState([]);
  const [formatFilter, setFormatFilter] = useState([]);
  const [sourceFilter, setSourceFilter] = useState([]);

  const rbsContext = useContext(RbsContext);

  useEffect(() => {
    signalRService.onMessageExternalEvent = updateArchivedReportStatus;

    fetchReportGroupFilter();
    fetchFormatFilter();
    fetchSourceFilter();

    return () => {
      signalRService.onMessageExternalEvent = null;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    archiveListRef.current = archiveList;
  }, [archiveList]);

  const updateArchivedReportStatus = (archId, newStatus) => {
    setArchiveList(
      archiveListRef.current.map((item) => {
        if (item.uid === archId) {
          return { ...item, status: newStatus };
        }
        return item;
      })
    );
  };

  const fetchReportGroupFilter = () => {
    fetchData(
      setReportGroupFilter,
      "/reportgroups",
      REPORT_SERVICE,
      "GET",
      {},
      groupFilterMock,
      __("Błąd podczas pobierania listy dostępnych grup raportów")
    );
  };
  const fetchFormatFilter = () => {
    fetchData(
      setFormatFilter,
      "/reports/static/formats",
      REPORT_SERVICE,
      "GET",
      {},
      formatFilterMock,
      __("Błąd podczas pobierania listy dostępnych formatów")
    );
  };
  const fetchSourceFilter = () => {
    fetchData(
      setSourceFilter,
      "/archive/archivedreportsources",
      REPORT_SERVICE,
      "GET",
      {},
      sourceFilterMock,
      __("Błąd podczas pobierania listy dostępnych źródeł")
    );
  };

  const columns = () => [
    {
      Header: "Grupa",
      accessor: "reportGroup",
      Filter: SelectFilterWithTranslation(reportGroupFilter),
      Cell: mapValueFromOptionsTranslation([], "reportGroup"),
    },
    {
      Header: "Nazwa",
      accessor: "name",
      Cell: (rowData) => __(rowData.row.name),
    },
    {
      Header: "Data Zapisania",
      accessor: "modificationDateStr",
    },
    {
      Header: "Parametry",
      maxWidth: 100,
      accessor: "parameters",
      filterable: false,
      Cell: ({ row }) => (
        <div className="d-block w-100 text-center row">
          <Button
            role="button"
            color="link"
            onClick={() => handleParamsClick(row._original.parameters)}
          >
            {" "}
            {__("Pokaż")}
          </Button>
        </div>
      ),
    },
    {
      Header: "Format",
      maxWidth: 80,
      accessor: "fileExtension",
      Filter: SelectFilterWithTranslation(formatFilter),
      Cell: mapValueFromOptionsTranslation([], "fileExtension"),
    },
    {
      Header: "Źródło",
      accessor: "sourceName",
      Filter: SelectFilterWithTranslation(sourceFilter),
      Cell: mapValueFromOptionsTranslation([], "sourceName"),
    },
    {
      Header: "Utworzone przez",
      accessor: "creator",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div>
          {row.status === 2 && (
            <div style={{ display: "inline-block", marginRight: 8 }}>
              <Spinner color="primary" size="sm" />
            </div>
          )}
          {`${handleStatus(row.status)} `}
          {row.status === 4 && (
            <DefaultTooltip
              id={`tooltip_${row._index}`}
              content={__(
                "Błąd generacji raportu, skontaktuj się z obsługą klienta."
              )}
            />
          )}
        </div>
      ),
    },
    {
      Header: "Akcja",
      maxWidth: 180,
      filterable: false,
      sortable: false,
      Cell: ({ row }) => (
        <div className="d-block w-100 text-center row">
          {row.status === 3 && (
            <Button
              role="button"
              onClick={() => downloadReport(row._original)}
              color="link"
            >
              {__("Pobierz")}
            </Button>
          )}
          {row.status !== 99 && (
            <Link
              to={getReportsUrl(row._original.reportUid, row._original.uid)}
            >
              <Button role="button" color="link">
                {" "}
                {__("Wygeneruj podobny")}
              </Button>
            </Link>
          )}
          <Link to={archiveDetailsUrl(row._original.uid)}>
            <Button role="button" color="link">
              {" "}
              {__("Więcej")}
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  const languages = useLanguages(true);

  const handleParamsClick = (params) => {
    // eslint-disable-next-line no-param-reassign
    params = correctLanguageParameters(params, languages);
    setParamsModal(params);
    setParamsModalOpen(true);
  };

  const handleStatus = (status) => {
    switch (status) {
      case 0:
        return "---";
      case 1:
        return "Oczekuje";
      case 2:
        return "W trakcie generowania";
      case 3:
        return "Wygenerowany";
      case 5:
        return "Wygenerowany<br />Plik wygasł";
      case 99:
        return "Błąd";
      default:
        return "---";
    }
  };

  const getReportsUrl = (id, achId) =>
    getAhrUrl(
      `/report/predefined-reports/${id}/${companyId}/${achId}`,
      rbsContext.userInfo.isAhr()
    );

  const fetchArchiveList = useCallback(
    async (filters, page, pageSize, sort) => {
      const { data: newData, count: newCount } = await getListingData(
        REPORT_SERVICE,
        `/archive/reports/${companyId}`,
        filters,
        page,
        pageSize,
        sort,
        {},
        ArchiveListMock
      );
      setArchiveList(newData);
      setArchiveListListCount(newCount);
    },
    [companyId]
  );

  const downloadReport = async (data) => {
    if (!isMockView()) {
      // const chosenFormat = formatOptions.find((x) => x.renderFormatId === Number(format));
      const session = getSession();
      const server = getServiceHost(REPORT_SERVICE);
      const url = `${window.reportingTool}${server}/archive/report/file?archivedReportId=${data.uid}`;

      fetch(url, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${await session.getValidAccessToken()}`,
        },

        // 1. Convert the data into 'blob'
      })
        .then((response) => response.blob())
        .then((blob) => {
          if (blob.size !== 0) {
            // 2. Create blob link to download
            const urlfile = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = urlfile;
            link.setAttribute(
              "download",
              `${__(data.name)}_${data.modificationDate}.${data.fileExtension}`
            );
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
          }
        });
    }
  };

  return (
    <>
      {companyId !== "" &&
        companyId !== "00000000-0000-0000-0000-000000000000" && (
          <DataTableControlled
            key={companyId}
            columns={columns()}
            data={archiveList}
            filterable
            fetchData={fetchArchiveList}
            count={archiveListListCount}
          />
        )}
      <ParametersModal
        isOpen={paramsModalOpen}
        close={() => setParamsModalOpen(false)}
        parameters={paramsModal}
      />
    </>
  );
};

export default Archive;
Archive.propTypes = {
  companyId: PropTypes.string.isRequired,
  archiveDetailsUrl: PropTypes.func.isRequired,
};

const fetchData = async (
  updateData,
  endpoint,
  service,
  { headers, params },
  mockData,
  error
) => {
  try {
    const result = await restApiRequest(
      service,
      endpoint,
      "GET",
      { headers, params },
      mockData
    );
    updateData(result);
  } catch (e) {
    console.error(e);
    newRelicErrorReport(e, "Pages/Report/GeneratedReports/List/index.js - 315");
    dynamicNotification(error || e.message || "błąd", "error");
  }
};
