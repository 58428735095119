/* eslint-disable react/jsx-props-no-spreading, no-unused-expressions */
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Label, Row, Col } from "reactstrap";
import __ from "src/utils/Translations";
import { getAdditionalFiltersComponent } from "src/Components/DataTableControlled/utils";

export default function AdditionalFilters({
  filtersConfig,
  onFilteredChange,
  currentFilters,
}) {
  const onUpdate = useCallback(
    (key, value) => {
      const newFilters = [...currentFilters];
      const valueObject = currentFilters.find((el) => el.id === key);
      if (valueObject) {
        valueObject.value = value;
      } else {
        newFilters.push({ id: key, value });
      }
      onFilteredChange(newFilters);
    },
    [currentFilters, onFilteredChange]
  );

  const onRangeChange = useCallback(
    (value, id) => {
      const { from, to } = value;
      const newValue = { from: from || null, to: to || null };
      onUpdate(id, newValue);
    },
    [onUpdate]
  );

  const components = useMemo(() => {
    const components = {};

    filtersConfig?.forEach(
      ({ id, type, options, validation, props: restProps }) => {
        const valueObject = currentFilters?.find((el) => el.id === id);
        const value = valueObject?.value ?? "";

        const component = getAdditionalFiltersComponent({
          type,
          id,
          value,
          options,
          onUpdate,
          valueObject,
          onRangeChange,
          restProps,
          validation,
        });

        components[id] = component;
      }
    );

    return components;
  }, [filtersConfig, currentFilters, onRangeChange, onUpdate]);

  return (
    <Row>
      {filtersConfig.map(({ label, id, type, hasLineBreak }) => {
        const component = components[id];

        return (
          <>
            {hasLineBreak && (
              <div style={{ flexBasis: "100%", height: 0, marginTop: 10 }} />
            )}
            <Col
              key={id}
              sm={2}
              className="mb-2"
              style={type === "dateRange" ? { minWidth: "fit-content" } : {}}
            >
              <Label>{__(label)}:</Label> {component}
            </Col>
          </>
        );
      })}
    </Row>
  );
}

AdditionalFilters.propTypes = {
  filtersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.oneOf(["text", "select"]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    })
  ).isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};
