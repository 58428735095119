const DISCOUNT_TYPES = {
  PERCENTAGE: "PERCENTAGE",
  REDUCING_AMOUNT: "REDUCING_AMOUNT",
  AMOUNT_TO_USE: "AMOUNT_TO_USE",
  GRATIS: "GRATIS",
};

const POSSIBLE_DISCOUNT_TYPES = [
  { value: DISCOUNT_TYPES.PERCENTAGE, label: "Procentowy" },
  { value: DISCOUNT_TYPES.GRATIS, label: "GRATIS" },
  { value: DISCOUNT_TYPES.REDUCING_AMOUNT, label: "Kwotowy obniżający" },
  { value: DISCOUNT_TYPES.AMOUNT_TO_USE, label: "Kwotowy do wydania" },
];

const POSSIBLE_PERIODS = [
  { value: "WEEKLY", label: "tydzień" },
  { value: "MONTHLY", label: "miesiąc" },
];

const POSSIBLE_RANKS = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

const HARDCODED_FIELDS = {
  SETTLEMENT_TYPE: {
    OPTIONS: [
      {
        label: "za zaraportowane przez Partnera",
        value: "settleForReportedByPartner",
      },
    ],
    VALUE: "settleForReportedByPartner",
  },
  DISCOUNT_CODE_TYPE: {
    OPTIONS: [{ value: "unique", label: "Unikatowy" }],
    VALUE: "unique",
  },
  BUSINESS_CATEGORY: { VALUE: "MyBenefit Active" },
  ACCEPTANCE_TYPE: {
    OPTIONS: [{ value: "auto", label: "automatyczna" }],
    VALUE: "auto",
  },
};

const DISCOUNT_CODES_CONSTS = {
  DISCOUNT_TYPES,
  POSSIBLE_RANKS,
  POSSIBLE_DISCOUNT_TYPES,
  POSSIBLE_PERIODS,
  HARDCODED_FIELDS,
};

export default DISCOUNT_CODES_CONSTS;
