import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function DynamicParametersDescription({
  parametersDynamic,
  isOpen,
}) {
  const [parametersDynamicInside, setParametersDynamicInside] =
    useState(parametersDynamic);
  useEffect(() => {
    setParametersDynamicInside(parametersDynamic);
  }, [parametersDynamic]);

  function generateDynamicParameters() {
    if (typeof parametersDynamicInside === "undefined") {
      return renderNothing();
    }
    if (parametersDynamicInside.length < 1) {
      return renderNothing();
    }
    return renderDynamicParameters();
  }

  function renderNothing() {
    return <></>;
  }

  function renderParameter(paramItem) {
    try {
      return (
        <>
          <b>{paramItem.parameterLabel}:</b>{" "}
          {paramItem.descriptionForSubscription}
          <br />
        </>
      );
    } catch (Exception) {
      newRelicErrorReport(
        Exception,
        "Pages/Report/NewReport/ReportsList/Report/dynamicParametersDescription.js - 39"
      );
      return <></>;
    }
  }

  function renderDynamicParameters() {
    const paramArray = [];
    for (let index = 0; index < parametersDynamicInside.length; index += 1) {
      paramArray.push(parametersDynamicInside[index]);
    }
    // console.log('array: '+paramArray.length);
    // console.log('list: '+parametersDynamicInside.length);
    return (
      <>
        <hr />
        <b>Zastosowanie mają parametry dynamiczne:</b>
        <br /> {paramArray.map((paramItem) => renderParameter(paramItem))}{" "}
        <hr />
      </>
    );
  }

  function renderParemeters() {
    return <div>{generateDynamicParameters()}</div>;
  }

  return <>{isOpen && renderParemeters()}</>;
}

DynamicParametersDescription.propTypes = {
  parametersDynamic: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
