import React from "react";
import { useLocation } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";
import { match as matchPropTypes } from "react-router-prop-types";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import MicroFrontend from "src/Components/MicroFrontend";

const customElementName = "proposals";

const Proposals = ({ match }) => {
  const companyName = useCompanyName();
  const companyId = useCompanyId();
  const { state } = useLocation();

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={`Wnioski i dofinansowania dla firmy ${companyName} (ID: ${companyId})`}
        breadcrumbs={[]}
        pushToHistory
      />
      <div className="my-3 card">
        <MicroFrontend customElementName={customElementName} key={state?.uuid}>
          {(appRef) => (
            <proposals-component
              ref={appRef}
              basename={match.url}
              management
              companyId={companyId}
            />
          )}
        </MicroFrontend>
      </div>
    </CSSTransitionGroup>
  );
};

Proposals.propTypes = {
  match: matchPropTypes.isRequired,
};

export default Proposals;
