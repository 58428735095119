/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useCallback, useState } from "react";
import { CSSTransitionGroup } from "react-transition-group";

import { Link } from "react-router-dom";
import PageTitle from "src/Layout/AppMain/PageTitle";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import { OPERATOR_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";
import {
  booleanOptions,
  SelectFilter,
  UuidFilter,
} from "src/Components/DataTable/filters";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import useOperatorRoles from "src/utils/hooks/operator/useOperatorRoles";
import SecurityWrapper from "src/utils/RoleBasedSecurity/SecurityComponents/SecuirityWrapper";
import { operatorOperatorPermissionWrite } from "src/utils/RoleBasedSecurity/permissions";
import BusinessIdColumn from "src/Components/DataTable/businessIdColumn";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const getUrlToForm = (id) => `/user/operator/edit/${id}`;

export default function OperatorList() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const fetchData = useCallback(async (filters, page, pageSize, sort) => {
    if (sort) {
      // eslint-disable-next-line no-param-reassign
      sort.key = changeSortKey(sort.key, "operatorRole", "operatorRole.name");
    }
    const { data: newData, count: newCount } = await getListingData(
      OPERATOR_MANAGEMENT_SERVICE,
      "/operators",
      filters,
      page,
      pageSize,
      sort,
      {},
      mockData,
      true
    );
    if (sort) {
      // eslint-disable-next-line no-param-reassign
      sort.key = changeSortKey(sort.key, "operatorRole.name", "operatorRole");
    }
    if (newData) {
      setData(newData);
      setCount(newCount);
    }
  }, []);

  const groups = useOperatorRoles(true, true);

  const toggleActive = async (id, value) => {
    try {
      const updatedData = [...data];
      const item = updatedData.find((el) => el.id === id);
      setIsActive(false);
      if (item) {
        item.active = value;
        setData(updatedData);
      }
      await restApiRequest(
        OPERATOR_MANAGEMENT_SERVICE,
        `/operators/${id}`,
        "PATCH",
        {
          body: {
            active: value,
          },
        },
        {}
      );
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "Pages/User/Operator/List/index.js - 76");
      const updatedData = [...data];
      const item = updatedData.find((el) => el.id === id);
      if (item) {
        item.active = !value;
        setData(updatedData);
      }
      dynamicNotification(
        e.message || __("Nie udało się zmienić aktywności użytkownika"),
        "error"
      );
    }
    setIsActive(true);
  };

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Lista operatorów"
          breadcrumbs={[{ title: "Operatorzy MB", link: "/user" }]}
        />
        <DataTableControlled
          id="operatorsListing"
          fetchData={fetchData}
          data={data}
          count={count}
          filterable
          buttons={[
            {
              color: "primary",
              href: getUrlToForm(-1),
              text: "+ Dodaj operatora",
              permission: operatorOperatorPermissionWrite,
              dataT1: "addOperator",
            },
          ]}
          columns={[
            {
              Header: "ID",
              accessor: "id",
              width: 150,
              Cell: BusinessIdColumn,
              Filter: UuidFilter,
            },
            {
              Header: "Imię",
              accessor: "firstName",
            },
            {
              Header: "Nazwisko",
              accessor: "lastName",
            },
            {
              Header: "Login",
              accessor: "username",
            },
            {
              Header: "E-mail",
              accessor: "businessEmail",
              data2: "email",
            },
            {
              Header: "Rola",
              accessor: "operatorRole",
              Filter: SelectFilter(groups),
              filterMethod: (filter) => {
                switch (filter.value) {
                  default:
                    return true;
                }
              },
              Cell: mapValueFromOptions(groups, "operatorRole"),
            },
            {
              Header: "Aktywny",
              accessor: "active",
              Filter: SelectFilter(booleanOptions),
              filterMethod: (filter) => {
                switch (filter.value) {
                  default:
                    return true;
                }
              },
              Cell: (rowData) => (
                <div className="d-block w-100 text-center">
                  <SecurityWrapper
                    disable
                    permission={operatorOperatorPermissionWrite}
                  >
                    <ToggleSwitch
                      handleChange={(isOn) =>
                        toggleActive(rowData.row._original.id, isOn)
                      }
                      checked={rowData.row._original.active}
                      id={rowData.row._original.id}
                      disabled={!isActive}
                    />
                  </SecurityWrapper>
                </div>
              ),
            },
            {
              Header: "Akcja",
              accessor: "action",
              filterable: false,
              sortable: false,
              Cell: (rowData) => (
                <div className="d-block w-100 text-center">
                  <Link
                    to={getUrlToForm(rowData.row._original.id)}
                    data-t1="rowAction-edit"
                    data-t2={rowData.row.id}
                  >
                    Edytuj
                  </Link>
                </div>
              ),
            },
          ]}
        />
      </CSSTransitionGroup>
    </>
  );
}
// eslint-disable-next-line func-names
export function changeSortKey(sortKey = null, oldKey, newKey) {
  if (sortKey !== null && sortKey === oldKey) {
    return newKey;
  }

  return oldKey;
}
export const mockData = [
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200021",
    ssoUuid: "asd-qwe-zxc-3421",
    firstName: "Adrian",
    lastName: "Admin",
    username: "admin",
    businessEmail: "adrian-admin@mybenefit.pl",
    operatorRole: "1",
    active: true,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200022",
    ssoUuid: "asd-qwe-222-3422",
    firstName: "Jan",
    lastName: "Kowal",
    username: "j.kowal",
    businessEmail: "j.kowal@mybenefit.pl",
    operatorRole: "3",
    active: true,
  },
  {
    id: "a43275e4-eeb2-11ea-adc1-0242ac1200023",
    ssoUuid: "asd-qwe-444-3423",
    firstName: "John",
    lastName: "Dow",
    username: "j.dow",
    businessEmail: "j.dow@mybenefit.pl",
    operatorRole: "2",
    active: false,
  },
];
