import { useState, useEffect } from "react";
import { COMPANY_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { mockData as companyMockData } from "src/Pages/Company/CompanyList";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import { companyCompanyPermissionRead } from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function useCompanies({
  asOption = false,
  filterField = null,
  filterValue = null,
  idAsIri = false,
  noFetching = false,
  withAdditionalMap = false,
}) {
  const [data, setData] = useState([]);
  const [dataMap, setDataMap] = useState([]);
  const hasPermission = useHasPermission(companyCompanyPermissionRead);
  const skipAction = !hasPermission || noFetching;

  useEffect(() => {
    if (skipAction) {
      setData([]);
      return;
    }
    const params = {
      itemsPerPage: 10000,
    };
    if (filterField && filterValue) {
      params[filterField] = filterValue;
    }
    restApiRequest(
      COMPANY_MANAGEMENT_SERVICE,
      asOption ? "/companies-names" : "/companies",
      "GET",
      { params },
      companyMockData
    )
      .then((resData) => {
        const result = resData.sort((a, b) =>
          a.shortName.localeCompare(b.shortName)
        );
        const filteredResult = [];
        const mappedResult = new Map();
        result.forEach((el) => {
          let res = el;

          if (idAsIri) {
            res.id = `${IRI_PREFIX}/${el.id}`;
          }
          if (asOption) {
            res = {
              value: res.id,
              label: res.shortName,
              fullName: res.fullName,
            };
          }

          if (withAdditionalMap) {
            mappedResult.set(el.id, res);
          }

          filteredResult.push(res);
        });

        setData(filteredResult);
        setDataMap(mappedResult);
      })
      .catch((e) => {
        newRelicErrorReport(e, "utils/hooks/company/useCompanies.js - 60");
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy firm"),
          "error"
        );
      });
  }, [filterField, filterValue, idAsIri, asOption, skipAction]);

  return withAdditionalMap ? [data, dataMap] : data;
}

export const IRI_PREFIX = "/api/company-management/v1/rest/companies";
