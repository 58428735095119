import React, { useEffect, useState, useContext } from "react";
// import PropTypes from 'prop-types';
import { match as matchPropTypes } from "react-router-prop-types";
import { useHistory } from "react-router-dom";
import { dynamicNotification } from "src/utils/Notifications";
import { REPORT_SERVICE, restApiRequest } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import __ from "src/utils/Translations";

const LandingPage = ({ match }) => {
  const { id } = match.params;
  const [archiveId, setArchiveId] = useState({});
  const history = useHistory();
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();

  useEffect(() => {
    getArchiveReport(id);
  }, [id]);

  useEffect(() => {
    pushToDetailsPage(archiveId, isAhr);
  }, [archiveId, isAhr]); // eslint-disable-line

  const pushToDetailsPage = (arch, isA) => {
    if (arch.archiveId) {
      history.push(
        getAhrUrl(
          `/report/archive/details/${arch.archiveId}&instantDownload=true`,
          isA
        )
      );
    }
  };

  const getArchiveReport = (token) => {
    const endpoint = `/filetokenverify/${token}`;

    fetchData(
      setArchiveId,
      endpoint,
      REPORT_SERVICE,
      "GET",
      {},
      [],
      __("Błąd podczas pobierania pliku z archiwum")
    );
  };

  return (
    <>
      <div>
        <p>{id}</p>
      </div>
    </>
  );
};

export default LandingPage;
LandingPage.propTypes = {
  match: matchPropTypes.isRequired,
};

const fetchData = async (
  updateData,
  endpoint,
  service,
  method,
  { headers, params },
  mockData,
  error
) => {
  if (isMockView()) {
    updateData(mockData);
  } else {
    try {
      const result = await restApiRequest(
        service,
        endpoint,
        method,
        { headers, params },
        {}
      );
      updateData(result);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "Pages/Report/LandingPage/index.js - 89");
      dynamicNotification(error || e.message || "błąd", "error");
    }
  }
};
