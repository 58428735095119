import React from "react";

import __ from "src/utils/Translations";

const getDateCell = (key, withTime = false, withZone = false) => {
  const renderEditable = (cellInfo) => {
    let options = {};
    const date = cellInfo.row[key];
    if (!date) {
      return null;
    }
    const dateObject = new Date(date);
    if (withZone) {
      options = { timeZone: "Europe/Warsaw" };
    }
    return (
      <div className="d-block w-100 text-center">
        {withTime
          ? dateObject.toLocaleString("pl-PL", options)
          : dateObject.toLocaleDateString("pl-PL", options)}
      </div>
    );
  };
  return renderEditable;
};

export default getDateCell;
