const getEncodedRequestFilters = ({ requestFilters = [] }) => {
  const encodedRequestFilters = [];
  requestFilters.forEach(({ id, value }) => {
    const filter = {};

    filter.id = id;
    if (typeof value !== "string") {
      filter.value = value;
    } else {
      filter.value = value?.replace(/\+/g, ""); // Remove the '+' sign from the value
    }

    encodedRequestFilters.push(filter);
  });

  return encodedRequestFilters;
};

export default getEncodedRequestFilters;
