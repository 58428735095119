import __ from "src/utils/Translations";

const disableButtonChecker = (noResults, massActionSelect) => {
  const { included, excluded, includedSelectAll } = massActionSelect;
  const includedLength = included.length > 45;
  /* eslint-disable-next-line max-len */
  const excludedLenght =
    included === true &&
    (includedSelectAll.length - excluded.length !== includedSelectAll.length ||
      includedSelectAll.length - excluded.length <= 45);

  return noResults || includedLength || excludedLenght;
};

export default disableButtonChecker;
