import React from "react";

import LanguagesSwitcher from "src/Layout/AppHeader/LanguagesSwitcher";

const HeaderDots = () => (
  <>
    <div className="header-dots">
      <LanguagesSwitcher />
    </div>
  </>
);

export default HeaderDots;
