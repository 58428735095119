/* eslint-disable react/prop-types */
import React from "react";

import ReactTable from "react-table";
import __ from "src/utils/Translations";
import Pagination from "src/Components/Pagination";
import { defaultFilterMethod } from "src/Components/DataTable/filters/DefaultFilter";

const getTable = ({
  dataCount,
  data,
  defaultSorted,
  loading,
  hasAccess,
  parsedColumns,
  defaultFilters,
  showPagination,
  defaultPageSize,
  className,
  filterable,
  filters,
  sortable,
  rowId,
  currentPage,
  currentPageInex,
  currentPageSize,
  getTrProps,
  setPage,
  setPageSize,
  setDataCount,
  setMassActionSelect,
  setFilters,
  setDefaultSorted,
  setSort,
}) => (
  <>
    <div
      className="text-right"
      data-t1="numberOfRecords"
      data-t2={dataCount || 0}
    >
      {__("Ilość rekordów")}:{dataCount || 0}
    </div>
    <ReactTable
      key={data.length}
      defaultSorted={defaultSorted}
      data={data}
      defaultFilterMethod={defaultFilterMethod}
      NoDataComponent={() => (
        <div className="no-table-records">{__("Nie znaleziono rekordów")}</div>
      )}
      loading={loading || !hasAccess}
      loadingText={
        hasAccess
          ? __("Pobieranie danych...")
          : __("Taki zasób nie istnieje lub nie masz do niego dostępu.")
      }
      columns={parsedColumns}
      defaultFiltered={defaultFilters}
      defaultPageSize={showPagination ? defaultPageSize : data.length}
      className={`-striped -highlight text-center ${className}`}
      filterable={filterable}
      filtered={filters}
      previousText={__("Poprzednia")}
      nextText={__("Następna")}
      pageText={__("Strona")}
      ofText={__("z")}
      rowsText={__("wpisów")}
      sortable={sortable}
      minRows={0}
      page={currentPageInex}
      pageSize={+currentPageSize}
      showPagination={false}
      getTheadThProps={(a, b, column) =>
        column.sortable !== false
          ? {
              "data-t1": "gridSort",
              "data-t2": column.id,
            }
          : {}
      }
      getTrGroupProps={(state, row) => ({
        "data-t1": row?.original?.name,
      })}
      getTrProps={(state, row) => ({
        "data-t1": "gridRow",
        "data-t2": row?.original?.benefit?.id ?? row?.original[rowId],
        ...getTrProps(state, row),
      })}
      getTdProps={(state, row, column) => ({
        "data-t1": "gridCell",
        "data-t2": column.id,
      })}
      onPageChange={(newPage) => setPage(newPage)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      onFetchData={(state) => {
        state.onPageChange(1);
        setDataCount(state.sortedData.length);
      }}
      onFilteredChange={(newFilters) => {
        if (typeof setMassActionSelect === "function") {
          setMassActionSelect({
            excluded: [],
            included: [],
          });
        }
        setFilters(newFilters);
      }}
      onSortedChange={(field) => {
        let sortValue = null;
        if (field && field[0]) {
          sortValue = {
            key: field[0].id,
            value: field[0].desc ? "desc" : "asc",
          };
        }
        if (setDefaultSorted) setDefaultSorted([field[0]]);
        setSort(sortValue);
      }}
    />
    {showPagination ? (
      <Pagination
        page={+currentPage}
        pageSize={currentPageSize}
        pages={Math.ceil(dataCount / currentPageSize)}
        availablePageSizes={[5, 10, 20, 40, 50, 80, 100]}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />
    ) : null}
  </>
);

export default getTable;
