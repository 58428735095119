import { Button } from "reactstrap";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { API_GATEWAY_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import {
  ACCEPTED_REQUIREMENT_FOR_BENEFIT_SUCCESS,
  ACCEPTED_REQUIREMENT_FOR_BENEFIT_FAILURE,
  REJECT_BENEFIT_FAILURE,
  REJECT_BENEFIT_SUCCESS,
} from "./consts";
import { alertMessages } from "./Table/consts";
import { useSelectedRows } from "./Table/SelectedProvider";

const MassAction = ({ isLoading, setIsLoading, onSuccess }) => {
  const { selectedRows, onResetSelection } = useSelectedRows();
  const isMassActionDisabled = !selectedRows.length;

  const handleAccept = useCallback(() => {
    setIsLoading(true);

    restApiRequest(
      API_GATEWAY_SERVICE,
      `/waiting-room/mass-accept`,
      "PUT",
      {
        body: selectedRows,
      },
      []
    )
      .then(() => {
        dynamicNotification(__(ACCEPTED_REQUIREMENT_FOR_BENEFIT_SUCCESS));
        onSuccess();
        onResetSelection();
      })
      .catch((err) => {
        dynamicNotification(
          __(err.message || ACCEPTED_REQUIREMENT_FOR_BENEFIT_FAILURE),
          "error"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [onResetSelection, onSuccess, selectedRows, setIsLoading]);

  const cancelWaitingRoom = useCallback(() => {
    const waitingRoomIds = selectedRows.map(
      ({ waitingRoomId }) => waitingRoomId
    );

    setIsLoading(true);

    restApiRequest(
      API_GATEWAY_SERVICE,
      `/waiting-room/mass-cancel`,
      "PUT",
      {
        body: waitingRoomIds,
      },
      []
    )
      .then(() => {
        dynamicNotification(__(REJECT_BENEFIT_SUCCESS));
        onSuccess();
        onResetSelection();
      })
      .catch((err) => {
        dynamicNotification(__(err.message || REJECT_BENEFIT_FAILURE), "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [onResetSelection, onSuccess, selectedRows, setIsLoading]);

  const handleCancel = useCallback(() => {
    getUserConfirmationPopup(
      __(alertMessages.default),
      (confirm) => confirm && cancelWaitingRoom(),
      __(`Czy na pewno chcesz odrzucić świadczenia?`)
    );
  }, [cancelWaitingRoom]);

  return (
    <div
      className="d-flex align-items-center justify-content-between mb-3"
      style={{ gap: 8 }}
    >
      <span>{`${__("Zaznaczone pozycje")}: ${selectedRows.length}`}</span>
      <div className="d-flex align-items-center" style={{ gap: 8 }}>
        <span>{__("Zaznaczone formularze")}:</span>
        <Button
          color="primary"
          disabled={isLoading || isMassActionDisabled}
          onClick={handleAccept}
        >
          {__("Akceptuj wybrane")}
        </Button>
        <Button
          color="danger"
          disabled={isLoading || isMassActionDisabled}
          onClick={handleCancel}
        >
          {__("Odrzuć wybrane")}
        </Button>
      </div>
    </div>
  );
};

MassAction.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default MassAction;
