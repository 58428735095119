import React from "react";
import __ from "src/utils/Translations";
import { isNull } from "lodash";

const getBenefitPerson = (data) =>
  !isNull(data.beneficiaryFirstName) && !isNull(data.beneficiaryLastName)
    ? `${data.beneficiaryFirstName} ${data.beneficiaryLastName}`
    : "";

const BenefitNameColumn = (cellInfo) => {
  const benefitName = cellInfo.row[cellInfo.column.id];
  const beneficiaryPerson = getBenefitPerson(cellInfo.original);

  return (
    <span className="text-break text-center">
      <strong>{benefitName} </strong>
      {beneficiaryPerson.length ? (
        <>{`${__("dla")} ${beneficiaryPerson}`}</>
      ) : null}
    </span>
  );
};

export default BenefitNameColumn;
