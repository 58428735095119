/* eslint-disable no-underscore-dangle, react/destructuring-assignment, react/prop-types */
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import {
  SelectFilter,
  objectStatusOptions,
} from "src/Components/DataTable/filters";
import { TOURISM_SERVICE } from "src/utils/Api";
import useDictionary from "src/utils/hooks/dictionaries/useDictionary";
import { DICTIONARY_COUNTRIES } from "src/utils/hooks/dictionaries/dictionariesCodes";
import { mapValueFromOptions } from "src/Components/DataTable/commonCells";
import { tourismTourismObjectPermissionImport } from "src/utils/RoleBasedSecurity/permissions";
import ActionColumn from "src/Components/DataTable/actionColumn";
import useUniqueAnixeSuppliers from "src/utils/hooks/suppliers/useUniqueAnixeSuppliers";
import { ExportContext } from "src/Components/DataTableControlled/context";
import { getAnixeValue } from "./editObject/utils/anixeData";
import ImportForm from "./importForm";

export default function ObjectsTable() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [openImportPopup, setOpenImportPopup] = useState(false);
  const [massActionSelect, setMassActionSelect] = useState({
    excluded: [],
    included: [],
  });

  const countriesOptions = useDictionary(DICTIONARY_COUNTRIES);
  const suppliersOptions = useUniqueAnixeSuppliers();

  const closeImportForm = useCallback(() => {
    setOpenImportPopup(false);
  }, [setOpenImportPopup]);
  const getUrlToForm = (id) => `/tourism/objects/edit/${id}#basic_info`;

  const fetchData = useCallback(async (filters, page, pageSize, sort) => {
    const nameFilters = ["name", "anixeName"];
    const cityFilters = ["city", "anixeCity"];
    const filterData = [];
    let pushedName = false;
    filters.forEach(({ id, value }) => {
      if (nameFilters.includes(id)) {
        if (!pushedName) {
          filterData.push({ id: "multi_searchByName", value: [value] });
          pushedName = true;
        }
      } else if (cityFilters.includes(id)) {
        if (!pushedName) {
          filterData.push({ id: "multi_searchByCity", value: [value] });
          pushedName = true;
        }
      } else {
        filterData.push({ id, value });
      }
    });
    filterData.push({ id: "rowsPerPage", value: pageSize });
    const { data: newData, count: newCount } = await getListingData(
      TOURISM_SERVICE,
      "/tourism-objects",
      filterData,
      page,
      pageSize,
      sort,
      {},
      tourismObjectsMockData
    );
    setData(parseTourismObjectDataFromBackend(newData));
    setCount(newCount);
  }, []);

  const exportContext = new ExportContext({
    service: TOURISM_SERVICE,
    path: "/tourism-objects/export",
    permission: tourismTourismObjectPermissionImport,
    fileName: "eksport_obiektow_turystycznych",
  });

  return (
    <>
      <DataTableControlled
        id="tourismObjectListing"
        filterable
        exportContext={exportContext}
        columns={[
          {
            Header: "Kod obiektu",
            accessor: "anixeId",
          },
          {
            Header: "Pełna nazwa obiektu",
            accessor: "anixeName",
            Cell: ({ row }) => <div>{row._original.name}</div>,
          },
          {
            Header: "Lista dostawców",
            accessor: "suppliers.anixeSupplierCode",
            Filter: SelectFilter(suppliersOptions),
            Cell: ({ row }) => <div>{row._original.suppliers.join(", ")}</div>,
          },
          {
            Header: "Państwo",
            accessor: "countryCode",
            Filter: SelectFilter(countriesOptions),
            Cell: mapValueFromOptions(countriesOptions, "countryCode"),
          },
          {
            Header: "Miasto",
            accessor: "anixeCity",
            maxWidth: 150,
            Cell: ({ row }) => <div>{row._original.city}</div>,
          },
          {
            Header: "Status",
            accessor: "status",
            maxWidth: 150,
            Filter: SelectFilter(objectStatusOptions),
            Cell: mapValueFromOptions(objectStatusOptions, "status"),
          },
          {
            Header: "Akcja",
            filterable: false,
            sortable: false,
            Cell: ({ row }) => (
              <div className="d-block w-100 text-center row">
                <ActionColumn
                  data={row._original}
                  buttons={[
                    {
                      id: "tourismObjectEdit",
                      className: "m-1",
                      href: getUrlToForm(row._original.id),
                      color: "link",
                      label: "Edytuj",
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
        data={data || []}
        count={count}
        fetchData={fetchData}
        rowId="id"
        massActions={[
          {
            label: "Aktualizacja atrybutów obiektów",
            id: "update",
            action: (included, excluded, filters) => {
              const params = new URLSearchParams({
                included,
                excluded,
                filters: JSON.stringify(filters),
              });
              history.push(
                `/tourism/objects/mass-attribite-update?${params.toString()}`
              );
            },
          },
        ]}
        massActionSelect={massActionSelect}
        setMassActionSelect={setMassActionSelect}
      />
      {openImportPopup ? (
        <ImportForm close={closeImportForm} isOpen={openImportPopup} />
      ) : null}
    </>
  );
}

export const parseTourismObjectDataFromBackend = (newData) =>
  newData.map((record) => {
    const item = { ...record };
    const { suppliers = [] } = record;
    item.name =
      record.name ||
      record.anixeName ||
      getAnixeValue(record?.anixeData || {}, "name");
    item.countryCode = getAnixeValue(record?.anixeData || {}, "countryCode");
    item.suppliers = suppliers.map(
      ({ anixeSupplierCode }) => anixeSupplierCode
    );
    item.city =
      record.city ||
      record.anixeCity ||
      getAnixeValue(record?.anixeData || {}, "city");
    return item;
  });

export const tourismObjectsMockData = [
  {
    id: "1",
    anixeId: "WRO001",
    anixeCity: "Wrocław (Anixe)",
    anixeName: "Krasnoludkowo (Anixe)",
    city: "Wrocław",
    street: "Sezamkowa 15",
    countryCode: "Polska",
    code: "2342346",
    status: "Aktywny",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
    ],
    checkbox: false,
    anixeData: {
      pl: { name: "Krasnalowo (anixeData)", city: "Wrocław (anixeData)" },
    },
  },
  {
    id: "2",
    anixeId: "WRO002",
    city: "Gdańsk",
    street: "Sezamkowa 15",
    name: "Pod Dużym Krasnalem",
    countryCode: "Polska",
    code: "2342346",
    status: "Aktywny",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
      {
        id: "7ee597df-0647-4289-b04d-e8ef2fc91b81",
        anixeId: "WRO003",
        anixeSupplierCode: "HTLB",
        supplierId: "b021c252-0690-4373-bc46-0f5f0fcb58d3",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "3",
    anixeId: "WRO003",
    city: "Wrocław",
    street: "Sezamkowa 15",
    name: "Krasnal",
    countryCode: "Niemcy",
    code: "2342346",
    status: "Aktywny",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
      {
        id: "7ee597df-0647-4289-b04d-e8ef2fc91b81",
        anixeId: "WRO003",
        anixeSupplierCode: "HTLB",
        supplierId: "b021c252-0690-4373-bc46-0f5f0fcb58d3",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "4",
    anixeId: "WRO004",
    city: "Wrocław",
    street: "Sezamkowa 15",
    name: "U Róży",
    countryCode: "Belgia",
    status: "Aktywny",
    code: "2342346",
    suppliers: [
      {
        id: "7ee597df-0647-4289-b04d-e8ef2fc91b81",
        anixeId: "WRO003",
        anixeSupplierCode: "HTLB",
        supplierId: "b021c252-0690-4373-bc46-0f5f0fcb58d3",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "5",
    anixeId: "WRO005",
    city: "Wrocław",
    street: "Sezamkowa 15",
    name: "Pod Dużym Krasnalem",
    countryCode: "Belgia",
    status: "Aktywny",
    code: "2342346",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "6",
    anixeId: "WRO006",
    city: "Gdańsk",
    street: "Sezamkowa 15",
    name: "Pod Dużym Krasnalem",
    countryCode: "Belgia",
    code: "2342346",
    status: "Aktywny",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
      {
        id: "7ee597df-0647-4289-b04d-e8ef2fc91b81",
        anixeId: "WRO003",
        anixeSupplierCode: "HTLB",
        supplierId: "b021c252-0690-4373-bc46-0f5f0fcb58d3",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "7",
    anixeId: "WRO007",
    city: "Wrocław",
    street: "Sezamkowa 15",
    name: "Mikołajkowo",
    code: "2342346",
    countryCode: "Belgia",
    status: "Aktywny",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "8",
    anixeId: "WRO008",
    city: "Wrocław",
    street: "Sezamkowa 15",
    name: "Pod Dużym Krasnalem",
    countryCode: "Polska",
    code: "2342346",
    status: "Aktywny",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "9",
    anixeId: "WRO009",
    city: "Wrocław",
    street: "Sezamkowa 15",
    name: "Pod Dużym Krasnalem",
    countryCode: "Niemcy",
    code: "2342346",
    status: "Aktywny",
    suppliers: [
      {
        id: "7ee597df-0647-4289-b04d-e8ef2fc91b81",
        anixeId: "WRO003",
        anixeSupplierCode: "HTLB",
        supplierId: "b021c252-0690-4373-bc46-0f5f0fcb58d3",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
  {
    id: "10",
    anixeId: "WRO010",
    city: "Wrocław",
    street: "Sezamkowa 15",
    name: "Mikołajkowo",
    countryCode: "Niemcy",
    code: "2342346",
    status: "Aktywny",
    suppliers: [
      {
        id: "cac1e284-33b6-429a-a545-00f37ae8f7db",
        anixeId: "WRO003",
        anixeSupplierCode: "SUNHOTELS",
      },
      {
        id: "7ee597df-0647-4289-b04d-e8ef2fc91b81",
        anixeId: "WRO003",
        anixeSupplierCode: "HTLB",
        supplierId: "b021c252-0690-4373-bc46-0f5f0fcb58d3",
      },
    ],
    checkbox: false,
    anixeData: {},
  },
];
