import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

const defaultFeatureFlags = [];

const FeatureFlagsContext = createContext(defaultFeatureFlags);

const FeatureFlagsProvider = ({ children, options }) => {
  const [features, setFeatures] = useState(defaultFeatureFlags);
  useEffect(() => {
    if (options?.apiUrl) {
      const fetchFeatures = async () => {
        const res = await fetch(`${options.apiUrl}`);
        const data = await res.json();

        setFeatures(data);
        window.featureFlags = data;
      };

      fetchFeatures();
    }
  }, [options.apiUrl]);

  return (
    <FeatureFlagsContext.Provider value={features}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);

export { FeatureFlagsProvider, useFeatureFlagsContext };

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({
    apiUrl: PropTypes.string.isRequired,
  }).isRequired,
};
