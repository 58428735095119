class ExportContext {
  constructor({
    service,
    path,
    additionalPath,
    isAdditionalPathVisible,
    permission,
    fileName,
    handleAdditionalFilters = null,
    hideIfNoPermission,
    buttonTitle,
    customDropdownItems,
  }) {
    this.getService = () => service;
    this.getPath = () => path;
    this.getAdditionalPath = () => additionalPath;
    this.getIsAdditionalPathVisible = isAdditionalPathVisible;
    this.getPermission = () => permission;
    this.getFileName = () => fileName;
    this.getHideIfNoPermission = () => hideIfNoPermission;
    this.handleAdditionalFilters = handleAdditionalFilters;
    this.getButtonTitle = () => buttonTitle;
    this.getCustomDropdownItems = () => customDropdownItems;
  }
}

export default ExportContext;
