import __ from "src/utils/Translations";
import { getQueryString } from "src/utils/Api";

const buildExportPathForAhr = async (path, massActionSelect) => {
  const params = { itemsPerPage: 150000 };
  params["order[firstName]"] = "asc";
  let checkedIds = [];

  if (massActionSelect.included !== true)
    checkedIds = [...massActionSelect.included];
  const allCompanyIdQuery = checkedIds.map((ele) => `companyId[]=${ele}`);

  const queryString = getQueryString(params, false);
  return `${path}${queryString}${
    checkedIds && `&${allCompanyIdQuery.join("&")}`
  }`;
};

export default buildExportPathForAhr;
