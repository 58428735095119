import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { restApiRequest, REPORT_SERVICE } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { getLanguage } from "src/utils/Languages/LanguageContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { reportGroupsMockData } from "./mockData";
import ReportGroup from "./reportGroup";

const ReportsList = ({ companyId }) => {
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    fetchGroupsData(companyId);
  }, [companyId]);

  const fetchGroupsData = async (selectedCompany) => {
    if (
      selectedCompany !== "" &&
      selectedCompany !== "00000000-0000-0000-0000-000000000000"
    ) {
      setGroupList([]);
      try {
        const result = await restApiRequest(
          REPORT_SERVICE,
          `/reportgroups/groups/${selectedCompany}?languageCode=${getLanguage()}`,
          "GET",
          {},
          reportGroupsMockData
        );
        setGroupList(result);
      } catch (e) {
        console.error(e);
        newRelicErrorReport(e, "Pages/Report/LandingPage/index.js -107");
        dynamicNotification(
          __("Błąd podczas pobierania grup raportów") || e.message || "błąd",
          "error"
        );
      }
    }
  };

  const reportGroups = groupList.map((item) => (
    // eslint-disable-next-line eqeqeq
    <ReportGroup
      key={item.reportGroupId}
      group={item}
      reports={item.reports}
      isOpened={item.reportGroupId === "-1"}
      isAdHoc={item.reportGroupId === 9999}
    />
  ));

  return (
    <>
      <div className="row">
        <div className="col-md-8 col-6">
          {__("Lista raportów predefiniowanych w podziale na grupy")}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 report-group-title">
          {__("Grupy raportów")}
        </div>
      </div>
      {reportGroups}
    </>
  );
};

export default ReportsList;
ReportsList.propTypes = {
  companyId: PropTypes.string.isRequired,
};
