import React, { useCallback } from "react";
import { CustomInput } from "reactstrap";
import PropTypes from "prop-types";
import { useSelectedRows } from "./SelectedProvider";

const RowSelectionColumnHeader = ({ data }) => {
  const { onSelectAllChange } = useSelectedRows();

  const isEveryRowSelected =
    data.length > 0 && data.every(({ rowSelected }) => rowSelected);

  const handleChange = useCallback(
    (ev) => {
      const { checked } = ev.target;
      onSelectAllChange(
        checked,
        data.map(({ _original }) => _original)
      );
    },
    [data, onSelectAllChange]
  );

  return (
    <div style={{ overflow: "hidden" }}>
      <CustomInput
        id="table_select_all"
        type="checkbox"
        onChange={handleChange}
        checked={isEveryRowSelected}
        value={String(isEveryRowSelected)}
      />
    </div>
  );
};

RowSelectionColumnHeader.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ rowSelected: PropTypes.bool.isRequired })
  ).isRequired,
};

export default RowSelectionColumnHeader;
