import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import __ from "src/utils/Translations";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import PopupContext from "./popupContext";

export const toggleWithCheck = (isEdited, toggle) => {
  if (isEdited) {
    getUserConfirmationPopup(
      __("Czy na pewno chcesz wyjść z formularza?"),
      (confirm) => confirm && toggle(),
      __("Niezapisane zmiany zostaną utracone")
    );
  } else {
    toggle();
  }
};

const Popup = ({
  id,
  isOpen,
  toggle,
  unmountOnClose,
  size,
  className,
  isEdited,
  children,
  isLoading,
}) => (
  <PopupContext.Provider
    value={{ toggle: () => toggleWithCheck(isEdited, toggle) }}
  >
    <Modal
      isOpen={isOpen}
      toggle={isLoading ? null : () => toggleWithCheck(isEdited, toggle)}
      unmountOnClose={unmountOnClose}
      size={size}
      className={className}
    >
      <div data-t1={id}>{children}</div>
    </Modal>
  </PopupContext.Provider>
);

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  unmountOnClose: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  isEdited: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Popup.defaultProps = {
  isOpen: true,
  unmountOnClose: true,
  size: undefined,
  className: undefined,
  isEdited: false,
  isLoading: false,
};

export default Popup;
