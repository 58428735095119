/* eslint-disable react/prop-types */
import React from "react";
import __ from "src/utils/Translations";
import isUuid from "src/utils/jsHelpers/isUuid";
import { BusinessId } from "src/Components/DataTable/businessIdColumn";

export const SelectFilterWithTranslation =
  (options, submitForm = true) =>
  ({ filter, onChange, column }) => {
    const optionsArray =
      (Array.isArray(options) &&
        options.map(({ label, value }) => (
          <option key={value} value={value}>
            {__(label)}
          </option>
        ))) ||
      [];

    optionsArray.unshift(
      <option value="" key="empty-option">
        ---
      </option>
    );
    return (
      <select
        data-t1="gridFilter"
        data-t2={column.id}
        className="form-control"
        value={filter ? filter.value : 0}
        onChange={(e) => {
          onChange(e.target.value);
          if (submitForm && e.target.form) {
            const submitButton = e.target.form.querySelector(
              'button[type="submit"]'
            );
            setTimeout(() => {
              submitButton.click();
            }, 10);
          }
        }}
      >
        {optionsArray}
      </select>
    );
  };

export const mapValueFromOptionsTranslation = (
  options,
  key,
  optionValue = "value",
  optionLabel = "label"
) => {
  const renderEditable = (cellInfo) => {
    const value = cellInfo.row[key];
    if (!Array.isArray(value)) {
      const matchedOption = options.find(
        (option) => option[optionValue] === value
      );
      if (!matchedOption && isUuid(value)) {
        return <BusinessId value={value} />;
      }
      const result = matchedOption ? matchedOption[optionLabel] : value;
      return <div>{__(result)}</div>;
    }

    return (
      <ul>
        {value.map((elValue) => {
          const matchedOption = options.find(
            (option) => option[optionValue] === elValue
          );
          const result = matchedOption
            ? matchedOption[optionLabel]
            : `${__("Nieznany zasób")}: ${elValue}`;
          return <li key={elValue}>{__(result)}</li>;
        })}
      </ul>
    );
  };
  return renderEditable;
};
export const translatedFrequencyText = (info) => {
  let str = "";
  if (info.match("raz w dniu") != null && info.match("raz w dniu").length > 0) {
    str = __("raz w dniu") + info.substr(10);
  } else if (
    info.match("co roku w dniu") != null &&
    info.match("co roku w dniu").length > 0
  ) {
    str = __("co roku w dniu") + info.substr(14);
  } else {
    const iColon = info.indexOf(":") - 1;
    const iNumber =
      info.substr(0, 4).match(/\d+/) != null &&
      info.substr(0, 4).match(/\d+/).length > 0
        ? info.substr(0, 4).indexOf(info.match(/\d+/))
        : 0;
    let trs = [];
    if (
      info.match("tydzień w dniu") != null &&
      info.match("tydzień w dniu").length > 0
    ) {
      if (iNumber > 0) {
        str = `${__(info.substr(0, 4).substr(0, iNumber - 1))}${" "} ${info
          .substr(0, 4)
          .match(/\d+/)}`;
      }
      if (iColon > 0) {
        str += ` ${__(info.substr(iNumber + 1, iColon - 2))} `;
      }
      trs = info.substr(iColon + 2).split(",");
      trs.forEach((item, index, array) => {
        if (index > 0) {
          str += ", ";
        }
        str += __(item);
      });
    } else if (info.match("month") != null && info.match("month").length > 0) {
      if (iColon > 0) {
        str += ` ${__(info.substr(0, info.indexOf(":") + 1))} `;
      }
      trs = info.substr(iColon + 2).split(",");
      trs.forEach((item, index, array) => {
        if (index > 0) {
          str += ", ";
        }
        str += __(item);
      });
    } else {
      str = info;
    }
  }

  return str;
};
export const correctLanguageParameters = (parameters, languages) => {
  parameters.forEach((param, i) => {
    if (param.parameterName === "pLanguage") {
      param.values.forEach((lp, j) => {
        const lang = languages.find((l) => l.code === lp.value);
        if (lang) {
          // eslint-disable-next-line no-param-reassign
          parameters[i].values[j].value = lang.label;
        }
      });
    }
  });
  return parameters;
};

export const parametersHelper = () => {};
