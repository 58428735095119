import PropTypes from "prop-types";
import React, { useState } from "react";
import Popup from "src/Components/Popup/popup";
import Form from "src/Components/Form";
import { API_GATEWAY_SERVICE, restApiPrepaidCardsRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { isUndefined, isNil, isNaN } from "lodash";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { getLocalDate } from "src/utils/jsHelpers/getDateAndTime";

export default function FormPopup({ isOpen, close, employeeId }) {
  const [formData, setFormData] = useState({});

  const [errorMessage, setErrorMessage] = useState(null);
  const [dateErrorMessage, setDateErrorMessage] = useState(null);
  const [
    missingConfigurationErrorMessage,
    setMissingConfigurationErrorMessage,
  ] = useState(null);

  const errors = {
    accountNumberError: __(
      "Numer rachunku bankowego jest przypisany do innego użytkownika"
    ),
    dateError: __(
      "Nie można dodać aktywnej karty pracownika z powodu: Podana data ważności jest zbyt wczesna. Powinno być za co najmniej 3 miesiące od teraz"
    ),
    missingConfiguration: __(
      "Nie można dodać aktywnej karty pracownika z powodu: Nie znaleziono konfiguracji KPP dla danej grupy pracowniczej."
    ),
  };

  // Potencjalny rozwój
  /*   const alertMessage = (errorType) => 
    <Alert color="warning"><RiErrorWarningLine className='fa-2x' />
    {' '} {errors[errorType]}
  </Alert> */

  const onChange = (key, value) => {
    const updatedData = { ...formData };
    updatedData[key] = value;
    const previousStateCardNumberLength = () => {
      const { cardNumber } = formData;
      if (!isUndefined(cardNumber)) {
        return cardNumber.length;
      }
      return 0;
    };
    if (key === "accountNumber") {
      const event = value.slice(-1);
      if (isNaN(Number(event))) {
        updatedData[key] = value.slice(0, -1);
      }
      setErrorMessage(null);
    }
    if (key === "expirationDate") {
      setDateErrorMessage(null);
      const date = getLocalDate(value);
      updatedData[key] = date;
    }
    return setFormData(updatedData);
  };

  const onClick = () => {
    const validInputs =
      !isUndefined(formData.cardNumber) &&
      formData.cardNumber.toString().length === 16 &&
      !isUndefined(formData.accountNumber) &&
      formData.accountNumber.toString().length === 26 &&
      !isNil(formData.expirationDate);
    if (validInputs) {
      getUserConfirmationPopup(
        __("Czy na pewno chcesz dodać nową kartę dla użytkownika?"),
        (confirm) => confirm && formSubmit(),
        __(
          "Uwaga - dodając i aktywując nową kartę dla użytkownika, dezaktywujesz poprzednią"
        )
      );
    }
  };

  const formSubmit = async () => {
    try {
      const response = await restApiPrepaidCardsRequest(
        API_GATEWAY_SERVICE,
        "/admin-prepaid-cards/create-card-duplicate",
        "POST",
        {
          body: {
            employeeId,
            ...formData,
          },
        }
      );
      const data = await response.json();
      if (data.code === "CARD_IN_PROGRESS") {
        dynamicNotification(__(data.message), "error");
        close();
      }
      if (data.code === "DUPLICATED_ACCOUNT_NUMBER") {
        setErrorMessage("errorMessage");
      }
      if (data.code === "EXPIRATION_DATE_TOO_EARLY") {
        setDateErrorMessage("errorMessage");
      }
      if (data.code === "MISSING_CARD_CONFIGURATION") {
        setMissingConfigurationErrorMessage("errorMessage");
      }
      if (response.status === 201) {
        dynamicNotification(__("Dodano nową kartę"));
        close();
      }
    } catch (e) {
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/PrepaidCardsTab/formPopup.js - 103"
      );
      dynamicNotification(
        e.message || __("Nie udało się dodać nową kartę"),
        "error"
      );
    }
  };

  return (
    <>
      <Popup
        id="prepaidCardsModal"
        isOpen={isOpen}
        toggle={close}
        unmountOnClose
        size="lg"
      >
        <Form
          id="new-card"
          data={formData}
          config={{
            isInPopup: true,
            title: __("Dodaj karte"),
            buttons: [
              {
                size: "lg",
                color: "light",
                onClick: () => {
                  close();
                },
                className: "mr-2",
                text: __("Wróć"),
              },
              {
                size: "lg",
                color: "primary",
                className: "mr-2",
                text: __("Zapisz i aktywuj kartę"),
                type: "submit",
                onClick,
              },
            ],
            defaultOnChange: onChange,
            formGroups: [
              {
                formElements: [
                  {
                    label: __("Zamaskowany numer karty:"),
                    id: "cardNumber",
                    validation: [
                      "required",
                      { method: "equalLength", args: [16] },
                    ],
                    mask: "99\\*\\*\\*\\*\\*\\*\\*\\*\\*\\*9999",
                    maskChar: "_",
                  },
                  {
                    label: __("Numer rachunku bankowego:"),
                    id: "accountNumber",
                    [errorMessage]: errors.accountNumberError,
                    validation: [
                      "required",
                      { method: "equalLength", args: [26] },
                    ],
                  },
                  {
                    type: "date",
                    label: __("Data upływu możliwości zasilenia:"),
                    [dateErrorMessage]: errors.dateError,
                    [missingConfigurationErrorMessage]:
                      errors.missingConfiguration,
                    id: "expirationDate",
                    validation: ["required"],
                  },
                ],
              },
            ],
          }}
        />
      </Popup>
    </>
  );
}

const testPost = {
  cardNumber: "12**********5678",
  accountNumber: "77161013488826222909205046",
  expirationDate: "2022-01-01",
  employeeId: "4a4450dc-9fb7-4356-916a-dadb57a247fc",
};

FormPopup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  employeeId: PropTypes.string.isRequired,
};
