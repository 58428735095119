/* eslint-disable react/jsx-props-no-spreading  */
import React from "react";
import __ from "src/utils/Translations";
// eslint-disable-next-line import/no-cycle
import CustomDropdownItem from "src/Components/DataTableControlled/CustomDropdownItem";
import { MAX_EXPORT_ITEMS } from "src/Components/DataTableControlled/consts";

const getDropdownItems = ({
  noResults,
  service,
  filters,
  sort,
  pageSize,
  page,
  fileName,
  pathForPageSizeItems,
  pathForCustomSizeItems,
  pathForMaxExportItems,
  handleAdditionalFilters,
}) => {
  const commonProps = {
    sort,
    handleAdditionalFilters,
    service,
    fileName,
  };
  return [
    {
      disabled: noResults,
      path: pathForPageSizeItems,
      filters,
      pageSize,
      page,
      title: "Eksportuj obecną stronę do CSV",
      ...commonProps,
    },
    {
      disabled: filters.length === 0 || noResults,
      path: pathForCustomSizeItems,
      filters,
      pageSize: MAX_EXPORT_ITEMS,
      title: "Eksportuj wyfiltrowane do CSV",
      dataT1: "export-filtered",
      ...commonProps,
    },
    {
      path: pathForMaxExportItems,
      filters: [],
      pageSize: MAX_EXPORT_ITEMS,
      title: "Eksportuj wszystko do CSV",
      exportAll: true,
      dataT1: "export-all",
      ...commonProps,
    },
    {
      disabled: noResults,
      path: pathForPageSizeItems,
      filters,
      pageSize,
      page,
      title: "Eksportuj obecną stronę do XLS",
      fileType: "xlsx",
      ...commonProps,
    },
    {
      disabled: filters.length === 0 || noResults,
      path: pathForCustomSizeItems,
      filters,
      pageSize: MAX_EXPORT_ITEMS,
      title: "Eksportuj wyfiltrowane do XLS",
      dataT1: "export-filtered",
      fileType: "xlsx",
      ...commonProps,
    },
    {
      path: pathForMaxExportItems,
      filters: [],
      pageSize: MAX_EXPORT_ITEMS,
      title: "Eksportuj wszystko do XLS",
      dataT1: "export-all",
      fileType: "xlsx",
      exportAll: true,
      ...commonProps,
    },
  ].map((props) => <CustomDropdownItem {...props} />);
};

export default getDropdownItems;
