import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import { getAhrBaseBreadcrumbs } from "src/Pages/AhrCore/routerHelper";
import __ from "src/utils/Translations";
import VisualReport from "src/Pages/Report/GraphReports/Graph";

export default () => {
  const companyName = useCompanyName();
  const companyId = useCompanyId();

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Raporty graficzne dla firmy ${companyName} (ID: ${companyId})`}
          breadcrumbsHeading={__("Raporty graficzne")}
          pushToHistory
          breadcrumbs={[
            ...getAhrBaseBreadcrumbs(companyName),
            { title: __("Raporty"), link: "/ahr/report" },
          ]}
        />
        <VisualReport companyId={companyId} />
      </CSSTransitionGroup>
    </>
  );
};
