import React, { useContext } from "react";
import { Link } from "react-router-dom";

import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { faAngleDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import city3 from "src/assets/utils/images/dropdown-header/city3.jpg";
import __ from "src/utils/Translations";

const UserBox = () => {
  const { logout, userInfo } = useContext(RbsContext);
  const userName = `${userInfo.getFirstName()} ${userInfo.getLastName()}`;
  const role = userInfo.getRole();
  const email = userInfo.getEmail();
  const isAhr = userInfo.isAhr();

  const changePasswordRedirectUrl = isAhr ? "/ahr/password" : "/password";
  return (
    <>
      <div className="header-btn-lg pr-0" data-t1="userInfoBox">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle
                  color="link"
                  className="p-0"
                  data-t1="userDataTrigger"
                >
                  <FontAwesomeIcon size="2x" icon={faUser} />
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu
                  right
                  className="rm-pointers dropdown-menu-lg"
                  style={{ padding: 0 }}
                  data-t1="UserDetails"
                >
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div
                        className="menu-header-image opacity-2"
                        style={{
                          backgroundImage: `url(${city3})`,
                        }}
                      />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <FontAwesomeIcon size="2x" icon={faUser} />
                            </div>
                            <div className="widget-content-left">
                              <div
                                className="widget-heading"
                                data-t1="UserDetails"
                                data-t2="userFullName"
                                data-t3={userName}
                              >
                                {userName}
                              </div>
                              <div
                                className="widget-subheading"
                                data-t1="UserDetails"
                                data-t2="userEmail"
                              >
                                {email}
                              </div>
                              <div
                                className="widget-subheading"
                                data-t1="UserDetails"
                                data-t2="userRole"
                              >
                                {__("Rola")}: {role}
                              </div>
                            </div>
                            <div
                              className="widget-content-right mr-2"
                              style={{ minWidth: "100px" }}
                            >
                              <div className="mb-3">
                                <Link to={changePasswordRedirectUrl}>
                                  <Button
                                    color="focus"
                                    className="btn-pill btn-shadow btn-shine"
                                    data-t1="changePassword"
                                  >
                                    {__("Zmień hasło")}
                                  </Button>
                                </Link>
                              </div>
                              <Button
                                data-t1="logout"
                                className="btn-pill btn-shadow btn-shine"
                                color="focus"
                                onClick={logout}
                              >
                                {__("Wyloguj")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ml-3 header-user-info">
              <div
                className="widget-heading"
                data-t1="userFullName"
                data-t2={userName}
              >
                {userName}
              </div>
              <div className="widget-subheading">{email}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBox;
