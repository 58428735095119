import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card } from "reactstrap";
import { cloneDeep } from "lodash";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";
import ContentLoading from "src/Components/Loading/contentLoading";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import { restApiRequest, SUBSCRIPTION_MANAGEMENT_SERVICE } from "src/utils/Api";
import uniqueArray from "src/utils/jsHelpers/arrayUnique";
import {
  STATUS_ACTIVE,
  STATUS_BENEFIT_PRICING_CHANGED,
  getAlertMessage,
  alertTypes,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import PendingBenefitsContext from "./context";
import {
  PENDING_BENEFIT_FILTER_GROUPS,
  checkIfMassRejectionIsPossible,
  filterAdditionalStatus,
  filterSelectedBenefitsWithOriginalData,
  filterSelectedFormsWithOriginalData,
  onAddListsToExpandedMassivelyHandler,
  onManageListExpansionArrayHandler,
  prepareBackendFilters,
  resetFilterGroup,
} from "./utils";
import Filters from "./Filters";
import Table from "./Table";

const DEFAULT_FILTERS_STATE = {
  [PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS]: {},
  [PENDING_BENEFIT_FILTER_GROUPS.DEFAULT]: {},
};

const DEFAULT_MASS_ACTION_SELECT_STATE = {
  excluded: [],
  included: [],
};

const MASS_ACTION_POPUP_WARNING_MESSAGE =
  "Uwaga! Ta zmiana jest nieodwracalna.";
const MASS_ACCEPTANCE_ACTION_POPUP_TITLE =
  "Czy na pewno chcesz zaakceptować wszystkie zaznaczone elementy?";
const MASS_REJECTION_ACTION_POPUP_TITLE =
  "Czy na pewno chcesz odrzucić wszystkie zaznaczone elementy?";

export default function PendingBenefits({ companyId }) {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(() =>
    cloneDeep(DEFAULT_FILTERS_STATE)
  );
  const [isLoading, setIsLoading] = useState(false);

  const [expandedFormLists, setExpandedFormLists] = useState([]);
  const [expandedReceiverLists, setExpandedReceiverLists] = useState([]);

  const [massActionSelect, setMassActionSelect] = useState(
    DEFAULT_MASS_ACTION_SELECT_STATE
  );
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);

  const memorizedFiltersToUse = useRef(PENDING_BENEFIT_FILTER_GROUPS.DEFAULT);
  const memorizedSelectedBenefitsIds = useRef([]);

  const isAnythingSelected =
    massActionSelect.included.length ||
    selectedForms.length ||
    selectedPeople.length;

  const isMassRejectionPossible = checkIfMassRejectionIsPossible({
    data,
    selectedBenefits: massActionSelect.included,
    selectedPeople,
    selectedForms,
  });

  const hasBenefitPricingChanged = selectedPeople.some(
    (el) => el.status === STATUS_BENEFIT_PRICING_CHANGED
  );

  const updateItem = useCallback(
    (newData, itemId, attribute) => {
      const processedId = itemId || newData.id;

      const item = data.find(({ id }) => id === processedId);
      if (!item) return;
      const updatedData = [...data];
      updatedData[data.indexOf(item)] = attribute
        ? { ...item, [attribute]: newData }
        : newData;

      setData(updatedData);
    },
    [data]
  );

  const fetchData = async (filtersToUse) => {
    if (filtersToUse) memorizedFiltersToUse.current = filtersToUse;
    setIsLoading(true);

    const params = prepareBackendFilters(filters, companyId, filtersToUse);

    const newData = await restApiRequest(
      SUBSCRIPTION_MANAGEMENT_SERVICE,
      `/subscription-item-receivers`,
      "GET",
      {
        params: { ...params },
      },
      []
    );

    const prepNewData = newData
      .map((ele) => ({
        ...ele,
        // When it's benefit_pricing_change case, we want to display status from "subscriptionItemStatus" even for individual purchaseMethod
        status:
          ele.subscriptionItemStatus === "benefit_pricing_changed"
            ? ele.subscriptionItemStatus
            : ele.status,
      }))
      .filter((ele) => ele.status !== STATUS_ACTIVE);
    const filteredData =
      filtersToUse === PENDING_BENEFIT_FILTER_GROUPS.DEFAULT &&
      filters[PENDING_BENEFIT_FILTER_GROUPS.DEFAULT].status
        ? filterAdditionalStatus(
            prepNewData,
            filters[PENDING_BENEFIT_FILTER_GROUPS.DEFAULT].status
          )
        : prepNewData;

    if (
      memorizedFiltersToUse.current ===
        PENDING_BENEFIT_FILTER_GROUPS.FORM_NUMBERS &&
      filters.formNumbers.formNumbers?.length
    ) {
      const pdfBarcodesIds = filteredData.reduce(
        (acc, { pdfBarcodes }) => [
          ...acc,
          ...pdfBarcodes.map(({ barcode }) => barcode),
        ],
        []
      );

      const missingFormNumbers = filters.formNumbers.formNumbers?.filter(
        (selectedFormNumber) =>
          pdfBarcodesIds.every(
            (pdfBarcode) => pdfBarcode !== selectedFormNumber
          )
      );

      if (
        missingFormNumbers.length === filters.formNumbers.formNumbers?.length
      ) {
        dynamicNotification(
          __("Kody nie zostały znalezione"),
          "error",
          null,
          false
        );
      }

      if (
        missingFormNumbers.length &&
        missingFormNumbers.length < filters.formNumbers.formNumbers?.length
      ) {
        dynamicNotification(
          <>
            {__("Nie znaleziono kodów o numerze:")}
            {missingFormNumbers.map((formNumber) => (
              <>
                <br />
                {formNumber}
              </>
            ))}
          </>,
          "error",
          null,
          false
        );
      }
    }
    setData(filteredData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetFiltersHandler = (filterGroup) => {
    memorizedFiltersToUse.current = null;
    if (Object.values(PENDING_BENEFIT_FILTER_GROUPS).includes(filterGroup)) {
      setFilters((prev) => resetFilterGroup(prev, filterGroup));
      return;
    }
    setFilters(cloneDeep(DEFAULT_FILTERS_STATE));
  };

  const onClearSelectedItemsHandler = () => {
    setMassActionSelect(DEFAULT_MASS_ACTION_SELECT_STATE);
    setSelectedForms([]);
    setSelectedPeople([]);
  };

  const onMassRejectionHandler = () => {
    getUserConfirmationPopup(
      __(
        hasBenefitPricingChanged
          ? getAlertMessage(alertTypes.cancelAlertPricingChanged)
          : MASS_ACTION_POPUP_WARNING_MESSAGE
      ),
      async (isAccepted) => {
        if (!isAccepted) return;
        try {
          await restApiRequest(
            SUBSCRIPTION_MANAGEMENT_SERVICE,
            `/employee-subscription-item-receivers/bulk-cancel`,
            "POST",
            {
              body: {
                subscriptionItemReceivers:
                  filterSelectedBenefitsWithOriginalData(
                    massActionSelect.included,
                    data
                  ),
              },
            },
            null
          );
          fetchData();
          onClearSelectedItemsHandler();
          dynamicNotification(__("Pomyślnie wykonano masową anulację"));
        } catch (e) {
          dynamicNotification(
            __("Nie udało się wykonać masowej anulacji"),
            "error"
          );
        }
      },
      __(MASS_REJECTION_ACTION_POPUP_TITLE)
    );
  };

  const onMassAcceptanceHandler = () => {
    getUserConfirmationPopup(
      __(
        hasBenefitPricingChanged
          ? getAlertMessage(alertTypes.acceptPricingChanged)
          : MASS_ACTION_POPUP_WARNING_MESSAGE
      ),
      async (isAccepted) => {
        if (!isAccepted) return;
        try {
          await restApiRequest(
            SUBSCRIPTION_MANAGEMENT_SERVICE,
            `/employee-subscription-item-receivers/bulk-accept`,
            "POST",
            {
              body: {
                receivers: filterSelectedBenefitsWithOriginalData(
                  massActionSelect.included,
                  data
                ),
                pdfBarcodes: filterSelectedFormsWithOriginalData(
                  selectedForms,
                  data
                ),
              },
            },
            null
          );
          fetchData();
          onClearSelectedItemsHandler();
          dynamicNotification(__("Pomyślnie wykonano masową akceptację"));
        } catch (e) {
          dynamicNotification(
            __("Nie udało się wykonać masowej akceptacji"),
            "error"
          );
        }
      },
      __(MASS_ACCEPTANCE_ACTION_POPUP_TITLE)
    );
  };

  const onUnselectCheckboxHandler = (ids, cb, benefitId) => {
    if (benefitId) {
      setMassActionSelect((prev) => ({
        ...prev,
        included: prev.included.filter(
          (selectedBenefit) => selectedBenefit !== benefitId
        ),
      }));
    }
    cb((prev) => prev.filter((itemId) => !ids.some((id) => itemId === id)));
  };

  const onSelectFormsHandler = (forms) => {
    setSelectedForms((prev) => {
      const newIds = forms
        .filter(
          ({ id: rowFormId, accepted }) =>
            !accepted && !prev.some((formId) => rowFormId === formId)
        )
        .map(({ id }) => id);
      return [...prev, ...newIds];
    });
  };

  const onUnselectFormsHandler = (ids, benefitId) => {
    onUnselectCheckboxHandler(ids, setSelectedForms, benefitId);
  };

  const onSelectPeopleHandler = (receivers) => {
    setSelectedPeople((prev) => {
      const newIds = receivers
        .filter(
          ({ currentReceiverId: rowPersonId, pending }) =>
            pending && !prev.some((personId) => rowPersonId === personId)
        )
        .map(({ currentReceiverId }) => currentReceiverId);

      return [...prev, ...newIds];
    });
  };

  const onUnselectPeopleHandler = (ids, benefitId) => {
    onUnselectCheckboxHandler(ids, setSelectedPeople, benefitId);
  };

  const onChangeMassActionSelectHandler = (value) => {
    const selectedBenefitsIds = value;
    const selectedData = data.filter(({ id }) =>
      selectedBenefitsIds.some((selectedBenefit) => id === selectedBenefit)
    );
    if (
      data.length &&
      selectedBenefitsIds.length < memorizedSelectedBenefitsIds.current.length
    ) {
      const unselectedBenefitIds = memorizedSelectedBenefitsIds.current.filter(
        (id) => !selectedBenefitsIds.some((selectedId) => id === selectedId)
      );

      const { recId, pdfBarcodes: pdfBarcodesToUnselect } = data
        .filter(({ id }) => unselectedBenefitIds.includes(id))
        .reduce(
          ({ pdfBarcodes: accumulatedPdfBarcodes }, { pdfBarcodes }) => {
            const filteredPdfBarcodes = pdfBarcodes.filter(
              ({ accepted }) => !accepted
            );

            return {
              pdfBarcodes: [...accumulatedPdfBarcodes, ...filteredPdfBarcodes],
            };
          },
          { receivers: [], pdfBarcodes: [] }
        );

      const idsOfPdfBarcodesToUnselect = pdfBarcodesToUnselect.map(
        ({ id }) => id
      );

      setSelectedPeople((prev) => prev.filter((id) => id === recId));
      setSelectedForms((prev) =>
        prev.filter(
          (id) =>
            !idsOfPdfBarcodesToUnselect.some(
              (idToUnselect) => id === idToUnselect
            )
        )
      );

      memorizedSelectedBenefitsIds.current = selectedBenefitsIds;
      return;
    }

    const receiversIdsIncludedInSelectedData = selectedData.reduce(
      (acc, curr) => [...acc, curr],
      []
    );

    setSelectedPeople((prev) =>
      [...prev, ...receiversIdsIncludedInSelectedData].filter(uniqueArray)
    );

    const formsIdsIncludedInSelectedData = selectedData.reduce(
      (acc, { pdfBarcodes }) => [
        ...acc,
        ...pdfBarcodes.filter(({ accepted }) => !accepted).map(({ id }) => id),
      ],
      []
    );

    setSelectedForms((prev) =>
      [...prev, ...formsIdsIncludedInSelectedData].filter(uniqueArray)
    );

    onAddListsToExpandedMassivelyHandler({
      ids: selectedBenefitsIds,
      cb: setExpandedFormLists,
    });

    onAddListsToExpandedMassivelyHandler({
      ids: selectedBenefitsIds,
      cb: setExpandedReceiverLists,
    });

    memorizedSelectedBenefitsIds.current = selectedBenefitsIds;
  };

  const onManageFormListExpansionHandler = ({ id, isExpanded }) => {
    onManageListExpansionArrayHandler({
      id,
      isExpanded,
      cb: setExpandedFormLists,
    });
  };

  const onManageReceiverListExpansionHandler = ({ id, isExpanded }) => {
    onManageListExpansionArrayHandler({
      id,
      isExpanded,
      cb: setExpandedReceiverLists,
    });
  };

  return (
    <PendingBenefitsContext.Provider
      value={{
        massActionSelect,
        setMassActionSelect,
        onChangeMassActionSelectCb: onChangeMassActionSelectHandler,
        selectedPeople,
        setSelectedPeople,
        onSelectPeopleHandler,
        onUnselectPeopleHandler,
        selectedForms,
        setSelectedForms,
        onSelectFormsHandler,
        onUnselectFormsHandler,
        expandedFormLists,
        manageFormListExpansion: onManageFormListExpansionHandler,
        expandedReceiverLists,
        manageReceiverListExpansion: onManageReceiverListExpansionHandler,
      }}
    >
      <Filters
        fetchData={fetchData}
        companyId={companyId}
        filters={filters}
        setFilters={setFilters}
        resetFilters={onResetFiltersHandler}
      />
      <ContentLoading show={isLoading}>
        <Card>
          <div
            className="d-flex justify-content-end mb-3 pt-3 pr-3"
            style={{ gap: 8 }}
          >
            <Button
              onClick={onMassAcceptanceHandler}
              color="primary"
              disabled={!isAnythingSelected}
            >
              {__("Akceptuj zaznaczone")}
            </Button>

            <Button
              onClick={onMassRejectionHandler}
              color="danger"
              disabled={!isMassRejectionPossible}
            >
              {__("Anuluj zaznaczone")}{" "}
              {!isMassRejectionPossible && (
                <DefaultTooltip
                  type="black"
                  content={__(`
                    Masowa anulacja możliwa jest tylko dla całego benefitu, nie ma
                    możliwości anulowania masowo osób oraz formularzy
                  `)}
                  innerClassName=""
                  id="rejection_mass_action"
                />
              )}
            </Button>
          </div>

          <Table
            subscriptionsData={data}
            updateItem={updateItem}
            fetchData={fetchData}
          />
        </Card>
      </ContentLoading>
    </PendingBenefitsContext.Provider>
  );
}

PendingBenefits.propTypes = {
  companyId: PropTypes.string.isRequired,
};
