import { Button, Input } from "reactstrap";
import __ from "src/utils/Translations";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import { requireErrorMessage } from "src/Pages/ActiveForms/Edit/Configurator/validation";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import PropTypes from "prop-types";
import React from "react";
// eslint-disable-next-line import/no-cycle
import DynamicTranslationTrigger from "src/Components/DynamicTranslation/DynamicTranslationTrigger";

const SelectCreatorView = ({
  listTitle = null,
  setListTitle = null,
  onTitleBlur = null,
  validateFields = false,
  id,
  options,
  move,
  updateOption,
  removeOption,
  addOption,
}) => {
  const listHeader = setListTitle ? (
    <div className="selectCreatorInputTitle">
      <div className="d-flex flex-row ">
        <Input
          placeholder={__("Tytuł listy rozwijanej")}
          value={listTitle}
          type="text"
          onChange={(e) => setListTitle(e.target.value)}
          onBlur={onTitleBlur}
          invalid={validateFields && !listTitle}
        />
        <DynamicTranslationTrigger
          scope={`subscriptionManagement:activeForms:form:select:title:${id}`}
          value={listTitle}
          type="text"
        />
      </div>
      <ValidationMessage
        message={validateFields && !listTitle ? __(requireErrorMessage) : null}
      />
    </div>
  ) : (
    <p>{__("Opcje listy rozwijanej")}</p>
  );

  return (
    <div className="selectCreatorContainer">
      {listHeader}
      {Array.isArray(options) &&
        options.map((option, index) => {
          const isOptionString = typeof option === "string";
          const optionHasValue = Boolean(
            isOptionString ? option : option.value
          );

          return (
            <div className="optionContainer">
              <div className="optionInnerContainer">
                <div className="selectCreatorButtonsContainer">
                  {index !== 0 && (
                    <i
                      className="lnr-arrow-up btn-icon-wrapper cursor-pointer"
                      role="presentation"
                      onClick={() =>
                        move(isOptionString ? index : option.order, "up")
                      }
                    />
                  )}
                  {index !== options.length - 1 && (
                    <i
                      className="lnr-arrow-down btn-icon-wrapper cursor-pointer"
                      role="presentation"
                      onClick={() =>
                        move(isOptionString ? index : option.order, "down")
                      }
                    />
                  )}
                </div>
                <Input
                  placeholder={`${__("Opcja")} ${index + 1}`}
                  value={isOptionString ? option : option.value}
                  type="text"
                  onChange={(e) => updateOption(e.target.value, index)}
                  invalid={validateFields && !optionHasValue}
                />
                <div className="selectCreatorButtonsContainer">
                  <DynamicTranslationTrigger
                    scope={`subscriptionManagement:activeForms:form:select:option:${id}`}
                    value={isOptionString ? option : option.value}
                    type="text"
                  />
                  {options.length > 1 && (
                    <i
                      className="lnr-trash btn-icon-wrapper cursor-pointer"
                      role="presentation"
                      onClick={() =>
                        getUserConfirmationPopup(
                          "Czy potwierdzasz usunięcie opcji dla listy rozwijanej?",
                          (confirm) =>
                            confirm &&
                            removeOption(isOptionString ? index : option.order),
                          "Usunięcie opcji listy rozwijanej"
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <ValidationMessage
                message={
                  validateFields && !optionHasValue
                    ? __(requireErrorMessage)
                    : null
                }
              />
            </div>
          );
        })}
      <Button
        key="add_option"
        color="link"
        onClick={addOption}
        data-t1="addEmployeeGroup"
      >
        <i className="pe-7s-plus pe-3x pe-va" />
        {__("Dodaj opcję listy rozwijanej")}
      </Button>
    </div>
  );
};

SelectCreatorView.propTypes = {
  listTitle: PropTypes.string,
  setListTitle: PropTypes.func,
  onTitleBlur: PropTypes.func,
  validateFields: PropTypes.bool,
  id: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  move: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  addOption: PropTypes.func.isRequired,
};

SelectCreatorView.defaultProps = {
  listTitle: null,
  setListTitle: null,
  onTitleBlur: null,
  validateFields: false,
};

export default SelectCreatorView;
