/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import __ from "src/utils/Translations";

export const POPUP_TYPE_RESIGN = "resign";
export const POPUP_TYPE_CANCEL = "cancel";
export const POPUP_TYPE_CHANGE = "change";
export const POPUP_TYPE_SUSPEND = "suspend";
export const POPUP_TYPE_BLOCK = "block";
export const POPUP_TYPE_CHANGE_DATA = "changeData";
export const POPUP_TYPE_SHOW_PERSONS = "showPersons";

export const STATUS_CANCELED = "canceled";
export const STATUS_PENDING = "pending";
export const STATUS_PENDING_ACCEPTED = "pending_accepted";
export const STATUS_NEW_PERSON = "new_person";
export const STATUS_ACTIVE = "active";
export const STATUS_DENIED = "denied";
export const STATUS_RESIGNED = "resigned";
export const STATUS_SUSPENDED = "suspended";
export const STATUS_PROCESSING = "processing";
export const STATUS_BENEFIT_PRICING_CHANGED = "benefit_pricing_changed";

export const statusesOptions = [
  { value: STATUS_ACTIVE, label: "Aktywne" },
  { value: STATUS_CANCELED, label: "Anulowane" },
  { value: STATUS_RESIGNED, label: "Zrezygnowane" },
  { value: STATUS_SUSPENDED, label: "Zawieszone" },
  { value: STATUS_PENDING, label: "Oczekujące" },
  { value: STATUS_PENDING_ACCEPTED, label: "Oczekujące" },
  { value: STATUS_NEW_PERSON, label: "Dodano kolejną osobę" },
  { value: STATUS_PROCESSING, label: "Przetwarzane" },
  {
    value: STATUS_BENEFIT_PRICING_CHANGED,
    label: "Zmieniono sposób finansowania",
  },
];

export const statusesOptionsDeactivated = [
  { value: STATUS_CANCELED, label: "Anulowane" },
  { value: STATUS_RESIGNED, label: "Zrezygnowane" },
];

export const FORM_TYPE_ACTIVE = "activeForm";
export const FORM_TYPE_SIMPLE = "form";

export const FORM_FIELD_CONFIG = {
  firstname: "imię",
  lastname: "nazwisko",
};

export const RECEIVER_LABELS = {
  employee: "pracownika",
  others: "osoby dodatkowej",
};

export const alertTypes = {
  default: "default",
  acceptPricingChanged: "acceptPricingChanged",
  new_person: "new_person",
  cancelAlert: "cancelAlert",
  cancelAlertPricingChanged: "cancelAlertPricingChanged",
};

export const getAlertMessage = (type) => {
  const alertMessages = {
    default: "Uwaga! ta zmiana jest nieodwracalna.",
    acceptPricingChanged: (
      <>
        <p className="mb-0">{__("Uwaga! ta zmiana jest nieodwracalna.")}</p>
        <p>
          {__(`Akceptacja zmiany sposobu finansowania dla jednej osoby spowoduje akceptację
  zmiany sposobu finansowania dla wszystkich osób w świadczeniu`)}
        </p>
      </>
    ),
    new_person: `Uwaga! Dokonujesz anulacji całego abonamentu, jeśli chcesz anulować
  abonament tylko dla nowo dodanych użyj przycisku "anuluj zaznaczone" w kolumnie "status".
  Anulacja jest nieodwracalna.`,
    cancelAlert: `Uwaga! Jeśli abonament dostępny jest dla pracownika i osób dodatkowych, dokonując anulacji abonamentu dla pracownika,
  dokonujesz anulacji całego abonamentu. Anulacja jest nieodwracalna. Czy chcesz dokonać anulacji?`,
    cancelAlertPricingChanged: (
      <>
        <p className="mb-0">{__("Uwaga!")}</p>
        <p>
          {__(`Anulacja zmiany sposobu finansowania dla jednej osoby spowoduje anulację zmiany sposobu finansowania
  dla wszystkich osób w świadczeniu.`)}
        </p>
        <p className="mb-0">
          {__(`Jeśli abonament dostępny jest dla pracownika i osób dodatkowych, dokonując anulacji abonamentu
  dla pracownika, dokonujesz anulacji całego abonamentu.`)}
        </p>
        <p>{__("Anulacja jest nieodwracalna. Czy chcesz dokonać anulacji?")}</p>
      </>
    ),
  };
  return alertMessages[type] ?? alertMessages.default;
};

export const benefitIdColumn = (cellInfo) => {
  const { benefitGroupId, benefitSubgroupId, benefitId } = cellInfo.original;

  if (!benefitGroupId || !benefitId) return null;

  const { company } = cellInfo.column;

  const editHref = benefitSubgroupId
    ? `/company/edit/${company}/ums-subscriptions/benefits/edit/${benefitId}`
    : `/company/edit/${company}/subscriptions/benefits/edit/${benefitId}`;

  return (
    <div className="d-block w-100 text-center">
      <Link to={editHref}>{benefitId}</Link>
    </div>
  );
};

export const hasFormsWithNames = (data) => {
  if (!data) return false;

  if (!_.isEmpty(data.form)) {
    return data.form.fields?.some(
      (ele) => Object.keys(FORM_FIELD_CONFIG).includes(ele?.type) && ele?.value
    );
  }

  return false;
};

export const parseDataToBackend = (formToChange, newData) => {
  const cleanedForm = {
    firstname: formToChange.fields?.find((field) => field.type === "firstname")
      ?.value,
    lastname: formToChange.fields?.find((field) => field.type === "lastname")
      ?.value,
  };

  for (const fieldKey in newData) {
    if (Object.prototype.hasOwnProperty.call(newData, fieldKey)) {
      const splittedKey = fieldKey.split(".");
      const [formId, fieldName] = splittedKey;

      if (formToChange.id === formId) {
        cleanedForm[fieldName] = newData[fieldKey];
      }
    }
  }

  return cleanedForm;
};

export const getDataProvidedDate = (cellInfo) => {
  const { receivers } = cellInfo.original;

  if (_.isEmpty(receivers) || !receivers[0].dataProvidedAt) {
    return "-";
  }

  const date = new Date(receivers[0].dataProvidedAt);

  return date.toLocaleDateString("pl-PL", { timeZone: "Europe/Warsaw" });
};

export const prepareDataNameColumn = (prepFilteredData) =>
  prepFilteredData.map((person) => {
    const resultData = {
      receiverFullName: "",
      startsAt: person.startsAt,
      status: person.status,
      form: {
        fields: person.fields,
      },
      id: person.subscriptionItemReceiverId,
    };
    const isThatNamesScope = person.fields && person.fields.length;

    if (!isThatNamesScope) return resultData;

    const firstName =
      person.fields.find((field) => field.type === "firstname")?.value || "";
    const lastName =
      person.fields.find((field) => field.type === "lastname")?.value || "";

    return {
      receiverFullName:
        firstName || lastName ? `${firstName} ${lastName}` : "...",
      startsAt: person.startsAt,
      status: person.status,
      form: {
        fields: person.fields,
      },
      id: person.subscriptionItemReceiverId,
    };
  });
