import { useState, useEffect } from "react";
import { restApiRequest, COMPANY_MANAGEMENT_SERVICE } from "src/utils/Api";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";

export default function useCompanyPointsBanks(
  asOption = false,
  companyId,
  noFetching = false
) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (noFetching) {
      setData([]);
      return;
    }
    const params = {
      pointsBankOwnerId: companyId,
    };
    restApiRequest(
      COMPANY_MANAGEMENT_SERVICE,
      "/points-banks/owner",
      "GET",
      { params },
      mockData
    )
      .then((resData) => {
        let result = resData;
        if (asOption) {
          result = result.map(({ pointsBankId, name, fullName }) => ({
            value: pointsBankId,
            label: fullName || name,
          }));
        }
        setData(result);
      })
      .catch((e) => {
        newRelicErrorReport(
          e,
          "utils/hooks/pointsBank/useCompanyPointsBanks.js - 40"
        );
        dynamicNotification(
          e.message || __("Nie udało się pobrać listy banków punktów"),
          "error"
        );
      });
  }, [companyId, asOption, noFetching]);

  return data;
}

export const IRI_PREFIX = "/api/company-management/v1/rest/companies";

const mockData = [
  {
    pointsBankId: "047ff444-c57e-4dc2-86c2-c8a5910984b9",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "test5",
    locks: [],
  },
  {
    pointsBankId: "4d157c59-bae0-416c-ba20-c1d39cc7b5eb",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "Testowy bank 2",
    locks: [],
  },
  {
    pointsBankId: "c38648dd-4c06-4959-aa5d-2fceace8f3c4",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "tes4",
    locks: [],
  },
  {
    pointsBankId: "eed80c44-7eec-413b-9808-d85a596301ba",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "Testowy bank",
    locks: [],
  },
  {
    pointsBankId: "235621a9-8060-4d87-85dc-a9f036bf86e5",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "test6",
    locks: [],
  },
  {
    pointsBankId: "07042743-c2d0-46b7-bfd0-61da57335a21",
    pointsBankOwnerId: "00000002-0000-4000-8001-000000000001",
    name: "test3",
    locks: [],
  },
];
